import React from 'react';
import { RouteNode } from '../../@types';

const List = React.lazy(() => import('../../pages/jobLevel/List'));
const Details = React.lazy(() => import('../../pages/jobLevel/Details'));
const Update = React.lazy(() => import('../../pages/jobLevel/Update'));
const Correction = React.lazy(() => import('../../pages/jobLevel/Correction'));
const Create = React.lazy(() => import('../../pages/jobLevel/Create'));

const JobLevel: RouteNode[] = [
  {
    label: 'Job Level',
    path: 'job-level',
    component: List,
    child: null,
  },
  {
    label: '',
    path: 'job-level/detail/:id',
    component: Details,
    child: null,
  },
  {
    label: '',
    path: 'job-level/create',
    component: Create,
    child: null,
  },
  {
    label: '',
    path: 'job-level/update/:id',
    component: Update,
    child: null,
  },
  {
    label: '',
    path: 'job-level/correction/:id',
    component: Correction,
    child: null,
  },
];

export default JobLevel;
