import React from 'react';
import { Box, Typography, useTheme } from '@mui/material';
import { useLocation } from 'react-router-dom';
import { Input } from '..';
import { SearchMd } from '../icon';
import { InputProps } from '../../../@types';
import { useAppDispatch, useAppSelector } from '../../../store';
import useDebouncedEffect from '../../../hooks/useDebouncedEffect';
import {
  resetAllFilter,
  setPages,
  setSearch,
  resetSearch,
} from '../../../store/feature/utilSlice';

type SearchboxProps = {
  paginationKey?: string;
  subText?: string;
  // onSearchChange?: Function;
};

const Searchbox = <UseFieldArrayOptions extends Record<string, any>>(
  Props: InputProps & UseFieldArrayOptions & SearchboxProps
) => {
  const { paginationKey, subText, ...props } = Props;
  const { palette } = useTheme();
  const dispatch = useAppDispatch();

  const location = useLocation();
  const pathName = paginationKey
    ? `${location.pathname}/${paginationKey}`
    : location.pathname;

  const { search } = useAppSelector(state => state.utils);
  const [searchFilter, setSearchFilter] = React.useState(
    search[pathName] || ''
  );

  const onChangeSearch = (e: any) => {
    setSearchFilter(e?.target?.value);
  };

  useDebouncedEffect(
    () => {
      dispatch(setSearch({ [pathName]: searchFilter }));
      if (searchFilter) {
        dispatch(resetAllFilter(pathName));
        dispatch(setPages({ [pathName]: 0 }));
      }
    },
    [searchFilter],
    500
  );

  React.useEffect(() => {
    dispatch(resetSearch(pathName));
  }, []);

  const Search = React.useMemo(
    () => (
      <>
        <Input
          placeholder="Search..."
          sx={{ width: '300px' }}
          key={search[pathName]}
          prefixIcon={
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                '& svg': {
                  transform: 'scale(0.8333)',
                },
              }}
            >
              <SearchMd />
            </Box>
          }
          onChange={(
            event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
          ) => {
            if (event.target.value.length <= 100) {
              onChangeSearch(event);
            }
          }}
          inputProps={{
            maxLength: 100,
          }}
          defaultValue={search[pathName]}
          {...props}
        />

        {subText !== '' && (
          <Typography variant="body14" component="div" color={palette.gray[90]}>
            {subText}
          </Typography>
        )}
      </>
    ),
    []
    // [search[pathName]]
  );

  return Search;
};

export default Searchbox;
