const CheckboxUncheck = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.8 3.75H16.2C17.0525 3.75 17.6467 3.75058 18.1093 3.78838C18.5632 3.82546 18.824 3.8946 19.0215 3.99524C19.4448 4.21095 19.789 4.55516 20.0048 4.97852C20.1054 5.17604 20.1745 5.4368 20.2116 5.89068C20.2494 6.35331 20.25 6.94755 20.25 7.8V16.2C20.25 17.0525 20.2494 17.6467 20.2116 18.1093C20.1745 18.5632 20.1054 18.824 20.0048 19.0215C19.789 19.4448 19.4448 19.789 19.0215 20.0048C18.824 20.1054 18.5632 20.1745 18.1093 20.2116C17.6467 20.2494 17.0525 20.25 16.2 20.25H7.8C6.94755 20.25 6.35331 20.2494 5.89068 20.2116C5.4368 20.1745 5.17604 20.1054 4.97852 20.0048C4.55516 19.789 4.21095 19.4448 3.99524 19.0215C3.8946 18.824 3.82546 18.5632 3.78838 18.1093C3.75058 17.6467 3.75 17.0525 3.75 16.2V7.8C3.75 6.94755 3.75058 6.35331 3.78838 5.89068C3.82546 5.4368 3.8946 5.17604 3.99524 4.97852C4.21095 4.55516 4.55516 4.21095 4.97852 3.99524C5.17604 3.8946 5.4368 3.82546 5.89068 3.78838C6.35331 3.75058 6.94755 3.75 7.8 3.75Z"
      stroke="#475667"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default CheckboxUncheck;
