import { Box, SxProps, Typography, useTheme } from '@mui/material';
import React from 'react';
import { GridViewProps } from '../../../@types/GridViewLayoutProps';
import {
  calculateDateDiff,
  isValidDate,
  formatBirthToGen,
  formatDateTimeFromUnixUTC,
} from '../../../utils/date';
import { Tags } from '../../atom';
import { ArrowRight } from '../../atom/icon';
import dayjs from '../../../utils/dayjs';
import ButtonEmployeeLink from './Details/ButtonEmployeeLink';
import UpcomingChanges from '../upcomingChanges/UpcomingChanges';

type GridLayoutProps = {
  layout: GridViewProps;
  type?: string;
  onClickLinked?: Function;
  onClickCancel?: Function;
  shouldFormatBirthToGen?: boolean;
  upcomingContainerWidth?: string;
  customBg?: string;
};

const GridLayout = ({
  layout,
  type = '',
  onClickLinked,
  onClickCancel,
  shouldFormatBirthToGen = true,
  upcomingContainerWidth,
  customBg,
}: GridLayoutProps) => {
  const { palette, typography } = useTheme();
  const dataPresentationStatus = layout.type || type;
  const style: SxProps = {
    display: 'flex',
    flexDirection: 'column',
    gap: '8px',
    '& .list': {
      display: 'flex',
      mb: '1rem',
      backgroundColor: customBg || palette.gray[10],
      padding: '8px 12px',
    },
    '& .sub-title': {
      fontWeight: 700,
    },
    '& .grid-container': {
      display: 'flex',
      justifyContent: 'space-between',
    },
    '& .list-container': {
      display: 'flex',
      gap: '24px',
      mb: '0.5rem',
      '& .label': {
        ...typography.body14,
        width: '168px',
        color: palette.gray[80],
      },
      '& .value': {
        display: 'flex',
        flex: 1,
        // gap: '8px',
        columnGap: '8px',
        flexWrap: 'wrap',
      },
      '& .MuiButtonBase-root': {
        justifyContent: 'flex-start',
      },
      '& .arrow': {
        mr: '0.5rem',
      },
    },
  };

  const compareObject = (obj1: any, obj2: any) => {
    let differences: string[] = [];
    if (
      (Object.keys(obj1).length === 0 || Object.keys(obj1).length === 0) &&
      dataPresentationStatus === 'UPCOMING'
    ) {
      Object.keys(obj2).forEach(key => {
        differences = [...differences, key];
      });
      return differences;
    }
    Object.keys(obj1).forEach(key => {
      if (typeof obj1[key] === 'function') {
        return;
      }
      if (obj1[key] !== obj2[key]) {
        differences = [...differences, key];
      }
    });
    return differences;
  };

  const determineValue = (dataValue: any) => {
    if (typeof dataValue === 'number') {
      return formatDateTimeFromUnixUTC(dataValue);
    }
    if (typeof dataValue === 'boolean') {
      return dataValue ? 'Yes' : 'No';
    }
    if (dataValue && dataValue?.label) {
      return dataValue?.label || '';
    }
    if (dataValue && !dataValue?.label) {
      return dataValue;
    }
    return '-';
  };

  const getLabelValue = (
    key: string,
    headerKey: { [key: string]: string },
    name: string,
    data: { [key: string]: any },
    upcomingData?: { [key: string]: any },
    tagsComponent?: string[],
    extraTags?: string[]
  ) => {
    let differences: string[] = [];
    let color = 'inherit';
    const dataValue = data[name]
      ? data[name][key as keyof typeof headerKey]
      : data[key];
    let upcoming: any = '';
    if (dataPresentationStatus !== '' && dataPresentationStatus !== 'ACTIVE') {
      differences = compareObject(data, upcomingData);
      if (dataPresentationStatus === 'PREVIOUS') {
        color = `${palette.red[60]}`;
      } else {
        color = `inherit`;
        if (differences.includes(key)) {
          upcoming = determineValue(
            upcomingData?.[key as keyof typeof headerKey]
          );
          if (tagsComponent?.includes(key)) {
            upcoming = <Tags label={upcoming} />;
          }
        }
      }
    }
    let value;
    if (
      dataPresentationStatus === 'UPCOMING' &&
      (Object.keys(data).length === 0 || Object.keys(data).length === 0)
    ) {
      value = '';
    } else {
      value = tagsComponent?.includes(key) ? (
        <Tags label={determineValue(dataValue)} />
      ) : (
        determineValue(dataValue)
      );
    }
    return {
      label: headerKey[key as keyof typeof headerKey],
      value,
      color: differences.includes(key) ? color : 'inherit',
      upcoming,
      showTags: extraTags?.includes(key),
    };
  };

  return (
    <Box sx={style} className="section" key={layout.name}>
      {layout.title !== '' &&
        typeof layout.title === 'function' &&
        layout.title !== null &&
        layout.title()}
      {layout.subtitle !== '' &&
        typeof layout.subtitle === 'function' &&
        layout.subtitle()}
      {layout.title !== '' && typeof layout.title !== 'function' && (
        <Box
          className="list"
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            borderRadius: '10px',
            alignItems: 'center',
          }}
        >
          <Typography
            className="sub-title"
            variant="h6"
            color={palette.gray[90]}
            mb=".5rem"
          >
            {layout.title}
          </Typography>
        </Box>
      )}
      <Box className="grid-container">
        <Box>
          {Object.keys(layout.headerKey).map(val => {
            const { label, value, color, upcoming, showTags } = getLabelValue(
              val,
              layout.headerKey,
              layout.name,
              layout.data,
              layout.upcomingData,
              layout.tagsComponent,
              layout.addTagsForKeys
            );
            return (
              <Box className="list-container" key={label}>
                <Typography className="label">{label}</Typography>
                <Typography
                  variant="body14"
                  className="value"
                  color={color === 'inherit' ? palette.gray[100] : color}
                  component="div"
                  textTransform="capitalize"
                >
                  {typeof value === 'function' && value()}
                  {typeof value !== 'function' && value}
                  {showTags && value !== '-' && (
                    <Typography
                      variant="body12"
                      component="span"
                      color={palette.gray[90]}
                      sx={{
                        display: 'inline-block',
                        padding: '0px 8px 0px 8px',
                        borderRadius: '6px',
                        background: palette.gray[50],
                      }}
                    >
                      {val === 'joinDate' && calculateDateDiff(value)}
                      {val !== 'joinDate' &&
                        `${dayjs(new Date()).diff(value, 'year')} years ${
                          val === 'birthDate' ? 'old' : 'of operation'
                        }`}
                      {shouldFormatBirthToGen &&
                        ` • ${formatBirthToGen(value)}`}
                    </Typography>
                  )}
                  {dataPresentationStatus === 'UPCOMING' &&
                    typeof upcoming !== 'function' && (
                      <>
                        <Typography
                          variant="body14"
                          className="arrow"
                          color={palette.blue[50]}
                        >
                          {upcoming !== '' && (
                            <ArrowRight
                              width="11"
                              height="11"
                              viewBox="0 0 11 11"
                              sx={{
                                width: '11px',
                                height: '11px',
                                stroke: 'initial',
                                fill: palette.green[50],
                              }}
                            />
                          )}
                        </Typography>
                        <Typography
                          variant="body14"
                          className="value"
                          color={palette.green[50]}
                        >
                          {upcoming}
                        </Typography>
                      </>
                    )}
                </Typography>
              </Box>
            );
          })}
        </Box>
        {onClickLinked && <ButtonEmployeeLink />}
      </Box>
      {/* <UpcomingChanges reason="" /> */}
      {dataPresentationStatus === 'UPCOMING' && !layout.hideCancelUpcoming && (
        <UpcomingChanges
          handleCancel={onClickCancel}
          upcomingContainerWidth={upcomingContainerWidth}
        />
      )}
    </Box>
  );
};

export default GridLayout;
