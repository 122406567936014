import { Box, Grid, Typography, useTheme } from '@mui/material';
import { useState } from 'react';
import { useDetailAttendanceRequestQuery } from '../../../../store/feature/service/homeApiSlice';
import XClose from '../../../atom/icon/XClose';
import Check from '../../../atom/icon/Check';
import {
  formatUnixToDateTime,
  newformatDateTimeFromUnixUTC,
  formatSecondsToTimeString,
  formatSecondsToHour,
} from '../../../../utils/date';
import { Clock } from '../../../atom/icon';
import LinkExternal from '../../../atom/icon/LinkExternal';
import LabelTextModal from '../../../atom/labelText/LabelTextModal';
import Button from '../../../atom/button/Button';
import { useAppSelector } from '../../../../store';
import { Modal } from '../../../molecule';
import WorkflowItem from '../../../molecule/workflowItem/WorkflowItem';
import { useSubmitEssTaskApprovalMutation } from '../../../../store/feature/service/essTimeOffApiSlice';

const EssAttendanceRequestItem = ({
  data,
  handleClose,
}: {
  data: any;
  handleClose: () => void;
}) => {
  const { palette } = useTheme();
  const [statusCodeApproval, setStatusCodeApproval] = useState('');
  const { dataAccess } = useAppSelector(state => state.userAccess);
  const [submitEssTaskApproval, { isLoading }] =
    useSubmitEssTaskApprovalMutation();
  const { data: detailData } = useDetailAttendanceRequestQuery(data?.detailId);
  const sendApproval = async (statusCode: string) => {
    try {
      setStatusCodeApproval(statusCode);
      await submitEssTaskApproval({
        id: data?.id,
        payload: {
          statusCode,
          remarks: null,
          effectiveDate: null,
        },
      }).unwrap();
      handleClose();
    } catch (error) {
      console.log(error);
      handleClose();
    }
  };

  const SECONDS_TO_HOURS = 86400;
  const isMidnight = data.shiftStartTime > data.shiftEndTime;

  return !data ? (
    <div />
  ) : (
    <Modal
      fullHeight
      open
      onClose={handleClose}
      showCloseIcon
      title="Attendance Request"
      showButton
      leftButtonProps={{
        variant: 'outlined',
        isLoading: statusCodeApproval === 'REJECTED' && isLoading,
        disabled: isLoading,
        onClick: () => {
          sendApproval('REJECTED');
        },
      }}
      rightButtonProps={{
        variant: 'outlined',
        isLoading: statusCodeApproval === 'APPROVED' && isLoading,
        disabled: isLoading,
        onClick: () => {
          sendApproval('APPROVED');
        },
      }}
      leftButtonLabel={
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            gap: '8px',
            '& svg path': {
              stroke: palette.red[50],
            },
          }}
        >
          <XClose />
          Reject
        </Box>
      }
      rightButonLabel={
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            gap: '8px',
            '& svg path': {
              stroke: palette.green[50],
            },
          }}
        >
          <Check />
          Approve
        </Box>
      }
      icon={
        <Box
          sx={{
            background: palette.blue[20],
            height: '48px',
            width: '48px',
            borderRadius: '90px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            '& svg': {
              width: '20px',
              height: '20px',
            },
            '& svg path': {
              stroke: palette.blue[90],
              strokeWidth: '2',
            },
          }}
        >
          <Clock />
        </Box>
      }
    >
      <Grid container gap="16px" sx={{ overflow: 'auto' }}>
        <LabelTextModal
          label="ID"
          texts={[detailData?.attendanceRequestId || '']}
        />
        <LabelTextModal
          label="Employee"
          texts={[
            `(${detailData?.employeeNumber}) ${detailData?.employeeFullName}` ||
              '',
          ]}
        />
        <LabelTextModal
          label="Date"
          texts={[
            detailData?.date
              ? newformatDateTimeFromUnixUTC(detailData?.date)
              : '',
          ]}
        />

        <LabelTextModal
          label="Shift"
          texts={[
            `${detailData?.shiftCode}  (${formatSecondsToHour(
              detailData?.clockIn ?? 0
            )} - ${formatSecondsToHour(detailData?.clockOut ?? 0)}) ` || '',
          ]}
        />
        <LabelTextModal
          label="Clock In – Clock Out"
          texts={[
            `${formatSecondsToHour(
              detailData?.clockIn ?? 0
            )} - ${formatSecondsToHour(detailData?.clockOut ?? 0)} ` || '',
          ]}
        />
        <LabelTextModal
          label="Total Hour(s)"
          texts={[
            data
              ? formatSecondsToTimeString(
                  isMidnight
                    ? SECONDS_TO_HOURS -
                        (detailData?.shiftStartTime ?? 0) +
                        (detailData?.shiftEndTime ?? 0)
                    : (detailData?.shiftEndTime ?? 0) -
                        (detailData?.shiftStartTime ?? 0)
                )
              : '-',
          ]}
        />
        <LabelTextModal
          label="Attachment"
          textComponents={[
            data?.attachment?.fileName ? (
              <Button
                onClick={() =>
                  window.open(detailData?.attachment?.fileUrl, '_blank')
                }
                variant="link"
                sx={{ width: '320px' }}
              >
                <Typography
                  variant="body14"
                  fontWeight={600}
                  color={palette.blue[50]}
                  sx={{
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                  }}
                >
                  {detailData?.attachment?.fileName}
                </Typography>
                <LinkExternal />
              </Button>
            ) : (
              <div>-</div>
            ),
          ]}
        />
        <LabelTextModal label="Remarks" texts={[detailData?.remarks || '-']} />
        <Grid
          item
          sx={{ borderTop: `1px solid ${palette.gray[50]}`, width: '100%' }}
        />
        <Grid item>
          <Typography
            variant="body16"
            fontWeight={700}
            display="block"
            color={palette.gray[90]}
          >
            Workflow
          </Typography>
        </Grid>
        <WorkflowItem
          title={detailData?.employeeFullName}
          image={
            <img
              src={detailData?.profileURL}
              alt="employee profile"
              style={{ width: '40px', height: '40px', objectFit: 'cover' }}
            />
          }
          description={formatUnixToDateTime(detailData?.createdAt ?? 0)}
        />
      </Grid>
    </Modal>
  );
};
export default EssAttendanceRequestItem;
