const BookOpen02 = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.99998 13.3334H3.46665C2.71991 13.3334 2.34654 13.3334 2.06133 13.1881C1.81044 13.0603 1.60647 12.8563 1.47864 12.6054C1.33331 12.3202 1.33331 11.9468 1.33331 11.2001V4.80008C1.33331 4.05334 1.33331 3.67998 1.47864 3.39476C1.60647 3.14388 1.81044 2.9399 2.06133 2.81207C2.34654 2.66675 2.71991 2.66675 3.46665 2.66675H3.73331C5.22679 2.66675 5.97352 2.66675 6.54395 2.9574C7.04572 3.21306 7.45367 3.62101 7.70933 4.12277C7.99998 4.6932 7.99998 5.43994 7.99998 6.93341M7.99998 13.3334V6.93341M7.99998 13.3334H12.5333C13.28 13.3334 13.6534 13.3334 13.9386 13.1881C14.1895 13.0603 14.3935 12.8563 14.5213 12.6054C14.6666 12.3202 14.6666 11.9468 14.6666 11.2001V4.80008C14.6666 4.05334 14.6666 3.67998 14.5213 3.39476C14.3935 3.14388 14.1895 2.9399 13.9386 2.81207C13.6534 2.66675 13.28 2.66675 12.5333 2.66675H12.2666C10.7732 2.66675 10.0264 2.66675 9.45601 2.9574C8.95424 3.21306 8.54629 3.62101 8.29063 4.12277C7.99998 4.6932 7.99998 5.43994 7.99998 6.93341"
      stroke="#475667"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default BookOpen02;
