const personalInfoHeader = {
  effectiveDate: 'Effective Date',
  identityType: 'ID Type',
  identityNumber: 'ID No.',
  fullName: 'Full Name',
  gender: 'Gender',
  birthDate: 'Birth Date',
  religion: 'Religion',
  maritalStatus: 'Marital Status',
  bloodType: 'Blood Type',
  isDisability: 'Disability',
  disabilityType: 'Type of Disability',
  mobileNumber: 'Phone Number',
  email: 'Email',
  citizenship: 'Citizenship',
  emergencyName: 'Emergency Name',
  emergencyMobileNumber: 'Emergency Mobile No.',
  emergencyRelationshipType: 'Relationship',
};

const addressHeader = {
  effectiveDate: 'Effective Date',
  address: 'Address',
  country: 'Country',
  state: 'State',
  city: 'City',
  postalCode: 'Postal Code',
};

const employmentHeader = {
  effectiveDate: 'Effective Date',
  status: 'Status',
  workEmail: 'Work Email',
  legalEntity: 'Legal Entity',
  employeeNumber: 'Employee ID',
  employmentType: 'Type',
  joinDate: 'Join Date',
  permanent: 'Permanent?',
  endDate: 'End Date',
  probation: 'Probation?',
  organization: 'Organization',
  jobPosition: 'Job Position',
  jobGrade: 'Grade',
  businessTitle: 'Business Title',
};

const bankHeader = {
  effectiveDate: 'Effective Date',
  bankName: 'Name',
  accountNumber: 'Account No.',
  accountName: 'Account Name',
};

const ptkpHeader = {
  effectiveDate: 'Effective Date',
  name: 'Name',
  legalTaxNumber: 'Tax No.',
  ptkp: 'PTKP',
};

const socialSecurityHeader = {
  effectiveDate: 'Effective Date',
  name: 'Name',
  socialSecurityNumber: 'No.',
  socialSecurityActivationDate: 'Activation Date',
};

const healthCareHeader = {
  effectiveDate: 'Effective Date',
  name: 'Name',
  healthCareNumber: 'No.',
  healthCareActivationDate: 'Activation Date',
};

const bpjsHeader = {
  effectiveDate: 'Effective Date',
  name: 'Name',
  no: 'No.',
  activationDate: 'Activation Date',
};

const experienceHeader = {
  effectiveDate: 'Effective Date',
  position: 'Position',
  startDate: 'Start Date',
  endDate: 'End Date',
  lastSalary: 'Last Salary',
  reasonOfResignation: 'Reason of Resignation',
};

const educationHeader = {
  effectiveDate: 'Effective Date',
  institution: 'Institution',
  degree: 'Degree',
  fieldOfStudy: 'Field of Study',
  startDate: 'Start Date',
  endDate: 'End Date',
  documentation: 'Documentation',
};

const familyHeader = {
  effectiveDate: 'Effective Date',
  name: 'Name',
  identityNumber: 'ID No.',
  relationType: 'Relation Type',
  birthDate: 'Birth Date',
  gender: 'Gender',
  bloodType: 'Blood Type',
  isDependent: 'Dependent Status',
  isDeceased: 'Deceased',
};

const fileHeader = {
  effectiveDate: 'Effective Date',
  name: 'File Name',
  category: 'Category',
  attachment: 'Attachment',
};

const headerWorkLocation = {
  address: 'Address',
  city: 'City',
  code: 'Code',
  country: 'Country',
  countryPhoneCode: 'Country Phone Code',
  fax: 'Fax',
  id: 'ID',
  mobileNumber: 'Mobile Number',
  name: 'Name',
  postalCode: 'Postal Code',
  state: 'State',
  workLocation: 'Work Location',
};

const employeeID = '9410aad1-f677-4ba7-b073-47a9c4f2fa48';

export default {
  personalInfoHeader,
  addressHeader,
  employmentHeader,
  bankHeader,
  employeeID,
  ptkpHeader,
  socialSecurityHeader,
  healthCareHeader,
  bpjsHeader,
  experienceHeader,
  educationHeader,
  familyHeader,
  fileHeader,
  headerWorkLocation,
};
