import { createSlice } from '@reduxjs/toolkit';
import { essAccessControlApiSlice } from './service/essAccessControl';

type initialStateType = {
  messageSuccessDetail: string;
  messageSuccess: string;
  messageError: string;
};

const initialState: initialStateType = {
  messageSuccessDetail: '',
  messageSuccess: '',
  messageError: '',
};

const essAccessControlSlice = createSlice({
  name: 'essAccessControl',
  initialState,
  reducers: {
    removeMessage: state => {
      state.messageSuccess = '';
      state.messageSuccessDetail = '';
      state.messageError = '';
    },
  },
  extraReducers(builder) {
    builder.addMatcher(
      essAccessControlApiSlice.endpoints.createEssAccessControl.matchFulfilled,
      (state, _action) => {
        state.messageSuccess = 'ESS Access Control created';
      }
    );
    builder.addMatcher(
      essAccessControlApiSlice.endpoints.updateEssAccessControl.matchFulfilled,
      (state, _action) => {
        state.messageSuccessDetail = 'ESS Access Control updated';
      }
    );
  },
});

export const { removeMessage } = essAccessControlSlice.actions;
export const essAccessControlReducer = essAccessControlSlice.reducer;
