import React from 'react';
import { Grid } from '@mui/material';
import { familyHeaderKey } from '../../../../constants/employee';
import { Hearts } from '../../../../components/atom/icon';
import EmptyCard from '../../../../components/molecule/emptyCard/EmptyCard';
import FamilySection from './Familysection';
import { useGetESSFamilyListQuery } from '../../../../store/feature/service/essEmployee';

const Family = () => {
  const { data }: any = useGetESSFamilyListQuery({});

  return (
    <Grid container spacing={2}>
      <Grid item xs={6} container rowSpacing={3}>
        {data?.map((item: any, index: number) => (
          <FamilySection
            key={JSON.stringify(item)}
            index={index}
            headerKey={familyHeaderKey}
            groupId={item?.data?.old?.groupId || item?.data?.new?.groupId}
          />
        ))}
        {data && data?.length < 1 && (
          <EmptyCard icon={<Hearts />} label="Family" isESS />
        )}
      </Grid>
    </Grid>
  );
};

export default Family;
