import { Box, CircularProgress, Grid } from '@mui/material';

export const ProgressOverlay = () => (
  <Grid
    container
    spacing={0}
    direction="column"
    alignItems="center"
    justifyContent="center"
    position="absolute"
    zIndex="999999"
    bgcolor="hsla(213, 43%, 11%, .3)"
    sx={{
      height: '100vh',
      width: '100vw',
      pointerEvents: 'none',
      cursor: 'not-allowed',
    }}
    component="div"
  >
    <Box>
      <CircularProgress thickness={5} />
    </Box>
  </Grid>
);
