import { PayslipTemplateType } from '../../../@types/PayslipTemplateType';
import { apiSlice } from '../api/apiSlice';

export const payslipTemplateApiSlice = apiSlice.injectEndpoints({
  endpoints: builder => ({
    getListPayslipTemplate: builder.query<Array<PayslipTemplateType>, null>({
      query: () => ({
        url: '/payroll/payslip-templates',
      }),
      providesTags: ['PayslipTemplate'],
    }),
  }),
});

export const { useGetListPayslipTemplateQuery } = payslipTemplateApiSlice;
