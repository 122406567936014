import React from 'react';
import { Box, SxProps, Typography, useTheme } from '@mui/material';
import { GridViewContainerProps } from '../../../../../@types/GridViewLayoutProps';
import { GridLayout } from '../../../../molecule';
import EmptyCard from '../../../../molecule/emptyCard/EmptyCard';

const GridViewContainer = ({
  layout,
  type = '',
  CustomRightComponent,
  leftTitle = '',
  rightTitle = '',
  handleClickCancel,
  leftemptyState,
  rightemptyState,
  shouldFormatBirthToGen,
  upcomingContainerWidth,
  customBg,
}: GridViewContainerProps) => {
  const { palette } = useTheme();
  const style: SxProps = {
    display: 'flex',
    gap: '24px',
    '& .left-cont': {
      display: 'flex',
      flex: 1,
      gap: '24px',
      flexDirection: 'column',
    },
    '& .right-cont': {
      display: 'flex',
      flex: 1,
      gap: '24px',
      flexDirection: 'column',
    },
    '& .section:last-of-type': {
      mb: '0.5rem',
    },
    '& .MuiButtonBase-root': {
      justifyContent: 'flex-start',
    },
    '& .grid-title': {
      fontWeight: 700,
    },
    '& .title-container': {
      height: '28px',
    },
  };

  return (
    <Box sx={style}>
      {layout?.left?.length > 0 ? (
        <Box className="left-cont">
          {leftTitle !== '' && (
            <Box className="title-container">
              <Typography
                variant="body18"
                className="grid-title"
                color={palette.gray[90]}
              >
                {leftTitle}
              </Typography>
            </Box>
          )}
          {layout.left.map(leftSection => (
            <GridLayout
              customBg={customBg}
              key={leftSection.name}
              layout={leftSection}
              type={type}
              onClickLinked={leftSection?.onClickLinked}
              shouldFormatBirthToGen={shouldFormatBirthToGen}
              upcomingContainerWidth={upcomingContainerWidth}
              onClickCancel={handleClickCancel}
            />
          ))}
        </Box>
      ) : (
        leftemptyState
      )}
      {layout?.right?.length > 0 || CustomRightComponent ? (
        <Box className="right-cont">
          {rightTitle !== '' && (
            <Box className="title-container">
              <Typography
                variant="body18"
                className="grid-title"
                color={customBg || palette.gray[90]}
              >
                {rightTitle}
              </Typography>
            </Box>
          )}
          {layout?.right?.map(rightSection => (
            <GridLayout
              key={rightSection.name}
              layout={rightSection}
              type={type}
              onClickLinked={rightSection?.onClickLinked}
              onClickCancel={rightSection?.handleClickCancel}
              upcomingContainerWidth={upcomingContainerWidth}
            />
          ))}
          {CustomRightComponent}
        </Box>
      ) : (
        rightemptyState
      )}
    </Box>
  );
};

export default GridViewContainer;
