// import { Typography, List, ListItemButton } from '@mui/material';
import { Collapse, List } from '@mui/material';
import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { NavMenu } from '../../atom';
import { RouteProps, RouteNode } from '../../../@types/RouteProps';
import { useAppDispatch, useAppSelector } from '../../../store';
import { closeSideBar } from '../../../store/feature/responsiveSlice';

const TreeList: React.FC<RouteProps> = ({ data, sx }) => {
  const { dataAccess } = useAppSelector(state => state.userAccess);
  const navigate = useNavigate();
  const location = useLocation();
  const [expanded, setExpanded] = useState<string[]>(
    location.pathname.split('/')
  );

  const sidebarDictionary: { [key: string]: Array<string> } = {
    Home: ['home'],
    Employee: ['employee'],
    Payroll: ['run_payroll'],
    'Run Payroll': ['run_payroll'],
    Setup: ['pay_group', 'pay_item'],
    Claim: ['claim_type', 'claim'],
    'Claim Type': ['claim_type'],
    'Pay Group': ['pay_group'],
    'Pay Item': ['pay_item'],
    Accumulator: ['accumulator'],
    'Bank File': ['bank_file'],
    'Exchange Rate': ['exchange_rate'],
    Formula: ['formula'],
    'Payslip Template': ['payslip_template'],
    Statutory: ['statutory'],
    'Value Rounding': ['value_rounding'],
    Time: [
      'analytics',
      'work_schedule',
      'attendance_process',
      'daily_attendance',
      'overtime_request',
      'time_off_request',
      'holiday',
      'job_site',
      'overtime',
      'shift',
      'schedule',
      'time_off',
      'attendance_machine',
      'attendance_status',
      'overtime_item',
      'policy',
      'time_event',
      'time_item',
      'time_off_item',
    ],
    Analytics: ['analytics'],
    'Work Schedule': ['work_schedule'],
    Attendance: ['attendance_process', 'daily_attendance'],
    'Attendance Process': ['attendance_process'],
    'Daily Attendance': ['daily_attendance'],
    Request: ['overtime_request'],
    Holiday: ['holiday'],
    'Job Site': ['job_site'],
    Shift: ['shift'],
    Schedule: ['schedule'],
    Configuration: [
      'attendance_machine',
      'attendance_status',
      'overtime_item',
      'policy',
      'time_event',
      'time_item',
      'time_off_item',
    ],
    'Attendance Machine': ['attendance_machine'],
    'Attendance Status': ['attendance_status'],
    'Overtime Item': ['overtime_item'],
    Policy: ['policy'],
    'Time Event': ['time_event'],
    'Time Item': ['time_item'],
    'Time Off Item': ['time_off_item'],
    Overtime: ['overtime_request'],
    'Time Off': ['time_off_request'],
    Organization: ['legal_entity', 'job_grade'],
    'Legal Entity': ['legal_entity'],
    'Organization Structure': ['organization_structure'],
    'Job Grade': ['job_grade'],
    'Job Level': ['job_level'],
    'Job Position': ['job_position'],
    'Organization Unit': ['organization_unit'],
    'Work Location': ['work_location'],
    File: ['file'],
    'Master Data': ['bank', 'cost_center', 'employment_type', 'tax_location'],
    Bank: ['bank'],
    'Cost Center': ['cost_center'],
    'Employment Type': ['employment_type'],
    'Tax Location': ['tax_location'],
    Accounting: ['chart_of_account'],
    'Chart of Accounts': ['chart_of_account'],
    Report: ['standard_report'],
    'Standard Report': ['standard_report'],
    Import: ['import'],
    Export: ['export'],
    'Custom Fields': ['custom_field'],
    'User Interface': ['user_interface'],
    Security: ['user_access', 'access_control', 'ess_access_control'],
    'User Access': ['user_access'],
    'Access Control': ['access_control'],
    'ESS Access Control': ['ess_access_control'],
  };

  const checkAccess = (label: string) => {
    let viewable = false;
    for (let index = 0; index < sidebarDictionary[label]?.length; index += 1) {
      const element = sidebarDictionary[label][index];
      if (dataAccess[element]?.indexOf('VIEW') > -1) {
        viewable = true;
        break;
      }
    }
    return viewable;
  };

  const dispatch = useAppDispatch();
  const handleClick = (path: string, isParent: boolean, goto: string) => {
    if (!isParent) {
      // navigate(goto === '/' ? '/' : `/${goto}`);
      setExpanded(goto.split('/'));
      dispatch(closeSideBar());
    } else if (expanded.includes(path)) {
      const ex = expanded.filter(item => item !== path);
      setExpanded(ex);
    } else {
      // doing this so when user click non navigate dropdown, other open dropdown not automaticly closed
      setExpanded([...goto.split('/'), ...expanded]);
    }
  };

  const check = (node: any) => {
    const isExist = location.pathname
      .split('/')
      .slice(1, 4)
      .join('/')
      .includes(node.path === '/' ? '--|--|--|--' : node.path);

    const indexOfString = location.pathname
      .split('/')
      .slice(1, 4)
      .indexOf(node.path === '/' ? '--|--|--|--' : node.path);

    const isTheSameLength =
      node.path.length ===
      location.pathname.split('/')[indexOfString + 1]?.length;

    return isExist && isTheSameLength;
  };

  const renderList = (nodes: RouteNode[], num: number, path: string[]) => (
    <List
      component="nav"
      sx={{
        padding: 0,
        display: 'flex',
        flexDirection: 'column',
        gap: '4px',
        ...sx,
      }}
    >
      {nodes
        .filter(node => node.label !== 'child')
        .map(node => {
          const { icon: ComponentIcon } = node;
          return (
            <React.Fragment key={node.path}>
              {node.label && checkAccess(node.label) && (
                <NavMenu
                  onClick={() =>
                    handleClick(
                      node.path,
                      node.child !== null,
                      `${[...path, node.path].join('/')}`
                    )
                  }
                  path={`${[...path, node.path].join('/')}`}
                  label={node.label}
                  isActive={
                    node.path === '/' && location.pathname === '/'
                      ? true
                      : check(node)
                  }
                  depth={num}
                  icon={ComponentIcon ? <ComponentIcon /> : null}
                  isParent={node.child !== null}
                  isExpended={expanded.includes(node.path)}
                />
              )}
              {node.child && (
                <Collapse
                  in={expanded.includes(node.path)}
                  timeout="auto"
                  unmountOnExit
                  // onExited={() => {}}
                >
                  {renderList(node.child, num + 1, [...path, node.path])}
                </Collapse>
              )}
            </React.Fragment>
          );
        })}
    </List>
  );
  return renderList(data, 0, []);
};

export default TreeList;
