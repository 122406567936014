import {
  Paper,
  SxProps,
  Table as Tb,
  TableContainer,
  useTheme,
  Box,
  Typography,
} from '@mui/material';
import React from 'react';

import { TableBody, TableHead, TableBodyLoading } from '../../atom';
import { TableListProps } from '../../../@types/TableProps';

function Table(
  Props: Omit<
    TableListProps,
    | 'setPage'
    | 'excludeSelectedColumn'
    | 'showShadow'
    | 'handleSort'
    | 'showRightShadow'
  >
) {
  const {
    sortBy,
    sortType,
    headerKey,
    rowData,
    setSort,
    showSort = false,
    stickyLeft = false,
    stickyRight = false,
    activeDescription = false,
    onDescriptionClick,
    excludeSortTable,
    page,
    alterTableHeaderName = {},
    isLoading,
    listCheckedRow,
    showCheckbox,
    handleUnselectAll,
    isAllChecked,
    handleSelectAllCheckbox,
    listUncheckedRow,
    handleClickUncheckRow,
    onClickRowCheckbox,
    limit,
    customFirstCellHeaderWidth,
    showIdx,
    showBorderTableContainer,
    styleTableContainer,
  } = Props;
  const { palette } = useTheme();
  const [showShadow, setShowShadow] = React.useState(false);
  const [showRightShadow, setShowRightShadow] = React.useState(false);

  const style: SxProps = {
    border: 0,
    '&.MuiTableContainer-root': {
      border: showBorderTableContainer ? `1px solid ${palette.gray[40]}` : 0,
      borderRadius: '8px',
    },
    '& .MuiTableCell-root': {
      border: 0,
      boxSizing: 'border-box',
      padding: 0,
    },
    '& .MuiTableHead-root .MuiTableRow-root': {
      background: palette.gray[20],
    },
    '& .MuiTableBody-root': {
      '.MuiTableRow-root': {
        '&:nth-of-type(even)': {
          background: palette.gray[10],
        },
      },
    },
    '& .sticky': {
      position: 'sticky',
      left: 0,
      zIndex: 9,
    },
    '& .sticky-right': {
      position: 'sticky',
      right: 0,
      zIndex: 9,
    },
    ...styleTableContainer,
  };

  const handleSort = (label: string) => {
    setSort((prev: any) => {
      if (prev.sortBy === label) {
        if (prev.sortType === 'asc') {
          return {
            sortBy: label,
            sortType: 'desc',
          };
        }
        if (prev.sortType === 'desc') {
          return {
            sortBy: label,
            sortType: 'asc',
          };
        }
      }
      return {
        sortBy: label,
        sortType: 'asc',
      };
    });
  };

  const handleScroll = (e: any) => {
    const { target } = e;
    const maxScrollLeft = target.scrollWidth - target.clientWidth;
    if (target.scrollLeft && !showShadow) {
      setShowShadow(true);
    }

    if (target.scrollLeft === 0 && showShadow) {
      setShowShadow(false);
    }

    if (target.scrollLeft < maxScrollLeft && !showRightShadow) {
      setShowRightShadow(true);
    }

    if (target.scrollLeft >= maxScrollLeft && showRightShadow) {
      setShowRightShadow(false);
    }
  };

  return (
    <Box>
      <TableContainer component={Paper} sx={style} onScroll={handleScroll}>
        <Tb size="small">
          <TableHead
            headerKey={headerKey}
            showShadow={showShadow}
            showRightShadow={showRightShadow}
            showSort={showSort}
            sortBy={sortBy}
            sortType={sortType}
            handleSort={handleSort}
            stickyLeft={rowData.length < 1 ? false : stickyLeft}
            stickyRight={rowData.length < 1 ? false : stickyRight}
            excludeSortTable={excludeSortTable}
            alterTableHeaderName={alterTableHeaderName}
            listCheckedRow={listCheckedRow}
            showCheckbox={showCheckbox}
            handleUnselectAll={handleUnselectAll}
            handleSelectAllCheckbox={handleSelectAllCheckbox}
            isAllChecked={isAllChecked}
            listUncheckedRow={listUncheckedRow}
            customFirstCellHeaderWidth={customFirstCellHeaderWidth}
            showIdx={showIdx}
          />
          {!isLoading && (
            <TableBody
              headerKey={headerKey}
              rowData={rowData}
              showShadow={showShadow}
              showRightShadow={showRightShadow}
              stickyLeft={rowData.length < 1 ? false : stickyLeft}
              stickyRight={rowData.length < 1 ? false : stickyRight}
              activeDescription={activeDescription}
              onDescriptionClick={onDescriptionClick}
              page={page}
              listCheckedRow={listCheckedRow}
              showCheckbox={showCheckbox}
              isAllChecked={isAllChecked}
              listUncheckedRow={listUncheckedRow}
              handleClickUncheckRow={handleClickUncheckRow}
              onClickRowCheckbox={onClickRowCheckbox}
              limit={limit}
              showIdx={showIdx}
            />
          )}
          {isLoading && (
            <TableBodyLoading
              headerKey={headerKey}
              showShadow={showShadow}
              showRightShadow={showRightShadow}
              stickyLeft={rowData.length < 1 ? false : stickyLeft}
              stickyRight={rowData.length < 1 ? false : stickyRight}
              rowData={[]}
              page={0}
            />
          )}
        </Tb>
      </TableContainer>
      {!isLoading && rowData.length < 1 && (
        <Box
          sx={{
            width: '100%',
            height: '96px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Typography variant="body14" color={palette.gray[80]}>
            No data available
          </Typography>
        </Box>
      )}
    </Box>
  );
}

export default Table;
