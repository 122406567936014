import { createSlice } from '@reduxjs/toolkit';
import { essTimeOffApiSlice } from './service/essTimeOffApiSlice';

interface initialStateI {
  messageSuccess: string;
  messageError: string;
}

const initialState: initialStateI = {
  messageSuccess: '',
  messageError: '',
};

const essTimeOffSlice = createSlice({
  name: 'essTimeOff',
  initialState,
  reducers: {
    removeMessage: state => {
      state.messageSuccess = '';
      state.messageError = '';
    },
  },
  extraReducers(builder) {
    builder.addMatcher(
      essTimeOffApiSlice.endpoints.essTimeOffWithdraw.matchFulfilled,
      state => {
        state.messageSuccess = 'Request withdrawn.';
      }
    );
    builder.addMatcher(
      essTimeOffApiSlice.endpoints.essTimeOffWithdraw.matchRejected,
      (state: any, action: any) => {
        state.messageError =
          action?.payload?.data?.message || 'Internal Server Error';
      }
    );
    builder.addMatcher(
      essTimeOffApiSlice.endpoints.submitEssTaskApproval.matchFulfilled,
      (state: initialStateI, action: any) => {
        if (action?.payload?.statusCode === 'APPROVED') {
          state.messageSuccess = 'Request approved';
        } else {
          state.messageSuccess = 'Request rejected';
        }
      }
    );
    builder.addMatcher(
      essTimeOffApiSlice.endpoints.submitEssTaskApproval.matchRejected,
      (state: initialStateI, action: any) => {
        const payload = action.payload as any;
        if (payload.data?.errors?.statusCode?.[0] === 'ACTION-ALREADY-TAKEN') {
          state.messageError =
            'Another approver has taken action on this request.';
        } else {
          state.messageError = payload.data.message;
        }
      }
    );
  },
});

export const { removeMessage } = essTimeOffSlice.actions;
export const essTimeOffReducer = essTimeOffSlice.reducer;
