import { Box, useTheme } from '@mui/material';
import { FileType } from '../../../@types/FileType';
import { download } from '../../../utils/file';
import Modal from './Modal';

type ModalFilePreviewProps = {
  isOpen?: boolean;
  item: FileType | null;
  setModalState: Function;
  handleLeftButton?: Function;
  handleDownload?: Function;
};

const ModalFilePreview = ({
  isOpen = false,
  item,
  setModalState,
  handleLeftButton,
  handleDownload,
}: ModalFilePreviewProps) => {
  const { palette } = useTheme();
  return (
    <Modal
      open={isOpen}
      leftButtonLabel="back"
      rightButonLabel="Download"
      rightButtonProps={{
        variant: 'primary',
        destructive: false,
        onClick: async () => {
          handleDownload?.();
        },
      }}
      leftButtonProps={{
        variant: 'outlined',
        // sx: { display: 'none' },
        onClick: () => setModalState?.(),
      }}
      // type="left"
      showCloseIcon
      // title="File Preview"
      // caption="Back"
      onClose={() => {
        handleLeftButton?.();
        // setModalState(null);
      }}
      icon={<div />}
    >
      <Box
        sx={{
          height: '500px',
          bgcolor: palette.gray[20],
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <img
          key={item?.fileUrl || ''}
          src={item?.fileUrl || ''}
          alt={item?.fileName || ''}
          style={{ width: '100%' }}
        />
      </Box>
    </Modal>
  );
};

export default ModalFilePreview;
