import { CustomFieldsStatus } from '../@types/CustomFields';

const headerKey = {
  name: 'Name',
  code: 'Code',
  description: 'Description',
};

const options = {
  basedOn: [
    { label: 'Employment type', value: 'EMPLOYMENT_TYPE' },
    { label: 'Job Position', value: 'JOB_POSITION' },
    { label: 'Legal Entity', value: 'LEGAL_ENTITY' },
    { label: 'Organization', value: 'ORGANIZATION' },
    { label: 'Work Location', value: 'WORK_LOCATION' },
  ],
};

const defaultCreatStepFieldCustomization = {
  name: '',
  fieldType: '',
  values: '',
  required: false,
  placeholder: '',
  helperText: '',
  applyTo: 'ALL',
};

const defaultValueCreate = {
  name: null,
  code: null,
  basedOn: null,
  employmentType: null,
  jobPosition: null,
  letalEntity: null,
  organization: null,
  workLocation: null,
  description: null,
  effectiveDate: null,
};

const detailKey = {
  effectiveDate: 'Effective Date',
  name: 'Name',
  code: 'Code',
  basedOn: 'Based on',
  // employmentType: 'Employment Type',
  // jobPosition: 'Job Position',
  // letalEntity: 'Legal Entity',
  // organization: 'Organization',
  // workLocation: 'Work Location',
  description: 'Descripition',
};

export default {
  defaultValueCreate,
  headerKey,
  options,
  defaultCreatStepFieldCustomization,
  detailKey,
};
