import {
  CreateEmployeeFormType,
  EmployeeCreateMenu,
} from '../@types/EmployeeTypes';
import { generateRandomUUID } from '../utils/uuid';

const stepperCreateEmployee = [
  'Personal Data',
  'Employment',
] as EmployeeCreateMenu[];

const listVariant = ['Personal Data', 'Employment'] as EmployeeCreateMenu[];

const stepperStep = {
  personal: 0,
  employment: 1,
};

const defaultValueAddress = {
  addressId: '',
  addressTypeCode: null,
  address: '',
  country: null,
  state: null,
  city: null,
  postalCode: '',
  isDeleted: false,
};

const defaultValueAddressForm = {
  addressForms: [
    {
      ...defaultValueAddress,
      addressId: generateRandomUUID(),
    },
  ],
};

const defaultValueCreateEmployee: CreateEmployeeFormType = {
  profileUrl: '',
  fullName: '',
  identityType: null,
  identityNumber: '',
  gender: null,
  birthDate: '',
  religion: null,
  maritalStatus: null,
  bloodType: null,
  isDisability: false,
  disabilityType: null,
  mobileNumber: '',
  countryCodeNumber: null,
  email: '',
  citizenship: null,
  emergencyName: '',
  emergencyMobileNumber: '',
  emergencyCountryCodeNumber: null,
  emergencyRelationshipType: null,
  ...defaultValueAddressForm,
  workEmail: '',
  legalEntity: null,
  employeeNumber: '',
  employmentType: null,
  joinDate: '',
  endDate: '',
  endDateReminder: '',
  isPermanent: false,
  isProbation: false,
  probationDuration: '',
  probationDurationType: null,
  probationReminder: '',
  organization: null,
  jobPosition: null,
  jobGrade: null,
  title: null,
  birthPlace: null,
  businessTitle: '',
  superior: null,
  reportTo: null,
  effectiveDate: '',
};
const defaultValueUpdateEmployment = {
  workEmail: '',
  legalEntity: null,
  employeeNumber: '',
  employmentType: null,
  joinDate: null,
  endDate: null,
  endDateReminder: null,
  isPermanent: false,
  isProbation: false,
  probationDuration: '',
  probationDurationType: null,
  probationReminder: '',
  organization: null,
  jobPosition: null,
  jobGrade: null,
  businessTitle: '',
  effectiveDate: null,
  reason: '',
};

export const defaultValueUpdatePersonal = {
  title: null,
  fullName: '',
  identityType: null,
  identityNumber: '',
  gender: null,
  birthPlace: '',
  birthDate: null,
  religion: null,
  maritalStatus: null,
  bloodType: null,
  isDisability: false,
  disabilityType: null,
  countryCodeNumber: null,
  mobileNumber: '',
  email: '',
  citizenship: null,
  emergencyName: '',
  emergencyCountryCodeNumber: null,
  emergencyMobileNumber: '',
  emergencyRelationshipType: null,
  effectiveDate: null,
  reason: '',
};
const defaultValueExperienceForm = {
  companyName: '',
  position: '',
  startDate: null,
  endDate: null,
  reasonOfResignation: '',
  effectiveDate: '',
};
const defaultValueFamilyForm = {
  name: '',
  identityNumber: '',
  relationType: null,
  gender: null,
  bloodType: null,
  isDependent: false,
  isDeceased: false,
  effectiveDate: '',
  birthDate: null,
};

export const personalData: { [key: string]: string } = {
  effectiveDate: 'Effective Date',
  identityType: 'ID Type',
  identityNumber: 'ID No.',
  title: 'Title',
  fullName: 'Full Name',
  gender: 'Gender',
  birthPlace: 'Birth Place',
  birthDate: 'Birth Date',
  religion: 'Religion',
  maritalStatus: 'Marital Status',
  bloodType: 'Blood Type',
  isDisability: 'Disability',
  disabilityType: 'Type of Disability',
  mobileNumber: 'Mobile No.',
  email: 'Email',
  citizenship: 'Citizenship',
  emergencyName: 'Emergency Name',
  emergencyMobileNumber: 'Emergency Mobile No.',
  emergencyRelationshipType: 'Relationship',
};

export const address = {
  effectiveDate: 'Effective Date',
  addressType: 'Address Type',
  address: 'Address',
  country: 'Country',
  state: 'State',
  city: 'City',
  postalCode: 'Postal Code',
};
export const familyHeaderKey = {
  effectiveDate: 'Effective Date',
  name: 'Name',
  identityNumber: 'ID No.',
  relationType: 'Relation Type',
  birthDate: 'Birth Date',
  gender: 'Gender',
  bloodType: 'Blood Type',
  isDependent: 'Dependent Status',
  isDeceased: 'Deceased',
};

export const statutoryHeaderKey: { [key: string]: {} } = {
  TAX: {
    effectiveDate: 'Effective Date',
    country: 'Country',
    statutoryType: 'Name',
    legalTaxNumber: 'Employee NPWP',
    ptkp: 'PTKP',
    categoryCode: 'Category',
    taxObject: 'Tax Object Code',
    taxLocation: 'Tax Location',
  },
  HEALTH_CARE: {
    effectiveDate: 'Effective Date',
    statutoryType: 'Name',
    healthCareNumber: 'No',
    healthCareActivationDate: 'Activation Date',
    additionalMember: 'Additional Member',
  },
  SOCIAL_SECURITY: {
    effectiveDate: 'Effective Date',
    statutoryType: 'Name',
    socialSecurityNumber: 'No',
    socialSecurityActivationDate: 'Activation Date',
  },
};

export default {
  TABS: {
    PERSONAL: 0,
    EMPLOYMENT: 1,
    BANK: 2,
    STATUTORY: 3,
    EXPERIENCE: 4,
    EDUCATION: 5,
    FAMILY: 6,
    FILES: 7,
    HISTORY: 8,
  },
  personalData,
  address,
  stepperCreateEmployee,
  listVariant,
  stepperStep,
  defaultValueAddress,
  defaultValueAddressForm,
  defaultValueCreateEmployee,
  defaultValueUpdateEmployment,
  defaultValueExperienceForm,
  defaultValueFamilyForm,
};

export const earnings = {
  name: 'Name',
  code: 'Code',
  currencyCode: '',
  amount: 'Amount',
  taxCalculationName: 'Tax Calculation',
  payFrequencyName: 'Pay Frequency',
  prorationName: 'Proration',
  action: 'Action',
};

export const deductions = {
  name: 'Name',
  code: 'Code',
  currencyCode: '',
  amount: 'Amount',
  taxCalculationName: 'Tax Calculation',
  payFrequencyName: 'Pay Frequency',
  prorationName: 'Proration',
  action: 'Action',
};

export const costCenterHeaderKey: { [key: string]: string } = {
  effectiveDate: 'Effective Date',
  name: 'Name',
  code: 'Code',
  description: 'Description',
};

export const bankHeaderKey: { [key: string]: string } = {
  effectiveDate: 'Effective Date',
  bank: 'Name',
  accountNumber: 'Account No.',
  accountName: 'Account Name',
};

export const employmentHeaderKey: { [key: string]: string } = {
  effectiveDate: 'Effective Date',
  status: 'Status',
  workEmail: 'Work Email',
  legalEntity: 'Legal Entity',
  employeeNumber: 'Employee ID',
  employmentType: 'Employment Type',
  joinDate: 'Join Date',
  isPermanent: 'Set as Permanent',
  endDate: 'End Date',
  endDateReminder: 'Reminder of End Date',
  isProbation: 'Set Probation',
  probationDuration: 'Duration of Probation',
  probationReminder: 'Reminder of Probation',
  organization: 'Organization',
  jobPosition: 'Job Position',
  jobGrade: 'Grade',
  businessTitle: 'Business Title',
  superior: 'Superior',
  subordinates: 'Subordinates',
  reportTo: 'Report To',
  directReports: 'Direct Reports',
};

export const workLocationHeaderKey: { [key: string]: string } = {
  effectiveDate: 'Effective Date',
  name: 'Name',
  code: 'Code',
  address: 'Address',
  country: 'Country',
  state: 'State',
  city: 'City',
  postalCode: 'Postal Code',
  mobileNumber: 'Mobile No.',
  fax: 'Fax',
};

export const educationHeaderKey = {
  effectiveDate: 'Effective Date',
  institution: 'Institution',
  degree: 'Degree',
  fieldOfStudy: 'Field of Study',
  startDate: 'Start Date',
  endDate: 'End Date',
  fileName: 'Document',
};

export const filesHeaderKey: { [key: string]: string } = {
  effectiveDate: 'Effective Date',
  name: 'File Name',
  category: 'Category',
  fileName: 'Attachment',
};
