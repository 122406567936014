import { apiSlice } from '../api/apiSlice';
import {
  DynamicGroupProps,
  ListResponse,
  DetailDynamicGroupProps,
} from '../../../@types/DynamicGroup';

type argProp = { id: string; body?: DetailDynamicGroupProps };

export const dynamicGroupApiSlice = apiSlice.injectEndpoints({
  endpoints: builder => ({
    getListDynamicGroup: builder.query<ListResponse<DynamicGroupProps>, any>({
      query: args => {
        const { search, page, sortType, sortBy, searchString } = args;
        return {
          url: `/security/dynamic-groups`,
          params: { search, page, sortType, sortBy, ...searchString },
        };
      },
      providesTags: ['dynamicGroupList'],
    }),
    getDetailDynamicGroup: builder.query<DetailDynamicGroupProps, any>({
      query: (args: argProp) => {
        const { id } = args;
        return {
          url: `/security/dynamic-groups/${id}`,
        };
      },
      providesTags: ['dynamicGroupDetail'],
    }),
    createDynamicGroup: builder.mutation<DetailDynamicGroupProps, any>({
      query: (args: argProp) => {
        const { body } = args;
        return {
          method: 'POST',
          url: '/security/dynamic-groups',
          body,
        };
      },
      invalidatesTags: ['dynamicGroupList'],
    }),
    deleteDynamicGroup: builder.mutation<any, any>({
      query: args => {
        const { id } = args;
        return {
          url: `/security/dynamic-groups/${id}`,
          method: 'DELETE',
        };
      },
      invalidatesTags: ['dynamicGroupDetail', 'dynamicGroupList'],
    }),
    updateDynamicGroup: builder.mutation<DetailDynamicGroupProps, any>({
      query: (args: argProp) => {
        const { body, id } = args;
        return {
          method: 'PUT',
          url: `/security/dynamic-groups/${id}`,
          body,
        };
      },
      invalidatesTags: ['dynamicGroupDetail'],
    }),
  }),
});

export const {
  useGetListDynamicGroupQuery,
  useGetDetailDynamicGroupQuery,
  useCreateDynamicGroupMutation,
  useDeleteDynamicGroupMutation,
  useUpdateDynamicGroupMutation,
} = dynamicGroupApiSlice;
