import { useTheme, useMediaQuery, Drawer } from '@mui/material';
import { RouteProps } from '../../../@types/RouteProps';
import { TreeList } from '../../molecule';
import { SidebarContainer } from '../../template';
import { useAppDispatch, useAppSelector } from '../../../store';
import {
  openSideBar,
  closeSideBar,
} from '../../../store/feature/responsiveSlice';

const Sidebar = ({ data }: RouteProps) => {
  const { palette } = useTheme();
  const { isOpenSideBar } = useAppSelector(
    (state: { responsive: any }) => state.responsive
  );
  const matches = useMediaQuery('(max-width:1023px)');
  const dispatch = useAppDispatch();
  if (matches) {
    return (
      <Drawer
        sx={{ padding: '5rem' }}
        anchor="left"
        open={isOpenSideBar}
        onClose={() => dispatch(closeSideBar())}
      >
        <SidebarContainer>
          <TreeList data={data} />
        </SidebarContainer>
      </Drawer>
    );
  }
  return (
    <SidebarContainer>
      <TreeList data={data} />
    </SidebarContainer>
  );
};

export default Sidebar;
