import { createSlice } from '@reduxjs/toolkit';
import { workScheduleApiSlice } from './service/workScheduleApiSlice';

interface initialStateI {
  messageSuccess: string;
  messageError: string;
}

const initialState: initialStateI = {
  messageSuccess: '',
  messageError: '',
};

const workScheduleSlice = createSlice({
  name: 'workSchedule',
  initialState,
  reducers: {
    removeMessage: state => {
      state.messageSuccess = '';
      state.messageError = '';
    },
  },
  extraReducers(builder) {
    builder.addMatcher(
      workScheduleApiSlice.endpoints.assignScheduleEmployee.matchFulfilled,
      (state, _action) => {
        state.messageSuccess = 'Schedule assigned';
      }
    );
    builder.addMatcher(
      workScheduleApiSlice.endpoints.assignScheduleEmployee.matchRejected,
      (state, action) => {
        const payload = action.payload as any;
        state.messageError = payload?.data?.message || 'Internal Server Error';
      }
    );
    builder.addMatcher(
      workScheduleApiSlice.endpoints.deleteShiftWorkSchedule.matchFulfilled,
      (state, _action) => {
        state.messageSuccess = 'Shift deleted';
      }
    );
    builder.addMatcher(
      workScheduleApiSlice.endpoints.deleteShiftWorkSchedule.matchRejected,
      (state, action) => {
        const payload = action.payload as any;
        state.messageError = payload?.data?.message || 'Internal Server Error';
      }
    );
    builder.addMatcher(
      workScheduleApiSlice.endpoints.addShiftWorkSchedule.matchFulfilled,
      (state, _action) => {
        state.messageSuccess = 'Shift added';
      }
    );
    builder.addMatcher(
      workScheduleApiSlice.endpoints.addShiftWorkSchedule.matchRejected,
      (state, action) => {
        const payload = action.payload as any;
        state.messageError = payload?.data?.message || 'Internal Server Error';
      }
    );
  },
});

export const { removeMessage } = workScheduleSlice.actions;
export const workScheduleReducer = workScheduleSlice.reducer;
