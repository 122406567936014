import { Box, SxProps, Typography, useTheme } from '@mui/material';
import { Button } from '../../atom';
import { AlertTriangle } from '../../atom/icon';

type UpcomingChangesProps = {
  handleCancel?: Function;
  upcomingContainerWidth?: string;
};

const UpcomingChanges = ({
  handleCancel,
  upcomingContainerWidth,
}: UpcomingChangesProps) => {
  const { palette } = useTheme();
  const style: SxProps = {
    display: 'flex',
    flexDirection: 'column',
    gap: '1.5rem',
    mt: '2rem',
    '& .cancel-alert-box-container': {
      px: '1rem',
      py: '14px',
      display: 'flex',
      gap: '12px',
      alignItems: 'center',
      backgroundColor: palette.orange[20],
      borderRadius: '0.5rem',
    },
    '& .cancel-alert-box-icon-container': {
      width: '20px',
      height: '20px',
    },
    '& .cancel-alert-box-icon': {
      fontSize: '20px',
      color: palette.orange[50],
      width: '20px',
      height: '20px',
    },
    '& .cancel-alert-box-message-container': {
      flex: 1,
    },
    '& .cancel-alert-box-message': {
      fontWeight: 400,
    },
    '& .upcoming-changes-container': {
      p: '1rem',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      backgroundColor: palette.gray[10],
      borderRadius: '0.5rem',
    },
    '& .upcoming-changes-section': {
      display: 'flex',
      flexDirection: 'column',
      gap: '0.5rem',
    },
    '& .upcoming-changes-title': {
      fontWeight: 600,
    },
    '& .upcoming-changes-reason': {
      fontWeight: 400,
    },
  };

  return (
    <Box sx={style}>
      <Box
        className="upcoming-changes-container"
        width={upcomingContainerWidth}
      >
        <Box className="upcoming-changes-section">
          <Typography fontWeight="800">Upcoming Changes</Typography>
          <Typography
            variant="body14"
            className="upcoming-changes-reason"
            color={palette.gray[90]}
          >
            Cancel upcoming changes before updating.
          </Typography>
        </Box>
        <Box>
          <Button variant="link" destructive onClick={() => handleCancel?.()}>
            Cancel Update
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default UpcomingChanges;
