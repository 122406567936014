const Gift01 = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
  >
    <g clipPath="url(#clip0_29700_1189)">
      <path
        d="M10.0003 5.00002V18.3334M10.0003 5.00002H7.0539C6.61976 5.00002 6.20341 4.82443 5.89643 4.51187C5.58945 4.1993 5.41699 3.77538 5.41699 3.33335C5.41699 2.89133 5.58945 2.4674 5.89643 2.15484C6.20341 1.84228 6.61976 1.66669 7.0539 1.66669C9.34556 1.66669 10.0003 5.00002 10.0003 5.00002ZM10.0003 5.00002H12.9468C13.3809 5.00002 13.7972 4.82443 14.1042 4.51187C14.4112 4.1993 14.5837 3.77538 14.5837 3.33335C14.5837 2.89133 14.4112 2.4674 14.1042 2.15484C13.7972 1.84228 13.3809 1.66669 12.9468 1.66669C10.6551 1.66669 10.0003 5.00002 10.0003 5.00002ZM16.667 9.16669V15.6667C16.667 16.6001 16.667 17.0668 16.4853 17.4233C16.3255 17.7369 16.0706 17.9919 15.757 18.1517C15.4005 18.3334 14.9337 18.3334 14.0003 18.3334L6.00033 18.3334C5.0669 18.3334 4.60019 18.3334 4.24367 18.1517C3.93007 17.9919 3.6751 17.7369 3.51531 17.4233C3.33366 17.0668 3.33366 16.6001 3.33366 15.6667V9.16669M1.66699 6.33335L1.66699 7.83335C1.66699 8.30006 1.66699 8.53342 1.75782 8.71168C1.83771 8.86848 1.9652 8.99596 2.122 9.07586C2.30026 9.16669 2.53362 9.16669 3.00033 9.16669L17.0003 9.16669C17.467 9.16669 17.7004 9.16669 17.8787 9.07586C18.0355 8.99596 18.1629 8.86848 18.2428 8.71168C18.3337 8.53342 18.3337 8.30006 18.3337 7.83335V6.33335C18.3337 5.86664 18.3337 5.63329 18.2428 5.45503C18.1629 5.29823 18.0355 5.17074 17.8787 5.09085C17.7004 5.00002 17.467 5.00002 17.0003 5.00002L3.00033 5.00002C2.53362 5.00002 2.30026 5.00002 2.122 5.09085C1.9652 5.17074 1.83771 5.29823 1.75782 5.45503C1.66699 5.63329 1.66699 5.86664 1.66699 6.33335Z"
        stroke="#475667"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_29700_1189">
        <rect width="20" height="20" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default Gift01;
