const CheckboxIcon = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.8 3.5H16.2C17.0483 3.5 17.6545 3.50039 18.1297 3.53921C18.599 3.57756 18.8963 3.65089 19.135 3.77248C19.6054 4.01217 19.9878 4.39462 20.2275 4.86502C20.3491 5.10366 20.4224 5.40099 20.4608 5.87032C20.4996 6.34549 20.5 6.95167 20.5 7.8V16.2C20.5 17.0483 20.4996 17.6545 20.4608 18.1297C20.4224 18.599 20.3491 18.8963 20.2275 19.135C19.9878 19.6054 19.6054 19.9878 19.135 20.2275C18.8963 20.3491 18.599 20.4224 18.1297 20.4608C17.6545 20.4996 17.0483 20.5 16.2 20.5H7.8C6.95167 20.5 6.34549 20.4996 5.87032 20.4608C5.40099 20.4224 5.10366 20.3491 4.86502 20.2275C4.39462 19.9878 4.01217 19.6054 3.77248 19.135C3.65089 18.8963 3.57756 18.599 3.53921 18.1297C3.50039 17.6545 3.5 17.0483 3.5 16.2V7.8C3.5 6.95167 3.50039 6.34549 3.53921 5.87032C3.57756 5.40099 3.65089 5.10366 3.77248 4.86502C4.01217 4.39462 4.39462 4.01217 4.86502 3.77248C5.10366 3.65089 5.40099 3.57756 5.87032 3.53921C6.34549 3.50039 6.95167 3.5 7.8 3.5Z"
      fill="#045FC4"
      stroke="#045FC4"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M16.8001 8.39999L10.2001 15L7.20007 12"
      stroke="white"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default CheckboxIcon;
