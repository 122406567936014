import { QueryParams } from '../../../@types/QueryParams';
import { apiSlice } from '../api/apiSlice';
import {
  LegalEntityListType,
  LegalEntityDetailsType,
  LegalEntitySubmitFormProps,
  BankDetails,
} from '../../../@types/LegalEntityProps';

interface Query {
  id: string;
  groupId?: string;
  effectiveDate?: string;
}

export const legalEntityApiSlice = apiSlice.injectEndpoints({
  endpoints: builder => ({
    getLegalEntityList: builder.query<LegalEntityListType, QueryParams>({
      query: params => {
        const { search, page, sortType, sort, searchString } = params;
        return {
          url: `/organization/legal-entities`,
          params: { search, page, sortType, sort, ...searchString },
        };
      },
      providesTags: ['LegalEntityList', 'LegalEntityDelete'],
    }),
    getLegalEntityDetails: builder.query<LegalEntityDetailsType, Query>({
      query: params => {
        const { effectiveDate, id } = params;
        return {
          url: `/organization/legal-entities/${id}`,
          params: {
            effectiveDate,
          },
        };
      },
      providesTags: ['LegalEntityDetail'],
    }),
    createLegalEntity: builder.mutation<void, LegalEntitySubmitFormProps>({
      query: body => ({
        url: `/organization/legal-entities`,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['LegalEntityList'],
    }),
    deleteLegalEntity: builder.mutation<void, Query>({
      query: params => ({
        url: `/organization/legal-entities/${params.id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['LegalEntityList', 'LegalEntityDelete'],
    }),
    cancelUpcomingLegalEntity: builder.mutation<any, any>({
      query: ({ id }) => ({
        url: `/organization/legal-entities/${id}/cancellation`,
        method: 'DELETE',
      }),
      invalidatesTags: ['LegalEntityDetail', 'LegalEntityList'],
    }),
    updateLegalEntity: builder.mutation({
      query: ({ payload, id }) => ({
        url: `organization/legal-entities/${id}`,
        method: 'PUT',
        body: payload,
      }),
      invalidatesTags: [
        'LegalEntityDetail',
        'EmployeeList',
        'employeeEmployment',
      ],
    }),
    createBank: builder.mutation({
      query: ({ payload, id }) => ({
        url: `/organization/legal-entities/${id}/banks`,
        method: 'POST',
        body: payload,
      }),
      invalidatesTags: ['legalEntityBankList'],
    }),
    getBankDetail: builder.query<any, any>({
      query: params => {
        const { effectiveDate, id, groupId } = params;
        return {
          url: `/organization/legal-entities/${id}/banks/${groupId}`,
          params: {
            effectiveDate,
          },
        };
      },
      providesTags: ['legalEntityBankDetail'],
    }),
    updateBank: builder.mutation({
      query: ({ payload, id, bankId }) => ({
        url: `/organization/legal-entities/${id}/banks/${bankId}`,
        method: 'PUT',
        body: payload,
      }),
      invalidatesTags: ['legalEntityBankList', 'legalEntityBankDetail'],
    }),
    deleteBank: builder.mutation<any, any>({
      query: ({ id, groupId }) => ({
        url: `/organization/legal-entities/${id}/banks/${groupId}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['legalEntityBankList'],
    }),
    cancelUpcomingChangesLegalEntityBank: builder.mutation<any, any>({
      query: ({ id, newId }) => ({
        url: `/organization/legal-entities/${id}/banks/${newId}/cancellation`,
        method: 'DELETE',
      }),
      invalidatesTags: ['legalEntityBankList', 'legalEntityBankDetail'],
    }),
    getDetailBranch: builder.query({
      query: params => {
        const { id } = params;
        return {
          url: `/organization/banks/${id}`,
        };
      },
    }),
    getLegalEntityBankList: builder.query<any, Query>({
      query: params => {
        const { id } = params;
        return {
          url: `/organization/legal-entities/${id}/banks`,
        };
      },
      providesTags: ['legalEntityBankList'],
    }),
    getLegalEntityStatutoryList: builder.query<any, any>({
      query: params => {
        const { id } = params;
        return {
          url: `/organization/legal-entities/${id}/statutories/idn`,
        };
      },
      providesTags: ['legalEntityStatutoryList'],
    }),
    getLegalEntityStatutoryDetail: builder.query<any, any>({
      query: params => {
        const { id, groupId, effectiveDate } = params;
        return {
          url: `/organization/legal-entities/${id}/statutories/idn/${groupId}`,
          params: {
            effectiveDate,
          },
        };
      },
      providesTags: ['legalEntityStatutoryDetail'],
    }),
    createLegalEntityStatutory: builder.mutation<any, any>({
      query: ({ id, payload }) => ({
        url: `/organization/legal-entities/${id}/statutories/idn`,
        method: 'POST',
        body: payload,
      }),
      invalidatesTags: ['legalEntityStatutoryList'],
    }),
    deleteLegalEntityStatutory: builder.mutation<any, any>({
      query: ({ id, groupId }) => ({
        url: `/organization/legal-entities/${id}/statutories/idn/${groupId}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['legalEntityStatutoryList'],
    }),
    updateLegalEntityStatutory: builder.mutation<any, any>({
      query: ({ id, oldId, payload }) => ({
        url: `/organization/legal-entities/${id}/statutories/idn/${oldId}`,
        method: 'PUT',
        body: payload,
      }),
      invalidatesTags: ['legalEntityStatutoryDetail'],
    }),
    cancelUpcomingChangesLegalEntityStatutory: builder.mutation<any, any>({
      query: ({ id, newId }) => ({
        url: `/organization/legal-entities/${id}/statutories/idn/${newId}/cancellation`,
        method: 'DELETE',
      }),
      invalidatesTags: [
        'legalEntityStatutoryList',
        'legalEntityStatutoryDetail',
      ],
    }),
    // TAX-LOCATION
    getTaxWithHolderDetail: builder.query<any, any>({
      query: params => {
        const { taxWithholderId } = params;
        return {
          url: `/organization/legal-entities/tax-with-holders/${taxWithholderId}/info`,
        };
      },
    }),
    getTaxLocationDetail: builder.query<any, any>({
      query: (params: any) => {
        const { taxWithholderId } = params;
        return {
          url: `/organization/tax-locations/${taxWithholderId}`,
        };
      },
    }),
    getLegalEntityTaxLocationList: builder.query<any, any>({
      query: (params: any) => {
        const { legalEntityId } = params;
        return {
          url: `/organization/legal-entities/${legalEntityId}/tax-locations/idn`,
        };
      },
      providesTags: [
        'legalEntityTaxLocationList',
        'legalEntityTaxLocationDetail',
      ],
    }),
    getLegalEntityTaxLocationDetails: builder.query<any, any>({
      query: (params: any) => {
        const { legalEntityId, taxLocationid, effectiveDate } = params;
        return {
          url: `/organization/legal-entities/${legalEntityId}/tax-locations/idn/${taxLocationid}`,
          params: {
            effectiveDate,
          },
        };
      },
      providesTags: ['legalEntityTaxLocationDetail'],
    }),
    createLegalEntityTaxLocation: builder.mutation<any, any>({
      query: ({ legalEntityId, payload }) => ({
        url: `/organization/legal-entities/${legalEntityId}/tax-locations/idn`,
        method: 'POST',
        body: payload,
      }),
      invalidatesTags: [
        'legalEntityTaxLocationDetail',
        'legalEntityTaxLocationList',
      ],
    }),
    deleteLegalEntityTaxLocation: builder.mutation<any, any>({
      query: ({ legalEntityId, taxLocationid }) => ({
        url: `/organization/legal-entities/${legalEntityId}/tax-locations/idn/${taxLocationid}`,
        method: 'DELETE',
      }),
      invalidatesTags: [
        'legalEntityTaxLocationDetail',
        'legalEntityTaxLocationList',
      ],
    }),
    updateLegalEntityTaxLocation: builder.mutation({
      query: ({ legalEntityId, oldId, payload }) => ({
        url: `/organization/legal-entities/${legalEntityId}/tax-locations/idn/${oldId}`,
        method: 'PUT',
        body: payload,
      }),
      invalidatesTags: ['legalEntityTaxLocationDetail'],
    }),
    cancelUpcomingChangesLegalEntityTaxLocation: builder.mutation<any, any>({
      query: ({ legalEntityId, newId }) => ({
        url: `/organization/legal-entities/${legalEntityId}/tax-locations/idn/${newId}/cancellation`,
        method: 'DELETE',
      }),
      invalidatesTags: [
        'legalEntityTaxLocationDetail',
        'legalEntityTaxLocationList',
      ],
    }),
    getSingleDetailTaxLocation: builder.query<any, any>({
      query: id => ({
        url: `/organization/legal-entities/tax-locations/${id}/info`,
      }),
    }),
    getDetailLegalEntityTaxWithHolderDetail: builder.query<any, any>({
      query: ({ legalEntityId, effectiveDate }) => ({
        url: `/organization/legal-entities/${legalEntityId}/tax-with-holders`,
        params: { effectiveDate },
      }),
    }),
  }),
});

export const {
  useGetLegalEntityListQuery,
  useGetLegalEntityDetailsQuery,
  useCreateLegalEntityMutation,
  useDeleteLegalEntityMutation,
  useUpdateLegalEntityMutation,
  useCreateBankMutation,
  useGetBankDetailQuery,
  useUpdateBankMutation,
  useGetDetailBranchQuery,
  useGetLegalEntityBankListQuery,
  useDeleteBankMutation,
  useGetLegalEntityStatutoryListQuery,
  useGetLegalEntityStatutoryDetailQuery,
  useCreateLegalEntityStatutoryMutation,
  useDeleteLegalEntityStatutoryMutation,
  useUpdateLegalEntityStatutoryMutation,
  useCancelUpcomingChangesLegalEntityStatutoryMutation,
  useCancelUpcomingChangesLegalEntityBankMutation,
  useCancelUpcomingLegalEntityMutation,
  // LEGAL-ENTITY_TAX-LOCATION
  useGetTaxWithHolderDetailQuery,
  useGetTaxLocationDetailQuery,
  useGetLegalEntityTaxLocationListQuery,
  useGetLegalEntityTaxLocationDetailsQuery,
  useCreateLegalEntityTaxLocationMutation,
  useUpdateLegalEntityTaxLocationMutation,
  useDeleteLegalEntityTaxLocationMutation,
  useCancelUpcomingChangesLegalEntityTaxLocationMutation,
  useGetSingleDetailTaxLocationQuery,
  // LEGAL-ENTITY_TAX-WITHHOLDER
  useGetDetailLegalEntityTaxWithHolderDetailQuery,
} = legalEntityApiSlice;
