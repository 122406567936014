import React from 'react';

const List = React.lazy(() => import('../../../pages/jobSite/List'));
const Create = React.lazy(() => import('../../../pages/jobSite/Create'));
const Update = React.lazy(() => import('../../../pages/jobSite/Update'));

export const jobSite = [
  {
    label: 'Job Site',
    child: null,
    path: 'job-site',
    component: List,
  },
  {
    label: '',
    child: null,
    path: 'job-site/create',
    component: Create,
  },
  {
    label: '',
    child: null,
    path: 'job-site/update/:id',
    component: Update,
  },
];
