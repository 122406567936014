import { Box, Typography, Skeleton } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import Modal from './Modal';
import { LogoWIthLabel } from '../../atom/icon';
import { Button } from '../../atom';
import { ReleaseNote } from '../../../@types/HomeTypes';
import { newformatDateTimeFromUnixUTC } from '../../../utils/date';

type ModalProps = {
  open: boolean;
  handleClose: Function;
  data?: ReleaseNote;
  loading?: boolean;
};

const About = ({ open, handleClose, data, loading }: ModalProps) => {
  const navigate = useNavigate();

  return (
    <Modal
      fullHeight
      open={open}
      onClose={handleClose}
      showCloseIcon
      showButton={false}
      showIcon={false}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: '16px',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: '8px',
            alignItems: 'center',
          }}
        >
          <LogoWIthLabel />
          <Typography variant="body12" color="#8A98A8">
            Create a Better Life for HR through Technology
          </Typography>
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: '8px',
            alignItems: 'center',
          }}
        >
          {loading && (
            <Skeleton
              variant="text"
              sx={{ fontSize: '2rem', width: '100px' }}
            />
          )}
          {!loading && (
            <Typography variant="body18" fontWeight={600} color="#475667">
              Version {data?.version}
            </Typography>
          )}
          {loading && (
            <Skeleton
              variant="text"
              sx={{ fontSize: '1rem', width: '100px' }}
            />
          )}
          {!loading && (
            <Typography fontSize="10px" color="#A5B1C0">
              {newformatDateTimeFromUnixUTC(data?.releaseDate || 0)}{' '}
            </Typography>
          )}
        </Box>
        <Button
          variant="link"
          onClick={() => {
            handleClose();
            navigate('/release-notes');
          }}
        >
          Release Notes
        </Button>
      </Box>
    </Modal>
  );
};

export default About;
