import { apiSlice } from '../api/apiSlice';

type CreateOrganizationUnit = {
  id: '';
  unit: '';
  description: null;
  effectiveDate: '';
};
type UpdateOrganizationUnit = {
  unit: string;
  description?: string;
  effectiveDate: number;
  reason?: string;
  typeOfChange?: string;
};

export const organizationUnitCreateSLice = apiSlice.injectEndpoints({
  endpoints: builder => ({
    createOrganizationUnit: builder.mutation<CreateOrganizationUnit, any>({
      query: payload => ({
        url: 'organization/organization-units',
        method: 'POST',
        body: payload,
      }),
      invalidatesTags: ['OrganizationUnitList'],
    }),
    updateOrganizationUnit: builder.mutation<UpdateOrganizationUnit, any>({
      query: ({ payload, id }) => ({
        url: `organization/organization-units/${id}`,
        method: 'PUT',
        body: payload,
      }),
      invalidatesTags: ['organizationUnitDetails'],
    }),
    deleteOrganizationUnit: builder.mutation<any, any>({
      query: id => ({
        url: `organization/organization-units/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['OrganizationUnitList'],
    }),
    cancelUpcomingChanges: builder.mutation<any, any>({
      query: ({ newId }) => ({
        url: `organization/organization-units/${newId}/cancellation`,
        method: 'DELETE',
      }),
      invalidatesTags: ['organizationUnitDetails', 'OrganizationUnitList'],
    }),
  }),
});

export const {
  useCreateOrganizationUnitMutation,
  useUpdateOrganizationUnitMutation,
  useDeleteOrganizationUnitMutation,
  useCancelUpcomingChangesMutation,
} = organizationUnitCreateSLice;
