import { FC } from 'react';
import { SvgIconProps } from '@mui/material';
import { SvgIcon } from './SvgIcon';

const ArrowLeft: FC<SvgIconProps> = props => (
  <SvgIcon {...props}>
    <path
      d="M19 12H5M5 12L12 19M5 12L12 5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SvgIcon>
);

export default ArrowLeft;
