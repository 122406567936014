import { Typography } from '@mui/material';
import { useState, useEffect, useRef } from 'react';

type TimerProps = {
  isRunning: boolean;
  variant?: any;
  currentTimer?: number;
  color?: string;
};

const Timer = ({
  isRunning,
  variant,
  currentTimer,
  color = 'black',
}: TimerProps) => {
  const [secondsElapsed, setSecondsElapsed] = useState(currentTimer || 0);
  const intervalRef = useRef<any>(null);

  useEffect(() => {
    if (isRunning) {
      intervalRef.current = setInterval(() => {
        setSecondsElapsed(prevSeconds => prevSeconds + 1);
      }, 1000);
    } else if (!isRunning && intervalRef.current) {
      clearInterval(intervalRef.current);
    }
    return () => clearInterval(intervalRef.current);
  }, [isRunning]);

  // Function to format time in HH:MM:SS
  const formatTime = (totalSeconds: number) => {
    const hours = Math.floor(totalSeconds / 3600);
    const minutes = Math.floor((totalSeconds % 3600) / 60);
    const seconds = totalSeconds % 60;

    const paddedHours = hours.toString().padStart(2, '0');
    const paddedMinutes = minutes.toString().padStart(2, '0');
    const paddedSeconds = seconds.toString().padStart(2, '0');

    return `${paddedHours}:${paddedMinutes}:${paddedSeconds}`;
  };

  /* eslint react/button-has-type: "off" */

  return (
    <Typography component="span" variant={variant} color={color}>
      {formatTime(secondsElapsed)}
    </Typography>
  );
};

export default Timer;
