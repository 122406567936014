import {
  FormControl,
  FormLabel,
  SxProps,
  Typography,
  useTheme,
  Box,
} from '@mui/material';
import React, { useRef } from 'react';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { GroupBase } from 'react-select';
import { AsyncProps } from 'react-select/async';
import { InputProps, OptionProps } from '../../../../@types';
import { LoadingSkeleton } from '../../../atom';
import SelectWithPaginate from '../../../atom/selectPaginate/SelectWithPaginate';
import { getMessageFromObject } from '../../../../utils/objectMap';

export type CustomProps = {
  label: string;
  additional?: any;
  url: string;
  customLabel?: Function;
  labelInfo?: React.ReactNode;
  unRemoved?: Array<OptionProps>;
  isLoading?: boolean;
  helperText?: React.ReactNode | string;
  CustomComponent?: any;
  customErrorMessage?: any;
};

const SelectPaginateWithLabel = <
  OptionType,
  IsMulti extends boolean = false,
  GroupType extends GroupBase<OptionType> = GroupBase<OptionType>,
  UseFieldArrayOptions extends Record<string, any> = any
>(
  Props: InputProps &
    AsyncProps<OptionType, IsMulti, GroupType> &
    UseFieldArrayOptions &
    CustomProps
) => {
  const { palette } = useTheme();
  const { t } = useTranslation();
  const selectRef = useRef();

  const {
    sx,
    name,
    control,
    label,
    url,
    additional,
    customLabel,
    labelInfo,
    onChange,
    unRemoved,
    isLoading,
    required = true,
    disabled,
    helperText,
    CustomComponent,
    customErrorMessage = null,
    ...props
  } = Props;
  const style = {
    '& .MuiFormLabel-root': {
      marginBottom: '0.5rem',
    },

    '& .input--error': {
      '& .select__control:not(.select__control--is-focused)': {
        borderColor: `${palette.red[50]} !important`,
      },
      '&:focused': {
        borderColor: `${palette.red[50]} !important`,
      },
    },
    ...sx,
  } as SxProps;

  return (
    <FormControl sx={style} fullWidth>
      {label !== '' && (
        <FormLabel htmlFor={name}>
          <Typography
            color={palette.gray[100]}
            variant="body14"
            fontWeight={500}
          >
            {label} {CustomComponent}
            {!required && (
              <Typography
                component="span"
                variant="body14"
                color={palette.gray[80]}
              >
                (optional)
              </Typography>
            )}
          </Typography>
          {labelInfo}
        </FormLabel>
      )}
      <Controller
        control={control}
        name={name || ''}
        render={({ field, formState }) => {
          const { errors } = formState as any;
          const isError =
            Object.keys(errors).length > 0 &&
            name &&
            getMessageFromObject(errors, name);
          return (
            <>
              {isLoading && (
                <LoadingSkeleton
                  sx={{
                    '& .react-loading-skeleton': {
                      height: '48px',
                      borderRadius: '8px',
                    },
                  }}
                />
              )}
              {!isLoading && (
                <Box className={isError ? 'input--error' : ''}>
                  <SelectWithPaginate
                    id={name}
                    url={url}
                    additional={additional}
                    customLabel={(value: any) => customLabel?.(value)}
                    closeMenuOnSelect={false}
                    {...props}
                    {...field}
                    isDisabled={disabled}
                    unRemoved={unRemoved}
                    onChange={(item: any, actionMeta: any) => {
                      field.onChange(item);
                      onChange?.(item);
                      if (actionMeta.action === 'clear') {
                        if (unRemoved && unRemoved?.length > 0) {
                          onChange?.(unRemoved as any);
                          field.onChange(unRemoved);
                        }
                      }
                    }}
                  />
                  {helperText && (
                    <Typography
                      marginTop="8px"
                      component="p"
                      variant="body14"
                      color={palette.gray[80]}
                    >
                      {helperText}
                    </Typography>
                  )}
                  {isError && !customErrorMessage && (
                    <Typography
                      marginTop="8px"
                      component="p"
                      variant="body14"
                      color={palette.red[50]}
                    >
                      {`${t(getMessageFromObject(errors, name))}`}
                    </Typography>
                  )}
                  {customErrorMessage && customErrorMessage}
                </Box>
              )}
            </>
          );
        }}
      />
    </FormControl>
  );
};

export default SelectPaginateWithLabel;
