import React from 'react';
import { RouteNode } from '../../@types';

const LegalEntityList = React.lazy(
  () => import('../../pages/legalEntity/LegalEntityList')
);
const LegalEntityCreate = React.lazy(
  () => import('../../pages/legalEntity/LegalEntityCreate')
);
const LegalEntityDetails = React.lazy(
  () => import('../../pages/legalEntity/LegalEntityDetails')
);
const LegalEntityUpdate = React.lazy(
  () => import('../../pages/legalEntity/LegalEntityUpdate')
);
const LegalEntityCorrection = React.lazy(
  () => import('../../pages/legalEntity/LegalEntityCorrection')
);

const legalEntity: RouteNode[] = [
  {
    label: 'Legal Entity',
    path: 'legal-entity',
    child: null,
    component: LegalEntityList,
  },
  {
    label: '',
    path: 'legal-entity/create',
    component: LegalEntityCreate,
    child: null,
  },
  {
    label: '',
    path: 'legal-entity/detail/:id',
    component: LegalEntityDetails,
    child: null,
  },
  {
    label: '',
    path: 'legal-entity/update/:id',
    component: LegalEntityUpdate,
    child: null,
  },
  {
    label: '',
    path: 'legal-entity/correction/:id',
    component: LegalEntityCorrection,
    child: null,
  },
];

export default legalEntity;
