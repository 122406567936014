import { SerializedError } from '@reduxjs/toolkit';
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query';

export const mappingError = (
  paramsError: any,
  setError: Function,
  pages?: string
) => {
  try {
    if (typeof paramsError === 'string' && paramsError !== '') {
      Object.keys(JSON.parse(paramsError)).forEach((key: any) => {
        const message = JSON.parse(paramsError)[key]?.join(', ');
        setError(key, {
          type: 'manual',
          message: pages ? `error.${pages}.${key}.${message}` : message,
        });
      });
    }
    if (
      typeof paramsError !== 'string' &&
      JSON.stringify(paramsError) !== '{}'
    ) {
      Object.keys(paramsError).forEach((key: any) => {
        const message = paramsError[key]?.join(', ');
        setError(key, {
          type: 'manual',
          message: pages ? `error.${pages}.${key}.${message}` : message,
        });
      });
    }
  } catch (error) {
    console.log(error);
  }
};

export const getErrorMessage = (
  error?: FetchBaseQueryError | SerializedError
) => {
  if (error && 'data' in error) {
    const errorData = error.data as any;
    if (errorData !== null) {
      return errorData.error?.message || errorData?.message || '';
    }
  }
  return '';
};
