import { QueryParams } from '../../../@types/QueryParams';
import { apiSlice } from '../api/apiSlice';

type TimeOff = {
  id: string;
  name: string;
  typeName: string;
  code: string;
  entitlement: string;
};

type ListTimeOff = {
  data: TimeOff[];
  limit: number;
  page: number;
  total: number;
};

export const timeOffApiSlice = apiSlice.injectEndpoints({
  endpoints: builder => ({
    listTimeOff: builder.query<ListTimeOff, any>({
      query: params => {
        const { search, page, limit, searchString } = params;
        return {
          url: '/time-management/time-off',
          params: { search, page, limit, ...searchString },
        };
      },
      providesTags: ['listTimeOff'],
    }),
    createTimeOff: builder.mutation<any, any>({
      query: payload => ({
        url: '/time-management/time-off',
        method: 'POST',
        body: payload,
      }),
      invalidatesTags: ['listTimeOff'],
    }),
    detailsTimeOff: builder.query<any, any>({
      query: id => ({
        url: `/time-management/time-off/${id}`,
      }),
      providesTags: ['detailTimeOff'],
    }),
    listEntitlementTimeOffDetail: builder.query<
      any,
      Omit<QueryParams, 'sort'> & { sortBy: string; id: string }
    >({
      query: params => {
        const { search, page, sortType, sortBy, limit, searchString, id } =
          params;
        return {
          url: `/time-management/time-off/${id}/entitlements`,
          params: { search, page, sortType, sortBy, limit, ...searchString },
        };
      },
    }),
  }),
});

export const {
  useListTimeOffQuery,
  useCreateTimeOffMutation,
  useDetailsTimeOffQuery,
  useListEntitlementTimeOffDetailQuery,
} = timeOffApiSlice;
