import { apiSlice } from '../../api/apiSlice';

export const employeeCareerTransitionSlice = apiSlice.injectEndpoints({
  endpoints: builder => ({
    getListCareerTransitionEmployee: builder.query<any, any>({
      query: args => {
        const { employeeId, search, page, sortType, sort, searchString } = args;
        return {
          url: `/employee/employees/${employeeId}/transition`,
          params: { search, page, sortType, sort, ...searchString },
        };
      },
      providesTags: ['employeeCareerTransitionList'],
      // invalidatesTags: ['employeeEarningDetail', 'employeeDeductionDetail'],
    }),
    getDetailCareerTransitionEmployee: builder.query<any, any>({
      query: ({ employeeId, transitionId }) => ({
        url: `/employee/employees/${employeeId}/transition/${transitionId}`,
      }),
      providesTags: ['employeeCareerTransitionDetail'],
    }),
    getDetailCareerTransitionTermination: builder.query<any, any>({
      query: ({ employeeId, transitionId }) => ({
        url: `/employee/employees/${employeeId}/terminate/${transitionId}`,
      }),
      providesTags: ['employeeCareerTransitionTermination'],
    }),
    getEmploymentDetailTransition: builder.query<any, any>({
      query: ({ employeeId, transitionId }) => ({
        url: `/employee/employees/${employeeId}/transition/employment/${transitionId}`,
      }),
      providesTags: ['employmentDetailCareerTransition'],
    }),
    getWorkLocationDetailTransition: builder.query<any, any>({
      query: ({ employeeId, transitionId }) => ({
        url: `/employee/employees/${employeeId}/transition/work-location/${transitionId}`,
      }),
      providesTags: ['workLocationDetailCareerTransition'],
    }),
    getTaxLocationDetailTransition: builder.query<any, any>({
      query: ({ employeeId, transitionId }) => ({
        url: `/employee/employees/${employeeId}/transition/tax-location/${transitionId}`,
      }),
      providesTags: ['taxLocationDetailCareerTransition'],
    }),
    getEarningsDetailTransition: builder.query<any, any>({
      query: ({ employeeId, transitionId }) => ({
        url: `/employee/employees/${employeeId}/transition/earnings/${transitionId}`,
      }),
      providesTags: ['earningsDetailCareerTransition'],
    }),
    getDeductionsDetailTransition: builder.query<any, any>({
      query: ({ employeeId, transitionId }) => ({
        url: `/employee/employees/${employeeId}/transition/deductions/${transitionId}`,
      }),
      providesTags: ['deductionsDetailCareerTransition'],
    }),
    createCareerTransition: builder.mutation<
      any,
      {
        employeeId: string | undefined;
        transitionType: string | undefined;
        body: any;
      }
    >({
      query: ({ employeeId, transitionType, body }) => ({
        url: `/employee/employees/${employeeId}/transition/${transitionType}`,
        method: 'POST',
        body,
      }),
      invalidatesTags: [
        'employeeCareerTransitionList',
        'employeeEmployment',
        'employeeWorkLocation',
        'employeeStatutoryList',
        'employeeEarningList',
        'employeeDeductionDetail',
        'employeeEarningDetail',
        'EmployeeList',
        'employeeStatutoryList',
        'employeeStatutoryDetail',
        'employeeProfiles',
      ],
    }),
    withdrawCareerTransition: builder.mutation<
      any,
      {
        body: Record<string, any>;
        carrerTransitionID: string;
        employeeID: string;
      }
    >({
      query: ({ body, carrerTransitionID, employeeID }) => ({
        url: `/employee/employees/${employeeID}/transition/withdraw/${carrerTransitionID}`,
        method: 'DELETE',
        body,
      }),
      invalidatesTags: [
        'employeeCareerTransitionList',
        'employeeEmployment',
        'employeeWorkLocation',
        'employeeStatutoryList',
        'employeeEarningList',
        'employeeDeductionDetail',
        'employeeEarningDetail',
        'EmployeeList',
        'employeeStatutoryList',
        'employeeStatutoryDetail',
        'employeeCareerTransitionDetail',
        'employmentDetailCareerTransition',
        'employeeProfiles',
      ],
    }),
    cancelTransition: builder.mutation<any, any>({
      query: ({ employeeId, transitionId }) => ({
        url: `/employee/employees/${employeeId}/transition/cancel/${transitionId}`,
        method: 'DELETE',
      }),
      invalidatesTags: [
        'employeeCareerTransitionList',
        'employeeEmployment',
        'employeeWorkLocation',
        'employeeStatutoryList',
        'employeeEarningList',
        'employeeDeductionDetail',
        'employeeEarningDetail',
        'EmployeeList',
        'employeeStatutoryList',
        'employeeStatutoryDetail',
        'employeeProfiles',
      ],
    }),
    getUpcomingDataTransition: builder.query<any, any>({
      query: ({ employeeId, data }) => ({
        url: `/employee/employees/${employeeId}/transition/check-upcoming/${data}`,
      }),
    }),
  }),
});

export const {
  useCreateCareerTransitionMutation,
  useGetDetailCareerTransitionEmployeeQuery,
  useGetListCareerTransitionEmployeeQuery,
  useWithdrawCareerTransitionMutation,
  useCancelTransitionMutation,
  useGetEmploymentDetailTransitionQuery,
  useGetWorkLocationDetailTransitionQuery,
  useGetTaxLocationDetailTransitionQuery,
  useGetEarningsDetailTransitionQuery,
  useGetDeductionsDetailTransitionQuery,
  useGetUpcomingDataTransitionQuery,
  useGetDetailCareerTransitionTerminationQuery,
} = employeeCareerTransitionSlice;
