import { createSlice } from '@reduxjs/toolkit';
import { legalEntityApiSlice } from './service/legalEntitySlice';

interface initialStateI {
  messageSuccess: string;
  messageError: string;
}

const initialState: initialStateI = {
  messageSuccess: '',
  messageError: '',
};

const legalEntitySlice = createSlice({
  name: 'legalEntity',
  initialState,
  reducers: {
    removeMessage: state => {
      state.messageSuccess = '';
      state.messageError = '';
    },
  },
  extraReducers(builder) {
    builder.addMatcher(
      legalEntityApiSlice.endpoints.createLegalEntity.matchFulfilled,
      (state, _action) => {
        state.messageSuccess = 'Legal Entity created';
      }
    );
    builder.addMatcher(
      legalEntityApiSlice.endpoints.deleteLegalEntity.matchFulfilled,
      (state, _action) => {
        state.messageSuccess = 'Legal Entity deleted';
      }
    );
    builder.addMatcher(
      legalEntityApiSlice.endpoints.deleteLegalEntity.matchRejected,
      (state, action) => {
        const payload = action.payload as any;
        state.messageError = payload?.data?.message;
      }
    );
    builder.addMatcher(
      legalEntityApiSlice.endpoints.cancelUpcomingLegalEntity.matchFulfilled,
      (state, _action) => {
        state.messageSuccess = 'Upcoming Changes cancelled';
      }
    );
    builder.addMatcher(
      legalEntityApiSlice.endpoints.cancelUpcomingLegalEntity.matchRejected,
      (state, action) => {
        const payload = action.payload as any;
        state.messageError = payload?.data?.message;
      }
    );
    builder.addMatcher(
      legalEntityApiSlice.endpoints.updateLegalEntity.matchFulfilled,
      (state, action) => {
        if (action?.payload?.typeOfChange === 'UPDATED') {
          state.messageSuccess = ' Legal Entity updated ';
        } else if (action?.payload?.typeOfChange === 'CORRECTED') {
          state.messageSuccess = 'Legal Entity corrected';
        }
      }
    );
    builder.addMatcher(
      legalEntityApiSlice.endpoints.createBank.matchFulfilled,
      (state, action) => {
        state.messageSuccess = 'Bank Info added';
      }
    );
    builder.addMatcher(
      legalEntityApiSlice.endpoints.updateBank.matchFulfilled,
      (state, action) => {
        if (action?.payload?.typeOfChange === 'UPDATED') {
          state.messageSuccess = 'Bank Info updated';
        } else if (action?.payload?.typeOfChange === 'CORRECTED') {
          state.messageSuccess = 'Bank Info corrected';
        }
      }
    );
    builder.addMatcher(
      legalEntityApiSlice.endpoints.deleteBank.matchFulfilled,
      (state, _action) => {
        state.messageSuccess = 'Bank Info deleted';
      }
    );
    builder.addMatcher(
      legalEntityApiSlice.endpoints.deleteBank.matchRejected,
      (state, action) => {
        const payload = action.payload as any;
        state.messageError = payload?.data?.message;
      }
    );
    builder.addMatcher(
      legalEntityApiSlice.endpoints.createLegalEntityStatutory.matchFulfilled,
      (state, _action) => {
        state.messageSuccess = 'Statutory Info added';
      }
    );
    builder.addMatcher(
      legalEntityApiSlice.endpoints.deleteLegalEntityStatutory.matchFulfilled,
      (state, _action) => {
        state.messageSuccess = 'Statutory Info deleted';
      }
    );
    builder.addMatcher(
      legalEntityApiSlice.endpoints.updateLegalEntityStatutory.matchFulfilled,
      (state, _action) => {
        if (_action.payload.typeOfChange === 'UPDATED') {
          state.messageSuccess = 'Statutory Info updated';
        } else if (_action.payload.typeOfChange === 'CORRECTED') {
          state.messageSuccess = 'Statutory Info corrected';
        }
      }
    );
    builder.addMatcher(
      legalEntityApiSlice.endpoints.deleteLegalEntityStatutory.matchRejected,
      (state, action) => {
        const payload = action.payload as any;
        state.messageError = payload?.data?.message;
      }
    );
    builder.addMatcher(
      legalEntityApiSlice.endpoints.cancelUpcomingChangesLegalEntityStatutory
        .matchFulfilled,
      (state, _action) => {
        state.messageSuccess = 'Upcoming Changes cancelled';
      }
    );
    builder.addMatcher(
      legalEntityApiSlice.endpoints.cancelUpcomingChangesLegalEntityBank
        .matchFulfilled,
      (state, _action) => {
        state.messageSuccess = 'Upcoming Changes cancelled';
      }
    );
    // LEGAL-ENTITY_TAX-LOCATION
    builder.addMatcher(
      legalEntityApiSlice.endpoints.createLegalEntityTaxLocation.matchFulfilled,
      (state, _action) => {
        state.messageSuccess = 'Tax Location Info added';
      }
    );
    builder.addMatcher(
      legalEntityApiSlice.endpoints.deleteLegalEntityTaxLocation.matchFulfilled,
      (state, _action) => {
        state.messageSuccess = 'Tax Location Info deleted';
      }
    );
    builder.addMatcher(
      legalEntityApiSlice.endpoints.updateLegalEntityTaxLocation.matchFulfilled,
      (state, _action) => {
        if (_action.payload.typeOfChange === 'UPDATED') {
          state.messageSuccess = 'Tax Location Info updated';
        } else if (_action.payload.typeOfChange === 'CORRECTED') {
          state.messageSuccess = 'Tax Location Info corrected';
        }
      }
    );
    builder.addMatcher(
      legalEntityApiSlice.endpoints.deleteLegalEntityTaxLocation.matchRejected,
      (state, action) => {
        const payload = action.payload as any;
        state.messageError = payload?.data?.message;
      }
    );
    builder.addMatcher(
      legalEntityApiSlice.endpoints.cancelUpcomingChangesLegalEntityTaxLocation
        .matchFulfilled,
      (state, _action) => {
        state.messageSuccess = 'Upcoming Changes cancelled';
      }
    );
  },
});

export const { removeMessage } = legalEntitySlice.actions;
export const legalEntityReducer = legalEntitySlice.reducer;
