import { apiSlice } from '../api/apiSlice';

export const fileUploadApiSlice = apiSlice.injectEndpoints({
  endpoints: builder => ({
    updatePhoto: builder.mutation({
      query: ({ body }) => ({
        url: `/employee/upload`,
        method: 'POST',
        body,
      }),
    }),
  }),
});

export const { useUpdatePhotoMutation } = fileUploadApiSlice;
