import { apiSlice } from '../api/apiSlice';

interface DTOLegalEntityPhoto {
  fileName: string;
  fileUrl: string;
  size: number;
  mimeType: string;
}
export const changeLogoSlice = apiSlice.injectEndpoints({
  endpoints: builder => ({
    getProfileTenant: builder.query<
      { fileUrl: string; updatedAt: number; updater: string },
      { tenantID: string }
    >({
      query: params => {
        const { tenantID } = params;
        return {
          url: `/identity/tenant/tenant-picture/${tenantID}`,
        };
      },
      providesTags: ['profileDetail'],
    }),
    changeProfileEmployee: builder.mutation<
      {
        employeeId: string;
        fileName: string;
        fileUrl: string;
        size: number;
        mimeType: string;
      },
      {
        employeeId: string;
        payload: {
          employeeId: string;
          fileName: string;
          fileUrl: string;
          size: number;
          mimeType: string;
        };
      }
    >({
      query: params => {
        const { payload, employeeId } = params;
        return {
          url: `/employee/employees/update-employee-profile-picture/${employeeId}`,
          method: 'PUT',
          body: payload,
        };
      },
      invalidatesTags: ['EmployeeList'],
    }),
    changeProfileLegalEntity: builder.mutation<
      DTOLegalEntityPhoto,
      { legalEntityID: string; payload: DTOLegalEntityPhoto }
    >({
      query: params => {
        const { fileName, fileUrl, size, mimeType } = params.payload;
        const { legalEntityID } = params;
        return {
          url: `/organization/legal-entities/update-photo/${legalEntityID}`,
          method: 'PUT',
          body: { fileName, fileUrl, size, mimeType },
        };
      },
      invalidatesTags: ['LegalEntityDetail'],
    }),
    changeProfileApp: builder.mutation<
      any,
      {
        tenantID: string;
        fileName: string;
        fileUrl: string;
        size: number;
        mimeType: string;
      }
    >({
      query: params => {
        const { fileName, fileUrl, size, mimeType, tenantID } = params;
        return {
          url: `/identity/tenant/tenant-update-logo/${tenantID}`,
          method: 'PUT',
          body: { fileName, fileUrl, size, mimeType },
        };
      },
      invalidatesTags: ['profileDetail'],
    }),
    resetProfileApp: builder.mutation<any, { tenantID: string }>({
      query: params => {
        const { tenantID } = params;
        return {
          url: `/identity/tenant/tenant-picture/${tenantID}`,
          method: 'PUT',
        };
      },
      invalidatesTags: ['profileDetail'],
    }),
  }),
});

export const {
  useGetProfileTenantQuery,
  useChangeProfileEmployeeMutation,
  useChangeProfileLegalEntityMutation,
  useChangeProfileAppMutation,
  useResetProfileAppMutation,
} = changeLogoSlice;
