import { PayloadType } from '../../../../@types/EmployeeEarning';
import { apiSlice } from '../../api/apiSlice';

export const employeeEarningApiSlice = apiSlice.injectEndpoints({
  endpoints: builder => ({
    getDetailEmployeeEarning: builder.query({
      query: args => {
        const { id, groupId, ...query } = args;
        return {
          url: `/payroll/employees/${id}/earnings`,
          params: query,
        };
      },
      providesTags: ['employeeEarningDetail'],
    }),
    createEmployeeEarning: builder.mutation<any, PayloadType>({
      query: ({ employeeId, data }) => ({
        url: `/payroll/employees/${employeeId}/earnings`,
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['employeeEarningDetail'],
    }),
    updateEmployeeEarning: builder.mutation<any, PayloadType>({
      query: ({ employeeId, earningId, data }) => ({
        url: `/payroll/employees/${employeeId}/earnings/${earningId}`,
        method: 'PUT',
        body: data,
      }),
      invalidatesTags: ['employeeEarningDetail'],
    }),
    cancelChangesEmployeeEarning: builder.mutation<any, any>({
      query: ({ employeeId, earningId }) => ({
        url: `/payroll/employees/${employeeId}/earnings/${earningId}/cancellation`,
        method: 'DELETE',
      }),
      invalidatesTags: ['employeeEarningDetail'],
    }),
  }),
});

export const {
  useCreateEmployeeEarningMutation,
  useGetDetailEmployeeEarningQuery,
  useUpdateEmployeeEarningMutation,
  useCancelChangesEmployeeEarningMutation,
} = employeeEarningApiSlice;
