import { MouseEventHandler } from 'react';

type XCircleProps = {
  stroke?: string;
  onClick?: MouseEventHandler<SVGSVGElement>;
};

const XCircle = ({ stroke, onClick }: XCircleProps) => (
  <svg
    onClick={onClick}
    width="24"
    height="24"
    cursor="pointer"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15 9L9 15M9 9L15 15M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12Z"
      stroke={stroke || '#101B28'}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default XCircle;
