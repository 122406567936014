import { apiSlice } from '../api/apiSlice';

export const timeItemSlice = apiSlice.injectEndpoints({
  endpoints: builder => ({
    getListTimeItem: builder.query<any, any>({
      query: params => {
        const { search, page, sortType, sortBy, limit, searchString } = params;
        return {
          url: '/time-management/time-items',
          params: { search, page, sortType, sortBy, limit, ...searchString },
        };
      },
      providesTags: ['timeItemList'],
    }),
    getTimeItemDetail: builder.query<any, { id: string }>({
      query: params => {
        const { id } = params;
        return {
          url: `/time-management/time-items/${id}`,
        };
      },
      providesTags: ['timeItemDetail'],
    }),
    createTimeItem: builder.mutation<
      void,
      { body: { name: string; dayType: { label: string; value: string } } }
    >({
      query: params => {
        const { body } = params;
        return {
          url: '/time-management/time-items',
          method: 'POST',
          body,
        };
      },
      invalidatesTags: ['timeItemList'],
    }),
    updateTimeItem: builder.mutation<
      void,
      {
        id: string;
        body: { name: string; dayType: { label: string; value: string } };
      }
    >({
      query: params => {
        const { body, id } = params;
        return {
          url: `/time-management/time-items/${id}`,
          method: 'PUT',
          body: { ...body },
        };
      },
      invalidatesTags: ['timeItemDetail'],
    }),
  }),
});

export const {
  useGetListTimeItemQuery,
  useCreateTimeItemMutation,
  useUpdateTimeItemMutation,
  useGetTimeItemDetailQuery,
} = timeItemSlice;
