import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { employeeDeductionApiSlice } from './service/employee/deductionApiSlice';
import { DeductionType, ParamsType } from '../../@types/EmployeeDeduction';
import { sortData } from '../../utils/dataDisplay';

type initialStateType = {
  messageSuccess: string;
  messageError: string;
  deductions: Array<DeductionType>;
  filteredDeductions: Array<DeductionType>;
  updatedDeduction: number;
};

const initialState: initialStateType = {
  messageSuccess: '',
  messageError: '',
  filteredDeductions: [],
  deductions: [],
  updatedDeduction: 0,
};

const employeeDeductionSlice = createSlice({
  name: 'employeeDeduction',
  initialState,
  reducers: {
    removeMessage: state => {
      state.messageSuccess = '';
      state.messageError = '';
    },
    getDeductionList: (state, action: PayloadAction<ParamsType>) => {
      const { search } = action.payload;
      state.filteredDeductions = state.deductions;
      if (search) {
        console.log('search', search);
        state.filteredDeductions = state.filteredDeductions.filter(
          deduction =>
            deduction.payItemName
              ?.toLowerCase()
              .includes(search.toLowerCase()) ||
            deduction.payItemCode?.toLowerCase().includes(search.toLowerCase())
        );
      }
      state.filteredDeductions = sortData(
        state.filteredDeductions,
        action.payload.sortBy,
        action.payload.sortType
      );
    },
    addDeduction: (state, action: PayloadAction<DeductionType>) => {
      const indexDeduction = state.deductions.findIndex(
        item => item.id === action.payload.id
      );
      if (indexDeduction === -1) {
        state.deductions.push(action.payload);
        state.messageSuccess = 'Deduction added';
      } else {
        state.messageError = 'Error! Deduction already exist!';
      }
      state.updatedDeduction = new Date().getTime();
    },
    updateDeduction: (
      state,
      action: PayloadAction<{ id: string; data: DeductionType }>
    ) => {
      const indexExistingDeduction = state.deductions.findIndex(
        item =>
          item.id !== action.payload.id && item.id === action.payload.data.id
      );

      const indexDeduction = state.deductions.findIndex(
        item => item.id === action.payload.id
      );

      if (indexExistingDeduction === -1) {
        state.deductions[indexDeduction] = action.payload.data;
        state.messageSuccess = 'Deduction edited';
        state.updatedDeduction = new Date().getTime();
      } else {
        state.messageError = 'Error! Earning already exist!';
      }
    },
    deleteDeduction: (state, action: PayloadAction<string>) => {
      state.deductions = state.deductions.filter(
        item => item.id !== action.payload
      );
      state.messageSuccess = 'Deduction removed';
      state.updatedDeduction = new Date().getTime();
    },
    setDeduction: (state, action: PayloadAction<Array<DeductionType>>) => {
      state.deductions = action.payload;
      state.filteredDeductions = action.payload;
    },
    resetDeduction: state => {
      state.deductions = [];
      state.filteredDeductions = [];
    },
  },
  extraReducers(builder) {
    builder.addMatcher(
      employeeDeductionApiSlice.endpoints.createEmployeeDeduction
        .matchFulfilled,
      (state, _action) => {
        state.messageSuccess = 'Deduction updated';
      }
    );
    builder.addMatcher(
      employeeDeductionApiSlice.endpoints.updateEmployeeDeduction
        .matchFulfilled,
      (state, _action) => {
        if (_action.payload.typeOfChange === 'UPDATED') {
          state.messageSuccess = 'Deduction updated';
        } else if (_action.payload.typeOfChange === 'CORRECTED') {
          state.messageSuccess = 'Deduction corrected';
        }
      }
    );
    builder.addMatcher(
      employeeDeductionApiSlice.endpoints.cancelChangesEmployeeDeduction
        .matchFulfilled,
      (state, _action) => {
        state.messageSuccess = 'Upcoming Changes cancelled';
      }
    );
  },
});

export const {
  removeMessage,
  addDeduction,
  updateDeduction,
  resetDeduction,
  getDeductionList,
  deleteDeduction,
  setDeduction,
} = employeeDeductionSlice.actions;
export const employeeDeductionReducer = employeeDeductionSlice.reducer;
