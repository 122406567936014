import { createSlice } from '@reduxjs/toolkit';
import { employeeApiSlice } from './service/employeeApislice';
import { EmployeeWorkLocationApiSlice } from './service/employee/workLocation';
import { employeeEmploymentApiSlice } from './service/employee/employment';
import { employeeExperienceApiSlice } from './service/employee/experience';
import { employeeFamilyApiSlice } from './service/employee/family';
import { employeeEducationApiSlice } from './service/employee/educationApiSlice';
import { employeeAddressApiSlice } from './service/employee/address';
import { employeePersonalApiSlice } from './service/employee/personalInfo';
import { employeeStatutoryApiSlice } from './service/employee/statutory';
import { employeeRehireApiSlice } from './service/employee/rehire';
import { employeeTerminationApiSlice } from './service/employee/termination';

interface initialStateI {
  messageSuccess: string;
  messageError: string;
}

const initialState: initialStateI = {
  messageSuccess: '',
  messageError: '',
};

const employeeSlice = createSlice({
  name: 'employee',
  initialState,
  reducers: {
    removeMessage: state => {
      state.messageSuccess = '';
      state.messageError = '';
    },
  },
  extraReducers(builder) {
    builder.addMatcher(
      employeeApiSlice.endpoints.getEmployeeExport.matchFulfilled,
      (state, _action) => {
        state.messageSuccess = 'Employee Data exported';
      }
    );
    builder.addMatcher(
      employeeApiSlice.endpoints.addEmployee.matchFulfilled,
      (state, _action) => {
        state.messageSuccess = 'Employee created';
      }
    );
    builder.addMatcher(
      employeeApiSlice.endpoints.addEmployee.matchRejected,
      (state, _action) => {
        const payload = _action.payload as any;
        state.messageError = payload?.data?.message;
      }
    );
    builder.addMatcher(
      employeeApiSlice.endpoints.getEmployeeExport.matchRejected,
      (state, _action) => {
        state.messageError = 'Error exported';
      }
    );
    builder.addMatcher(
      employeeApiSlice.endpoints.updateDetailStatutory.matchFulfilled,
      (state, _action) => {
        state.messageSuccess = 'Statutory Info corrected';
      }
    );
    builder.addMatcher(
      employeeApiSlice.endpoints.updateDetailPersonal.matchFulfilled,
      (state, _action) => {
        state.messageSuccess = 'Personal Info corrected';
      }
    );
    builder.addMatcher(
      EmployeeWorkLocationApiSlice.endpoints.addEmployeeWorkLocation
        .matchFulfilled,
      (state, _action) => {
        state.messageSuccess = 'Work Location added';
      }
    );
    builder.addMatcher(
      EmployeeWorkLocationApiSlice.endpoints.addEmployeeWorkLocation
        .matchRejected,
      (state, action) => {
        console.log(action.payload);
        state.messageError = 'Incorrect Form Filled';
      }
    );
    builder.addMatcher(
      EmployeeWorkLocationApiSlice.endpoints.updateEmployeeWorkLocation
        .matchRejected,
      (state, action: any) => {
        console.log(action.payload);
        if (action?.payload?.data?.errors) {
          state.messageError = 'Incorrect Form Filled';
        } else {
          state.messageError = 'Incorrect Form Filled';
        }
      }
    );
    builder.addMatcher(
      EmployeeWorkLocationApiSlice.endpoints.updateEmployeeWorkLocation
        .matchFulfilled,
      (state, action) => {
        if (action.payload.typeOfChange === 'UPDATED') {
          state.messageSuccess = 'Work Location updated';
        } else if (action.payload.typeOfChange === 'CORRECTED') {
          state.messageSuccess = 'Work Location corrected';
        }
      }
    );
    builder.addMatcher(
      EmployeeWorkLocationApiSlice.endpoints.cancelChangesWorkLocation
        .matchFulfilled,
      (state, _action) => {
        state.messageSuccess = 'Upcoming Changes cancelled';
      }
    );
    builder.addMatcher(
      employeeEmploymentApiSlice.endpoints.updateEmployment.matchFulfilled,
      (state, action) => {
        if (action.payload.typeOfChange === 'UPDATED') {
          state.messageSuccess = 'Employment updated';
        } else if (action.payload.typeOfChange === 'CORRECTED') {
          state.messageSuccess = 'Employment corrected';
        }
      }
    );
    builder.addMatcher(
      employeeExperienceApiSlice.endpoints.createExperience.matchFulfilled,
      (state, _action) => {
        state.messageSuccess = 'Experience Info added';
      }
    );
    builder.addMatcher(
      employeeExperienceApiSlice.endpoints.cancelChangesExperience
        .matchFulfilled,
      (state, _action) => {
        state.messageSuccess = 'Upcoming Changes cancelled';
      }
    );
    builder.addMatcher(
      employeeExperienceApiSlice.endpoints.deleteExperience.matchFulfilled,
      (state, _action) => {
        state.messageSuccess = 'Experience Info deleted';
      }
    );
    builder.addMatcher(
      employeeExperienceApiSlice.endpoints.updateExperience.matchFulfilled,
      (state, _action) => {
        if (_action.payload.typeOfChange === 'UPDATED') {
          state.messageSuccess = 'Experience Info updated';
        } else if (_action.payload.typeOfChange === 'CORRECTED') {
          state.messageSuccess = 'Experience Info corrected';
        }
      }
    );
    builder.addMatcher(
      employeeApiSlice.endpoints.createEmployeeCostCenter.matchFulfilled,
      (state, _action) => {
        state.messageSuccess = 'Cost Center Info added';
      }
    );
    builder.addMatcher(
      employeeApiSlice.endpoints.createEmployeeCostCenter.matchRejected,
      (state, action) => {
        const messageError = action.payload as any;
        state.messageError =
          messageError.data?.message || 'Failed add Cost Center';
      }
    );
    builder.addMatcher(
      employeeApiSlice.endpoints.deleteEmployeeCostCenter.matchFulfilled,
      (state, _action) => {
        state.messageSuccess = 'Cost Center deleted';
      }
    );
    builder.addMatcher(
      employeeApiSlice.endpoints.deleteEmployeeCostCenter.matchRejected,
      (state, action) => {
        const messageError = action.payload as any;
        state.messageError =
          messageError.data?.message || 'Failed remove Cost Center';
      }
    );
    builder.addMatcher(
      employeeApiSlice.endpoints.updateEmployeeCostCenter.matchFulfilled,
      (state, action) => {
        console.log(action.payload);
        if (action.payload?.typeOfChange === 'UPDATED') {
          state.messageSuccess = 'Cost Center Info updated';
        }
        if (action.payload?.typeOfChange === 'CORRECTED') {
          state.messageSuccess = 'Cost Center Info corrected';
        }
      }
    );
    builder.addMatcher(
      employeeApiSlice.endpoints.updateEmployeeCostCenter.matchRejected,
      (state, action) => {
        const messageError = action.payload as any;
        state.messageError =
          messageError.data?.message || 'Failed update Cost Center';
      }
    );
    builder.addMatcher(
      employeeApiSlice.endpoints.createEmployeeBank.matchFulfilled,
      (state, _action) => {
        state.messageSuccess = 'Bank Info added';
      }
    );
    builder.addMatcher(
      employeeApiSlice.endpoints.createEmployeeBank.matchRejected,
      (state, action) => {
        const messageError = action.payload as any;
        state.messageError = messageError.data?.message || 'Failed add Bank';
      }
    );
    builder.addMatcher(
      employeeApiSlice.endpoints.deleteEmployeeBank.matchFulfilled,
      (state, _action) => {
        state.messageSuccess = 'Bank Info deleted';
      }
    );
    builder.addMatcher(
      employeeApiSlice.endpoints.deleteEmployeeBank.matchRejected,
      (state, action) => {
        const messageError = action.payload as any;
        state.messageError = messageError.data?.message || 'Failed remove Bank';
      }
    );
    builder.addMatcher(
      employeeApiSlice.endpoints.updateEmployeeBank.matchFulfilled,
      (state, _action) => {
        state.messageSuccess = 'Bank Info updated';
      }
    );
    builder.addMatcher(
      employeeApiSlice.endpoints.updateEmployeeBank.matchRejected,
      (state, action) => {
        const messageError = action.payload as any;
        state.messageError = messageError.data?.message || 'Failed update Bank';
      }
    );
    builder.addMatcher(
      employeeApiSlice.endpoints.cancelEmployeeBank.matchFulfilled,
      (state, _action) => {
        state.messageSuccess = 'Upcoming Changes cancelled';
      }
    );
    builder.addMatcher(
      employeeFamilyApiSlice.endpoints.createFamily.matchFulfilled,
      (state, _action) => {
        state.messageSuccess = 'Family Info added';
      }
    );
    builder.addMatcher(
      employeeFamilyApiSlice.endpoints.updateFamily.matchFulfilled,
      (state, _action) => {
        if (_action.payload.typeOfChange === 'UPDATED') {
          state.messageSuccess = 'Family Info updated';
        } else if (_action.payload.typeOfChange === 'CORRECTED') {
          state.messageSuccess = 'Family Info corrected';
        }
      }
    );
    builder.addMatcher(
      employeeFamilyApiSlice.endpoints.cancelChangesFamily.matchFulfilled,
      (state, _action) => {
        state.messageSuccess = 'Upcoming Changes cancelled';
      }
    );
    builder.addMatcher(
      employeeApiSlice.endpoints.createEmployeeFiles.matchFulfilled,
      (state, _action) => {
        state.messageSuccess = 'Files Info added';
      }
    );
    builder.addMatcher(
      employeeApiSlice.endpoints.createEmployeeFiles.matchRejected,
      (state, action) => {
        const messageError = action.payload as any;
        state.messageError = messageError.data?.message || 'Failed add Files';
      }
    );
    builder.addMatcher(
      employeeApiSlice.endpoints.deleteEmployeeFiles.matchFulfilled,
      (state, _action) => {
        state.messageSuccess = 'Files Info deleted';
      }
    );
    builder.addMatcher(
      employeeApiSlice.endpoints.deleteEmployeeFiles.matchRejected,
      (state, action) => {
        const messageError = action.payload as any;
        state.messageError =
          messageError.data?.message || 'Failed delete Files';
      }
    );
    builder.addMatcher(
      employeeApiSlice.endpoints.updateEmployeeFiles.matchFulfilled,
      (state, _action) => {
        state.messageSuccess = 'Files Info updated';
      }
    );
    builder.addMatcher(
      employeeApiSlice.endpoints.updateEmployeeFiles.matchRejected,
      (state, action) => {
        const messageError = action.payload as any;
        state.messageError =
          messageError.data?.message || 'Failed update Files';
      }
    );
    builder.addMatcher(
      employeeApiSlice.endpoints.cancelChangesEmployeeFiles.matchFulfilled,
      (state, _action) => {
        state.messageSuccess = 'Upcoming Changes cancelled';
      }
    );
    builder.addMatcher(
      employeeEducationApiSlice.endpoints.createEmployeeEducation
        .matchFulfilled,
      (state, _action) => {
        state.messageSuccess = 'Education Info added';
      }
    );
    builder.addMatcher(
      employeeEducationApiSlice.endpoints.cancelChangesEmployeeEducation
        .matchFulfilled,
      (state, _action) => {
        state.messageSuccess = 'Upcoming Changes cancelled';
      }
    );
    builder.addMatcher(
      employeeFamilyApiSlice.endpoints.deleteFamily.matchFulfilled,
      (state, _action) => {
        state.messageSuccess = 'Family Info deleted';
      }
    );
    builder.addMatcher(
      employeeEducationApiSlice.endpoints.deleteEmployeeEducation
        .matchFulfilled,
      (state, _action) => {
        state.messageSuccess = 'Education Info deleted';
      }
    );
    builder.addMatcher(
      employeeEducationApiSlice.endpoints.updateEmployeeEducation
        .matchFulfilled,
      (state, _action) => {
        if (_action.payload.typeOfChange === 'UPDATED') {
          state.messageSuccess = 'Education Info updated';
        } else if (_action.payload.typeOfChange === 'CORRECTED') {
          state.messageSuccess = 'Education Info corrected';
        }
      }
    );
    builder.addMatcher(
      employeeAddressApiSlice.endpoints.createAddressEmployee.matchFulfilled,
      (state, _action) => {
        state.messageSuccess = 'Address added';
      }
    );
    builder.addMatcher(
      employeeAddressApiSlice.endpoints.updateAddressEmployee.matchFulfilled,
      (state, _action) => {
        if (_action.payload.typeOfChange === 'UPDATED') {
          state.messageSuccess = 'Address updated';
        } else if (_action.payload.typeOfChange === 'CORRECTED') {
          state.messageSuccess = 'Address corrected';
        }
      }
    );
    builder.addMatcher(
      employeeAddressApiSlice.endpoints.deleteAddressEmployee.matchFulfilled,
      (state, _action) => {
        state.messageSuccess = 'Address deleted';
      }
    );
    builder.addMatcher(
      employeeAddressApiSlice.endpoints.cancelAddressEmployee.matchFulfilled,
      (state, _action) => {
        state.messageSuccess = 'Upcoming Changes cancelled';
      }
    );
    builder.addMatcher(
      employeePersonalApiSlice.endpoints.updatePersonalInfo.matchFulfilled,
      (state, _action) => {
        if (_action.payload.typeOfChange === 'UPDATED') {
          state.messageSuccess = 'Personal Info updated';
        } else if (_action.payload.typeOfChange === 'CORRECTED') {
          state.messageSuccess = 'Personal Info corrected';
        }
      }
    );
    builder.addMatcher(
      employeePersonalApiSlice.endpoints.updatePersonalInfo.matchRejected,
      (state, action) => {
        const messageError = action.payload as any;
        state.messageError =
          messageError.data?.message || 'Failed update Personal Info';
      }
    );
    builder.addMatcher(
      employeePersonalApiSlice.endpoints.cancelPersonalInfo.matchFulfilled,
      (state, _action) => {
        state.messageSuccess = 'Upcoming Changes cancelled';
      }
    );
    builder.addMatcher(
      employeeApiSlice.endpoints.cancelUpcomingEmployeeCostCenter
        .matchFulfilled,
      (state, _action) => {
        state.messageSuccess = 'Upcoming Changes cancelled';
      }
    );
    // EMPLOYEE STATUTORY
    builder.addMatcher(
      employeeStatutoryApiSlice.endpoints.createEmployeeStatutory
        .matchFulfilled,
      (state, _action) => {
        state.messageSuccess = 'Statutory Info added';
      }
    );
    builder.addMatcher(
      employeeStatutoryApiSlice.endpoints.updateEmployeeStatutory
        .matchFulfilled,
      (state, _action) => {
        if (_action.payload.typeOfChange === 'UPDATED') {
          state.messageSuccess = 'Statutory Info updated';
        } else if (_action.payload.typeOfChange === 'CORRECTED') {
          state.messageSuccess = 'Statutory Info corrected';
        }
      }
    );
    builder.addMatcher(
      employeeStatutoryApiSlice.endpoints.deleteEmployeeStatutory
        .matchFulfilled,
      (state, _action) => {
        state.messageSuccess = 'Statutory Info deleted';
      }
    );
    builder.addMatcher(
      employeeStatutoryApiSlice.endpoints.deleteEmployeeStatutory.matchRejected,
      (state, _action) => {
        const payload = _action.payload as any;
        state.messageError = payload?.data?.message;
      }
    );
    builder.addMatcher(
      employeeStatutoryApiSlice.endpoints.cancelEmployeeChangesStatutory
        .matchFulfilled,
      (state, _action) => {
        state.messageSuccess = 'Upcoming Changes cancelled';
      }
    );
    builder.addMatcher(
      employeeEmploymentApiSlice.endpoints.cancelChangesEmployment
        .matchFulfilled,
      (state, _action) => {
        state.messageSuccess = 'Upcoming Changes cancelled';
      }
    );
    builder.addMatcher(
      employeeApiSlice.endpoints.bulkUploadEmployee.matchFulfilled,
      (state, _action) => {
        state.messageSuccess = 'Success upload and run progress';
      }
    );
    builder.addMatcher(
      employeeRehireApiSlice.endpoints.createRehire.matchFulfilled,
      (state, action) => {
        const payload = action.payload as any;
        console.log(payload);
        if (payload.joinDate > payload.effectiveDate) {
          state.messageSuccess = 'Rehirement submitted';
        }
        if (payload.effectiveDate >= payload.joinDate) {
          state.messageSuccess = 'Employee rehired';
        }
      }
    );
    builder.addMatcher(
      employeeRehireApiSlice.endpoints.createRehire.matchRejected,
      (state, _action) => {
        const messageError = _action.payload as any;
        state.messageError = messageError?.data?.message;
      }
    );
    builder.addMatcher(
      employeeRehireApiSlice.endpoints.cancelUpcomingRehire.matchFulfilled,
      (state, action) => {
        state.messageSuccess = 'Rehirement cancelled';
      }
    );
    builder.addMatcher(
      employeeRehireApiSlice.endpoints.cancelUpcomingRehire.matchRejected,
      (state, _action) => {
        const messageError = _action.payload as any;
        state.messageError = messageError?.data?.message;
      }
    );
    // TERMINATION
    builder.addMatcher(
      employeeTerminationApiSlice.endpoints.createTermination.matchFulfilled,
      (state, _action) => {
        state.messageSuccess = 'Termination submitted';
      }
    );
    builder.addMatcher(
      employeeTerminationApiSlice.endpoints.cancelChangesTermination
        .matchFulfilled,
      (state, _action) => {
        state.messageSuccess = 'Termination Info canceled';
      }
    );
    builder.addMatcher(
      employeeTerminationApiSlice.endpoints.updateTermination.matchFulfilled,
      (state, _action) => {
        state.messageSuccess = 'Termination Info updated';
      }
    );
    builder.addMatcher(
      employeeTerminationApiSlice.endpoints.withdrawTermintaion.matchFulfilled,
      (state, _action) => {
        state.messageSuccess = 'Termination withdrawn';
      }
    );
  },
});

export const { removeMessage } = employeeSlice.actions;
export const employeeReducer = employeeSlice.reducer;
