import { Box, Grid, SxProps, Typography, useTheme } from '@mui/material';
import { ReactNode } from 'react';
import Tags from '../../atom/tags/Tags';
import formatString from '../../../utils/upperCaseToNormal';
import rendertags from '../../../utils/renderTags';

interface IWorkflowItemProps {
  title: ReactNode | string;
  image: ReactNode;
  description: string;
  style?: SxProps;
  icon?: ReactNode | null;
  data?: any;
  status?: string;
}

const WorkflowItem = ({
  title,
  image,
  description,
  style,
  icon,
  data,
  status,
}: IWorkflowItemProps) => {
  const { palette } = useTheme();
  return (
    <Grid item container gap="16px" sx={{ ...style }}>
      <Grid
        item
        sx={{
          borderRadius: '40px',
          width: '40px',
          height: '40px',
          backgroundColor: palette.gray[10],
          overflow: 'hidden',
          border: `1px solid ${palette.gray[20]}`,
        }}
      >
        {image}
      </Grid>
      <Grid item container direction="column" gap="4px" xs>
        <Grid item>
          <Box sx={{ display: 'flex', gap: '.5rem' }}>
            <Typography
              component="div"
              variant="body14"
              fontWeight={700}
              color={palette.gray[100]}
              sx={{
                '& svg': {
                  '& path': {
                    stroke: `${palette.blue[90]} !important`,
                    strokeWidth: 1.5,
                  },
                },
              }}
            >
              {title}
            </Typography>
            {icon}
          </Box>
        </Grid>
        <Grid item>
          <Typography
            variant="body12"
            fontWeight={400}
            color={palette.gray[80]}
          >
            {description}
          </Typography>
        </Grid>
      </Grid>
      <Grid item>
        {(data?.actionCode || status) && (
          <Tags
            label={formatString(data?.actionCode || status || 'Requested')}
            variant={rendertags(data?.actionCode || status || '')}
          />
        )}
      </Grid>
    </Grid>
  );
};

export default WorkflowItem;
