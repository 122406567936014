import { Box, SxProps, useTheme, useMediaQuery } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { useLogoutMutation } from '../../../store/feature/service/authApiSlice';
import {
  useUserTenantListQuery,
  homeApiSlice,
} from '../../../store/feature/service/homeApiSlice';
import { Button } from '../../atom';
import {
  DotsGrid,
  LogoMark,
  LogOut01,
  LogoWIthLabel,
  Menu01,
} from '../../atom/icon';
import { Modal } from '../../molecule';
import LogoutPopover from '../../molecule/LogoutPopover/LogoutPopover';
import { openSideBar } from '../../../store/feature/responsiveSlice';
import { useAppDispatch } from '../../../store';
import SwitchTenantPopover from '../../molecule/switchTenantPopover/SwitchTenantPopover';
import { useGetProfileTenantQuery } from '../../../store/feature/service/changeProfileRename';
import About from '../../molecule/modal/About';
import { useDisclosure } from '../../../hooks/useDisclosure';
import { ReleaseNote } from '../../../@types/HomeTypes';
import { useGetUserAccessesSpecificFunctionQuery } from '../../../store/feature/service/userAccessApiSlice';
import { setDataAccess } from '../../../store/feature/userAccessSlice';

const Header = () => {
  const [latestReleaseNotes, setLatestReleaseNotes] = useState<ReleaseNote>();
  const [openModalAbout, { toggle }] = useDisclosure();
  const TENANT = window.location.hostname.split('.')[0].toUpperCase();

  const { data: profileData } = useGetProfileTenantQuery({
    tenantID: TENANT,
  });
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );
  const [switchTenantAchorEl, setSwitchTenantAchorEl] =
    React.useState<HTMLButtonElement | null>(null);
  const [openModal, setOpenModal] = React.useState(false);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClickDotGrid = (event: React.MouseEvent<HTMLButtonElement>) => {
    setSwitchTenantAchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const [fetchReleaseNotes, { isLoading: loadingFetchReleaseNotes }] =
    homeApiSlice.endpoints.releaseNotes.useLazyQuery();

  const onClickAbout = async () => {
    toggle();

    const response = await fetchReleaseNotes({ page: 1, limit: 10 });

    setLatestReleaseNotes(response?.data?.data?.[0]);
  };

  const { palette } = useTheme();
  const navigate = useNavigate();

  const [logout, { isLoading }] = useLogoutMutation();
  const { data } = useUserTenantListQuery();
  const matches = useMediaQuery('(max-width:1023px)');
  const dispatch = useAppDispatch();

  const { data: dataAccess } = useGetUserAccessesSpecificFunctionQuery({});

  const style: SxProps = {
    height: '56px',
    borderBottom: '1px solid #DCE0E5',
    display: 'flex',
    justifyContent: 'space-between',
    boxSizing: 'border-box',
    padding: '8px 16px',
    boxShadow: `0px 1px 2px rgba(16, 27, 40, 0.05)`,
    '& .left-container': {
      display: 'flex',
      alignItems: 'center',
      gap: '12px',
    },
    '& .button-grid': {
      height: '36px',
      width: '36px',
      minWidth: '36px',
    },
    '& .left-button-logo': {
      height: '40px',
      width: '40px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      borderRadius: '90px',
      minWidth: '40px',
      boxSizing: 'border-box',
      border: '1px solid #DCE0E5',
    },
  };

  useEffect(() => {
    if (dataAccess) {
      dispatch(setDataAccess(dataAccess));
    }
  }, [JSON.stringify(dataAccess)]);

  return (
    <>
      <Modal
        open={openModal}
        leftButtonLabel="Cancel"
        rightButonLabel="Yes, Log Out"
        rightButtonProps={{
          variant: 'primary',
          destructive: true,
          onClick: async () => {
            await logout();
            setTimeout(() => {
              navigate('/login');
            }, 500);
          },
        }}
        leftButtonProps={{
          variant: 'outlined',
          onClick: () => {
            setOpenModal(false);
            handleClose();
          },
        }}
        type="center"
        showCloseIcon
        title="Log Out"
        caption="Are you sure want to log out?"
        onClose={() => {
          handleClose();
          setOpenModal(false);
        }}
        icon={
          <Box
            sx={{
              background: palette.red[20],
              height: '48px',
              width: '48px',
              borderRadius: '90px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              '& svg path': {
                stroke: palette.red[70],
              },
            }}
          >
            <LogOut01 />
          </Box>
        }
      />
      <Box sx={style}>
        <Box className="left-container">
          <Button
            className="button-grid"
            variant="tertiary"
            onClick={handleClickDotGrid}
          >
            <Box
              sx={{
                '& svg path': {
                  stroke: switchTenantAchorEl ? '#045FC4' : '#101B28',
                },
                bgcolor: switchTenantAchorEl ? '#EDF5FF' : 'transparent',
                height: '36px',
                minWidth: '36px',
                borderRadius: '8px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <DotsGrid />
            </Box>
          </Button>
          <Box
            sx={{
              '& img': {
                width: '60px',
                height: '32px',
                objectFit: 'cover',
                borderRadius: '3px',
              },
            }}
          >
            {profileData?.fileUrl ? (
              <img src={profileData?.fileUrl} alt="logo" />
            ) : (
              <LogoWIthLabel />
            )}
          </Box>
        </Box>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: '12px',
          }}
        >
          {/* <Button className="button-grid" variant="tertiary">
            <Globe02 />
          </Button> */}
          {matches && (
            <Button
              variant="outlined"
              sx={{
                minWidth: '0',
                width: '36px',
                height: '36px',
                p: '8px',
                borderRadius: '8px',
              }}
              onClick={() => dispatch(openSideBar())}
            >
              <Menu01 />
            </Button>
          )}
          <Button
            variant="outlined"
            sx={{
              minWidth: '0',
              width: '40px',
              height: '40px',
              p: '8px',
              borderRadius: '100px',
            }}
            onClick={handleClick}
          >
            <LogoMark />
          </Button>
        </Box>
        <LogoutPopover
          handleClose={handleClose}
          onClick={() => {
            setOpenModal(!openModal);
          }}
          anchorEl={anchorEl}
          handleClickAbout={onClickAbout}
        />
        <SwitchTenantPopover
          anchorEl={switchTenantAchorEl}
          handleClose={() => setSwitchTenantAchorEl(null)}
          data={data}
        />
        <About
          open={openModalAbout}
          handleClose={() => toggle()}
          data={latestReleaseNotes}
          loading={loadingFetchReleaseNotes}
        />
      </Box>
    </>
  );
};

export default Header;
