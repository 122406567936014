const headerKey = {
  name: 'Name',
  code: 'Code',
  maxHeadcount: 'Max. Headcount',
};

const activeDetailHeaderKey: { [key: string]: any } = {
  effectiveDate: 'Effective Date',
  name: 'Name',
  code: 'Code',
  degree: 'Degree',
  fieldOfStudy: 'Field of Study',
  skills: 'Skills',
  experience: 'Experience',
  certification: 'Certification',
  maxHeadCount: 'Max. Headcount',
  description: 'Description',
  totalEmployee: 'Total Employee',
};

const detailHeaderKey = {
  effectiveDate: 'Effective Date',
  name: 'Name',
  code: 'Code',
  degree: 'Degree',
  fieldOfStudy: 'Field of Study',
  skills: 'Skills',
  experience: 'Experience',
  certification: 'Certification',
  maxHeadCount: 'Max. Headcount',
  description: 'Description',
};

const defaultValuesJobPosition = {
  effectiveDate: null,
  name: null,
  code: null,
  degree: null,
  fieldOfStudy: null,
  skills: null,
  experience: null,
  certification: null,
  maxHeadCount: null,
  description: null,
};

export default {
  headerKey,
  detailHeaderKey,
  defaultValuesJobPosition,
  activeDetailHeaderKey,
};
