import {
  Backdrop,
  Box,
  CircularProgress,
  Typography,
  useTheme,
} from '@mui/material';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import Otp from '../../otp/Otp';

const OtpForm = <UseFieldArrayOptions extends Record<string, any>>(
  Props: UseFieldArrayOptions
) => {
  const { palette } = useTheme();
  const { t } = useTranslation();
  const { name, control, disabled, ...props } = Props;

  return (
    <Controller
      control={control}
      name={name || ''}
      render={({ field, formState }) => {
        const { errors } = formState as any;
        const isError =
          Object.keys(errors).length > 0 &&
          name &&
          Object.prototype.hasOwnProperty.call(errors, name);
        return (
          <Box>
            <Backdrop
              sx={{ zIndex: (theme: any) => theme.zIndex.drawer + 1 }}
              open={disabled}
            >
              <CircularProgress />
            </Backdrop>
            <Otp
              disabled={disabled}
              onChange={field.onChange}
              size={6}
              error={isError}
            />
            {isError && (
              <Typography
                component="p"
                variant="body14"
                color={palette.red[50]}
                textAlign="center"
              >
                {t(errors?.[name]?.message)}
              </Typography>
            )}
          </Box>
        );
      }}
    />
  );
};

export default OtpForm;
