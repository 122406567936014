import { CustomFieldsStatus } from '../@types/CustomFields';

const headerKey = {
  name: 'Name',
  sectionName: 'Section',
  fieldTypeName: 'Field Type',
};

const options = {
  sections: [
    {
      label: 'Personal',
      value: 'PERSONAL',
    },
    {
      label: 'Address',
      value: 'ADDRESS',
    },
    {
      label: 'Employment',
      value: 'EMPLOYMENT',
    },
    {
      label: 'Cost Center',
      value: 'COST_CENTER',
    },
    {
      label: 'Statutory',
      value: 'STATUTORY',
    },
    {
      label: 'Bank',
      value: 'BANK',
    },
    {
      label: 'Experience',
      value: 'EXPERIENCE',
    },
    {
      label: 'Education',
      value: 'EDUCATION',
    },
    {
      label: 'Files',
      value: 'FILES',
    },
  ],
  applyTo: [
    {
      label: 'All Employees',
      value: 'ALL_EMPLOYEES',
    },
    {
      label: 'Custom',
      value: 'CUSTOM',
    },
  ],
  type: [
    {
      label: 'Text Input',
      value: 'TEXT_INPUT',
    },
    {
      label: 'Radio Button',
      value: 'RADIO_BUTTON',
    },
    {
      label: 'Single Select',
      value: 'SINGLE_SELECT',
    },
    {
      label: 'Checkbox',
      value: 'CHECKBOX',
    },
    {
      label: 'Multiple Select',
      value: 'MULTIPLE_SELECT',
    },
    {
      label: 'Date Picker',
      value: 'DATE_PICKER',
    },
  ],
};

const paginationKey = {
  employee: 'employee',
  payItem: 'pay-item',
};

const stepperCustomField = [
  'FIELD CUSTOMIZATION',
  'EMPLOYEE SELECTION',
] as CustomFieldsStatus[];

const listVariant = [
  'FIELD CUSTOMIZATION',
  'EMPLOYEE SELECTION',
] as CustomFieldsStatus[];

const defaultCreatStepFieldCustomization = {
  name: '',
  fieldType: null,
  values: '',
  required: false,
  placeholder: '',
  helperText: null,
  applyTo: 'ALL_EMPLOYEES',
};

const headerKeyPayItemStep = {
  name: 'Name',
  sectionName: 'Code',
  fieldTypeName: 'Type',
};

const headerKeyEmployeeSetup = {
  employeeNumber: 'Employee ID',
  fullName: 'Name',
  jobPositionName: 'Job Position',
  organizationName: 'Organization',
  action: 'Action',
};

const applyToLabel: { [key: string]: any } = {
  ALL_EMPLOYEES: 'All Employees',
  CUSTOM: 'Custom',
};

export default {
  headerKey,
  options,
  paginationKey,
  stepperCustomField,
  listVariant,
  defaultCreatStepFieldCustomization,
  headerKeyPayItemStep,
  headerKeyEmployeeSetup,
  applyToLabel,
};
