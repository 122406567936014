import { Box, Grid, Typography, useTheme } from '@mui/material';
import { useState } from 'react';
import { useAppSelector } from '../../../../store';
import { download } from '../../../../utils/file';
import { objectMapper } from '../../../../utils/objectMap';
import LabelText from '../../../../components/atom/labelText/LabelText';
import HeaderAction from '../../../../components/molecule/action/HeaderAction';
import HistoricalTimeLine from '../../../../components/molecule/historicalTimeLine/HistoricalTimeLine';
import SubHeader from '../../../../components/molecule/subHeader/SubHeader';
import { useGetESSFileDetailQuery } from '../../../../store/feature/service/essEmployee';
import ModalFilePreview from '../../../../components/molecule/modal/FilePreview';

const FilesSection = ({ index, headerKey, groupId }: any) => {
  const selectedHeader = `employee/files/${groupId}`;
  const { headerDate } = useAppSelector(state => state.utils);
  const { palette } = useTheme();
  const [openModal, setModal] = useState(false);
  const { data } = useGetESSFileDetailQuery({
    dataId: groupId,
    effectiveDate: headerDate[selectedHeader],
  });
  const currentData: { [key: string]: any } = {
    ...objectMapper(data?.data?.old || {}),
    effectiveDate: (
      <HistoricalTimeLine headersState={data} selectedTab={selectedHeader} />
    ),
  };

  const upcomingData: { [key: string]: any } = {
    ...objectMapper(data?.data?.new || {}),
  };

  const handleClickFile = (data: any) => {
    if (data?.mimeType === 'application/pdf') {
      return window.open(data?.fileUrl, '_blank');
    }
    return setModal(true);
  };
  const customeCurrentComponent: { [key: string]: any } = {
    fileName: (
      <Typography
        color={
          data?.dataPresentationStatus === 'PREVIOUS'
            ? palette.red[50]
            : palette.blue[50]
        }
        sx={{
          cursor:
            data?.dataPresentationStatus !== 'PREVIOUS' ? 'pointer' : 'no-drop',
        }}
        variant="body14"
        component="span"
        onClick={() => handleClickFile(currentData)}
      >
        {currentData.fileName}
      </Typography>
    ),
  };
  const customeUpcommingComponent: { [key: string]: any } = {
    fileName: (
      <Typography
        sx={{ cursor: 'pointer' }}
        variant="body14"
        color={palette.green[50]}
        component="span"
        onClick={() => handleClickFile(upcomingData)}
      >
        {upcomingData.fileName}
      </Typography>
    ),
  };

  const item = {
    fileUrl: data?.data?.old?.fileUrl || '',
    fileName: data?.data?.old?.fileName || '',
    mimeType: data?.data?.old?.mimeType || '',
  };

  return (
    <Grid item container spacing={1}>
      {data?.data?.old?.fileName && (
        <ModalFilePreview
          isOpen={openModal}
          item={item}
          handleLeftButton={() => setModal(false)}
          setModalState={() => setModal(false)}
          handleDownload={async () => {
            await download({
              fileUrl: currentData?.fileUrl,
              fileName: currentData?.fileName,
            });
            setTimeout(() => setModal(false), 1000);
          }}
          // setModalState={() => setModal(false)}
        />
      )}

      {index === 0 && (
        <Grid item xs={12}>
          <HeaderAction label="Files Info" labelAdd="Files" />
        </Grid>
      )}
      <Grid item xs={12}>
        <Box
          sx={{
            '& .list': {
              display: 'flex',
              padding: '8px 12px',
            },
            '& .sub-title': {
              fontWeight: 700,
            },
            display: 'flex',
            flexDirection: 'column',
            gap: '20px',
          }}
        >
          <SubHeader label="Files" idx={index} isCorrectionEnabled={false} />
        </Box>
      </Grid>
      {Object.keys(headerKey).map((key: any) => (
        <Grid key={headerKey[key]} item xs={12}>
          <LabelText
            headerKey={headerKey[key]}
            currentData={currentData?.[key]}
            upcommingData={upcomingData?.[key]}
            dataPresentationStatus={data?.dataPresentationStatus}
            customeCurrentComponent={customeCurrentComponent?.[key]}
            customePreviousComponent={customeCurrentComponent?.[key]}
            customeUpcommingComponent={customeUpcommingComponent?.[key]}
          />
        </Grid>
      ))}
    </Grid>
  );
};

export default FilesSection;
