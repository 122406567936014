import { Box, SxProps, TextField, Typography, useTheme } from '@mui/material';
import React from 'react';
import { mergeDeep } from '../../../utils/deepMerge';

import { InputProps } from '../../../@types';

const ExtendInput = React.forwardRef((Props: InputProps, ref: any) => {
  const { palette } = useTheme();
  const {
    prefix,
    prefixIcon,
    suffix,
    suffixIcon,
    sx,
    disabled,
    multiline = false,
    ...props
  } = Props;

  const style: SxProps = {
    width: '100%',
    '& .MuiOutlinedInput-root': {
      height: multiline ? '100%' : '3rem',
      padding: multiline ? '12px 16px' : '0px',
      borderRadius: '8px',
      boxSizing: 'border-box',
      background: disabled ? palette.gray[20] : '#fff',
      '& input': {
        padding: '12px 12px',
        fontSize: '1rem',
        fontWeight: 400,
        '&:-webkit-autofill, &:-webkit-autofill:hover, &:-webkit-autofill:focus, &:-webkit-autofill:active':
          {
            transition:
              'background-color 5000s ease-in-out 0s, color 5000s ease-in-out 0s',
            transitionDelay: 'background-color 5000s, color 5000s',
          },
      },
      '& fieldset': {
        borderColor: `${palette.gray[50]}`,
      },

      '&.Mui-focused fieldset': {
        borderColor: `${palette.blue[50]}`,
        borderWidth: '1px',
      },
      '&.Mui-disabled .MuiOutlinedInput-notchedOutline': {
        borderColor: `${palette.gray[50]}`,
      },
      '&:hover': {
        '& input + div + .MuiOutlinedInput-notchedOutline': {
          borderColor: disabled ? palette.gray[50] : palette.blue[50],
        },
      },
      '& .start-adornment': {
        padding: '12px',
        background: palette.gray[50],
        borderTopLeftRadius: '8px',
        borderBottomLeftRadius: '8px',
        borderRightStyle: 'solid',
        borderRightColor: palette.gray[50],
        borderRightWidth: '1px',
      },
      '& .start-adornment-icon': {
        display: 'flex',
        paddingLeft: '12px',
      },
      '& .end-adornment': {
        padding: '12px',
        background: palette.gray[50],
        borderTopRightRadius: '8px',
        borderBottomRightRadius: '8px',
      },
    },
    '& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
      borderColor: 'orange',
    },
  };

  return (
    <TextField
      ref={ref}
      sx={mergeDeep(style, sx) as SxProps}
      variant="outlined"
      InputProps={{
        startAdornment: (
          <>
            {prefix && (
              <Box className="start-adornment">
                <Typography variant="body16" color={palette.gray[90]}>
                  {prefix}
                </Typography>
              </Box>
            )}
            {prefixIcon && (
              <Box className="start-adornment-icon">{prefixIcon}</Box>
            )}
          </>
        ),
        endAdornment: (
          <Box>
            {suffixIcon && (
              <Box className="start-adornment-icon">{suffixIcon}</Box>
            )}
            {suffix && (
              <Box className="end-adornment">
                <Typography variant="body16" color={palette.gray[90]}>
                  {suffix}
                </Typography>
              </Box>
            )}
          </Box>
        ),
      }}
      disabled={disabled}
      multiline={multiline}
      {...props}
    />
  );
});

export default ExtendInput;
