import { ActionMenuProps } from '../@types/TableProps';

const dateNumber = Array.from({ length: 31 }, (_, idx) => ({
  label: (idx + 1).toString(),
  value: idx + 1,
}));

const dateNumberStringValue = Array.from({ length: 31 }, (_, idx) => ({
  label: (idx + 1).toString(),
  value: (idx + 1).toString(),
}));

const headerKey = {
  name: 'Name',
  code: 'Code',
  legalEntityName: 'Legal Entity',
  payTypeName: 'Pay Type',
  payFrequencyName: 'Pay Frequency',
  totalEmployee: 'Total Employee',
};

const options = {
  payType: [
    {
      label: 'Regular',
      value: 'REGULAR',
    },
    {
      label: 'Off-Cycle',
      value: 'OFF_CYCLE',
    },
  ],
  payFrequency: [
    {
      label: 'Monthly',
      value: 'MONTHLY',
    },
    {
      label: 'Bimonthly',
      value: 'BIMONTHLY',
      disabled: true,
    },
  ],
  groupCode: [
    {
      label: 'All Employee',
      value: 'ALL',
    },
    {
      label: 'Custom',
      value: 'CUSTOM',
    },
  ],
  groupCategory: [
    {
      label: 'Job Grade',
      value: 'JOB_GRADE',
    },
    {
      label: 'Job Position',
      value: 'JOB_POSITION',
    },
    {
      label: 'Organization',
      value: 'ORGANIZATION',
    },
    {
      label: 'Citizenship',
      value: 'CITIZENSHIP',
    },
    {
      label: 'Tax Calculation Method',
      value: 'TAX_CALCULATION_METHOD',
    },
    {
      label: 'Individual Employee',
      value: 'INDIVIDUAL_EMPLOYEE',
    },
  ],
  payItemType: [
    {
      label: 'Earnings',
      value: 'EARNING',
    },
    {
      label: 'Deduction',
      value: 'DEDUCTION',
    },
  ],
  primaryPaymentMethodCode: [
    {
      label: 'Bank Transfer',
      value: 'BANK_TRANSFER',
    },
    {
      label: 'Cash',
      value: 'CASH',
    },
    {
      label: 'Cheque',
      value: 'CHEQUE',
    },
  ],
  taxCalculationMethod: [
    {
      label: 'Nett',
      value: 'NETT',
    },
    {
      label: 'Gross',
      value: 'GROSS',
    },
    {
      label: 'Gross Up',
      value: 'GROSS UP',
    },
    {
      label: 'Mix',
      value: 'MIX',
    },
  ],
};

const filterFields = [
  {
    name: 'legalEntityId',
    label: 'Legal Entity',
    type: 'selectPaginate',
    url: '/organization/legal-entities/dropdown',
    isMulti: true,
  },
  {
    name: 'payTypeCode',
    label: 'Pay Type',
    type: 'select',
    options: options.payType,
  },
  {
    name: 'payFrequency',
    label: 'Pay Frequency',
    type: 'select',
    options: options.payFrequency,
  },
  {
    name: 'cutOffStart',
    label: '',
    type: 'array',
    content: [
      {
        name: 'cuttOffStartFrom',
        label: 'Cut Off Start From',
        type: 'select',
      },
      {
        name: 'cuttOffStartTo',
        label: 'To',
        type: 'select',
      },
    ],
  },
  {
    name: 'cutOffEnd',
    label: '',
    type: 'array',
    content: [
      {
        name: 'cuttOffEndFrom',
        label: 'Cut Off End From',
        type: 'select',
        options: dateNumber,
      },
      {
        name: 'cuttOffEndTo',
        label: 'To',
        type: 'select',
        options: dateNumber,
      },
    ],
  },
  {
    name: 'totalEmployee',
    label: '',
    type: 'array',
    content: [
      {
        name: 'totalEmployeeFrom',
        label: 'Total Employee From',
        type: 'input',
        placeholder: 'e.g. 100',
      },
      {
        name: 'totalEmployeeTo',
        label: 'To',
        type: 'input',
        placeholder: 'e.g. 100',
      },
    ],
  },
  {
    name: 'payDate',
    label: '',
    type: 'array',
    content: [
      {
        name: 'payDateFrom',
        label: 'Pay Date From',
        type: 'select',
        options: dateNumber,
      },
      {
        name: 'payDateTo',
        label: 'To',
        type: 'select',
        options: dateNumber,
      },
    ],
  },
  {
    name: 'groupCode',
    label: 'Group',
    type: 'select',
    options: options.groupCode,
    onInputChange: (setValue: Function) => {
      setValue('groupCategory', '');
      setValue('groupValue', '');
    },
  },
  {
    name: 'groupCategory',
    label: 'Group Category',
    type: 'select',
    options: options.groupCategory,
    isMulti: true,
    // isDisabled: (watch: Function) => watch('groupCode')?.value === 'ALL',
    isDisabled: "watch('groupCode')?.value === 'ALL'",
    onInputChange: (setValue: Function, watch: Function) => {
      if (
        watch('groupCategory') &&
        watch('groupCategory').findIndex(
          (val: any) => val.value === 'INDIVIDUAL_EMPLOYEE'
        )
      ) {
        setValue('groupValue', '');
      }
    },
  },
  {
    name: 'groupValue',
    label: 'Group Value',
    type: 'select',
    isMulti: true,
    // isDisabled:
    //   "watch('groupCode')?.value === 'ALL' || (watch('groupCategory')) && watch('groupCategory').findIndex((val: any) => val.value === 'INDIVIDUAL_EMPLOYEE') !== -1)",
    isDisabled: "watch('groupCode')?.value === 'ALL'",
    // isDisabled:"watch('groupCategory').findIndex((val: any) => val.value === 'INDIVIDUAL_EMPLOYEE')",
  },
];

const defaultFilterValue = {
  legalEntityId: null,
  payTypeCode: '',
  payFrequency: '',
};

const filterPayItemFields = [
  {
    name: 'groupId',
    label: 'Group',
    type: 'selectPaginate',
    url: '/payroll/pay-item-groups/dropdown',
    isMulti: true,
  },
  {
    name: 'typeCode',
    label: 'Type',
    type: 'select',
    options: options.payItemType,
    isMulti: true,
  },
];

const defaultValuePayItemFilterFields = {
  groupId: [],
  typeCode: null,
};

const defaultEmployeeFilterValue = {
  primaryPaymentMethodCode: [],
  primaryBankId: [],
  taxCalculationMethodCode: [],
  legalEntityId: [],
  organizationId: [],
  jobPositionId: [],
  jobGradeId: [],
  employmentTypeId: [],
  employmentStatusId: [],
  joinDateFrom: '',
  joinDateTo: '',
  endDateFrom: '',
  endDateTo: '',
  citizenshipId: [],
};

const paginationKey = {
  employee: 'employee',
  payItem: 'pay-item',
};

const stepperRunPayroll = ['BASIC INFO', 'PAY ITEM'];

const stepperRunPayrollEdit = ['SETUP', 'ASSIGN'];

const listVariant = ['BASIC INFO', 'PAY ITEM'];
const listVariantEdit = ['SETUP', 'ASSIGN'];

const cutOffType = [
  { label: 'Current Month', value: 'CURRENT_MONTH' },
  { label: 'Previous Month', value: 'PREVIOUS_MONTH' },
];

const defaultCreateStepSetupValue = {
  name: '',
  code: '',
  legalEntity: null,
  payType: 'REGULAR',
  payFrequency: 'MONTHLY',
  cutOff: [
    {
      id: 1,
      cutOffFromDate: null,
      cutOffFromType: null,
      cutOffToDate: null,
      cutOffToType: null,
      payDate: null,
    },
  ],
};

const headerKeyPayItem = {
  name: 'Name',
  code: 'Code',
  groupName: 'Group',
};

const dictionaryPayGroup: { [key: string]: any } = {
  payFrequency: {
    MONTHLY: 'Monthly',
    BIMONTHLY: 'Bimonthly',
  },
  payType: {
    REGULAR: 'Regular',
    OFF_CYCLE: 'Off-Cycle',
  },
};

export default {
  headerKey,
  filterFields,
  defaultFilterValue,
  filterPayItemFields,
  defaultValuePayItemFilterFields,
  options,
  dateNumber,
  defaultEmployeeFilterValue,
  paginationKey,
  stepperRunPayroll,
  listVariant,
  cutOffType,
  defaultCreateStepSetupValue,
  headerKeyPayItem,
  dateNumberStringValue,
  stepperRunPayrollEdit,
  listVariantEdit,
  dictionaryPayGroup,
};
