import { Box, Popover, Typography, useTheme } from '@mui/material';
import { Check } from '../../atom/icon';
import { TenantListType } from '../../../@types/UserTenantType';

type SwitchTenantPopoverProps = {
  anchorEl: HTMLButtonElement | null;
  handleClose: () => void;
  data: TenantListType | undefined;
};

const SwitchTenantPopover = ({
  anchorEl,
  handleClose,
  data,
}: SwitchTenantPopoverProps) => {
  const { palette } = useTheme();
  const openAction = Boolean(anchorEl);
  const currentHostName = window.location.hostname;
  const generateTenantList = () =>
    data
      ? data.reduce((tenants, d) => {
          tenants.push(d);
          tenants.push({
            ...d,
            name: `${d.name} Web ESS`,
            host: `${d.host}/ess/employee`,
          });
          return tenants;
        }, [] as TenantListType)
      : [];

  return (
    <Popover
      open={openAction}
      anchorEl={anchorEl}
      onClose={handleClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      sx={{
        marginRight: '15px',
        '& .MuiPaper-root': {
          borderRadius: '8px',
          maxHeight: '348px',
        },
        '& .MuiPopover-paper': {
          boxShadow: '0px 2px 4px -2px #101B280F, 0px 4px 8px -2px #101B281A',
          borderRadius: '8px',
        },
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          borderRadius: '8px',
          bgcolor: 'green',
          p: '4px 0 4px 0',
          minWidth: '320px',
        }}
      >
        {generateTenantList().map(data => {
          const { host, logoURL, name } = data;
          return (
            <Box
              key={logoURL}
              sx={{
                display: 'flex',
                alignItems: 'center',
                gap: '12px',
                p: '12px 16px 12px 16px',
                cursor: 'pointer',
                '&:hover': {
                  background: '#F9FAFB',
                },
              }}
              onClick={() => {
                window.open(`https://${host}`, '_blank');
              }}
            >
              <Box
                sx={{
                  borderRadius: '8px',
                  width: '40px',
                  height: '40px',
                  backgroundColor: palette.gray[10],
                  overflow: 'hidden',
                  border: `1px solid ${palette.gray[20]}`,
                }}
              >
                <img
                  src={logoURL}
                  alt="Tenant logo"
                  style={{
                    width: '40px',
                    height: '45px',
                    objectFit: 'cover',
                  }}
                />
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  width: `calc(100% - 60px)`,
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '4px',
                  }}
                >
                  <Typography variant="body14" fontWeight={600} color="#101B28">
                    {name}
                  </Typography>
                  <Typography variant="body12" color="#8A98A8">
                    {host}
                  </Typography>
                </Box>
                {currentHostName === host && (
                  <Box
                    sx={{
                      '& svg path': {
                        stroke: palette.green[50],
                      },
                    }}
                  >
                    <Check />
                  </Box>
                )}
              </Box>
            </Box>
          );
        })}
      </Box>
    </Popover>
  );
};

export default SwitchTenantPopover;
