import { createSlice } from '@reduxjs/toolkit';
import { changeLogoSlice as changeLogoApiSlice } from './service/changeProfileRename';

interface initialStateI {
  messageSuccess: string;
  messageError: string;
  dataImage: Array<{
    id: string;
    mimeType: string;
    name: string;
    percent: number;
    previewUrl: string;
    size: number;
  }>;
  isDeleted: boolean;
}

const initialState: initialStateI = {
  messageSuccess: '',
  messageError: '',
  dataImage: [],
  isDeleted: false,
};

const changeLogoSlice = createSlice({
  name: 'careerTransition',
  initialState,
  reducers: {
    setDataImage: (state, payload) => {
      state.dataImage = payload.payload;
    },
    removeMessageProfile: state => {
      state.messageSuccess = '';
    },
    setRemove: state => {
      state.isDeleted = true;
    },
    resetImage: state => {
      state.dataImage = [];
    },
  },
  extraReducers(builder) {
    builder.addMatcher(
      changeLogoApiSlice.endpoints.changeProfileEmployee.matchFulfilled,
      state => {
        state.messageSuccess = 'Photo changed';
      }
    );
    builder.addMatcher(
      changeLogoApiSlice.endpoints.changeProfileLegalEntity.matchFulfilled,
      state => {
        state.messageSuccess = 'Photo changed';
      }
    );
    builder.addMatcher(
      changeLogoApiSlice.endpoints.changeProfileApp.matchFulfilled,
      state => {
        state.messageSuccess = 'Photo changed';
      }
    );
    builder.addMatcher(
      changeLogoApiSlice.endpoints.resetProfileApp.matchFulfilled,
      state => {
        state.messageSuccess = 'Logo reset';
      }
    );
  },
});

export const { setDataImage, removeMessageProfile, resetImage } =
  changeLogoSlice.actions;
export const changeLogoSliceReducer = changeLogoSlice.reducer;
