import {
  FormControl,
  FormLabel,
  SxProps,
  Typography,
  useTheme,
} from '@mui/material';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { InputProps } from '../../../../@types';
import { Textarea } from '../../../atom';
import { getMessageFromObject } from '../../../../utils/objectMap';

export type CustomProps = {
  label: string;
  showLabelOnError?: boolean;
  showCounter?: boolean;
};

const TextareaWithLabel = <UseFieldArrayOptions extends Record<string, any>>(
  Props: InputProps & UseFieldArrayOptions & CustomProps
) => {
  const { palette } = useTheme();
  const { t } = useTranslation();

  const {
    sx,
    name,
    control,
    label,
    showLabelOnError,
    showCounter,
    disabled,
    maxLength,
    placeholder,
    required = true,
    ...props
  } = Props;
  const style = {
    '& .MuiFormLabel-root': {
      marginBottom: '0.5rem',
    },
    '& .input--error ': {
      '& .MuiOutlinedInput-notchedOutline': {
        borderColor: palette.red[50],
      },
    },
    ...sx,
  } as SxProps;
  return (
    <FormControl sx={style} fullWidth>
      <FormLabel htmlFor={name}>
        <Typography color={palette.gray[100]} variant="body14" fontWeight={500}>
          {label}{' '}
          {!required && (
            <Typography
              component="span"
              variant="body14"
              color={palette.gray[80]}
            >
              (optional)
            </Typography>
          )}
        </Typography>
      </FormLabel>
      <Controller
        control={control}
        name={name || ''}
        render={({ field, formState }) => {
          const { errors } = formState as any;
          const isError =
            Object.keys(errors).length > 0 &&
            name &&
            getMessageFromObject(errors, name);
          return (
            <>
              <Textarea
                {...field}
                disabled={disabled}
                size="small"
                id={name}
                inputProps={{ maxLength, placeholder }}
                rows={4}
                className={isError ? 'input--error' : ''}
                multiline
                {...props}
              />
              {showCounter && (
                <Typography
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: isError ? 'space-between' : 'flex-end',
                    margin: 0,
                    marginTop: '8px',
                    color: palette.gray[80],
                  }}
                  variant="caption12"
                  component="div"
                >
                  {isError && (
                    <Typography
                      component="p"
                      variant="body14"
                      color={palette.red[50]}
                    >
                      {`${t(getMessageFromObject(errors, name))}`}
                    </Typography>
                  )}
                  {field?.value?.toString().length || 0} / {maxLength}
                </Typography>
              )}
            </>
          );
        }}
      />
    </FormControl>
  );
};

export default TextareaWithLabel;
