import React from 'react';
import { RouteNode } from '../../../@types';

const List = React.lazy(() => import('../../../pages/EssAccessControl/List'));
const Create = React.lazy(
  () => import('../../../pages/EssAccessControl/Create')
);
const Detail = React.lazy(
  () => import('../../../pages/EssAccessControl/Detail')
);
const Update = React.lazy(
  () => import('../../../pages/EssAccessControl/Update')
);

const EssAccessControl: RouteNode[] = [
  {
    label: 'ESS Access Control',
    path: 'ess-access-control',
    child: null,
    component: List,
  },
  {
    label: '',
    path: 'ess-access-control/create',
    child: null,
    component: Create,
  },
  {
    label: '',
    path: 'ess-access-control/detail/:id',
    child: null,
    component: Detail,
  },
  {
    label: '',
    path: 'ess-access-control/update/:id',
    child: null,
    component: Update,
  },
];

export default EssAccessControl;
