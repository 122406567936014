import { lazy } from 'react';

const List = lazy(() => import('../../../pages/timeAnalytics/List'));

export const AnalyticRoute = [
  {
    label: 'Analytics',
    child: null,
    path: 'analytics',
    component: List,
  },
];
