import { Grid } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import { filesHeaderKey } from '../../../../constants/employee';
import FilesSection from './FilesSection';
import File04 from '../../../../components/atom/icon/File04';
import EmptyCard from '../../../../components/molecule/emptyCard/EmptyCard';
import { useGetESSFileListQuery } from '../../../../store/feature/service/essEmployee';

const Files = () => {
  const navigate = useNavigate();
  const params = useParams();
  const { data }: any = useGetESSFileListQuery({});

  return (
    <Grid container spacing={2}>
      <Grid item xs={6} container rowSpacing={3}>
        {data?.map((item: any, index: number) => (
          <FilesSection
            key={JSON.stringify(item)}
            index={index}
            headerKey={filesHeaderKey}
            groupId={item?.data?.old?.groupId || item?.data?.new?.groupId}
          />
        ))}
        {data?.length < 1 && (
          <EmptyCard
            icon={<File04 />}
            label="Files Info"
            customLabel="Files"
            isESS
            onClickAddTop={() =>
              navigate(`/employee/detail/${params.id}/files/create`)
            }
          />
        )}
      </Grid>
    </Grid>
  );
};

export default Files;
