import { apiSlice } from '../../api/apiSlice';

type ParamsQuery = {
  employeeId?: string;
  groupId?: string;
  type?: string;
  effectiveDate?: number | string;
};

export const employeeStatutoryApiSlice = apiSlice.injectEndpoints({
  endpoints: builder => ({
    getListEmployeeStatutory: builder.query({
      query: args => {
        const { employeeId, ...query } = args;
        return {
          url: `/employee/employees/${employeeId}/statutories/idn`,
          params: query,
        };
      },
      providesTags: ['employeeStatutoryList', 'employeeStatutoryDetail'],
    }),
    getDetailEmployeeStatutory: builder.query<any, ParamsQuery>({
      query: args => {
        const { employeeId, groupId, type, ...query } = args;
        return {
          url: `/employee/employees/${employeeId}/statutories/idn/${groupId}/${type}`,
          params: query,
        };
      },
      providesTags: ['employeeStatutoryDetail'],
    }),
    createEmployeeStatutory: builder.mutation<any, any>({
      query: ({ employeeId, payload }) => ({
        url: `/employee/employees/${employeeId}/statutories/idn`,
        method: 'POST',
        body: payload,
      }),
      invalidatesTags: ['employeeStatutoryList', 'employeeStatutoryDetail'],
    }),
    updateEmployeeStatutory: builder.mutation<any, any>({
      query: ({ employeeId, oldId, payload }) => ({
        url: `/employee/employees/${employeeId}/statutories/idn/${oldId}`,
        method: 'PUT',
        body: payload,
      }),
      invalidatesTags: ['employeeStatutoryList', 'employeeStatutoryDetail'],
    }),
    cancelEmployeeChangesStatutory: builder.mutation<any, any>({
      query: ({ id, newId, type }) => ({
        url: `/employee/employees/${id}/statutories/idn/${newId}/${type}/cancellation`,
        method: 'DELETE',
      }),
      invalidatesTags: ['employeeStatutoryDetail'],
    }),
    deleteEmployeeStatutory: builder.mutation<any, any>({
      query: ({ employeeId, groupId, type }) => ({
        url: `/employee/employees/${employeeId}/statutories/idn/${groupId}/${type}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['employeeStatutoryList'],
    }),
  }),
});

export const {
  useGetListEmployeeStatutoryQuery,
  useGetDetailEmployeeStatutoryQuery,
  useCreateEmployeeStatutoryMutation,
  useUpdateEmployeeStatutoryMutation,
  useCancelEmployeeChangesStatutoryMutation,
  useDeleteEmployeeStatutoryMutation,
} = employeeStatutoryApiSlice;
