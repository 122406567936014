import React, { useEffect, useMemo, useState } from 'react';
import {
  Box,
  Step,
  Stepper,
  SxProps,
  Typography,
  useTheme,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { SubmitHandler, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Button } from '../../components/atom';
import Step1 from './Step1';
import Step2 from './Step2';
import Step3 from './Step3';
import Step4 from './Step4';
import { ContactUsFormProps } from '../../@types/ContactUs';
import { DefaultValueContactUsForm } from '../../constants/ContactUs';
import contactUsFormValidations from '../../validations/contactUs';
import { useRequestDemoMutation } from '../../store/feature/service/demoApiSlice';

const RequestDemo = () => {
  const { palette } = useTheme();

  const navigate = useNavigate();

  const [activeStep, setActiveStep] = useState(1);
  const [isValidNext, setIsValidNext] = useState(false);

  const { control, handleSubmit, setValue, watch, trigger, clearErrors } =
    useForm<ContactUsFormProps>({
      defaultValues: DefaultValueContactUsForm,
      resolver: yupResolver(contactUsFormValidations),
    });

  const [submitRequest, { isSuccess, isLoading }] = useRequestDemoMutation();

  const style: SxProps = {
    pt: '80px',
    width: '100vw',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    '& .container': {
      width: '400px',
    },
    '& .section': {
      mb: '2rem',
    },
    '& .content': {
      mb: '1.5rem',
    },
    '& .text-center': {
      textAlign: 'center',
    },
    '& .title': {
      fontWeight: 700,
      lineHeight: '1.75rem',
    },
    '& .stepper': {
      px: '4rem',
    },
    '& .option': {
      display: 'flex',
      alignItems: 'center',
      borderRadius: '0.5rem !important',
      borderTopColor: `${palette.gray[50]} !important`,
      mt: '0px !important',
    },
    '& .not-last': {
      mb: '1.25rem',
    },
    '& .circle': {
      borderRadius: '50%',
    },
    '& .MuiStep-root': {
      width: '2.25rem',
      height: '2.25rem',
      border: `1px solid ${palette.gray[80]}`,
      color: palette.gray[80],
      p: 0,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    '& .MuiStepConnector-root': {
      height: '2px',
      backgroundColor: `${palette.gray[50]} !important`,
    },
    '& .Mui-completed': {
      backgroundColor: palette.blue[50],
      color: 'white',
    },
    '& .Mui-selected': {
      border: `2px solid ${palette.blue[50]} !important`,
      backgroundColor: `${palette.blue[20]} !important`,
      color: `${palette.blue[50]} !important`,
    },
    '& .next-button': {
      mb: '1rem',
    },
  };

  const onSubmit: SubmitHandler<ContactUsFormProps> = form => {
    const payload = {
      country: form.country,
      totalEmployees: form.organization,
      companyNeeds: form.modules.map(module => ({
        label: module,
        value: module === 'Other' ? form.other : module,
      })),
      fullName: form.fullName,
      jobTitle: form.jobTitle,
      company: form.company,
      businessEmail: form.businessEmail,
      phoneNumber: form.phoneNumber,
    };
    submitRequest(payload).unwrap();
  };

  const backToLogin = () => {
    navigate('/login');
  };

  const handleBack = () => {
    setActiveStep(prev => prev - 1);
  };

  const isValidToNext = async () => {
    const res = await trigger('other', { shouldFocus: true });
    return res;
  };

  const goToNextStep = async () => {
    if (activeStep === 3 && !(await isValidToNext())) return;
    setActiveStep(prev => prev + 1);
  };

  const onChangeOtherInput = () => {
    clearErrors();
  };

  const isDisabledButton = useMemo(() => {
    if (
      !!watch('fullName') &&
      !!watch('jobTitle') &&
      !!watch('company') &&
      !!watch('businessEmail') &&
      !!watch('phoneNumber')
    ) {
      return false;
    }
    return true;
  }, [JSON.stringify(watch())]);

  useEffect(() => {
    if (isSuccess) {
      navigate('/thankyou');
    }
  }, [isSuccess]);
  return (
    <Box sx={style}>
      <Box className="container">
        <Box className="section text-center">
          <img src="Logo.png" alt="tricor-unify" title="Tricor Unify" />
        </Box>
        <Box className="section stepper">
          <Stepper activeStep={activeStep}>
            {[1, 2, 3, 4].map(step => {
              const stepProps: { completed?: boolean } = {};
              return (
                <Step key={step} className="circle" {...stepProps}>
                  {step}
                </Step>
              );
            })}
          </Stepper>
        </Box>
        <Box className="section text-center">
          <Typography variant="subtitle18" className="title">
            {activeStep === 1 && 'Choose your country'}
            {activeStep === 2 && 'How many employees in your organization?'}
            {activeStep === 3 && 'Select area of your company needs'}
            {activeStep === 4 &&
              "Tell us about you and we'll get in touch soon"}
          </Typography>
        </Box>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Box className="content">
            {activeStep === 1 && (
              <Step1
                setValue={setValue}
                watch={watch}
                onChange={() => setActiveStep(2)}
              />
            )}
            {activeStep === 2 && (
              <Step2
                setValue={setValue}
                watch={watch}
                onChange={() => setActiveStep(3)}
              />
            )}
            {activeStep === 3 && (
              <Step3
                setValue={setValue}
                watch={watch}
                onChange={(isValid: boolean) => setIsValidNext(isValid)}
                control={control}
                onChangeOtherInput={onChangeOtherInput}
              />
            )}
            {activeStep === 4 && <Step4 control={control} />}
          </Box>
          {activeStep === 3 && (
            <Button
              variant="primary"
              className="next-button"
              disabled={!isValidNext}
              fullWidth
              onClick={goToNextStep}
            >
              Next
            </Button>
          )}
          {activeStep === 4 && (
            <Button
              variant="primary"
              type="submit"
              fullWidth
              className="next-button"
              isLoading={isLoading}
              disabled={isDisabledButton || isLoading}
            >
              Book a Demo
            </Button>
          )}
          {activeStep === 1 && (
            <Button fullWidth variant="link" onClick={backToLogin}>
              Back to Login
            </Button>
          )}
          {activeStep !== 1 && (
            <Button fullWidth variant="link" onClick={handleBack}>
              Back
            </Button>
          )}
        </form>
      </Box>
    </Box>
  );
};

export default RequestDemo;
