import { Box, Typography, useTheme } from '@mui/material';
import React from 'react';
import { AlertTriangle } from '../icon';

const AlertBanner = () => {
  const { palette } = useTheme();
  return (
    <Box
      sx={{
        bgcolor: `${palette.orange[20]}`,
        mb: '16px',
        padding: '12px 16px 12px 16px',
        borderRadius: '8px',
        '& svg': {
          '& path': {
            strokeWidth: 1.5,
            stroke: palette.orange[50],
          },
        },
      }}
    >
      <Box
        sx={{
          display: 'flex',
          gap: '16px',
        }}
      >
        <AlertTriangle />
        <Typography variant="body14">
          This pay group is being used in current payroll run, unable to make
          any changes.
        </Typography>
      </Box>
    </Box>
  );
};

export default AlertBanner;
