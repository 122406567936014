import { SvgIconProps } from '@mui/material';
import { FC } from 'react';
import { SvgIcon } from './SvgIcon';

const PlusCircle: FC<SvgIconProps> = props => (
  <SvgIcon
    style={{ minWidth: '24px' }}
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M12 8V16M8 12H16M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12Z"
      stroke="#101B28"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SvgIcon>
);

export default PlusCircle;
