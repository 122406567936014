import { lazy } from 'react';

const List = lazy(() => import('../../../pages/dailyAttendance/List'));

export const dailyAttendance = [
  {
    label: 'Daily Attendance',
    child: null,
    path: 'daily-attendance',
    component: List,
  },
];
