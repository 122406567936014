import costCenterRoute from './costCenter';
import taxLocation from './taxLocation';
import { RouteNode } from '../../@types';
import { FolderDropdownIcon } from '../../components/atom/icon';
import employementTypeRoute from './employementType';
import bank from './bank';

export const MasterDataRoute: RouteNode[] = [
  {
    icon: FolderDropdownIcon,
    label: 'Master Data',
    path: 'master-data',
    component: null,
    child: [
      ...bank,
      ...costCenterRoute,
      ...employementTypeRoute,
      ...taxLocation,
    ],
  },
];
