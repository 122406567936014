import React from 'react';
import { RouteNode } from '../../@types';

const Update = React.lazy(() => import('../../pages/employee/earning/Update'));
const Create = React.lazy(() => import('../../pages/employee/earning/Create'));
const Correction = React.lazy(
  () => import('../../pages/employee/earning/Correction')
);

const earning: RouteNode[] = [
  {
    label: '',
    path: 'employee/detail/:id/earning/update',
    component: Create,
    child: null,
  },
  {
    label: '',
    path: 'employee/detail/:id/earning/update/:earningId',
    component: Update,
    child: null,
  },
  {
    label: '',
    path: 'employee/detail/:id/earning/correction/:earningId',
    component: Correction,
    child: null,
  },
];

export default earning;
