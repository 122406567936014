import React from 'react';
import { RouteNode } from '../../../@types';

const ValueRoundingList = React.lazy(
  () => import('../../../pages/valueRounding/ValueRoundingList')
);
const ValueRoundingCreate = React.lazy(
  () => import('../../../pages/valueRounding/ValueRoundingCreate')
);
const ValueRoundingDetail = React.lazy(
  () => import('../../../pages/valueRounding/ValueRoundingDetail')
);
const ValueRoundingUpdate = React.lazy(
  () => import('../../../pages/valueRounding/ValueRoundingUpdate')
);

const valueRounding: RouteNode[] = [
  {
    label: 'Value Rounding',
    path: 'value-rounding',
    component: ValueRoundingList,
    child: null,
  },
  {
    label: '',
    path: 'value-rounding/create',
    component: ValueRoundingCreate,
    child: null,
  },
  {
    label: '',
    path: 'value-rounding/detail/:id',
    component: ValueRoundingDetail,
    child: null,
  },
  {
    label: '',
    path: 'value-rounding/update/:id',
    component: ValueRoundingUpdate,
    child: null,
  },
];

export default valueRounding;
