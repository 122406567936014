import { createSlice } from '@reduxjs/toolkit';
import { fileApiSlice } from './service/fileApiSlice';

interface initialStateI {
  messageSuccess: string;
  messageError: string;
}

const initialState: initialStateI = {
  messageSuccess: '',
  messageError: '',
};

const fileSlice = createSlice({
  name: 'file',
  initialState,
  reducers: {
    removeMessage: state => {
      state.messageSuccess = '';
      state.messageError = '';
    },
  },
  extraReducers(builder) {
    builder.addMatcher(
      fileApiSlice.endpoints.createFile.matchFulfilled,
      (state, _action) => {
        state.messageSuccess = 'Files created';
      }
    );
    builder.addMatcher(
      fileApiSlice.endpoints.updateFile.matchFulfilled,
      (state, _action) => {
        state.messageSuccess = 'Files updated';
      }
    );
    builder.addMatcher(
      fileApiSlice.endpoints.createFile.matchRejected,
      (state, action) => {
        const payload = action.payload as any;
        state.messageError = payload?.data?.message || 'Internal Server Error';
      }
    );
    builder.addMatcher(
      fileApiSlice.endpoints.deleteFile.matchFulfilled,
      (state, _action) => {
        state.messageSuccess = 'Files deleted';
      }
    );
  },
});

export const { removeMessage } = fileSlice.actions;
export const fileReducer = fileSlice.reducer;
