import React from 'react';

const ArrowCircleUp = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="arrow-circle-up">
      <g id="Icon">
        <path
          d="M9.99935 18.3334C14.6017 18.3334 18.3327 14.6025 18.3327 10.0001C18.3327 5.39771 14.6017 1.66675 9.99935 1.66675C5.39698 1.66675 1.66602 5.39771 1.66602 10.0001C1.66602 14.6025 5.39698 18.3334 9.99935 18.3334Z"
          fill="#F5FAFF"
        />
        <path
          d="M13.3327 10.0001L9.99935 6.66675L6.66602 10.0001"
          fill="#F5FAFF"
        />
        <path
          d="M13.3327 10.0001L9.99935 6.66675M9.99935 6.66675L6.66602 10.0001M9.99935 6.66675V13.3334M18.3327 10.0001C18.3327 14.6025 14.6017 18.3334 9.99935 18.3334C5.39698 18.3334 1.66602 14.6025 1.66602 10.0001C1.66602 5.39771 5.39698 1.66675 9.99935 1.66675C14.6017 1.66675 18.3327 5.39771 18.3327 10.0001Z"
          stroke="#045FC4"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </g>
  </svg>
);

export default ArrowCircleUp;
