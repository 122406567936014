import React from 'react';

const AddressBook = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1.3335 4.66659H3.8335M1.3335 11.3333H3.8335M5.9168 10.7499C6.67377 9.98483 7.70148 9.51463 8.83347 9.51463C9.96546 9.51463 10.9932 9.98483 11.7501 10.7499M14.6668 2.99992V12.9999C14.6668 13.4419 14.4912 13.8659 14.1787 14.1784C13.8661 14.491 13.4422 14.6666 13.0002 14.6666H4.66683C4.2248 14.6666 3.80088 14.491 3.48832 14.1784C3.17576 13.8659 3.00016 13.4419 3.00016 12.9999V2.99992C3.00016 2.55789 3.17576 2.13397 3.48832 1.82141C3.80088 1.50885 4.2248 1.33325 4.66683 1.33325H13.0002C13.4422 1.33325 13.8661 1.50885 14.1787 1.82141C14.4912 2.13397 14.6668 2.55789 14.6668 2.99992ZM10.2918 6.05629C10.2918 6.8238 9.63889 7.446 8.83347 7.446C8.02806 7.446 7.37514 6.8238 7.37514 6.05629C7.37514 5.28878 8.02806 4.66659 8.83347 4.66659C9.63889 4.66659 10.2918 5.28878 10.2918 6.05629Z"
      stroke="#045FC4"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default AddressBook;
