import { Box, Grid, Typography, useTheme } from '@mui/material';
import { useParams } from 'react-router-dom';
import { useAppSelector } from '../../../../store';
import { useGetDetailEmployeeEducationQuery } from '../../../../store/feature/service/employee/educationApiSlice';
import { objectMapper } from '../../../../utils/objectMap';
import { formatDateTimeFromUnixUTC } from '../../../../utils/date';
import { download } from '../../../../utils/file';
import LabelText from '../../../../components/atom/labelText/LabelText';
import HeaderAction from '../../../../components/molecule/action/HeaderAction';
import HistoricalTimeLine from '../../../../components/molecule/historicalTimeLine/HistoricalTimeLine';
import SubHeader from '../../../../components/molecule/subHeader/SubHeader';
import { useGetESSEducationDetailQuery } from '../../../../store/feature/service/essEmployee';

const EducationSection = ({ index, headerKey, groupId }: any) => {
  const { palette } = useTheme();
  const selectedHeader = `employee/education/${groupId}`;
  const { headerDate } = useAppSelector(state => state.utils);
  const { data }: any = useGetESSEducationDetailQuery({
    dataId: groupId,
    effectiveDate: headerDate[selectedHeader],
  });

  const currentData: { [key: string]: any } = {
    ...objectMapper(data?.data?.old || {}),
    startDate: formatDateTimeFromUnixUTC(data?.data?.old?.startDate),
    endDate: formatDateTimeFromUnixUTC(data?.data?.old?.endDate),
    effectiveDate: (
      <HistoricalTimeLine headersState={data} selectedTab={selectedHeader} />
    ),
  };

  const upcomingData: { [key: string]: any } = {
    ...objectMapper(data?.data?.new || {}),
    startDate: formatDateTimeFromUnixUTC(data?.data?.new?.startDate),
    endDate: formatDateTimeFromUnixUTC(data?.data?.new?.endDate),
  };

  const customeCurrentComponent: { [key: string]: any } = {
    fileName:
      currentData?.fileName !== '-' ? (
        <Typography
          variant="body14"
          color={
            data?.dataPresentationStatus === 'PREVIOUS'
              ? palette.red[50]
              : palette.blue[50]
          }
          sx={{
            cursor:
              data?.dataPresentationStatus !== 'PREVIOUS'
                ? 'pointer'
                : 'no-drop',
          }}
          component="span"
          onClick={() =>
            data?.dataPresentationStatus !== 'PREVIOUS' &&
            download({
              fileUrl: currentData?.fileUrl,
              fileName: currentData?.fileName,
            })
          }
        >
          {currentData.fileName}
        </Typography>
      ) : (
        currentData?.fileName
      ),
  };
  const customeUpcommingComponent: { [key: string]: any } = {
    fileName:
      upcomingData?.fileName !== '-' ? (
        <Typography
          sx={{ cursor: 'pointer' }}
          variant="body14"
          color={palette.green[50]}
          component="span"
          onClick={() =>
            download({
              fileUrl: upcomingData?.fileUrl,
              fileName: upcomingData?.fileName,
            })
          }
        >
          {upcomingData?.fileName}
        </Typography>
      ) : (
        upcomingData?.fileName
      ),
  };

  return (
    <Grid item container spacing={1}>
      {index === 0 && (
        <Grid item xs={12}>
          <HeaderAction label="Education Info" labelAdd="Education" />
        </Grid>
      )}
      <Grid item xs={12}>
        <Box
          sx={{
            '& .list': {
              display: 'flex',
              padding: '8px 12px',
            },
            '& .sub-title': {
              fontWeight: 700,
            },
            display: 'flex',
            flexDirection: 'column',
            gap: '20px',
          }}
        >
          <SubHeader
            label="Education"
            idx={index}
            data={data}
            isCorrectionEnabled={false}
          />
        </Box>
      </Grid>
      {Object.keys(headerKey).map((key: any) => (
        <Grid item xs={12}>
          <LabelText
            headerKey={headerKey[key]}
            currentData={currentData?.[key]}
            upcommingData={upcomingData?.[key]}
            dataPresentationStatus={data?.dataPresentationStatus}
            customeCurrentComponent={customeCurrentComponent?.[key]}
            customePreviousComponent={customeCurrentComponent?.[key]}
            customeUpcommingComponent={customeUpcommingComponent?.[key]}
          />
        </Grid>
      ))}
    </Grid>
  );
};

export default EducationSection;
