import React from 'react';

const List = React.lazy(() => import('../../../pages/overtimeRequest/List'));
const Detail = React.lazy(
  () => import('../../../pages/overtimeRequest/Detail')
);

export const overtimeRequest = [
  {
    label: 'Request',
    child: null,
    path: 'overtime-request',
    component: List,
  },
  {
    label: '',
    child: null,
    path: 'overtime-request/detail/:id',
    component: Detail,
  },
];
