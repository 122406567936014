import { useEffect } from 'react';
import {
  Avatar,
  Box,
  Grid,
  SxProps,
  Typography,
  useTheme,
} from '@mui/material';
import { useForm } from 'react-hook-form';
import { useDetailUpdateDataRequestQuery } from '../../../../store/feature/service/homeApiSlice';
import { Modal } from '../../../molecule';
import { Check, Clock, XClose } from '../../../atom/icon';
import LabelTextModal from '../../../atom/labelText/LabelTextModal';
import {
  formatDateToPostUnix,
  formatUnixToDateTime,
  newformatDateTimeFromUnixUTC,
} from '../../../../utils/date';
import WorkflowItem from '../../../molecule/workflowItem/WorkflowItem';
import { getDifferentProperties } from '../../../../utils/getDifferentProps';
import { objectMapper } from '../../../../utils/objectMap';
import { updateDataRequestHeaderKey } from '../../../../constants/news';
import DatePickerWithLabel from '../../../molecule/form/DatePickerWithLabel/DatePickerWithLabel';
import TextareaWithLabel from '../../../molecule/form/TextareaWithLabel/TextareaWithLabel';
import { mappingError } from '../../../../utils/errorMapping';
import { Button } from '../../../atom';
import { useAppSelector } from '../../../../store';
import { useSubmitEssTaskApprovalMutation } from '../../../../store/feature/service/essTimeOffApiSlice';

const EssDataUpdateRequest = ({
  data,
  handleClose,
}: {
  data: any;
  handleClose: () => void;
}) => {
  const { palette } = useTheme();
  const [submitEssTaskApproval, { isLoading }] =
    useSubmitEssTaskApprovalMutation();
  const { data: detailData } = useDetailUpdateDataRequestQuery(data.detailId);
  const { dataAccess } = useAppSelector(state => state.userAccess);

  const sendApproval = async (form: any, status: any) => {
    const payload = {
      ...form,
      effectiveDate:
        typeof form.effectiveDate === 'string'
          ? formatDateToPostUnix(form.effectiveDate)
          : form.effectiveDate.setHours(0, 0, 0, 0) / 1000,
      statusCode: status?.value,
    };

    try {
      await submitEssTaskApproval({ id: data?.id, payload }).unwrap();
      handleClose();
    } catch (error) {
      const err = error as any;
      mappingError(err.data.errors, setError, 'approveUpdateDataRequest');
    }
  };

  const {
    control,
    handleSubmit,
    formState: { isValid },
    setError,
    setValue,
  } = useForm({
    defaultValues: { effectiveDate: '', remarks: '' },
    reValidateMode: 'onChange',
    mode: 'all',
  });

  const headerKey = updateDataRequestHeaderKey[detailData?.module?.value] || {};

  const parsedDataOld = detailData?.dataValueOld
    ? JSON.parse(detailData?.dataValueOld)
    : {};
  const parsedDataNew = detailData?.dataValueNew
    ? JSON.parse(detailData?.dataValueNew)
    : {};
  const dataNewMapped: any = {
    ...objectMapper(parsedDataNew),
    startDate: newformatDateTimeFromUnixUTC(parsedDataNew?.startDate),
    endDate: newformatDateTimeFromUnixUTC(parsedDataNew?.endDate),
    birthDate: parsedDataNew?.birthDate
      ? newformatDateTimeFromUnixUTC(parsedDataNew?.birthDate)
      : '-',
  };

  const differentProperties = getDifferentProperties(
    {
      ...objectMapper(parsedDataOld),
      startDate: newformatDateTimeFromUnixUTC(parsedDataOld?.startDate),
      endDate: newformatDateTimeFromUnixUTC(parsedDataOld?.endDate),
      birthDate: newformatDateTimeFromUnixUTC(parsedDataOld?.birthDate),
    },
    {
      ...objectMapper(parsedDataNew),
      startDate: newformatDateTimeFromUnixUTC(parsedDataNew?.startDate),
      endDate: newformatDateTimeFromUnixUTC(parsedDataNew?.endDate),
      birthDate: newformatDateTimeFromUnixUTC(parsedDataNew?.birthDate),
    }
  );

  const filteredData = Object.keys(differentProperties).reduce(
    (filtered: any, key) => {
      const item = { ...filtered };
      if (Object.prototype.hasOwnProperty.call(headerKey, key)) {
        item[key] = data[key];
      }
      return item;
    },
    {}
  );

  const buttonStyles: SxProps = {
    width: '100%',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    display: 'block',
    textOverflow: 'ellipsis',
    textAlign: 'left',
  };

  useEffect(() => {
    if (data && detailData) {
      setValue('effectiveDate', newformatDateTimeFromUnixUTC(data?.createdAt));
      setValue('remarks', detailData?.remarks);
    }
  }, [JSON.stringify(data), JSON.stringify(detailData)]);

  return !data ? (
    <div />
  ) : (
    <Modal
      fullHeight
      open
      onClose={handleClose}
      showCloseIcon
      title="Data Update Request"
      showButton
      leftButtonProps={{
        variant: 'outlined',
        disabled: isLoading,
        onClick: handleSubmit(data =>
          sendApproval(data, { label: 'Rejected', value: 'REJECTED' })
        ),
      }}
      rightButtonProps={{
        variant: 'outlined',
        disabled: isLoading,
        onClick: handleSubmit(data =>
          sendApproval(data, { label: 'Approved', value: 'APPROVED' })
        ),
      }}
      leftButtonLabel={
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            gap: '8px',
            '& svg path': {
              stroke: palette.red[50],
            },
          }}
        >
          <XClose />
          Reject
        </Box>
      }
      rightButonLabel={
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            gap: '8px',
            '& svg path': {
              stroke: palette.green[50],
            },
          }}
        >
          <Check />
          Approve
        </Box>
      }
      icon={
        <Box
          sx={{
            background: palette.blue[20],
            height: '48px',
            width: '48px',
            borderRadius: '90px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            '& svg': {
              width: '20px',
              height: '20px',
            },
            '& svg path': {
              stroke: palette.blue[90],
              strokeWidth: '2',
            },
          }}
        >
          <Clock />
        </Box>
      }
    >
      <Grid container gap="16px" sx={{ overflow: 'auto' }}>
        {/* Static Text */}
        <LabelTextModal label="ID" texts={[detailData?.requestUpdateCode]} />
        <LabelTextModal
          label="Employee"
          texts={[detailData?.employeeFullName]}
        />
        <LabelTextModal label="Data" texts={[detailData?.module?.label]} />
        <LabelTextModal
          label="Action Type"
          texts={[detailData?.actionType?.label]}
        />
        {detailData?.actionType?.value === 'UPDATE' &&
          detailData?.module?.value !== 'PROFILE_PHOTO' &&
          detailData?.module?.value !== 'PERSONAL' && (
            <LabelTextModal
              label={detailData?.module?.label}
              texts={[detailData?.nameUpdate]}
            />
          )}
        {detailData?.actionType?.value === 'UPDATE' &&
          detailData?.module?.value === 'PROFILE_PHOTO' && (
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: '8px',
              }}
            >
              <Typography
                variant="body14"
                fontWeight={400}
                color={palette.gray[80]}
              >
                {detailData?.module?.label}
              </Typography>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: '16px',
                }}
              >
                <Avatar
                  sx={{ height: '88px', width: '88px' }}
                  src={parsedDataOld?.fileUrl}
                  alt="profile-picture-old"
                />
                <Typography>{'->'}</Typography>
                <Avatar
                  sx={{ height: '88px', width: '88px' }}
                  src={parsedDataNew?.fileUrl}
                  alt="profile-picture-new"
                />
              </Box>
            </Box>
          )}

        {/* Dynamic Text */}
        {detailData?.dataValueOld &&
          Object.keys(filteredData).map((key: any, index: number) => (
            <Grid key={key} item container>
              <Box display="flex" flexDirection="column" rowGap="8px">
                <Typography
                  variant="body14"
                  fontWeight={400}
                  color={palette.gray[80]}
                >
                  {headerKey[key]}
                </Typography>
                {key !== 'fileName' && (
                  <Typography
                    variant="body14"
                    fontWeight={400}
                    display="block"
                    color={palette.gray[100]}
                  >
                    {differentProperties[key]?.[0] || '-'} {'->'}{' '}
                    <span style={{ color: '#039855' }}>
                      {differentProperties[key]?.[1] || '-'}
                    </span>
                  </Typography>
                )}
                {key === 'fileName' && (
                  <Box
                    sx={{
                      display: 'flex',
                      gap: '4px',
                      justifyContent: 'space-between',
                    }}
                  >
                    <Button
                      variant="link"
                      sx={buttonStyles}
                      onClick={() =>
                        window.open(differentProperties?.fileUrl?.[0], '_blank')
                      }
                    >
                      {differentProperties[key]?.[0]}
                    </Button>
                    {'->'}
                    <Button
                      variant="link"
                      sx={buttonStyles}
                      onClick={() =>
                        window.open(differentProperties?.fileUrl?.[1], '_blank')
                      }
                    >
                      {differentProperties[key]?.[1]}
                    </Button>
                  </Box>
                )}
              </Box>
            </Grid>
          ))}

        {!detailData?.dataValueOld &&
          Object.keys(headerKey).map(key => {
            if (key === 'effectiveDate') return '';
            return (
              <Grid key={key} item container>
                <Box display="flex" flexDirection="column" gap="8px">
                  <Typography
                    variant="body14"
                    fontWeight={400}
                    color={palette.gray[80]}
                  >
                    {headerKey[key]}
                  </Typography>
                  {key !== 'fileName' && (
                    <Typography
                      variant="body14"
                      fontWeight={400}
                      display="block"
                      color="#039855"
                    >
                      {dataNewMapped[key]}
                    </Typography>
                  )}
                  {key === 'fileName' && (
                    <Button
                      variant="link"
                      onClick={() =>
                        window.open(dataNewMapped?.fileUrl, '_blank')
                      }
                      sx={buttonStyles}
                    >
                      {dataNewMapped[key]}
                    </Button>
                  )}
                </Box>
              </Grid>
            );
          })}

        <DatePickerWithLabel
          control={control}
          label="Effective Date"
          name="effectiveDate"
        />
        <TextareaWithLabel
          control={control}
          name="remarks"
          label="Remarks"
          required={false}
          placeholder="Enter Remarks"
        />

        <Grid
          item
          sx={{ borderTop: `1px solid ${palette.gray[50]}`, width: '100%' }}
        />
        <Grid item>
          <Typography
            variant="body16"
            fontWeight={700}
            display="block"
            color={palette.gray[90]}
          >
            Workflow
          </Typography>
        </Grid>
        {detailData?.workflow?.map((w: any) => (
          <WorkflowItem
            title={w.fullName}
            image={
              <img
                src={w.pictureUrl}
                alt="employee profile"
                style={{ width: '40px', height: '40px', objectFit: 'cover' }}
              />
            }
            description={formatUnixToDateTime(w.date)}
            status={w.statusCode}
          />
        ))}
      </Grid>
    </Modal>
  );
};
export default EssDataUpdateRequest;
