import React from 'react';
import { RouteNode } from '../../@types';

const Create = React.lazy(
  () => import('../../pages/employee/statutory/Create')
);

const Corrections = React.lazy(
  () => import('../../pages/employee/statutory/Correction')
);

const Update = React.lazy(
  () => import('../../pages/employee/statutory/Update')
);

const Statutory: RouteNode[] = [
  {
    label: '',
    path: '/employee/detail/:id/statutory/create',
    component: Create,
    child: null,
  },
  {
    label: '',
    path: '/employee/detail/:id/statutory/correction/:groupId/:type',
    component: Corrections,
    child: null,
  },
  {
    label: '',
    path: '/employee/detail/:id/statutory/update/:groupId/:type',
    component: Update,
    child: null,
  },
];

export default Statutory;
