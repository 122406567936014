import { createSlice } from '@reduxjs/toolkit';
import { bankFileApiSlice } from './service/report/bankFileApiSlice';

interface initialStateI {
  messageSuccess: string;
  messageError: string;
}

const initialState: initialStateI = {
  messageSuccess: '',
  messageError: '',
};

const bankFileSlice = createSlice({
  name: 'bankFileSlice',
  initialState,
  reducers: {
    removeMessage: state => {
      state.messageSuccess = '';
      state.messageError = '';
    },
  },
  extraReducers(builder) {
    builder.addMatcher(
      bankFileApiSlice.endpoints.generateBankFile.matchFulfilled,
      (state, _action) => {
        state.messageSuccess = 'Bank File created';
      }
    );
    builder.addMatcher(
      bankFileApiSlice.endpoints.generateBankFile.matchRejected,
      (state, _action) => {
        state.messageError = 'Bank Format Code already exist';
      }
    );
    builder.addMatcher(
      bankFileApiSlice.endpoints.generateBankFile.matchRejected,
      (state, action) => {
        const payload = action.payload as any;
        state.messageError = payload?.data?.message;
      }
    );
    builder.addMatcher(
      bankFileApiSlice.endpoints.deleteBankFile.matchFulfilled,
      (state, _action) => {
        state.messageSuccess = 'Bank File deleted';
      }
    );
    builder.addMatcher(
      bankFileApiSlice.endpoints.deleteBankFile.matchRejected,
      (state, action) => {
        const payload = action.payload as any;
        state.messageError = payload?.data?.message;
      }
    );
  },
});

export const { removeMessage } = bankFileSlice.actions;
export const bankFileReducer = bankFileSlice.reducer;
