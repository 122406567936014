import { createSlice } from '@reduxjs/toolkit';
import { organizationUnitCreateSLice } from './service/organizationUnitApiSlice';

interface initialStateI {
  messageSuccess: string;
  messageError: string;
}

const initialState: initialStateI = {
  messageSuccess: '',
  messageError: '',
};

const organizationUnitSlice = createSlice({
  name: 'organizationUnit',
  initialState,
  reducers: {
    removeMessage: state => {
      state.messageSuccess = '';
      state.messageError = '';
    },
  },
  extraReducers(builder) {
    builder.addMatcher(
      organizationUnitCreateSLice.endpoints.createOrganizationUnit
        .matchFulfilled,
      (state, _action) => {
        state.messageSuccess = 'Organization Unit Created ';
      }
    );
    builder.addMatcher(
      organizationUnitCreateSLice.endpoints.updateOrganizationUnit
        .matchFulfilled,
      (state, action) => {
        // state.messageSuccess = 'Organization Unit Updated';
        if (action?.payload?.typeOfChange === 'UPDATED') {
          state.messageSuccess = 'Organization Unit updated';
        } else if (action?.payload?.typeOfChange === 'CORRECTED') {
          state.messageSuccess = 'Organization Unit corrected';
        }
      }
    );
    builder.addMatcher(
      organizationUnitCreateSLice.endpoints.deleteOrganizationUnit
        .matchFulfilled,
      (state, _action) => {
        state.messageSuccess = 'Organization Unit deleted';
      }
    );
    builder.addMatcher(
      organizationUnitCreateSLice.endpoints.deleteOrganizationUnit
        .matchRejected,
      (state, action) => {
        const payload = action.payload as any;
        state.messageError = payload?.data?.message;
      }
    );
    builder.addMatcher(
      organizationUnitCreateSLice.endpoints.cancelUpcomingChanges
        .matchFulfilled,
      (state, _action) => {
        state.messageSuccess = 'Upcoming Changes cancelled';
      }
    );
  },
});

export const { removeMessage } = organizationUnitSlice.actions;
export const organizationUnitReducer = organizationUnitSlice.reducer;
