import { Box, Button, Typography, useTheme } from '@mui/material';
import React, { useState } from 'react';
import { Copy04 } from '../../components/atom/icon';
import Toast from '../../components/atom/toast/Toast';

const DeleteAccount = () => {
  const { palette } = useTheme();
  const [message, setMessage] = React.useState('');
  const deleteMessage = `Dear TricorUnify

  I would like to restore my account with the details below:
  Full Name: [Your Full Name]
  Email: [Your Registered Email]
  Legal Entity: [Your Legal Entity]
  Effective Restoration Request Date:[Today]
  Request: Restore Account
  
  Best Regards, [Your Name]`;

  const restoreMessage = `Dear TricorUnify

  I would like to delete my account with the details below:
  Full Name: [Your Full Name]
  Email: [Your Registered Email]
  Legal Entity: [Your Legal Entity]
  Effective Deletion Request Date:[Today]
  Request: Delete Account
  
  Best Regards, [Your Name]`;

  const handleCopy = (text: string) => {
    setMessage(text);
    navigator.clipboard
      .writeText(text)
      .then(() => {
        console.log('Text copied to clipboard');
      })
      .catch(err => {
        console.error('Failed to copy text: ', err);
      });
  };
  return (
    <Box>
      <Toast
        open={message !== ''}
        variant="info"
        message="Template copied"
        onClose={() => setMessage('')}
      />
      <Box p="3.5rem">
        <Typography fontWeight="800" fontSize="1.8rem" mb="1rem">
          Delete your TricorUnify Account
        </Typography>
        <Typography fontWeight="200" mb="1rem">
          You are allowed to send a request to delete your TricorUnify account.
          We will grant you a 14-day grace period (after the request is sent) to
          cancel. During this grace period, your account will be temporarily
          deactivated and will be permanently deleted after that. If you delete
          your account, you will no longer be able to access TricorUnify
          services with that account. After deletion, we might need to keep
          certain records indicating that your account was deleted.
        </Typography>
        <Typography fontWeight="800" fontSize="1.3rem" mb="1rem">
          Delete your account
        </Typography>
        <Typography fontWeight="200" mb="1rem">
          <ol>
            <li>Send a request to: MYOrisoft.BPOSupport@my.tricorglobal.com</li>
            <li>Input CC to: wahyudinianingsih@id.tricorglobal.com</li>
            <li>Subject: [Full Name]'s account deletion request</li>
            <li> Copy this following template:</li>

            <Box
              sx={{
                background: palette.gray['10'],
                padding: '1rem',
                borderTop: `8px solid ${palette.blue['50']}`,
                margin: ' 1rem 0',
              }}
            >
              <Typography mb="1rem">
                Dear TricorUnify
                <br />
                <br />
                I would like to delete my account with the details below:
                <br />
                Full Name: [Your Full Name]
                <br />
                Email: [Your Registered Email]
                <br />
                Legal Entity: [Your Legal Entity]
                <br />
                Effective Deletion Request Date:[Today]
                <br />
                Request: Delete Account
                <br />
                <br />
                Best Regards, [Your Name]
              </Typography>
              <Button
                onClick={() => handleCopy(deleteMessage)}
                variant="contained"
                sx={{
                  display: 'flex',
                  gap: '.3rem',
                  '& svg': {
                    '& path': {
                      stroke: 'white',
                      strokeWidth: 1.5,
                    },
                  },
                }}
              >
                <Copy04 />
                Copy
              </Button>
            </Box>
            <li> Send the email.</li>
            <li>
              Upon receipt of your email, we will initiate the process of
              submitting your request for approval from your legal entity.
            </li>
            <li>
              We will send you information about the deletion request through
              your email within 14 days.
            </li>
          </ol>
        </Typography>
        <Typography fontWeight="800" fontSize="1.3rem" mb="1rem">
          What happens after an account is deleted?
        </Typography>
        <Typography fontWeight="200" mb="1rem">
          During the 14-day grace period upon the account deletion request,
          other users can still see your name in our application. When your
          account has been permanently deleted, the "Former user" title will
          replace your name. While your data has been successfully deleted from
          TricorUnify services and products, it is important to note that
          certain data may still be retained within the marketplace apps even
          after the deletion of your account.
        </Typography>
      </Box>
      <Box p="3.5rem">
        <Typography fontWeight="800" fontSize="1.8rem" mb="1rem">
          Restore your Deleted TricorUnify Account
        </Typography>
        <Typography fontWeight="200" mb="1rem">
          It is possible to restore your account within the 14-day grace period.
          However, it is important to note that once this period concludes, your
          account will be permanently deleted, rendering restoration impossible.
        </Typography>
        <Typography fontWeight="800" fontSize="1.3rem" mb="1rem">
          Restore your account
        </Typography>
        <Typography fontWeight="200" mb="1rem">
          <ol>
            <li>Send a request to: MYOrisoft.BPOSupport@my.tricorglobal.com</li>
            <li>Input CC to: wahyudinianingsih@id.tricorglobal.com</li>
            <li>Subject: [Full Name]'s account restoration request</li>
            <li>Copy this following template:</li>

            <Box
              sx={{
                background: palette.gray['10'],
                padding: '1rem',
                borderTop: `8px solid ${palette.blue['50']}`,
                margin: ' 1rem 0',
              }}
            >
              <Typography mb="1rem">
                Dear TricorUnify
                <br />
                <br />
                I would like to restore my account with the details below:
                <br />
                Full Name: [Your Full Name]
                <br />
                Email: [Your Registered Email]
                <br />
                Legal Entity: [Your Legal Entity]
                <br />
                Effective Restoration Request Date:[Today]
                <br />
                Request: Restore Account
                <br />
                <br />
                Best Regards, [Your Name]
              </Typography>
              <Button
                onClick={() => handleCopy(restoreMessage)}
                variant="contained"
                sx={{
                  display: 'flex',
                  gap: '.3rem',
                  '& svg': {
                    '& path': {
                      stroke: 'white',
                      strokeWidth: 1.5,
                    },
                  },
                }}
              >
                <Copy04 />
                Copy
              </Button>
            </Box>
            <li> Send the email.</li>
            <li>
              Upon receipt of your email, we will initiate the process of
              submitting your request for approval from your legal entity.
            </li>
            <li>
              We will send you information about the restoration request through
              your email within 14 days.
            </li>
          </ol>
        </Typography>
        <Typography fontWeight="200" mb="1rem">
          After the restoration is approved, your account will be restored and
          you can log in to all services and products you have previously
          accessed.
        </Typography>
      </Box>
    </Box>
  );
};

export default DeleteAccount;
