import React from 'react';
import { Box, Typography, SxProps } from '@mui/material';
import { SubmitHandler, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useNavigate } from 'react-router-dom';
import { ArrowLeft } from '../../components/atom/icon';
import { Button } from '../../components/atom';
import InputWithLabel from '../../components/molecule/form/InputWithLabel/InputWithLabel';
import forgotPasswordValidation from '../../validations/forgotPassword';
import { useSendEmailMutation } from '../../store/feature/service/forgotPasswordSlice';
import { Notification } from '../../components/molecule';
import { mappingError } from '../../utils/errorMapping';

const ForgotPassword = () => {
  const navigate = useNavigate();

  const [sendEmail, { isLoading, isSuccess }] = useSendEmailMutation();

  const style: SxProps = {
    height: '100vh',
    width: '100vw',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    justifyContent: 'center',
    '& .container': {
      width: '400px',
    },
    '& .header': {
      mb: '1.5rem',
      textAlign: 'center',
    },
    '& .title': {
      display: 'flex',
      alignItems: 'center',
      mb: '1rem',
    },
    '& .forgotPassword': {
      fontWeight: 700,
      flex: 1,
      textAlign: 'center',
    },
    '& .caption': {
      lineHeight: '20px',
      letterSpacing: '0.05px',
    },
    '& .textField': {
      mb: '1.5rem',
    },
  };

  const { control, watch, handleSubmit, setError } = useForm({
    defaultValues: {
      email: '',
    },
    resolver: yupResolver(forgotPasswordValidation),
  });

  const onSubmit: SubmitHandler<{ email: string }> = async form => {
    try {
      await sendEmail(form).unwrap();
    } catch (error) {
      const err = error as any;
      mappingError(err.data.errors, setError, 'forget-password');
    }
  };

  const handleClose = () => {
    setTimeout(() => {
      navigate('/login');
    }, 500);
  };

  return (
    <Box sx={style}>
      <Box className="container">
        <Box className="header">
          <Box className="title">
            <Button
              variant="link"
              onClick={() => {
                navigate('/login');
              }}
            >
              <ArrowLeft />
            </Button>
            <Typography variant="h6" className="forgotPassword">
              Forgot Password
            </Typography>
          </Box>
          <Typography variant="paragraph14" className="caption">
            Enter your email address and we'll send you the link to reset your
            password.
          </Typography>
        </Box>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Box className="textField">
            <InputWithLabel
              label="Email"
              name="email"
              placeholder="Enter your email"
              control={control}
            />
          </Box>
          <Button
            fullWidth
            disabled={watch('email') === '' || isLoading}
            type="submit"
            isLoading={isLoading}
          >
            Send
          </Button>
        </form>
      </Box>
      <Notification
        open={isSuccess}
        variant="success"
        message="The link to reset your password was sent, please check your email."
        onNotificationClose={handleClose}
      />
    </Box>
  );
};

export default ForgotPassword;
