import { apiSlice } from '../api/apiSlice';

export const importApiSlice = apiSlice.injectEndpoints({
  endpoints: builder => ({
    getImportHistoryList: builder.query<any, any>({
      query: params => {
        const { search, page, sortType, sortBy, searchString } = params;
        return {
          url: '/bulk-data/import-buckets',
          params: { search, page, sortType, sortBy, ...searchString },
        };
      },
      providesTags: ['listImportHistory'],
    }),
    getStatusApproval: builder.query<any, any>({
      query: id => ({
        url: `/bulk-data/import-buckets/${id}/approval-status`,
      }),
      providesTags: ['importStatusApproval'],
    }),
    getImportDetailHeader: builder.query<any, any>({
      query: id => ({
        url: `/bulk-data/import-buckets/${id}`,
      }),
      providesTags: ['DetailImportHeader'],
    }),
    getListImportDatainDetail: builder.query<any, any>({
      query: params => {
        const {
          id,
          statusCode,
          page,
          search,
          searchString,
          limit,
          sortType,
          sortBy,
        } = params;
        return {
          url: `/bulk-data/import-buckets/${id}/list`,
          params: {
            statusCode,
            page,
            search,
            limit,
            sortType,
            sortBy,
            ...searchString,
          },
        };
      },
      providesTags: ['listImportDataInDetail'],
    }),
    getSpecificFilterList: builder.query<any, any>({
      query: params => {
        const { spesificEntity } = params;
        return {
          url: '/bulk-data/spesific-filters/dropdown',
          params: { spesificEntity },
        };
      },
    }),
    createImport: builder.mutation<any, any>({
      query: payload => ({
        url: '/bulk-data/import-buckets',
        method: 'POST',
        body: payload,
      }),
      invalidatesTags: ['listImportHistory'],
    }),
    submitApprovalImport: builder.mutation<any, any>({
      query: ({ id, payload }) => ({
        url: `/bulk-data/import-buckets/${id}/approval`,
        method: 'POST',
        body: payload,
      }),
      invalidatesTags: [
        'importStatusApproval',
        'listImportDataInDetail',
        'DetailImportHeader',
        'listImportHistory',
        'EmployeeList',
        'payrollRunList',
        'employeeEarningDetail',
        'employeeDeductionDetail',
      ],
    }),
    downloadTemplate: builder.mutation<any, any>({
      query: payload => ({
        url: `/bulk-data/import-buckets/download-template`,
        method: 'POST',
        body: payload,
      }),
    }),
    downloadError: builder.mutation<any, any>({
      query: ({ id, payload }) => ({
        url: `/bulk-data/import-buckets/${id}/download-error`,
        method: 'POST',
        body: payload,
      }),
    }),
  }),
});

export const {
  useCreateImportMutation,
  useGetImportHistoryListQuery,
  useGetImportDetailHeaderQuery,
  useGetListImportDatainDetailQuery,
  useGetStatusApprovalQuery,
  useSubmitApprovalImportMutation,
  useDownloadTemplateMutation,
  useDownloadErrorMutation,
} = importApiSlice;
