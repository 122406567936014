const Hearts = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_18112_8239)">
      <path
        d="M10.3637 6.61687C10.613 6.17394 10.8208 5.77426 10.949 5.47006C11.5715 3.9929 10.956 2.29376 9.45127 1.60067C7.94656 0.907571 6.43558 1.62625 5.77245 3.01387C4.50436 2.14372 2.81277 2.27151 1.8799 3.62941C0.947041 4.98731 1.23982 6.76127 2.51751 7.72928C3.09738 8.16861 4.24422 8.81605 5.32395 9.39222M10.8648 7.83322C10.5834 6.32125 9.29688 5.21548 7.67707 5.51602C6.05726 5.81657 5.0099 7.27797 5.22888 8.86592C5.40481 10.1417 6.37593 13.1351 6.75092 14.2626C6.80209 14.4164 6.82767 14.4934 6.87833 14.547C6.92246 14.5937 6.98114 14.6276 7.04367 14.6425C7.11546 14.6595 7.19488 14.6432 7.35371 14.6106C8.51766 14.3716 11.5955 13.7159 12.7884 13.2304C14.2731 12.6261 15.0393 10.9893 14.4654 9.43525C13.8915 7.88116 12.3149 7.32092 10.8648 7.83322Z"
        stroke="#475667"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_18112_8239">
        <rect width="16" height="16" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default Hearts;
