import { useEffect, useState } from 'react';
import { CircularProgress, Typography, Box } from '@mui/material';
import useDownloader from 'react-use-downloader';
import { Button } from '../../components/atom';
import { useGetPayslipCompiledQuery } from '../../store/feature/service/payrollRun';

const generate = () => {
  const payrollID = localStorage.getItem('payrollID');
  const payrollName = localStorage.getItem('payPeriod');

  const [timer, setTimer] = useState<undefined | number>(2000);
  const { data: dataDownload }: any = useGetPayslipCompiledQuery(
    { id: payrollID },
    {
      pollingInterval: timer,
    }
  );
  useEffect(() => {
    dataDownload?.fileName && setTimer(undefined);
  }, [dataDownload]);

  const { percentage, download: downloading, isInProgress } = useDownloader();

  const handleDownload = async () => {
    const url = dataDownload !== null ? dataDownload?.fileUrl : '';
    const name = dataDownload !== null ? dataDownload?.fileName : '';
    url !== null && name !== null && (await downloading(url, name));
  };

  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      flexDirection="column"
      gap="1.5rem"
      width="100%"
      padding="3rem"
    >
      <Typography color="#101B28" fontSize="2rem" fontWeight="700">
        {dataDownload?.fileName
          ? 'Employee Payslips Compiled'
          : 'Compiling Employee Payslips'}
      </Typography>
      <Typography color="#101B28" fontSize="1rem" fontWeight="400">
        {dataDownload?.fileName
          ? `Click the button below to download payslip for ${
              payrollName || ''
            }`
          : `Please don't refresh this page while we're processing it, this will take a minute.`}
      </Typography>
      {!dataDownload?.fileName ? (
        <CircularProgress />
      ) : (
        <Button onClick={handleDownload} disabled={isInProgress}>
          {isInProgress ? `Downloading ${percentage} %` : 'Download'}
        </Button>
      )}
    </Box>
  );
};

export default generate;
