/* eslint-disable no-new-func */
/* eslint-disable no-eval */
import React from 'react';
import { Box, useTheme } from '@mui/material';
import { useForm } from 'react-hook-form';
import { useLocation } from 'react-router-dom';
import { ChevronDown } from '../../atom/icon';
import { useAppDispatch, useAppSelector } from '../../../store';
import { setSearchFilter, setPages } from '../../../store/feature/utilSlice';
import { Button } from '../../atom';
import { formatDateToPost } from '../../../utils/date';
import SelectPaginateWithLabel from '../form/selectPaginateWithLabel/SelectPaginateWithLabel';
import DatePickerWithLabel from '../form/DatePickerWithLabel/DatePickerWithLabel';
import SelectWithLabel from '../form/selectWithLabel/SelectWithLabel';
import { FilterFields } from '../../../@types/FilterFields';
import InputWithLabel from '../form/InputWithLabel/InputWithLabel';
import FilterContainer from './FilterContainer';

type ModalFilterComponentProps = {
  open?: boolean;
  handleClose: React.MouseEventHandler<HTMLButtonElement>;
  defaultValue: any;
  fields: Array<FilterFields>;
  hasHiddenField?: boolean;
  paginationKey?: string;
};

interface DataObject {
  [key: string]: any;
}

const renderForm = (
  isHidden: boolean,
  type: string,
  control: any,
  name: string,
  label: string,
  props: any,
  watch: any,
  setValue: any
) => {
  const modifiedProps: DataObject = Object.fromEntries(
    Object.entries(props).map(([key, value]) => {
      if (typeof value === 'string') {
        try {
          const func = eval(value);
          return [key, func];
        } catch (error) {
          return [key, value];
        }
      }
      return [key, value];
    })
  );
  if (isHidden) return null;
  if (type === 'selectPaginate') {
    return (
      <SelectPaginateWithLabel
        key={name}
        control={control}
        name={name}
        label={label}
        url="/organization/legal-entities/dropdown"
        placeholder="Select"
        {...modifiedProps}
      />
    );
  }
  if (type === 'select') {
    return (
      <SelectWithLabel
        key={name}
        control={control}
        name={name}
        label={label}
        placeholder="Select"
        {...modifiedProps}
        onInputChange={() => {
          props.onInputChange?.(setValue, watch);
        }}
      />
    );
  }
  if (type === 'date') {
    return (
      <DatePickerWithLabel
        key={name}
        control={control}
        name={name}
        label={label}
        inputFormat="dd/MM/yyyy"
        {...modifiedProps}
      />
    );
  }
  if (type === 'input') {
    return (
      <InputWithLabel
        key={name}
        control={control}
        name={name}
        label={label}
        {...modifiedProps}
      />
    );
  }
  return null;
};

const ModalFilterComponent = ({
  open = false,
  handleClose,
  defaultValue,
  fields,
  hasHiddenField = false,
  paginationKey = '',
}: ModalFilterComponentProps) => {
  const location = useLocation();
  const [showAll, setShowAll] = React.useState(false);

  const {
    handleSubmit,
    control,
    reset,
    formState: { isDirty },
    watch,
    setValue,
  } = useForm<any>({
    reValidateMode: 'onChange',
    defaultValues: defaultValue,
  });

  const { filter } = useAppSelector(state => state.utils);

  const { palette } = useTheme();
  const dispatch = useAppDispatch();

  const onSubmit = (data: any) => {
    const modifiedData: DataObject = Object.entries(data).reduce(
      (acc, [key, value]) => {
        if (typeof value === 'object') {
          const date = formatDateToPost(value as any);
          if (date !== 'Invalid Date') {
            const newAcc = { ...acc, [key]: date };
            return newAcc;
          }
        }
        return acc;
      },
      {}
    );
    const submittedData = { ...data, ...modifiedData };
    dispatch(
      setSearchFilter({
        [`${location.pathname}${
          paginationKey !== '' ? `/${paginationKey}` : ''
        }`]: submittedData,
      })
    );
    dispatch(
      setPages({
        [`${location.pathname}${
          paginationKey !== '' ? `/${paginationKey}` : ''
        }`]: 0,
      })
    );
    handleClose(data);
  };

  React.useEffect(() => {
    reset(
      filter[
        `${location.pathname}${paginationKey !== '' ? `/${paginationKey}` : ''}`
      ] || defaultValue
    );
  }, [
    open,
    filter[
      `${location.pathname}${paginationKey !== '' ? `/${paginationKey}` : ''}`
    ],
  ]);

  return (
    <FilterContainer
      open={open}
      handleClose={handleClose}
      handleSubmit={handleSubmit}
      onSubmit={onSubmit}
      isDirty={isDirty}
    >
      {fields.map(field => {
        const { name, label, type, isHidden, ...props } = field;
        if (type === 'array') {
          return (
            <Box key={name} sx={{ display: 'flex', gap: '16px' }}>
              {field.content.map((content: any) => {
                const {
                  name: contentName,
                  label: contentLabel,
                  type: contentType,
                  ...contentProps
                } = content;
                return renderForm(
                  showAll && isHidden,
                  contentType,
                  control,
                  contentName,
                  contentLabel,
                  contentProps,
                  watch,
                  setValue
                );
              })}
            </Box>
          );
        }
        return renderForm(
          showAll && isHidden,
          type,
          control,
          name,
          label,
          props,
          watch,
          setValue
        );
      })}
      {hasHiddenField && !showAll && (
        <Button
          onClick={() => setShowAll(true)}
          variant="tertiary"
          sx={{ '& svg path': { stroke: palette.blue[50] } }}
        >
          Show All <ChevronDown />
        </Button>
      )}
    </FilterContainer>
  );
};

export default ModalFilterComponent;
