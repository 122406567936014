import { Grid } from '@mui/material';
import EmploymentSection from './EmploymentSection';
import WorkLocationSection from './WorkLocationSection';

const EmploymentInfo = () => (
  <Grid container spacing={2}>
    <Grid item xs={6} rowSpacing={3} padding="0">
      <EmploymentSection />
    </Grid>
    <Grid item xs={6} rowSpacing={3}>
      <WorkLocationSection />
    </Grid>
  </Grid>
);

export default EmploymentInfo;
