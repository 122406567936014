import { createSlice } from '@reduxjs/toolkit';

type TInitialState = {
  isOpenSideBar: boolean;
};
const initialState: TInitialState = {
  isOpenSideBar: false,
};

const responsiveSlice = createSlice({
  name: 'responsive',
  initialState,
  reducers: {
    openSideBar: state => {
      state.isOpenSideBar = true;
    },
    closeSideBar: state => {
      state.isOpenSideBar = false;
    },
  },
});

export const { openSideBar, closeSideBar } = responsiveSlice.actions;
export const responsiveReducer = responsiveSlice.reducer;
