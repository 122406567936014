import React from 'react';
import { Box, SxProps, Typography, useTheme } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { Button } from '../../components/atom';

const Thankyou = () => {
  const { palette } = useTheme();
  const navigate = useNavigate();
  const style: SxProps = {
    height: '100vh',
    width: '100vw',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    justifyContent: 'center',
    '& .container': {
      textAlign: 'center',
      width: '400px',
    },
    '& .section': {
      mb: '2rem',
    },
    '& .title': {
      fontWeight: 700,
      lineHeight: '2.25rem',
      color: palette.gray[100],
      mb: '1rem',
    },
    '& .content': {
      color: palette.gray[100],
    },
    '& .phoneNumber': {
      margin: 0,
      padding: 0,
      color: palette.blue[50],
      cursor: 'pointer',
      textDecoration: 'none',
    },
  };

  const backToLogin = () => {
    navigate('/login');
  };
  return (
    <Box sx={style}>
      <Box className="container">
        <Box className="section text-center">
          <img src="Logo.png" alt="tricor-unify" title="Tricor Unify" />
        </Box>
        <Typography variant="h4" className="title">
          Thank you!
        </Typography>
        <Typography variant="body2" className="section content">
          Our sales team will contact you within the next 24 business hours.
          Please call us at{' '}
          <a
            target="_blank"
            href="https://wa.me/6281250308020"
            className="phoneNumber"
            rel="noreferrer"
          >
            +62 812 5030 8020
          </a>{' '}
          if you need assistance.
        </Typography>
        <Button fullWidth variant="link" onClick={backToLogin}>
          Back to Login
        </Button>
      </Box>
    </Box>
  );
};

export default Thankyou;
