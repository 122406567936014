import { apiSlice } from '../api/apiSlice';

export const dailyAttendanceApiSlice = apiSlice.injectEndpoints({
  endpoints: builder => ({
    editDailyAttendance: builder.mutation<any, any>({
      query: ({ dateUnix, id, payload }) => ({
        url: `/time-management/daily-attendance/${dateUnix}/${id}`,
        method: 'PUT',
        body: payload,
      }),
      invalidatesTags: ['listDailyAttendance'],
    }),
    deleteShiftDailyAttendance: builder.mutation<
      void,
      { date: number; id: string; shiftId: string }
    >({
      query: ({ date, id, shiftId }) => ({
        url: `/time-management/daily-attendance/${date}/${id}/${shiftId}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['listDailyAttendance'],
    }),
  }),
});

export const {
  useEditDailyAttendanceMutation,
  useDeleteShiftDailyAttendanceMutation,
} = dailyAttendanceApiSlice;
