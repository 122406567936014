import React from 'react';
import { RouteNode } from '../../@types';
import { UploadCloud01 } from '../../components/atom/icon';

const List = React.lazy(() => import('../../pages/import/ListImportHistory'));
const PageDownload = React.lazy(
  () => import('../../pages/import/PageDownload')
);
const PageImport = React.lazy(
  () => import('../../pages/import/PageImportFile')
);
const Details = React.lazy(() => import('../../pages/import/DetailImport'));

export const importFileRoute: RouteNode[] = [
  {
    icon: UploadCloud01,
    label: 'Import',
    path: 'import',
    component: List,
    child: null,
  },
  {
    label: '',
    path: 'import/detail/:id',
    component: Details,
    child: null,
  },
  {
    label: '',
    path: 'import/download-template',
    component: PageDownload,
    child: null,
  },
  {
    label: '',
    path: 'import/import-file',
    component: PageImport,
    child: null,
  },
];
