import { Box, SxProps } from '@mui/material';
import { mergeDeep } from '../../../utils/deepMerge';
import { Button } from '../../atom';

type ConfirmationButtonProps = {
  negativeTitle?: string;
  positiveTitle?: string;
  onClickCancel?: Function;
  onSubmit?: Function;
  sx?: SxProps;
  isDisabledSubmitButton?: boolean;
  isLoading?: boolean;
};

const ConfirmationButton = ({
  negativeTitle = 'Cancel',
  positiveTitle = 'Save',
  onClickCancel,
  onSubmit,
  sx,
  isDisabledSubmitButton = false,
  isLoading = false,
}: ConfirmationButtonProps) => {
  const style: SxProps = {
    display: 'flex',
    gap: '1.5rem',
    justifyContent: 'flex-end',
  };
  return (
    <Box sx={mergeDeep(style, sx)}>
      <Button onClick={() => onClickCancel?.()} variant="tertiary">
        {negativeTitle}
      </Button>
      <Button
        isLoading={isLoading}
        onClick={() => onSubmit?.()}
        disabled={isDisabledSubmitButton}
      >
        {positiveTitle}
      </Button>
    </Box>
  );
};

export default ConfirmationButton;
