import { Box, Typography, useTheme } from '@mui/material';
import { BlockLayoutLogo } from '../../atom/icon';

const BlockLayout = () => {
  const { palette } = useTheme();
  return (
    <Box
      sx={{
        height: '100vh',
        backgroundImage: `url(${'/assets/Background.png'})`,
        backgroundSize: 'cover',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        gap: '32px',
        padding: '10px 24px 10px 24px',
      }}
    >
      <BlockLayoutLogo />
      <Typography
        textAlign="center"
        color={palette.gray[0]}
        variant="body16"
        fontWeight={500}
      >
        For a better experience, we recommend using a wider screen.
      </Typography>
    </Box>
  );
};

export default BlockLayout;
