import { Box, Grid } from '@mui/material';
import { useParams } from 'react-router-dom';
import { useAppSelector } from '../../../../store';
import { objectMapper } from '../../../../utils/objectMap';
import { address } from '../../../../constants/employee';
import LabelText from '../../../../components/atom/labelText/LabelText';
import HeaderAction from '../../../../components/molecule/action/HeaderAction';
import HistoricalTimeLine from '../../../../components/molecule/historicalTimeLine/HistoricalTimeLine';
import SubHeader from '../../../../components/molecule/subHeader/SubHeader';
import { useGetESSPersonalAddressDetailQuery } from '../../../../store/feature/service/essEmployee';

const AddressSection = ({ index, groupId, handleClickAction }: any) => {
  const { id } = useParams();
  const selectedHeader = `employee/address/${groupId}`;
  const { headerDate } = useAppSelector(state => state.utils);
  const { data }: any = useGetESSPersonalAddressDetailQuery({
    id,
    dataId: groupId,
    effectiveDate: headerDate[selectedHeader],
  });
  const currentData: { [key: string]: any } = {
    ...objectMapper(data?.data?.old || {}),
    ...objectMapper(
      data?.data?.old?.customFields.reduce((obj: any, field: any) => {
        let item = {
          ...obj,
        };
        if (field.name && field.value) {
          item = {
            ...item,
            [field.name]: field.value,
          };
        }
        return item;
      }, {}) || {}
    ),
    effectiveDate: (
      <HistoricalTimeLine headersState={data} selectedTab={selectedHeader} />
    ),
  };

  const upcomingData: { [key: string]: any } = {
    ...objectMapper(data?.data?.new || {}),
    ...objectMapper(
      data?.data?.new?.customFields.reduce((obj: any, field: any) => {
        let item = {
          ...obj,
        };
        if (field.name && field.value) {
          item = {
            ...item,
            [field.name]: field.value,
          };
        }
        return item;
      }, {}) || {}
    ),
  };

  const customeHeaderKey: Record<string, any> = {
    ...address,
    ...data?.data?.old?.customFields?.reduce((obj: any, field: any) => {
      let item = {
        ...obj,
      };
      if (field.name) {
        item = {
          ...item,
          [field.name]: field.name,
        };
      }
      return item;
    }, {}),
    ...data?.data?.new?.customFields.reduce((obj: any, field: any) => {
      let item = {
        ...obj,
      };
      if (field.name) {
        item = {
          ...item,
          [field.name]: field.name,
        };
      }
      return item;
    }, {}),
  };

  return (
    <Grid item container spacing={1} mb="1rem">
      {index === 0 && (
        <Grid item xs={12}>
          <HeaderAction
            label="Address"
            isCorrectionEnabled={false}
            isButtonEnabled={false}
          />
        </Grid>
      )}
      <Grid item xs={12}>
        <Box
          sx={{
            '& .list': {
              display: 'flex',
              padding: '8px 12px',
            },
            '& .sub-title': {
              fontWeight: 700,
            },
            display: 'flex',
            flexDirection: 'column',
            gap: '20px',
          }}
        >
          <SubHeader
            label="Address"
            idx={index}
            onClickMenuAction={handleClickAction}
            data={data}
            isCorrectionEnabled={false}
          />
        </Box>
      </Grid>
      {Object.keys(customeHeaderKey).map((key: any) => (
        <Grid item xs={12}>
          <LabelText
            headerKey={customeHeaderKey[key]}
            currentData={currentData?.[key]}
            upcommingData={upcomingData?.[key]}
            dataPresentationStatus={data?.dataPresentationStatus}
          />
        </Grid>
      ))}
    </Grid>
  );
};

export default AddressSection;
