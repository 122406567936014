import { TableBody as TableB, Typography, useTheme } from '@mui/material';
import Box from '@mui/material/Box';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

import { TableBodyProps } from '../../../@types/TableProps';
import './TableBody.css';

const TableBody = (Props: TableBodyProps) => {
  const { headerKey, showShadow, stickyLeft, showRightShadow } = Props;
  const { palette } = useTheme();

  const rowData = Array.from(Array(10).keys());

  return (
    <TableB>
      {rowData.map((value: any, index: number) => {
        if (stickyLeft) {
          return (
            <TableRow
              key={JSON.stringify(value)}
              sx={{
                background:
                  index % 2 === 0 ? palette.gray[0] : palette.gray[10],
                '& .body-14': {
                  width: '52px',
                  padding: '12px 16px',
                  boxSizing: 'border-box',
                  height: '44px',
                },
                '& .sticky': {
                  backgroundColor:
                    index % 2 === 0 ? palette.gray[0] : palette.gray[10],
                  '&::after': {
                    position: 'absolute',
                    right: '-8px',
                    top: 0,
                    content: "''",
                    width: '8px',
                    height: '100%',
                    boxShadow: showShadow
                      ? 'inset 10px 0 8px -8px rgb(5 5 5 / 6%)'
                      : 'none',
                  },
                  '& .sticky-table': {
                    maxWidth: '200px',
                    minWidth: '150px',
                    padding: '12px 16px',
                    boxSizing: 'border-box',
                  },
                },
                '&:hover': {
                  backgroundColor: '#F3F6F9 !important',
                  '& .sticky': {
                    backgroundColor: '#F3F6F9 !important',
                  },
                },
              }}
            >
              <TableCell className="sticky">
                <Box sx={{ display: 'flex' }}>
                  <Typography
                    className="body-14"
                    variant="body14"
                    component="p"
                    color={palette.gray[100]}
                  >
                    <Skeleton />
                  </Typography>
                  {Object.keys(headerKey).map(
                    (key, index) =>
                      index < 1 && (
                        <Box key={key + value[key]} className="sticky-table">
                          <Typography variant="body14">
                            <Skeleton />
                          </Typography>
                        </Box>
                      )
                  )}
                </Box>
              </TableCell>
              {Object.keys(headerKey).map(
                (key, index) =>
                  index >= 1 && (
                    <TableCell key={key + value[key]}>
                      <Typography
                        component="div"
                        sx={{
                          whiteSpace: 'nowrap',
                          padding: '12px 16px',
                        }}
                        variant="body14"
                        color={palette.gray[100]}
                      >
                        <Skeleton />
                      </Typography>
                    </TableCell>
                  )
              )}
            </TableRow>
          );
        }
        return (
          <TableRow
            key={JSON.stringify(value)}
            sx={{
              background: index % 2 === 0 ? palette.gray[0] : palette.gray[10],
              '&:hover': {
                backgroundColor: '#F3F6F9',
                '& .sticky': {
                  backgroundColor: '#F3F6F9',
                },
              },
            }}
          >
            <TableCell>
              <Typography
                variant="body14"
                component="p"
                color={palette.gray[100]}
                sx={{
                  padding: '12px 16px',
                }}
              >
                <Skeleton />
              </Typography>
            </TableCell>
            {Object.keys(headerKey).map((key, index) => (
              <TableCell key={key + value[key]}>
                <Typography
                  component="div"
                  sx={{
                    whiteSpace: 'nowrap',
                    padding: '12px 16px',
                  }}
                  variant="body14"
                >
                  <Skeleton />
                </Typography>
              </TableCell>
            ))}
          </TableRow>
        );
      })}
    </TableB>
  );
};

export default TableBody;
