const Wallet02 = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.6667 5.33333V3.00044C10.6667 2.44594 10.6667 2.1687 10.5499 1.99832C10.4478 1.84945 10.2898 1.74833 10.1118 1.71805C9.90819 1.6834 9.65646 1.79958 9.153 2.03195L3.23934 4.76133C2.79034 4.96856 2.56583 5.07218 2.4014 5.23288C2.25604 5.37495 2.14508 5.54837 2.077 5.73989C2 5.95653 2 6.20379 2 6.69831V10M11 9.66667H11.0067M2 7.46667L2 11.8667C2 12.6134 2 12.9868 2.14532 13.272C2.27316 13.5229 2.47713 13.7268 2.72801 13.8547C3.01323 14 3.3866 14 4.13333 14H11.8667C12.6134 14 12.9868 14 13.272 13.8547C13.5229 13.7268 13.7268 13.5229 13.8547 13.272C14 12.9868 14 12.6134 14 11.8667V7.46667C14 6.71993 14 6.34656 13.8547 6.06135C13.7268 5.81046 13.5229 5.60649 13.272 5.47866C12.9868 5.33333 12.6134 5.33333 11.8667 5.33333L4.13333 5.33333C3.3866 5.33333 3.01323 5.33333 2.72801 5.47866C2.47713 5.60649 2.27316 5.81046 2.14532 6.06135C2 6.34656 2 6.71993 2 7.46667ZM11.3333 9.66667C11.3333 9.85076 11.1841 10 11 10C10.8159 10 10.6667 9.85076 10.6667 9.66667C10.6667 9.48257 10.8159 9.33333 11 9.33333C11.1841 9.33333 11.3333 9.48257 11.3333 9.66667Z"
      stroke="#475667"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default Wallet02;
