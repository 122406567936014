import { createSlice } from '@reduxjs/toolkit';
import { timeItemSlice } from './service/timeItem';

interface initialStateI {
  messageSuccess: string;
  messageError: string;
}

const initialState: initialStateI = {
  messageSuccess: '',
  messageError: '',
};

const timeItem = createSlice({
  name: 'timeItem',
  initialState,
  reducers: {
    removeMessage: state => {
      state.messageSuccess = '';
      state.messageError = '';
    },
  },
  extraReducers(builder) {
    builder.addMatcher(
      timeItemSlice.endpoints.createTimeItem.matchFulfilled,
      (state, _action) => {
        state.messageSuccess = 'Time Item created';
      }
    );
    builder.addMatcher(
      timeItemSlice.endpoints.updateTimeItem.matchFulfilled,
      (state, _action) => {
        state.messageSuccess = 'Time Item updated';
      }
    );
  },
});

export const { removeMessage } = timeItem.actions;
export const timeItemReducer = timeItem.reducer;
