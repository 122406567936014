import {
  FormControl,
  FormLabel,
  SxProps,
  Typography,
  useTheme,
} from '@mui/material';
import React, { ReactNode } from 'react';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { GroupBase } from 'react-select';
import { AsyncProps } from 'react-select/async';

import { InputProps } from '../../../../@types';
import { LoadingSkeleton, Select } from '../../../atom';
import { getMessageFromObject } from '../../../../utils/objectMap';

export type CustomProps = {
  label?: string;
  customLabel?: Function;
  isLoading?: boolean;
  errorMessage?: string;
  showLabel?: boolean;
  CustomComponent?: ReactNode;
  customErrorMessage?: any;
};

const SelectWithLabel = <
  OptionType,
  IsMulti extends boolean = false,
  GroupType extends GroupBase<OptionType> = GroupBase<OptionType>,
  UseFieldArrayOptions extends Record<string, any> = any
>(
  Props: InputProps &
    AsyncProps<OptionType, IsMulti, GroupType> &
    UseFieldArrayOptions &
    CustomProps
) => {
  const { palette } = useTheme();
  const { t } = useTranslation();

  const {
    sx,
    name,
    control,
    label,
    url,
    additional,
    customLabel,
    onChange,
    isLoading,
    errorMessage,
    disabled,
    isMulti,
    required = true,
    showLabel = true,
    CustomComponent,
    customErrorMessage = null,
    ...props
  } = Props;
  const style = {
    '& .MuiFormLabel-root': {
      marginBottom: '0.5rem',
    },
    '& .input--error ': {
      '& .select__control:not(.select__control--is-focused)': {
        borderColor: `${palette.red[50]} !important`,
      },
      '&:focused': {
        borderColor: `${palette.red[50]} !important`,
      },
    },
    ...sx,
  } as SxProps;

  return (
    <FormControl sx={style} fullWidth>
      {showLabel && (
        <FormLabel htmlFor={name}>
          <Typography
            color={palette.gray[100]}
            variant="body14"
            fontWeight={500}
            sx={{
              wordWrap: 'break-word',
              maxWidth: '500px',
            }}
          >
            {label}
            {CustomComponent}
            {!required && (
              <Typography
                component="span"
                variant="body14"
                color={palette.gray[80]}
              >
                (optional)
              </Typography>
            )}
          </Typography>
        </FormLabel>
      )}
      {control && (
        <Controller
          control={control}
          name={name || ''}
          render={({ field, formState }) => {
            const { errors } = formState as any;
            const isError =
              Object.keys(errors).length > 0 &&
              name &&
              getMessageFromObject(errors, name);
            return (
              <>
                {isLoading && (
                  <LoadingSkeleton
                    sx={{
                      '& .react-loading-skeleton': {
                        height: '48px',
                        borderRadius: '8px',
                      },
                    }}
                  />
                )}
                {!isLoading && (
                  <Select
                    id={name}
                    className={isError ? 'input--error' : ''}
                    url={url}
                    additional={additional}
                    customLabel={(value: any) => customLabel?.(value)}
                    closeMenuOnSelect={!isMulti}
                    isMulti={isMulti}
                    {...props}
                    {...field}
                    onChange={(item: any) => {
                      field.onChange(item);
                      onChange?.(item);
                    }}
                    isDisabled={disabled}
                  />
                )}
                {/* {(isError || errorMessage) && name && ( */}
                {isError && !customErrorMessage && (
                  <Typography
                    marginTop="8px"
                    component="p"
                    variant="body14"
                    color={palette.red[50]}
                  >
                    {`${t(getMessageFromObject(errors, name))}`}
                  </Typography>
                )}
                {customErrorMessage && customErrorMessage}
              </>
            );
          }}
        />
      )}
      {!control && (
        <>
          {isLoading && (
            <LoadingSkeleton
              sx={{
                '& .react-loading-skeleton': {
                  height: '48px',
                  borderRadius: '8px',
                },
              }}
            />
          )}
          {!isLoading && (
            <Select
              id={name}
              url={url}
              additional={additional}
              customLabel={(value: any) => customLabel?.(value)}
              closeMenuOnSelect={!isMulti}
              isMulti={isMulti}
              {...props}
              onChange={(item: any) => {
                onChange?.(item);
              }}
              isDisabled={disabled}
            />
          )}
        </>
      )}
    </FormControl>
  );
};

export default SelectWithLabel;
