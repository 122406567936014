import { SxProps, Typography, useTheme } from '@mui/material';
import { ReactNode } from 'react';

type TitleProps = {
  sx?: SxProps;
  title: ReactNode | string;
};

const Title = ({ sx, title }: TitleProps) => {
  const { palette } = useTheme();
  const style: SxProps = {
    fontWeight: 700,
    ...sx,
  };
  return (
    <Typography sx={style} variant="body18" color={palette.gray[90]}>
      {title}
    </Typography>
  );
};

export default Title;
