export const getDifferentProperties = (current: any, upcoming: any) => {
  const transform: any = {};
  Object.keys(current).forEach(prop => {
    if (current[prop] !== upcoming[prop] && prop !== 'effectiveDate') {
      if (!transform[prop]) {
        transform[prop] = [current[prop], upcoming[prop]];
      } else {
        transform[prop].push(upcoming[prop]);
      }
    }
  });
  return transform;
};
