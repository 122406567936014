import { createSlice } from '@reduxjs/toolkit';
import { attendanceProcessApiSlice } from './service/attendanceProcessApiSlice';

interface initialStateI {
  messageSuccess: string;
  messageError: string;
  messageSuccessDetail: string;
  messageErrorDetail: string;
}

const initialState: initialStateI = {
  messageSuccess: '',
  messageError: '',
  messageSuccessDetail: '',
  messageErrorDetail: '',
};

const attendanceProcessSlice = createSlice({
  name: 'attendanceProcess',
  initialState,
  reducers: {
    removeMessage: state => {
      state.messageSuccess = '';
      state.messageError = '';
      state.messageSuccessDetail = '';
      state.messageErrorDetail = '';
    },
  },
  extraReducers(builder) {
    builder.addMatcher(
      attendanceProcessApiSlice.endpoints.createAttendanceProcess
        .matchFulfilled,
      (state, _action) => {
        state.messageSuccess = 'Attendance Process created';
      }
    );
    builder.addMatcher(
      attendanceProcessApiSlice.endpoints.createAttendanceProcess.matchRejected,
      (state, action) => {
        const payload = action.payload as any;
        state.messageError = payload?.data?.message || 'Internal Server Error';
      }
    );
    builder.addMatcher(
      attendanceProcessApiSlice.endpoints.deleteAttendanceProcess
        .matchFulfilled,
      state => {
        state.messageSuccessDetail = 'Attendance Process deleted';
      }
    );
    builder.addMatcher(
      attendanceProcessApiSlice.endpoints.deleteAttendanceProcess.matchRejected,
      state => {
        state.messageErrorDetail =
          "This data can't be deleted as it had been processed in another module.";
      }
    );
    builder.addMatcher(
      attendanceProcessApiSlice.endpoints.reprocessAttendanceData.matchRejected,
      (state, action) => {
        console.log(state, action);
        state.messageErrorDetail =
          'This data can’t be reprocessed as it had been processed in another module.';
      }
    );
    builder.addMatcher(
      attendanceProcessApiSlice.endpoints.checkIfAttendanceIsReprocessing
        .matchFulfilled,
      (state, action) => {
        if (action.payload?.statusCode === 'COMPLETED') {
          state.messageSuccessDetail = 'Attendance reprocessed';
        }
      }
    );
    builder.addMatcher(
      attendanceProcessApiSlice.endpoints.cancelAttendanceData.matchFulfilled,
      state => {
        state.messageSuccessDetail = '';
      }
    );
  },
});

export const { removeMessage } = attendanceProcessSlice.actions;
export const attendanceProcessReducer = attendanceProcessSlice.reducer;
