import React from 'react';
import { RouteNode } from '../../@types';
import { FileDownload02 } from '../../components/atom/icon';

const UnderConstruction = React.lazy(
  () => import('../../pages/UnderConstruction')
);
const StandartReport = React.lazy(
  () => import('../../pages/standardReport/List')
);
const EmployeeReportHistory = React.lazy(
  () => import('../../pages/standardReport/EmployeeReportHistory')
);
const PayrollRegister = React.lazy(
  () => import('../../pages/standardReport/PayrollRegister')
);
const PayrollSummary = React.lazy(
  () => import('../../pages/standardReport/PayrollSummary')
);
// GENERATE PAGE
const Generate1721i = React.lazy(
  () => import('../../pages/standardReport/Generate1721i')
);
const GenerateTaxCalculation = React.lazy(
  () => import('../../pages/standardReport/GenerateTaxCalculation')
);
const GenerateCostCenter = React.lazy(
  () => import('../../pages/standardReport/GenerateCostCenter')
);
const GenerateGeneralLedger = React.lazy(
  () => import('../../pages/standardReport/GenerateGeneralLedger')
);
const GenerateBPJSKesehatan = React.lazy(
  () => import('../../pages/standardReport/GenerateBPJSKesehatan')
);
const Generate1721A1 = React.lazy(
  () => import('../../pages/standardReport/Generate1721A1')
);
const GenerateBPJSKetenagakerjaan = React.lazy(
  () => import('../../pages/standardReport/GenerateBPJSKetenagakerjaan')
);
const GenerateBupot2126 = React.lazy(
  () => import('../../pages/standardReport/GenerateBupot2126')
);
const GenerateOvertimeRport = React.lazy(
  () => import('../../pages/standardReport/GenerateOvertime')
);
// CONFIGURATION PAGE
const ConfigurationReportBpjsKesehatan = React.lazy(
  () => import('../../pages/standardReport/configuration/BpjsKesehatan')
);
const ConfigurationReport1721 = React.lazy(
  () => import('../../pages/standardReport/configuration/1721-i')
);

const ConfigurationReport1721A1 = React.lazy(
  () => import('../../pages/standardReport/configuration/Configuration1721A1')
);
const ConfigurationBPJSKetenagakerjaanBaru = React.lazy(
  () =>
    import('../../pages/standardReport/configuration/BpjsKetenagakerjaanBaru')
);
const ConfigurationBPJSKetenagakerjaanLanjutan = React.lazy(
  () =>
    import(
      '../../pages/standardReport/configuration/BpjsKetenagakerjaanLanjutan'
    )
);
const ConfigurationEBupot21 = React.lazy(
  () => import('../../pages/standardReport/configuration/EBupot21')
);

// HISTORY VIEW
const ViewHistoryBPJSKesehatan = React.lazy(
  () => import('../../pages/standardReport/history/BPJSKesehatan')
);
const ViewHistoryBPJSTKBaru = React.lazy(
  () => import('../../pages/standardReport/history/BPJSTKBaru')
);
const ViewHistoryBPJSTKLanjutan = React.lazy(
  () => import('../../pages/standardReport/history/BPJSTKLanjutan')
);
const ViewHistory1721i = React.lazy(
  () => import('../../pages/standardReport/history/1721i')
);
const ViewHistory1721ai = React.lazy(
  () => import('../../pages/standardReport/history/1721A1')
);
const ViewHistoryEbupot2126 = React.lazy(
  () => import('../../pages/standardReport/history/Ebupot2126')
);

export const ReportRoute: RouteNode[] = [
  {
    label: 'Report',
    icon: FileDownload02,
    path: 'report',
    component: null,
    child: [
      {
        label: 'Standard Report',
        path: 'standard-report',
        component: StandartReport,
        child: null,
      },
      {
        label: '',
        path: 'standard-report/employee-info-history',
        component: EmployeeReportHistory,
        child: null,
      },
      {
        label: '',
        path: 'standard-report/payroll-register',
        component: PayrollRegister,
        child: null,
      },
      {
        label: '',
        path: 'standard-report/payroll-summary',
        component: PayrollSummary,
        child: null,
      },
      {
        label: '',
        path: 'standard-report/generate-1721-i',
        component: Generate1721i,
        child: null,
      },
      {
        label: '',
        path: 'standard-report/generate-1721-a1',
        component: Generate1721A1,
        child: null,
      },
      {
        label: '',
        path: 'standard-report/generate-tax-calculation',
        component: GenerateTaxCalculation,
        child: null,
      },
      {
        label: '',
        path: 'standard-report/generate-bpjs',
        component: GenerateBPJSKesehatan,
        child: null,
      },
      {
        label: '',
        path: 'standard-report/generate-cost-center',
        component: GenerateCostCenter,
        child: null,
      },
      {
        label: '',
        path: 'standard-report/generate-general-ledger',
        component: GenerateGeneralLedger,
        child: null,
      },
      {
        label: '',
        path: 'standard-report/generate-bpjs-ketenagakerjaan-baru',
        component: GenerateBPJSKetenagakerjaan,
        child: null,
      },
      {
        label: '',
        path: 'standard-report/generate-bpjs-ketenagakerjaan-lanjutan',
        component: GenerateBPJSKetenagakerjaan,
        child: null,
      },
      {
        label: '',
        path: 'standard-report/generate-e-bupot-2126',
        component: GenerateBupot2126,
        child: null,
      },
      {
        label: '',
        path: 'standard-report/generate-overtime',
        component: GenerateOvertimeRport,
        child: null,
      },

      // {
      //   label: 'My Report',
      //   path: 'my-report',
      //   component: UnderConstruction,
      //   child: null,
      // },
      // {
      //   label: 'Custom Report',
      //   path: 'custom-report',
      //   component: UnderConstruction,
      //   child: null,
      // },
      // {
      //   label: 'General Ledger',
      //   path: 'general-ledger',
      //   component: UnderConstruction,
      //   child: null,
      // },

      // CONFIGURATION PAGE
      {
        label: '',
        path: 'bpjs-configuration',
        component: ConfigurationReportBpjsKesehatan,
        child: null,
      },
      {
        label: '',
        path: '1721-configuration',
        component: ConfigurationReport1721,
        child: null,
      },
      {
        label: '',
        path: 'standard-report/ebupot21-configuration',
        component: ConfigurationEBupot21,
        child: null,
      },
      {
        label: '',
        path: 'standard-report/1721a1-configuration',
        component: ConfigurationReport1721A1,
        child: null,
      },
      {
        label: '',
        path: 'standard-report/configuration-bpjs-ketenagakerjaan-baru',
        component: ConfigurationBPJSKetenagakerjaanBaru,
        child: null,
      },
      // HISTORY VIEW
      {
        label: '',
        path: 'standard-report/history-view/bpjs-kesehatan',
        component: ViewHistoryBPJSKesehatan,
        child: null,
      },
      {
        label: '',
        path: 'standard-report/configuration-bpjs-ketenagakerjaan-lanjutan',
        component: ConfigurationBPJSKetenagakerjaanLanjutan,
        child: null,
      },
      {
        label: '',
        path: 'standard-report/history-view/bpjs-tk-baru',
        component: ViewHistoryBPJSTKBaru,
        child: null,
      },
      {
        label: '',
        path: 'standard-report/history-view/bpjs-tk-lanjutan',
        component: ViewHistoryBPJSTKLanjutan,
        child: null,
      },
      {
        label: '',
        path: 'standard-report/history-view/1721i',
        component: ViewHistory1721i,
        child: null,
      },
      {
        label: '',
        path: 'standard-report/history-view/1721a1',
        component: ViewHistory1721ai,
        child: null,
      },
      {
        label: '',
        path: 'standard-report/history-view/e-bupot-2126',
        component: ViewHistoryEbupot2126,
        child: null,
      },
    ],
  },
];
