import { createSlice } from '@reduxjs/toolkit';
import { WorkLocationApiSlice } from './service/workLocation';

interface initialStateI {
  messageSuccess: string;
  messageError: string;
  messageSuccessUpdate: string;
  messageErrorUpdate: string;
}

const initialState: initialStateI = {
  messageSuccess: '',
  messageError: '',
  messageSuccessUpdate: '',
  messageErrorUpdate: '',
};

const WorkLocationSlice = createSlice({
  name: 'workLocation',
  initialState,
  reducers: {
    removeMessage: state => {
      state.messageSuccess = '';
      state.messageErrorUpdate = '';
      state.messageSuccessUpdate = '';
    },
    removeMessageUpdate: state => {
      state.messageSuccessUpdate = '';
      state.messageErrorUpdate = '';
    },
  },
  extraReducers(builder) {
    builder.addMatcher(
      WorkLocationApiSlice.endpoints.addWorkLocation.matchFulfilled,
      state => {
        state.messageSuccess = 'Work Location created';
      }
    );
    builder.addMatcher(
      WorkLocationApiSlice.endpoints.updateWorkLocation.matchFulfilled,
      (state, _action) => {
        if (_action.payload.typeOfChange === 'UPDATED') {
          state.messageSuccessUpdate = 'Work Location updated';
        } else if (_action.payload.typeOfChange === 'CORRECTED') {
          state.messageSuccessUpdate = 'Work Location corrected';
        }
      }
    );
    builder.addMatcher(
      WorkLocationApiSlice.endpoints.deleteWorkLocation.matchFulfilled,
      (state, _action) => {
        state.messageSuccess = 'Work Location deleted';
      }
    );
    builder.addMatcher(
      WorkLocationApiSlice.endpoints.deleteWorkLocation.matchRejected,
      (state, action: any) => {
        state.messageErrorUpdate = action.payload.data.message;
      }
    );
    builder.addMatcher(
      WorkLocationApiSlice.endpoints.cancelUpdateWorkLocation.matchFulfilled,
      (state, _action) => {
        state.messageSuccessUpdate = 'Upcoming Changes cancelled';
        state.messageSuccess = 'Upcoming Changes cancelled';
      }
    );
    builder.addMatcher(
      WorkLocationApiSlice.endpoints.cancelUpdateWorkLocation.matchRejected,
      (state, action) => {
        const payload = action.payload as any;
        state.messageErrorUpdate = payload?.data?.message;
      }
    );
  },
});

export const { removeMessage, removeMessageUpdate } = WorkLocationSlice.actions;
export const workLocationReducer = WorkLocationSlice.reducer;
