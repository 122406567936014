const LOCAL_STORAGE_KEY = {
  TOKEN_STORAGE: 'app__token',
  EXPIRE_TOKEN_TIME: 'app__refresh_token',
  TOKEN_OTP: 'app__token__otp',
  REFRESH_TOKEN: 'tricor__admin__app__refresh__token',
  FULL_NAME: 'tricor__admin__app__full__name',
  USER_EMAIL: 'tricor__admin__app__user__email',
  OTP_TIME: 'tricor__admin__app__otp__time',
  USER_ACCESS: 'tricor__admin__app__user__access',
  PROFILE_PICTURE: 'tricor__admin__app__user_profile_picture',
  RESEND_OTP_TOKEN: 'tricor__admin__app__resend__otp__token',
  CLIENT_ID: 'tricor__client__id',
  CLIENT_SECRET: 'tricor__client_secret',
  AUTHORITY: 'tricor__authority',
};

export default LOCAL_STORAGE_KEY;
