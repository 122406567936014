const Frame2822 = () => (
  <svg
    width="48"
    height="48"
    viewBox="0 0 48 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="48" height="48" rx="24" fill="#12B76A" />
    <g clipPath="url(#clip0_8765_171520)">
      <path
        d="M34 23.0857V24.0057C33.9988 26.1621 33.3005 28.2604 32.0093 29.9875C30.7182 31.7147 28.9033 32.9782 26.8354 33.5896C24.7674 34.201 22.5573 34.1276 20.5345 33.3803C18.5117 32.633 16.7847 31.2518 15.611 29.4428C14.4373 27.6338 13.8798 25.4938 14.0217 23.342C14.1636 21.1903 14.9972 19.1421 16.3983 17.5028C17.7994 15.8635 19.6928 14.7211 21.7962 14.2459C23.8996 13.7706 26.1003 13.9881 28.07 14.8657M34 16L24 26.01L21 23.01"
        stroke="white"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_8765_171520">
        <rect
          width="24"
          height="24"
          fill="white"
          transform="translate(12 12)"
        />
      </clipPath>
    </defs>
  </svg>
);
export default Frame2822;
