import {
  EmployeeStatusChart,
  EmployeeGenerationChart,
  EmployeeTenureChart,
  EmployeeCelebrationChart,
  EmployeeEmploymentChart,
  TaskOverviewDataType,
  OverlapTaskEmployeeType,
  ReleaseNotesReponse,
  TimeOffRequestTaskDetailType,
  AttendanceRequestTaskDetailType,
} from '../../../@types/HomeTypes';
import { NewsList } from '../../../@types/NewsType';
import { QueryParams } from '../../../@types/QueryParams';
import { TenantListType } from '../../../@types/UserTenantType';
import { apiSlice } from '../api/apiSlice';

export const homeApiSlice = apiSlice.injectEndpoints({
  endpoints: builder => ({
    //  Employee Overview
    getListStatusChart: builder.query<EmployeeStatusChart[], any>({
      query: () => ({
        url: '/employee/home/status',
      }),
    }),
    getListGenerationChart: builder.query<EmployeeGenerationChart[], any>({
      query: () => ({
        url: '/employee/home/generation',
      }),
    }),
    getTenureChart: builder.query<EmployeeTenureChart, any>({
      query: () => ({
        url: '/employee/home/tenure',
      }),
    }),
    getListCelebrationChart: builder.query<EmployeeCelebrationChart[], any>({
      query: () => ({
        url: '/employee/home/celebration',
      }),
    }),
    getListEmploymentChart: builder.query<EmployeeEmploymentChart[], any>({
      query: () => ({
        url: '/employee/home/employment',
      }),
    }),

    // Task Overview
    getListTask: builder.query<TaskOverviewDataType, QueryParams>({
      query: params => {
        const { search, limit, page, sortType, sort, sortBy } = params;
        return {
          url: '/organization/home/task',
          params: { search, limit, page, sortType, sort, sortBy },
        };
      },
      providesTags: ['ListTask'],
    }),
    detailTimeOffRequest: builder.query<TimeOffRequestTaskDetailType, any>({
      query: id => ({
        url: `/time-management/home/time-off-request/${id}`,
      }),
    }),
    detailAttendanceRequest: builder.query<
      AttendanceRequestTaskDetailType,
      any
    >({
      query: id => ({
        url: `/time-management/home/attendance-requests/${id}`,
      }),
    }),
    getListAttendanceRequest: builder.query<TaskOverviewDataType, QueryParams>({
      query: params => {
        const { search, limit, page, sortType, sort, sortBy } = params;
        return {
          url: 'time-management/home/attendance-requests',
          params: { search, limit, page, sortType, sort, sortBy },
        };
      },
      providesTags: ['ListTask'],
    }),
    listUpdateDataRequest: builder.query<any, any>({
      query: params => {
        const { search, limit, page, sortType, sort, sortBy } = params;
        return {
          url: 'employee/employees/request-update/approval',
          params: { search, limit, page, sortType, sort, sortBy },
        };
      },
      providesTags: ['ListTask'],
    }),
    detailUpdateDataRequest: builder.query<any, any>({
      query: id => ({
        url: `employee/employees/request-update/approval/${id}`,
      }),
    }),
    getOverlapTaskEmployee: builder.query<
      Array<OverlapTaskEmployeeType>,
      { id: string }
    >({
      query: params => {
        const { id } = params;
        return {
          url: `/time-management/home/time-off-request/${id}/overlap-employees`,
        };
      },
      providesTags: ['OverlapTaskEmployee'],
    }),
    submitTaskApproval: builder.mutation({
      query: ({ id, payload }) => ({
        url: `/organization/home/task/${id}/approval`,
        method: 'PUT',
        body: payload,
      }),
      invalidatesTags: ['ListTask'],
    }),
    //  List News
    getListNews: builder.query<NewsList, any>({
      query: (params: { limit: any; page: any }) => {
        const { limit, page } = params;
        return {
          url: '/organization/news',
          params: { limit, page },
        };
      },
      providesTags: ['newsList'],
    }),
    userTenantList: builder.query<TenantListType, void>({
      query: () => ({
        url: '/identity/user-tenants',
      }),
      providesTags: ['profileDetail'],
    }),
    releaseNotes: builder.query<
      ReleaseNotesReponse,
      { limit: number; page: number }
    >({
      query: ({ limit, page }) => ({
        url: '/organization/release-notes',
        params: { limit, page },
      }),
    }),
  }),
});

export const {
  useGetListStatusChartQuery,
  useGetListGenerationChartQuery,
  useGetTenureChartQuery,
  useGetListCelebrationChartQuery,
  useGetListEmploymentChartQuery,
  useGetListNewsQuery,
  useGetListTaskQuery,
  useGetOverlapTaskEmployeeQuery,
  useGetListAttendanceRequestQuery,
  useUserTenantListQuery,
  useListUpdateDataRequestQuery,
  useDetailUpdateDataRequestQuery,
  useReleaseNotesQuery,
  useDetailTimeOffRequestQuery,
  useDetailAttendanceRequestQuery,
  useSubmitTaskApprovalMutation,
} = homeApiSlice;
