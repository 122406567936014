import React from 'react';
import { ToggleButton, ToggleButtonGroup } from '@mui/material';
import { UseFormSetValue, UseFormWatch } from 'react-hook-form';
import { ContactUsFormProps } from '../../@types/ContactUs';

interface Step2Props {
  onChange: Function;
  setValue: UseFormSetValue<ContactUsFormProps>;
  watch: UseFormWatch<ContactUsFormProps>;
}

const Step2 = ({ onChange, setValue, watch }: Step2Props) => {
  const handleChange = (
    _: React.MouseEvent<HTMLElement>,
    totalEmployees: string
  ) => {
    setValue('organization', totalEmployees);
    if (totalEmployees) {
      setTimeout(() => {
        onChange();
      }, 500);
    }
  };
  return (
    <ToggleButtonGroup
      orientation="vertical"
      value={watch('organization')}
      exclusive
      fullWidth
      onChange={handleChange}
    >
      <ToggleButton
        value="10-50 employees"
        className="option not-last"
        fullWidth
      >
        10 - 50 employees
      </ToggleButton>
      <ToggleButton
        value="51-100 employees"
        className="option not-last"
        fullWidth
      >
        51 - 100 employees
      </ToggleButton>
      <ToggleButton
        value="101-500 employees"
        className="option not-last"
        fullWidth
      >
        101 - 500 employees
      </ToggleButton>
      <ToggleButton
        value="501-1000 employees"
        className="option not-last"
        fullWidth
      >
        501 - 1000 employees
      </ToggleButton>
      <ToggleButton value="1000+ employees" className="option" fullWidth>
        1000+ employees
      </ToggleButton>
    </ToggleButtonGroup>
  );
};

export default Step2;
