import React from 'react';

const List = React.lazy(() => import('../../../pages/claim/List'));
const Detail = React.lazy(() => import('../../../pages/claim/Detail'));

export const claim = [
  {
    label: 'Claim',
    child: null,
    path: 'claim',
    component: List,
  },
  {
    label: '',
    path: 'claim/detail/:id',
    component: Detail,
    child: null,
  },
];
