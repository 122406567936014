import React from 'react';
import { Box, useTheme } from '@mui/material';
import { FilterFunnel2 } from '../../atom/icon';
import Modal from './Modal';

type FilterContainerProps = {
  open: boolean;
  handleClose: any;
  children: React.ReactNode;
  isDirty: boolean;
  handleSubmit: Function;
  onSubmit: Function;
};

const FilterContainer = ({
  open,
  handleClose,
  children,
  isDirty,
  handleSubmit,
  onSubmit,
}: FilterContainerProps) => {
  const { palette } = useTheme();
  return (
    <Modal
      fullHeight
      open={open}
      onClose={handleClose}
      showCloseIcon
      title="Filter"
      leftButtonProps={{
        variant: 'outlined',
        onClick: handleClose,
      }}
      leftButtonLabel="Cancel"
      rightButtonProps={{
        key: isDirty.toString(),
        variant: 'primary',
        onClick: handleSubmit(onSubmit),
        disabled: !isDirty,
      }}
      rightButonLabel="Apply"
      icon={
        <Box
          sx={{
            background: palette.gray[20],
            height: '48px',
            width: '48px',
            borderRadius: '90px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            '& svg path': {
              stroke: palette.gray[90],
            },
            mb: '1rem',
          }}
        >
          <FilterFunnel2 />
        </Box>
      }
    >
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: '20px' }}>
        {children}
      </Box>
    </Modal>
  );
};

export default FilterContainer;
