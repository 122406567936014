import { lazy } from 'react';

const List = lazy(() => import('../../../pages/policy/List'));
const CreateAttendancePolicy = lazy(
  () => import('../../../pages/policy/attendance/Create')
);
const DetailsAttendancePolicy = lazy(
  () => import('../../../pages/policy/attendance/Details')
);
const CreateEssPolicy = lazy(() => import('../../../pages/policy/ess/Create'));
const DetailsEssPolicy = lazy(
  () => import('../../../pages/policy/ess/Details')
);

export const policy = [
  {
    label: 'Policy',
    child: null,
    path: 'policy',
    component: List,
  },
  {
    label: '',
    child: null,
    path: 'policy/attendance/create',
    component: CreateAttendancePolicy,
  },
  {
    label: '',
    child: null,
    path: 'policy/attendance/detail/:id',
    component: DetailsAttendancePolicy,
  },
  {
    label: '',
    child: null,
    path: 'policy/ess/create',
    component: CreateEssPolicy,
  },
  {
    label: '',
    child: null,
    path: 'policy/ess/detail/:id',
    component: DetailsEssPolicy,
  },
];
