import { Box, SxProps, Typography, useTheme } from '@mui/material';
import { ReactNode } from 'react';
import Breadcrumb from '../../../molecule/breadcrumb/Breadcrumb';
import ESSContainer from '../content/ESSContainer';
import { mergeDeep } from '../../../../utils/deepMerge';

interface ContainerDetailProps {
  children: ReactNode;
  title?: string;
  subtitle?: string;
  sx?: SxProps;
}

const ESSDetailsContainer = ({
  children,
  title = '',
  subtitle = '',
  sx,
  ...props
}: ContainerDetailProps) => {
  const { palette } = useTheme();
  const style: SxProps = {
    gap: '0px',
    '& .title': {
      fontWeight: 700,
    },
    '& .container-title': {
      mb: '2rem',
    },
    '& .breadcrumb': {
      mb: '2rem',
    },
    '& .subtitle': {
      mt: '0.5rem',
      fontWeight: 400,
    },
  };

  return (
    <ESSContainer sx={mergeDeep(style, sx)} {...props}>
      <Breadcrumb className="breadcrumb" />
      <Box className="container-title">
        <Typography className="title" variant="h5" color={palette.gray[100]}>
          {title}
        </Typography>
        <Typography
          className="subtitle"
          variant="body14"
          color={palette.gray[80]}
        >
          {subtitle}
        </Typography>
      </Box>
      {children}
    </ESSContainer>
  );
};

export default ESSDetailsContainer;
