import { Box, SxProps } from '@mui/material';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

interface ILoadingSkeleton {
  sx: SxProps;
}

const LoadingSkeleton = ({ sx }: ILoadingSkeleton) => (
  <Box sx={sx}>
    <Skeleton />
  </Box>
);

export default LoadingSkeleton;
