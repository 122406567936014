import React from 'react';
import {
  Box,
  Typography,
  useTheme,
  Popover,
  useMediaQuery,
} from '@mui/material';
import { useLocation } from 'react-router-dom';

import { Button, Capsule, Searchbox } from '../../atom';
import {
  ChevronDown,
  ColumnsEdit,
  FilterFunnel2,
  Plus,
  Close,
} from '../../atom/icon';
import CheckboxWithLabel from '../form/checkboxWithLabel/CheckboxWithLabel';
import { TableHeaderProps } from '../../../@types/TableProps';
import { useAppSelector, useAppDispatch } from '../../../store';
import { removeFilter, resetAllFilter } from '../../../store/feature/utilSlice';
import { formatDateTimeFromUnixUTC } from '../../../utils/date';

const TableHeader = (Props: TableHeaderProps) => {
  const {
    headerKey,
    selected,
    setSelected,
    handleSelectAll,
    handleDeselectAll,
    onClickCreate,
    onFilterClick,
    showAction = false,
    showSearch = true,
    showEdit = true,
    showFilter = true,
    showCreate = true,
    actions,
    onClickMenuAction,
    onClickMenuCreate,
    additionalComponent,
    paginationKey,
    actionPopoverDirection = 'left',
    createPopoverDirection = 'left',
    createMenu,
    showBanner,
    componentBeforeAction,
  } = Props;
  const { palette } = useTheme();
  const location = useLocation();
  const pathName = paginationKey
    ? `${location.pathname}/${paginationKey}`
    : location.pathname;
  const dispatch = useAppDispatch();
  const { searchLabel } = useAppSelector(state => state.utils);

  const [columnGridAnchorEl, setColumnGridAnchorEl] =
    React.useState<HTMLButtonElement | null>(null);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setColumnGridAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setColumnGridAnchorEl(null);
  };
  const openColumnGrid = Boolean(columnGridAnchorEl);

  const [ActionAnchorEl, setActionAnchorEl] =
    React.useState<HTMLButtonElement | null>(null);
  const [CreateAnchorEl, setCreateAnchorEl] =
    React.useState<HTMLButtonElement | null>(null);
  const handleActionClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setActionAnchorEl(event.currentTarget);
  };
  const handleActionClose = (keepAnchor: boolean) => {
    setActionAnchorEl(null);
  };
  const handleCreateClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setCreateAnchorEl(event.currentTarget);
  };
  const handleCreateClose = () => {
    setCreateAnchorEl(null);
  };
  const openAction = Boolean(ActionAnchorEl);
  const openCreate = Boolean(CreateAnchorEl);
  const matches = useMediaQuery('(max-width:599px)');

  const isEpoch = (value: string) => {
    if (typeof value !== 'number') {
      return false;
    }
    const epochRegex = /^\d{10}$/;
    return epochRegex.test(value);
  };

  return (
    <>
      <Popover
        // id={id}
        open={openColumnGrid}
        anchorEl={columnGridAnchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        sx={{
          marginTop: '4px',
          '& .MuiPaper-root': {
            borderRadius: '8px',
          },
        }}
      >
        <Box
          sx={{
            width: '280px',
            // height: '384px',
            boxSizing: 'border-box',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              height: '54px',
              padding: '16px',
              boxSizing: 'border-box',
            }}
          >
            <Typography
              variant="body16"
              fontWeight={600}
              color={palette.gray[100]}
            >
              Column Displayed
            </Typography>
            <Button
              onClick={() => {
                Object.keys(headerKey).length === selected.length
                  ? handleDeselectAll()
                  : handleSelectAll();
              }}
              sx={{ height: '24px', minWidth: 0, padding: 0 }}
              variant="tertiary"
            >
              {Object.keys(headerKey).length === selected.length
                ? 'Deselect All'
                : 'Select All'}
            </Button>
          </Box>
          <Box
            sx={{
              padding: '16px',
              // height: 'calc(384px - 54px)',
              overflow: 'auto',
              boxSizing: 'border-box',
            }}
          >
            <CheckboxWithLabel
              options={Object.keys(headerKey).map((key: string) => ({
                label: headerKey[key],
                value: headerKey[key],
              }))}
              name=""
              onChange={(e: any) => {
                setSelected(e);
              }}
              defaultValue={selected}
            />
          </Box>
        </Box>
      </Popover>
      <Popover
        open={openAction}
        anchorEl={ActionAnchorEl}
        onClose={handleActionClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: actionPopoverDirection === 'left' ? 'left' : 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: actionPopoverDirection === 'left' ? 'left' : 'right',
        }}
        sx={{
          marginTop: '4px',
          '& .MuiPaper-root': {
            borderRadius: '8px',
          },
        }}
      >
        <Box
          sx={{
            boxSizing: 'border-box',
            paddingY: '4px',
            minWidth: '220px',
            '& .MuiButtonBase-root': {
              border: 'none',
              borderRadius: 0,
              justifyContent: 'flex-start',
              height: '44px',
              gap: '12px',
              paddingX: '16px',
              fontWeight: 400,
              lineHeight: '20px',
              color: palette.gray[100],
              '& svg': {
                width: '20px',
                height: '20px',
                minWidth: '20px !important',
                minHeight: '20px',
                '& path': {
                  stroke: palette.gray[90],
                },
              },
            },
          }}
        >
          {actions &&
            actions.map(action => {
              const { Icon, title, name, hasBorderTop } = action;
              return (
                <Button
                  key={name}
                  fullWidth
                  variant="outlined"
                  onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
                    onClickMenuAction?.(name, e, setActionAnchorEl);
                    // handleActionClose(keepAnchor);
                  }}
                  sx={{
                    borderTop: hasBorderTop
                      ? `1px solid ${palette.gray[50]} !important`
                      : 'none',
                  }}
                >
                  {Icon}
                  {title}
                </Button>
              );
            })}
        </Box>
      </Popover>
      <Popover
        open={openCreate}
        anchorEl={CreateAnchorEl}
        onClose={handleCreateClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: createPopoverDirection === 'left' ? 'left' : 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: createPopoverDirection === 'left' ? 'left' : 'right',
        }}
        sx={{
          marginTop: '4px',
          '& .MuiPaper-root': {
            borderRadius: '8px',
            width: '220px',
          },
        }}
      >
        <Box
          sx={{
            boxSizing: 'border-box',
            paddingY: '4px',
            '& .MuiButtonBase-root': {
              border: 'none',
              borderRadius: 0,
              justifyContent: 'flex-start',
              height: '44px',
              gap: '12px',
              paddingX: '16px',
              fontWeight: 400,
              lineHeight: '20px',
              color: palette.gray[100],
              '& svg': {
                width: '20px',
                height: '20px',
                minWidth: '20px !important',
                minHeight: '20px',
                '& path': {
                  stroke: palette.gray[90],
                },
              },
            },
          }}
        >
          {createMenu &&
            createMenu.map(menu => {
              const { Icon, title, name, hasBorderTop } = menu;
              return (
                <Button
                  key={name}
                  fullWidth
                  variant="outlined"
                  onClick={() => onClickMenuCreate?.(name)}
                  sx={{
                    borderTop: hasBorderTop
                      ? `1px solid ${palette.gray[50]} !important`
                      : 'none',
                  }}
                >
                  {Icon}
                  {title}
                </Button>
              );
            })}
        </Box>
      </Popover>

      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          flexDirection: matches ? 'column' : 'row',
          gap: matches ? '20px' : 0,
        }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            gap: '8px',
          }}
        >
          {showSearch && (
            <Searchbox
              paginationKey={paginationKey}
              sx={{ width: matches ? '100%' : '300px' }}
            />
          )}
          {showEdit && (
            <Button
              sx={{
                width: '48px',
                height: '48px',
                minWidth: '48px',
                '& svg': {
                  transform: 'scale(0.8333)',
                },
              }}
              variant="outlined"
              onClick={handleClick}
            >
              <ColumnsEdit />
            </Button>
          )}
          {showFilter && (
            <Button
              sx={{
                width: '48px',
                height: '48px',
                minWidth: '48px',
                '& svg': {
                  transform: 'scale(0.8333)',
                },
              }}
              variant="outlined"
              onClick={() => onFilterClick?.()}
            >
              <FilterFunnel2 />
            </Button>
          )}
        </Box>

        <Box
          sx={{
            display: 'flex',
            gap: '16px',
          }}
        >
          {componentBeforeAction}
          {showAction && (
            <Button variant="outlined" onClick={handleActionClick}>
              Action <ChevronDown />
            </Button>
          )}
          {showCreate && (
            <Button
              sx={{
                '& svg': {
                  height: '20px',
                  width: '20px',
                  minWidth: '20px !important',
                  minHeight: '20px',
                  '& path': {
                    stroke: palette.gray[0],
                  },
                },
                display: 'flex',
                gap: '8px',
              }}
              onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
                createMenu && handleCreateClick(e);
                !createMenu && onClickCreate?.();
              }}
            >
              <Plus />
              <Typography
                color={palette.gray[0]}
                fontWeight={600}
                variant="body14"
              >
                Create
              </Typography>
              {createMenu && <ChevronDown />}
            </Button>
          )}
          {additionalComponent}
        </Box>
      </Box>
      {showBanner}

      {searchLabel[pathName]?.length > 0 && (
        <Box
          sx={{
            display: 'flex',
            gap: '16px',
            width: '100%',
          }}
        >
          <Box
            sx={{
              height: '36px',
              width: '65px',
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <Typography variant="body14" color={palette.gray[90]}>
              Filter By:
            </Typography>
          </Box>
          <Box
            sx={{
              width: 'calc(100% - 70px)',
              display: 'flex',
              gap: '8px',
              flexWrap: 'wrap',
            }}
          >
            {searchLabel[pathName]?.map((item: any) => (
              <Capsule
                key={item}
                sx={{ '&:hover': { cursor: 'pointer' } }}
                onClick={() => {
                  dispatch(removeFilter({ [pathName]: item }));
                  dispatch(removeFilter({ [pathName]: item }));
                }}
              >
                <Typography variant="body14" color={palette.gray[90]}>
                  {isEpoch(item.label)
                    ? formatDateTimeFromUnixUTC(item.label)
                    : item.label}
                </Typography>
                <Close />
              </Capsule>
            ))}
            <Box
              sx={{
                '&:hover': {
                  cursor: 'pointer',
                },
                height: '36px',
                boxSizing: 'border-box',
                display: 'flex',
                alignItems: 'center',
              }}
              onClick={() => {
                dispatch(resetAllFilter(pathName));
                dispatch(resetAllFilter(pathName));
              }}
            >
              <Typography
                variant="body14"
                color={palette.blue[50]}
                fontWeight="600"
              >
                Reset Filter
              </Typography>
            </Box>
          </Box>
        </Box>
      )}
    </>
  );
};

export default TableHeader;
