import { Box, Typography, useTheme, SxProps } from '@mui/material';
import React from 'react';
import { useLocation } from 'react-router-dom';
import Button from '../../atom/button/Button';
import Marker from '../../atom/icon/Marker';
import { Plus } from '../../atom/icon';
import Header from '../action/HeaderAction';
import { removeInfoLabel } from '../../../utils/removeInfoLabel';
import { useAppSelector, RootState } from '../../../store';

type EmptyCardProps = {
  label: string;
  onClickAddTop?: Function;
  onClickAddBot?: Function;
  icon: React.ReactNode;
  onClickTopButton?: () => void;
  isESS?: boolean;
  customLabel?: string;
  style?: SxProps;
  isTopEnabled?: boolean;
  subText?: string | React.ReactNode;
  disableButton?: boolean;
  sx?: SxProps;
  sxSvg?: SxProps;
};

const ButtonAdd = ({ handleAction, label, disableButton }: any) => {
  const { palette } = useTheme();
  return (
    <Button
      style={{ backgroundColor: palette.blue[20], color: palette.blue[50] }}
      onClick={handleAction}
      disabled={disableButton}
    >
      <Plus sx={disableButton ? null : { color: palette.blue[50] }} />
      <Typography variant="body16" fontWeight="600">
        Add {label}
      </Typography>
    </Button>
  );
};

const EmptyCard = ({
  label,
  onClickAddTop,
  onClickAddBot,
  icon,
  onClickTopButton,
  customLabel,
  isESS = false,
  style,
  isTopEnabled = true,
  subText = '',
  disableButton,
  sx,
  sxSvg,
}: EmptyCardProps) => {
  const { palette } = useTheme();
  const location = useLocation();
  const { isTerminated } = useAppSelector(
    (state: RootState) => state.termination
  );
  const styleSvg = sxSvg || {
    '& svg': {
      '& path': {
        stroke: palette.gray[80],
        strokeWidth: 1.5,
      },
      width: '60px',
      height: '60px',
      mt: '2rem',
    },
  };

  return (
    <Box sx={sx}>
      <Header
        label={label}
        isButtonEnabled={!isESS && isTopEnabled}
        handleAction={() => {
          onClickAddTop?.();
          onClickTopButton?.();
        }}
        labelAdd={customLabel}
        sx={{ mt: '10px' }}
        disableActionButton={disableButton}
      />

      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        p=".5rem"
        //   bgcolor="darkviolet"
        flexDirection="column"
        gap=".5rem"
        sx={style}
      >
        <Box sx={styleSvg}>{icon}</Box>
        <Typography component="div" fontWeight="800">
          No data available
        </Typography>
        {subText !== '' ? (
          <Typography sx={{ color: palette.gray[80] }}>{subText}</Typography>
        ) : (
          <Typography
            component="div"
            sx={{
              color: palette.gray[80],
            }}
          >
            {' '}
            {isESS
              ? 'Please contact your HR admin to complete your data'
              : 'Add data to this page by clicking '}
            {!isESS && (
              <Typography component="span" fontWeight="800">
                Add {customLabel || removeInfoLabel(label)}
              </Typography>
            )}
          </Typography>
        )}

        {onClickAddBot !== undefined && (
          <ButtonAdd
            label={label}
            handleAction={onClickAddBot}
            disableButton={disableButton}
          />
        )}
      </Box>
    </Box>
  );
};

export default EmptyCard;
