const headerKey = {
  name: 'Name',
  code: 'Code',
  description: 'Description',
};
const detailKey = {
  effectiveDate: 'Effective Date',
  name: 'Name',
  code: 'Code',
  description: 'Description',
};
const defaultValueUpdate = {
  effectiveDate: null,
  name: null,
  code: null,
  description: null,
  totalEmployees: null,
};
const defaultValueCreate = {
  effectiveDate: null,
  name: null,
  code: null,
  description: null,
  totalEmployees: null,
};

export default {
  defaultValueUpdate,
  headerKey,
  detailKey,
  defaultValueCreate,
};
