import { PayrollRunStepSetupPayItemList } from '../../../@types/PayrollRun/StepSetup/StepSetup';
import {
  PayrollDetailsType,
  PayGroupDataList,
  PayGroupEmployeeListType,
} from '../../../@types/PayGroup';
import { QueryParams } from '../../../@types/QueryParams';
import { apiSlice } from '../api/apiSlice';
import { PayItemInfoResponse } from '../../../@types/PayItem';

interface TabQuery extends QueryParams {
  id: string;
}

export const PayGroupApiSlice = apiSlice.injectEndpoints({
  endpoints: builder => ({
    getPayGroupList: builder.query<PayGroupDataList, QueryParams>({
      query: params => {
        const { search, page, sortType, sort, searchString } = params;
        return {
          url: `/payroll/pay-groups`,
          params: { search, page, sortType, sort, ...searchString },
        };
      },
      providesTags: ['ListPayGroup'],
    }),
    getPayItemListInfo: builder.query<Array<PayItemInfoResponse>, any>({
      query: params => ({
        url: `/payroll/pay-items/list-info`,
      }),
    }),
    getPayGroupDetails: builder.query<any, string>({
      query: id => `/payroll/pay-groups/${id}`,
      providesTags: ['PayGroupDetail'],
    }),
    createPayGroup: builder.mutation<any, any>({
      query: ({ data }) => ({
        url: `/payroll/pay-groups`,
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['ListPayGroup'],
    }),
    updatePayGroup: builder.mutation<any, any>({
      query: ({ id, data }) => ({
        url: `/payroll/pay-groups/${id}`,
        method: 'PUT',
        body: data,
      }),
      invalidatesTags: ['PayGroupDetail'],
    }),
    deletePayGroup: builder.mutation<any, any>({
      query: id => ({
        url: `/payroll/pay-groups/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['ListPayGroup'],
    }),
  }),
});

export const {
  useGetPayItemListInfoQuery,
  useGetPayGroupListQuery,
  useGetPayGroupDetailsQuery,
  useCreatePayGroupMutation,
  useUpdatePayGroupMutation,
  useDeletePayGroupMutation,
} = PayGroupApiSlice;
