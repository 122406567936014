const CornerDownRight = ({ className }: { className: string }) => (
  <svg
    className={className}
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1.33325 1.33398V2.50065C1.33325 5.30091 1.33325 6.70104 1.87822 7.7706C2.35759 8.71141 3.12249 9.47632 4.0633 9.95568C5.13286 10.5007 6.53299 10.5007 9.33325 10.5007H14.6666M14.6666 10.5007L10.4999 6.33398M14.6666 10.5007L10.4999 14.6673"
      stroke="#8A98A8"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default CornerDownRight;
