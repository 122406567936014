import utc from 'dayjs/plugin/utc';
import dayjs from 'dayjs';
import isToday from 'dayjs/plugin/isToday'; // Impo
import isYesterday from 'dayjs/plugin/isYesterday'; // Impo

dayjs.extend(utc);
dayjs.extend(isToday);
dayjs.extend(isYesterday);

export default dayjs;
