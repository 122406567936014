import { apiSlice } from '../../api/apiSlice';

type TRespGenerateReport = {
  fileName: string;
  fileUrl: string;
  mimeType: string;
  name: string;
};

type TParamsFilter = {
  'filter.organization': string;
  'filter.jobPosition': string;
  'filter.jobGrade': string;
  'filter.employmentType': string;
  'filter.employmentStatus': string;
  'filter.workLocation': string;
} | null;

interface IPayloadReportOvertime {
  legalEntity: {
    label: string;
    value: string;
  };
  from: number;
  to: number;
  specificEmployees: null | any;
  organizations: null | any;
  jobPositions: null | any;
  jobGrades: null | any;
  employmentTypes: null | any;
  workLocations: null | any;
}

interface IResponseOvertime {
  fileName: string;
  fileUrl: string;
  mimeType: string;
  name: string;
}

export const standardReportApiSlice = apiSlice.injectEndpoints({
  endpoints: builder => ({
    postGenerate1721i: builder.mutation<any, any>({
      query: ({ payload }) => ({
        url: '/payroll/reports/1721-i-report/generate',
        method: 'POST',
        body: payload,
      }),
    }),
    postGeneratePayrollRegister: builder.mutation<
      TRespGenerateReport,
      { payload: string; payQueryId: string; paramsFilter: any }
    >({
      query: ({ payload, payQueryId, paramsFilter }) => ({
        url: `/payroll/report/payroll-register/${payQueryId}`,
        method: 'POST',
        body: payload,
        params: paramsFilter,
      }),
    }),
    postGenerateBPJSKesehatan: builder.mutation<any, any>({
      query: ({ payload }) => ({
        url: '/payroll/reports/bpjs-kesehatan/generate',
        method: 'POST',
        body: payload,
      }),
    }),
    postGenerateIncomeTaxCalculation: builder.mutation<any, any>({
      query: ({ payload }) => ({
        url: `/payroll/reports/income-tax-calculation`,
        method: 'POST',
        body: payload,
      }),
    }),
    getDataGenerate1721i: builder.query<any, any>({
      query: () => ({
        url: '/payroll/1721-i-report-configuration',
      }),
    }),
    generate1721a1Report: builder.mutation<any, any>({
      query: payload => ({
        url: '/payroll/reports/1721-a1-report/generate',
        method: 'POST',
        body: payload,
      }),
    }),
    generateBPJSKetenagakerjaan: builder.mutation<any, any>({
      query: payload => ({
        url: '/payroll/reports/bpjs-tk-baru/generate',
        method: 'POST',
        body: payload,
      }),
    }),
    generateBPJSKetenagakerjaanLanjutan: builder.mutation<any, any>({
      query: payload => ({
        url: '/payroll/reports/bpjs-tk-lanjutan/generate',
        method: 'POST',
        body: payload,
      }),
    }),
    generateCostCenterReport: builder.mutation<any, any>({
      query: payload => ({
        url: `/payroll/report/cost-center`,
        method: 'POST',
        body: payload,
      }),
    }),
    generateBupot2126: builder.mutation<any, any>({
      query: payload => ({
        url: '/payroll/reports/bupot/generate',
        method: 'POST',
        body: payload,
      }),
    }),
    generateOvertimeReport: builder.mutation<
      IResponseOvertime,
      IPayloadReportOvertime
    >({
      query: payload => ({
        url: '/time-management/reports/overtime/generate',
        method: 'POST',
        body: payload,
      }),
    }),
  }),
});

export const {
  useGetDataGenerate1721iQuery,
  usePostGenerate1721iMutation,
  usePostGeneratePayrollRegisterMutation,
  usePostGenerateBPJSKesehatanMutation,
  usePostGenerateIncomeTaxCalculationMutation,
  useGenerate1721a1ReportMutation,
  useGenerateBPJSKetenagakerjaanMutation,
  useGenerateBPJSKetenagakerjaanLanjutanMutation,
  useGenerateCostCenterReportMutation,
  useGenerateBupot2126Mutation,
  useGenerateOvertimeReportMutation,
} = standardReportApiSlice;
