import { createSlice } from '@reduxjs/toolkit';
import { payrollInfoApiSlice } from './service/employee/compensation/payrollInfo';
import { previousEmploymentApiSlice } from './service/employee/compensation/previousEmployment';

interface initialStateI {
  messageSuccess: string;
  messageError: string;
}

const initialState: initialStateI = {
  messageSuccess: '',
  messageError: '',
};

const compensationSlice = createSlice({
  name: 'compensation',
  initialState,
  reducers: {
    removeMessages: state => {
      state.messageSuccess = '';
      state.messageError = '';
    },
  },
  extraReducers(builder) {
    // PREVIOUS EMPLOYMENT
    builder.addMatcher(
      previousEmploymentApiSlice.endpoints.createPrevEmployment.matchFulfilled,
      (state, _action) => {
        state.messageSuccess = 'Previous Employment added';
      }
    );
    builder.addMatcher(
      previousEmploymentApiSlice.endpoints.updatePrevEmployment.matchFulfilled,
      (state, _action) => {
        if (_action.payload.typeOfChange === 'UPDATED') {
          state.messageSuccess = 'Previous Employment updated';
        } else if (_action.payload.typeOfChange === 'CORRECTED') {
          state.messageSuccess = 'Previous Employment corrected';
        }
      }
    );
    builder.addMatcher(
      previousEmploymentApiSlice.endpoints.cancelPrevEmployment.matchFulfilled,
      (state, _action) => {
        state.messageSuccess = 'Upcoming Changes cancelled';
      }
    );
    // PAYROLL INFO
    builder.addMatcher(
      payrollInfoApiSlice.endpoints.createPayrollInfo.matchFulfilled,
      (state, _action) => {
        state.messageSuccess = 'Payroll Info added';
      }
    );
    builder.addMatcher(
      payrollInfoApiSlice.endpoints.updatePayrollinfo.matchFulfilled,
      (state, _action) => {
        if (_action.payload.typeOfChange === 'UPDATED') {
          state.messageSuccess = 'Payroll Info updated';
        } else if (_action.payload.typeOfChange === 'CORRECTED') {
          state.messageSuccess = 'Payroll Info corrected';
        }
      }
    );
    builder.addMatcher(
      payrollInfoApiSlice.endpoints.cancelPayrollInfo.matchFulfilled,
      (state, _action) => {
        state.messageSuccess = 'Upcoming Changes cancelled';
      }
    );
  },
});

export const { removeMessages } = compensationSlice.actions;
export const compenstationReducer = compensationSlice.reducer;
