import { apiSlice } from '../../../api/apiSlice';
import { PayrollInfoDetail } from '../../../../../@types/PayrollInfo';

export const payrollInfoApiSlice = apiSlice.injectEndpoints({
  endpoints: builder => ({
    getDetailPayrollInfo: builder.query<PayrollInfoDetail, any>({
      query: args => {
        const { employeeId, ...query } = args;
        return {
          url: `/payroll/employees/${employeeId}/payroll-informations`,
          params: query,
        };
      },
      providesTags: ['payrollInfoDetail'],
    }),
    createPayrollInfo: builder.mutation<any, any>({
      query: ({ employeeId, body }) => ({
        url: `/payroll/employees/${employeeId}/payroll-informations`,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['payrollInfoDetail'],
    }),
    updatePayrollinfo: builder.mutation<any, any>({
      query: ({ employeeId, oldId, body }: any) => ({
        url: `/payroll/employees/${employeeId}/payroll-informations/${oldId}`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: ['payrollInfoDetail'],
    }),
    cancelPayrollInfo: builder.mutation<any, any>({
      query: ({ employeeId, newId }) => ({
        url: `/payroll/employees/${employeeId}/payroll-informations/${newId}/cancellation`,
        method: 'DELETE',
      }),
      invalidatesTags: ['payrollInfoDetail'],
    }),
    deletePayrollInfo: builder.mutation<any, any>({
      query: ({ employeeId, groupId }) => ({
        url: `/payroll/employees/${employeeId}/address/${groupId}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['payrollInfoDetail'],
    }),
  }),
});

export const {
  useGetDetailPayrollInfoQuery,
  useCreatePayrollInfoMutation,
  useUpdatePayrollinfoMutation,
  useCancelPayrollInfoMutation,
  useDeletePayrollInfoMutation,
} = payrollInfoApiSlice;
