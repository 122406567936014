import { apiSlice } from '../api/apiSlice';
import {
  TimeOffItemsResponse,
  TimeOffItem,
} from '../../../@types/TimeOffItemTypes';
import { QueryParams } from '../../../@types/QueryParams';

export const timeOffItemApiSlice = apiSlice.injectEndpoints({
  endpoints: builder => ({
    timeOffItems: builder.query<
      TimeOffItemsResponse,
      Omit<QueryParams, 'sort'> & { sortBy: string }
    >({
      query: params => {
        const { search, page, searchString, sortType, sortBy } = params;
        return {
          url: '/time-management/time-off-items',
          params: { search, page, sortBy, sortType, ...searchString },
        };
      },
      providesTags: ['timeOffItemList'],
    }),
    timeOffItemDetail: builder.query<TimeOffItem, { id: string }>({
      query: ({ id }) => ({
        url: `/time-management/time-off-items/${id}`,
      }),
      providesTags: ['timeOffItemDetail'],
    }),
    createTimeOffItem: builder.mutation<void, any>({
      query: payload => ({
        url: '/time-management/time-off-items',
        body: payload,
        method: 'POST',
      }),
      invalidatesTags: ['timeOffItemList'],
    }),
    deleteTimeOffItem: builder.mutation<void, { id: string }>({
      query: ({ id }) => ({
        url: `/time-management/time-off-items/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['timeOffItemList'],
    }),
  }),
});

export const {
  useTimeOffItemsQuery,
  useTimeOffItemDetailQuery,
  useCreateTimeOffItemMutation,
  useDeleteTimeOffItemMutation,
} = timeOffItemApiSlice;
