import React from 'react';
import { Breadcrumbs, Typography, Box, useTheme } from '@mui/material';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { BreadCrumbList } from '../../../constants/_breadcrumbList';
import { BreadcrumbProps } from '../../../@types';
import { ArrowLeft } from '../../atom/icon';

const Breadcrumb = (Props: BreadcrumbProps) => {
  const { onClickBack, titleBreadCrumb, ...props } = Props;
  const { palette } = useTheme();
  const location = useLocation();
  const navigate = useNavigate();
  const params = useParams<Record<string, string>>();
  const url = Object.values(params).reduce(
    (path, param) => path?.replace(`/${param}`, ''),
    location.pathname
  );
  const handleClickNavigation = () => {
    if (url) {
      if (BreadCrumbList[url || '']) {
        if (BreadCrumbList[url || ''].type === 'detail') {
          navigate(`${BreadCrumbList[url || ''].clickTo}/${params.id}`);
          return;
        }
        navigate(BreadCrumbList[url || ''].clickTo);
      }
      onClickBack?.();
    }
  };
  return (
    <Breadcrumbs
      aria-label="breadcrumb"
      separator="/"
      sx={{
        lineHeight: '20px',
        svg: {
          margin: 0,
        },
        display: 'flex',
        alignItems: 'center',
      }}
      {...props}
    >
      <Box
        onClick={handleClickNavigation}
        sx={{
          '&:hover': {
            cursor: 'pointer',
          },
          display: 'flex',
          alignItems: 'center',
          color: palette.primary.main,
        }}
      >
        <Box sx={{ mr: '0.5rem' }}>
          <ArrowLeft sx={{ fontSize: '1rem', strokeWidth: '2' }} />
        </Box>
        <Typography
          sx={{
            lineHeight: '1rem',
            fontWeight: 400,
            color: palette.primary.main,
          }}
          variant="body12"
          component="div"
        >
          Back to {BreadCrumbList[url || '']?.title}
        </Typography>
      </Box>
      <div role="presentation">
        <Typography
          sx={{
            lineHeight: '1rem',
            fontWeight: 600,
          }}
          color={palette.gray[90]}
          variant="body12"
          component="div"
        >
          {titleBreadCrumb || BreadCrumbList[url || '']?.subtitle}
        </Typography>
      </div>
    </Breadcrumbs>
  );
};

export default Breadcrumb;
