import { FC } from 'react';
import { SvgIconProps } from '@mui/material';
import { SvgIcon } from './SvgIcon';

const ChevronRight: FC<SvgIconProps> = props => (
  <SvgIcon
    style={{ minHeight: '24px', minWidth: '24px' }}
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M9 18L15 12L9 6" strokeLinecap="round" strokeLinejoin="round" />
  </SvgIcon>
);

export default ChevronRight;
