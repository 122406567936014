import { Box, Popover, useTheme } from '@mui/material';
import React from 'react';
import { Button } from '../../atom';
import { AlertCircle, DotsVertical, XCircle } from '../../atom/icon';

type ButtonPopoverPayrollRunProps = {
  data: any;
  actionDetail: Function;
  actionRemove: Function;
};

const ButtonPopoverPayrollRun = ({
  actionDetail,
  actionRemove,
  data,
}: ButtonPopoverPayrollRunProps) => {
  const { palette } = useTheme();
  const [ActionAnchorEl, setActionAnchorEl] =
    React.useState<HTMLButtonElement | null>(null);
  const handleActionClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setActionAnchorEl(event.currentTarget);
  };
  const handleActionClose = () => {
    setActionAnchorEl(null);
  };
  const openAction = Boolean(ActionAnchorEl);

  return (
    <>
      <Popover
        open={openAction}
        anchorEl={ActionAnchorEl}
        onClose={handleActionClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        sx={{
          marginTop: '4px',
          '& .MuiPaper-root': {
            borderRadius: '8px',
          },
        }}
      >
        <Box
          sx={{
            width: '220px',
            boxSizing: 'border-box',
            paddingY: '4px',
            '& .MuiButtonBase-root': {
              border: 'none',
              borderRadius: 0,
              justifyContent: 'flex-start',
              height: '44px',
              gap: '12px',
              paddingX: '16px',
              '& svg': {
                width: '20px',
                height: '20px',
                minWidth: '20px !important',
                minHeight: '20px',
                '& path': {
                  stroke: palette.gray[90],
                },
              },
            },
          }}
        >
          {/* {data?.severity?.level && (
            <Button
              onClick={() => {
                actionDetail?.();
                handleActionClose();
              }}
              fullWidth
              variant="outlined"
            >
              <AlertCircle /> Severity Detail
            </Button>
          )} */}
          <Button
            onClick={() => {
              actionRemove?.();
              handleActionClose();
            }}
            fullWidth
            variant="outlined"
          >
            <XCircle /> Remove Employee
          </Button>
        </Box>
      </Popover>
      <Button
        onClick={handleActionClick}
        variant="tertiary"
        sx={{
          width: '100%',
          padding: 0,
          minWidth: 0,
          minHeight: 0,
          height: 0,
          '& svg': {
            width: '20px',
            '& path': { stroke: palette.blue[50] },
          },
        }}
      >
        <DotsVertical />
      </Button>
    </>
  );
};

export default ButtonPopoverPayrollRun;
