import { createSlice } from '@reduxjs/toolkit';
import { exportApiSlice } from './service/exportApiSlice';

type InitialStateI = {
  messageSuccess: string;
  messageError: string;
};

const initialState: InitialStateI = {
  messageSuccess: '',
  messageError: '',
};

const exportSlice = createSlice({
  name: 'import',
  initialState,
  reducers: {
    removeMessage: state => {
      state.messageSuccess = '';
      state.messageError = '';
    },
  },
  extraReducers(builder) {
    builder.addMatcher(
      exportApiSlice.endpoints.downloadExport.matchFulfilled,
      (state, _action) => {
        state.messageSuccess = 'File exported';
      }
    );
    builder.addMatcher(
      exportApiSlice.endpoints.downloadExport.matchRejected,
      (state, _action) => {
        state.messageError = 'Internal server Error';
      }
    );
  },
});

export const { removeMessage } = exportSlice.actions;
export const exportReducer = exportSlice.reducer;
