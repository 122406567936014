import { apiSlice } from '../../api/apiSlice';

export const employeePersonalApiSlice = apiSlice.injectEndpoints({
  endpoints: builder => ({
    updatePersonalInfo: builder.mutation<any, any>({
      query: ({ id, employeeId, payload }) => ({
        url: `/employee/employees/${employeeId}/personal/${id}`,
        method: 'PUT',
        body: payload,
      }),
      invalidatesTags: ['employeePersonalDetail', 'EmployeeList'],
    }),
    getDetailPersonalInfo: builder.query({
      query: args => {
        const { id, ...query } = args;
        return {
          url: `/employee/employees/${id}/employment`,
          params: query,
        };
      },
      providesTags: ['employeePersonalDetail'],
    }),
    cancelPersonalInfo: builder.mutation<any, any>({
      query: ({ id, newId }) => ({
        url: `/employee/employees/${id}/personal/${newId}/cancellation`,
        method: 'DELETE',
      }),
      invalidatesTags: ['employeePersonalList', 'employeePersonalDetail'],
    }),
  }),
});

export const {
  useUpdatePersonalInfoMutation,
  useGetDetailPersonalInfoQuery,
  useCancelPersonalInfoMutation,
} = employeePersonalApiSlice;
