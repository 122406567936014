const headerKey = {
  name: 'Name',
  code: 'Code',
  parentName: 'Parent',
  unitName: 'Unit',
  statusName: 'Status',
};

const detailKey = {
  effectiveDate: 'Effective Date',
  name: 'Name',
  code: 'Code',
  parent: 'Parent',
  unit: 'Unit',
  statusName: 'Status',
};

const activeDetailKey = {
  effectiveDate: 'EffectiveDate',
  name: 'Name',
  code: 'Code',
  parent: 'Parent',
  unit: 'Unit',
  statusName: 'Status',
  totalEmployees: 'Total Employees',
};

const defaultValueForm = {
  name: null,
  code: null,
  parent: null,
  unit: null,
  effectiveDate: null,
};

const defaultValueFormUpdate = {
  name: null,
  code: null,
  parent: null,
  unit: null,
  effectiveDate: '',
  reason: null,
  isActive: false,
};

export default {
  headerKey,
  detailKey,
  defaultValueForm,
  defaultValueFormUpdate,
  activeDetailKey,
};
