const ArrowNarrowDown = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="arrow-narrow-down">
      <path
        id="Icon"
        d="M12 4V20M12 20L18 14M12 20L6 14"
        stroke="#039855"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
  </svg>
);

export default ArrowNarrowDown;
