import { createSlice } from '@reduxjs/toolkit';
import { employeeTerminationApiSlice } from './service/employee/termination';
import { employeeApiSlice } from './service/employeeApislice';

interface initialStateI {
  isTerminated: boolean;
}

const initialState: initialStateI = {
  isTerminated: false,
};

const terminationSlice = createSlice({
  name: 'taxLocations',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder.addMatcher(
      employeeApiSlice.endpoints.getDetailEmployee.matchFulfilled,
      (state, action) => {
        const termination = action?.payload?.employmentStatus?.value as any;
        if (termination === 'INACTIVE') {
          state.isTerminated = true;
        }
        if (termination !== 'INACTIVE') {
          state.isTerminated = false;
        }
      }
    );
  },
});

export const terminationReducer = terminationSlice.reducer;
