import { createSlice } from '@reduxjs/toolkit';
import { ValueRoundingApiSlice } from './service/valueRoundingSlice';

interface initialStateI {
  messageSuccess: string;
  messageError: string;
}

const initialState: initialStateI = {
  messageSuccess: '',
  messageError: '',
};

const valueRoundingSlice = createSlice({
  name: 'valueRounding',
  initialState,
  reducers: {
    removeMessage: state => {
      state.messageSuccess = '';
      state.messageError = '';
    },
  },
  extraReducers(builder) {
    builder.addMatcher(
      ValueRoundingApiSlice.endpoints.createValueRounding.matchFulfilled,
      (state, _action) => {
        state.messageSuccess = 'Value Rounding created';
      }
    );
    builder.addMatcher(
      ValueRoundingApiSlice.endpoints.updateValueRounding.matchFulfilled,
      (state, _action) => {
        state.messageSuccess = 'Value Rounding updated';
      }
    );
    builder.addMatcher(
      ValueRoundingApiSlice.endpoints.deleteValueRounding.matchFulfilled,
      (state, _action) => {
        state.messageSuccess = 'Value Rounding deleted';
      }
    );
    builder.addMatcher(
      ValueRoundingApiSlice.endpoints.deleteValueRounding.matchRejected,
      (state, _action) => {
        const payload = _action.payload as any;
        state.messageError = payload?.data?.message;
      }
    );
  },
});

export const { removeMessage } = valueRoundingSlice.actions;
export const valueRoundingReducer = valueRoundingSlice.reducer;
