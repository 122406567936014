import React from 'react';
import { Box, Popover, Typography, useTheme } from '@mui/material';
import { Button } from '../../atom';
import { ActionMenuProps } from '../../../@types/TableProps';
import { ChevronDown } from '../../atom/icon';

type ActionButtonProps = {
  actionPopoverDirection?: 'left' | 'right';
  actions?: Array<ActionMenuProps>;
  onClickMenuAction?: Function;
  disabled?: boolean;
  headerLabel?: string;
};

const ActionButton = ({
  actionPopoverDirection = 'left',
  actions,
  onClickMenuAction,
  disabled = false,
  headerLabel,
}: ActionButtonProps) => {
  const { palette } = useTheme();
  const [ActionAnchorEl, setActionAnchorEl] =
    React.useState<HTMLButtonElement | null>(null);

  const openAction = Boolean(ActionAnchorEl);

  const handleActionClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setActionAnchorEl(event.currentTarget);
  };
  const handleActionClose = () => {
    setActionAnchorEl(null);
  };
  return (
    <>
      <Popover
        open={openAction}
        anchorEl={ActionAnchorEl}
        onClose={handleActionClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: actionPopoverDirection === 'left' ? 'left' : 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: actionPopoverDirection === 'left' ? 'left' : 'right',
        }}
        sx={{
          marginTop: '4px',
          '& .MuiPaper-root': {
            borderRadius: '8px',
          },
        }}
      >
        <Box
          sx={{
            boxSizing: 'border-box',
            paddingY: '4px',
            minWidth: '220px',
            '& .MuiButtonBase-root': {
              border: 'none',
              borderRadius: 0,
              justifyContent: 'flex-start',
              height: '44px',
              gap: '12px',
              paddingX: '16px',
              fontWeight: 400,
              lineHeight: '20px',
              '& svg': {
                width: '20px',
                height: '20px',
                minWidth: '20px !important',
                minHeight: '20px',
              },
            },
          }}
        >
          {headerLabel && (
            <Box paddingLeft="18px">
              <Typography
                sx={{
                  fontSize: '12px',
                  fontWeight: 400,
                  color: '#475667',
                }}
              >
                {headerLabel}
              </Typography>
            </Box>
          )}
          {actions &&
            actions.map(action => {
              const {
                Icon,
                title,
                name,
                hasBorderTop,
                color,
                hasBorderBottom,
                isDisabled,
                rightIcon,
              } = action;
              return (
                <Button
                  key={name}
                  fullWidth
                  variant="outlined"
                  onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
                    onClickMenuAction?.(name, e);
                  }}
                  disabled={isDisabled}
                  sx={{
                    bgcolor: isDisabled ? palette.gray[20] : '',
                    cursor: isDisabled ? 'no-drop' : '',
                    borderTop: hasBorderTop
                      ? `1px solid ${palette.gray[50]} !important`
                      : 'none',
                    borderBottom: hasBorderBottom
                      ? `1px solid ${palette.gray[50]} !important`
                      : 'none',
                    '& svg': {
                      '& path': {
                        stroke: !isDisabled && color ? color : '',
                      },
                    },
                  }}
                >
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                      gap: '.75rem',
                      padding: '.2rem',
                    }}
                  >
                    {Icon}
                    <Typography
                      variant="body14"
                      color={!isDisabled && color ? color : palette.gray[90]}
                      marginRight="1rem"
                    >
                      {title}
                    </Typography>
                    {rightIcon}
                  </Box>
                </Button>
              );
            })}
        </Box>
      </Popover>
      <Button
        variant="outlined"
        onClick={handleActionClick}
        disabled={disabled}
      >
        Action <ChevronDown />
      </Button>
    </>
  );
};

export default ActionButton;
