import { Box, SxProps, TextField, Typography, useTheme } from '@mui/material';
import React from 'react';
import { mergeDeep } from '../../../utils/deepMerge';

import { InputProps } from '../../../@types';

const Textarea = React.forwardRef((Props: InputProps, ref: any) => {
  const { palette } = useTheme();
  const { prefix, prefixIcon, suffix, suffixIcon, sx, disabled, ...props } =
    Props;

  const style: SxProps = {
    width: '100%',
    '& .MuiOutlinedInput-root': {
      '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
        borderColor: palette.blue[50],
        borderWidth: '1px',
      },
      '&:hover fieldset': {
        borderColor: palette.blue[50],
      },
    },
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: palette.gray[50],
      borderRadius: '8px',
    },
  };

  return (
    <TextField
      ref={ref}
      sx={mergeDeep(style, sx) as SxProps}
      variant="outlined"
      disabled={disabled}
      {...props}
    />
  );
});

export default Textarea;
