import * as yup from 'yup';
import {
  validationEmailField,
  validationTextField,
} from './defaultValidations';

export default yup.object().shape({
  email: validationEmailField('Email', 'Your Email is invalid.', 8, 100),
  password: validationTextField('Password', 8, 128),
});

export const loginSSO = yup.object().shape({
  email: validationEmailField('Email', 'Your Email is invalid.', 8, 100),
});
