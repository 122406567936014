import { apiSlice } from '../../api/apiSlice';

export const employeeExperienceApiSlice = apiSlice.injectEndpoints({
  endpoints: builder => ({
    getListExperience: builder.query({
      query: args => {
        const { id, ...query } = args;
        return {
          url: `/employee/employees/${id}/experiences`,
          params: query,
        };
      },
      providesTags: ['EmployeeList'],
    }),
    getDetailExperience: builder.query({
      query: args => {
        const { id, groupId, ...query } = args;
        return {
          url: `/employee/employees/${id}/experiences/${groupId}`,
          params: query,
        };
      },
    }),
    createExperience: builder.mutation<any, any>({
      query: ({ id, payload }) => ({
        url: `/employee/employees/${id}/experiences`,
        method: 'POST',
        body: payload,
      }),
      invalidatesTags: ['EmployeeList'],
    }),
    updateExperience: builder.mutation<any, any>({
      query: ({ id, groupId, payload }) => ({
        url: `/employee/employees/${id}/experiences/${groupId}`,
        method: 'PUT',
        body: payload,
      }),
      invalidatesTags: ['EmployeeList'],
    }),
    cancelChangesExperience: builder.mutation<any, any>({
      query: ({ id, newId }) => ({
        url: `/employee/employees/${id}/experiences/${newId}/cancellation`,
        method: 'DELETE',
      }),
      invalidatesTags: ['EmployeeList'],
    }),
    deleteExperience: builder.mutation<any, any>({
      query: ({ id, groupId }) => ({
        url: `/employee/employees/${id}/experiences/${groupId}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['EmployeeList'],
    }),
  }),
});

export const {
  useCreateExperienceMutation,
  useGetListExperienceQuery,
  useGetDetailExperienceQuery,
  useUpdateExperienceMutation,
  useCancelChangesExperienceMutation,
  useDeleteExperienceMutation,
} = employeeExperienceApiSlice;
