import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { PayGroupApiSlice } from './service/payGroupApiSlice';
import {
  PayGroupInitialType,
  PayGroupPayItemTab,
  PayItemType,
  PayrollDetailsType,
} from '../../@types/PayGroup';
import { sortData } from '../../utils/dataDisplay';

const initialState: PayGroupInitialType = {
  payGroupCreateId: '',
  payGroupData: {},
  messageSuccess: '',
  messageError: '',
  filteredPayItems: [
    {
      id: 1,
      data: [],
    },
  ],
  payItems: [
    {
      id: 1,
      data: [],
    },
  ],
  updatedPayItem: 0,
};

const payGroupSlice = createSlice({
  name: 'payGroup',
  initialState,
  reducers: {
    removeMessage: state => {
      state.messageSuccess = '';
      state.messageError = '';
    },
    removePayGroupCreateId: state => {
      state.payGroupCreateId = '';
    },
    getPayItemList: (
      state,
      action: PayloadAction<{
        indexData: number;
        sortBy: string;
        sortType: string;
        search: string;
      }>
    ) => {
      const { search, sortBy, sortType, indexData } = action.payload;
      const filteredPayItems: Array<PayItemType> = [];
      state.payItems.forEach((e: PayItemType) => {
        filteredPayItems.push({
          ...e,
          data: e.data.slice(0),
        });
      });
      state.filteredPayItems = filteredPayItems;
      if (search) {
        state.filteredPayItems[indexData].data = state.filteredPayItems[
          indexData
        ].data.filter(
          payItem =>
            payItem.name?.toLowerCase().includes(search.toLowerCase()) ||
            payItem.code?.toLowerCase().includes(search.toLowerCase())
        );
      }
      state.filteredPayItems[indexData].data = sortData(
        state.filteredPayItems[indexData].data,
        sortBy,
        sortType
      );
    },
    addPayItemRow: state => {
      state.payItems.push({ id: 2, data: [] });
    },
    removePayItemRow: state => {
      state.payItems = state.payItems.slice(0, 1);
      state.filteredPayItems = state.payItems;
    },
    addPayItem: (
      state,
      action: PayloadAction<{
        indexData: number;
        data: Array<PayGroupPayItemTab>;
      }>
    ) => {
      const { indexData, data } = action.payload;
      const payItemData: Array<PayGroupPayItemTab> = [];
      let isError = false;
      for (let index = 0; index < data.length; index += 1) {
        const element = data[index];
        const indexPayItem = state.payItems[indexData].data.findIndex(
          item => item.id === element.id
        );
        if (indexPayItem > -1) {
          isError = true;
          break;
        }
        payItemData.push(element);
      }

      if (isError) {
        state.messageError = 'Error! Pay Item already exist!';
        return;
      }

      state.payItems[indexData].data =
        state.payItems[indexData].data.concat(payItemData);
      state.messageSuccess = 'Pay Item added';
      state.updatedPayItem = new Date().getTime();
    },
    deletePayItem: (
      state,
      action: PayloadAction<{ indexData: number; id: string }>
    ) => {
      const { id, indexData } = action.payload;
      state.payItems[indexData].data = state.payItems[indexData].data.filter(
        item => item.id !== id
      );
      state.messageSuccess = 'Pay Item removed';
      state.updatedPayItem = new Date().getTime();
    },
    setPayItem: (state, action) => {
      state.payItems = action.payload;
      state.filteredPayItems = action.payload;
    },
    resetPayItem: state => {
      const defaultValuePayItems = [
        {
          id: 1,
          data: [],
        },
      ];
      state.payItems = defaultValuePayItems;
      state.filteredPayItems = defaultValuePayItems;
    },
  },
  extraReducers(builder) {
    builder.addMatcher(
      PayGroupApiSlice.endpoints.createPayGroup.matchFulfilled,
      (state, _) => {
        state.messageSuccess = 'Pay Group created';
      }
    );
    builder.addMatcher(
      PayGroupApiSlice.endpoints.createPayGroup.matchRejected,
      (state, action) => {
        const messageError = action.payload as any;
        state.messageError = messageError.data?.message;
      }
    );
    builder.addMatcher(
      PayGroupApiSlice.endpoints.updatePayGroup.matchFulfilled,
      (state, _) => {
        state.messageSuccess = 'Pay Group updated';
      }
    );
    builder.addMatcher(
      PayGroupApiSlice.endpoints.updatePayGroup.matchRejected,
      (state, action) => {
        const messageError = action.payload as any;
        state.messageError = messageError.data?.message;
      }
    );
    builder.addMatcher(
      PayGroupApiSlice.endpoints.getPayGroupDetails.matchFulfilled,
      (state, action: PayloadAction<PayrollDetailsType>) => {
        state.filteredPayItems = action.payload.payItems;
        state.payItems = action.payload.payItems;
      }
    );
    builder.addMatcher(
      PayGroupApiSlice.endpoints.deletePayGroup.matchFulfilled,
      (state, _) => {
        state.messageSuccess = 'Pay Group deleted';
      }
    );
    builder.addMatcher(
      PayGroupApiSlice.endpoints.deletePayGroup.matchRejected,
      (state, action) => {
        const messageError = action.payload as any;
        state.messageError = messageError?.data?.message;
      }
    );
  },
});

export const {
  removeMessage,
  removePayGroupCreateId,
  addPayItem,
  deletePayItem,
  addPayItemRow,
  removePayItemRow,
  getPayItemList,
  resetPayItem,
} = payGroupSlice.actions;
export const payGroupReducer = payGroupSlice.reducer;
