import * as React from 'react';
import { useTheme, Box, Tab, Tabs, SxProps } from '@mui/material';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

interface IBasicTabs {
  tabs: {
    label: string;
    Icon?: React.ReactNode;
    component: () => React.ReactNode;
  }[];
  value: number;
  setValue: Function;
  sx?: SxProps;
}

export function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export default function VerticalTabsButton({
  tabs,
  value,
  setValue,
  sx,
}: IBasicTabs) {
  const { palette, typography } = useTheme();

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const renderTabComponent = (
    icon: any,
    e: any,
    index: any
  ): React.ReactChild => {
    if (icon) {
      return (
        <Tab
          icon={icon}
          iconPosition="start"
          key={e.label}
          label={e.label}
          sx={{
            padding: '8px 24px',
            minHeight: 0,
            display: 'flex',
            alignItems: 'center',
          }}
          {...a11yProps(index)}
        />
      );
    }
    return (
      <Tab
        key={e.label}
        sx={{ padding: '8px 24px', minHeight: 0 }}
        label={e.label}
        {...a11yProps(index)}
      />
    );
  };

  return (
    <Box sx={{ width: '100%', ...sx }}>
      <Box>
        <Tabs
          orientation="vertical"
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
          sx={{
            // height: '40px',
            minHeight: '0',
            boxSizing: 'border-box',
            gap: '8px',
            display: 'flex',
            '& .MuiTabs-fixed': {
              overflow: 'auto !important',
              '&::-webkit-scrollbar': {
                height: '0.1px',
              },
            },
            '& .MuiTabs-indicator': {
              border: 'none',
              height: '0',
            },
            '& .MuiButtonBase-root': {
              padding: '12px 16px',
              minWidth: 0,
              height: '44px',
              boxSizing: 'border-box',
              alignItems: 'flex-start',
            },
            '& .MuiTab-root.Mui-selected': {
              color: palette.blue[50],
              ...typography.subtitle16,
              fontWeight: '500',
              backgroundColor: palette.blue[20],
              borderRadius: '8px',
            },
            '& .MuiTab-root': {
              color: palette.gray[80],
              ...typography.subtitle16,
              fontWeight: '500',
              alignItems: 'center',
              justifyContent: 'flex-start',
            },
            '& .MuiTab-textColorPrimary': {
              ...typography.subtitle16,
              fontWeight: '500',
            },
            '& .MuiTab-root svg ': {
              marginRight: '8px',
              fontSize: '16px',
              width: '16px',
            },
            '& .MuiTab-root.Mui-selected svg path ': {
              stroke: palette.blue[50],
            },
            '& .MuiTab-root svg path': {
              stroke: palette.gray[80],
            },
            '& .MuiTabs-flexContainer': {
              gap: '8px',
            },
          }}
        >
          {tabs.map((e: any, index: number) =>
            renderTabComponent(e.icon, e, index)
          )}
        </Tabs>
      </Box>
    </Box>
  );
}
