import React from 'react';
import { RouteNode } from '../../@types';

const Create = React.lazy(() => import('../../pages/termination/Create'));
const Detail = React.lazy(() => import('../../pages/termination/Detail'));
const Update = React.lazy(() => import('../../pages/termination/Update'));

const Termination: RouteNode[] = [
  {
    label: '',
    path: '/employee/detail/:id/termination/create',
    component: Create,
    child: null,
  },
  {
    label: '',
    path: '/employee/detail/:id/termination/detail/:terminationId',
    component: Detail,
    child: null,
  },
  {
    label: '',
    path: '/employee/detail/:id/termination/update/:terminationId',
    component: Update,
    child: null,
  },
];

export default Termination;
