const UploadCloud01 = () => (
  <svg
    width="16"
    height="14"
    viewBox="0 0 16 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2.66665 9.82815C1.86266 9.28998 1.33331 8.37347 1.33331 7.33333C1.33331 5.77095 2.52765 4.48753 4.05314 4.34625C4.36519 2.44809 6.01348 1 7.99998 1C9.98648 1 11.6348 2.44809 11.9468 4.34625C13.4723 4.48753 14.6666 5.77095 14.6666 7.33333C14.6666 8.37347 14.1373 9.28998 13.3333 9.82815M5.33331 9.66667L7.99998 7M7.99998 7L10.6666 9.66667M7.99998 7V13"
      stroke="#045FC4"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default UploadCloud01;
