import React from 'react';

const SwitchDisable = () => (
  <svg
    style={{ minWidth: '24px' }}
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.3333 2.66666V13.3333M11.3333 13.3333L8.66667 10.6667M11.3333 13.3333L14 10.6667M4.66667 13.3333V2.66666M4.66667 2.66666L2 5.33333M4.66667 2.66666L7.33333 5.33333"
      stroke="#DCE0E5"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default SwitchDisable;
