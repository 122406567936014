import { apiSlice } from '../../../api/apiSlice';
import {
  PreviousEmploymentDetail,
  PreviousEmploymentMutation,
  PreviousEmploymentUpdate,
} from '../../../../../@types/prevEmployment';

interface QueryParams {
  employeeId?: string;
  body?: any;
  sort?: string;
  sortType?: string;
  effectiveDate?: any;
}

interface UpdateQueryParams {
  employeeId: string;
  sort?: any;
  oldId: string;
  body: any;
}

export const previousEmploymentApiSlice = apiSlice.injectEndpoints({
  endpoints: builder => ({
    getDetailPrevEmployment: builder.query<
      PreviousEmploymentDetail,
      QueryParams
    >({
      query: args => {
        const { employeeId, ...query } = args;
        return {
          url: `/payroll/employees/${employeeId}/previous-employments`,
          params: query,
        };
      },
      providesTags: ['prevEmploymentList', 'prevEmploymentDetail'],
    }),
    createPrevEmployment: builder.mutation<
      PreviousEmploymentMutation,
      QueryParams
    >({
      query: ({ employeeId, body }) => ({
        url: `/payroll/employees/${employeeId}/previous-employments`,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['prevEmploymentList'],
    }),
    updatePrevEmployment: builder.mutation<
      PreviousEmploymentUpdate,
      UpdateQueryParams
    >({
      query: ({ employeeId, oldId, body }: any) => ({
        url: `/payroll/employees/${employeeId}/previous-employments/${oldId}`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: ['prevEmploymentDetail'],
    }),
    cancelPrevEmployment: builder.mutation<any, any>({
      query: ({ employeeId, newId }) => ({
        url: `/payroll/employees/${employeeId}/previous-employments/${newId}/cancellation`,
        method: 'DELETE',
      }),
      invalidatesTags: ['prevEmploymentList', 'prevEmploymentDetail'],
    }),
  }),
});

export const {
  useGetDetailPrevEmploymentQuery,
  useCreatePrevEmploymentMutation,
  useUpdatePrevEmploymentMutation,
  useCancelPrevEmploymentMutation,
} = previousEmploymentApiSlice;
