import { Box, SxProps, useMediaQuery } from '@mui/material';
import React from 'react';

interface ContainerProps {
  children: React.ReactNode;
  sx?: SxProps;
}

const ContentContainer = ({ children, sx }: ContainerProps) => {
  const matches = useMediaQuery('(max-width:1023px)');
  return (
    <Box
      sx={{
        width: matches ? '100%' : 'calc(100vw - 256px)',
        height: 'calc(100vh - 56px)',
        padding: matches ? '24px' : '24px 64px',
        overflow: 'auto',
        boxSizing: 'border-box',
        display: 'flex',
        flexDirection: 'column',
        gap: '2rem',
        ...sx,
      }}
    >
      {children}
    </Box>
  );
};

export default ContentContainer;
