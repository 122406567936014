import React from 'react';
import { RouteNode } from '../../../@types';

const AccessControlList = React.lazy(
  () => import('../../../pages/accessControl/List')
);
const AccessControllCreate = React.lazy(
  () => import('../../../pages/accessControl/Create')
);
const AccessControlDetail = React.lazy(
  () => import('../../../pages/accessControl/Detail')
);
const AccessControlUpdate = React.lazy(
  () => import('../../../pages/accessControl/Update')
);

const AccessControlRoutes: RouteNode[] = [
  {
    label: 'Access Control',
    path: 'access-control',
    child: null,
    component: AccessControlList,
  },
  {
    label: '',
    path: 'access-control/create',
    child: null,
    component: AccessControllCreate,
  },
  {
    label: '',
    path: 'access-control/detail/:id',
    child: null,
    component: AccessControlDetail,
  },
  {
    label: '',
    path: 'access-control/update/:id',
    child: null,
    component: AccessControlUpdate,
  },
];

export default AccessControlRoutes;
