import { lazy } from 'react';

const List = lazy(() => import('../../../pages/attendanceProcess/List'));
const Create = lazy(() => import('../../../pages/attendanceProcess/Create'));
const Detail = lazy(() => import('../../../pages/attendanceProcess/Detail'));

export const attendanceProcess = [
  {
    label: 'Attendance Process',
    child: null,
    path: 'attendance-process',
    component: List,
  },
  {
    label: '',
    child: null,
    path: 'attendance-process/create',
    component: Create,
  },
  {
    label: '',
    child: null,
    path: 'attendance-process/detail/:id',
    component: Detail,
  },
];
