import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Divider, SxProps, Typography, useTheme } from '@mui/material';
import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useAppDispatch, useAppSelector } from '../../../store';
import { loginSSO } from '../../../validations/login';
import { useValidateEmailSSOMutation } from '../../../store/feature/service/authApiSlice';
import { removeMessage } from '../../../store/feature/authSlice';
import { mappingError } from '../../../utils/errorMapping';
import LogoLogin from '../../atom/icon/LoginLogo';
import InputWithLabel from '../../molecule/form/InputWithLabel/InputWithLabel';
import { Button } from '../../atom';
import Alert from '../../atom/alert/Alert';
import { Mail01 } from '../../atom/icon';

type LoginWithSSOProps = {
  setIsLoginWithEmail: Dispatch<SetStateAction<boolean>>;
};

const LoginWithSSO = ({ setIsLoginWithEmail }: LoginWithSSOProps) => {
  const { palette } = useTheme();
  const dispatch = useAppDispatch();
  const { messageError } = useAppSelector(state => state.auth);

  const { control, setError, watch, handleSubmit } = useForm<any>({
    resolver: yupResolver(loginSSO),
    reValidateMode: 'onChange',
    defaultValues: {
      email: '',
    },
  });

  const [validateEmailSSO, { isLoading }] = useValidateEmailSSOMutation();

  const submit = async (e: any) => {
    try {
      dispatch(removeMessage());
      await validateEmailSSO(e).unwrap();
    } catch (error) {
      const err = error as any;
      mappingError(err.data.errors, setError, 'login');
    }
  };

  const styles: SxProps = {
    width: '100vw',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    paddingTop: '80px',
    gap: '32px',
    '& .caption-container': {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      gap: '8px',
      width: '400px',
    },
    '& .input-container': {
      width: '400px',
      display: 'flex',
      flexDirection: 'column',
      gap: '20px',
      '& .button-submit': {
        width: '100%',
        marginTop: '4px',
      },
      '& .MuiFormControl-root': {
        '& .MuiButtonBase-root': {
          border: 'none',
          '&:hover': { background: 'none' },
        },
      },
      '& .input-form .MuiFormLabel-root': {
        marginBottom: '0.5rem',
      },
      '& .input-form .input--error .MuiInputBase-root fieldset': {
        borderColor: `${palette.red[50]} !important`,
        '&:focused': {
          borderColor: `${palette.red[50]} !important`,
        },
      },
    },
  };

  return (
    <Box sx={styles}>
      <LogoLogin />
      <Box className="caption-container">
        <Typography
          color={palette.gray[100]}
          variant="h5"
          fontWeight={700}
          component="h5"
        >
          Log In with SSO
        </Typography>
        <Typography
          color={palette.gray[80]}
          variant="body14"
          textAlign="center"
          component="p"
        >
          Your organization uses Single Sign-On (SSO) with TricorUnify. Please
          log in using your SSO credentials
        </Typography>
      </Box>
      <form onSubmit={handleSubmit(submit)}>
        <Box className="input-container">
          <InputWithLabel
            label="Email"
            name="email"
            control={control}
            placeholder="Enter your email"
          />
          {messageError && <Alert variant="warning" message={messageError} />}
          <Button
            disabled={!watch('email') || isLoading}
            variant="primary"
            type="submit"
            className="button-submit"
          >
            Continue
          </Button>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Divider sx={{ flex: 1 }} />
            <Box
              sx={{
                flex: 0.4,
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              <Typography color={palette.gray[100]} variant="body14">
                or
              </Typography>
            </Box>
            <Divider sx={{ flex: 1 }} />
          </Box>
          <Button
            variant="outlined"
            type="button"
            onClick={() => {
              setIsLoginWithEmail(true);
            }}
          >
            <Mail01 /> Login In with Email
          </Button>
        </Box>
      </form>
    </Box>
  );
};

export default LoginWithSSO;
