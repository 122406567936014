import { apiSlice } from '../api/apiSlice';
import { NewsDetailType, NewsStats } from '../../../@types/NewsType';

export const newsApiSlice = apiSlice.injectEndpoints({
  endpoints: builder => ({
    getNewsDetail: builder.query<NewsDetailType, any>({
      query: args => {
        const { id, ...query } = args;
        return {
          url: `/organization/news/${id}`,
          params: query,
        };
      },
      providesTags: ['newsDetail'],
    }),
    createNews: builder.mutation<any, any>({
      query: ({ id, body }) => ({
        url: `/organization/news`,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['newsList'],
    }),
    getNewsStats: builder.query<NewsStats, any>({
      query: args => {
        const { id } = args;
        return {
          url: `/organization/news/${id}/stats`,
        };
      },
      providesTags: ['newsDetail'],
    }),
    updateNews: builder.mutation<any, any>({
      query: ({ id, body }) => ({
        url: `/organization/news/${id}`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: ['newsDetail'],
    }),
    cancelUpcomingNews: builder.mutation<any, any>({
      query: ({ id, body }) => ({
        url: `/organization/news/${id}/cancellation`,
        method: 'DELETE',
        body,
      }),
      invalidatesTags: ['newsDetail'],
    }),
  }),
});

export const {
  useGetNewsDetailQuery,
  useCreateNewsMutation,
  useGetNewsStatsQuery,
  useUpdateNewsMutation,
  useCancelUpcomingNewsMutation,
} = newsApiSlice;
