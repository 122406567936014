import { apiSlice } from '../../api/apiSlice';

export const employeeEmploymentApiSlice = apiSlice.injectEndpoints({
  endpoints: builder => ({
    updateEmployment: builder.mutation<any, any>({
      query: ({ id, payload, oldId }) => ({
        url: `/employee/employees/${id}/employment/${oldId}`,
        method: 'PUT',
        body: payload,
      }),
      invalidatesTags: ['employeeEmployment', 'EmployeeList'],
    }),
    getDetailEmployment: builder.query({
      query: args => {
        const { id, ...query } = args;
        return {
          url: `/employee/employees/${id}/employment`,
          params: query,
        };
      },
      providesTags: ['employeeEmployment'],
    }),
    cancelChangesEmployment: builder.mutation<any, any>({
      query: ({ id, employeeEmploymentId }) => ({
        url: `/employee/employees/${id}/employment/${employeeEmploymentId}/cancellation`,
        method: 'DELETE',
      }),
      invalidatesTags: ['employeeEmployment'],
    }),
  }),
});

export const { useUpdateEmploymentMutation, useGetDetailEmploymentQuery } =
  employeeEmploymentApiSlice;
