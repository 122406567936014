import { apiSlice } from '../api/apiSlice';
import { ScheduleList } from '../../../@types/ScheduleTypes';
import { QueryParams } from '../../../@types/QueryParams';

export const scheduleApiSlice = apiSlice.injectEndpoints({
  endpoints: builder => ({
    getListSchedule: builder.query<
      ScheduleList,
      Omit<QueryParams, 'sort'> & { sortBy: string }
    >({
      query: params => {
        const { search, page, sortType, sortBy, limit, searchString } = params;
        return {
          url: '/time-management/schedules',
          params: { search, page, sortType, sortBy, limit, ...searchString },
        };
      },
      providesTags: ['listSchedule'],
    }),
    scheduleDetails: builder.query<any, any>({
      query: ({ id, effectiveDate }) => ({
        url: `/time-management/schedules/${id}/details`,
        params: { effectiveDate },
      }),
      providesTags: ['detailSchedule'],
    }),
    scheduleShiftAssignment: builder.query<any, any>({
      query: params => {
        const {
          search,
          page,
          sortType,
          sortBy,
          limit,
          searchString,
          id,
          effectiveDate,
        } = params;
        return {
          url: `/time-management/schedules/${id}/details/shift-assignment`,
          params: {
            search,
            page,
            sortType,
            sortBy,
            limit,
            ...searchString,
            effectiveDate,
          },
        };
      },
      providesTags: ['listShiftAssignment'],
    }),
    createSchedule: builder.mutation<any, any>({
      query: payload => ({
        url: '/time-management/schedules',
        method: 'POST',
        body: payload,
      }),
      invalidatesTags: ['listSchedule'],
    }),
    deleteSchedule: builder.mutation<void, { id: string }>({
      query: ({ id }) => ({
        url: `/time-management/schedules/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['listSchedule'],
    }),
    updateSchedule: builder.mutation<any, any>({
      query: ({ id, payload }) => ({
        url: `/time-management/schedules/${id}`,
        method: 'PUT',
        body: payload,
      }),
      invalidatesTags: [
        'listSchedule',
        'detailSchedule',
        'listShiftAssignment',
      ],
    }),
    getScheduleInfo: builder.query<any, any>({
      query: id => ({
        url: `/time-management/schedules/${id}/info`,
      }),
    }),
    getScheduleCalendar: builder.mutation<any, any>({
      query: params => {
        const { startDate, endDate, scheduleStartDayId, id, payload } = params;
        return {
          url: `/time-management/schedules/${id}/calendar`,
          params: { startDate, endDate, scheduleStartDayId },
          method: 'POST',
          body: payload,
        };
      },
    }),
  }),
});

export const {
  useGetListScheduleQuery,
  useScheduleDetailsQuery,
  useScheduleShiftAssignmentQuery,
  useCreateScheduleMutation,
  useDeleteScheduleMutation,
  useUpdateScheduleMutation,
  useGetScheduleInfoQuery,
  useGetScheduleCalendarMutation,
} = scheduleApiSlice;
