const BlockLayoutLogo = () => (
  <svg
    width="174"
    height="32"
    viewBox="0 0 174 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M42 9.61366V6.87257H56.3522V9.61366H50.7922V24.9232H47.5599V9.61366H42Z"
      fill="white"
    />
    <path
      d="M57.5775 24.9232V11.3852H60.6605V13.6416H60.801C61.0469 12.8601 61.4685 12.2578 62.0658 11.8347C62.6689 11.4058 63.357 11.1913 64.1299 11.1913C64.3056 11.1913 64.5018 11.2001 64.7184 11.2178C64.9409 11.2295 65.1254 11.2501 65.2718 11.2795V14.2145C65.1371 14.1675 64.9234 14.1263 64.6306 14.0911C64.3437 14.0499 64.0655 14.0294 63.7962 14.0294C63.2164 14.0294 62.6953 14.1557 62.2327 14.4084C61.776 14.6552 61.4158 14.9989 61.1523 15.4396C60.8888 15.8803 60.7571 16.3885 60.7571 16.9644V24.9232H57.5775Z"
      fill="white"
    />
    <path
      d="M67.3842 24.9232V11.3852H70.5638V24.9232H67.3842ZM68.9828 9.46383C68.4792 9.46383 68.0459 9.29636 67.6828 8.96144C67.3198 8.62064 67.1383 8.21227 67.1383 7.73632C67.1383 7.2545 67.3198 6.84613 67.6828 6.5112C68.0459 6.1704 68.4792 6 68.9828 6C69.4922 6 69.9255 6.1704 70.2827 6.5112C70.6458 6.84613 70.8273 7.2545 70.8273 7.73632C70.8273 8.21227 70.6458 8.62064 70.2827 8.96144C69.9255 9.29636 69.4922 9.46383 68.9828 9.46383Z"
      fill="white"
    />
    <path
      d="M79.7184 25.1877C78.3716 25.1877 77.2151 24.8909 76.2489 24.2975C75.2886 23.704 74.5478 22.8843 74.0267 21.8384C73.5114 20.7866 73.2537 19.5762 73.2537 18.2071C73.2537 16.8322 73.5172 15.6188 74.0443 14.567C74.5713 13.5094 75.3149 12.6867 76.2752 12.0992C77.2414 11.5057 78.3833 11.209 79.7008 11.209C80.7958 11.209 81.7649 11.4117 82.6081 11.8171C83.4572 12.2167 84.1335 12.7837 84.6371 13.5182C85.1407 14.2468 85.4276 15.0988 85.4979 16.0742H82.4588C82.3358 15.422 82.0431 14.8784 81.5805 14.4436C81.1237 14.0029 80.5118 13.7826 79.7447 13.7826C79.0947 13.7826 78.5238 13.9589 78.0319 14.3114C77.5401 14.6581 77.1565 15.1575 76.8813 15.8098C76.6119 16.462 76.4773 17.2435 76.4773 18.1542C76.4773 19.0768 76.6119 19.87 76.8813 20.534C77.1507 21.1921 77.5284 21.7003 78.0144 22.0588C78.5062 22.4113 79.083 22.5876 79.7447 22.5876C80.2132 22.5876 80.6318 22.4995 81.0008 22.3232C81.3755 22.141 81.6888 21.8795 81.9406 21.5387C82.1924 21.1979 82.3651 20.7837 82.4588 20.296H85.4979C85.4218 21.2538 85.1407 22.1028 84.6547 22.8432C84.1686 23.5777 83.507 24.1535 82.6696 24.5707C81.8322 24.982 80.8485 25.1877 79.7184 25.1877Z"
      fill="white"
    />
    <path
      d="M93.9937 25.1877C92.6762 25.1877 91.5343 24.8968 90.5681 24.3151C89.602 23.7334 88.8524 22.9196 88.3196 21.8737C87.7926 20.8278 87.5291 19.6056 87.5291 18.2071C87.5291 16.8087 87.7926 15.5835 88.3196 14.5318C88.8524 13.48 89.602 12.6632 90.5681 12.0815C91.5343 11.4998 92.6762 11.209 93.9937 11.209C95.3112 11.209 96.453 11.4998 97.4192 12.0815C98.3854 12.6632 99.132 13.48 99.659 14.5318C100.192 15.5835 100.458 16.8087 100.458 18.2071C100.458 19.6056 100.192 20.8278 99.659 21.8737C99.132 22.9196 98.3854 23.7334 97.4192 24.3151C96.453 24.8968 95.3112 25.1877 93.9937 25.1877ZM94.0112 22.6317C94.7256 22.6317 95.3229 22.4348 95.8031 22.0411C96.2832 21.6416 96.6404 21.1069 96.8747 20.437C97.1147 19.7672 97.2348 19.0209 97.2348 18.1983C97.2348 17.3698 97.1147 16.6206 96.8747 15.9508C96.6404 15.2751 96.2832 14.7374 95.8031 14.3379C95.3229 13.9383 94.7256 13.7385 94.0112 13.7385C93.2793 13.7385 92.6703 13.9383 92.1843 14.3379C91.7041 14.7374 91.344 15.2751 91.1039 15.9508C90.8697 16.6206 90.7526 17.3698 90.7526 18.1983C90.7526 19.0209 90.8697 19.7672 91.1039 20.437C91.344 21.1069 91.7041 21.6416 92.1843 22.0411C92.6703 22.4348 93.2793 22.6317 94.0112 22.6317Z"
      fill="white"
    />
    <path
      d="M103.157 24.9232V11.3852H106.24V13.6416H106.381C106.626 12.8601 107.048 12.2578 107.645 11.8347C108.249 11.4058 108.937 11.1913 109.709 11.1913C109.885 11.1913 110.081 11.2001 110.298 11.2178C110.52 11.2295 110.705 11.2501 110.851 11.2795V14.2145C110.717 14.1675 110.503 14.1263 110.21 14.0911C109.923 14.0499 109.645 14.0294 109.376 14.0294C108.796 14.0294 108.275 14.1557 107.812 14.4084C107.356 14.6552 106.995 14.9989 106.732 15.4396C106.468 15.8803 106.337 16.3885 106.337 16.9644V24.9232H103.157Z"
      fill="white"
    />
    <path
      d="M124.444 6.87257H127.702V18.6654C127.702 19.9581 127.398 21.0951 126.789 22.0764C126.186 23.0577 125.337 23.8245 124.242 24.3768C123.147 24.9232 121.867 25.1965 120.403 25.1965C118.934 25.1965 117.651 24.9232 116.556 24.3768C115.461 23.8245 114.612 23.0577 114.009 22.0764C113.406 21.0951 113.104 19.9581 113.104 18.6654V6.87257H116.363V18.3922C116.363 19.1443 116.527 19.8142 116.855 20.4018C117.189 20.9894 117.657 21.4506 118.26 21.7855C118.863 22.1146 119.578 22.2791 120.403 22.2791C121.229 22.2791 121.943 22.1146 122.547 21.7855C123.155 21.4506 123.624 20.9894 123.952 20.4018C124.28 19.8142 124.444 19.1443 124.444 18.3922V6.87257Z"
      fill="white"
    />
    <path
      d="M134.283 16.9908V24.9232H131.104V11.3852H134.143V13.6856H134.301C134.611 12.9277 135.106 12.3254 135.785 11.8788C136.47 11.4322 137.317 11.209 138.324 11.209C139.255 11.209 140.066 11.4087 140.757 11.8083C141.454 12.2079 141.992 12.7866 142.373 13.5446C142.759 14.3026 142.95 15.2222 142.944 16.3033V24.9232H139.764V16.7969C139.764 15.892 139.53 15.184 139.062 14.6728C138.599 14.1616 137.958 13.906 137.138 13.906C136.582 13.906 136.087 14.0294 135.654 14.2762C135.226 14.5171 134.889 14.8667 134.644 15.325C134.403 15.7833 134.283 16.3386 134.283 16.9908Z"
      fill="white"
    />
    <path
      d="M146.176 24.9232V11.3852H149.356V24.9232H146.176ZM147.775 9.46383C147.271 9.46383 146.838 9.29636 146.475 8.96144C146.112 8.62064 145.93 8.21227 145.93 7.73632C145.93 7.2545 146.112 6.84613 146.475 6.5112C146.838 6.1704 147.271 6 147.775 6C148.284 6 148.718 6.1704 149.075 6.5112C149.438 6.84613 149.619 7.2545 149.619 7.73632C149.619 8.21227 149.438 8.62064 149.075 8.96144C148.718 9.29636 148.284 9.46383 147.775 9.46383Z"
      fill="white"
    />
    <path
      d="M159.459 11.3852V13.8531H151.484V11.3852H159.459ZM153.477 24.9232V10.1072C153.477 9.19647 153.665 8.43849 154.04 7.83327C154.42 7.22806 154.93 6.77562 155.568 6.47595C156.206 6.17628 156.915 6.02644 157.694 6.02644C158.244 6.02644 158.733 6.07051 159.16 6.15865C159.588 6.24679 159.904 6.32611 160.109 6.39662L159.477 8.86449C159.342 8.82336 159.172 8.78222 158.967 8.74109C158.762 8.69409 158.534 8.67058 158.282 8.67058C157.691 8.67058 157.272 8.81454 157.026 9.10246C156.786 9.3845 156.666 9.78994 156.666 10.3188V24.9232H153.477Z"
      fill="white"
    />
    <path
      d="M163.925 30C163.492 30 163.091 29.9647 162.722 29.8942C162.359 29.8296 162.069 29.7532 161.852 29.6651L162.59 27.1796C163.053 27.3147 163.466 27.3794 163.829 27.3735C164.192 27.3676 164.511 27.253 164.786 27.0297C165.067 26.8123 165.304 26.448 165.498 25.9368L165.77 25.2053L160.878 11.3852H164.25L167.36 21.6093H167.5L170.618 11.3852H174L168.598 26.5626C168.346 27.2795 168.013 27.8935 167.597 28.4047C167.181 28.9218 166.672 29.3155 166.069 29.5858C165.471 29.8619 164.757 30 163.925 30Z"
      fill="white"
    />
    <path
      d="M16.1133 24.2056C17.7177 22.898 18.9211 21.1811 19.5903 19.245C19.5903 19.245 20.0275 18.1879 19.9612 15.7017V6.70179C19.9612 5.34648 20.2628 3.89373 21.0287 2.77465C21.855 1.63441 23.01 0.761545 24.3459 0.26777C25.2066 -0.0261314 26.1339 -0.0793033 27.0238 0.114227C28.2251 0.374358 29.3244 0.967914 30.1902 1.82386C31.2843 2.94203 31.9272 4.41077 31.9995 5.9577L31.8759 15.1673C31.8035 16.7263 31.7191 18.4153 31.6859 18.8553C31.5683 20.3789 31.3512 20.559 30.9984 21.1436C30.9984 21.1436 29.189 23.869 26.6197 25.1623C26.6197 25.1623 24.3911 26.5176 19.9371 25.9241C18.569 25.5766 17.2744 24.9948 16.1133 24.2056Z"
      fill="url(#paint0_linear_30429_7142)"
    />
    <path
      d="M2.68488 16.2525C2.28209 16.4559 1.8394 16.5552 1.38732 16.5734C0.945941 16.5912 0.505866 16.5167 0.0960083 16.3548L0.100424 16.8002C0.105791 17.6033 0.188586 18.4041 0.347705 19.1919C0.474361 19.8238 0.628156 20.497 0.757828 20.9636C1.20715 22.5551 2.22945 24.2116 3.75233 26.2165C3.75233 26.2165 7.63946 31.2184 14.4155 31.933C14.4155 31.933 21.2731 32.8188 26.3996 28.1535C26.3996 28.1535 30.7662 24.873 31.4779 20.1988C31.4779 20.1988 29.0925 23.7805 26.7042 24.873C26.7042 24.873 22.4823 27.3592 16.9547 24.5246C14.9783 23.2726 13.4794 21.4167 12.6906 19.2451C12.6906 19.2451 11.8734 17.2313 12.0483 12.7284C12.0604 12.4615 12.0563 12.1947 12.0523 11.9322C12.0503 11.8027 12.0483 11.6742 12.0483 11.5473L12.037 10.5842C9.96005 12.1745 6.95868 14.114 2.68488 16.2525Z"
      fill="url(#paint1_linear_30429_7142)"
    />
    <path
      d="M2.91297 13.059C3.21266 13.0471 3.50612 12.9718 3.77313 12.8383C7.67802 10.9042 9.98051 9.22828 11.2847 8.04855C11.5055 7.8514 11.6799 7.60997 11.7961 7.3408C11.9123 7.07164 11.9675 6.78113 11.9579 6.48919C11.9483 6.19724 11.8741 5.91078 11.7405 5.64945C11.6068 5.38812 11.4169 5.15812 11.1836 4.9752C7.91657 2.38284 5.10588 0.924643 3.24937 0.165163C2.96107 0.0439375 2.64835 -0.011821 2.33477 0.00208647C2.02119 0.015994 1.71494 0.0992033 1.43908 0.24545C1.16322 0.391696 0.924951 0.597159 0.74223 0.846365C0.559509 1.09557 0.437096 1.38202 0.38422 1.68412C-0.424762 6.41062 0.194941 9.71941 0.856128 11.6637C0.949168 11.9421 1.10294 12.1975 1.30685 12.4123C1.51075 12.627 1.75993 12.7961 2.03721 12.9077C2.31449 13.0194 2.61328 13.071 2.91297 13.059Z"
      fill="url(#paint2_linear_30429_7142)"
    />
    <defs>
      <linearGradient
        id="paint0_linear_30429_7142"
        x1="23.9388"
        y1="1.37209"
        x2="24.1441"
        y2="27.5954"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.2" stopColor="white" />
        <stop offset="0.37" stopColor="white" />
        <stop offset="0.48" stopColor="#FAFAFA" />
        <stop offset="0.57" stopColor="#F0F0F0" />
        <stop offset="0.66" stopColor="#E3E3E3" />
        <stop offset="0.74" stopColor="#D4D4D4" />
        <stop offset="0.81" stopColor="#BFBFBF" />
        <stop offset="0.88" stopColor="#ABABAB" />
        <stop offset="0.95" stopColor="#8F8F8F" />
        <stop offset="1" stopColor="#8A8A8A" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_30429_7142"
        x1="3.20363"
        y1="11.1887"
        x2="27.5431"
        y2="30.7971"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.51" stopColor="white" />
        <stop offset="0.69" stopColor="#FDFDFD" />
        <stop offset="0.76" stopColor="#F6F6F6" />
        <stop offset="0.81" stopColor="#EBEBEB" />
        <stop offset="0.84" stopColor="#DADADA" />
        <stop offset="0.88" stopColor="#C4C4C4" />
        <stop offset="0.9" stopColor="#A8A8A8" />
        <stop offset="0.93" stopColor="#888888" />
        <stop offset="0.95" stopColor="#626262" />
        <stop offset="0.98" stopColor="#373737" />
        <stop offset="1" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_30429_7142"
        x1="3.20363"
        y1="11.1887"
        x2="27.5431"
        y2="30.7971"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.51" stopColor="white" />
        <stop offset="0.69" stopColor="#FDFDFD" />
        <stop offset="0.76" stopColor="#F6F6F6" />
        <stop offset="0.81" stopColor="#EBEBEB" />
        <stop offset="0.84" stopColor="#DADADA" />
        <stop offset="0.88" stopColor="#C4C4C4" />
        <stop offset="0.9" stopColor="#A8A8A8" />
        <stop offset="0.93" stopColor="#888888" />
        <stop offset="0.95" stopColor="#626262" />
        <stop offset="0.98" stopColor="#373737" />
        <stop offset="1" />
      </linearGradient>
    </defs>
  </svg>
);

export default BlockLayoutLogo;
