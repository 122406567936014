import { createSlice } from '@reduxjs/toolkit';
import { shiftApiSlice } from './service/shiftApiSlice';

interface initialStateI {
  messageSuccess: string;
  messageError: string;
}

const initialState: initialStateI = {
  messageSuccess: '',
  messageError: '',
};

const shiftSlice = createSlice({
  name: 'shift',
  initialState,
  reducers: {
    removeMessage: state => {
      state.messageSuccess = '';
      state.messageError = '';
    },
  },
  extraReducers(builder) {
    builder.addMatcher(
      shiftApiSlice.endpoints.createShift.matchFulfilled,
      (state, _action) => {
        state.messageSuccess = 'Shift created';
      }
    );
    builder.addMatcher(
      shiftApiSlice.endpoints.createShift.matchRejected,
      (state, action) => {
        const payload = action.payload as any;
        state.messageError = payload?.data?.message || 'Internal Server Error';
      }
    );
    builder.addMatcher(
      shiftApiSlice.endpoints.updateShift.matchFulfilled,
      (state, action) => {
        if (action?.payload?.typeOfChange === 'UPDATED') {
          state.messageSuccess = 'Shift updated';
        } else if (action?.payload?.typeOfChange === 'CORRECTED') {
          state.messageSuccess = 'Shift corrected';
        }
      }
    );
    builder.addMatcher(
      shiftApiSlice.endpoints.updateShift.matchRejected,
      (state, action) => {
        const payload = action.payload as any;
        state.messageError = payload?.data?.message || 'Internal Server Error';
      }
    );
    builder.addMatcher(
      shiftApiSlice.endpoints.deleteShift.matchFulfilled,
      (state, _action) => {
        state.messageSuccess = 'Shift deleted';
      }
    );
    builder.addMatcher(
      shiftApiSlice.endpoints.deleteShift.matchRejected,
      (state, action) => {
        const payload = action.payload as any;
        state.messageError = payload?.data?.message || 'Internal Server Error';
      }
    );
  },
});

export const { removeMessage } = shiftSlice.actions;
export const shiftReducer = shiftSlice.reducer;
