import { createSlice } from '@reduxjs/toolkit';
import { overtimeItemApiSlice } from './service/overtimeItemApiSlice';
import { CriteriaModalForm } from '../../@types/OvertimeTypes';

interface initialStateI {
  messageSuccess: string;
  messageError: string;
  rowData: Array<CriteriaModalForm>;
  updatedData: number;
}

const initialState: initialStateI = {
  messageSuccess: '',
  messageError: '',
  rowData: [],
  updatedData: 0,
};

const overtimeitemSlice = createSlice({
  name: 'overtime',
  initialState,
  reducers: {
    removeMessage: state => {
      state.messageSuccess = '';
      state.messageError = '';
    },
  },
  extraReducers(builder) {
    builder.addMatcher(
      overtimeItemApiSlice.endpoints.createOvertimeItem.matchFulfilled,
      (state, _action) => {
        state.messageSuccess = 'Overtime item created';
      }
    );
  },
});

export const { removeMessage } = overtimeitemSlice.actions;
export const overtimeItemReducer = overtimeitemSlice.reducer;
