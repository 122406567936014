import { apiSlice } from '../../api/apiSlice';

export const employeeRehireApiSlice = apiSlice.injectEndpoints({
  endpoints: builder => ({
    createRehire: builder.mutation<any, any>({
      query: ({ employeeId, payload }) => ({
        url: `/employee/employees/${employeeId}/rehirement`,
        method: 'POST',
        body: payload,
      }),
      invalidatesTags: [
        'employeeProfiles',
        'employeeRehireDetail',
        'EmployeeList',
      ],
    }),
    detailRehire: builder.query<any, any>({
      query: args => {
        const { employeeId, ...query } = args;
        return {
          url: `/employee/employees/${employeeId}/rehirement`,
          params: query,
        };
      },
      providesTags: ['employeeRehireDetail'],
    }),
    cancelUpcomingRehire: builder.mutation<any, any>({
      query: ({ employeeId, rehireId }) => ({
        url: `/employee/employees/${employeeId}/rehirement/${rehireId}/cancellation`,
        method: 'DELETE',
      }),
      invalidatesTags: ['employeeRehireDetail', 'employeeProfiles'],
    }),
    getTerminationDetail: builder.query<any, any>({
      query: ({ employeeId }) => ({
        url: `/employee/employees/${employeeId}/terminate`,
      }),
      providesTags: ['EmployeeTerminationDetail'],
    }),
  }),
});

export const {
  useCreateRehireMutation,
  useDetailRehireQuery,
  useCancelUpcomingRehireMutation,
  useGetTerminationDetailQuery,
} = employeeRehireApiSlice;
