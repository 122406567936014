import { Avatar, AvatarProps, SxProps } from '@mui/material';

const ESSAvatarProfile = (props: AvatarProps) => {
  const style: SxProps = {
    width: '96px',
    height: '96px',
    border: '4px solid white',
    filter:
      'drop-shadow(0px 1px 3px rgba(16, 27, 40, 0.1)) drop-shadow(0px 1px 2px rgba(16, 27, 40, 0.06))',
  };
  return <Avatar sx={style} {...props} />;
};

export default ESSAvatarProfile;
