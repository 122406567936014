import { apiSlice } from '../api/apiSlice';
import {
  AttendanceStatusDetails,
  AttendanceStatusForm,
  AttendanceStatusInfoTypes,
  AttendanceStatusList,
} from '../../../@types/AttendanceStatusTypes';

type QueryParams = {
  page: number;
  limit: number;
  sortBy: string;
  sortType: string;
  search: string;
  searchString: { [key: string]: any };
  id?: string | number;
};

export const attendanceStatusApiSlice = apiSlice.injectEndpoints({
  endpoints: builder => ({
    attendanceStatusList: builder.query<AttendanceStatusList, QueryParams>({
      query: params => {
        const { search, page, sortType, sortBy, searchString } = params;
        return {
          url: '/time-management/attendances',
          params: { search, page, sortType, sortBy, ...searchString },
        };
      },
      providesTags: ['attendanceStatusList'],
    }),
    attendanceStatusDetail: builder.query<AttendanceStatusDetails, any>({
      query: ({ id }) => ({
        url: `/time-management/attendances/${id}`,
      }),
      providesTags: ['attendanceStatusDetails'],
    }),
    createAttendanceStatus: builder.mutation<void, AttendanceStatusForm>({
      query: payload => ({
        url: '/time-management/attendances',
        method: 'POST',
        body: payload,
      }),
      invalidatesTags: ['attendanceStatusList'],
    }),
    updateAttendanceStatus: builder.mutation<
      void,
      { id: string; payload: AttendanceStatusForm }
    >({
      query: ({ id, payload }) => ({
        url: `/time-management/attendances/${id}`,
        method: 'PUT',
        body: payload,
      }),
      invalidatesTags: ['attendanceStatusList', 'attendanceStatusDetails'],
    }),
    deleteAttendanceStatus: builder.mutation<void, { id: string }>({
      query: ({ id }) => ({
        url: `/time-management/attendances/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['attendanceStatusList'],
    }),
    attendanceStatusInfo: builder.query<
      AttendanceStatusInfoTypes,
      { id: string }
    >({
      query: ({ id }) => ({
        url: `/time-management/attendances/${id}/info`,
      }),
    }),
  }),
});

export const {
  useAttendanceStatusListQuery,
  useAttendanceStatusDetailQuery,
  useCreateAttendanceStatusMutation,
  useUpdateAttendanceStatusMutation,
  useDeleteAttendanceStatusMutation,
  useAttendanceStatusInfoQuery,
} = attendanceStatusApiSlice;
