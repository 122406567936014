import { apiSlice } from '../api/apiSlice';

type ResetPasswordData = {
  newPassword: string;
  token: string;
};

export const ResetPasswordApiSlice = apiSlice.injectEndpoints({
  endpoints: builder => ({
    resetPassword: builder.mutation<void, ResetPasswordData>({
      query: credentials => ({
        url: '/identity/authenticate/reset-password',
        method: 'POST',
        body: credentials,
      }),
    }),
  }),
});

export const { useResetPasswordMutation } = ResetPasswordApiSlice;
