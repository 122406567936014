import React from 'react';
import { useTheme, Checkbox, CheckboxProps, SxProps } from '@mui/material';
import {
  CheckboxIcon,
  CheckboxIconDisabled,
  CheckboxUncheck,
  CheckboxUncheckDisable,
} from '../icon';
import { mergeDeep } from '../../../utils/deepMerge';

const SingleCheckbox = React.forwardRef((Props: CheckboxProps, ref: any) => {
  const { sx, disabled, ...props } = Props;
  const { palette } = useTheme();
  const style: SxProps = {
    '&.MuiCheckbox-root.MuiButtonBase-root': {
      paddingY: 0,
    },
  };

  return (
    <Checkbox
      ref={ref}
      sx={mergeDeep(style, sx) as SxProps}
      checkedIcon={disabled ? <CheckboxIconDisabled /> : <CheckboxIcon />}
      icon={disabled ? <CheckboxUncheckDisable /> : <CheckboxUncheck />}
      disabled={disabled}
      disableRipple
      {...props}
    />
  );
});

export default SingleCheckbox;
