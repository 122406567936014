import dayjs from './dayjs';

export const formatDate = (date: string | Date) =>
  dayjs(date).format('DD MMM YYYY');

export const formatDateNewFormat = (date: string | Date) =>
  dayjs(date).format('YYYY-MM-DD');

export const formatDateToMonthDay = (date: any) =>
  date ? dayjs.unix(date).local().format('MM-DD') : null;

export const formatDateWithFullMonth = (date: string) =>
  dayjs(date).format('DD MMMM YYYY');

export const formatDateUnixToFullMonth = (date: number | null) =>
  date ? dayjs.unix(date).local().format('DD MMMM YYYY') : null;

export const formatDateToPost = (date: string | null) =>
  date ? dayjs(date).format('YYYY-MM-DD') : null;

export const formatDateToPostUnix = (date: string | null | Date | number) =>
  date ? dayjs(date).utc().valueOf() / 1000 : null;

export const formatDateTimeTimeOff = (date: number | null) =>
  date ? dayjs.unix(date).local().format('YYYY-MM-DD') : '';

export const formatDateTimeFromUnixUTC = (date: number | null) =>
  date ? dayjs.unix(date).local().format('DD MMM YYYY') : '';

export const newformatDateTimeFromUnixUTC = (date: number | null) =>
  date ? dayjs.unix(date).local().format('YYYY-MM-DD') : '';

export const formatDateTimeFromUnixUTCWOYear = (date: number | null) =>
  date ? dayjs.unix(date).local().format('DD MMM') : '';

export const formatDateTimeFromUTC = (date: string | null) =>
  date ? dayjs.utc(date).local().format('DD MMM YYYY, HH:mm') : '';

export const formatUnixToDateWithHours = (date: number | null) =>
  date ? dayjs.unix(date).local().format('YYYY-MM-DD hh:mm A') : '';

export const formatDateMonthYear = (date: Date) =>
  dayjs(date).format('MMM YYYY');

export const formatUnixTimestampToFullDateTime = (date: number | null) =>
  date ? dayjs.unix(date).local().format('YYYY-MM-DD, hh:mm A [UTC+7]') : '';

export const isValidDate = (value: string, format: string = 'YYYY-MM-DD') =>
  dayjs(value, format).format(format) === value;

export const formatUnixToNumberYearMonthDay = (date: number | null) =>
  date ? dayjs.unix(date).local().format('YYYY-MM-DD') : '';

export const formatUnixToDayYearMonthDate = (date: number | null) =>
  date ? dayjs.unix(date).local().format('dddd, YYYY-MM-DD') : '';

export const formatUnixTo12HourFormat = (date: number | null) =>
  date ? dayjs.unix(date).format('h:mm A') : '';

export const formatUnixToString = (date: number | null) => {
  if (!date) return '';
  const formattedDate = dayjs.unix(date).local();
  if (formattedDate.isToday()) {
    return 'Today';
  }
  if (formattedDate.isYesterday()) {
    return 'Yesterday';
  }
  return formattedDate.format('YYYY-MM-DD');
};

export const formatDecimalToHours = (hoursDecimal: number): string => {
  // Calculate whole hours and remaining minutes
  const hours = Math.floor(hoursDecimal);
  const minutes = Math.round((hoursDecimal - hours) * 60);

  // Format the result as 'Xh Ym'
  const absHours = Math.abs(hours);
  return minutes > 0 ? `${absHours}h ${minutes}m` : `${absHours}h`;
};

export const formatUnixToDateTime = (date: number | null) =>
  date ? dayjs.unix(date).local().format('YYYY-MM-DD hh:mm A') : '';

export const calculateDateDiff = (date: string) => {
  const years = dayjs(new Date()).diff(date, 'years');
  const months = dayjs(new Date()).diff(date, 'month') - 12 * years;
  return `${years} years ${months} months`;
};

export const calculateSecondDifference = (date1: string, date2: string) => {
  const date1Hour = dayjs(date1).get('hour');
  const date2Hour = dayjs(date2).get('hour');
  let hourDiff = date2Hour - date1Hour;

  const date1Minute = dayjs(date1).get('minute');
  const date2Minute = dayjs(date2).get('minute');
  const minuteDiff = date2Minute - date1Minute;
  let minuteToSecond = 0;

  // Adjust minute difference and decrement hour difference if minuteDiff is negative
  if (minuteDiff < 0) {
    minuteToSecond = (minuteDiff + 60) * 60;
    hourDiff -= 1; // Decrement hourDiff to account for borrowed hour
  } else {
    minuteToSecond = minuteDiff * 60;
  }

  // Adjust hour difference if it's negative
  if (hourDiff < 0) {
    hourDiff += 24;
  }

  return hourDiff * 3600 + minuteToSecond;
};

export const formatDateToSeconds = (date: Date) => {
  const dayJsObj = dayjs(date);
  const secondsInDay = dayJsObj.diff(dayJsObj.startOf('day'), 'second');
  return secondsInDay;
};

export const calculateAgeSinceBirth = (tanggal: any) => {
  const currentDate = Date.now() / 1000;
  // const yearsAge = Math.floor((currentDate - tanggal) / 60 / 60 / 24 / 30 / 12);
  const date1 = dayjs.unix(tanggal);
  const date2 = dayjs.unix(currentDate);
  const yearsAge = date2.diff(date1, 'year');
  return `${yearsAge} years old`;
};

export const calculateSinceJoin = (tanggal: any) => {
  const currentDate = Date.now() / 1000;
  const date1 = dayjs.unix(tanggal);
  const date2 = dayjs.unix(currentDate);
  const yearsAge = date2.diff(date1, 'year');
  return `${getOrdinalNumber(yearsAge)} anniversary`;
};

export const calculateActiveIn = (date: any) => {
  const currentDate = Date.now() / 1000;
  const date1 = dayjs.unix(currentDate);
  const date2 = dayjs.unix(date);
  const remaining = date2.diff(date1, 'days');
  return `Active in ${remaining} Day(s)`;
};

const getOrdinalNumber = (number: any) => {
  if (number === 0) return '0th';
  if (number % 100 >= 11 && number % 100 <= 13) {
    return `${number}th`;
  }
  switch (number % 10) {
    case 1:
      return `${number}st`;
    case 2:
      return `${number}nd`;
    case 3:
      return `${number}rd`;
    default:
      return `${number}th`;
  }
};

export const calculateEndDate = (
  duration: number,
  type: string,
  startDate: string | number | Date
) => {
  let result;
  switch (type) {
    case 'DAY':
      result = dayjs(new Date(startDate)).add(duration, 'd');
      break;
    case 'WEEK':
      result = dayjs(new Date(startDate)).add(duration, 'w');
      break;
    case 'MONTH':
      result = dayjs(new Date(startDate)).add(duration, 'month');
      break;
    case 'YEAR':
      result = dayjs(new Date(startDate)).add(duration, 'y');
      break;
    default:
      result = dayjs(new Date(startDate));
  }
  return result.format('DD/MM/YYYY');
};

export const formatBirthToGen = (date: number) => {
  const birthDate = new Date(date * 1000);
  const birthYear = birthDate.getUTCFullYear();

  if (birthYear <= 1945) {
    return 'Silent Gen';
  }
  if (birthYear >= 1946 && birthYear <= 1964) {
    return 'Baby Boomers';
  }
  if (birthYear >= 1965 && birthYear <= 1980) {
    return 'Gen X';
  }
  if (birthYear >= 1981 && birthYear <= 1996) {
    return 'Millennials';
  }
  if (birthYear >= 1997 && birthYear <= 2012) {
    return 'Gen Z';
  }
  return 'Gen Alpha';
};

export const getCurrentTime = (timezone: number) => {
  const currentDate = new Date();

  // Get the UTC hours and add the timezone offset
  let hours = currentDate.getUTCHours() + timezone;
  const minutes = currentDate.getUTCMinutes();
  const ampm = hours >= 12 ? 'PM' : 'AM';

  // Adjust hours to be within 0-23 range
  if (hours >= 24) {
    hours -= 24;
  } else if (hours < 0) {
    hours += 24;
  }

  // Convert to 12-hour format
  hours %= 12;
  hours = hours || 12; // Handle midnight (0 hours)

  // Add leading zeros to minutes if necessary
  const newMinutes = minutes < 10 ? `0${minutes}` : minutes;

  // Format the time as HH:MM AM/PM
  const currentTime = `${hours}:${newMinutes} ${ampm}`;

  return currentTime;
};

export const getTimezone = () => {
  const currentDate = new Date();
  // Get local timezone offset in minutes and convert it to hours
  const timezoneOffsetInMinutes = currentDate.getTimezoneOffset();
  const timezoneOffsetInHours = -timezoneOffsetInMinutes / 60;
  return timezoneOffsetInHours;
};

export const getCurrentDateYearMonth = () => {
  const date = dayjs().format('YYYY-MM-DD');
  const dateData = date.split('-');
  return {
    date: dateData[2],
    month: dateData[1],
    year: dateData[0],
  };
};

export const formatMonthToNumber = (month: string) => {
  const date = dayjs(`01 ${month} 2023`);
  return date.format('MM');
};

export const isDateToday = (epochTime: number): boolean => {
  // Convert the epoch time to a Date object
  const date = new Date(epochTime * 1000); // Convert seconds to milliseconds

  // Get the current date
  const today = new Date();

  // Compare the year, month, and day of the two dates
  return (
    date.getDate() === today.getDate() && date.getMonth() === today.getMonth()
  );
};

export const formatSeconds = (seconds: number) => {
  const time = dayjs().startOf('day').add(seconds, 'seconds');
  return time.format('HH:mm:ss');
};

/* eslint no-plusplus: "off" */
export const generateThreeYears = () => {
  const currentYear = new Date().getFullYear();
  const years = [];

  for (let i = 0; i < 3; i++) {
    const year = currentYear + i;
    years.push({ label: `${year}`, value: `${year}` });
  }

  return years;
};

export const generatePrevTwoYears = () => {
  const currentYear = new Date().getFullYear();
  const years = [];

  for (let i = 0; i < 3; i++) {
    const year = currentYear - i;
    years.push({ label: `${year}`, value: `${year}` });
  }

  return years;
};

export const formatSecondsToHour = (seconds: number) => {
  if (seconds === 0) return '00:00 AM';
  if (!seconds) return '-';
  const formattedTime = dayjs()
    .startOf('day')
    .add(seconds, 'second')
    .format('hh:mm A');
  return formattedTime;
};

export const formatSecondsToTimeString = (seconds: number): string => {
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);

  const hoursString = `${hours} Hour(s)`;
  const minutesString = `${minutes} Minute(s)`;

  const formattedTime = `${hoursString} ${minutesString}`.trim();
  return formattedTime;
};

/**
 * Converts an epoch time to a string formatted as "hh:mm AM/PM"
 * @param epochTime - The epoch time to convert
 * @returns The formatted time string in "hh:mm AM/PM" format
 */
export const epochToHHMM = (epochTime: number): string => {
  // Create a new Date object using the epoch time
  const date = new Date(epochTime * 1000);

  // Calculate the time in UTC+7
  const offset = 7 * 60 * 60 * 1000; // UTC+7 offset in milliseconds
  const dateUTC7 = new Date(date.getTime() + offset);

  // Extract the hours and minutes
  let hours = dateUTC7.getUTCHours();
  const minutes = dateUTC7.getUTCMinutes();

  // Determine AM or PM
  const ampm = hours >= 12 ? 'PM' : 'AM';

  // Convert hours from 24-hour to 12-hour format
  // eslint-disable-next-line operator-assignment
  hours = hours % 12;
  // eslint-disable-next-line no-unneeded-ternary
  hours = hours ? hours : 12; // the hour '0' should be '12'

  // Format hours and minutes to ensure two digits
  const formattedHours = hours.toString().padStart(2, '0');
  const formattedMinutes = minutes.toString().padStart(2, '0');

  // Return the formatted time string
  return `${formattedHours}:${formattedMinutes} ${ampm}`;
};

export const formatDateFromString = (dateString: string, year: number) => {
  /* eslint radix: "off" */
  const [month, day] = dateString.split('-');
  const monthNames = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];
  const monthName = monthNames[parseInt(month) - 1];
  const formattedDate = `${day} ${monthName} ${year}`;

  return formattedDate;
};

export const getDate = () => dayjs().startOf('day').toDate();

export const getDateUnix = () => dayjs().startOf('day').unix();

export const getEpochDateWithoutHourSecond = (Datum: any) => {
  const event = new Date(Datum);
  console.log(Math.floor(event.setHours(0, 0, 0, 0) / 1000));
  return Math.floor(event.setHours(0, 0, 0, 0) / 1000);
};
