const headerKey = {
  name: 'Name',
  code: 'Code',
  countryName: 'Country',
};

const locations = {
  list: '/organization/legal-entity',
  detail: '/organization/legal-entity/detail',
  create: '/organization/legal-entity/create',
};

const legalEntityFormDefaultValues = {
  id: '',
  effectiveDate: null,
  profileUrl: '',
  businessIndustry: null,
  name: '',
  companyName: '',
  companyCode: '',
  businessIdNumber: '',
  mobileNumber: '',
  countryCodeNumber: null,
  email: '',
  baseCurrency: null,
  calendar: null,
  country: null,
  address: null,
  state: null,
  city: null,
  district: null,
  subDistrict: null,
  neighborhood: '',
  hamletNumber: '',
  postalCode: '',
};

const formSection = {
  legalEntity: 'legalEntity',
  address: 'address',
};

const TABS = {
  GENERAL: 0,
  STATUTORY: 1,
  BANK: 2,
};

const detailHeaderLegalEntityInfo = {
  businessIndustry: 'Business Industry',
  name: 'Name',
  companyName: 'Company Name',
  companyCode: 'Company Code',
  businessIdNumber: 'Business ID No.',
  mobileNumber: 'Phone No.',
  email: 'Email',
  baseCurrency: 'Base Currency',
  calendar: 'Calendar',
};

const detailHeaderLegalEntityAddress = {
  country: 'Country of Operation',
  address: 'Address',
  state: 'State',
  city: 'City',
  district: 'District',
  subDistrict: 'Sub-district',
  neighborhood: 'RT/Neighborhood',
  hamletNumber: 'RW/Hamlet Number',
  postalCode: 'Postal Code',
};

const legalEntityLabels: any = {
  businessIndustry: 'Business Industry',
  name: 'Name',
  companyName: 'Company Name',
  companyCode: 'Company Code',
  businessIdNumber: 'Business ID No.',
  mobileNumber: 'Phone No.',
  email: 'Email',
  baseCurrency: 'Base Currency',
  calendar: 'Calendar',
  country: 'Country of Operation',
  address: 'Address',
  state: 'State',
  city: 'City',
  district: 'District',
  subDistrict: 'Sub-district',
  neighborhood: 'RT/Neighborhood',
  hamletNumber: 'RW/Hamlet Number',
  postalCode: 'Postal Code',
};

const detailGeneralInfo = {
  effectiveDate: 'EffectiveDate',
};

const defaultValueBankForm = {
  country: null,
  bank: null,
  bankBranchName: '',
  bankBranchCode: '',
  bankBranchAddress: '',
  accountName: '',
  accountNo: '',
  accountCode: '',
  description: '',
  effectiveDate: '',
};

const bankSectionHeaderKey = {
  effectiveDate: 'Effective Date',
  country: 'Country',
  bank: 'Bank Name',
  bankBranchName: 'Branch Name',
  bankCode: 'Bank Code',
  swiftCode: 'Swift Code',
  bankBranchCode: 'Branch Code',
  bankBranchAddress: 'Branch Address',
  accountName: 'Account Name',
  accountNo: 'Account No.',
  accountCode: 'Account Code',
  description: 'Description',
};

const statutorySectionHeaderKey = {
  HEALTH_CARE: {
    effectiveDate: 'Effective Date',
    country: 'Country',
    statutoryType: 'Name',
    // programs: 'Program',
    // riskLevel: 'Risk Level',
    accountNumber: 'Account No.',
    office: 'Office',
    signatoryName: 'Signatory Name',
    signatoryIdentificationNumber: 'Signatory ID No.',
    signatoryEmail: 'Signatory Email',
    signatoryMobileNumber: 'Signatory Mobile No.',
    signatoryPosition: 'Signatory Position',
  },
  TAX: {
    effectiveDate: 'Effective Date',
    country: 'Country',
    statutoryType: 'Name',
    // programs: 'Program',
    // riskLevel: 'Risk Level',
    accountNumber: 'Account No.',
    office: 'Office',
    signatoryName: 'Signatory Name',
    signatoryIdentificationNumber: 'Signatory ID No.',
    signatoryEmail: 'Signatory Email',
    signatoryMobileNumber: 'Signatory Mobile No.',
    signatoryPosition: 'Signatory Position',
  },
  SOCIAL_SECURITY: {
    effectiveDate: 'Effective Date',
    country: 'Country',
    statutoryType: 'Name',
    programs: 'Program',
    riskLevel: 'Risk Level',
    accountNumber: 'Account No.',
    office: 'Office',
    signatoryName: 'Signatory Name',
    signatoryIdentificationNumber: 'Signatory ID No.',
    signatoryEmail: 'Signatory Email',
    signatoryMobileNumber: 'Signatory Mobile No.',
    signatoryPosition: 'Signatory Position',
  },
};

const statutoryDefaultValue = {
  effectiveDate: '',
  country: null,
  statutoryType: null,
  programs: null,
  accountNumber: '',
  office: null,
  signatoryName: '',
  signatoryIdentificationNumber: '',
  signatoryEmail: '',
  signatoryMobileNumber: '',
  signatoryCountryCodeNumber: null,
  signatoryPosition: '',
  riskLevel: null,
};
const statutoryHeaderKey: { [key: string]: {} } = {
  TAX: {
    effectiveDate: 'Effective Date',
    country: 'Country',
    statutoryType: 'Name',
    accountNumber: 'Account No.',
    office: 'Office',
    signatoryName: 'Signatory Name',
    signatoryIdentificationNumber: 'Signatory ID No.',
    signatoryEmail: 'Signatory Email',
    signatoryMobileNumber: 'Signatory Mobile No.',
    signatoryPosition: 'Signatory Position',
  },
  HEALTH_CARE: {
    effectiveDate: 'Effective Date',
    country: 'Country',
    statutoryType: 'Name',
    accountNumber: 'Account No.',
    office: 'Office',
    signatoryName: 'Signatory Name',
    signatoryIdentificationNumber: 'Signatory ID No.',
    signatoryEmail: 'Signatory Email',
    signatoryMobileNumber: 'Signatory Mobile No.',
    signatoryPosition: 'Signatory Position',
  },
  SOCIAL_SECURITY: {
    effectiveDate: 'Effective Date',
    country: 'Country',
    statutoryType: 'Name',
    riskLevel: 'Risk Level',
    programs: 'Program',
    accountNumber: 'Account No.',
    office: 'Office',
    signatoryName: 'Signatory Name',
    signatoryIdentificationNumber: 'Signatory ID No.',
    signatoryEmail: 'Signatory Email',
    signatoryMobileNumber: 'Signatory Mobile No.',
    signatoryPosition: 'Signatory Position',
  },
};

export default {
  headerKey,
  locations,
  legalEntityFormDefaultValues,
  formSection,
  TABS,
  detailHeaderLegalEntityInfo,
  detailHeaderLegalEntityAddress,
  legalEntityLabels,
  detailGeneralInfo,
  defaultValueBankForm,
  bankSectionHeaderKey,
  statutorySectionHeaderKey,
  statutoryDefaultValue,
  statutoryHeaderKey,
};
