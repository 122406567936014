import { lazy } from 'react';

const List = lazy(() => import('../../../pages/overtime/List'));
const Create = lazy(() => import('../../../pages/overtime/Create'));
const Details = lazy(() => import('../../../pages/overtime/Details'));
const Assign = lazy(() => import('../../../pages/overtime/Assign'));

export const overtime = [
  {
    label: 'Overtime',
    child: null,
    path: 'overtime',
    component: List,
  },
  {
    label: '',
    child: null,
    path: 'overtime/create',
    component: Create,
  },
  {
    label: '',
    child: null,
    path: 'overtime/detail/:id',
    component: Details,
  },
  {
    label: '',
    child: null,
    path: 'overtime/assign/:sessionId',
    component: Assign,
  },
];
