import React, { useState } from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
  useTheme,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

interface Iprops {
  elevation: number;
  title: string;
  children: React.ReactNode;
}
const Accordions = ({ elevation, title, children }: Iprops) => {
  const [isExpanded, setIsExpanded] = useState(true);

  const handleChange = () => {
    setIsExpanded(!isExpanded);
  };

  const { palette } = useTheme();

  return (
    <Accordion
      elevation={elevation}
      expanded={isExpanded}
      onChange={handleChange}
      sx={{
        borderRadius: '4px',
        border: isExpanded ? 'none' : `1px solid ${palette.gray[60]}`,
      }}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1bh-content"
        id="panel1bh-header"
        sx={
          isExpanded
            ? {
                border: `1px solid ${palette.gray[60]}`,
                mb: '1rem',
                display: 'flex',
                alignContent: 'center',
              }
            : {}
        }
      >
        <Typography>Title</Typography>
      </AccordionSummary>
      <AccordionDetails>{children}</AccordionDetails>
    </Accordion>
  );
};

export default Accordions;
