import security from './security';
import { CustomFields } from './CustomFields';
import workFlow from './workflows';
import UserInterfaceRoute from './userInterface/index';

export default [
  ...CustomFields,
  ...UserInterfaceRoute,
  ...security,
  // ...workFlow
];
