import {
  EmployeeCompoensationProps,
  EmployeeCompoensationEarningProps,
  EmployeeCompoensationDeductionsProps,
  EmployeeCompensationBanksProps,
  EmployeeCompensationPayrollHistory,
} from '../../../@types/EmployeeCompensation';
import { ListResponse } from '../../../@types/ListResponseProps';
import { apiSlice } from '../api/apiSlice';

export const employeeApiSlice = apiSlice.injectEndpoints({
  endpoints: builder => ({
    getEmployeeComponsationList: builder.query<
      ListResponse<EmployeeCompoensationProps>,
      any
    >({
      query: args => {
        const { search, page, sortType, sortBy, searchString } = args;
        return {
          url: `/compensation/employee-compensations`,
          params: { search, page, sortType, sortBy, ...searchString },
        };
      },
    }),
    getEearningList: builder.query<
      ListResponse<EmployeeCompoensationEarningProps>,
      any
    >({
      query: args => {
        const { id, search, page, sortType, sortBy, searchString } = args;
        return {
          url: `/compensation/employee-compensations/${id}/earnings`,
          params: { search, page, sortType, sortBy, ...searchString },
        };
      },
      providesTags: ['employeeEarningDetail'],
    }),
    getDeductionList: builder.query<
      ListResponse<EmployeeCompoensationDeductionsProps>,
      any
    >({
      query: args => {
        const { id, search, page, sortType, sortBy, searchString } = args;
        return {
          url: `/compensation/employee-compensations/${id}/deductions`,
          params: { search, page, sortType, sortBy, ...searchString },
        };
      },
    }),
    getBankList: builder.query<Array<EmployeeCompensationBanksProps>, any>({
      query: args => {
        const { id } = args;
        return {
          url: `/compensation/employee-compensations/${id}/banks`,
        };
      },
    }),
    getPayrollHistory: builder.query<
      ListResponse<EmployeeCompensationPayrollHistory>,
      any
    >({
      query: args => {
        const { id, search, page, sortType, sort, searchString, employeeId } =
          args;
        return {
          url: `/payroll/employees/${id}/payroll-histories`,
          params: { search, page, sortType, sort, employeeId, ...searchString },
        };
      },
    }),
    getListEmail: builder.query<any, any>({
      query: ({ employeeId }) => ({
        url: `/payroll/reports/payslip/employee/${employeeId}/emails`,
      }),
    }),
    sendEmailPaySlip: builder.mutation<any, any>({
      query: args => {
        const { payrollId, employeeId, body } = args;
        return {
          url: `/payroll/reports/payslip/${payrollId}/employee/${employeeId}/send`,
          method: 'POST',
          body,
        };
      },
    }),
    getDownloadFile: builder.query({
      query: args => {
        const { payrollId, employeeId } = args;
        return {
          url: `/payroll/reports/payslip/${payrollId}/employee/${employeeId}/download`,
        };
      },
    }),
    sendEmployeeEmailPayslip: builder.mutation<any, any>({
      query: args => {
        const { payrollId, body } = args;
        return {
          url: `/payroll/payroll-run/${payrollId}/payslip/blast`,
          method: 'POST',
          body,
        };
      },
    }),
    sendWorkPersonalPayslip: builder.mutation<any, any>({
      query: args => {
        const { payrollId, body } = args;
        return {
          url: `/payroll/payroll-run/${payrollId}/payslip/blast`,
          method: 'POST',
          body,
        };
      },
      invalidatesTags: ['payrollRunSummary'],
    }),
  }),
});

export const {
  useGetEmployeeComponsationListQuery,
  useGetEearningListQuery,
  useGetDeductionListQuery,
  useGetBankListQuery,
  useGetPayrollHistoryQuery,
  useGetListEmailQuery,
  useSendEmailPaySlipMutation,
  useGetDownloadFileQuery,
  useSendEmployeeEmailPayslipMutation,
  useSendWorkPersonalPayslipMutation,
} = employeeApiSlice;
