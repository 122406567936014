const LogoWIthLabel = () => (
  <svg
    width="133"
    height="24"
    viewBox="0 0 133 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M32 7.21025V5.15443H42.9703V7.21025H38.7205V18.6924H36.2498V7.21025H32Z"
      fill="#101B28"
    />
    <path
      d="M43.9069 18.6924V8.53893H46.2635V10.2312H46.3709C46.5589 9.64506 46.8811 9.19335 47.3377 8.87606C47.7987 8.55435 48.3246 8.3935 48.9154 8.3935C49.0497 8.3935 49.1996 8.40011 49.3652 8.41333C49.5353 8.42214 49.6763 8.43757 49.7882 8.4596V10.6609C49.6852 10.6256 49.5219 10.5947 49.2981 10.5683C49.0788 10.5375 48.8662 10.522 48.6603 10.522C48.2172 10.522 47.8188 10.6168 47.4652 10.8063C47.1161 10.9914 46.8408 11.2492 46.6394 11.5797C46.438 11.9102 46.3373 12.2914 46.3373 12.7233V18.6924H43.9069Z"
      fill="#101B28"
    />
    <path
      d="M51.4029 18.6924V8.53893H53.8333V18.6924H51.4029ZM52.6248 7.09787C52.2398 7.09787 51.9086 6.97227 51.6311 6.72108C51.3536 6.46548 51.2149 6.1592 51.2149 5.80224C51.2149 5.44087 51.3536 5.13459 51.6311 4.8834C51.9086 4.6278 52.2398 4.5 52.6248 4.5C53.0142 4.5 53.3454 4.6278 53.6184 4.8834C53.8959 5.13459 54.0347 5.44087 54.0347 5.80224C54.0347 6.1592 53.8959 6.46548 53.6184 6.72108C53.3454 6.97227 53.0142 7.09787 52.6248 7.09787Z"
      fill="#101B28"
    />
    <path
      d="M60.8307 18.8907C59.8013 18.8907 58.9173 18.6682 58.1788 18.2231C57.4447 17.778 56.8785 17.1632 56.4802 16.3788C56.0863 15.59 55.8894 14.6822 55.8894 13.6553C55.8894 12.6241 56.0908 11.7141 56.4936 10.9253C56.8964 10.132 57.4649 9.51506 58.1989 9.07437C58.9374 8.62927 59.8102 8.40672 60.8173 8.40672C61.6543 8.40672 62.395 8.55876 63.0395 8.86283C63.6885 9.1625 64.2055 9.58777 64.5904 10.1386C64.9753 10.6851 65.1947 11.3241 65.2484 12.0556H62.9254C62.8314 11.5665 62.6076 11.1588 62.254 10.8327C61.9049 10.5022 61.4372 10.3369 60.8508 10.3369C60.354 10.3369 59.9176 10.4692 59.5417 10.7336C59.1657 10.9936 58.8725 11.3682 58.6621 11.8573C58.4563 12.3465 58.3533 12.9326 58.3533 13.6157C58.3533 14.3076 58.4563 14.9025 58.6621 15.4005C58.868 15.894 59.1567 16.2752 59.5282 16.5441C59.9042 16.8085 60.3451 16.9407 60.8508 16.9407C61.2089 16.9407 61.5289 16.8746 61.8109 16.7424C62.0974 16.6058 62.3368 16.4097 62.5293 16.1541C62.7218 15.8985 62.8538 15.5878 62.9254 15.222H65.2484C65.1902 15.9403 64.9754 16.5771 64.6039 17.1324C64.2324 17.6833 63.7266 18.1151 63.0865 18.428C62.4465 18.7365 61.6945 18.8907 60.8307 18.8907Z"
      fill="#101B28"
    />
    <path
      d="M71.7423 18.8907C70.7352 18.8907 69.8624 18.6726 69.1239 18.2363C68.3854 17.8 67.8125 17.1897 67.4052 16.4053C67.0024 15.6208 66.8009 14.7042 66.8009 13.6553C66.8009 12.6065 67.0024 11.6877 67.4052 10.8988C67.8125 10.11 68.3854 9.49743 69.1239 9.06115C69.8624 8.62486 70.7352 8.40672 71.7423 8.40672C72.7494 8.40672 73.6222 8.62486 74.3607 9.06115C75.0992 9.49743 75.6699 10.11 76.0727 10.8988C76.48 11.6877 76.6837 12.6065 76.6837 13.6553C76.6837 14.7042 76.48 15.6208 76.0727 16.4053C75.6699 17.1897 75.0992 17.8 74.3607 18.2363C73.6222 18.6726 72.7494 18.8907 71.7423 18.8907ZM71.7557 16.9737C72.3018 16.9737 72.7583 16.8261 73.1253 16.5308C73.4924 16.2312 73.7654 15.8301 73.9444 15.3278C74.1279 14.8254 74.2197 14.2657 74.2197 13.6487C74.2197 13.0274 74.1279 12.4655 73.9444 11.9631C73.7654 11.4563 73.4924 11.0531 73.1253 10.7534C72.7583 10.4537 72.3018 10.3039 71.7557 10.3039C71.1962 10.3039 70.7308 10.4537 70.3593 10.7534C69.9922 11.0531 69.717 11.4563 69.5335 11.9631C69.3544 12.4655 69.2649 13.0274 69.2649 13.6487C69.2649 14.2657 69.3544 14.8254 69.5335 15.3278C69.717 15.8301 69.9922 16.2312 70.3593 16.5308C70.7308 16.8261 71.1962 16.9737 71.7557 16.9737Z"
      fill="#101B28"
    />
    <path
      d="M78.7465 18.6924V8.53893H81.103V10.2312H81.2104C81.3984 9.64506 81.7207 9.19335 82.1772 8.87606C82.6382 8.55435 83.1641 8.3935 83.755 8.3935C83.8892 8.3935 84.0392 8.40011 84.2048 8.41333C84.3749 8.42214 84.5158 8.43757 84.6277 8.4596V10.6609C84.5248 10.6256 84.3614 10.5947 84.1376 10.5683C83.9183 10.5375 83.7057 10.522 83.4998 10.522C83.0567 10.522 82.6584 10.6168 82.3048 10.8063C81.9557 10.9914 81.6804 11.2492 81.479 11.5797C81.2776 11.9102 81.1769 12.2914 81.1769 12.7233V18.6924H78.7465Z"
      fill="#101B28"
    />
    <path
      d="M95.0173 5.15443H97.5082V13.9991C97.5082 14.9686 97.2754 15.8213 96.8099 16.5573C96.3489 17.2932 95.6999 17.8683 94.8629 18.2826C94.0259 18.6924 93.048 18.8974 91.929 18.8974C90.8055 18.8974 89.8253 18.6924 88.9884 18.2826C88.1514 17.8683 87.5024 17.2932 87.0414 16.5573C86.5803 15.8213 86.3498 14.9686 86.3498 13.9991V5.15443H88.8406V13.7942C88.8406 14.3582 88.966 14.8606 89.2166 15.3013C89.4717 15.742 89.8298 16.088 90.2908 16.3391C90.7518 16.5859 91.2979 16.7093 91.929 16.7093C92.5601 16.7093 93.1061 16.5859 93.5672 16.3391C94.0326 16.088 94.3907 15.742 94.6414 15.3013C94.892 14.8606 95.0173 14.3582 95.0173 13.7942V5.15443Z"
      fill="#101B28"
    />
    <path
      d="M102.538 12.7431V18.6924H100.108V8.53893H102.431V10.2642H102.552C102.789 9.69574 103.167 9.24403 103.687 8.90911C104.21 8.57418 104.857 8.40672 105.627 8.40672C106.338 8.40672 106.958 8.55656 107.487 8.85622C108.019 9.15589 108.431 9.58997 108.722 10.1585C109.017 10.727 109.163 11.4166 109.158 12.2275V18.6924H106.728V12.5977C106.728 11.919 106.549 11.388 106.191 11.0046C105.837 10.6212 105.347 10.4295 104.72 10.4295C104.295 10.4295 103.917 10.522 103.586 10.7071C103.259 10.8878 103.002 11.15 102.814 11.4938C102.63 11.8375 102.538 12.2539 102.538 12.7431Z"
      fill="#101B28"
    />
    <path
      d="M111.629 18.6924V8.53893H114.059V18.6924H111.629ZM112.851 7.09787C112.466 7.09787 112.135 6.97227 111.857 6.72108C111.58 6.46548 111.441 6.1592 111.441 5.80224C111.441 5.44087 111.58 5.13459 111.857 4.8834C112.135 4.6278 112.466 4.5 112.851 4.5C113.24 4.5 113.571 4.6278 113.844 4.8834C114.122 5.13459 114.261 5.44087 114.261 5.80224C114.261 6.1592 114.122 6.46548 113.844 6.72108C113.571 6.97227 113.24 7.09787 112.851 7.09787Z"
      fill="#101B28"
    />
    <path
      d="M121.782 8.53893V10.3898H115.686V8.53893H121.782ZM117.21 18.6924V7.58043C117.21 6.89736 117.353 6.32886 117.639 5.87495C117.93 5.42104 118.32 5.08171 118.808 4.85696C119.296 4.63221 119.837 4.51983 120.432 4.51983C120.853 4.51983 121.227 4.55288 121.554 4.61899C121.88 4.68509 122.122 4.74458 122.279 4.79747L121.795 6.64837C121.692 6.61752 121.563 6.58667 121.406 6.55582C121.249 6.52057 121.075 6.50294 120.882 6.50294C120.43 6.50294 120.11 6.61091 119.922 6.82685C119.739 7.03838 119.647 7.34245 119.647 7.73907V18.6924H117.21Z"
      fill="#101B28"
    />
    <path
      d="M125.196 22.5C124.865 22.5 124.558 22.4736 124.276 22.4207C123.999 22.3722 123.777 22.3149 123.611 22.2488L124.175 20.3847C124.529 20.486 124.844 20.5345 125.122 20.5301C125.399 20.5257 125.643 20.4398 125.854 20.2723C126.069 20.1093 126.25 19.836 126.398 19.4526L126.606 18.904L122.866 8.53893H125.444L127.821 16.2069H127.928L130.312 8.53893H132.897L128.768 19.922C128.575 20.4596 128.32 20.9201 128.002 21.3035C127.684 21.6913 127.295 21.9866 126.834 22.1893C126.377 22.3964 125.831 22.5 125.196 22.5Z"
      fill="#101B28"
    />
    <path
      d="M12.0853 18.1542C13.2886 17.1735 14.1912 15.8858 14.6931 14.4338C14.6931 14.4338 15.021 13.641 14.9713 11.7763V5.02634C14.9713 4.00986 15.1974 2.9203 15.7719 2.08098C16.3916 1.22581 17.2579 0.571159 18.2598 0.200828C18.9053 -0.0195986 19.6008 -0.0594775 20.2682 0.08567C21.1692 0.280769 21.9937 0.725936 22.643 1.3679C23.4636 2.20652 23.9457 3.30808 24 4.46827L23.9073 11.3755C23.853 12.5448 23.7897 13.8115 23.7648 14.1414C23.6766 15.2842 23.5138 15.4192 23.2491 15.8577C23.2491 15.8577 21.8921 17.9018 19.9651 18.8717C19.9651 18.8717 18.2937 19.8882 14.9532 19.4431C13.9271 19.1824 12.9562 18.7461 12.0853 18.1542Z"
      fill="url(#paint0_linear_11062_3871)"
    />
    <path
      d="M2.18473 9.79427C2.40949 9.78529 2.62959 9.72882 2.82985 9.62876V9.63635C5.75852 8.18575 7.48538 6.92121 8.46355 6.03641C8.62911 5.88855 8.75995 5.70748 8.84709 5.5056C8.93423 5.30373 8.97562 5.08585 8.96842 4.86689C8.96121 4.64793 8.90559 4.43308 8.80535 4.23709C8.70512 4.04109 8.56265 3.86859 8.38771 3.7314C5.93743 1.78713 3.82941 0.693482 2.43703 0.123872C2.2208 0.0329533 1.98626 -0.00886578 1.75108 0.00156485C1.51589 0.0119955 1.2862 0.0744028 1.07931 0.184088C0.872414 0.293773 0.693713 0.447869 0.556672 0.634774C0.419632 0.821678 0.327822 1.03652 0.288165 1.26309C-0.318572 4.80796 0.146206 7.28956 0.642096 8.74776C0.711876 8.95657 0.827207 9.14811 0.980134 9.30919C1.13306 9.47027 1.31995 9.59705 1.52791 9.68079C1.73587 9.76454 1.95996 9.80326 2.18473 9.79427Z"
      fill="#CC2228"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.0136 12.1778C1.71152 12.3303 1.3795 12.4164 1.04043 12.4301C0.709384 12.4434 0.379308 12.3875 0.0718994 12.2661L0.0752111 12.6002C0.0792363 13.2025 0.141333 13.8031 0.260672 14.394C0.355664 14.8679 0.471011 15.3728 0.568264 15.7227C0.905259 16.9163 1.67198 18.1587 2.81414 19.6624C2.81414 19.6624 5.72949 23.4138 10.8116 23.9498C10.8116 23.9498 15.9547 24.6141 19.7996 21.1151C19.7996 21.1151 23.0745 18.6548 23.6083 15.1491C23.6083 15.1491 21.8193 17.8354 20.028 18.6548C20.028 18.6548 16.8616 20.5194 12.7159 18.3934C11.2336 17.4544 10.1094 16.0625 9.51785 14.4338C9.51785 14.4338 8.90493 12.9235 9.03611 9.54629C9.04519 9.34615 9.04212 9.146 9.03909 8.94917C9.03759 8.852 9.03611 8.75563 9.03611 8.66047L9.02768 7.93823C7.46993 9.13088 5.21892 10.5855 2.0136 12.1894V12.1778Z"
      fill="url(#paint1_linear_11062_3871)"
    />
    <defs>
      <linearGradient
        id="paint0_linear_11062_3871"
        x1="17.9545"
        y1="1.02907"
        x2="18.1084"
        y2="20.6966"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.2" stopColor="#CB2127" />
        <stop offset="0.37" stopColor="#C82126" />
        <stop offset="0.48" stopColor="#C01F25" />
        <stop offset="0.57" stopColor="#B11D22" />
        <stop offset="0.66" stopColor="#9B191E" />
        <stop offset="0.74" stopColor="#801519" />
        <stop offset="0.81" stopColor="#5E0F12" />
        <stop offset="0.88" stopColor="#37090A" />
        <stop offset="0.95" stopColor="#090202" />
        <stop offset="0.96" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_11062_3871"
        x1="2.40931"
        y1="8.42571"
        x2="20.6243"
        y2="23.1384"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.51" stopColor="#CB2127" />
        <stop offset="0.7" stopColor="#C92127" />
        <stop offset="0.77" stopColor="#C22025" />
        <stop offset="0.82" stopColor="#B71E23" />
        <stop offset="0.86" stopColor="#A61B20" />
        <stop offset="0.89" stopColor="#90171C" />
        <stop offset="0.92" stopColor="#741316" />
        <stop offset="0.95" stopColor="#540E10" />
        <stop offset="0.98" stopColor="#2F0809" />
        <stop offset="1" />
      </linearGradient>
    </defs>
  </svg>
);

export default LogoWIthLabel;
