import { createSlice } from '@reduxjs/toolkit';
import { ExchangeRateSlice } from './service/exchangeRateSlice';

interface initialStateI {
  messageSuccess: string;
  messageError: string;
}

const initialState: initialStateI = {
  messageSuccess: '',
  messageError: '',
};

const exchangeRateCreateSlice = createSlice({
  name: 'exchangeRate',
  initialState,
  reducers: {
    removeMessage: state => {
      state.messageSuccess = '';
      state.messageError = '';
    },
  },
  extraReducers(builder) {
    builder.addMatcher(
      ExchangeRateSlice.endpoints.createExchangeRate.matchFulfilled,
      (state: any, action: any) => {
        state.messageSuccess = 'Exchange Rate created';
      }
    );
    builder.addMatcher(
      ExchangeRateSlice.endpoints.createExchangeRate.matchRejected,
      (state: any, action: any) => {
        state.messageError = action?.payload?.data?.message;
      }
    );
    builder.addMatcher(
      ExchangeRateSlice.endpoints.updateExchangeRate.matchFulfilled,
      (state: initialStateI, action: any) => {
        if (action?.payload?.typeOfChange === 'UPDATED') {
          state.messageSuccess = 'Exchange Rate updated';
        } else if (action?.payload?.typeOfChange === 'CORRECTED') {
          state.messageSuccess = 'Exchange Rate corrected';
        }
      }
    );
    builder.addMatcher(
      ExchangeRateSlice.endpoints.updateExchangeRate.matchRejected,
      (state: initialStateI, action) => {
        const payload = action.payload as any;
        state.messageError = payload?.data?.message;
      }
    );
    builder.addMatcher(
      ExchangeRateSlice.endpoints.deleteExchangeRate.matchFulfilled,
      (state: initialStateI) => {
        state.messageSuccess = 'Exchange Rate deleted';
      }
    );
    builder.addMatcher(
      ExchangeRateSlice.endpoints.deleteExchangeRate.matchRejected,
      (state: initialStateI, action) => {
        const payload = action.payload as any;
        state.messageError = payload?.data?.message;
      }
    );
    builder.addMatcher(
      ExchangeRateSlice.endpoints.cancelUpcomingExchangeRate.matchFulfilled,
      (state: initialStateI, action: any) => {
        state.messageSuccess = 'Upcoming Changes cancelled';
      }
    );
  },
});

export const { removeMessage } = exchangeRateCreateSlice.actions;
export const exchangeRateReducer = exchangeRateCreateSlice.reducer;
