import { apiSlice } from '../api/apiSlice';
import { QueryParams } from '../../../@types/QueryParams';
import {
  AttendancePolicyDetail,
  AttendancePolicyList,
  ESSDetailsResponse,
  EssPolicyList,
} from '../../../@types/Policy';

export const policyApiSlice = apiSlice.injectEndpoints({
  endpoints: builder => ({
    listAttendancePolicy: builder.query<
      AttendancePolicyList,
      Omit<QueryParams, 'sort'> & { sortBy: string }
    >({
      query: params => {
        const { search, page, sortType, sortBy, limit, searchString } = params;
        return {
          url: '/time-management/attendance-policy',
          params: { search, page, sortType, sortBy, limit, ...searchString },
        };
      },
      providesTags: ['attendancePolicyList'],
    }),
    createAttendancePolicy: builder.mutation<any, void>({
      query: payload => ({
        url: '/time-management/attendance-policy',
        method: 'POST',
        body: payload,
      }),
      invalidatesTags: ['attendancePolicyList'],
    }),
    detailsAttendancePolicy: builder.query<
      AttendancePolicyDetail,
      { id: string }
    >({
      query: ({ id }) => ({
        url: `/time-management/attendance-policy/${id}`,
      }),
      providesTags: ['attendancePolicyDetail'],
    }),
    listEssPolicy: builder.query<
      EssPolicyList,
      Omit<QueryParams, 'sort'> & { sortBy: string }
    >({
      query: params => {
        const { search, page, sortType, sortBy, limit, searchString } = params;
        return {
          url: '/time-management/ess-policy',
          params: { search, page, sortType, sortBy, limit, ...searchString },
        };
      },
      providesTags: ['essPolicyList'],
    }),
    createEssPolicy: builder.mutation<any, void>({
      query: payload => ({
        url: '/time-management/ess-policy',
        method: 'POST',
        body: payload,
      }),
      invalidatesTags: ['essPolicyList'],
    }),
    detailEssPolicy: builder.query<ESSDetailsResponse, { id: string }>({
      query: ({ id }) => ({
        url: `/time-management/ess-policy/${id}`,
      }),
    }),
  }),
});

export const {
  useListAttendancePolicyQuery,
  useCreateAttendancePolicyMutation,
  useDetailsAttendancePolicyQuery,
  useListEssPolicyQuery,
  useCreateEssPolicyMutation,
  useDetailEssPolicyQuery,
} = policyApiSlice;
