const Building07 = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4.9987 7.33333H3.06536C2.692 7.33333 2.50531 7.33333 2.3627 7.406C2.23726 7.46991 2.13528 7.5719 2.07136 7.69734C1.9987 7.83995 1.9987 8.02663 1.9987 8.4V14M10.9987 7.33333H12.932C13.3054 7.33333 13.4921 7.33333 13.6347 7.406C13.7601 7.46991 13.8621 7.5719 13.926 7.69734C13.9987 7.83995 13.9987 8.02663 13.9987 8.4V14M10.9987 14V4.13333C10.9987 3.3866 10.9987 3.01323 10.8534 2.72801C10.7255 2.47713 10.5216 2.27316 10.2707 2.14532C9.98547 2 9.6121 2 8.86536 2H7.13203C6.38529 2 6.01193 2 5.72671 2.14532C5.47583 2.27316 5.27185 2.47713 5.14402 2.72801C4.9987 3.01323 4.9987 3.3866 4.9987 4.13333V14M14.6654 14H1.33203M7.33203 4.66667H8.66536M7.33203 7.33333H8.66536M7.33203 10H8.66536"
      stroke="#045FC4"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default Building07;
