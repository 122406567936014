import { useEffect, useState } from 'react';
import { useAuth } from 'react-oidc-context';
import { useNavigate } from 'react-router-dom';
import LoginWithEmail from '../../components/organism/login/LoginWithEmail';
import LoginWithSSO from '../../components/organism/login/LoginWithSSO';
import { useAuthenticateSSOMutation } from '../../store/feature/service/authApiSlice';
import { useAppSelector } from '../../store';

const Login = () => {
  const [isLoginWithEmail, setIsLoginWithEmail] = useState(true);
  const auth = useAuth();
  const { isRedirectSignInSSO } = useAppSelector(state => state.auth);
  const [authenticateSSO] = useAuthenticateSSOMutation();
  const navigate = useNavigate();

  useEffect(() => {
    if (auth.isAuthenticated) {
      const authenticate = async () => {
        try {
          const result = await authenticateSSO({
            token: auth.user?.access_token,
            idToken: auth.user?.id_token,
            clientId: auth.settings.client_id,
          }).unwrap();
          auth.removeUser();
          const tenant = result?.tenants?.[0]?.subdomain;
          if (tenant) {
            window.location.href = `${window.location.protocol}//${tenant}.${window.location.host}`;
            return;
          }
          navigate('/');
        } catch (error) {
          console.log('error', error);
        }
      };
      authenticate();
    }
  }, [auth]);

  useEffect(() => {
    if (isRedirectSignInSSO) {
      auth.signinRedirect();
    }
  }, [isRedirectSignInSSO]);

  return (
    <>
      {isLoginWithEmail && !isRedirectSignInSSO && (
        <LoginWithEmail setIsLoginWithEmail={setIsLoginWithEmail} />
      )}
      {(!isLoginWithEmail || isRedirectSignInSSO) && (
        <LoginWithSSO setIsLoginWithEmail={setIsLoginWithEmail} />
      )}
    </>
  );
};

export default Login;
