import { createSlice } from '@reduxjs/toolkit';
import { JobPositionApiSlice } from './service/jobPositionApiSlice';

interface initialStateI {
  messageSuccess: string;
  messageError: string;
}

const initialState: initialStateI = {
  messageSuccess: '',
  messageError: '',
};

const jobPositionSlice = createSlice({
  name: 'jobPosition',
  initialState,
  reducers: {
    removeMessage: state => {
      state.messageSuccess = '';
      state.messageError = '';
    },
  },
  extraReducers(builder) {
    builder.addMatcher(
      JobPositionApiSlice.endpoints.addJobPosition.matchFulfilled,
      (state: initialStateI) => {
        state.messageSuccess = 'Job Position created';
      }
    );
    builder.addMatcher(
      JobPositionApiSlice.endpoints.updateJobPosition.matchFulfilled,
      (state: initialStateI, action: any) => {
        if (action?.payload?.typeOfChange === 'UPDATED') {
          state.messageSuccess = 'Job Position updated';
        } else if (action?.payload?.typeOfChange === 'CORRECTED') {
          state.messageSuccess = 'Job Position corrected';
        }
      }
    );
    builder.addMatcher(
      JobPositionApiSlice.endpoints.updateJobPosition.matchRejected,
      (state: initialStateI, action: any) => {
        const payload = action.payload as any;
        state.messageError = payload.data.message;
      }
    );
    builder.addMatcher(
      JobPositionApiSlice.endpoints.addJobPosition.matchRejected,
      (state: initialStateI, action: any) => {
        const payload = action.payload as any;
        state.messageError = payload?.data?.message;
      }
    );
    builder.addMatcher(
      JobPositionApiSlice.endpoints.deleteJobPosition.matchFulfilled,
      (state, _action) => {
        state.messageSuccess = 'Job Position deleted';
      }
    );
    builder.addMatcher(
      JobPositionApiSlice.endpoints.deleteJobPosition.matchRejected,
      (state, action) => {
        const payload = action.payload as any;
        state.messageError = payload?.data?.message;
      }
    );
    builder.addMatcher(
      JobPositionApiSlice.endpoints.cancelUpdateJobPosition.matchFulfilled,
      (state, action) => {
        state.messageSuccess = 'Upcoming Changes cancelled';
      }
    );
    builder.addMatcher(
      JobPositionApiSlice.endpoints.cancelUpdateJobPosition.matchRejected,
      (state, action) => {
        const payload = action.payload as any;
        state.messageSuccess =
          payload?.data?.message || 'Internal Server Error';
      }
    );
  },
});

export const { removeMessage } = jobPositionSlice.actions;
export const jobPositionReducer = jobPositionSlice.reducer;
