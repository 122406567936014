import React from 'react';

const Users03 = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12 10.5579C12.9706 11.0455 13.8028 11.828 14.4102 12.8064C14.5305 13.0002 14.5906 13.0971 14.6114 13.2312C14.6537 13.5038 14.4672 13.839 14.2133 13.9469C14.0884 14 13.9478 14 13.6667 14M10.6667 7.68816C11.6545 7.19726 12.3333 6.17791 12.3333 5C12.3333 3.82209 11.6545 2.80274 10.6667 2.31184M9.33335 5C9.33335 6.65685 7.9902 8 6.33335 8C4.67649 8 3.33335 6.65685 3.33335 5C3.33335 3.34315 4.67649 2 6.33335 2C7.9902 2 9.33335 3.34315 9.33335 5ZM1.70617 12.6256C2.76904 11.0297 4.44626 10 6.33335 10C8.22043 10 9.89766 11.0297 10.9605 12.6256C11.1934 12.9752 11.3098 13.15 11.2964 13.3733C11.286 13.5471 11.172 13.76 11.0331 13.8651C10.8546 14 10.6092 14 10.1184 14H2.54825C2.05746 14 1.81207 14 1.63364 13.8651C1.49471 13.76 1.38074 13.5471 1.3703 13.3733C1.3569 13.15 1.47332 12.9752 1.70617 12.6256Z"
      stroke="#045FC4"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default Users03;
