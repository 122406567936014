const headerKey = {
  name: 'Name',
  code: 'Code',
  levelName: 'Level',
  description: 'Description',
};

const detailKey: { [key: string]: any } = {
  effectiveDate: 'Effective Date',
  name: 'Name',
  code: 'Code',
  level: 'Level',
  description: 'Description',
};

const defaultValueForm = {
  name: null,
  code: null,
  level: null,
  description: null,
  effectiveDate: '',
};

export default {
  headerKey,
  detailKey,
  defaultValueForm,
};
