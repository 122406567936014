import { apiSlice } from '../../api/apiSlice';

export const BankApiSlice = apiSlice.injectEndpoints({
  endpoints: builder => ({
    getBankListMasterData: builder.query<any, any>({
      query: params => {
        const { search, page, sortType, sort, searchString } = params;
        return {
          url: `/organization/banks`,
          params: { search, page, sortType, sort, ...searchString },
        };
      },
      providesTags: ['bankList'],
    }),
    getBankDetails: builder.query<any, any>({
      query: (params: { id: any }) => {
        const { id } = params;
        return {
          url: `/organization/banks/${id}`,
        };
      },
      providesTags: ['bankDetail'],
    }),
    createBankMasterData: builder.mutation<any, any>({
      query: payload => ({
        url: '/organization/banks',
        method: 'POST',
        body: payload,
      }),
      invalidatesTags: ['bankList'],
    }),
    updateBankMasterData: builder.mutation<any, any>({
      query: ({ id, payload }) => ({
        url: `/organization/banks/${id}`,
        method: 'PUT',
        body: payload,
      }),
      invalidatesTags: [
        'bankDetail',
        'bankList',
        'employeeBank',
        'employeeBankDetail',
      ],
    }),
    deleteBankMasterData: builder.mutation<any, any>({
      query: id => ({
        url: `/organization/banks/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['bankList'],
    }),
  }),
});

export const {
  useGetBankListMasterDataQuery,
  useGetBankDetailsQuery,
  useCreateBankMasterDataMutation,
  useDeleteBankMasterDataMutation,
  useUpdateBankMasterDataMutation,
} = BankApiSlice;
