import { useRef, useMemo } from 'react';

import { Button } from '..';
import { useUpdatePhotoMutation } from '../../../store/feature/service/fileUploadApiSlice';

export interface ImageUploadProps {
  value: string;
  onChange: Function;
  disabled: boolean;
  size: 'sx' | 'sm' | 'md' | 'lg' | 'xl' | '2xl' | 'big avatar';
  acceptedFiles?: string[];
  categoryCode: string;
  maxFileSize?: number;
  setOnError?: Function;
}

const ImageUpload = (Props: ImageUploadProps) => {
  const {
    value,
    onChange,
    disabled,
    size = 'big avatar',
    acceptedFiles = [],
    categoryCode,
    maxFileSize = 10 * 1000 * 1000,
    setOnError,
  } = Props;
  const [uploadPhoto, { isLoading: loadingUploadPhoto, isError }] =
    useUpdatePhotoMutation();

  const avatarSize = useMemo(
    () => ({
      sx: {
        container: '24px',
        avatar: '16px',
      },
      sm: {
        container: '32px',
        avatar: '20px',
      },
      md: {
        container: '40px',
        avatar: '24px',
      },
      lg: {
        container: '48px',
        avatar: '28px',
      },
      xl: {
        container: '56px',
        avatar: '32px',
      },
      '2xl': {
        container: '64px',
        avatar: '32px',
      },
      'big avatar': {
        container: '96px',
        avatar: '48px',
      },
    }),
    [size]
  );

  const inputFile: any = useRef(null);

  const onImageChange = async (data: any) => {
    const files = data.currentTarget?.files;
    if (files?.length) {
      const file = files[0];
      const fileSize = Number(file.size / 1e6);
      const maxMB = maxFileSize / 1e6;
      if (fileSize <= maxMB) {
        const payload = new FormData();
        payload.append('file', file);
        payload.append('name', file.name);
        payload.append('categoryCode', categoryCode);
        const data = await uploadPhoto({
          body: payload,
        }).unwrap();
        onChange(data.url);
      } else {
        setOnError?.(
          `Maximum file size: ${(maxFileSize / 1e6).toFixed(2)} MB`,
          {}
        );
      }
    }
  };

  const handleClickBrowse = () => {
    inputFile?.current?.click();
  };

  return (
    <>
      <input
        aria-label="file"
        ref={inputFile}
        accept={acceptedFiles.join(',')}
        type="file"
        id="file"
        style={{ display: 'none' }}
        onChange={onImageChange}
        onClick={(e: any) => {
          const { target = {} } = e || {};
          target.value = '';
        }}
      />
      <Button
        variant="outlined"
        onClick={handleClickBrowse}
        disabled={disabled}
        sx={{
          minWidth: avatarSize[size].container,
          minHeight: avatarSize[size].container,
          width: avatarSize[size].container,
          height: avatarSize[size].container,
          borderRadius: '100px',
          background: value ? `url(${value})` : 'url(/assets/user-default.png)',
          backgroundRepeat: 'no-repeat',
          backgroundSize: avatarSize[size].avatar,
          backgroundPosition: 'center',
          '&:hover': {
            background: 'rgba(16, 27, 40, 0.5), url(/assets/user-default.png)',
            opacity: '0.5',
            '& img': {
              display: 'block !important',
            },
          },
        }}
      >
        {!disabled && (
          <img
            style={{ display: 'none' }}
            src="/assets/photoaddicon.svg"
            alt="test"
          />
        )}
      </Button>
    </>
  );
};

export default ImageUpload;
