import { lazy } from 'react';

const List = lazy(() => import('../../../pages/attendanceStatus/List'));
const Create = lazy(() => import('../../../pages/attendanceStatus/Create'));
const Details = lazy(() => import('../../../pages/attendanceStatus/Details'));
const Update = lazy(() => import('../../../pages/attendanceStatus/Update'));

export const attendanceStatus = [
  {
    label: 'Attendance Status',
    child: null,
    path: 'attendance-status',
    component: List,
  },
  {
    label: '',
    child: null,
    path: 'attendance-status/create',
    component: Create,
  },
  {
    label: '',
    path: 'attendance-status/detail/:id',
    component: Details,
    child: null,
  },
  {
    label: '',
    path: 'attendance-status/update/:id',
    component: Update,
    child: null,
  },
];
