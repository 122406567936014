import {
  Box,
  Modal as BaseModal,
  ModalProps as MP,
  Typography,
  useTheme,
} from '@mui/material';
import React, { ReactNode } from 'react';

import { ButtonProps } from '../../../@types';
import { Button } from '../../atom';
import { AlertCircle, Close } from '../../atom/icon';

interface ModalProps extends Omit<MP, 'onClose' | 'children'> {
  fullHeight?: boolean;
  leftButtonProps?: ButtonProps;
  leftButtonLabel?: string | ReactNode;
  rightButtonProps?: ButtonProps;
  rightButonLabel?: string | React.ReactNode;
  title?: string;
  caption?: string | React.ReactNode;
  icon?: React.ReactNode;
  showCloseIcon?: boolean;
  showIcon?: boolean;
  onClose?: Function;
  children?: React.ReactNode;
  type?: 'left' | 'center';
  showButton?: boolean;
}

const AlertIcon = () => {
  const { palette } = useTheme();

  return (
    <Box
      sx={{
        background: palette.green[20],
        height: '48px',
        width: '48px',
        borderRadius: '90px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        '& svg path': {
          stroke: palette.green[70],
        },
      }}
    >
      <AlertCircle />
    </Box>
  );
};

const Modal = (Props: ModalProps) => {
  const {
    fullHeight,
    leftButtonProps,
    rightButtonProps,
    leftButtonLabel,
    rightButonLabel,
    icon,
    title,
    caption,
    onClose,
    showCloseIcon,
    children,
    type = 'left',
    showButton = true,
    sx = {},
    showIcon = true,
    ...props
  } = Props;
  const { palette } = useTheme();

  const handleClose = (_e: any) => {
    onClose?.();
  };

  return (
    <BaseModal
      {...props}
      onClose={handleClose}
      sx={{
        overflow: 'auto',
        display: 'flex',
        justifyContent: 'center',
        padding: '80px',
        alignItems: fullHeight ? 'flex-start' : 'center',
      }}
      disableAutoFocus
    >
      <Box
        className="header"
        sx={{
          background: '#fff',
          borderRadius: '16px',
          padding: '24px',
          width: '400px',
          maxWidth: '400px',
          boxSizing: 'border-box',
          display: 'flex',
          flexDirection: 'column',
          gap: '16px',
          ...sx,
        }}
      >
        <Box
          className="header"
          sx={{ gap: '16px', display: 'flex', flexDirection: 'column' }}
        >
          <Box
            sx={{
              display: 'flex',
              position: 'relative',
              justifyContent: type === 'left' ? 'flex-start' : 'center',
            }}
          >
            {showIcon && (icon || <AlertIcon />)}
            {showCloseIcon && (
              <Button
                onClick={handleClose}
                variant="tertiary"
                sx={{
                  right: 0,
                  top: 0,
                  position: 'absolute',
                  height: '28px',
                  width: '28px',
                  minWidth: 0,
                  minHeight: 0,
                  padding: 0,
                }}
              >
                <Close />
              </Button>
            )}
          </Box>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: type === 'left' ? 'flex-start' : 'center',
            }}
          >
            <Typography
              variant="body18"
              fontWeight={700}
              color={palette.gray[100]}
              component="p"
            >
              {title}
            </Typography>
            <Typography variant="body14" color={palette.gray[80]} component="p">
              {caption}
            </Typography>
          </Box>
        </Box>
        {children && <Box className="content">{children}</Box>}
        {showButton && (
          <Box
            className="footer"
            sx={{
              display: 'flex',
              flexDirection: 'row',
              gap: '16px',
              width: '100%',
            }}
          >
            <Button {...leftButtonProps} fullWidth>
              {leftButtonLabel}
            </Button>
            <Button {...rightButtonProps} fullWidth>
              {rightButonLabel}
            </Button>
          </Box>
        )}
      </Box>
    </BaseModal>
  );
};

export default Modal;
