import { createSlice } from '@reduxjs/toolkit';
import { employeeApiSlice } from './service/employeeCompensationApiSlice';

interface initialStateI {
  messageSuccess: string;
  messageError: string;
}

const initialState: initialStateI = {
  messageSuccess: '',
  messageError: '',
};

const employeePayrollHistorySlice = createSlice({
  name: 'payItem',
  initialState,
  reducers: {
    removeMessage: state => {
      state.messageSuccess = '';
      state.messageError = '';
    },
  },
  extraReducers(builder) {
    builder.addMatcher(
      employeeApiSlice.endpoints.sendEmailPaySlip.matchFulfilled,
      (state, action) => {
        action?.payload?.sendTo === 'EMPLOYEE'
          ? (state.messageSuccess = 'Payslip sent to employee')
          : (state.messageSuccess = 'Payslip sent to your email');
      }
    );
    builder.addMatcher(
      employeeApiSlice.endpoints.sendEmailPaySlip.matchRejected,
      (state, action) => {
        const payload = action.payload as any;
        state.messageError = payload?.data?.message || 'Internal Server Error';
      }
    );
    builder.addMatcher(
      employeeApiSlice.endpoints.getDownloadFile.matchFulfilled,
      state => {
        state.messageSuccess = 'Payslip downloaded';
      }
    );
    builder.addMatcher(
      employeeApiSlice.endpoints.getDownloadFile.matchRejected,
      (state, action) => {
        const payload = action.payload as any;
        state.messageError = payload?.data?.message || 'Internal Server Error';
      }
    );
  },
});

export const { removeMessage } = employeePayrollHistorySlice.actions;
export const payrollHistoryReducer = employeePayrollHistorySlice.reducer;
