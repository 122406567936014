import { FC } from 'react';
import { SvgIconProps } from '@mui/material';
import { SvgIcon } from './SvgIcon';

const ArrowLeft: FC<SvgIconProps> = props => (
  <SvgIcon {...props}>
    <path d="M5.97017 10.5568L4.81676 9.41335L7.84446 6.38565H0.75V4.70526H7.84446L4.81676 1.68253L5.97017 0.534091L10.9815 5.54545L5.97017 10.5568Z" />
  </SvgIcon>
);

export default ArrowLeft;
