import { createSlice } from '@reduxjs/toolkit';

import LOCAL_STORAGE_KEY from '../../constants/localStorage';
import { deleteCookie, getTld } from '../../utils/cookies';
import { getFullName, getToken, getUserEmail } from '../../utils/storage';

const { TOKEN_STORAGE, EXPIRE_TOKEN_TIME, REFRESH_TOKEN } = LOCAL_STORAGE_KEY;

interface initialStateI {
  token: string | null;
  isLoading: boolean;
  fullName: string | null;
  messageError: string;
  messageSuccess: string;
  errorCode: string;
  parameterError: string;
  refreshToken: string | null;
  resendOTPToken: string | null;
  email: string | null;
}

const initialState: initialStateI = {
  token: getToken().toString(),
  fullName: getFullName().toString(),
  isLoading: false,
  messageSuccess: '',
  messageError: '',
  errorCode: '',
  parameterError: '',
  refreshToken: null,
  resendOTPToken: null,
  email: getUserEmail().toString(),
};

const credentialSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setCredentials: (state, action) => {
      state.isLoading = false;
      state.fullName = action?.payload?.userName;
      state.token = action?.payload?.token;
      state.refreshToken = action?.payload?.refreshToken;
    },
    logOut: state => {
      state.token = null;
      state.fullName = null;
      const tld = getTld(window.location.hostname);
      deleteCookie(TOKEN_STORAGE, '/', `.${tld}`);
      deleteCookie(EXPIRE_TOKEN_TIME, '/', `.${tld}`);
      deleteCookie(REFRESH_TOKEN, '/', `.${tld}`);
    },
    removeMessage: state => {
      state.messageError = '';
    },
  },
});

export const { setCredentials, logOut, removeMessage } =
  credentialSlice.actions;
export const credentialReducer = credentialSlice.reducer;
