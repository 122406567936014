import React from 'react';
import { Snackbar } from '@mui/material';
import { AlertProps } from '../../../@types';
import Alert from '../../atom/alert/Alert';

interface CustomProps {
  open?: boolean;
  autoHideDuration?: number;
  onNotificationClose?: Function;
}

const Notification = <UseFieldArrayOptions extends Record<string, any>>(
  Props: AlertProps & UseFieldArrayOptions & CustomProps
) => {
  const {
    variant,
    message,
    open = false,
    autoHideDuration = 2000,
    onNotificationClose,
    ...props
  } = Props;

  const handleClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === 'clickaway') {
      return;
    }
    onNotificationClose?.();
  };

  return (
    <Snackbar
      open={open}
      autoHideDuration={autoHideDuration}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      onClose={handleClose}
    >
      <Alert variant={variant} message={message} {...props} />
    </Snackbar>
  );
};

export default Notification;
