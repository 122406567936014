import {
  JobSiteData,
  JobSiteDataPagination,
} from '../../../@types/JobSiteType';
import { apiSlice } from '../api/apiSlice';

type ListParams = {
  search: string;
};

export const jobSiteApiSlice = apiSlice.injectEndpoints({
  endpoints: builder => ({
    getJobSiteList: builder.query<JobSiteDataPagination, ListParams>({
      query: params => {
        const { search } = params;
        return {
          url: `/time-management/job-sites`,
          params: { search, limit: 99999 },
        };
      },
      providesTags: ['JobSiteList'],
    }),
    createJobSite: builder.mutation<any, JobSiteData>({
      query: payload => ({
        url: '/time-management/job-sites',
        method: 'POST',
        body: payload,
      }),
      invalidatesTags: ['JobSiteList'],
    }),
    updatesJobSite: builder.mutation<any, { id: string; payload: any }>({
      query: params => {
        const { id, payload } = params;
        return {
          url: `/time-management/job-sites/${id}`,
          method: 'put',
          body: payload,
        };
      },
      invalidatesTags: ['JobSiteList'],
    }),
    deleteJobSite: builder.mutation<any, { id: string }>({
      query: ({ id }) => ({
        url: `/time-management/job-sites/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['JobSiteList'],
    }),
  }),
});

export const {
  useGetJobSiteListQuery,
  useCreateJobSiteMutation,
  useDeleteJobSiteMutation,
  useUpdatesJobSiteMutation,
} = jobSiteApiSlice;
