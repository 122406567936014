export const parseToCurrency = (
  n: number = 0,
  countryCode: string = 'id-ID',
  currencyType: string = 'IDR',
  currencyDisplay: string = 'code'
) =>
  Number(n).toLocaleString(countryCode, {
    style: 'currency',
    currency: currencyType,
    currencyDisplay,
  });

export const parseNumber = (
  n: number = 0,
  countryCode: string = 'id-ID',
  digits: number = 2
) =>
  Number(n).toLocaleString(countryCode, {
    minimumFractionDigits: digits,
  });

export const parseNumberWoDecimal = (
  n: number = 0,
  countryCode: string = 'id-ID'
) =>
  Number(n).toLocaleString(countryCode, {
    minimumFractionDigits: 0,
  });
