import { createSlice } from '@reduxjs/toolkit';
import { employeePaymentDetailsApiSlice } from './service/employee/compensation/paymentDetailsApiSlice';
import { sortData } from '../../utils/dataDisplay';

// type intialStateType = {
//     messageError: string;
//     messageSuccess: string;
//     regularPayments: Arra
// }

const initialState = {
  messageSuccess: '',
  messageError: '',
  filteredRegularPayments: [],
  regularPayments: [],
  updatedRegularPayment: 0,
};

const employeePaymentDetailsSlice = createSlice({
  name: 'employeePaymentDetails',
  initialState,
  reducers: {
    removeMessage: state => {
      state.messageSuccess = '';
      state.messageError = '';
    },
    getRegularPaymentList: (state, action) => {
      const { search, sortBy, sortType } = action.payload;
      state.filteredRegularPayments = state.regularPayments;
      if (search) {
        state.filteredRegularPayments = state.filteredRegularPayments.filter(
          (item: any) =>
            item?.paymentType?.label
              .toLowerCase()
              .includes(search.toLowerCase()) ||
            item?.companyAccount?.label
              ?.toLowerCase()
              .includes(search.toLowerCase()) ||
            item?.payGroup?.label?.toLowerCase().includes(search.toLowerCase())
        );
      }
      sortData(state.filteredRegularPayments, sortBy, sortType);
    },
    addRegularPayment: (state: any, action: any) => {
      const currentAmount =
        state.regularPayments.length > 0
          ? state.regularPayments.reduce(
              (total: any, item: any) => total + item.amount,
              0
            )
          : 0;
      const { payload } = action;
      /* eslint no-unsafe-optional-chaining: "off" */
      if (payload?.amount <= 100 && currentAmount + payload?.amount <= 100) {
        state.regularPayments.push(payload);
        state.messageSuccess = 'Payment added';
      } else if (payload?.amount > 100) {
        state.messageError = 'Amount must not be larger than 100%';
      } else {
        state.messageError = 'Total amount must not be larger than 100%';
      }
      state.updatedRegularPayment = new Date().getTime();
    },
    addOffCyclePayment: (state: any, action: any) => {
      const currentAmount =
        state.regularPayments.length > 0
          ? state.regularPayments
              .filter(
                (payment: any) =>
                  payment.payGroup.value === action.payload.payGroup.value
              )
              .reduce((total: any, item: any) => total + item.amount, 0)
          : 0;
      const { payload } = action;
      /* eslint no-unsafe-optional-chaining: "off" */
      if (payload?.amount <= 100 && currentAmount + payload?.amount <= 100) {
        state.regularPayments.push(payload);
        state.messageSuccess = 'Payment added';
      } else if (payload?.amount > 100) {
        state.messageError = 'Amount must not be larger than 100%';
      } else {
        state.messageError = 'Total amount must not be larger than 100%';
      }
      state.updatedRegularPayment = new Date().getTime();
    },
    deleteRegularPayment: (state, action) => {
      state.regularPayments = state.regularPayments.filter(
        (item: any) => item.id !== action.payload
      );
      state.messageSuccess = 'Payment removed';
      state.updatedRegularPayment = new Date().getTime();
    },
    updateRegularPayment: (state: any, action: any) => {
      const indexPayment = state.regularPayments.findIndex(
        (item: any) => item.id === action.payload.selectedId
      );
      const { payload } = action;

      let currentAmount = 0;
      /* eslint no-else-return: "off" */
      if (state.regularPayments.length > 0) {
        currentAmount = state.regularPayments.reduce(
          (total: any, item: any, index: number) => {
            if (index !== indexPayment) {
              return total + item.amount;
            } else {
              return total;
            }
          },
          0
        );
      }

      if (indexPayment !== -1) {
        /* eslint no-unsafe-optional-chaining: "off" */
        if (payload?.amount <= 100 && currentAmount + payload?.amount <= 100) {
          state.regularPayments[indexPayment] = action.payload;
          state.messageSuccess = 'Payment edited';
          state.updatedRegularPayment = new Date().getTime();
        } else if (payload?.amount > 100) {
          state.messageError = 'Amount must not be larger than 100%';
        } else {
          state.messageError = 'Total amount must not be larger than 100%';
        }
      } else {
        state.messageError = 'Error! Payment did not exist';
      }
    },
    updateOffCyclePayment: (state: any, action: any) => {
      const indexPayment = state.regularPayments.findIndex(
        (item: any) => item.id === action.payload.selectedId
      );
      const { payload } = action;

      let currentAmount = 0;
      /* eslint no-else-return: "off" */
      if (state.regularPayments.length > 0) {
        currentAmount = state.regularPayments
          .filter(
            (payment: any) =>
              payment.payGroup.value === action.payload.payGroup.value
          )
          .reduce((total: any, item: any, index: number) => {
            if (index !== indexPayment) {
              return total + item.amount;
            } else {
              return total;
            }
          }, 0);
      }

      if (indexPayment !== -1) {
        /* eslint no-unsafe-optional-chaining: "off" */
        if (payload?.amount <= 100 && currentAmount + payload?.amount <= 100) {
          state.regularPayments[indexPayment] = action.payload;
          state.messageSuccess = 'Payment edited';
          state.updatedRegularPayment = new Date().getTime();
        } else if (payload?.amount > 100) {
          state.messageError = 'Amount must not be larger than 100%';
        } else {
          state.messageError = 'Total amount must not be larger than 100%';
        }
      } else {
        state.messageError = 'Error! Payment did not exist';
      }
    },
    updateRegularPayments: (state: any, action: any) => {
      state.regularPayments = action.payload;
      state.messageSuccess = 'Payment Order changed';
      state.filteredRegularPayments = state.filteredRegularPayments.map(
        (e: any) => {
          const payment = action.payload.find((item: any) => item.id === e.id);
          return payment;
        }
      );
    },
    setPayment: (state, action) => {
      state.regularPayments = action.payload;
      state.filteredRegularPayments = action.payload;
    },
    resetRegularPayment: state => {
      state.regularPayments = [];
      state.filteredRegularPayments = [];
    },
  },
  extraReducers(builder) {
    builder.addMatcher(
      employeePaymentDetailsApiSlice.endpoints.createRegularPayment
        .matchFulfilled,
      (state, action) => {
        state.messageSuccess = 'Regular Payment updated';
      }
    );
    builder.addMatcher(
      employeePaymentDetailsApiSlice.endpoints.createRegularPayment
        .matchRejected,
      (state, action) => {
        const payload = action.payload as any;
        state.messageError = payload?.data?.message || 'Internal Server Error';
      }
    );
    builder.addMatcher(
      employeePaymentDetailsApiSlice.endpoints.updateRegularPayment
        .matchFulfilled,
      (state, action) => {
        state.messageSuccess = 'Regular Payment updated';
      }
    );
    builder.addMatcher(
      employeePaymentDetailsApiSlice.endpoints.updateRegularPayment
        .matchRejected,
      (state, action) => {
        const payload = action.payload as any;
        state.messageError = payload?.data?.message || 'Internal Server Error';
      }
    );
    builder.addMatcher(
      employeePaymentDetailsApiSlice.endpoints.cancelChangesRegularPayment
        .matchFulfilled,
      (state, action) => {
        state.messageSuccess = 'Upcoming Changes cancelled';
      }
    );
    builder.addMatcher(
      employeePaymentDetailsApiSlice.endpoints.createOffCyclePayment
        .matchFulfilled,
      (state, action) => {
        state.messageSuccess = 'Off-Cycle Payment updated';
      }
    );
    builder.addMatcher(
      employeePaymentDetailsApiSlice.endpoints.createOffCyclePayment
        .matchRejected,
      (state, action) => {
        const payload = action.payload as any;
        state.messageError = payload?.data?.message || 'Internal Server Error';
      }
    );
    builder.addMatcher(
      employeePaymentDetailsApiSlice.endpoints.updateOffCyclePayment
        .matchFulfilled,
      (state, action) => {
        state.messageSuccess = 'Off-Cycle Payment updated';
      }
    );
    builder.addMatcher(
      employeePaymentDetailsApiSlice.endpoints.updateOffCyclePayment
        .matchRejected,
      (state, action) => {
        const payload = action.payload as any;
        state.messageError = payload?.data?.message || 'Internal Server Error';
      }
    );
    builder.addMatcher(
      employeePaymentDetailsApiSlice.endpoints.cancelChangesOffCyclePayment
        .matchFulfilled,
      (state, action) => {
        state.messageSuccess = 'Upcoming Changes cancelled';
      }
    );
  },
});
export const {
  removeMessage,
  getRegularPaymentList,
  addRegularPayment,
  updateRegularPayment,
  updateRegularPayments,
  resetRegularPayment,
  deleteRegularPayment,
  setPayment,
  addOffCyclePayment,
  updateOffCyclePayment,
} = employeePaymentDetailsSlice.actions;
export const employeePaymentDetailsReducer =
  employeePaymentDetailsSlice.reducer;
