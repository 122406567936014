import React from 'react';
import { RouteNode } from '../../@types';

const Create = React.lazy(
  () => import('../../pages/employee/CostCenterCreate')
);

const Update = React.lazy(
  () => import('../../pages/employee/CostCenterUpdate')
);
const Corrections = React.lazy(
  () => import('../../pages/employee/CostCenterCorrection')
);

const costCenter: RouteNode[] = [
  {
    label: '',
    path: 'employee/detail/:id/cost-center/create',
    component: Create,
    child: null,
  },
  {
    label: '',
    path: 'employee/detail/:id/cost-center/correction/:ccid',
    component: Corrections,
    child: null,
  },
  {
    label: '',
    path: 'employee/detail/:id/cost-center/update/:ccid',
    component: Update,
    child: null,
  },
];

export default costCenter;
