import { QueryParams } from '../../../../@types/QueryParams';
import { apiSlice } from '../../api/apiSlice';
import {
  TaxLocationDetailsType,
  TaxLocationListType,
} from '../../../../@types/masterData/taxLocation';

interface Query {
  id: string;
  effectiveDate?: string;
}

export const TaxLocationApiSlice = apiSlice.injectEndpoints({
  endpoints: builder => ({
    getTaxLocationList: builder.query<TaxLocationListType, QueryParams>({
      query: params => {
        const { search, page, sortType, sort, searchString } = params;
        return {
          url: `/organization/tax-locations`,
          params: { search, page, sortType, sort, ...searchString },
        };
      },
      providesTags: ['taxLocationList'],
    }),
    getTaxLocationDetails: builder.query<TaxLocationDetailsType, Query>({
      query: (params: { id: any }) => {
        const { id } = params;
        return {
          url: `/organization/tax-locations/${id}`,
        };
      },
      providesTags: ['taxLocationDetail'],
    }),
    updateTaxLocation: builder.mutation({
      query: (params: { id: any; payload: any }) => {
        const { id, payload } = params;
        return {
          url: `/organization/tax-locations/${id}`,
          method: 'PUT',
          body: payload,
        };
      },
      invalidatesTags: ['taxLocationDetail'],
    }),
    createTaxLocation: builder.mutation({
      query: payload => ({
        url: '/organization/tax-locations',
        method: 'POST',
        body: payload,
      }),
      invalidatesTags: ['taxLocationList'],
    }),
    deleteTaxLocation: builder.mutation<any, any>({
      query: id => ({
        url: `/organization/tax-locations/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['taxLocationList'],
    }),
    getTaxOfficeDetails: builder.query<any, any>({
      query: params => ({
        url: `/organization/tax-offices/country/${params.id}`,
      }),
    }),
  }),
});

export const {
  useGetTaxLocationListQuery,
  useGetTaxLocationDetailsQuery,
  useCreateTaxLocationMutation,
  useDeleteTaxLocationMutation,
  useUpdateTaxLocationMutation,
  useGetTaxOfficeDetailsQuery,
} = TaxLocationApiSlice;
