import {
  MachineCard,
  ResponseDetailObject,
} from '../../../@types/AttendanceMachineType';
import { apiSlice } from '../api/apiSlice';

type ListParams = {
  search: string;
};

export const attendanceMachineApiSlice = apiSlice.injectEndpoints({
  endpoints: builder => ({
    getAttendanceMachineList: builder.query<Array<MachineCard>, ListParams>({
      query: params => {
        const { search } = params;
        return {
          url: `/time-management/attendance-machine`,
          params: { search },
        };
      },
      providesTags: ['listAttendanceMachine'],
    }),
    createAttendanceMachine: builder.mutation<any, any>({
      query: payload => ({
        url: '/time-management/attendance-machine',
        method: 'POST',
        body: payload,
      }),
      invalidatesTags: ['listAttendanceMachine'],
    }),
    getDetailAttendanceMachine: builder.query<
      ResponseDetailObject,
      { id: string }
    >({
      query: params => {
        const { id } = params;
        return {
          url: `/time-management/attendance-machine/${id}`,
        };
      },
      providesTags: ['DetailAttendanceMachine'],
    }),
    updateAttendanceMachine: builder.mutation<
      any,
      { id: string; payload: any }
    >({
      query: params => {
        const { id, payload } = params;
        return {
          url: `/time-management/attendance-machine/${id}`,
          body: payload,
          method: 'PUT',
        };
      },
      invalidatesTags: ['DetailAttendanceMachine'],
    }),
    deleteAttendanceMachine: builder.mutation<any, { id: string }>({
      query: params => {
        const { id } = params;
        return {
          url: `/time-management/attendance-machine/${id}`,
          method: 'DELETE',
        };
      },
      invalidatesTags: ['DetailAttendanceMachine', 'listAttendanceMachine'],
    }),
  }),
});

export const {
  useGetAttendanceMachineListQuery,
  useCreateAttendanceMachineMutation,
  useGetDetailAttendanceMachineQuery,
  useUpdateAttendanceMachineMutation,
  useDeleteAttendanceMachineMutation,
} = attendanceMachineApiSlice;
