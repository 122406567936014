import { ChevronRight } from '../../../atom/icon';
import { Button } from '../../../atom';

type ButtonEmployeeLinkProps = {
  onClick?: Function;
};

const ButtonEmployeeLink = ({ onClick }: ButtonEmployeeLinkProps) => (
  <Button variant="link" onClick={() => onClick?.()}>
    50+ employees linked <ChevronRight />
  </Button>
);

export default ButtonEmployeeLink;
