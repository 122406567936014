import React from 'react';
import {
  FormControl,
  FormLabel,
  SxProps,
  Typography,
  useTheme,
} from '@mui/material';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { LoadingSkeleton, ImageUpload } from '../../../atom';

export type CustomProps = {
  label?: string;
  showLabelOnError?: boolean;
  isLading?: boolean;
  categoryCode: string;
};

const UploadImageWithLabel = <UseFieldArrayOptions extends Record<string, any>>(
  Props: UseFieldArrayOptions & CustomProps
) => {
  const { palette } = useTheme();
  const { t } = useTranslation();

  const {
    sx,
    name,
    control,
    label,
    showLabelOnError,
    isLoading,
    required = true,
    disabled,
    size,
    acceptedFiles,
    categoryCode,
    maxFileSize,
    setError,
    clearErrors,
    ...props
  } = Props;
  const style = {
    '& .MuiFormLabel-root': {
      marginBottom: '0.5rem',
    },
    '& .input--error .MuiInputBase-root fieldset': {
      borderColor: `${palette.red[50]} !important`,
      '&:focused': {
        borderColor: `${palette.red[50]} !important`,
      },
    },
    ...sx,
  } as SxProps;
  return (
    <FormControl sx={style} fullWidth>
      {label && (
        <FormLabel htmlFor={name}>
          <Typography
            color={palette.gray[100]}
            variant="body14"
            fontWeight={500}
          >
            {label}{' '}
            {!required && (
              <Typography
                component="span"
                variant="body14"
                color={palette.gray[80]}
              >
                (optional)
              </Typography>
            )}
          </Typography>
        </FormLabel>
      )}
      <Controller
        control={control}
        name={name || ''}
        render={({ field, formState }) => {
          const { errors } = formState as any;
          const isError =
            Object.keys(errors).length > 0 &&
            name &&
            Object.prototype.hasOwnProperty.call(errors, name);
          return (
            <>
              {!isLoading && (
                <ImageUpload
                  value={field.value}
                  onChange={(e: any) => {
                    field.onChange(e);
                    clearErrors?.(name);
                  }}
                  disabled={disabled}
                  size={size}
                  acceptedFiles={acceptedFiles}
                  categoryCode={categoryCode}
                  maxFileSize={maxFileSize}
                  setOnError={(message: string, parameterError: string) => {
                    setError(name, {
                      message,
                    });
                  }}
                />
              )}
              {isLoading && (
                <LoadingSkeleton
                  sx={{
                    '& .react-loading-skeleton': {
                      height: '48px',
                      borderRadius: '8px',
                    },
                  }}
                />
              )}
              {isError && (
                <Typography
                  marginTop="8px"
                  component="p"
                  variant="body14"
                  color={palette.red[50]}
                >
                  {`${showLabelOnError ? label : ''} ${t(
                    errors?.[name]?.message
                  )}`}
                </Typography>
              )}
            </>
          );
        }}
      />
    </FormControl>
  );
};

export default UploadImageWithLabel;
