import {
  ESSBank,
  ESSEducation,
  ESSEmployeeProfile,
  ESSEmployement,
  ESSExperience,
  ESSFamily,
  ESSFile,
  ESSPersonalAddressInfo,
  ESSPersonalInfo,
  ESSStatutory,
  ESSEmployeeProfileHistory,
  ESSEmployeeLatestPayment,
} from '../../../@types/EssEmployee';
import { apiSlice } from '../api/apiSlice';

interface QueryParams {
  id?: string;
  sort?: string;
  sortType?: string;
}

interface TabParams {
  id?: string;
  dataId?: string;
  effectiveDate?: string;
}

interface Statutory extends TabParams {
  type: string;
}

export const essEmployeeSlice = apiSlice.injectEndpoints({
  endpoints: builder => ({
    getESSProfile: builder.query<ESSEmployeeProfile, QueryParams>({
      query: args => {
        const { ...query } = args;
        return {
          url: `employee/ess/web/employees/profile`,
          params: query,
        };
      },
    }),
    getESSLatestPayslip: builder.query<ESSEmployeeLatestPayment, any>({
      query: args => {
        const { ...query } = args;
        return {
          url: `/payroll/payroll-run/ess/web/employee/payslip/latest`,
          params: query,
        };
      },
    }),
    getDownloadPayslip: builder.query<
      ESSEmployeeLatestPayment,
      { payrollQueryId: string }
    >({
      query: args => {
        const { payrollQueryId, ...query } = args;
        return {
          url: `/payroll/payroll-run/ess/web/${payrollQueryId}/payslip/employee`,
          params: query,
        };
      },
    }),
    getESSListMonthsByYear: builder.query<
      { label: string; value: string }[],
      { year: any }
    >({
      query: args => {
        const { year } = args;
        return {
          url: `/payroll/payroll-run/ess/web/employee/payslip`,
          params: { year },
        };
      },
    }),
    getESSListYears: builder.query<{ label: string; value: string }[], any>({
      query: args => {
        const { ...query } = args;
        return {
          url: `payroll/payroll-run/ess/web/employee/payslip/year`,
          params: query,
        };
      },
    }),
    getESSCumulative: builder.query<
      { label: string; value: string }[],
      { year: string }
    >({
      query: args => {
        const { year } = args;
        return {
          url: `payroll/payroll-run/ess/web/payslip/cumulative?year=${year}`,
        };
      },
    }),
    getESSProfileHistory: builder.query<
      Array<ESSEmployeeProfileHistory>,
      QueryParams
    >({
      query: args => {
        const { ...query } = args;
        return {
          url: `/employee/ess/web/employees/profile-history`,
          params: query,
        };
      },
    }),
    getESSPersonalData: builder.query<ESSPersonalInfo, TabParams>({
      query: args => {
        const { ...query } = args;
        return {
          url: `/employee/ess/web/employees/personal`,
          params: query,
        };
      },
    }),
    getESSPersonalAddressList: builder.query<
      Array<ESSPersonalAddressInfo>,
      TabParams
    >({
      query: args => {
        const { ...query } = args;
        return {
          url: `/employee/ess/web/employees/address`,
          params: query,
        };
      },
    }),
    getESSPersonalAddressDetail: builder.query<
      ESSPersonalAddressInfo,
      TabParams
    >({
      query: args => {
        const { dataId, ...query } = args;
        return {
          url: `/employee/ess/web/employees/address/${dataId}`,
          params: query,
        };
      },
    }),
    getESSEmploymentData: builder.query<ESSEmployement, TabParams>({
      query: args => {
        const { ...query } = args;
        return {
          url: `/employee/ess/web/employees/employment`,
          params: query,
        };
      },
    }),
    getESSBankList: builder.query<Array<ESSBank>, TabParams>({
      query: args => {
        const { ...query } = args;
        return {
          url: `/employee/ess/web/employees/banks`,
          params: query,
        };
      },
    }),
    getESSBankDetail: builder.query<ESSBank, TabParams>({
      query: args => {
        const { dataId, ...query } = args;
        return {
          url: `/employee/ess/web/employees/banks/${dataId}`,
          params: query,
        };
      },
    }),
    getESSStatutoryList: builder.query<Array<ESSStatutory>, TabParams>({
      query: args => {
        const { ...query } = args;
        return {
          url: `/employee/ess/web/employees/statutories/idn`,
          params: query,
        };
      },
    }),
    getESSStatutoryDetail: builder.query<ESSStatutory, Statutory>({
      query: args => {
        const { dataId, type, ...query } = args;
        return {
          url: `/employee/ess/web/employees/statutories/idn/${dataId}/${type}`,
          params: query,
        };
      },
    }),
    getESSExperienceList: builder.query<Array<ESSExperience>, TabParams>({
      query: args => {
        const { ...query } = args;
        return {
          url: `/employee/ess/web/employees/experiences`,
          params: query,
        };
      },
    }),
    getESSExperienceDetail: builder.query<ESSExperience, TabParams>({
      query: args => {
        const { dataId, ...query } = args;
        return {
          url: `/employee/ess/web/employees/experiences/${dataId}`,
          params: query,
        };
      },
    }),
    getESSEducationList: builder.query<Array<ESSEducation>, TabParams>({
      query: args => {
        const { ...query } = args;
        return {
          url: `/employee/ess/web/employees/educations`,
          params: query,
        };
      },
    }),
    getESSEducationDetail: builder.query<ESSEducation, TabParams>({
      query: args => {
        const { dataId, ...query } = args;
        return {
          url: `/employee/ess/web/employees/educations/${dataId}`,
          params: query,
        };
      },
    }),
    getESSFamilyList: builder.query<Array<ESSFamily>, TabParams>({
      query: args => {
        const { ...query } = args;
        return {
          url: `/employee/ess/web/employees/families`,
          params: query,
        };
      },
    }),
    getESSFamilyDetail: builder.query<ESSFamily, TabParams>({
      query: args => {
        const { dataId, ...query } = args;
        return {
          url: `/employee/ess/web/employees/families/${dataId}`,
          params: query,
        };
      },
    }),
    getESSFileList: builder.query<Array<ESSFile>, TabParams>({
      query: args => {
        const { ...query } = args;
        return {
          url: `/employee/ess/web/employees/files`,
          params: query,
        };
      },
    }),
    getESSFileDetail: builder.query<ESSFile, TabParams>({
      query: args => {
        const { dataId, ...query } = args;
        return {
          url: `/employee/ess/web/employees/files/${dataId}`,
          params: query,
        };
      },
    }),
    getESSDetailWorkLocation: builder.query({
      query: args => {
        const { ...query } = args;
        return {
          url: `/employee/ess/web/employees/work-locations`,
          params: query,
        };
      },
    }),
  }),
});

export const {
  useGetESSCumulativeQuery,
  useGetESSListMonthsByYearQuery,
  useGetESSListYearsQuery,
  useGetESSLatestPayslipQuery,
  useGetESSProfileQuery,
  useGetESSPersonalDataQuery,
  useGetESSPersonalAddressListQuery,
  useGetESSPersonalAddressDetailQuery,
  useGetESSEmploymentDataQuery,
  useGetESSBankListQuery,
  useGetESSBankDetailQuery,
  useGetESSStatutoryListQuery,
  useGetESSExperienceListQuery,
  useGetESSExperienceDetailQuery,
  useGetESSEducationListQuery,
  useGetESSEducationDetailQuery,
  useGetESSFamilyListQuery,
  useGetESSFamilyDetailQuery,
  useGetESSFileListQuery,
  useGetESSFileDetailQuery,
  useGetESSProfileHistoryQuery,
  useGetESSDetailWorkLocationQuery,
  useGetESSStatutoryDetailQuery,
} = essEmployeeSlice;
