import { Box, SxProps, TextFieldProps, useTheme } from '@mui/material';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

import React from 'react';
import { Input } from '..';
import { DatePickerProps } from '../../../@types';
import { Calendar } from '../icon';
import './DatePicker.css';

export const DatePickerForm = React.forwardRef(
  (Props: DatePickerProps & { id?: string }, ref?: any) => {
    const { palette: _pal } = useTheme();
    const { id } = Props;
    const style: SxProps = {
      '& .MuiOutlinedInput-root': {
        paddingRight: '20px',
      },
    };

    return (
      <Box ref={ref}>
        <LocalizationProvider
          className="test-classname"
          dateAdapter={AdapterDateFns}
        >
          <DatePicker
            // inputFormat="yyyy-MM-dd"
            // NOTE: revert to old format
            inputFormat="dd/MM/yyyy"
            disableMaskedInput
            components={{
              OpenPickerIcon: Calendar,
            }}
            renderInput={(params: TextFieldProps) => (
              <Input sx={style} id={id} {...params} />
            )}
            {...Props}
          />
        </LocalizationProvider>
      </Box>
    );
  }
);

export default DatePickerForm;
