import { apiSlice } from '../api/apiSlice';
import {
  WorkflowProps,
  DetailWorkflowProps,
  ListResponse,
} from '../../../@types/workflow';

type argProp = { id: string; body?: DetailWorkflowProps };

export const workflowApiSlice = apiSlice.injectEndpoints({
  endpoints: builder => ({
    getListWorkflow: builder.query<ListResponse<WorkflowProps>, any>({
      query: args => {
        const { search, page, sortType, sort, searchString } = args;
        return {
          url: '/security/workflows',
          params: { search, page, sortType, sort, ...searchString },
        };
      },
      providesTags: ['workflowList'],
    }),
    getDetailWorkflow: builder.query<any, any>({
      query: (args: argProp) => {
        const { id } = args;
        return {
          url: `/security/workflows/${id}`,
        };
      },
      providesTags: ['workflowDetail'],
    }),
    getListEmployeeDynamicGroup: builder.query<any, any>({
      query: args => {
        const { id } = args;
        return {
          url: `/security/dynamic-groups/${id}/employees`,
        };
      },
    }),
    createWorkflow: builder.mutation<DetailWorkflowProps, any>({
      query: (args: argProp) => {
        const { body } = args;
        return {
          method: 'POST',
          url: '/security/workflows',
          body,
        };
      },
      invalidatesTags: ['workflowList'],
    }),
    cancelUpcomingWorkflow: builder.mutation<any, any>({
      query: (args: any) => {
        const { params, id } = args;
        return {
          method: 'DELETE',
          url: `/security/workflows/${id}/cancellation`,
          params,
        };
      },
      invalidatesTags: ['workflowDetail'],
    }),
    deleteWorkflow: builder.mutation<any, any>({
      query: args => {
        const { id } = args;
        return {
          url: `/security/workflow/${id}`,
          method: 'DELETE',
        };
      },
      invalidatesTags: ['workflowDetail'],
    }),
    updateWorkflow: builder.mutation<DetailWorkflowProps, any>({
      query: (args: argProp) => {
        const { body, id } = args;
        return {
          method: 'PUT',
          url: `/security/workflows/${id}`,
          body,
        };
      },
      invalidatesTags: ['workflowDetail'],
    }),
  }),
});

export const {
  useGetListWorkflowQuery,
  useGetDetailWorkflowQuery,
  useGetListEmployeeDynamicGroupQuery,
  useCreateWorkflowMutation,
  useUpdateWorkflowMutation,
  useDeleteWorkflowMutation,
  useCancelUpcomingWorkflowMutation,
} = workflowApiSlice;
