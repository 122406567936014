import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { severancePayApiSlice } from './service/severancePayApiSlice';
import { EarningType, ParamsType } from '../../@types/EmployeeEarning';
import { sortData } from '../../utils/dataDisplay';

type initialStateType = {
  messageSuccess: string;
  messageError: string;
  listPayItem: Array<EarningType>;
  filteredListPayItem: Array<EarningType>;
  updatedPayItem: number;
};

const initialState: initialStateType = {
  messageSuccess: '',
  messageError: '',
  filteredListPayItem: [],
  listPayItem: [],
  updatedPayItem: 0,
};

const severancePaySlice = createSlice({
  name: 'severancePay',
  initialState,
  reducers: {
    removeMessage: state => {
      state.messageSuccess = '';
      state.messageError = '';
    },
    getPayItemList: (state, action: PayloadAction<ParamsType>) => {
      const { search } = action.payload;
      state.filteredListPayItem = state.listPayItem;
      if (search) {
        state.filteredListPayItem = state.filteredListPayItem.filter(item =>
          item.payItem?.label?.toLowerCase().includes(search.toLowerCase())
        );
      }
      state.filteredListPayItem = sortData(
        state.filteredListPayItem,
        action.payload.sortBy,
        action.payload.sortType
      );
    },
    addPayItem: (state, action: PayloadAction<EarningType>) => {
      const indexPayItem = state.listPayItem.findIndex(
        item => item.id === action.payload.id
      );
      if (indexPayItem === -1) {
        state.listPayItem.push(action.payload);
        state.messageSuccess = 'Pay Item added';
      } else {
        state.messageError = 'Error! Pay Item already exist!';
      }
      state.updatedPayItem = new Date().getTime();
    },
    updatePayItem: (
      state,
      action: PayloadAction<{ id: string; data: EarningType }>
    ) => {
      const indexExistingPayItem = state.listPayItem.findIndex(
        item =>
          item.id !== action.payload.id && item.id === action.payload.data.id
      );

      const indexPayItem = state.listPayItem.findIndex(
        item => item.id === action.payload.id
      );

      if (indexExistingPayItem === -1) {
        state.listPayItem[indexPayItem] = action.payload.data;
        state.updatedPayItem = new Date().getTime();
        state.messageSuccess = 'Pay Item edited';
      } else {
        state.messageError = 'Error! Pay Item already exist!';
      }
    },
    deletePayItem: (state, action: PayloadAction<string>) => {
      state.listPayItem = state.listPayItem.filter(
        item => item.id !== action.payload
      );
      console.log('action payload ===>', action.payload);
      state.messageSuccess = 'Pay Item removed';
      state.updatedPayItem = new Date().getTime();
    },
    setPayItem: (state, action: PayloadAction<Array<EarningType>>) => {
      state.listPayItem = action.payload;
      state.filteredListPayItem = action.payload;
    },
    resetPayItem: state => {
      state.listPayItem = [];
      state.filteredListPayItem = [];
    },
  },
  extraReducers(builder) {
    builder.addMatcher(
      severancePayApiSlice.endpoints.createSeverancePayPlan.matchFulfilled,
      (state, _action) => {
        state.messageSuccess = 'Severance Plan created';
      }
    );
    builder.addMatcher(
      severancePayApiSlice.endpoints.createSeverancePayPlan.matchRejected,
      (state, _action) => {
        const payload = _action.payload as any;
        state.messageError = payload.data.message || 'Internal Server Error';
      }
    );
    builder.addMatcher(
      severancePayApiSlice.endpoints.createStep1SeverancePayment.matchRejected,
      (state, action) => {
        state.messageError = 'Internal Server Error';
      }
    );
    builder.addMatcher(
      severancePayApiSlice.endpoints.changeSeverancePayPaymentDetailStatus
        .matchRejected,
      (state, action) => {
        state.messageError = 'Internal Server Error';
      }
    );
  },
});

export const {
  removeMessage,
  addPayItem,
  updatePayItem,
  resetPayItem,
  getPayItemList,
  deletePayItem,
  setPayItem,
} = severancePaySlice.actions;
export const severancePayReducer = severancePaySlice.reducer;
