import { Box, Typography, useTheme } from '@mui/material';
import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { hasSettingUrl } from '../../../../utils/sideBarUtil';

interface ContainerProps {
  children: React.ReactNode;
}

const SidebarContainer = ({ children }: ContainerProps) => {
  const { palette } = useTheme();
  const navigate = useNavigate();

  const handleBack = () => {
    navigate('/');
  };

  return (
    <Box
      sx={{
        height: 'calc(100vh - 56px)',
        background: palette.gray[10],
        borderRight: `1px solid ${palette.gray[50]}`,
        boxSizing: 'border-box',
        overflow: 'auto',
      }}
    >
      {hasSettingUrl() && (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            boxSizing: 'border-box',
            height: '68px',
            paddingX: '24px',
            gap: '8px',
            borderBottom: `1px solid ${palette.gray[40]}`,
            '&:hover': {
              cursor: 'pointer',
            },
          }}
          onClick={handleBack}
        >
          <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M15.8332 10.0001H4.1665M4.1665 10.0001L9.99984 15.8334M4.1665 10.0001L9.99984 4.16675"
              stroke="#475667"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
          <Typography
            variant="body14"
            fontWeight={600}
            color={palette.gray[90]}
          >
            Back
          </Typography>
        </Box>
      )}
      <Box
        sx={{
          paddingTop: '24px',
          paddingX: '12px',
          width: '256px',
        }}
      >
        {children}
        <Typography
          sx={{
            alignSelf: 'flex-end',
            fontSize: '0.5em',
            left: 20,
            color: palette.gray[60],
          }}
        >
          {process.env.REACT_APP_BUILD_ENV}
          {process.env.REACT_APP_BUILD_TIME}
        </Typography>
      </Box>
    </Box>
  );
};

export default SidebarContainer;
