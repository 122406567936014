import { createSlice } from '@reduxjs/toolkit';
import { BankApiSlice } from './service/masterData/bank';

interface initialStateI {
  messageSuccess: string;
  messageError: string;
}

const initialState: initialStateI = {
  messageSuccess: '',
  messageError: '',
};

const bankSlice = createSlice({
  name: 'bankMasterData',
  initialState,
  reducers: {
    removeMessage: state => {
      state.messageSuccess = '';
      state.messageError = '';
    },
  },
  extraReducers(builder) {
    builder.addMatcher(
      BankApiSlice.endpoints.createBankMasterData.matchFulfilled,
      (state, _action) => {
        state.messageSuccess = 'Bank created';
      }
    );
    builder.addMatcher(
      BankApiSlice.endpoints.createBankMasterData.matchRejected,
      (state, action) => {
        const payload = action.payload as any;
        state.messageError = payload?.data?.message;
      }
    );
    builder.addMatcher(
      BankApiSlice.endpoints.updateBankMasterData.matchFulfilled,
      (state, action) => {
        state.messageSuccess = 'Bank updated';
      }
    );
    builder.addMatcher(
      BankApiSlice.endpoints.updateBankMasterData.matchRejected,
      (state, action: any) => {
        const payload = action.payload as any;
        state.messageError = payload?.data?.message;
      }
    );
    builder.addMatcher(
      BankApiSlice.endpoints.deleteBankMasterData.matchFulfilled,
      (state, _action) => {
        state.messageSuccess = 'Bank deleted';
      }
    );
    builder.addMatcher(
      BankApiSlice.endpoints.deleteBankMasterData.matchRejected,
      (state, _action: any) => {
        const payload = _action.payload as any;
        state.messageError = payload?.data?.message;
      }
    );
  },
});

export const { removeMessage } = bankSlice.actions;
export const bankReducer = bankSlice.reducer;
