export const DefaultValueContactUsForm = {
  country: '',
  organization: '',
  modules: [],
  other: '',
  fullName: '',
  jobTitle: '',
  company: '',
  businessEmail: '',
  phoneNumber: '',
};
