import { createSlice } from '@reduxjs/toolkit';
import { OrganizationStructureApiSlice } from './service/organizationStructureSlice';

interface initialStateI {
  messageSuccess: string;
  messageError: string;
}

const initialState: initialStateI = {
  messageSuccess: '',
  messageError: '',
};

const organizationStructureSlice = createSlice({
  name: 'organizationStructure',
  initialState,
  reducers: {
    removeMessage: state => {
      state.messageSuccess = '';
      state.messageError = '';
    },
  },
  extraReducers(builder) {
    builder.addMatcher(
      OrganizationStructureApiSlice.endpoints.createOrganizationStructure
        .matchFulfilled,
      (state: any, _action: any) => {
        state.messageSuccess = 'Organization Created';
      }
    );
    builder.addMatcher(
      OrganizationStructureApiSlice.endpoints.createOrganizationStructure
        .matchRejected,
      (state: any, action: any) => {
        state.messageError = action?.payload?.data?.message;
      }
    );
    builder.addMatcher(
      OrganizationStructureApiSlice.endpoints.deleteOrganizationStructure
        .matchFulfilled,
      (state, _action) => {
        state.messageSuccess = 'Organization deleted';
      }
    );
    builder.addMatcher(
      OrganizationStructureApiSlice.endpoints.deleteOrganizationStructure
        .matchRejected,
      (state, _action) => {
        const payload = _action.payload as any;
        state.messageError = payload?.data?.message;
      }
    );
    builder.addMatcher(
      OrganizationStructureApiSlice.endpoints.cancelUpcomingChangesOrganization
        .matchFulfilled,
      (state: any, action: any) => {
        state.messageSuccess = 'Upcoming Changes cancelled';
      }
    );
  },
});

export const { removeMessage } = organizationStructureSlice.actions;
export const organizationStructureReducer = organizationStructureSlice.reducer;
