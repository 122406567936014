import { createSlice } from '@reduxjs/toolkit';
import { timeEventApiSlice } from './service/timeEventApiSlice';

interface initialStateI {
  messageSuccess: string;
  messageError: string;
}

const initialState: initialStateI = {
  messageSuccess: '',
  messageError: '',
};

const timeEvent = createSlice({
  name: 'timeEvent',
  initialState,
  reducers: {
    removeMessage: state => {
      state.messageSuccess = '';
      state.messageError = '';
    },
  },
  extraReducers(builder) {
    builder.addMatcher(
      timeEventApiSlice.endpoints.createTimeEvent.matchFulfilled,
      (state, _action) => {
        state.messageSuccess = 'Time Event created';
      }
    );
    builder.addMatcher(
      timeEventApiSlice.endpoints.createTimeEvent.matchRejected,
      (state, action) => {
        const payload = action.payload as any;
        state.messageError = payload?.data?.message || 'Internal Server Error';
      }
    );
    builder.addMatcher(
      timeEventApiSlice.endpoints.updateTimeEvent.matchFulfilled,
      (state, _action) => {
        state.messageSuccess = 'Time Event updated';
      }
    );
    builder.addMatcher(
      timeEventApiSlice.endpoints.updateTimeEvent.matchRejected,
      (state, action) => {
        const payload = action.payload as any;
        state.messageError = payload?.data?.message || 'Internal Server Error';
      }
    );
    builder.addMatcher(
      timeEventApiSlice.endpoints.deleteTimeEvent.matchFulfilled,
      (state, _action) => {
        state.messageSuccess = 'Time Event deleted';
      }
    );
    builder.addMatcher(
      timeEventApiSlice.endpoints.deleteTimeEvent.matchRejected,
      (state, action) => {
        const payload = action.payload as any;
        state.messageError = payload?.data?.message || 'Internal Server Error';
      }
    );
  },
});

export const { removeMessage } = timeEvent.actions;
export const timeEventReducer = timeEvent.reducer;
