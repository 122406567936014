import {
  FormControl,
  FormLabel,
  SxProps,
  Typography,
  useTheme,
} from '@mui/material';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { InputProps } from '../../../../@types';
import dayjs from '../../../../utils/dayjs';
import { DatePicker, LoadingSkeleton } from '../../../atom';

export type CustomProps = {
  label: string;
  format?: string;
  isLoading?: boolean;
  value?: any;
  subText?: any;
  customErrorMessage?: any;
};

const DatePickerWithLabel = <UseFieldArrayOptions extends Record<string, any>>(
  Props: InputProps & UseFieldArrayOptions & CustomProps
) => {
  const { palette } = useTheme();
  const { t } = useTranslation();
  const {
    sx,
    name,
    control,
    label,
    onChange,
    format,
    isLoading,
    isDateDisabled = false,
    required = true,
    value,
    subText = '',
    customErrorMessage = null,
    ...props
  } = Props;
  const style = {
    '& .MuiFormLabel-root': {
      marginBottom: '0.5rem',
    },
    '& .input--error .MuiInputBase-root fieldset': {
      borderColor: 'red !important',
      '&:focused': {
        borderColor: 'red !important',
      },
    },
    ...sx,
  } as SxProps;
  return (
    <FormControl sx={style} fullWidth>
      {label !== '' && (
        <FormLabel htmlFor={name}>
          <Typography
            color={palette.gray[100]}
            variant="body14"
            fontWeight={500}
            sx={{
              wordWrap: 'break-word',
              maxWidth: '500px',
            }}
          >
            {label}{' '}
            {!required && (
              <Typography
                component="span"
                variant="body14"
                color={palette.gray[80]}
              >
                (optional)
              </Typography>
            )}
          </Typography>
        </FormLabel>
      )}

      {control && (
        <Controller
          control={control}
          name={name || ''}
          render={({ field, formState }) => {
            const { errors } = formState as any;
            const isError =
              Object.keys(errors).length > 0 &&
              name &&
              Object.prototype.hasOwnProperty.call(errors, name);
            return (
              <>
                {!isLoading && (
                  <DatePicker
                    id={name}
                    className={isError ? 'input--error' : ''}
                    {...field}
                    {...props}
                    onChange={(item: any) => {
                      if (format) {
                        field.onChange(dayjs(item).format(format));
                        onChange?.(dayjs(item).format(format) as any);
                        return;
                      }
                      field.onChange(item);
                      onChange?.(item);
                    }}
                    value={field.value || value || null}
                    pl
                  />
                )}
                {isLoading && (
                  <LoadingSkeleton
                    sx={{
                      '& .react-loading-skeleton': {
                        height: '48px',
                        borderRadius: '8px',
                      },
                    }}
                  />
                )}
                {isError && !customErrorMessage && (
                  <Typography
                    marginTop="8px"
                    component="p"
                    variant="body14"
                    color={palette.red[50]}
                  >
                    {/* {errors?.[name]?.message} */}
                    {t(errors?.[name]?.message)}
                  </Typography>
                )}
                {customErrorMessage && customErrorMessage}
                {subText !== '' && (
                  <Typography
                    marginTop="8px"
                    component="p"
                    variant="body14"
                    color={palette.gray[80]}
                  >
                    {subText}
                  </Typography>
                )}
              </>
            );
          }}
        />
      )}
      {!control && (
        <>
          {!isLoading && (
            <DatePicker
              id={name}
              {...props}
              onChange={(item: any) => {
                if (format) {
                  onChange?.(dayjs(item).format(format) as any);
                  return;
                }
                onChange?.(item);
              }}
              value={value || null}
              pl
            />
          )}
          {isLoading && (
            <LoadingSkeleton
              sx={{
                '& .react-loading-skeleton': {
                  height: '48px',
                  borderRadius: '8px',
                },
              }}
            />
          )}
        </>
      )}
    </FormControl>
  );
};

export default DatePickerWithLabel;
