import React from 'react';
import { RouteNode } from '../../@types';

const OrganizationStructureList = React.lazy(
  () => import('../../pages/organizationStructure/OrganizationStructureList')
);
const OrganizationStructureDetails = React.lazy(
  () => import('../../pages/organizationStructure/OrganizationStructureDetails')
);
const OrganizationStructureCorrection = React.lazy(
  () =>
    import('../../pages/organizationStructure/OrganizationStructureCorrection')
);
const OrganizationStructureCreate = React.lazy(
  () => import('../../pages/organizationStructure/OrganizationStructureCreate')
);
const OrganizationStructureUpdate = React.lazy(
  () => import('../../pages/organizationStructure/OrganizationStructureUpdate')
);

const organizationStructure: RouteNode[] = [
  {
    label: 'Organization Structure',
    path: 'organization-structure',
    component: OrganizationStructureList,
    child: null,
  },
  {
    label: 'child',
    path: 'organization-structure/create',
    component: OrganizationStructureCreate,
    child: null,
  },
  {
    label: '',
    path: 'organization-structure/update/:id',
    component: OrganizationStructureUpdate,
    child: null,
  },
  {
    label: '',
    path: 'organization-structure/correction/:id',
    component: OrganizationStructureCorrection,
    child: null,
  },
  {
    label: '',
    path: 'organization-structure/detail/:id',
    component: OrganizationStructureDetails,
    child: null,
  },
];

export default organizationStructure;
