const ClosedPayroll = () => (
  <svg
    width="48"
    height="48"
    viewBox="0 0 48 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="48" height="48" rx="24" fill="#475667" />
    <path
      d="M21 22.5L23 24.5L27.5 20M32 33V19.8C32 18.1198 32 17.2798 31.673 16.638C31.3854 16.0735 30.9265 15.6146 30.362 15.327C29.7202 15 28.8802 15 27.2 15H20.8C19.1198 15 18.2798 15 17.638 15.327C17.0735 15.6146 16.6146 16.0735 16.327 16.638C16 17.2798 16 18.1198 16 19.8V33L18.75 31L21.25 33L24 31L26.75 33L29.25 31L32 33Z"
      stroke="white"
      strokeWidth="3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default ClosedPayroll;
