const LogoLogin = () => (
  <svg
    width="56"
    height="56"
    viewBox="0 0 56 56"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect x="0.5" y="0.5" width="55" height="55" rx="7.5" fill="white" />
    <path
      d="M28.1138 36.2056C29.7181 34.898 30.9216 33.1811 31.5908 31.245C31.5908 31.245 32.028 30.1879 31.9617 27.7017V18.7018C31.9617 17.3465 32.2633 15.8937 33.0292 14.7746C33.8555 13.6344 35.0105 12.7615 36.3464 12.2678C37.2071 11.9739 38.1344 11.9207 39.0243 12.1142C40.2256 12.3744 41.3249 12.9679 42.1907 13.8239C43.2848 14.942 43.9277 16.4108 44 17.9577L43.8764 27.1673C43.804 28.7263 43.7196 30.4153 43.6864 30.8553C43.5688 32.3789 43.3517 32.559 42.9988 33.1436C42.9988 33.1436 41.1895 35.869 38.6202 37.1623C38.6202 37.1623 36.3916 38.5176 31.9376 37.9241C30.5694 37.5766 29.2749 36.9948 28.1138 36.2056Z"
      fill="url(#paint0_linear_8005_106871)"
    />
    <path
      d="M14.913 25.059C15.2127 25.0471 15.5061 24.9718 15.7731 24.8383V24.8485C19.678 22.9143 21.9805 21.2283 23.2847 20.0486C23.5055 19.8514 23.6799 19.61 23.7961 19.3408C23.9123 19.0716 23.9675 18.7811 23.9579 18.4892C23.9483 18.1972 23.8741 17.9108 23.7405 17.6494C23.6068 17.3881 23.4169 17.1581 23.1836 16.9752C19.9166 14.3828 17.1059 12.9246 15.2494 12.1652C14.9611 12.0439 14.6483 11.9882 14.3348 12.0021C14.0212 12.016 13.7149 12.0992 13.4391 12.2455C13.1632 12.3917 12.925 12.5972 12.7422 12.8464C12.5595 13.0956 12.4371 13.382 12.3842 13.6841C11.5752 18.4106 12.1949 21.7194 12.8561 23.6637C12.9492 23.9421 13.1029 24.1975 13.3068 24.4123C13.5107 24.627 13.7599 24.7961 14.0372 24.9077C14.3145 25.0194 14.6133 25.071 14.913 25.059Z"
      fill="#CC2228"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.6848 28.2371C14.282 28.4405 13.8393 28.5552 13.3873 28.5734C12.9459 28.5913 12.5058 28.5167 12.0959 28.3548L12.1004 28.8002C12.1057 29.6033 12.1885 30.4041 12.3476 31.1919C12.4743 31.8238 12.6281 32.4971 12.7578 32.9636C13.2071 34.5551 14.2294 36.2116 15.7523 38.2165C15.7523 38.2165 19.6394 43.2185 26.4155 43.933C26.4155 43.933 33.273 44.8188 38.3995 40.1535C38.3995 40.1535 42.7661 36.873 43.4778 32.1988C43.4778 32.1988 41.0925 35.7805 38.7041 36.873C38.7041 36.873 34.4823 39.3592 28.9546 36.5246C26.9783 35.2726 25.4793 33.4167 24.6906 31.2451C24.6906 31.2451 23.8733 29.2313 24.0482 24.7284C24.0603 24.4615 24.0562 24.1947 24.0522 23.9322C24.0502 23.8027 24.0482 23.6742 24.0482 23.5473L24.037 22.5843C21.96 24.1745 18.9586 26.114 14.6848 28.2526V28.2371Z"
      fill="url(#paint1_linear_8005_106871)"
    />
    <rect x="0.5" y="0.5" width="55" height="55" rx="7.5" stroke="#DCE0E5" />
    <defs>
      <linearGradient
        id="paint0_linear_8005_106871"
        x1="35.9393"
        y1="13.3721"
        x2="36.1446"
        y2="39.5954"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.2" stopColor="#CB2127" />
        <stop offset="0.37" stopColor="#C82126" />
        <stop offset="0.48" stopColor="#C01F25" />
        <stop offset="0.57" stopColor="#B11D22" />
        <stop offset="0.66" stopColor="#9B191E" />
        <stop offset="0.74" stopColor="#801519" />
        <stop offset="0.81" stopColor="#5E0F12" />
        <stop offset="0.88" stopColor="#37090A" />
        <stop offset="0.95" stopColor="#090202" />
        <stop offset="0.96" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_8005_106871"
        x1="15.2125"
        y1="23.2342"
        x2="39.4992"
        y2="42.8512"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.51" stopColor="#CB2127" />
        <stop offset="0.7" stopColor="#C92127" />
        <stop offset="0.77" stopColor="#C22025" />
        <stop offset="0.82" stopColor="#B71E23" />
        <stop offset="0.86" stopColor="#A61B20" />
        <stop offset="0.89" stopColor="#90171C" />
        <stop offset="0.92" stopColor="#741316" />
        <stop offset="0.95" stopColor="#540E10" />
        <stop offset="0.98" stopColor="#2F0809" />
        <stop offset="1" />
      </linearGradient>
    </defs>
  </svg>
);

export default LogoLogin;
