import { Box, Tooltip, Typography, useTheme } from '@mui/material';
import { useMemo } from 'react';
import { HeaderStatePropsNew } from '../../../@types/HeaderStateProps';
import { useAppDispatch } from '../../../store';
import { setHeaderDate } from '../../../store/feature/utilSlice';
import { newformatDateTimeFromUnixUTC } from '../../../utils/date';
import { Button } from '../../atom';
import { ChevronLeft, ChevronRight, InfoCircle } from '../../atom/icon';
import UpperCaseFirstLetter from '../../../utils/UpperCaseFirstLetter';

interface PropsPreviousNextDateButton {
  headersState?: HeaderStatePropsNew;
  handleClickPrevious?: Function;
  handleClickUpcoming?: Function;
  selectedTab?: string;
  showInfoTooltip?: boolean;
}

function convertString(inputStr: string) {
  const words = inputStr.split('_');

  // Capitalize the first letter of each word
  const capitalizedWords = words.map(
    word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
  );

  // Join the words back with spaces
  const resultStr = capitalizedWords.join(' ');

  return resultStr;
}

const HistoricalTimeLine = ({
  headersState,
  handleClickPrevious,
  handleClickUpcoming,
  selectedTab,
  showInfoTooltip = true,
}: PropsPreviousNextDateButton) => {
  const { palette } = useTheme();
  const dispatch = useAppDispatch();

  const handleGoToPrevious = () => {
    if (selectedTab) {
      dispatch(
        setHeaderDate({
          [selectedTab]: headersState?.previousEffectiveDate || null,
        })
      );
    }
  };

  const handleGoToUpComming = () => {
    if (selectedTab) {
      dispatch(
        setHeaderDate({
          [selectedTab]: headersState?.upcomingEffectiveDate || null,
        })
      );
    }
  };

  const memoizedUtil = useMemo(
    () => convertString(headersState?.typeOfChange || ''),
    [headersState?.typeOfChange]
  );

  return (
    <Box sx={{ display: 'flex', gap: '1rem' }}>
      <Button
        sx={{
          borderRadius: '4x',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          padding: 0,
          minWidth: '0',
          height: '25px',
          background:
            headersState?.previousEffectiveDate === null ? '#F2F4F7' : '',
          '& svg path': {
            stroke: palette.gray[90],
          },
        }}
        variant="outlined"
        disabled={headersState?.previousEffectiveDate === null}
        onClick={() => {
          handleClickPrevious?.();
          handleGoToPrevious();
        }}
      >
        <ChevronLeft />
      </Button>
      <Tooltip
        title={(() => {
          if (
            headersState?.dataPresentationStatus?.toLowerCase() === 'active'
          ) {
            return 'Active Effective Date';
          }
          if (
            headersState?.dataPresentationStatus?.toLowerCase() === 'upcoming'
          ) {
            return 'Upcoming Effective Date';
          }
          return 'Previous Effective Date';
        })()}
        placement="top"
        componentsProps={{
          tooltip: {
            sx: {
              maxWidth: 'none',
              background: 'RGBA(255,255,255,1)',
              color: palette.gray[80],
              boxSizing: 'border-box',
              padding: '12px',
              borderRadius: '8px',
              fontSize: '12px',
              lineHeight: '16px',
              // minWidth: '302px',
              wordBreak: 'keep-all',
              boxShadow:
                '0px 4px 8px -2px rgba(16, 27, 40, 0.1), 0px 2px 4px -2px rgba(16, 27, 40, 0.06)',
              '& .MuiTooltip-arrow': {
                color: 'RGBA(255,255,255,1)',
              },
            },
          },
        }}
        arrow
      >
        <Typography
          component="div"
          sx={{
            boxSizing: 'border-box',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            minWidth: '5rem',
          }}
          variant="body14"
          color={palette.gray[100]}
        >
          {headersState?.effectiveDate
            ? newformatDateTimeFromUnixUTC(headersState?.effectiveDate || null)
            : '-'}
        </Typography>
      </Tooltip>
      <Button
        sx={{
          borderRadius: '4x',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          padding: 0,
          minWidth: '0',
          height: '25px',
          background:
            headersState?.upcomingEffectiveDate === null ? '#F2F4F7' : '',
          '& svg path': {
            stroke: palette.gray[90],
          },
        }}
        variant="outlined"
        disabled={headersState?.upcomingEffectiveDate === null}
        onClick={() => {
          handleClickUpcoming?.();
          handleGoToUpComming();
        }}
      >
        <ChevronRight />
      </Button>
      {showInfoTooltip && (
        <Tooltip
          title={
            <Box>
              <Typography
                variant="body2"
                color={palette.gray[90]}
                fontWeight="700"
              >
                Activity Details
              </Typography>
              <Box sx={{ marginTop: '1rem' }}>
                {headersState && (
                  <Box>
                    <Box display="flex" gap="1rem" alignContent="space-between">
                      <Box width="60px">
                        <Typography variant="body2">Updater</Typography>
                      </Box>
                      <Box>
                        <Typography variant="body2" color={palette.gray[90]}>
                          {headersState?.updater || '-'}
                        </Typography>
                      </Box>
                    </Box>
                    <Box display="flex" gap="1rem" alignContent="space-between">
                      <Box width="60px">
                        <Typography variant="body2">Action</Typography>
                      </Box>
                      <Box>
                        <Typography variant="body2" color={palette.gray[90]}>
                          {memoizedUtil}
                        </Typography>
                      </Box>
                    </Box>
                    <Box display="flex" gap="1rem" alignContent="space-between">
                      <Box width="60px">
                        <Typography variant="body2">Remarks</Typography>
                      </Box>
                      <Box>
                        <Typography variant="body2" color={palette.gray[90]}>
                          {headersState?.reason || '-'}
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                )}
              </Box>
            </Box>
          }
          placement="bottom-start"
          componentsProps={{
            tooltip: {
              sx: {
                background: 'RGBA(255,255,255,1)',
                color: palette.gray[80],
                boxSizing: 'border-box',
                padding: '12px',
                borderRadius: '8px',
                fontSize: '12px',
                lineHeight: '16px',
                minWidth: '302px',
                wordBreak: 'keep-all',
                boxShadow:
                  '0px 4px 8px -2px rgba(16, 27, 40, 0.1), 0px 2px 4px -2px rgba(16, 27, 40, 0.06)',
                '& .MuiTooltip-arrow': {
                  color: 'RGBA(255,255,255,1)',
                },
              },
            },
          }}
          arrow
        >
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              '& svg': {
                '& path': { stroke: palette.gray[80], strokeWidth: 2 },
              },
            }}
          >
            <InfoCircle />
          </Box>
        </Tooltip>
      )}
    </Box>
  );
};

export default HistoricalTimeLine;
