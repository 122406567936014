export function isObject(item: any) {
  return item && typeof item === 'object' && !Array.isArray(item);
}

/* eslint-disable */
export function mergeDeep(target: any, ...sources: any): any {
  if (!sources.length) return target;
  const source = sources.shift();

  if (isObject(target) && isObject(source)) {
    for (const key in source) {
      if (isObject(source[key])) {
        if (!target[key]) Object.assign(target, { [key]: {} });
        mergeDeep(target[key], source[key]);
      } else {
        Object.assign(target, { [key]: source[key] });
      }
    }
  }

  return mergeDeep(target, ...sources);
}

export const flattenMembersChildren: any = (members: Array<any>) => {
  let children: any = [];

  return members
    ?.map(mem => {
      const m = { ...mem }; // use spread operator
      if (m?.children && m?.children?.length) {
        children = [
          ...children,
          ...m.children.map((c: any) => ({
            ...c,
            parentId: m.id,
            parentName: m.name,
          })),
          ,
        ];
      }
      delete m.children; // this will not affect the original array object
      return m;
    })
    .concat(children.length ? flattenMembersChildren(children) : children)
    .filter((c: any) => JSON.stringify(c) !== '{}');
};
