import { apiSlice } from '../api/apiSlice';
import { OvertimeListType } from '../../../@types/OvertimeTypes';
import { QueryParams } from '../../../@types/QueryParams';

export const overtimeItemApiSlice = apiSlice.injectEndpoints({
  endpoints: builder => ({
    getOvertimeItemList: builder.query<any, any>({
      query: params => {
        const { search, page, searchString, sortType, sortBy } = params;
        return {
          url: '/time-management/overtime-items',
          params: { search, page, sortBy, sortType, ...searchString },
        };
      },
      providesTags: ['overtimeItemList'],
    }),
    createOvertimeItem: builder.mutation<void, any>({
      query: payload => ({
        url: '/time-management/overtime-items',
        method: 'POST',
        body: payload,
      }),
      invalidatesTags: ['overtimeItemList'],
    }),
  }),
});

export const { useGetOvertimeItemListQuery, useCreateOvertimeItemMutation } =
  overtimeItemApiSlice;
