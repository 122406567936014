import { Grid } from '@mui/material';
import Skeleton from 'react-loading-skeleton';
import TaskItemLoading from '../../molecule/taskItem/TaskItemLoading';

const TaskOverviewLoading = () => {
  const data = [1, 2, 3];
  return (
    <Grid container direction="column" gap="16px">
      <Grid item sx={{ width: '160px' }}>
        <Skeleton />
      </Grid>
      {data.map(e => (
        <TaskItemLoading key={e} />
      ))}
    </Grid>
  );
};

export default TaskOverviewLoading;
