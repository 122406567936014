const File05 = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.6663 1.89111V5.33323C11.6663 5.79994 11.6663 6.0333 11.7572 6.21156C11.8371 6.36836 11.9645 6.49584 12.1213 6.57574C12.2996 6.66656 12.533 6.66656 12.9997 6.66656H16.4418M11.6663 14.1665H6.66634M13.333 10.8332H6.66634M16.6663 8.32336V14.3332C16.6663 15.7333 16.6663 16.4334 16.3939 16.9681C16.1542 17.4386 15.7717 17.821 15.3013 18.0607C14.7665 18.3332 14.0665 18.3332 12.6663 18.3332H7.33301C5.93288 18.3332 5.23281 18.3332 4.69803 18.0607C4.22763 17.821 3.84517 17.4386 3.60549 16.9681C3.33301 16.4334 3.33301 15.7333 3.33301 14.3332V5.6665C3.33301 4.26637 3.33301 3.56631 3.60549 3.03153C3.84517 2.56112 4.22763 2.17867 4.69803 1.93899C5.23281 1.6665 5.93288 1.6665 7.33301 1.6665H10.0095C10.621 1.6665 10.9267 1.6665 11.2144 1.73558C11.4695 1.79682 11.7134 1.89783 11.9371 2.0349C12.1893 2.18951 12.4055 2.4057 12.8379 2.83808L15.4948 5.49493C15.9271 5.92731 16.1433 6.1435 16.2979 6.39579C16.435 6.61947 16.536 6.86333 16.5973 7.11842C16.6663 7.40614 16.6663 7.71188 16.6663 8.32336Z"
      stroke="#475667"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default File05;
