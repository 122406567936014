import { apiSlice } from '../api/apiSlice';

export const formulaApiSlice = apiSlice.injectEndpoints({
  endpoints: builder => ({
    getListFormula: builder.query<any, any>({
      query: args => ({
        url: `/payroll/formulas`,
        params: args,
      }),
      providesTags: ['formulaList'],
    }),
    getDetailFormula: builder.query<any, any>({
      query: ({ id, effectiveDate }) => ({
        url: `/payroll/formulas/${id}`,
        params: { effectiveDate },
      }),
      providesTags: ['formulaDetail'],
    }),
    getFormulaVariables: builder.query<any, any>({
      query: () => ({
        url: `/payroll/formulas/variables`,
      }),
      providesTags: ['formulaList'],
    }),
    createFormula: builder.mutation<any, any>({
      query: payload => ({
        url: `/payroll/formulas`,
        method: 'POST',
        body: payload,
      }),
      invalidatesTags: ['formulaList'],
    }),
    updateFormula: builder.mutation<any, any>({
      query: ({ id, payload }) => ({
        url: `/payroll/formulas/${id}`,
        method: 'PUT',
        body: payload,
      }),
      invalidatesTags: ['formulaDetail'],
    }),
    deleteFormula: builder.mutation<any, any>({
      query: id => ({
        url: `/payroll/formulas/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['formulaList'],
    }),
    cancelUpcomingUpdate: builder.mutation<any, any>({
      query: newId => ({
        url: `/payroll/formulas/${newId}/cancellation`,
        method: 'DELETE',
      }),
      invalidatesTags: ['formulaList', 'formulaDetail'],
    }),
  }),
});

export const {
  useCreateFormulaMutation,
  useDeleteFormulaMutation,
  useGetDetailFormulaQuery,
  useGetListFormulaQuery,
  useUpdateFormulaMutation,
  useCancelUpcomingUpdateMutation,
  useGetFormulaVariablesQuery,
} = formulaApiSlice;
