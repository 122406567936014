const FileAtc04 = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="file-attachment-04">
      <path
        id="Icon"
        d="M16.6673 5.83317V5.6665C16.6673 4.26637 16.6673 3.56631 16.3948 3.03153C16.1552 2.56112 15.7727 2.17867 15.3023 1.93899C14.7675 1.6665 14.0674 1.6665 12.6673 1.6665H7.33398C5.93385 1.6665 5.23379 1.6665 4.69901 1.93899C4.2286 2.17867 3.84615 2.56112 3.60647 3.03153C3.33398 3.56631 3.33398 4.26637 3.33398 5.6665V14.3332C3.33398 15.7333 3.33398 16.4334 3.60647 16.9681C3.84615 17.4386 4.2286 17.821 4.69901 18.0607C5.23379 18.3332 5.93385 18.3332 7.33398 18.3332H10.4173M10.4173 9.1665H6.66732M9.58398 12.4998H6.66732M13.334 5.83317H6.66732M15.0007 14.9998V10.4165C15.0007 9.72615 15.5603 9.1665 16.2507 9.1665C16.941 9.1665 17.5007 9.72615 17.5007 10.4165V14.9998C17.5007 16.3805 16.3814 17.4998 15.0007 17.4998C13.6199 17.4998 12.5007 16.3806 12.5007 14.9998V11.6665"
        stroke="#8A98A8"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
  </svg>
);

export default FileAtc04;
