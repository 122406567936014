const headerKey = {
  unit: 'Unit',
  description: 'Description',
};

const detailKey: { [key: string]: any } = {
  effectiveDate: 'Effective Date',
  unit: 'Unit',
  description: 'Description',
};

const defaultValueForm = {
  unit: null,
  description: null,
  effectiveDate: null,
};

const defaultValueUpdateForm = {
  unit: '',
  description: '',
  effectiveDate: '',
  id: '',
};

export default {
  headerKey,
  detailKey,
  defaultValueForm,
  defaultValueUpdateForm,
};
