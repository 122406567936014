import { apiSlice } from '../api/apiSlice';
import { SummaryDataType } from '../../../@types/SeverancePay';

export const severancePayApiSlice = apiSlice.injectEndpoints({
  endpoints: builder => ({
    getSeverancePayPlanList: builder.query<any, any>({
      query: params => {
        const { search, page, sortType, sort, searchString } = params;
        return {
          url: '/payroll/payroll-severance-plans',
          params: { search, page, sortType, sort, ...searchString },
        };
      },
      providesTags: ['severancePayPlanList'],
    }),
    getSeverancePayPlanDetail: builder.query<any, any>({
      query: id => ({
        url: `/payroll/payroll-severance-plans/${id}`,
      }),
      providesTags: ['severancePayPlanDetail'],
    }),
    createSeverancePayPlan: builder.mutation<any, any>({
      query: payload => ({
        url: '/payroll/payroll-severance-plans',
        method: 'POST',
        body: payload,
      }),
      invalidatesTags: ['severancePayPlanList'],
    }),
    getSeverancePayPaymentList: builder.query<any, any>({
      query: params => {
        const { search, page, sortType, sort, searchString } = params;
        return {
          url: '/payroll/payroll-severance-payments',
          params: { search, page, sortType, sort, ...searchString },
        };
      },
      providesTags: ['severancePayPaymentList'],
    }),
    changeSeverancePayPaymentDetailStatus: builder.mutation<any, any>({
      query: ({ detailId, payload }) => ({
        url: `/payroll/payroll-severance-payments/${detailId}/change-status`,
        method: 'POST',
        body: payload,
      }),
      invalidatesTags: [
        'severancePayPaymentDetailStatus',
        'severancePayPaymentList',
        'severancePayPaymentSummary',
        'listEmployeePaymentsSeverancePay',
      ],
    }),
    createStep1SeverancePayment: builder.mutation<any, any>({
      query: ({ id, payload }) => ({
        url: `/payroll/payroll-severance-payments/${id}/setup`,
        method: 'POST',
        body: payload,
      }),
      invalidatesTags: ['severancePayPaymentList'],
    }),
    getEmployeePaymentsStepComplete: builder.query<any, any>({
      query: params => {
        const { detailId, search, page, sortType, sort, searchString } = params;
        return {
          url: `/payroll/payroll-severance-payments/${detailId}/employees`,
          params: { search, page, sortType, sort, ...searchString },
        };
      },
      providesTags: ['listEmployeePaymentsSeverancePay'],
    }),
    getListExpenseBreakdownStepComplete: builder.query<any, any>({
      query: params => {
        const { detailId, search, page, sortType, sort, searchString } = params;
        return {
          url: `/payroll/payroll-severance-payments/${detailId}/expenses-breakdown`,
          params: { search, page, sortType, sort, ...searchString },
        };
      },
    }),
    getSummary: builder.query<SummaryDataType, { detailId: string }>({
      query: ({ detailId }) => ({
        url: `/payroll/payroll-severance-payments/${detailId}/summary`,
      }),
      providesTags: ['severancePayPaymentSummary'],
    }),
    getStep1TotalEmployees: builder.query<any, any>({
      query: ({ periodName, legalEntityId }) => ({
        url: `/payroll/payroll-severance-payments/pay-period/${periodName}/total-employee`,
        params: { legalEntityId },
      }),
    }),
  }),
});

export const {
  useGetSeverancePayPlanListQuery,
  useGetSeverancePayPlanDetailQuery,
  useCreateSeverancePayPlanMutation,
  useGetSeverancePayPaymentListQuery,
  useChangeSeverancePayPaymentDetailStatusMutation,
  useCreateStep1SeverancePaymentMutation,
  useGetEmployeePaymentsStepCompleteQuery,
  useGetListExpenseBreakdownStepCompleteQuery,
  useGetSummaryQuery,
  useGetStep1TotalEmployeesQuery,
} = severancePayApiSlice;
