import { SvgIconProps } from '@mui/material';
import { FC } from 'react';
import { SvgIcon } from './SvgIcon';

const CheckCircle: FC<SvgIconProps> = props => (
  <SvgIcon {...props}>
    <g clipPath="url(#clip0_8696_167706)">
      <path d="M6.24984 9.99996L8.74984 12.5L13.7498 7.49996M18.3332 9.99996C18.3332 14.6023 14.6022 18.3333 9.99984 18.3333C5.39746 18.3333 1.6665 14.6023 1.6665 9.99996C1.6665 5.39759 5.39746 1.66663 9.99984 1.66663C14.6022 1.66663 18.3332 5.39759 18.3332 9.99996Z" />
    </g>
    <defs>
      <clipPath id="clip0_8696_167706">
        <rect width="20" height="20" fill="white" />
      </clipPath>
    </defs>
  </SvgIcon>
);

export default CheckCircle;
