import {
  Box,
  ListItemButton,
  ListItemButtonBaseProps,
  useTheme,
  Typography,
  SxProps,
} from '@mui/material';
import { NavLink } from 'react-router-dom';
import Color from 'color';
import React from 'react';

import { ChevronRight } from '../icon';

interface NavMenuProps extends ListItemButtonBaseProps {
  label: string;
  isActive: boolean;
  onClick?: Function;
  depth: number;
  icon: React.ReactNode;
  isParent: boolean;
  isExpended: boolean;
  path: string;
}

const NavMenu = (Props: NavMenuProps) => {
  const {
    label,
    isActive,
    onClick,
    depth,
    icon: ComponentIcon,
    isParent,
    isExpended,
    path,
    ...props
  } = Props;
  const { palette } = useTheme();

  const style: SxProps = {
    display: 'flex',
    justifyContent: 'space-between',
    height: '2.75rem',
    borderRadius: '8px',
    padding: isParent ? '12px 16px' : 0,
    boxSizing: 'border-box',
    background: isActive
      ? palette.blue[20]
      : Color(palette.gray[0]).alpha(0).toString(),
    ...(isActive &&
      isParent &&
      isExpended && {
        background: Color(palette.gray[0]).alpha(0).toString(),
      }),
    ...(isActive &&
      isParent &&
      !isExpended && {
        background: palette.blue[20],
      }),
    ...(isParent &&
      isExpended && {
        marginBottom: '4px',
      }),
    '&:hover': {
      background: palette.gray[20],
    },
    '& .button-body': {
      display: 'flex',
      '& .button-icon': {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        '& svg path': {
          stroke: isActive ? palette.blue[50] : palette.gray[90],
        },
        ...(ComponentIcon ? { marginRight: '12px' } : {}),
      },
      '& .button-label': {
        paddingLeft: `${depth * 28}px`,
        color: isActive ? palette.blue[50] : palette.gray[90],
      },
    },
    '& .icon-container': {
      transition: ' all .2s linear',
      ...(isExpended && { transform: 'rotate(90deg)' }),
      '& svg path': {
        stroke: isActive ? palette.blue[50] : palette.gray[90],
      },
    },
    '& a': {
      display: 'block',
      padding: '12px 16px',
      textDecoration: 'none',
      width: '100%',
    },
  };

  return (
    <ListItemButton sx={style} onClick={() => onClick?.()} {...props}>
      {isParent ? (
        <>
          <Box className="button-body">
            {ComponentIcon && (
              <Box className="button-icon">{ComponentIcon}</Box>
            )}
            <Typography
              className="button-label"
              component="div"
              variant="body14"
            >
              {label}
            </Typography>
          </Box>
          {isParent && (
            <Box className="icon-container">
              <ChevronRight />
            </Box>
          )}
        </>
      ) : (
        <NavLink to={path} end>
          <Box className="button-body">
            {ComponentIcon && (
              <Box className="button-icon">{ComponentIcon}</Box>
            )}
            <Typography
              className="button-label"
              component="div"
              variant="body14"
            >
              {label}
            </Typography>
          </Box>
          {isParent && (
            <Box className="icon-container">
              <ChevronRight />
            </Box>
          )}
        </NavLink>
      )}
    </ListItemButton>
  );
};

export default NavMenu;
