import { QueryParams } from '../../../@types/QueryParams';
import { apiSlice } from '../api/apiSlice';
import { ListResponse } from '../../../@types/ListResponseProps';
import {
  UserAccessDetailType,
  UserAccessType,
} from '../../../@types/UserAccessType';

type RequestParameterType = {
  id: string;
  effectiveDate?: string;
  sortType?: string;
  sortBy?: string;
  search?: string;
};

export const userAccessApiSlice = apiSlice.injectEndpoints({
  endpoints: builder => ({
    getUserAccessList: builder.query<ListResponse<UserAccessType>, QueryParams>(
      {
        query: params => {
          const { search, page, sortType, sort, searchString } = params;
          return {
            url: `/organization/user-accesses`,
            params: { search, page, sortType, sort, ...searchString },
          };
        },
        providesTags: ['UserAccessList'],
      }
    ),
    getUserAccessData: builder.query<any, any>({
      query: params => ({
        url: '/security/user-access-data',
        params,
      }),
    }),
    getUserAccessFunction: builder.query<any, any>({
      query: params => {
        const { search } = params;
        return {
          url: '/security/user-access-functions',
          params: search,
        };
      },
    }),
    createUserAccess: builder.mutation<void, any>({
      query: body => ({
        url: '/organization/user-accesses',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['UserAccessList'],
    }),
    getUserAccessDetail: builder.query<
      UserAccessDetailType,
      RequestParameterType
    >({
      query: params => {
        const { id } = params;
        return {
          url: `/organization/user-accesses/${id}`,
        };
      },
      providesTags: ['UserAccessDetail'],
    }),
    deleteUserAccess: builder.mutation<void, { id: string }>({
      query: ({ id }) => ({
        url: `/organization/user-accesses/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['UserAccessList'],
    }),
    updateUserAccess: builder.mutation({
      query: ({ id, payload }) => ({
        url: `/organization/user-accesses/${id}`,
        method: 'PUT',
        body: payload,
      }),
      invalidatesTags: ['UserAccessList', 'UserAccessDetail'],
    }),
    getUserAccessesSpecificFunction: builder.query({
      query: () => ({
        url: '/organization/user-accesses/specific-function-accesses',
      }),
    }),
  }),
});

export const {
  useGetUserAccessDataQuery,
  useGetUserAccessFunctionQuery,
  useGetUserAccessListQuery,
  useGetUserAccessDetailQuery,
  useCreateUserAccessMutation,
  useDeleteUserAccessMutation,
  useUpdateUserAccessMutation,
  useGetUserAccessesSpecificFunctionQuery,
} = userAccessApiSlice;
