import { createTheme } from '@mui/material/styles';
import React from 'react';

declare module '@mui/material/styles' {
  interface TypographyVariants {
    label: React.CSSProperties;
    subHeading: React.CSSProperties;
    caption: React.CSSProperties;
    overline: React.CSSProperties;
    body14: React.CSSProperties;
    body14Medium: React.CSSProperties;
    body16: React.CSSProperties;
    body16Bold: React.CSSProperties;
    body18: React.CSSProperties;
    body12: React.CSSProperties;
    caption8: React.CSSProperties;
    caption10: React.CSSProperties;
    caption12: React.CSSProperties;
    heading: React.CSSProperties;
    paragraph12: React.CSSProperties;
    paragraph14: React.CSSProperties;
    paragraph16: React.CSSProperties;
    buttonLarge: React.CSSProperties;
    buttonSmall: React.CSSProperties;
    subtitle16: React.CSSProperties;
    subtitle18: React.CSSProperties;
    h2: React.CSSProperties;
    h5: React.CSSProperties;
  }

  // allow configuration using `createTheme`
  interface TypographyVariantsOptions {
    label: React.CSSProperties;
    subHeading: React.CSSProperties;
    caption: React.CSSProperties;
    overline: React.CSSProperties;
    body14: React.CSSProperties;
    body14Medium: React.CSSProperties;
    body16: React.CSSProperties;
    body16Bold: React.CSSProperties;
    body18: React.CSSProperties;
    body12: React.CSSProperties;
    caption8: React.CSSProperties;
    caption10: React.CSSProperties;
    caption12: React.CSSProperties;
    heading: React.CSSProperties;
    paragraph12: React.CSSProperties;
    paragraph14: React.CSSProperties;
    paragraph16: React.CSSProperties;
    buttonLarge: React.CSSProperties;
    buttonSmall: React.CSSProperties;
    subtitle16: React.CSSProperties;
    subtitle18: React.CSSProperties;
    h2: React.CSSProperties;
    h5: React.CSSProperties;
  }
}
// Update the Typography's variant prop options
declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    label: true;
    subHeading: true;
    caption: true;
    overline: true;
    body14: true;
    body14Medium: true;
    body16: true;
    body16Bold: true;
    body18: true;
    body12: true;
    caption8: true;
    caption10: true;
    caption12: true;
    heading: true;
    paragraph12: true;
    paragraph14: true;
    paragraph16: true;
    buttonLarge: true;
    buttonSmall: true;
    subtitle16: true;
    subtitle18: true;
    h2: true;
    h5: true;
  }
}

declare module '@mui/material/styles' {
  interface Palette {
    blue: Record<90 | 70 | 50 | 30 | 20 | 10, string>;
    gray: Record<100 | 90 | 80 | 60 | 50 | 40 | 20 | 10 | 0, string>;
    green: Record<70 | 60 | 50 | 30 | 20 | 10, string>;
    orange: Record<70 | 60 | 50 | 30 | 20 | 10, string>;
    red: Record<70 | 60 | 50 | 30 | 20 | 10, string>;
  }

  // allow configuration using `createTheme`
  interface PaletteOptions {
    blue: Record<90 | 70 | 50 | 30 | 20 | 10, string>;
    gray: Record<100 | 90 | 80 | 60 | 50 | 40 | 20 | 10 | 0, string>;
    green: Record<70 | 60 | 50 | 30 | 20 | 10, string>;
    orange: Record<70 | 60 | 50 | 30 | 20 | 10, string>;
    red: Record<70 | 60 | 50 | 30 | 20 | 10, string>;
  }
}

// Update the Button's color prop options
declare module '@mui/material/Button' {
  interface ButtonPropsColorOverrides {
    neutral: true;
    gray: true;
    white: true;
  }
}

const blue = {
  90: '#002D61',
  70: '#124987',
  50: '#045FC4',
  30: '#C9E2FF',
  20: '#EDF5FF',
  10: '#F5FAFF',
};

const gray = {
  100: '#101B28',
  90: '#475667',
  // 80: '#98A5B3',
  80: '#8A98A8',
  60: '#CDD3DB',
  50: '#DCE0E5',
  40: '#EAEDF0',
  20: '#F2F4F7',
  10: '#F9FAFB',
  0: '#FFFFFF',
};

const green = {
  70: '#027A48',
  60: '#039855',
  50: '#12B76A',
  30: '#A6F4C5',
  20: '#ECFDF3',
  10: '#F5FFF9',
};

const orange = {
  70: '#B54708',
  60: '#DC6803',
  50: '#F79009',
  30: '#FEDF89',
  20: '#FFFAEB',
  10: '#FFFCF5',
};

const red = {
  70: '#B42318',
  60: '#D92D20',
  50: '#F04438',
  30: '#FFCCC9',
  20: '#FEEEEC',
  10: '#FFF6F5',
};

export default createTheme({
  palette: {
    blue,
    gray,
    green,
    orange,
    red,
  },
  typography: {
    button: {
      textTransform: 'none',
      cursor: 'pointer',
    },
    fontFamily: [
      'Inter',
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    label: {
      fontSize: '0.625rem',
      color: '#B6B6B6',
    },
    body16Bold: {
      fontSize: '16px',
      fontWeight: 700,
      color: '#475667',
      lineHeight: '24px',
    },
    body14: {
      fontSize: '0.875rem',
      color: '#101B28',
      lineHeight: '20px',
    },
    body14Medium: {
      fontSize: '14px',
      color: '#101B28',
      lineHeight: '20px',
      fontWeight: 500,
    },
    body12: {
      fontSize: '0.75rem',
      color: '#000000',
    },
    caption10: {
      fontSize: '0.625rem',
      color: '#000000',
    },
    caption12: {
      fontSize: '12px',
      lineHeight: '18px',
      letterSpacing: '0.4px',
    },
    heading: {
      fontSize: '1.5rem',
      color: '#000000',
      fontWeight: 'bold',
    },
    subHeading: {
      fontSize: '1rem',
      color: '#000000',
      fontWeight: 400,
    },
    caption8: {
      fontSize: '0.5rem',
      color: '#000000',
    },
    h1: {
      fontWeight: '400',
      fontSize: '3.75rem',
      letterSpacing: '-1.5px',
      lineHeight: '4.5rem',
    },
    h2: {
      fontWeight: '400',
      fontSize: '3rem',
      letterSpacing: '-0.5px',
      lineHeight: '3.25rem',
    },
    h3: {
      fontWeight: '400',
      fontSize: '2rem',
      letterSpacing: '0',
      lineHeight: '2.5rem',
    },
    h4: {
      fontWeight: '400',
      fontSize: '1.5rem',
      letterSpacing: '0.25px',
      lineHeight: '2rem',
    },
    h5: {
      fontWeight: '400',
      fontSize: '1.25rem',
      letterSpacing: '0.15px',
      lineHeight: '1.75rem',
    },
    subtitle1: {
      fontWeight: '400',
      fontSize: '1.125rem',
      letterSpacing: '0.15px',
      lineHeight: '1.5rem',
    },
    subtitle2: {
      fontWeight: '400',
      fontSize: '1rem',
      letterSpacing: '0.15px',
      lineHeight: '1.5rem',
    },
    subtitle16: {
      fontWeight: '400',
      fontSize: '1rem',
      letterSpacing: '0.15px',
      lineHeight: '1.375rem',
    },
    subtitle18: {
      fontWeight: '600',
      fontSize: '1.125rem',
      letterSpacing: '0.15px',
      lineHeight: '1.5rem',
    },
    body1: {
      fontWeight: '400',
      fontSize: '1rem',
      letterSpacing: '0.15px',
      lineHeight: '1.5rem',
    },
    body2: {
      fontWeight: '400',
      fontSize: '0.875rem',
      letterSpacing: '0.15px',
      lineHeight: '1.25rem',
    },
    body18: {
      fontWeight: '400',
      fontSize: '18px',
      // letterSpacing: '0.15px',
      lineHeight: '28px',
    },
    buttonLarge: {
      fontWeight: '600',
      fontSize: '0.875rem',
      letterSpacing: '0.4px',
      lineHeight: '1.5rem',
    },
    buttonSmall: {
      fontWeight: '600',
      fontSize: '0.75rem',
      letterSpacing: '0.46px',
      lineHeight: '1.25rem',
    },
    caption: {
      fontWeight: '400',
      fontSize: '0.75rem',
      letterSpacing: '0.4px',
      lineHeight: '1.125rem',
    },
    overline: {
      fontWeight: '400',
      fontSize: '0.75rem',
      letterSpacing: '1px',
      lineHeight: '1rem',
    },
    body16: {
      fontWeight: '400',
      fontSize: '1rem',
      letterSpacing: '0.15px',
      lineHeight: '1.5rem',
    },
    paragraph12: {
      fontWeight: '400',
      fontSize: '12px',
      letterSpacing: '0.15px',
      lineHeight: '28px',
    },
    paragraph16: {
      fontWeight: '400',
      fontSize: '16px',
      letterSpacing: '0.15px',
      lineHeight: '28px',
    },
    paragraph14: {
      fontWeight: '400',
      fontSize: '14px',
      letterSpacing: '0.15px',
      lineHeight: '24px',
    },
  },
  components: {
    MuiMenuItem: {
      styleOverrides: {
        root: {
          '&:hover': {
            backgroundColor: '#E3F3FF',
            borderRadius: 4,
          },
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          boxShadow: 'none',
          fontSize: '0.875rem',
          fontWeight: '400',
          '&:hover': {
            boxShadow: 'none',
          },
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          boxShadow: 'none',
          border: '1px solid #f4f4f4',
        },
      },
    },
    MuiLinearProgress: {
      styleOverrides: {
        root: {
          backgroundColor: '#E0E0E0',
        },
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          '&.Mui-checked': {
            color: '#045FC4',
          },
        },
      },
    },
  },
});

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
export const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};
