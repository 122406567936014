import { useLocation } from 'react-router-dom';
import { Box, SxProps, useTheme } from '@mui/material';
import { mergeDeep } from '../../../utils/deepMerge';
import { EmployeeProfile } from '../components';
import { VerticalTabsButton } from '../../../components/molecule';
import { useAppDispatch, useAppSelector } from '../../../store';
import { setTab } from '../../../store/feature/utilSlice';

type EmployeeNavigatorProps = {
  data: any;
  sx?: SxProps;
  className?: string;
  tabs: any;
};

const EmployeeNavigator = ({
  data,
  tabs,
  sx,
  className,
}: EmployeeNavigatorProps) => {
  const dispatch = useAppDispatch();
  const { palette } = useTheme();
  const location = useLocation();

  const style: SxProps = {
    border: `1px solid ${palette.gray[50]}`,
    borderRadius: '1rem',
    padding: '1rem',
    '& .top-container': {
      paddingBottom: '1rem',
      borderBottom: `1px solid ${palette.gray[50]}`,
    },
    '& .bottom-container': {
      paddingTop: '1rem',
    },
  };

  const { selectedTab } = useAppSelector(state => state.utils);

  const handleChangeValue = (index: number) => {
    dispatch(setTab({ [location.pathname]: index }));
  };
  return (
    <Box sx={mergeDeep(style, sx)} className={className}>
      <Box className="top-container">
        <EmployeeProfile data={data} />
      </Box>
      <Box className="bottom-container">
        <VerticalTabsButton
          value={selectedTab[location.pathname] || 0}
          setValue={handleChangeValue}
          tabs={tabs}
        />
      </Box>
    </Box>
  );
};

export default EmployeeNavigator;
