import { createSlice } from '@reduxjs/toolkit';
import { OrganizationStructureApiSlice } from './service/organizationStructureSlice';

type initialStateI = {
  messageSuccess: string;
  messageError: string;
};

const initialState: initialStateI = {
  messageSuccess: '',
  messageError: '',
};

const organizatioStructureUpdateSlice = createSlice({
  name: 'organizationStructureUpdate',
  initialState,
  reducers: {
    removeMessageError: (state: initialStateI) => {
      state.messageError = '';
    },
    removeMessageSuccesss: state => {
      state.messageSuccess = '';
    },
  },
  extraReducers(builder: any) {
    builder.addMatcher(
      OrganizationStructureApiSlice.endpoints.editOrganizationStructure
        .matchFulfilled,
      (state: initialStateI, action: any) => {
        if (action?.payload?.typeOfChange === 'CORRECTED') {
          state.messageSuccess = 'Organization Structure corrected';
        }
        if (action?.payload?.typeOfChange === 'UPDATED') {
          state.messageSuccess = 'Organization Structure updated';
        }
      }
    );
    builder.addMatcher(
      OrganizationStructureApiSlice.endpoints.editOrganizationStructure
        .matchRejected,
      (state: initialStateI, action: any) => {
        state.messageError = 'Incorrect Form filled';
      }
    );
    builder.addMatcher(
      OrganizationStructureApiSlice.endpoints.cancelUpcomingChangesOrganization
        .matchFulfilled,
      (state: any, action: any) => {
        state.messageSuccess = 'Upcoming Changes cancelled';
      }
    );
  },
});

export const { removeMessageError, removeMessageSuccesss } =
  organizatioStructureUpdateSlice.actions;
export const organizationStructureUpdateReducer =
  organizatioStructureUpdateSlice.reducer;
