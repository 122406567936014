import React from 'react';

const List = React.lazy(() => import('../../../pages/holiday/List'));
const Create = React.lazy(() => import('../../../pages/holiday/Create'));
const Detail = React.lazy(() => import('../../../pages/holiday/Detail'));

export const holiday = [
  {
    label: 'Holiday',
    child: null,
    path: 'holiday',
    component: List,
  },
  {
    label: '',
    child: null,
    path: 'holiday/create',
    component: Create,
  },
  {
    label: '',
    child: null,
    path: 'holiday/detail/:id',
    component: Detail,
  },
];
