import { Box, SxProps, Typography, useTheme } from '@mui/material';
import { useEffect, useState } from 'react';
import useDownloader from 'react-use-downloader';
import { Button } from '../../../components/atom';
import {
  useGetESSLatestPayslipQuery,
  essEmployeeSlice,
} from '../../../store/feature/service/essEmployee';
import Toast from '../../../components/atom/toast/Toast';

type PayslipsProps = {
  date?: string | number;
  id?: string;
  sx?: SxProps;
  isLatest?: boolean;
};

const Payslips = ({ date, id, sx, isLatest = false }: PayslipsProps) => {
  const { palette } = useTheme();
  const style: SxProps = {
    border: `1px solid ${palette.gray[50]}`,
    borderRadius: '1rem',
    p: '20px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    '& .payslip-month': {
      fontWeight: 600,
    },
    '& .action-button': {
      display: 'flex',
      alignItems: 'center',
      gap: '1rem',
    },
    ...sx,
  };
  const { data } = useGetESSLatestPayslipQuery({});
  const [isError, setError] = useState(false);
  const [trigger, { isLoading }] =
    essEmployeeSlice.endpoints.getDownloadPayslip.useLazyQuery({});
  const onClickView = async () => {
    try {
      await trigger({
        payrollQueryId: id || data?.latestPayslip?.id || '',
      }).then((val: any) => {
        val?.status === 'rejected' && setError(true);
        if (val?.status === 'fulfilled') {
          const newLink = document.createElement('a');
          newLink.setAttribute('href', val?.data?.fileUrl);
          newLink.setAttribute('target', '_blank');
          newLink.setAttribute('title', val?.data?.fileName);
          newLink.click();
          newLink.remove();
        }
      });
    } catch (error) {
      console.log(error);
    }
  };

  const { download: downloading, isInProgress } = useDownloader();
  const [isDownloaded, setDownloaded] = useState(false);

  const onClickDownload = async () => {
    try {
      await trigger({
        payrollQueryId: id || data?.latestPayslip?.id || '',
      }).then((val: any) => {
        val?.status === 'rejected' && setError(true);
        if (val?.status === 'fulfilled') {
          setTimeout(() => setDownloaded(true), 500);
          downloading(val?.data?.fileUrl, val?.data?.fileName);
        }
      });
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Box sx={style}>
      <Toast
        open={isError}
        variant="error"
        message="Internal Server Error"
        onClose={() => setError(false)}
      />
      <Toast
        open={isDownloaded}
        variant="success"
        message="Payslip Downloaded"
        onClose={() => setDownloaded(false)}
      />
      <Typography
        className="payslip-month"
        variant="body16"
        color={palette.gray[100]}
      >
        {date}
      </Typography>
      <Box className="action-button">
        <Button
          disabled={isLoading}
          variant={isLatest ? 'secondary' : 'link'}
          size="small"
          onClick={() => onClickView()}
        >
          View
        </Button>
        <Button
          variant={isLatest ? 'primary' : 'link'}
          size="small"
          disabled={isInProgress}
          onClick={() => onClickDownload()}
        >
          Download
        </Button>
      </Box>
    </Box>
  );
};

export default Payslips;
