import { createSlice } from '@reduxjs/toolkit';
import { dailyAttendanceApiSlice } from './service/dailyAttendanceApiSlice';

interface initialStateI {
  messageSuccess: string;
  messageError: string;
}

const initialState: initialStateI = {
  messageSuccess: '',
  messageError: '',
};

const dailyAttendanceSlice = createSlice({
  name: 'dailyAttendance',
  initialState,
  reducers: {
    removeMessage: state => {
      state.messageSuccess = '';
      state.messageError = '';
    },
  },
  extraReducers(builder) {
    builder.addMatcher(
      dailyAttendanceApiSlice.endpoints.editDailyAttendance.matchFulfilled,
      (state, _action) => {
        state.messageSuccess = 'Attendance edited';
      }
    );
    builder.addMatcher(
      dailyAttendanceApiSlice.endpoints.editDailyAttendance.matchRejected,
      (state, action) => {
        const payload = action.payload as any;
        state.messageError = payload?.data?.message || 'Internal Server Error';
      }
    );
    builder.addMatcher(
      dailyAttendanceApiSlice.endpoints.deleteShiftDailyAttendance
        .matchFulfilled,
      (state, _action) => {
        state.messageSuccess = 'Attendance Record deleted.';
      }
    );
    builder.addMatcher(
      dailyAttendanceApiSlice.endpoints.deleteShiftDailyAttendance
        .matchRejected,
      (state, action) => {
        const payload = action.payload as any;
        state.messageError = payload?.data?.message || 'Internal Server Error';
      }
    );
  },
});

export const { removeMessage } = dailyAttendanceSlice.actions;
export const dailyAttendanceReducer = dailyAttendanceSlice.reducer;
