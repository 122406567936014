import { TaskOverviewDataType } from '../../../@types/HomeTypes';
import { QueryParams } from '../../../@types/QueryParams';
import { apiSlice } from '../api/apiSlice';

export const essTimeOffApiSlice = apiSlice.injectEndpoints({
  endpoints: builder => ({
    essTimeOffList: builder.query<any, any>({
      query: params => {
        const { search, page, sortType, sortBy, limit, searchString } = params;
        return {
          url: 'time-management/ess/web/time-off-request',
          params: { search, page, sortType, sortBy, limit, ...searchString },
        };
      },
      providesTags: ['essTimeOffRequest'],
    }),
    essTimeOffDetail: builder.query<any, any>({
      query: id => ({
        url: `time-management/ess/web/time-off-request/${id}`,
      }),
      providesTags: ['essTimeOffRequestDetail'],
    }),
    essTimeOffWithdraw: builder.mutation({
      query: ({ id, payload }) => ({
        url: `time-management/ess/web/time-off-request/${id}/withdraw`,
        method: 'POST',
        body: payload,
      }),
      invalidatesTags: ['essTimeOffRequest', 'essTimeOffRequestDetail'],
    }),
    // ESS Task Approval
    essPendingTask: builder.query<TaskOverviewDataType, QueryParams>({
      query: params => {
        const { search, limit, page, sortType, sort, sortBy } = params;
        return {
          url: '/organization/ess/web/task',
          params: { search, limit, page, sortType, sort, sortBy },
        };
      },
      providesTags: ['essTaskList'],
    }),
    submitEssTaskApproval: builder.mutation({
      query: ({ id, payload }) => ({
        url: `/organization/ess/web/task/${id}/approval`,
        method: 'PUT',
        body: payload,
      }),
      invalidatesTags: ['essTaskList'],
    }),
  }),
});

export const {
  useEssTimeOffDetailQuery,
  useEssTimeOffListQuery,
  useEssTimeOffWithdrawMutation,
  useEssPendingTaskQuery,
  useSubmitEssTaskApprovalMutation,
} = essTimeOffApiSlice;
