import { lazy } from 'react';

const List = lazy(() => import('../../../pages/overtimeItem/List'));
const Create = lazy(() => import('../../../pages/overtimeItem/Create'));
const Detail = lazy(() => import('../../../pages/overtimeItem/Detail'));

export const overtimeItem = [
  {
    label: 'Overtime Item',
    child: null,
    path: 'overtime-item',
    component: List,
  },
  {
    label: '',
    child: null,
    path: 'overtime-item/create',
    component: Create,
  },
  {
    label: '',
    child: null,
    path: 'overtime-item/detail/:id',
    component: Detail,
  },
];
