import React from 'react';
import { RouteNode } from '../../../@types';

const UserAccessList = React.lazy(
  () => import('../../../pages/userAccess/UserAccessList')
);
const UserAccessCreateForm = React.lazy(
  () => import('../../../pages/userAccess/create/Create')
);
const UserAccessDetail = React.lazy(
  () => import('../../../pages/userAccess/UserAccessDetails')
);
const UserAccessUpdate = React.lazy(
  () => import('../../../pages/userAccess/UserAccessUpdate')
);

const userAccessRoutes: RouteNode[] = [
  {
    label: 'User Access',
    path: 'user-access',
    child: null,
    component: UserAccessList,
  },
  {
    label: '',
    path: 'user-access/create',
    child: null,
    component: UserAccessCreateForm,
  },
  {
    label: '',
    path: 'user-access/detail/:id',
    child: null,
    component: UserAccessDetail,
  },
  {
    label: '',
    path: 'user-access/update/:id',
    child: null,
    component: UserAccessUpdate,
  },
];

export default userAccessRoutes;
