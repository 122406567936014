import React from 'react';

const List = React.lazy(() => import('../../../pages/timeOffRequest/List'));
const Detail = React.lazy(() => import('../../../pages/timeOffRequest/Detail'));

export const timeOffRequest = [
  {
    label: 'Request',
    child: null,
    path: 'request',
    component: List,
  },
  {
    label: '',
    child: null,
    path: 'request/detail/:id',
    component: Detail,
  },
];
