import React, { ChangeEvent } from 'react';
import { Box, SxProps, ToggleButton, ToggleButtonGroup } from '@mui/material';
import { Control, UseFormSetValue, UseFormWatch } from 'react-hook-form';
import InputWithLabel from '../../components/molecule/form/InputWithLabel/InputWithLabel';
import { ContactUsFormProps } from '../../@types/ContactUs';

interface Step3Props {
  onChange: Function;
  control: Control<ContactUsFormProps>;
  watch: UseFormWatch<ContactUsFormProps>;
  setValue: UseFormSetValue<ContactUsFormProps>;
  onChangeOtherInput: Function;
}

const Step3 = ({
  onChange,
  control,
  watch,
  setValue,
  onChangeOtherInput,
}: Step3Props) => {
  const style: SxProps = {
    '& .button-group': {
      mb: '1.5rem',
    },
    '& .other-title': {
      mb: '0.5rem',
      fontWeight: 500,
    },
    '& .other': {
      display: 'flex',
      flexDirection: 'column',
      backgroundColor: 'red',
    },
    '& .MuiInputBase-root': {
      height: 'auto !important',
      px: '1rem !important',
      py: '0.75rem !important',
      borderRadius: '0.5rem !important',
    },
  };
  const handleChange = (
    _: React.MouseEvent<HTMLElement>,
    requests: string[]
  ) => {
    setValue('modules', requests);
    onChange(requests.length > 0);
  };
  return (
    <Box sx={style}>
      <ToggleButtonGroup
        orientation="vertical"
        value={watch('modules')}
        className="button-group"
        fullWidth
        onChange={handleChange}
      >
        <ToggleButton value="Core HR" className="option not-last" fullWidth>
          Core HR
        </ToggleButton>
        <ToggleButton value="Payroll" className="option not-last" fullWidth>
          Payroll
        </ToggleButton>
        <ToggleButton
          value="Time Management"
          className="option not-last"
          fullWidth
        >
          Time Management
        </ToggleButton>
        <ToggleButton value="Other" className="option" fullWidth>
          Other
        </ToggleButton>
      </ToggleButtonGroup>
      {watch('modules').findIndex(opt => opt === 'Other') !== -1 && (
        <InputWithLabel
          label="What does your company need?"
          placeholder="Tell us in detail..."
          name="other"
          multiline
          rows={4}
          control={control}
          onInput={(e: ChangeEvent<HTMLInputElement>) =>
            onChangeOtherInput(e.target.value)
          }
          sx={{
            '& .MuiOutlinedInput-root': {
              height: 'auto',
              padding: '8px 12px',
            },
          }}
        />
      )}
    </Box>
  );
};

export default Step3;
