import Box from '@mui/material/Box/Box';
import React, { useEffect, useState } from 'react';
import { Avatar, SxProps, Typography, useTheme } from '@mui/material';
import Skeleton from 'react-loading-skeleton';
import LogoWIthLabel from '../../components/atom/icon/LogoWIthLabel';
import Globe02 from '../../components/atom/icon/Globe02';
import DotsGrid from '../../components/atom/icon/DotsGrid';

const PreviewLogo = () => {
  const [dataImage, setData] = useState<null | any[]>(null);
  useEffect(() => {
    const dataLocalStorage = localStorage.getItem('image');
    setData(JSON.parse(dataLocalStorage as any));
    console.log(dataImage);
    // return localStorage.removeItem('image');
  }, []);
  const { palette } = useTheme();

  const style: SxProps = {
    borderBottom: '1px solid #DCE0E5',
    display: 'flex',
    justifyContent: 'space-between',
    boxSizing: 'border-box',
    padding: '8px 16px',
    minHeight: '56px',
    minWidth: '100vw',
    boxShadow: `0px 1px 2px rgba(16, 27, 40, 0.05)`,
    '& .left-container': {
      display: 'flex',
      alignItems: 'center',
      gap: '12px',
    },
    '& .button-grid': {
      height: '36px',
      width: '36px',
      minWidth: '36px',
    },
    '& .left-button-logo': {
      height: '40px',
      width: '40px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      borderRadius: '90px',
      minWidth: '40px',
      boxSizing: 'border-box',
      border: '1px solid #DCE0E5',
    },
  };

  const element = [];
  // eslint-disable-next-line no-plusplus
  for (let index = 0; index < 10; index++) {
    element.push(index);
  }

  return (
    <Box className="outer-container">
      <Box
        sx={{
          minWidth: '100vw',
          bgcolor: 'rgba(4, 95, 196, 1)',
          color: 'rgba(255, 255, 255, 1)',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          py: '10px',
        }}
      >
        <Typography>
          This is how TricorUnify looks like with your new logo.
        </Typography>
      </Box>
      <Box sx={style}>
        <Box className="left-container">
          <Box
            sx={{
              height: '36px',
              minWidth: '36px',
              borderRadius: '8px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <DotsGrid />
          </Box>
          {dataImage ? (
            <Box
              sx={{
                maxWidth: '57',
                maxHeight: '32',
                '& img': {
                  width: '57px',
                  heigth: '32px',
                  borderRadius: '5px',
                },
              }}
            >
              <img
                src={dataImage[0]?.previewUrl ?? ''}
                alt="logo-preview"
                style={{ objectFit: 'cover' }}
              />
            </Box>
          ) : (
            <LogoWIthLabel />
          )}
        </Box>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: '12px',
          }}
        >
          <Avatar
            sx={{ width: '40px', height: '40px', border: '4px solid white' }}
            src="Avatar.png"
          />
        </Box>
      </Box>
      <Box display="flex">
        <Box
          sx={{
            height: 'calc(100vh - 99px)',
            background: palette.gray[10],
            borderRight: `1px solid ${palette.gray[50]}`,
            boxSizing: 'border-box',
            overflow: 'auto',
            minWidth: '256px',
            maxWidth: '256px',
            paddingX: '12px',
            pt: '24px',
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          {element.map(_el => (
            <Box display="flex" gap="1rem" my="1rem" minWidth="232px">
              <Skeleton
                circle
                containerClassName="avatar-skeleton"
                width="16px"
                height="16px"
              />
              <Skeleton width="140px" />
            </Box>
          ))}
        </Box>
        <Box sx={{ width: '100%' }}>
          <Box
            display="flex"
            gap="1rem"
            my="1rem"
            flexDirection="column"
            p="1rem"
            paddingLeft="64px"
          >
            <Skeleton
              width="320px"
              style={{ height: '40px', borderRadius: '.5rem' }}
            />
            <Skeleton
              width="120px"
              style={{ height: '28px', borderRadius: '.5rem' }}
            />
            <Skeleton
              width="480px"
              style={{ height: '20px', borderRadius: '.5rem' }}
            />
            <Skeleton
              width="360px"
              style={{ height: '20px', borderRadius: '.5rem' }}
            />
            <Skeleton
              width="360px"
              style={{ height: '20px', borderRadius: '.5rem' }}
            />
            <Skeleton
              width="420px"
              style={{ height: '20px', borderRadius: '.5rem' }}
            />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default PreviewLogo;
