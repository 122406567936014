import { apiSlice } from '../api/apiSlice';
import {
  TimeEventListType,
  TimeEventDetails,
  TimeEventFormType,
} from '../../../@types/TimeEventTypes';

type QueryParams = {
  page: number;
  limit: number;
  sortBy: string;
  sortType: string;
  search: string;
  searchString: { [key: string]: any };
  id?: string | number;
};

export const timeEventApiSlice = apiSlice.injectEndpoints({
  endpoints: builder => ({
    timeEventList: builder.query<TimeEventListType, QueryParams>({
      query: params => {
        const { search, page, sortType, sortBy, searchString } = params;
        return {
          url: '/time-management/time-events',
          params: { search, page, sortType, sortBy, ...searchString },
        };
      },
      providesTags: ['timeEventList'],
    }),
    timeEventDetails: builder.query<TimeEventDetails, any>({
      query: ({ id }) => ({
        url: `/time-management/time-events/${id}`,
      }),
      providesTags: ['timeEventDetail'],
    }),
    createTimeEvent: builder.mutation<void, TimeEventFormType>({
      query: payload => ({
        url: '/time-management/time-events',
        method: 'POST',
        body: payload,
      }),
      invalidatesTags: ['timeEventList'],
    }),
    updateTimeEvent: builder.mutation<
      void,
      { id: string; payload: TimeEventFormType }
    >({
      query: ({ id, payload }) => ({
        url: `/time-management/time-events/${id}`,
        method: 'PUT',
        body: payload,
      }),
      invalidatesTags: ['timeEventList', 'timeEventDetail'],
    }),
    deleteTimeEvent: builder.mutation<void, { id: string }>({
      query: ({ id }) => ({
        url: `/time-management/time-events/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['timeEventList'],
    }),
  }),
});

export const {
  useTimeEventListQuery,
  useTimeEventDetailsQuery,
  useCreateTimeEventMutation,
  useUpdateTimeEventMutation,
  useDeleteTimeEventMutation,
} = timeEventApiSlice;
