const Briefcase02 = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5.33331 14V4.66667C5.33331 4.04669 5.33331 3.7367 5.40146 3.48236C5.5864 2.79218 6.12549 2.25308 6.81568 2.06815C7.07001 2 7.38 2 7.99998 2C8.61996 2 8.92995 2 9.18428 2.06815C9.87447 2.25308 10.4136 2.79218 10.5985 3.48236C10.6666 3.7367 10.6666 4.04669 10.6666 4.66667V14M3.46665 14H12.5333C13.28 14 13.6534 14 13.9386 13.8547C14.1895 13.7268 14.3935 13.5229 14.5213 13.272C14.6666 12.9868 14.6666 12.6134 14.6666 11.8667V6.8C14.6666 6.05326 14.6666 5.6799 14.5213 5.39468C14.3935 5.1438 14.1895 4.93982 13.9386 4.81199C13.6534 4.66667 13.28 4.66667 12.5333 4.66667H3.46665C2.71991 4.66667 2.34654 4.66667 2.06133 4.81199C1.81044 4.93982 1.60647 5.1438 1.47864 5.39468C1.33331 5.6799 1.33331 6.05326 1.33331 6.8V11.8667C1.33331 12.6134 1.33331 12.9868 1.47864 13.272C1.60647 13.5229 1.81044 13.7268 2.06133 13.8547C2.34654 14 2.71991 14 3.46665 14Z"
      stroke="#475667"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default Briefcase02;
