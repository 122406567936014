import { ReactNode } from 'react';
import { SxProps } from '@mui/material';
import Breadcrumb from '../../../molecule/breadcrumb/Breadcrumb';
import ContentContainer from '../content/ContentContainer';
import { mergeDeep } from '../../../../utils/deepMerge';

interface ContainerFormProps {
  children: ReactNode;
  sx?: SxProps;
}

const PageContainerForms = ({ children, sx, ...props }: ContainerFormProps) => {
  const style: SxProps = {
    gap: '0px',
    '& .title': {
      fontWeight: 700,
    },
    '& .breadcrumb': {
      mb: '2rem',
    },
  };
  return (
    <ContentContainer sx={mergeDeep(style, sx)} {...props}>
      <Breadcrumb className="breadcrumb" />
      {children}
    </ContentContainer>
  );
};

export default PageContainerForms;
