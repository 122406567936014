import React from 'react';
import {
  Alert as AlertBase,
  AlertTitle,
  Box,
  Typography,
  useTheme,
} from '@mui/material';

import { Button } from '..';
import { AlertProps } from '../../../@types';
import { mergeDeep } from '../../../utils/deepMerge';
import {
  AlertCircle,
  AlertTriangle,
  CheckCircle,
  Close,
  InfoCircle,
} from '../icon';

const Alert = React.forwardRef((Props: AlertProps, ref: any) => {
  const { variant, message, title, sx, bottomButton, ...props } = Props;
  const { palette } = useTheme();

  const bgColor: { [key: string]: string } = {
    success: palette.green[20],
    info: palette.blue[20],
    warning: palette.orange[20],
    error: palette.red[20],
  };

  const strokeColor: { [key: string]: string } = {
    success: palette.green[50],
    info: palette.blue[50],
    warning: palette.orange[50],
    error: palette.red[50],
  };

  const style = {
    background: bgColor[variant],
    display: 'flex',
    alignItems: 'center',
    gap: '12px',
    padding: '12px 16px 12px 16px',
    border: 'none',
    borderRadius: '8px',
    '& .MuiAlert-message': {
      padding: 0,
    },
    '& .MuiAlert-icon': {
      padding: 0,
      margin: 0,
      alignItems: title ? 'inherit' : 'center',
      '& svg': {
        transform: 'scale(0.8333)',
        '& path': {
          stroke: strokeColor[variant],
        },
      },
    },
    '& .alert-title': {
      marginTop: 0,
      marginBottom: '0.5rem',
    },
    '& .MuiAlert-action': {
      padding: 0,
      margin: 0,
      '& .MuiButtonBase-root': {
        padding: 0,
        width: '20px',
        height: '20px',
        '& svg': {
          transform: 'scale(0.8333)',
        },
      },
    },
    '& .message-container': {
      display: 'flex',
      gap: '8px',
      flexDirection: 'column',
      '& .bottom-button-container': {
        display: 'flex',
        gap: '8px',
        '& .MuiButtonBase-root': {
          padding: 0,
          minWidth: 0,
          minHeight: 0,
          height: '20px',
          '&:hover': {
            background: 'none',
          },
        },
      },
    },
  };

  return (
    <AlertBase
      {...props}
      ref={ref}
      sx={mergeDeep(style, sx)}
      components={{
        CloseIcon: Close,
      }}
      severity={variant}
      iconMapping={{
        warning: <AlertTriangle />,
        error: <AlertCircle />,
        info: <InfoCircle />,
        success: <CheckCircle />,
      }}
    >
      {title && (
        <AlertTitle className="alert-title">
          <Typography
            variant="body16"
            fontWeight={600}
            color={palette.gray[100]}
          >
            {title}
          </Typography>
        </AlertTitle>
      )}
      <Box className="message-container">
        <Typography component="div" variant="body14" color={palette.gray[100]}>
          {message}
        </Typography>
        {bottomButton?.leftLabel && (
          <Box className="bottom-button-container">
            <Button
              onClick={() => {
                bottomButton?.leftAction?.();
              }}
              disableRipple
              size="small"
              variant="tertiary"
            >
              {bottomButton?.leftLabel}
            </Button>
            ·
            {bottomButton?.rightLabel && (
              <Button
                onClick={() => {
                  bottomButton?.rightAction?.();
                }}
                disableRipple
                size="small"
                variant="tertiary"
              >
                {bottomButton?.rightLabel}
              </Button>
            )}
          </Box>
        )}
      </Box>
    </AlertBase>
  );
});

export default Alert;
