import { lazy } from 'react';

const List = lazy(() => import('../../../pages/workSchedule/List'));
const AssignSchedule = lazy(
  () => import('../../../pages/workSchedule/AssignSchedule')
);

export const workSchedule = [
  {
    label: 'Work Schedule',
    child: null,
    path: 'work-schedule',
    component: List,
  },
  {
    label: '',
    child: null,
    path: 'work-schedule/assign-schedule',
    component: AssignSchedule,
  },
];
