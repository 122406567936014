import { apiSlice } from '../api/apiSlice';

export const WorkLocationApiSlice = apiSlice.injectEndpoints({
  endpoints: builder => ({
    getWorkLocationList: builder.query({
      query: params => {
        const { search, page, sortType, sort, searchString } = params;
        return {
          url: '/organization/work-locations',
          params: { search, page, sortType, sort, ...searchString },
        };
      },
      providesTags: ['workLocationList'],
    }),
    getWorkLocationDetail: builder.query({
      query: params => {
        const { effectiveDate, searchString } = params;
        return {
          url: `/organization/work-locations/${params.id}`,
          params: { effectiveDate, ...searchString },
          searchString,
        };
      },
      providesTags: ['workLocationDetail'],
    }),
    addWorkLocation: builder.mutation({
      query: payload => ({
        url: '/organization/work-locations',
        method: 'POST',
        body: payload,
      }),
      invalidatesTags: ['workLocationList'],
    }),
    updateWorkLocation: builder.mutation({
      query: ({ payload, id }) => ({
        url: `/organization/work-locations/${id}`,
        method: 'PUT',
        body: payload,
      }),
      invalidatesTags: ['workLocationDetail'],
    }),
    deleteWorkLocation: builder.mutation<any, any>({
      query: id => ({
        url: `/organization/work-locations/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['workLocationList'],
    }),
    cancelUpdateWorkLocation: builder.mutation<any, any>({
      query: newId => ({
        url: `/organization/work-locations/${newId}/cancellation`,
        method: 'DELETE',
      }),
      invalidatesTags: ['workLocationList', 'workLocationDetail'],
    }),
  }),
});

export const {
  useGetWorkLocationListQuery,
  useGetWorkLocationDetailQuery,
  useAddWorkLocationMutation,
  useDeleteWorkLocationMutation,
  useUpdateWorkLocationMutation,
  useCancelUpdateWorkLocationMutation,
} = WorkLocationApiSlice;
