import { createSlice } from '@reduxjs/toolkit';
import { formulaApiSlice } from './service/formulaApiSlice';

interface initialStateI {
  messageSuccess: string;
  messageError: string;
  viewType: { [key: string]: string };
}

const initialState: initialStateI = {
  messageSuccess: '',
  messageError: '',
  viewType: {},
};

const formulaSlice = createSlice({
  name: 'formula',
  initialState,
  reducers: {
    removeMessage: state => {
      state.messageSuccess = '';
      state.messageError = '';
    },
    setViewType: (state, action) => {
      const { payload } = action;
      const key = Object.keys(payload)?.[0];
      state.viewType = {
        ...state.viewType,
        [key]: payload[key],
      };
    },
  },
  extraReducers(builder) {
    builder.addMatcher(
      formulaApiSlice.endpoints.createFormula.matchFulfilled,
      (state, action) => {
        state.messageSuccess = 'Formula created';
      }
    );
    builder.addMatcher(
      formulaApiSlice.endpoints.createFormula.matchRejected,
      (state, action) => {
        const payload = action.payload as any;
        state.messageError = payload?.data?.message || 'Failed create formula';
      }
    );
    builder.addMatcher(
      formulaApiSlice.endpoints.deleteFormula.matchFulfilled,
      (state, action) => {
        state.messageSuccess = 'Formula deleted';
      }
    );
    builder.addMatcher(
      formulaApiSlice.endpoints.deleteFormula.matchRejected,
      (state, action) => {
        const payload = action.payload as any;
        state.messageError = payload?.data?.message;
      }
    );
    builder.addMatcher(
      formulaApiSlice.endpoints.updateFormula.matchFulfilled,
      (state, action) => {
        if (action?.payload?.typeOfChange === 'UPDATED') {
          state.messageSuccess = 'Formula updated';
        } else if (action?.payload?.typeOfChange === 'CORRECTED') {
          state.messageSuccess = 'Formula corrected';
        }
      }
    );
    builder.addMatcher(
      formulaApiSlice.endpoints.updateFormula.matchRejected,
      (state, action) => {
        const payload = action.payload as any;
        state.messageError =
          payload?.data?.message || 'Failed to update Formula';
      }
    );
    builder.addMatcher(
      formulaApiSlice.endpoints.cancelUpcomingUpdate.matchFulfilled,
      (state, action) => {
        state.messageSuccess = 'Upcoming Changes cancelled';
      }
    );
  },
});

export const { removeMessage, setViewType } = formulaSlice.actions;
export const formulaReducer = formulaSlice.reducer;
