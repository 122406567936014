import { QueryParams } from '../../../@types/QueryParams';
import { apiSlice } from '../api/apiSlice';
import {
  LegalEntityListType,
  LegalEntityDetailsType,
  LegalEntityFormProps,
} from '../../../@types/LegalEntityProps';

interface Query {
  id: string;
  effectiveDate?: string;
}

export const customFieldApiSlice = apiSlice.injectEndpoints({
  endpoints: builder => ({
    getCustomFieldList: builder.query({
      query: params => {
        const { search, page, sortType, sortBy, searchString } = params;
        return {
          url: `/employee/costum-field`,
          params: { search, page, sortType, sortBy, ...searchString },
        };
      },
      providesTags: ['LegalEntityList', 'LegalEntityDelete'],
    }),
    getCustomFieldDetails: builder.query({
      query: params => {
        const { id } = params;
        return {
          url: `/employee/costum-field/${id}`,
        };
      },
      providesTags: ['LegalEntityList', 'LegalEntityDelete'],
    }),
    creategetCustomField: builder.mutation<void, any>({
      query: body => ({
        url: `/employee/costum-field`,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['LegalEntityList'],
    }),
    updateCustomField: builder.mutation<any, any>({
      query: ({ id, payload }) => ({
        url: `/employee/costum-field/${id}`,
        method: 'PUT',
        body: payload,
      }),
      invalidatesTags: ['LegalEntityList', 'LegalEntityDelete'],
    }),
    deleteCustomField: builder.mutation<void, Query>({
      query: params => ({
        url: `/employee/costum-field/${params.id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['LegalEntityList', 'LegalEntityDelete'],
    }),
  }),
});

export const {
  useGetCustomFieldListQuery,
  useGetCustomFieldDetailsQuery,
  useUpdateCustomFieldMutation,
  useCreategetCustomFieldMutation,
  useDeleteCustomFieldMutation,
} = customFieldApiSlice;
