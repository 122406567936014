import { createSlice } from '@reduxjs/toolkit';
import { policyApiSlice } from './service/policyApiSlice';
import { ModalAttendancePolicy } from '../../@types/Policy';

interface initialStateI {
  messageSuccess: string;
  messageError: string;
  rowData: Array<ModalAttendancePolicy>;
  updatedData: number;
}

const initialState: initialStateI = {
  messageSuccess: '',
  messageError: '',
  rowData: [],
  updatedData: 0,
};

const policySlice = createSlice({
  name: 'policy',
  initialState,
  reducers: {
    removeMessage: state => {
      state.messageSuccess = '';
      state.messageError = '';
    },
    addRowData: (state, action) => {
      state.rowData.push(action.payload);
      state.messageSuccess = 'Policy added';
    },
    updateRowData: (state, action) => {
      const { payload } = action;
      const index = state.rowData.findIndex(obj => obj.id === payload.id);
      state.rowData[index] = payload;
      state.messageSuccess = 'Policy edited';
    },
    deleteRowData: (state, action) => {
      const filtered = state.rowData.filter(item => item.id !== action.payload);
      state.rowData = filtered;
      state.messageSuccess = 'Policy removed';
    },
    resetRowData: state => {
      state.rowData = [];
    },
    fillPayData: (state, _action) => {
      state.rowData = _action.payload;
    },
    setMessageSuccess: (state, action) => {
      state.messageSuccess = action.payload;
    },
  },
  extraReducers(builder) {
    builder.addMatcher(
      policyApiSlice.endpoints.createAttendancePolicy.matchFulfilled,
      (state, _action) => {
        state.messageSuccess = 'Attendance Policy created';
      }
    );
    builder.addMatcher(
      policyApiSlice.endpoints.createAttendancePolicy.matchRejected,
      (state, action) => {
        const payload = action.payload as any;
        state.messageError = payload?.data?.message || 'Internal Server Error';
      }
    );
    builder.addMatcher(
      policyApiSlice.endpoints.createEssPolicy.matchFulfilled,
      (state, _action) => {
        state.messageSuccess = 'ESS Policy created';
      }
    );
    builder.addMatcher(
      policyApiSlice.endpoints.createEssPolicy.matchRejected,
      (state, action) => {
        const payload = action.payload as any;
        state.messageError = payload?.data?.message || 'Internal Server Error';
      }
    );
  },
});

export const {
  removeMessage,
  addRowData,
  updateRowData,
  deleteRowData,
  resetRowData,
  fillPayData,
  setMessageSuccess,
} = policySlice.actions;
export const policyReducer = policySlice.reducer;
