const detailKey: { [key: string]: any } = {
  bankName: 'Bank Name',
  country: 'Country',
  bankCode: 'Bank Code',
  swiftCode: 'Swift Code',
};

const defaultValueForm = {
  country: null,
  bankName: '',
  bankCode: '',
  swiftCode: '',
};

const headerKey = {
  bankName: 'Bank Name',
  countryName: 'Country',
  bankCode: 'Bank Code',
  swiftCode: 'Swift Code',
};

export default {
  detailKey,
  defaultValueForm,
  headerKey,
};
