import React from 'react';
import { RouteNode } from '../../../@types';

const ExchangeRateList = React.lazy(
  () => import('../../../pages/exchangeRates/List')
);
const ExchangeRateCreate = React.lazy(
  () => import('../../../pages/exchangeRates/Create')
);
const ExchangeRateDetail = React.lazy(
  () => import('../../../pages/exchangeRates/Detail')
);
const ExchangeRateUpdate = React.lazy(
  () => import('../../../pages/exchangeRates/Update')
);
const ExchangeRateCorrection = React.lazy(
  () => import('../../../pages/exchangeRates/Correction')
);

const exchangeRate: RouteNode[] = [
  {
    label: 'Exchange Rate',
    path: 'exchange-rate',
    child: null,
    component: ExchangeRateList,
  },
  {
    label: '',
    path: 'exchange-rate/create',
    child: null,
    component: ExchangeRateCreate,
  },
  {
    label: '',
    path: 'exchange-rate/detail/:id',
    child: null,
    component: ExchangeRateDetail,
  },
  {
    label: '',
    path: 'exchange-rate/update/:id',
    component: ExchangeRateUpdate,
    child: null,
  },
  {
    label: '',
    path: 'exchange-rate/correction/:id',
    component: ExchangeRateCorrection,
    child: null,
  },
];

export default exchangeRate;
