import { createSlice } from '@reduxjs/toolkit';
import { payslipTemplateApiSlice } from './service/payslipTemplateApiSlice';

interface initialStateI {
  messageSuccess: string;
  messageError: string;
}

const initialState: initialStateI = {
  messageSuccess: '',
  messageError: '',
};

const payslipTemplate = createSlice({
  name: 'payslipTemplate',
  initialState,
  reducers: {
    removeMessage: state => {
      state.messageSuccess = '';
      state.messageError = '';
    },
  },
  extraReducers(builder) {
    builder.addMatcher(
      payslipTemplateApiSlice.endpoints.getListPayslipTemplate.matchRejected,
      (state, action) => {
        const payload = action.payload as any;
        state.messageError = payload?.data?.message;
      }
    );
  },
});

export const { removeMessage } = payslipTemplate.actions;
export const payslipTemplateReducers = payslipTemplate.reducer;
