import React from 'react';
import { RouteNode } from '../../@types';

const Correction = React.lazy(
  () => import('../../pages/employee/PersonalInfo/Correction')
);

const Update = React.lazy(
  () => import('../../pages/employee/PersonalInfo/Update')
);

const PersonalInfo: RouteNode[] = [
  {
    label: '',
    path: '/employee/detail/:id/personal-info/update',
    component: Update,
    child: null,
  },
  {
    label: '',
    path: '/employee/detail/:id/personal-info/correction',
    component: Correction,
    child: null,
  },
];

export default PersonalInfo;
