import { Box, Grid, Typography, useTheme } from '@mui/material';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import TaskItem from '../../../molecule/taskItem/TaskItem';
import { ChevronDown, Clock } from '../../../atom/icon';
import { TaskOverviewType } from '../../../../@types/HomeTypes';
import { useEssPendingTaskQuery } from '../../../../store/feature/service/essTimeOffApiSlice';
import { Button } from '../../../atom';
import TaskOverviewLoading from '../../taskOverview/TaskOverviewLoading';
import {
  formatUnixToString,
  getCurrentTime,
  newformatDateTimeFromUnixUTC,
} from '../../../../utils/date';
import { useAppSelector } from '../../../../store';
import Toast from '../../../atom/toast/Toast';
import { removeMessage } from '../../../../store/feature/homeSlice';
import { capitalizeWords } from '../../../../utils/capitalizeWords';
import EssApprovalLeave from '../pendingTask/EssApprovalLeave';
import EssAttendanceRequestItem from '../pendingTask/EssAttendanceRequestItem';
import EssDataUpdateRequest from '../pendingTask/EssDataUpdateRequest';

const PendingApproval = () => {
  const { palette } = useTheme();
  const dispatch = useDispatch();
  const [limit, setLimit] = useState(3);
  const [openModal, setOpenModal] = useState<
    'TIME_OFF' | 'ATTENDANCE_REQUEST' | 'DATA_UPDATE' | null
  >(null);
  const [concattedData, setConcattedData] = useState<Array<TaskOverviewType>>(
    []
  );
  const [activeData, setActiveData] = useState<TaskOverviewType | null>(null);
  const { messageError, messageSuccess } = useAppSelector(state => state.home);
  const { data, isLoading } = useEssPendingTaskQuery({
    page: 1,
    limit,
    sortType: 'desc',
    sortBy: 'createdAt',
    search: '',
    searchString: {},
  });

  const onClickTaskItem = (value: TaskOverviewType) => {
    setActiveData(value);

    if (value.type === 'TIME_OFF_REQUEST') {
      return setOpenModal('TIME_OFF');
    }
    if (value.type === 'ATTENDANCE_REQUEST') {
      return setOpenModal('ATTENDANCE_REQUEST');
    }
    return setOpenModal('DATA_UPDATE');
  };

  const handleCloseToast = () => {
    dispatch(removeMessage());
  };

  useEffect(() => {
    if (data) {
      if (limit === 3) {
        setConcattedData(_prev => [...data.data]);
      } else {
        setConcattedData(_prev => [...data.data]);
      }
    }
  }, [data]);

  const renderDescription = (e: TaskOverviewType) => {
    if (e.type === 'ATTENDANCE_REQUEST')
      return `${
        e.employee
      } has requested attendance on ${newformatDateTimeFromUnixUTC(
        e?.shiftDate
      )} for  ${e?.shiftCode}  (${getCurrentTime(
        e?.shiftStartTime!
      )} - ${getCurrentTime(e?.shiftEndTime!)}) `;
    if (e.type === 'TIME_OFF_REQUEST') {
      return `${e.employee} has requested ${e.timeOffItemName} for ${e.totalDays} day(s).`;
    }

    return ` ${
      e.employee
    } has requested you to ${e?.employeeModuleActionCode?.toLowerCase()} the ${capitalizeWords(
      e?.employeeModuleCode || ''
    )} data.`;
  };

  const determineListTitle = (val: any) => {
    if (val.type === 'TIME_OFF_REQUEST') {
      return 'Time Off Request';
    }
    if (val.type === 'ATTENDANCE_REQUEST') {
      return 'Attendance Request';
    }
    return 'Data Update Request';
  };

  return (
    <Grid container direction="column" gap="16px">
      <Toast
        message={messageError}
        variant="error"
        open={messageError !== ''}
        onClose={handleCloseToast}
      />
      <Toast
        message={messageSuccess}
        variant="success"
        open={messageSuccess !== ''}
        onClose={handleCloseToast}
      />
      {openModal === 'TIME_OFF' && (
        <EssApprovalLeave
          data={activeData}
          handleClose={() => setOpenModal(null)}
        />
      )}
      {openModal === 'ATTENDANCE_REQUEST' && (
        <EssAttendanceRequestItem
          data={activeData}
          handleClose={() => setOpenModal(null)}
        />
      )}
      {openModal === 'DATA_UPDATE' && (
        <EssDataUpdateRequest
          data={activeData}
          handleClose={() => setOpenModal(null)}
        />
      )}

      {!isLoading && (
        <Grid item>
          <Typography
            variant="body18"
            fontWeight={700}
            color={palette.gray[90]}
          >
            Task ({concattedData?.length})
          </Typography>
        </Grid>
      )}
      {isLoading && <TaskOverviewLoading />}
      {concattedData?.length === 0 && (
        <Typography variant="body14" color={palette.gray[100]}>
          No tasks available
        </Typography>
      )}
      {concattedData?.map(e => (
        <TaskItem
          key={e.id}
          icon={
            <Box
              sx={{
                '& svg': {
                  width: '20px',
                  height: '20px',
                  display: 'flex',
                  '& path': {
                    stroke: palette.gray[80],
                    strokeWidth: 1.5,
                  },
                },
              }}
            >
              <Clock />
            </Box>
          }
          title={determineListTitle(e)}
          onClick={() => {
            onClickTaskItem(e);
          }}
          image={
            <img
              src={e.employeeProfileUrl}
              alt="employee profile"
              style={{
                width: '40px',
                height: '45px',
                objectFit: 'cover',
              }}
            />
          }
          description={renderDescription(e)}
          caption={formatUnixToString(e.createdAt)}
        />
      ))}
      {data !== undefined && data.total !== data.data.length && (
        <Grid item container justifyContent="center">
          <Button
            variant="link"
            onClick={() => {
              setLimit(9999999);
            }}
          >
            <ChevronDown /> View All <ChevronDown />
          </Button>
        </Grid>
      )}
    </Grid>
  );
};

export default PendingApproval;
