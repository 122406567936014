import React from 'react';
import { RouteNode } from '../../@types';

const List = React.lazy(
  () => import('../../pages/employee/careerTransition/CareerTransitionList')
);
const Details = React.lazy(
  () => import('../../pages/employee/careerTransition/CareerTransitionDetails')
);
const Create = React.lazy(
  () => import('../../pages/employee/careerTransition/Create')
);
const CreateTermination = React.lazy(
  () => import('../../pages/employee/careerTransition/CreateTermination')
);

const careerTransition: RouteNode[] = [
  {
    label: '',
    path: 'employee/detail/:id/career-transition',
    component: List,
    child: null,
  },
  {
    label: '',
    path: 'employee/detail/:id/career-transition/detail/:transitionId',
    component: Details,
    child: null,
  },
  {
    label: '',
    path: 'employee/detail/:id/career-transition/create',
    component: Create,
    child: null,
  },
  {
    label: '',
    path: 'employee/detail/:id/career-transition/create-termination',
    component: CreateTermination,
    child: null,
  },
];

export default careerTransition;
