import * as yup from 'yup';
import regex from '../constants/regex';
import dayjs from '../utils/dayjs';
import { splitNameAndCode } from '../utils/splitter';

const validationTextField = (
  label: string,
  minimal: number = 5,
  maximal: number = 100
) =>
  yup
    .string()
    .nullable()
    .typeError(`${label} must be filled.`)
    .required(`${label} must be filled.`)
    .min(minimal, `${label} must be longer than ${minimal} characters.`)
    .max(maximal, `${label} must not be longer than ${maximal} characters.`);

const validationNumberFieldRequiredWithNoSymbols = (
  label: string,
  minimal: number,
  maximal: number
) =>
  yup
    .string()
    .required(`${label} must be filled`)
    .matches(/^\S*$/, {
      message: `${label} must not be filled with whitespace`,
    })
    .matches(/^[0-9\S]*$/, {
      message: `${label} must not be filled with symbols`,
    })
    .matches(/^[0-9]*$/, {
      message: `${label} must be filled with numbers`,
    })
    .min(minimal, `${label} must be longer than ${minimal} characters.`)
    .max(maximal, `${label} must not be longer than ${maximal} characters.`);

const validationNumberFieldNoRequiredWithNoSymbols = (
  label: string,
  minimal: number = 1,
  maximal: number = 20
) =>
  yup
    .string()
    .nullable()
    .test('numeric', `${label} must be numbers`, value => {
      if (value === '') {
        return true; // Pass the test if the value is an empty string
      }
      return /^\d*$/.test(value || ''); // Use empty string if value is undefined
    })
    .test(
      'minChar',
      `${label} must be longer than ${minimal} characters.`,
      value => {
        if (value === '' || value === null || value === undefined) {
          return true; // Pass the test if the value is an empty string
        }
        return (value || '').length >= minimal; // Use empty string if value is undefined
      }
    )
    .test(
      'maxChar',
      `${label} must not be longer than ${maximal} characters.`,
      value => {
        if (value === '' || value === null || value === undefined) {
          return true; // Pass the test if the value is an empty string
        }
        return (value || '').length <= maximal; // Use empty string if value is undefined
      }
    );

const validationTextFieldWithWhiteSpaceAndNoSymbols = (
  label: string,
  minimal: number = 5,
  maximal: number = 100
) =>
  yup
    .string()
    .typeError(`${label} must be filled.`)
    .required(`${label} must be filled.`)
    .matches(/^[a-zA-Z0-9\s]*$/, {
      message: `${label} must not contain symbols`,
    })
    .min(minimal, `${label} must be longer than ${minimal} characters.`)
    .max(maximal, `${label} must not be longer than ${maximal} characters.`);

const validationTextFieldWhiteSpaceNoSymbolExcUnderscore = (
  label: string,
  minimal: number = 5,
  maximal: number = 100
) =>
  yup
    .string()
    .typeError(`${label} must be filled.`)
    .required(`${label} must be filled.`)
    .matches(/^[a-zA-Z0-9_-\s]+$/, {
      message: `${label} must not contain symbols`,
    })
    .min(minimal, `${label} must be longer than ${minimal} characters.`)
    .max(maximal, `${label} must not be longer than ${maximal} characters.`);

const validationTextFieldNoWhitespaceNoSymbols = (
  label: string,
  minimal: number = 5,
  maximal: number = 100
) =>
  yup
    .string()
    .nullable()
    .typeError(`${label} must be filled.`)
    .required(`${label} must be filled.`)
    .matches(/^\S+$/, {
      message: `${label} must not contain whitespace`,
      excludeEmptyString: true,
    })
    .matches(/^[a-zA-Z0-9]*$/, {
      message: `${label} must not contain symbols`,
    })
    .min(minimal, `${label} must be longer than ${minimal} characters.`)
    .max(maximal, `${label} must not be longer than ${maximal} characters.`);

const validationTextFieldNoWhitespaceNoAllSymbols = (
  label: string,
  minimal: number = 5,
  maximal: number = 100
) =>
  yup
    .string()
    .nullable()
    .typeError(`${label} must be filled.`)
    .required(`${label} must be filled.`)
    .matches(/^\S+$/, {
      message: `${label} must not contain whitespace`,
      excludeEmptyString: true,
    })
    .matches(/^[a-zA-Z0-9\s]*$/, {
      message: `${label} must not contain symbols`,
    })
    .min(minimal, `${label} must be longer than ${minimal} characters.`)
    .max(maximal, `${label} must not be longer than ${maximal} characters.`);

const validationTextFieldNoRequiredWhitespaceNoSymbols = (
  label: string,
  minimal: number = 5,
  maximal: number = 100
) =>
  yup
    .string()
    .nullable()
    .matches(/^[a-zA-Z0-9\s]*$/, {
      message: `${label} must not contain symbols`,
    })
    .min(minimal, `${label} must be longer than ${minimal} characters.`)
    .max(maximal, `${label} must not be longer than ${maximal} characters.`);

const validationTextFieldNoRequiredWhitespaceNoSymbolsNoHyphenUnderscore = (
  label: string,
  minimal: number = 5,
  maximal: number = 100
) =>
  yup
    .string()
    .nullable()
    .matches(/^[a-zA-Z0-9\s]*$/, {
      message: `${label} must not contain symbols`,
    })
    .min(minimal, `${label} must be longer than ${minimal} characters.`)
    .max(maximal, `${label} must not be longer than ${maximal} characters.`);

const validationTextFieldNoRequired = (
  label: string,
  minimal: number = 5,
  maximal: number = 100
) =>
  yup
    .string()
    .nullable()
    .test(
      'minmax',
      `${label} must be longer than ${minimal} characters.`,
      (val: any) => {
        if (val) return val.length >= minimal;
        return true;
      }
    )
    .nullable()
    .test(
      'minmax',
      `${label} must not be longer than ${maximal} characters.`,
      (val: any) => {
        if (val) return val.length <= maximal;
        return true;
      }
    );
const validationTextFieldNoRequiredWithNoSymbol = (
  label: string,
  minimal: number = 5,
  maximal: number = 100
) =>
  yup
    .string()
    .nullable()
    .test(
      'minmax',
      `${label} must not be less than ${minimal} characters.`,
      (val: any) => {
        if (val) return val.length > minimal;
        return true;
      }
    )
    .test(
      'whitespace',
      `${label} must not contain symbols`,
      (val: any): any => {
        const reg = /^[a-zA-Z0-9_\- ]*$/.test(val);
        return reg;
      }
    )
    .test(
      'symbols',
      `${label} must not contain Whitespace`,
      (val: any): any => {
        const reg = /^\S*$/.test(val);
        return reg;
      }
    )
    .test(
      'minmax',
      `${label} must not be longer than ${maximal} characters.`,
      (val: any) => {
        if (val) return val.length <= maximal;
        return true;
      }
    );

const validationIdentityNumber = () =>
  yup
    .string()
    .required('ID Number must be filled!')
    .when('identityType', (val: any, schema: any) => {
      if (val?.label?.toLowerCase() === 'ktp')
        return schema
          .min(16, `ID Number must be longer than 16 characters.`)
          .max(16, `ID Number must not be longer than 16 characters.`);
      if (val?.label?.toLowerCase() === 'malaysian ic')
        return schema
          .min(12, `ID Number must be longer than 12 characters.`)
          .max(12, `ID Number must not be longer than 12 characters.`);
      if (val?.label?.toLowerCase() === 'thailand ic')
        return schema
          .min(13, `ID Number must be longer than 13 characters.`)
          .max(13, `ID Number must not be longer than 13 characters.`);
      return schema
        .min(2, `ID Number must be longer than 6 characters.`)
        .max(20, `ID Number must not be longer than 24 characters.`);
    });

const validationTextFieldNoSymbol = (
  label: string,
  minimal: number = 5,
  maximal: number = 100
) =>
  yup
    .string()
    .typeError(`${label} must be filled!`)
    .required(`${label} must be filled!`)
    .min(minimal, `${label} minimum ${minimal} characters!`)
    .max(maximal, `${label} maximum ${maximal} characters!`)
    .test(
      'symbol',
      `${label} only allows for alphabet and numeric!`,
      (val: any) => /^[A-Za-z0-9]*$/.test(val)
    );

const validationTextFieldOnlyLettersExactLength = (
  label: string,
  length: number = 10
) =>
  yup
    .string()
    .typeError(`${label} must be filled!`)
    .required(`${label} must be filled!`)
    .length(length, `${label} must be ${length} characters!`)
    .test('symbol', `${label} only allows for alphabet!`, (val: any) =>
      /^[A-Za-z]*$/.test(val)
    );

const validationTextFieldNoNumbers = (
  label: string,
  minimal: number = 5,
  maximal: number = 100
) =>
  yup
    .string()
    .typeError(`${label} must be filled!`)
    .required(`${label} must be filled!`)
    .min(minimal, `${label} minimum ${minimal} characters!`)
    .max(maximal, `${label} maximum ${maximal} characters!`)
    .test('numbers', `${label} cannot contain number!`, (val: any) =>
      /^[\D]*$/.test(val)
    );

const validationStringSelect = (label: string) =>
  yup.string().nullable().required(`${label} must be selected!`);

const validataionTextEditor = (
  label: string,
  min: number = 0,
  max: number = 2000
) =>
  yup
    .string()
    .required()
    .test('len', `${label} Maximum ${max} Characters!`, val => {
      if (val) {
        const textOnly = val.replace(/<[^<>]+>/g, '').length;
        if (textOnly > max) return false;
        if (textOnly < min) return false;
        return true;
      }
      return false;
    });

const validationTextEditorNoRequired = (
  label: string,
  min: number = 0,
  max: number = 2000
) =>
  yup
    .string()
    .nullable()
    .test('len', `${label} Minimum ${min} Characters`, val => {
      if (val) {
        console.log('VAL :', val);
        const textOnly = val.replace(/<[^<>]+>/g, '').length;
        if (textOnly < min) return false;
        return true;
      }
      return true;
    })
    .test(
      'len',
      `The ${label} must not be longer than ${max} Characters`,
      val => {
        if (val) {
          const textOnly = val.replace(/<[^<>]+>/g, '').length;
          if (val && textOnly > max) return false;
          return true;
        }
        return true;
      }
    )
    .test('noSymbol', `The ${label} must not be filled with symbols`, val => {
      if (val) {
        const textOnly = val.replace(/<[^<>]+>/g, '');
        const textWithoutSpace = textOnly.replace(/&nbsp;/g, ' ');
        const isSymbol = /^[a-zA-Z0-9\s]*$/.test(textWithoutSpace);
        if (isSymbol) return true;
        return false;
      }
      return true;
    });

const validationNumberSelect = (label: string) =>
  yup
    .number()
    .typeError(`${label} must be filled!`)
    .required(`${label} must be filled!`)
    .test(
      'len',
      `${label} cannot below 0`,
      val => val !== undefined && val >= 0
    );

const validationPercentNumber = (label: string) =>
  yup
    .number()
    .test(
      'len',
      `${label} must be in percent`,
      val => val !== undefined && val >= 0 && val <= 100
    )
    .typeError(`${label} must be in percent`)
    .required(`${label} must be filled!`);

const validationBooleanSelect = (label: string) =>
  yup.boolean().nullable().required(`${label} must be filled!`);

const validationTextFieldNoMin = (label: string) =>
  yup.string().required(`${label} must be filled!`);

const validationNumberField = (label: string, min = 0, max = 100) =>
  yup
    .string()
    .test('check if number', `${label} must be number`, (val: any) => {
      const isNumber = /^-?\d+(\.\d+)?$/.test(val);
      if (val === '' || val === undefined) {
        return true;
      }
      return isNumber;
    })
    .test('len', `${label} cant be less than ${min}`, (val: any) => {
      if (val === '' || val === undefined) {
        return true;
      }
      return Number(val) >= min;
    })
    .test(
      'len',
      `${label} not be longer than ${max} characters`,
      (val: any) => {
        if (val === '' || val === undefined) {
          return true;
        }
        return val.length <= max;
      }
    )
    .required(`${label} must be filled!`);

const validationNumberFieldWithMax = (label: string, min = 0, max = 1000) =>
  yup
    .number()
    .typeError(`${label} must be filled!`)
    .required(`${label} must be filled!`)
    .min(min, `${label} must be greater than ${min}`)
    .max(max, `${label} must not be greater than ${max}`);

const validationCurrencyFieldWithMax = (
  label: string,
  min = 0,
  max = 9999999999999
) =>
  yup
    .number()
    .typeError(`${label} must be filled!`)
    .required(`${label} must be filled!`)
    .min(min, `${label} minimum ${min.toString().length} character!`)
    .max(max, `${label} maximum ${max.toString().length} characters!`);

const validationDateField = (label: string) =>
  yup
    .date()
    .nullable()
    .required(`${label} must be filled!`)
    .typeError(`${label} must match date format DD/MM/YYYY.`)
    .max(new Date(), `${label} Cannot be in the future`);

const validationDateFieldNoMaxFuture = (label: string) =>
  yup
    .date()
    .nullable()
    .required(`${label} must be filled`)
    .typeError(`${label} must match date format DD/MM/YYYY.`);

const validationDateFieldNotSameAsExisting = (
  label: string,
  existingDate: any
) =>
  yup.object().shape({
    date: yup
      .date()
      .nullable()
      .transform((value, originalValue) => {
        if (originalValue) {
          return originalValue.toISOString().slice(0, 10); // Convert date to yyyy-mm-dd string
        }
        return value;
      })
      .test(
        'not-same-as-existing-date',
        'The new effective date must not be the same as the existing effective date.',
        value => {
          if (!value || !existingDate) {
            return true;
          }
          //
          return (
            value.toISOString().slice(0, 10) !==
            existingDate.toISOString().slice(0, 10)
          );
        }
      )
      .required('Date is required'),
  });

const validationDateFieldNoRequired = (label: string) =>
  yup
    .date()
    .nullable()
    .typeError(`${label} must match date format DD/MM/YYYY.`);

const validationObjectSelect = (label: string) =>
  yup.mixed().required(`${label} must be selected`);

const validationObjectSelectNotRequired = (label: string) =>
  yup.mixed()?.nullable();

const validationEmailField = (
  label: string,
  msg?: string,
  min: number = 5,
  max: number = 100
) =>
  yup
    .string()
    .required(`${label} must be filled.`)
    .min(min, `${label} must be longer than ${min} characters.`)
    .max(max, `${label} must not be longer than ${max} characters.`)
    .matches(/^\S+$/, {
      message: `${label} must not contain space`,
      excludeEmptyString: true,
    })
    .matches(regex.regexEmail, msg || `${label} format is incorrect!`);

const validationEmailFieldNoRequired = (
  label: string,
  min: number = 5,
  max: number = 100
) =>
  yup
    .string()
    .test(
      'minmax',
      `${label} must be longer than ${min} characters.`,
      (val: any) => {
        if (val) return val.length >= min;
        return true;
      }
    )
    .email(`${label} must be in email format`)
    .nullable()
    .max(max, `${label} must not be longer than ${max} characters.`);

const validationTextFieldExactLength = (label: string, length: number) =>
  yup
    .string()
    .required(`${label} must be filled!`)
    .length(length, `${label} must be ${length} characters!`)
    .matches(regex.regexNoSpecialCharacter, 'No Special Character are Allowed');

const validationTextArea = (label: string, maximal: number = 100) =>
  yup
    .string()
    .nullable()
    .max(maximal, `${label} must not be longer than ${maximal} characters.`);

const validationArraySelect = (label: string) =>
  yup
    .array()
    .typeError(`${label} must be selected.`)
    .test(
      'length',
      `${label} must be selected.`,
      (value, context) => Array.isArray(value) && value?.length > 0
    );

const validationCurrencyLength = (
  label: string,
  minimal: number = 0,
  maximal: number = 10
) =>
  yup
    .string()
    .test('check if number', `${label} must be number`, (val: any) => {
      const isNumber = /^\d+$/.test(val);
      if (val === '' || val === undefined) {
        return true;
      }
      return isNumber;
    })
    .test('len', `${label} minimum ${minimal} characters!`, (val: any) => {
      if (val === '' || val === undefined) {
        return true;
      }
      return val.length >= minimal;
    })
    .test('len', `${label} maximum ${maximal} characters!`, (val: any) => {
      if (val === '' || val === undefined) {
        return true;
      }
      return val.length <= maximal;
    })
    .required(`${label} must be filled!`);

const validationNumberLengthNoRequired = (
  label: string,
  minimal: number = 0,
  maximal: number = 10
) =>
  yup
    .string()
    .nullable()
    .test('check if number', `${label} must be number`, (val: any) => {
      const isNumber = /^[0-9]+$/.test(val);
      if (val === '' || val === undefined || val === null) {
        return true;
      }
      return isNumber;
    })
    .test(
      'len',
      `${label} must be longer than ${minimal} characters.`,
      (val: any) => {
        if (val === '' || val === undefined) {
          return true;
        }
        return val?.length >= minimal;
      }
    )
    .test(
      'len',
      `${label} must not be longer than ${maximal} characters.`,
      (val: any) => {
        if (val === '' || val === undefined) {
          return true;
        }
        return val?.length <= maximal;
      }
    );

const validationNumberLength = (
  label: string,
  minimal: number = 0,
  maximal: number = 10
) =>
  yup
    .string()
    .test('check if number', `${label} must be number`, (val: any) => {
      const isNumber = /^[0-9]+$/.test(val);
      if (val === '' || val === undefined) {
        return true;
      }
      return isNumber;
    })
    .test(
      'len',
      `${label} must be longer than ${minimal} characters.`,
      (val: any) => {
        if (val === '' || val === undefined) {
          return true;
        }
        return val.length >= minimal;
      }
    )
    .test(
      'len',
      `${label} must not be longer than ${maximal} characters.`,
      (val: any) => {
        if (val === '' || val === undefined) {
          return true;
        }
        return val.length <= maximal;
      }
    )
    .required(`${label} must be filled!`);

const validationPhoneNumber = (
  label: string,
  minimal: number = 0,
  maximal: number = 10
) =>
  yup.string().when('countryCodeNumber', (val: any, schema: any) => {
    if (val && val.value === '+62') {
      return schema.test('country', (v: any) =>
        /\+?(?:[ -]?\d+)+|(\d+)(?:[ -]\d+)/.test(v)
      );
    }
    if (val && val.value === '+54') {
      return schema.test('country', (v: any) =>
        /^(\+?6?01)[0-46-9]-*[0-9]{7,8}$/.test(v)
      );
    }
    if (val && val.value === '+66') {
      return schema.test('country', (v: any) =>
        /^(()([+]{1}[0-9]{1,3}[ ]{1}[0-9]{1,2}[ ]{1}[0-9]{4}[ ]{1}[0-9]{4})|([+]{1}[0-9]{1,3}[ ]{1}[0-9]{9,10})|([0]{1}[0-9]{1}[ ]{1}[0-9]{3,4}[ ]{1}[0-9]{4})|([0]{1}[0-9]{1}[-]{1}[0-9]{3,4}[-]{1}[0-9]{4})|([(]{1}[0]{1}[0-9]{1}[)]{1}[ ]{1}[0-9]{4}([ ]|[-]){1}[0-9]{4})|([0-9]{4}([ ]|[-])?[0-9]{4})|([0]{1}[0-9]{3}[ ]{1}[0-9]{3}[ ]{1}[0-9]{3})|([0]{1}[0-9]{8,9})|([(]{1}[0-9]{3}[)]{1}[ ]{1}[0-9]{3}[-]{1}[0-9]{4})|([0-9]{3}([/]|[-]){1}[0-9]{3}[-]{1}[0-9]{4})|([1]{1}[-]?[0-9]{3}([/]|[-]){1}[0-9]{3}[-]{1}[0-9]{4})|([1]{1}[0-9]{9}[0-9]?)|([0-9]{3}[.]{1}[0-9]{3}[.]{1}[0-9]{4})|([(]{1}[0-9]{3}[)]{1}[0-9]{3}([.]|[-]){1}[0-9]{4}(([ ]?(x|ext|extension)?)([ ]?[0-9]{3,4}))?)|([1]{1}[(]{1}[0-9]{3}[)]{1}[0-9]{3}([-]){1}[0-9]{4})|([+]{1}[1]{1}[ ]{1}[0-9]{3}[.]{1}[0-9]{3}[-]{1}[0-9]{4})|([+]{1}[1]{1}[ ]?[(]{1}[0-9]{3}[)]{1}[0-9]{3}[-]{1}[0-9]{4}))$/.test(
          v
        )
      );
    }
    return validationNumberLength(label, minimal, maximal);
  });
const validationPhoneNumberNoRequired = (
  label: string,
  minimal: number = 0,
  maximal: number = 10
) =>
  yup.string().when('countryCodeNumber', (val: any, schema: any) => {
    if (val && val.value === '+62') {
      return schema.test('country', (v: any) =>
        /\+?(?:[ -]?\d+)+|(\d+)(?:[ -]\d+)/.test(v)
      );
    }
    if (val && val.value === '+54') {
      return schema.test('country', (v: any) =>
        /^(\+?6?01)[0-46-9]-*[0-9]{7,8}$/.test(v)
      );
    }
    if (val && val.value === '+66') {
      return schema.test('country', (v: any) =>
        /^(()([+]{1}[0-9]{1,3}[ ]{1}[0-9]{1,2}[ ]{1}[0-9]{4}[ ]{1}[0-9]{4})|([+]{1}[0-9]{1,3}[ ]{1}[0-9]{9,10})|([0]{1}[0-9]{1}[ ]{1}[0-9]{3,4}[ ]{1}[0-9]{4})|([0]{1}[0-9]{1}[-]{1}[0-9]{3,4}[-]{1}[0-9]{4})|([(]{1}[0]{1}[0-9]{1}[)]{1}[ ]{1}[0-9]{4}([ ]|[-]){1}[0-9]{4})|([0-9]{4}([ ]|[-])?[0-9]{4})|([0]{1}[0-9]{3}[ ]{1}[0-9]{3}[ ]{1}[0-9]{3})|([0]{1}[0-9]{8,9})|([(]{1}[0-9]{3}[)]{1}[ ]{1}[0-9]{3}[-]{1}[0-9]{4})|([0-9]{3}([/]|[-]){1}[0-9]{3}[-]{1}[0-9]{4})|([1]{1}[-]?[0-9]{3}([/]|[-]){1}[0-9]{3}[-]{1}[0-9]{4})|([1]{1}[0-9]{9}[0-9]?)|([0-9]{3}[.]{1}[0-9]{3}[.]{1}[0-9]{4})|([(]{1}[0-9]{3}[)]{1}[0-9]{3}([.]|[-]){1}[0-9]{4}(([ ]?(x|ext|extension)?)([ ]?[0-9]{3,4}))?)|([1]{1}[(]{1}[0-9]{3}[)]{1}[0-9]{3}([-]){1}[0-9]{4})|([+]{1}[1]{1}[ ]{1}[0-9]{3}[.]{1}[0-9]{3}[-]{1}[0-9]{4})|([+]{1}[1]{1}[ ]?[(]{1}[0-9]{3}[)]{1}[0-9]{3}[-]{1}[0-9]{4}))$/.test(
          v
        )
      );
    }
    return validationNumberLengthNoRequired(label, minimal, maximal);
  });

const validationFileUpload = (label: string) =>
  yup
    .array()
    .required()
    .typeError(`${label} must be selected`)
    .test(
      'length',
      `${label} must be in PDF, JPEG, JPG, PNG or Docx and not larger than 5MB.`,
      (value, context) => {
        const allowedMimeTypes = [
          'application/pdf',
          'image/jpeg',
          'image/png',
          'application/msword',
          'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
        ];
        return (
          Array.isArray(value) &&
          value?.length > 0 &&
          allowedMimeTypes.includes(value?.[0]?.mimeType)
        );
      }
      // Array.isArray(value) && value?.length > 0
    );

const validationExcelFileUpload = (label: string) =>
  yup
    .array()
    .required()
    .typeError(`${label} must be attached`)
    .test(
      'length',
      `${label} format must be in .xlsx, .xls`,
      (value, context) => {
        const allowedMimeTypes = [
          'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
          'application/vnd.ms-excel',
        ];
        return (
          Array.isArray(value) &&
          value?.length > 0 &&
          allowedMimeTypes.includes(value?.[0]?.mimeType)
        );
      }
    );

const validationFileUploadNorequired = (label: string) =>
  yup
    .array()
    .nullable()
    .test(
      'length',
      `${label} must be in PDF, JPEG, JPG, PNG or Docx`,
      (value, _context) => {
        const allowedMimeTypes = [
          'application/pdf',
          'image/jpeg',
          'image/png',
          'application/msword',
          'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
        ];
        const allowedExtensions = ['pdf', 'jpg', 'jpeg', 'msword'];
        const match = value?.[0]?.name?.match(/\.(.*?)$/);
        if (value && value?.length > 0) {
          return (
            Array.isArray(value) &&
            (allowedExtensions.includes(match[1]) ||
              allowedMimeTypes.includes(value?.[0]?.mimeType))
          );
        }
        return true;
      }
    );

const validationTextFieldNoWhitespacesAcceptSymbols = (
  label: string,
  minimal: number = 5,
  maximal: number = 100
) =>
  yup
    .string()
    .typeError(`${label} must be filled.`)
    .required(`${label} must be filled.`)
    .matches(/^\S+$/, {
      message: `${label} must not contain whitespace`,
      excludeEmptyString: true,
    })
    .min(minimal, `${label} must be longer than ${minimal} characters.`)
    .max(maximal, `${label} must not be longer than ${maximal} characters.`);

const validationFormulaField = (label: string) =>
  yup
    .string()
    .typeError(`${label} must be filled.`)
    .required(`${label} must be filled.`);
// .test(
//   'unique',
//   'The formula must not have a duplicated variable from the Name field',
//   (value: any, context: any) => {
//     const { code } = splitNameAndCode(context.parent.payItem.label);
//     if (!code) return true;
//     return !value.includes(code);
//   }
// );
// .matches(/^[\d+\-*/%<>=()^]+$/, `${label} have invalid input`);

const validationNumberFieldTwoDecimals = (label: string, min = 0, max = 100) =>
  yup
    .string()
    .required(`${label} must be filled!`)
    .test('check if number', `${label} must be number`, (val: any) => {
      const isNumber = /^-?\d+(\.\d+)?$/.test(val);
      if (val === '' || val === undefined) {
        return true;
      }
      return isNumber;
    })
    .min(min, `${label} must be longer than ${min} characters.`)
    .max(max, `${label} must not be longer than ${max} characters.`)
    .matches(
      /^\d+(\.\d{1,2})?$/,
      `${label} must not have more than two decimals`
    )
    .nullable();

const validationNumberFieldTwoDecimalsNullable = (
  label: string,
  min = 0,
  max = 100
) =>
  yup
    .string()
    .required(`${label} must be filled!`)
    .nullable()
    .test('check if number', `${label} must be number`, (val: any) => {
      const isNumber = /^-?\d+(\.\d+)?$/.test(val);
      if (val === '' || val === undefined) {
        return true;
      }
      return isNumber;
    })
    .min(min, `${label} must be longer than ${min} characters.`)
    .max(max, `${label} must not be longer than ${max} characters.`)
    .matches(
      /^\d+(\.\d{1,2})?$/,
      `${label} must not have more than two decimals`
    );

const validationArraySelectRequired = (label: string) =>
  yup
    .array()
    .required()
    .typeError(`${label} must be selected`)
    .test(
      'length',
      `${label} must be selected!`,
      (value, context) => Array.isArray(value) && value?.length > 0
    );

const validationSameDate = (label: string) =>
  yup
    .date()
    .nullable()
    // .required(`${label} must be filled`)
    // .typeError(`${label} must match date format DD/MM/YYYY.`)
    .test(
      'unique',
      `The ${label} must not have the same date`,
      (value: any, context: any) => {
        const date = context.parent.publishDate;
        return !value?.toString().includes(date);
      }
    );

const validationCheckbox = (label: string) =>
  yup
    .array()
    .of(yup.string())
    .min(1, `${label} must be filled with at least 1 option.`);

const validationNumberRequiredNoNegative = (label: string) =>
  yup
    .string()
    .required(`${label} must be filled.`)
    .nullable()
    .test(
      'no-negative',
      `${label} must not contain negative number(s)`,
      val => {
        if (val && val.includes('-')) return false;
        return true;
      }
    )
    .matches(/^\d+$/, `${label} must be filled with number.`);
const validationNumberNoRequiredNoNegative = (label: string) =>
  yup
    .string()
    .test(
      'no-negative',
      `${label} must not contain negative number(s)`,
      val => {
        if (val && val.includes('-')) return false;
        return true;
      }
    )
    .matches(/^\d*$/, `${label} must be filled with number.`);

export {
  validationTextField,
  validationTextFieldWithWhiteSpaceAndNoSymbols,
  validationNumberSelect,
  validationPercentNumber,
  validationStringSelect,
  validationBooleanSelect,
  validationTextFieldNoMin,
  validationNumberField,
  validationNumberFieldRequiredWithNoSymbols,
  validationDateField,
  validationObjectSelect,
  validationObjectSelectNotRequired,
  validationTextArea,
  validationEmailField,
  validationTextFieldExactLength,
  validationNumberFieldWithMax,
  validationArraySelect,
  validataionTextEditor,
  validationCurrencyFieldWithMax,
  validationNumberLength,
  validationTextFieldNoSymbol,
  validationCurrencyLength,
  validationTextFieldNoNumbers,
  validationTextFieldOnlyLettersExactLength,
  validationIdentityNumber,
  validationTextFieldNoRequired,
  validationPhoneNumber,
  validationNumberLengthNoRequired,
  validationDateFieldNoMaxFuture,
  validationDateFieldNotSameAsExisting,
  validationDateFieldNoRequired,
  validationFileUpload,
  validationEmailFieldNoRequired,
  validationPhoneNumberNoRequired,
  validationTextFieldNoRequiredWithNoSymbol,
  validationTextFieldNoWhitespaceNoSymbols,
  validationTextFieldNoRequiredWhitespaceNoSymbols,
  validationTextEditorNoRequired,
  validationNumberFieldNoRequiredWithNoSymbols,
  validationTextFieldNoWhitespacesAcceptSymbols,
  validationFormulaField,
  validationTextFieldNoRequiredWhitespaceNoSymbolsNoHyphenUnderscore,
  validationNumberFieldTwoDecimals,
  validationFileUploadNorequired,
  validationArraySelectRequired,
  validationSameDate,
  validationExcelFileUpload,
  validationNumberFieldTwoDecimalsNullable,
  validationTextFieldWhiteSpaceNoSymbolExcUnderscore,
  validationTextFieldNoWhitespaceNoAllSymbols,
  validationCheckbox,
  validationNumberRequiredNoNegative,
  validationNumberNoRequiredNoNegative,
};
