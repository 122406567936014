import { Box, SxProps, useTheme } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { components, GroupBase } from 'react-select';
import { AsyncPaginate, LoadOptions } from 'react-select-async-paginate';
import { AsyncProps } from 'react-select/async';

import { LoadOptionsData } from '../../../store/feature/service/loadOption';
import { ChevronRight, Close } from '../icon';

interface CustomSelectAsyncProps {
  additional: any;
  customLabel: Function;
  url: string;
  unRemoved: any;
  selectAllKey?: string;
}

const SelectWithPaginate = React.forwardRef(
  <
    OptionType,
    IsMulti extends boolean = false,
    GroupType extends GroupBase<OptionType> = GroupBase<OptionType>
  >(
    Props: AsyncProps<OptionType, IsMulti, GroupType> & CustomSelectAsyncProps,
    _ref: any
  ) => {
    const {
      additional,
      customLabel,
      url,
      isDisabled,
      unRemoved,
      classNamePrefix,
      value,
      isMulti,
      onChange,
      selectAllKey,
      ...props
    } = Props;
    const { palette } = useTheme();
    const { DropdownIndicator, ClearIndicator, MultiValueRemove } = components;
    const [trigger] = LoadOptionsData.endpoints.getPaginateData.useLazyQuery();

    const style: SxProps = {
      '& .basic-single': {
        '& .select__menu .select__menu-list': {
          fontSize: '16px',
          fontWeight: '400',
          '& .select__option:not(.select__option--is-disabled)': {
            color: `${palette.gray[100]} !important`,
          },
        },
        '& .select__control': {
          minHeight: '48px',
          boxSizing: 'border-box',
          padding: 0,
          borderRadius: '8px',
          background: isDisabled ? palette.gray[20] : '#FFF',
          '& .select__value-container': {
            paddingX: '12px',
            '& .select__multi-value': {
              height: '24px',
              boxSizing: 'border-box',
              padding: '1px 8px 1px 8px',
              display: 'flex',
              alignItems: 'center',
              background: isDisabled ? palette.gray[40] : palette.blue[20],
              gap: '4px',
              borderRadius: '6px',
              '& .select__multi-value__label': {
                color: isDisabled ? palette.gray[90] : palette.blue[70],
                fontWeight: '500',
                fontSize: '14px',
                // lineHeight: '20px',
              },
              '& .select__multi-value__remove': {
                '&:hover': {
                  background: 'none',
                },
                padding: 0,
                '& .close--icon__multi': {
                  height: '100%',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  '& svg': {
                    transform: 'scale(0.83333)',
                    '&:hover': {
                      cursor: 'pointer',
                    },
                  },
                  '& svg path': {
                    stroke: palette.blue[90],
                  },
                },
              },
            },
          },
          '& .select__indicators .select__indicator': {
            '& .close--icon': {
              '& svg': {
                transform: 'scale(0.83333) !important',
              },
            },
            height: '100%',
            paddingRight: '12px',
            paddingY: 0,
            display: 'flex',
            alignItems: 'center',
            '& svg': {
              transform: 'scale(1.25)',
              rotate: '90deg',
              '& path': {
                stroke: palette.gray[90],
              },
            },
          },
          '& .select__indicators .select__clear-indicator': {
            paddingRight: '0px',
            '& .close--icon': {
              display: 'flex',
              alignItems: 'center',
              height: '100%',
              '& svg': {
                transform: 'scale(1)',
              },
            },
          },
          '& .select__value-container:not(.select__value-container--is-multi)':
            {
              height: '100%',
              padding: 0,
              '& .select__single-value': {
                marginLeft: '0px',
                display: 'flex',
                alignItems: 'center',
                paddingLeft: '13px',
              },
              '& .select__input-container': {
                paddingLeft: '13px',
                margin: 0,
                fontFamily: 'Inter',
                fontSize: '1rem',
                // lineHeight: '24px',
                color: '#101B28',
                height: '100%',
                paddingY: '12px',
              },
              '& .select__placeholder': {
                margin: 0,
                paddingLeft: '13px',
                fontFamily: 'Inter',
                fontSize: '1rem',
                // lineHeight: '24px',
                color: '#A5B1C0',
                height: '100%',
                paddingY: '12px',
              },
            },
        },
        '& .select__control:not(.select__control--is-focused)': {
          border: `1px solid ${palette.gray[50]}`,
        },
        '& .select__control--is-focused': {
          border: `1px solid ${palette.blue[50]}`,
          boxShadow: 'none',
        },
      },
    };

    const IndicatorDropdown = React.useCallback(
      (indicatorDropdownProps: any) => (
        <DropdownIndicator {...indicatorDropdownProps}>
          <Box
            sx={{
              height: '100%',
              display: 'flex',
              alignItems: 'center',
              '& svg': {
                transform: 'scale(0.83333) !important',
              },
            }}
          >
            <ChevronRight />
          </Box>
        </DropdownIndicator>
      ),
      []
    );

    const CloseDropdown = React.useCallback(
      (closeDropdownProps: any) => (
        <ClearIndicator {...closeDropdownProps}>
          <div className="close--icon">
            <Close />
          </div>
        </ClearIndicator>
      ),
      []
    );

    const RemoveIndicator = React.useCallback(
      (removeIndicatorProps: any) => (
        <MultiValueRemove {...removeIndicatorProps}>
          <div className="close--icon__multi">
            <Close />
          </div>
        </MultiValueRemove>
      ),
      []
    );

    const loadOptionsData = async (
      setUrl: string,
      params: {
        page: number;
        limit: number;
        search: string;
        excludes?: string;
      },
      setAdditional: any = {}
    ) =>
      trigger({
        url: setUrl,
        ...params,
      })
        .then(result => {
          const { data: resultData } = result;
          const { data, limit, page, total } = resultData;
          if (isMulti) {
            const transformVal = value as any;
            const isSelectAll =
              transformVal?.length &&
              transformVal?.filter((item: any) => item.label === selectAllKey);
            if (isSelectAll?.length) {
              return {
                options: [],
                hasMore: false,
                additional: {
                  ...additional,
                  page: 1,
                },
              };
            }
          }
          return {
            options: data,
            hasMore: page * limit < total,
            additional: {
              ...additional,
              page: page + 1,
              excludes: params.excludes,
              ...setAdditional,
            },
          };
        })
        .catch(_error => ({
          options: [],
          hasMore: false,
          additional: {
            page: 1,
          },
        }));

    const loadOptions: LoadOptions<any, any, any> = async (
      search,
      _loadedOptions,
      { page, excludes, ...props }
    ) => {
      const result = await loadOptionsData(url, {
        page,
        limit: 50,
        excludes: excludes || '',
        search: search || '',
        ...props,
      });

      if (customLabel) {
        customLabel(result);
      }
      return result;
    };

    const multiValueRemove = (base: any, state: any) => {
      const unRemovedId = unRemoved?.map((x: any) => x.value);
      if (
        isDisabled ||
        (unRemovedId && unRemovedId?.includes(state.data.value))
      ) {
        return {
          ...base,
          display: 'none',
        };
      }

      return base;
    };

    const multiValue = (base: any, state: any) => {
      const unRemovedId = unRemoved?.map((x: any) => x.value);
      if (unRemovedId?.includes(state.data.value)) {
        return {
          ...base,
          color: `${palette.gray[90]} !important`,
          background: `${palette.gray[40]} !important`,
        };
      }
      return base;
    };

    const multiValueLabel = (base: any, state: any): any => {
      const unRemovedId = unRemoved?.map((x: any) => x.value);
      if (unRemovedId?.includes(state.data.value)) {
        return {
          ...base,
          color: `${palette.gray[90]} !important`,
        };
      }
      return base;
    };

    const [cache, setCache] = useState(null);
    const handleChange = (data: any, actionMeta: any) => {
      if (isMulti) {
        const isSelectAll = data?.filter(
          (item: any) => item.label === selectAllKey
        );
        // when select all remove all option cache
        if (isSelectAll?.length) {
          onChange?.(isSelectAll, actionMeta);
          setCache(isSelectAll);
          return;
        }
      }

      // if we clear value, we want to cache to be clear so we can re fatch the option data
      if (actionMeta.action === 'clear') {
        setCache(null);
      }

      // if we remove select all option, we want to cache to be clear so we can re fatch the option data
      if (
        actionMeta.action === 'remove-value' &&
        actionMeta?.removedValue?.label === selectAllKey
      ) {
        setCache(null);
      }
      onChange?.(data, actionMeta);
    };

    return (
      <Box sx={style}>
        <AsyncPaginate
          {...props}
          value={value}
          isDisabled={isDisabled}
          loadOptions={loadOptions}
          additional={{
            page: 1,
            ...additional,
          }}
          isMulti={isMulti}
          debounceTimeout={500}
          className="basic-single"
          classNamePrefix="select"
          onChange={handleChange}
          closeMenuOnSelect={!isMulti}
          components={{
            IndicatorSeparator: null,
            DropdownIndicator: IndicatorDropdown,
            ClearIndicator: CloseDropdown,
            MultiValueRemove: RemoveIndicator,
          }}
          styles={{
            multiValueRemove,
            multiValue,
            multiValueLabel,
          }}
          cacheUniqs={[cache]}
        />
      </Box>
    );
  }
);

export default SelectWithPaginate;
