import { apiSlice } from '../api/apiSlice';

interface IPermission {
  fieldName: string;
  fieldCode: string;
  parentCode: null | string;
  permission: null | Record<string, string> | Array<Record<string, string>>;
}
interface IPayload {
  name: string;
  code: string;
  functions: IPermission[] | null;
}

export const essAccessControlApiSlice = apiSlice.injectEndpoints({
  endpoints: builder => ({
    getListEssAccessControl: builder.query<any, any>({
      query: params => {
        const { search } = params;
        return {
          url: '/security/ess-access-control',
          params: { search },
        };
      },
      providesTags: ['EssAccessControlList'],
    }),
    getFunctionEssAcessControl: builder.query<any, any>({
      query: () => ({
        url: '/security/ess-access-control/functions',
      }),
    }),
    getDetailEssAccessControl: builder.query<any, { id: string }>({
      query: ({ id }) => ({ url: `/security/ess-access-control/${id}` }),
      providesTags: ['EssAccessControlDetail'],
    }),
    createEssAccessControl: builder.mutation<any, { payload: IPayload }>({
      query: ({ payload }) => ({
        url: '/security/ess-access-control',
        method: 'POST',
        body: payload,
      }),
      invalidatesTags: ['EssAccessControlList'],
    }),
    updateEssAccessControl: builder.mutation<any, any>({
      query: ({ id, payload }) => ({
        url: `/security/ess-access-control/${id}`,
        method: 'PUT',
        body: payload,
      }),
      invalidatesTags: ['EssAccessControlDetail'],
    }),
  }),
});

export const {
  useGetFunctionEssAcessControlQuery,
  useGetListEssAccessControlQuery,
  useCreateEssAccessControlMutation,
  useUpdateEssAccessControlMutation,
  useGetDetailEssAccessControlQuery,
} = essAccessControlApiSlice;
