import { RouteNode } from '../../@types';
import { Wallet02 } from '../../components/atom/icon';
import configuration from './configuration';
import payrollRun from './payrollRun';
import payGroup from './payGroup';
import severancePay from './severancePay';

export const Payroll: RouteNode[] = [
  {
    label: 'Payroll',
    icon: Wallet02,
    path: 'payroll',
    component: null,
    child: [...payrollRun, ...payGroup, ...configuration, ...severancePay],
  },
];
