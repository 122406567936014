import { apiSlice } from '../api/apiSlice';

export const AccessControlApiSlice = apiSlice.injectEndpoints({
  endpoints: builder => ({
    getListAccessControl: builder.query({
      query: params => {
        const { search, page, sortType, sort, searchString } = params;
        return {
          url: '/organization/access-data-controls',
          params: { search, page, sortType, sort, ...searchString },
        };
      },
      providesTags: ['accessControlList'],
    }),
    getDetailAccessControl: builder.query({
      query: ({ id }) => ({
        url: `/organization/access-data-controls/${id}`,
      }),
      providesTags: ['accessControlDetail'],
    }),
    getAccessDatas: builder.query<any, any>({
      query: params => ({
        url: '/organization/access-data-controls/specific-data-access',
        params,
      }),
    }),
    getAccessFunctions: builder.query<any, any>({
      query: params => ({
        url: '/organization/access-data-controls/specific-function-access',
        params,
      }),
    }),
    createAccessControl: builder.mutation({
      query: (params: { body: any }) => {
        const { body } = params;
        return {
          method: 'POST',
          url: '/organization/access-data-controls',
          body,
        };
      },
      invalidatesTags: ['accessControlList'],
    }),
    updateAccessControl: builder.mutation({
      query: (params: { id: any; body: any }) => {
        const { id, body } = params;
        return {
          method: 'PUT',
          url: `/organization/access-data-controls/${id}`,
          body,
        };
      },
      invalidatesTags: ['accessControlDetail'],
    }),
    deleteAccessControl: builder.mutation({
      query: (params: any) => {
        const { id } = params;
        return {
          method: 'DELETE',
          url: `/organization/access-data-controls/${id}`,
        };
      },
      invalidatesTags: ['accessControlDetail', 'accessControlList'],
    }),
  }),
});

export const {
  useGetListAccessControlQuery,
  useGetAccessDatasQuery,
  useGetAccessFunctionsQuery,
  useGetDetailAccessControlQuery,
  useCreateAccessControlMutation,
  useUpdateAccessControlMutation,
  useDeleteAccessControlMutation,
} = AccessControlApiSlice;
