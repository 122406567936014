import { apiSlice } from '../api/apiSlice';

export const holidayApiSlice = apiSlice.injectEndpoints({
  endpoints: builder => ({
    getListHoliday: builder.query<any, any>({
      query: params => {
        const { search, page, sortType, sortBy, limit, searchString } = params;
        return {
          url: '/time-management/holidays',
          params: { search, page, sortType, sortBy, limit, ...searchString },
        };
      },
      providesTags: ['ListHoliday'],
    }),
    getDetailHoliday: builder.query<
      any,
      { holidayID: string; effectiveDate: number | string }
    >({
      query: params => {
        const { holidayID, effectiveDate } = params;
        return {
          url: `/time-management/holidays/${holidayID}`,
          params: { effectiveDate },
        };
      },
    }),
    createHoliday: builder.mutation<
      {
        body: {
          citizenship: null | Array<{ label: string; value: string }>;
          code: null | Array<{ label: string; value: string }>;
          createHolidayDetailRequest: Array<{
            date: number;
            id: string;
            name: string;
            type: string;
          }>;
          effectiveDate: number;
          entitledTo: string;
          legalEntity: Array<{ label: string; value: string }>;
          name: string;
          workLocation: Array<{ label: string; value: string }>;
        };
      },
      any
    >({
      query: params => {
        const { body } = params;
        return {
          url: '/time-management/holidays',
          method: 'POST',
          body,
        };
      },
      invalidatesTags: ['ListHoliday'],
    }),
    cancelUpcomingHoliday: builder.mutation<any, { id: string }>({
      query: params => {
        const { id } = params;
        return {
          url: `/time-management/holidays/${id}/cancellation`,
          method: 'DELETE',
        };
      },
      invalidatesTags: ['ListHoliday'],
    }),
  }),
});

export const {
  useGetListHolidayQuery,
  useCreateHolidayMutation,
  useGetDetailHolidayQuery,
  useCancelUpcomingHolidayMutation,
} = holidayApiSlice;
