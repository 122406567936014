import { Box, Grid, Typography, useTheme } from '@mui/material';
import { ReactNode } from 'react';

interface ITaskItemProps {
  icon?: ReactNode;
  title: string;
  image?: ReactNode;
  description: string;
  caption: string;
  onClick: Function;
}

const TaskItem = ({
  icon,
  title,
  image,
  description,
  caption,
  onClick,
}: ITaskItemProps) => {
  const { palette } = useTheme();
  return (
    <Grid item container gap="8px">
      {icon && (
        <Grid item>
          <Box
            sx={{
              width: '24px',
              height: '24px',
              display: 'flex',
              alignItems: 'center',
            }}
          >
            {icon}
          </Box>
        </Grid>
      )}
      <Grid xs item container gap="8px">
        <Grid
          item
          container
          alignItems="center"
          onClick={() => {
            onClick();
          }}
          sx={{
            cursor: 'pointer',
          }}
        >
          <Typography
            variant="body16"
            fontWeight={600}
            color={palette.gray[100]}
          >
            {title}
          </Typography>
        </Grid>
        <Grid item container gap="16px">
          <Grid
            item
            sx={{
              borderRadius: '40px',
              width: '40px',
              height: '40px',
              backgroundColor: palette.gray[10],
              overflow: 'hidden',
              border: `1px solid ${palette.gray[20]}`,
            }}
          >
            {image}
          </Grid>
          <Grid item container direction="column" gap="4px" xs>
            <Grid item>
              <Typography
                variant="body14"
                fontWeight={400}
                color={palette.gray[90]}
              >
                {description}
              </Typography>
            </Grid>
            <Grid item>
              <Typography
                variant="body12"
                fontWeight={400}
                color={palette.gray[80]}
              >
                {caption}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default TaskItem;
