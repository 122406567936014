import { Box, Grid } from '@mui/material';
import { useParams } from 'react-router-dom';
import { useAppSelector } from '../../../../store';
import { objectMapper } from '../../../../utils/objectMap';
import LabelText from '../../../../components/atom/labelText/LabelText';
import HeaderAction from '../../../../components/molecule/action/HeaderAction';
import HistoricalTimeLine from '../../../../components/molecule/historicalTimeLine/HistoricalTimeLine';
import SubHeader from '../../../../components/molecule/subHeader/SubHeader';
import { useGetESSBankDetailQuery } from '../../../../store/feature/service/essEmployee';

const BankSection = ({ index, headerKey, groupId }: any) => {
  const { id } = useParams();
  const selectedHeader = `employee/bank/${groupId}`;
  const { headerDate } = useAppSelector(state => state.utils);
  const { data } = useGetESSBankDetailQuery({
    id,
    dataId: groupId,
    effectiveDate: headerDate[selectedHeader],
  });

  const currentData: { [key: string]: any } = {
    ...objectMapper(data?.data?.old || {}),
    effectiveDate: (
      <HistoricalTimeLine headersState={data} selectedTab={selectedHeader} />
    ),
  };

  const upcomingData: { [key: string]: any } = {
    ...objectMapper(data?.data?.new || {}),
  };

  return (
    <Grid item container spacing={1}>
      {index === 0 && (
        <Grid item xs={12}>
          <HeaderAction label="Bank Info" labelAdd="Bank" />
        </Grid>
      )}
      <Grid item xs={12}>
        <Box
          sx={{
            '& .list': {
              display: 'flex',
              padding: '8px 12px',
            },
            '& .sub-title': {
              fontWeight: 700,
            },
            display: 'flex',
            flexDirection: 'column',
            gap: '20px',
          }}
        >
          <SubHeader
            label="Bank"
            idx={index}
            data={data}
            isCorrectionEnabled={false}
            onClickMenuAction={() => {}}
          />
        </Box>
      </Grid>
      {Object.keys(headerKey).map((key: any) => (
        <Grid item xs={12}>
          <LabelText
            headerKey={headerKey[key]}
            currentData={currentData?.[key]}
            upcommingData={upcomingData?.[key]}
            dataPresentationStatus={data?.dataPresentationStatus}
          />
        </Grid>
      ))}
    </Grid>
  );
};

export default BankSection;
