import {
  PayItemDataType,
  PayItemDataList,
  PayItemResponse,
} from '../../../@types/PayItem';
import { apiSlice } from '../api/apiSlice';

type queryParams = {
  page: number | string;
  limit: number;
  sort: string;
  sortType: string;
  search: string;
  searchString: { [key: string]: any };
};

const valuationCodeKey: { [key: string]: any } = {
  Monetary: 'MONETARY',
  'Non Monetary': 'NON_MONETARY',
};

const isPayableCode: { [key: string]: any } = {
  Yes: '1',
  No: '0',
};

const statusCode: { [key: string]: any } = {
  Active: 'ACTIVE',
  Inactive: 'INACTIVE',
};

export const PayItemApiSlice = apiSlice.injectEndpoints({
  endpoints: builder => ({
    getPayItemList: builder.query<PayItemDataList, queryParams>({
      query: params => {
        const { search, page, sortType, sort, searchString } = params;
        const transformSearchString = {
          ...searchString,
          statusCode: searchString?.statusCode
            ? statusCode[searchString.statusCode]
            : '',
        };
        return {
          url: `/payroll/pay-items`,
          params: { search, page, sortType, sort, ...transformSearchString },
        };
      },
      providesTags: ['PayItemList'],
    }),
    getPayItemDetails: builder.query<PayItemDataType, any>({
      query: params => {
        const { effectiveDate, id } = params;
        return {
          url: `/payroll/pay-items/${id}`,
          params: {
            effectiveDate,
          },
        };
      },
      providesTags: ['PayItemDetail'],
    }),
    addPayItem: builder.mutation<PayItemResponse, any>({
      query: body => ({
        url: '/payroll/pay-items',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['PayItemList'],
    }),
    editPayItem: builder.mutation({
      query: ({ body, id }: any) => ({
        url: `/payroll/pay-items/${id}`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: ['PayItemList', 'PayItemDetail'],
    }),
    deletePayItem: builder.mutation<any, any>({
      query: groupId => ({
        url: `/payroll/pay-items/${groupId}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['PayItemList'],
    }),
    getPayItemInfo: builder.query<any, any>({
      query: params => {
        const { id } = params;
        return {
          url: `/payroll/pay-items/${id}/info`,
        };
      },
    }),
    cancelUpcomingChangesPayItem: builder.mutation<any, any>({
      query: ({ newId }) => ({
        url: `payroll/pay-items/${newId}/cancellation`,
        method: 'DELETE',
      }),
      invalidatesTags: ['PayItemDetail', 'PayItemList'],
    }),
  }),
});

export const {
  useGetPayItemListQuery,
  useGetPayItemDetailsQuery,
  useAddPayItemMutation,
  useEditPayItemMutation,
  useDeletePayItemMutation,
  useGetPayItemInfoQuery,
  useCancelUpcomingChangesPayItemMutation,
} = PayItemApiSlice;
