import { apiSlice } from '../api/apiSlice';
import { getCookie } from '../../../utils/cookies';

type LoginData = {
  userName: string;
  password: string;
  grantType: string;
};

type LoginResponse = {
  exp: number;
  refreshToken: string;
  token: string;
  userName: string;
  tenants: any;
};

export const authApiSlice = apiSlice.injectEndpoints({
  endpoints: builder => ({
    sendOtp: builder.mutation({
      query: credentials => ({
        url: '/identity/authenticate/request-login-otp',
        method: 'POST',
        body: credentials,
      }),
    }),
    login: builder.mutation<LoginResponse, any>({
      query: creadentials => ({
        url: '/identity/authenticate/request-token/otp',
        method: 'POST',
        body: {
          otp: creadentials.otp,
          otpToken: getCookie('app__token__otp'),
        },
      }),
    }),
    logout: builder.mutation<any, void>({
      query: () => ({
        url: '/identity/authenticate/logout',
        method: 'POST',
      }),
    }),
    resendOtp: builder.mutation<any, any>({
      query: creadentials => ({
        url: '/identity/authenticate/resend-otp',
        method: 'POST',
        body: creadentials,
      }),
    }),
    validateEmail: builder.mutation({
      query: payload => ({
        url: '/identity/authenticate/validate-email',
        method: 'POST',
        body: payload,
      }),
    }),
    validateEmailSSO: builder.mutation({
      query: payload => ({
        url: '/identity/authenticate/validate-email-sso',
        method: 'POST',
        body: payload,
      }),
    }),
    authenticateSSO: builder.mutation({
      query: payload => ({
        url: '/identity/authenticate/sso',
        method: 'POST',
        body: payload,
      }),
    }),
  }),
});

export const {
  useSendOtpMutation,
  useLoginMutation,
  useLogoutMutation,
  useResendOtpMutation,
  useValidateEmailMutation,
  useValidateEmailSSOMutation,
  useAuthenticateSSOMutation,
} = authApiSlice;
