import chartOfAccount from './chartOfAccount';
import { RouteNode } from '../../@types';
import { File07 } from '../../components/atom/icon';

export const AccountingRoute: RouteNode[] = [
  {
    icon: File07,
    label: 'Accounting',
    path: 'accounting',
    component: null,
    child: [...chartOfAccount],
  },
];
