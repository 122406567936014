import {
  AccumulatorFormType,
  AccumulatorPayItem,
} from '../@types/AccumulatorTypes';

const headerKey = {
  name: 'Name',
  code: 'Code',
  totalPayItem: 'Total Pay Item',
  typeName: 'Type',
};

const payItemHeaderKey = {
  name: 'Pay Item',
  code: 'Code',
  operators: 'Operator',
  contributionPercentage: 'Contribution',
};

const detailKey: { [key: string]: string } = {
  effectiveDate: 'Effective Date',
  name: 'Name',
  code: 'Code',
  type: 'Type',
};

const locations = {
  list: '/payroll/configuration/accumulator',
  detail: '/payroll/configuration/accumulator/detail',
  create: '/payroll/configuration/accumulator/create',
  update: '/payroll/configuration/accumulator/update',
  correction: '/payroll/configuration/accumulator/correction',
};

const defaultValueAccumulatorForm: AccumulatorFormType = {
  name: '',
  code: '',
  effectiveDate: '',
  reason: '',
  accumulatorPayItems: [],
};

const defaultValuePayItem: AccumulatorPayItem = {
  id: '',
  name: '',
  code: '',
  operators: 'ADD',
  contributionPercentage: '',
};

export default {
  headerKey,
  detailKey,
  locations,
  defaultValueAccumulatorForm,
  payItemHeaderKey,
  defaultValuePayItem,
};
