import { Box, useTheme, SxProps, Typography } from '@mui/material';
import { ESSAvatarProfile } from '../../../components/atom';
import { mergeDeep } from '../../../utils/deepMerge';

type EmployeeProfileProps = {
  data: any;
  className?: string;
  sx?: SxProps;
};

const EmployeeProfile = ({ data, className, sx }: EmployeeProfileProps) => {
  const { palette } = useTheme();
  const style: SxProps = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    '&. employee-number': {
      fontWeight: 400,
    },
    '& .employee-details': {
      mt: '1rem',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      gap: '4px',
      textAlign: 'center',
    },
    '& .employee-name': {
      fontWeight: 600,
    },
  };
  return (
    <Box sx={mergeDeep(style, sx)} className={className}>
      <ESSAvatarProfile src={data?.profileUrl || ''} />
      <Box className="employee-details">
        <Typography
          className="employee-number"
          variant="body12"
          color={palette.gray[90]}
        >
          {data?.employeeNumber || ''}
        </Typography>
        <Typography
          className="employee-name"
          variant="body16"
          color={palette.gray[100]}
        >
          {data?.fullName || ''}
        </Typography>
        <Typography
          className="employee-number"
          variant="body14"
          color={palette.gray[100]}
        >
          {data?.legalEntityName || ''}
        </Typography>
      </Box>
    </Box>
  );
};

export default EmployeeProfile;
