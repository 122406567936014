/**
 * Return true if url has setting url
 * @returns {boolean}
 */
export const hasSettingUrl = () => {
  // list of first url in setting sidebar
  const settingUrl = ['custom-field', 'user-interface', 'security'];
  const firstParams = window.location.pathname.split('/');
  return settingUrl.includes(firstParams[1]);
};

export const isEssRoute = window.location.pathname.split('/')[1] === 'ess';
