import { createSlice } from '@reduxjs/toolkit';
import { EmploymentTypeApiSlice } from './service/employmentTypeSlice';

type IinitialState = {
  messageSuccess: string;
  messageError: string;
};

const initialState: IinitialState = {
  messageSuccess: '',
  messageError: '',
};

const employmentTypeSlice = createSlice({
  name: ' employmentType',
  initialState,
  reducers: {
    removeMessage: (state: IinitialState) => {
      state.messageError = '';
      state.messageSuccess = '';
    },
  },
  extraReducers(builder: any) {
    builder.addMatcher(
      EmploymentTypeApiSlice.endpoints.updateEmploymentType.matchFulfilled,
      (state: IinitialState, action: any) => {
        if (action?.payload?.typeOfChange === 'UPDATED') {
          state.messageSuccess = 'Employment Type updated';
        } else if (action?.payload?.typeOfChange === 'CORRECTED') {
          state.messageSuccess = 'Employment Type corrected';
        }
      }
    );
    builder.addMatcher(
      EmploymentTypeApiSlice.endpoints.updateEmploymentType.matchRejected,
      (state: IinitialState, action: any) => {
        state.messageError = action?.payload?.type?.message;
      }
    );
    builder.addMatcher(
      EmploymentTypeApiSlice.endpoints.deleteEmploymentType.matchFulfilled,
      (state: any, _action: any) => {
        state.messageSuccess = 'Employment Type deleted';
      }
    );
    builder.addMatcher(
      EmploymentTypeApiSlice.endpoints.addEmploymentType.matchFulfilled,
      (state: any, _action: any) => {
        state.messageSuccess = 'Employment Type created';
      }
    );
    builder.addMatcher(
      EmploymentTypeApiSlice.endpoints.cancelUpcomingEmploymentType
        .matchFulfilled,
      (state: any, _action: any) => {
        state.messageSuccess = 'Upcoming Changes cancelled';
      }
    );
    builder.addMatcher(
      EmploymentTypeApiSlice.endpoints.cancelUpcomingEmploymentType
        .matchRejected,
      (state: any, action: any) => {
        state.messageError = action?.payload?.message;
      }
    );
    builder.addMatcher(
      EmploymentTypeApiSlice.endpoints.deleteEmploymentType.matchRejected,
      (state: any, action: any) => {
        console.log(action);
        state.messageError = action?.payload?.data?.message;
      }
    );
    builder.addMatcher(
      EmploymentTypeApiSlice.endpoints.addEmploymentType.matchRejected,
      (state: any, action: any) => {
        const payload = action.payload as any;
        state.messageError = payload?.data?.message;
      }
    );
  },
});

export const { removeMessage } = employmentTypeSlice.actions;

export const employmentTypeReducer = employmentTypeSlice.reducer;
