import { createSlice } from '@reduxjs/toolkit';
import { ChartOfAccountApiSlice } from './service/accounting/chartOfAccount';

interface initialStateI {
  messageSuccess: string;
  messageError: string;
}

const initialState: initialStateI = {
  messageSuccess: '',
  messageError: '',
};

const chartOfAccount = createSlice({
  name: 'chartOfAccount',
  initialState,
  reducers: {
    removeMessage: state => {
      state.messageSuccess = '';
      state.messageError = '';
    },
  },
  extraReducers(builder) {
    builder.addMatcher(
      ChartOfAccountApiSlice.endpoints.createChartOfAccount.matchFulfilled,
      (state, _action) => {
        state.messageSuccess = 'Account created';
      }
    );
    builder.addMatcher(
      ChartOfAccountApiSlice.endpoints.createChartOfAccount.matchRejected,
      (state, action) => {
        const payload = action.payload as any;
        state.messageError = payload?.data?.message;
      }
    );
  },
});

export const { removeMessage } = chartOfAccount.actions;
export const chartOffAccountReducers = chartOfAccount.reducer;
