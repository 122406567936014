import { createSlice } from '@reduxjs/toolkit';
import { LoadOptionsData } from './service/loadOption';

interface initialStateI {
  data: any;
}

const initialState: initialStateI = {
  data: null,
};

const loadOptionSlice = createSlice({
  name: 'loadOption',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder.addMatcher(
      LoadOptionsData.endpoints.getPaginateData.matchFulfilled,
      (state: initialStateI, payload: any) => {
        state.data = payload?.payload?.data;
      }
    );
  },
});

export const loadOptionReducer = loadOptionSlice.reducer;
