import { Box, SxProps } from '@mui/material';
import { mergeDeep } from '../../../utils/deepMerge';
import { Button } from '../../atom';

type DraftConfirmationButtonProps = {
  negativeTitle?: string;
  positiveTitle?: string;
  onClickCancel?: Function;
  onSubmit?: Function;
  onClickSaveDraft?: Function;
  sx?: SxProps;
  isDisabledSubmitButton?: boolean;
  isDisabledSaveDraft?: boolean;
};

const DraftConfirmationButton = ({
  negativeTitle = 'Cancel',
  positiveTitle = 'Save',
  onClickCancel,
  onSubmit,
  onClickSaveDraft,
  sx,
  isDisabledSubmitButton = false,
  isDisabledSaveDraft = false,
}: DraftConfirmationButtonProps) => {
  const style: SxProps = {
    display: 'flex',
    gap: '1.5rem',
    justifyContent: 'flex-end',
  };
  return (
    <Box sx={mergeDeep(style, sx)}>
      <Button onClick={() => onClickCancel?.()} variant="tertiary">
        {negativeTitle}
      </Button>
      {/* <Button
        onClick={() => onClickSaveDraft?.()}
        variant="outlined"
        disabled={isDisabledSaveDraft}
      >
        Save as Draft
      </Button> */}
      <Button onClick={() => onSubmit?.()} disabled={isDisabledSubmitButton}>
        {positiveTitle}
      </Button>
    </Box>
  );
};

export default DraftConfirmationButton;
