import { createSlice } from '@reduxjs/toolkit';
import { customFieldApiSlice } from './service/customFieldApiSlice';

interface initialStateI {
  messageSuccess: string;
  messageError: string;
}

const initialState: initialStateI = {
  messageSuccess: '',
  messageError: '',
};

const customFieldSlice = createSlice({
  name: 'customField',
  initialState,
  reducers: {
    removeMessage: state => {
      state.messageSuccess = '';
      state.messageError = '';
    },
  },
  extraReducers(builder) {
    builder.addMatcher(
      customFieldApiSlice.endpoints.creategetCustomField.matchFulfilled,
      (state, _action) => {
        state.messageSuccess = 'Custom Field created';
      }
    );
    builder.addMatcher(
      customFieldApiSlice.endpoints.updateCustomField.matchFulfilled,
      (state, _action) => {
        state.messageSuccess = 'Custom Field updated';
      }
    );
    builder.addMatcher(
      customFieldApiSlice.endpoints.deleteCustomField.matchFulfilled,
      (state, _action) => {
        state.messageSuccess = 'Custom Field deleted';
      }
    );
    builder.addMatcher(
      customFieldApiSlice.endpoints.deleteCustomField.matchRejected,
      (state, action) => {
        state.messageError = action.payload.data?.message;
      }
    );
  },
});

export const { removeMessage } = customFieldSlice.actions;
export const customFieldReducer = customFieldSlice.reducer;
