import { EmployeeFileDataType, FileDataType } from '../../../@types/FileType';
import {
  WrapperDetailType,
  WrapperPaginationType,
} from '../../../@types/WrapperTypes';
import { apiSlice } from '../api/apiSlice';
import { DetailParams, QueryParams } from '../../../@types/QueryParams';

export const fileApiSlice = apiSlice.injectEndpoints({
  endpoints: builder => ({
    getFileList: builder.query<
      WrapperPaginationType<FileDataType>,
      QueryParams
    >({
      query: params => {
        const { search, page, limit } = params;
        return {
          url: `/organization/organization-files`,
          params: { search, page, limit },
        };
      },
      providesTags: ['fileList'],
    }),
    getFileEmployeeList: builder.query<
      WrapperPaginationType<EmployeeFileDataType>,
      QueryParams
    >({
      query: params => {
        const { page, id, search, searchString } = params;
        return {
          url: `/organization/organization-files/${id}/employees`,
          params: { page, search, ...searchString },
        };
      },
    }),
    createFile: builder.mutation<FileDataType, FileDataType>({
      query: payload => ({
        url: '/organization/organization-files',
        method: 'POST',
        body: payload,
      }),
      invalidatesTags: ['fileList'],
    }),
    updateFile: builder.mutation<FileDataType, FileDataType>({
      query: payload => ({
        url: `/organization/organization-files/${payload.id}`,
        method: 'PUT',
        body: payload,
      }),
      invalidatesTags: ['fileList'],
    }),
    getFileDetails: builder.query<
      WrapperDetailType<FileDataType>,
      DetailParams
    >({
      query: params => {
        const { id, effectiveDate } = params;
        return {
          url: `/organization/organization-files/${id}`,
          params: {
            effectiveDate,
          },
        };
      },
    }),
    deleteFile: builder.mutation<any, { id: string }>({
      query: params => {
        const { id } = params;
        return {
          url: `/organization/organization-files/${id}`,
          method: 'DELETE',
        };
      },
      invalidatesTags: ['fileList'],
    }),
  }),
});

export const {
  useGetFileListQuery,
  useCreateFileMutation,
  useDeleteFileMutation,
  useGetFileEmployeeListQuery,
  useGetFileDetailsQuery,
  useUpdateFileMutation,
} = fileApiSlice;
