import jwt from 'jwt-decode';
import LOCAL_STORAGE_KEY from '../constants/localStorage';
import { getCookie, setCookie } from './cookies';

const {
  TOKEN_STORAGE,
  EXPIRE_TOKEN_TIME,
  REFRESH_TOKEN,
  FULL_NAME,
  USER_ACCESS,
  USER_EMAIL,
  OTP_TIME,
  PROFILE_PICTURE,
  RESEND_OTP_TOKEN,
} = LOCAL_STORAGE_KEY;

// export function setToken(value: any, exp: number) {
//   setCookie(TOKEN_STORAGE, value, exp);
// }

export function getToken() {
  return getCookie(TOKEN_STORAGE) || '';
}

export function setExpiredToken(value: any, exp: number) {
  setCookie(EXPIRE_TOKEN_TIME, value, exp);
}

export function setFullName(value: string, exp: number) {
  setCookie(FULL_NAME, value, exp);
}

export function setProfilePicture(value: string, exp: number) {
  setCookie(PROFILE_PICTURE, value, exp);
}

export function getProfilePicture() {
  return getCookie(PROFILE_PICTURE);
}

export function getFullName() {
  if (getToken()) {
    try {
      const decodeJwt: any = jwt(getToken());
      return decodeJwt?.username?.toString() || '';
    } catch (error) {
      return 'Administrator';
    }
  }
  return '';
}

export function getUserId() {
  if (getToken()) {
    try {
      const decodeJwt: any = jwt(getToken());
      return decodeJwt?.id?.toString() || '';
    } catch (error) {
      return '';
    }
  }
  return '';
}

export function getUserEmail() {
  if (getToken()) {
    try {
      const decodeJwt: any = jwt(getToken());
      return decodeJwt?.email?.toString() || '';
    } catch (error) {
      return 'administrator@orisoft.co.id';
    }
  }
  return '';
}

export function checkExpireTime() {
  const expiresAt = getCookie(EXPIRE_TOKEN_TIME);
  if (!expiresAt) return false;

  const time = new Date().getTime();

  const expire = new Date(getCookie(EXPIRE_TOKEN_TIME) || 0).getTime();

  return time > expire;
}

export function clearStorage() {
  localStorage.removeItem(TOKEN_STORAGE);
  localStorage.removeItem(EXPIRE_TOKEN_TIME);
  localStorage.removeItem(FULL_NAME);
  localStorage.removeItem(REFRESH_TOKEN);
  localStorage.removeItem(USER_EMAIL);
  localStorage.removeItem(USER_ACCESS);
  localStorage.removeItem(PROFILE_PICTURE);
}

export function setRefreshToken(value: string, exp: number) {
  setCookie(REFRESH_TOKEN, value, exp);
}

export function getRefreshToken() {
  return getCookie(REFRESH_TOKEN) || '';
}

export function setEmail(email: string, exp: number) {
  setCookie(USER_EMAIL, email, exp);
}

export function getEmail() {
  return getCookie(USER_EMAIL) || '';
}

export function saveEndTime(time: number, exp: number) {
  setCookie(OTP_TIME, time.toString(), exp);
}

export function getEndTime() {
  return getCookie(OTP_TIME) || '';
}

export function clearEndTime() {
  localStorage.removeItem(OTP_TIME);
}

export function setTokenResendOtp(token: string, exp: number) {
  setCookie(RESEND_OTP_TOKEN, token, exp);
}

export function getTokenResendOtp() {
  return getCookie(RESEND_OTP_TOKEN) || '';
}
