const LogoMark = () => (
  <svg
    style={{ minWidth: '24px', minHeight: '24px' }}
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.0853 18.1542C13.2886 17.1735 14.1912 15.8858 14.6931 14.4338C14.6931 14.4338 15.021 13.641 14.9713 11.7763V5.02634C14.9713 4.00986 15.1974 2.9203 15.7719 2.08098C16.3916 1.22581 17.2579 0.571159 18.2598 0.200828C18.9053 -0.0195986 19.6008 -0.0594775 20.2682 0.08567C21.1692 0.280769 21.9937 0.725936 22.643 1.3679C23.4636 2.20652 23.9457 3.30808 24 4.46827L23.9073 11.3755C23.853 12.5448 23.7897 13.8115 23.7648 14.1414C23.6766 15.2842 23.5138 15.4192 23.2491 15.8577C23.2491 15.8577 21.8921 17.9018 19.9651 18.8717C19.9651 18.8717 18.2937 19.8882 14.9532 19.4431C13.9271 19.1824 12.9562 18.7461 12.0853 18.1542V18.1542Z"
      fill="url(#paint0_linear_11062_3873)"
    />
    <path
      d="M2.18473 9.79427C2.40949 9.78529 2.62959 9.72882 2.82985 9.62876V9.63635C5.75852 8.18575 7.48538 6.92121 8.46355 6.03641C8.62911 5.88855 8.75995 5.70748 8.84709 5.5056C8.93423 5.30373 8.97562 5.08585 8.96842 4.86689C8.96121 4.64793 8.90559 4.43308 8.80535 4.23709C8.70512 4.04109 8.56265 3.86859 8.38771 3.7314C5.93743 1.78713 3.82941 0.693482 2.43703 0.123872C2.2208 0.0329533 1.98626 -0.00886578 1.75108 0.00156485C1.51589 0.0119955 1.2862 0.0744028 1.07931 0.184088C0.872414 0.293773 0.693713 0.447869 0.556672 0.634774C0.419632 0.821678 0.327822 1.03652 0.288165 1.26309C-0.318572 4.80796 0.146206 7.28956 0.642096 8.74776C0.711876 8.95657 0.827207 9.14811 0.980134 9.30919C1.13306 9.47027 1.31995 9.59705 1.52791 9.68079C1.73587 9.76454 1.95996 9.80326 2.18473 9.79427Z"
      fill="#CC2228"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.0136 12.1778C1.71152 12.3303 1.3795 12.4164 1.04043 12.4301C0.709384 12.4434 0.379308 12.3875 0.0718994 12.2661L0.0752111 12.6002C0.0792363 13.2025 0.141333 13.8031 0.260672 14.394C0.355664 14.8679 0.471011 15.3728 0.568264 15.7227C0.905259 16.9163 1.67198 18.1587 2.81414 19.6624C2.81414 19.6624 5.72949 23.4138 10.8116 23.9498C10.8116 23.9498 15.9547 24.6141 19.7996 21.1151C19.7996 21.1151 23.0745 18.6548 23.6083 15.1491C23.6083 15.1491 21.8193 17.8354 20.028 18.6548C20.028 18.6548 16.8616 20.5194 12.7159 18.3934C11.2336 17.4544 10.1094 16.0625 9.51785 14.4338C9.51785 14.4338 8.90493 12.9235 9.03611 9.54629C9.04519 9.34615 9.04212 9.146 9.03909 8.94917C9.03759 8.852 9.03611 8.75563 9.03611 8.66047L9.02768 7.93823C7.46993 9.13088 5.21892 10.5855 2.0136 12.1894V12.1778Z"
      fill="url(#paint1_linear_11062_3873)"
    />
    <defs>
      <linearGradient
        id="paint0_linear_11062_3873"
        x1="17.9545"
        y1="1.02907"
        x2="18.1084"
        y2="20.6966"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.2" stopColor="#CB2127" />
        <stop offset="0.37" stopColor="#C82126" />
        <stop offset="0.48" stopColor="#C01F25" />
        <stop offset="0.57" stopColor="#B11D22" />
        <stop offset="0.66" stopColor="#9B191E" />
        <stop offset="0.74" stopColor="#801519" />
        <stop offset="0.81" stopColor="#5E0F12" />
        <stop offset="0.88" stopColor="#37090A" />
        <stop offset="0.95" stopColor="#090202" />
        <stop offset="0.96" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_11062_3873"
        x1="2.40931"
        y1="8.42571"
        x2="20.6243"
        y2="23.1384"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.51" stopColor="#CB2127" />
        <stop offset="0.7" stopColor="#C92127" />
        <stop offset="0.77" stopColor="#C22025" />
        <stop offset="0.82" stopColor="#B71E23" />
        <stop offset="0.86" stopColor="#A61B20" />
        <stop offset="0.89" stopColor="#90171C" />
        <stop offset="0.92" stopColor="#741316" />
        <stop offset="0.95" stopColor="#540E10" />
        <stop offset="0.98" stopColor="#2F0809" />
        <stop offset="1" />
      </linearGradient>
    </defs>
  </svg>
);

export default LogoMark;
