import { createSlice } from '@reduxjs/toolkit';

import LOCAL_STORAGE_KEY from '../../constants/localStorage';
import {
  deleteCookie,
  getCookie,
  getTld,
  setCookie,
} from '../../utils/cookies';
import { isDevelopment } from '../../utils/env';
import { getFullName, getToken } from '../../utils/storage';
import { authApiSlice } from './service/authApiSlice';

const { TOKEN_STORAGE, EXPIRE_TOKEN_TIME, REFRESH_TOKEN } = LOCAL_STORAGE_KEY;

interface initialStateI {
  token: string | null;
  isLoading: boolean;
  fullName: string | null;
  messageError: string;
  messageSuccess: string;
  errorCode: string;
  parameterError: string;
  refreshToken: string | null;
  resendOTPToken: string | null;
  email: string | null;
  clientId: string;
  clientSecret: string;
  authority: string;
  scope: string;
  isRedirectSignInSSO: boolean;
}

const initialState: initialStateI = {
  token: getToken().toString(),
  fullName: getFullName().toString(),
  isLoading: false,
  messageSuccess: '',
  messageError: '',
  errorCode: '',
  parameterError: '',
  refreshToken: null,
  resendOTPToken: null,
  email: null,
  clientId: getCookie(LOCAL_STORAGE_KEY.CLIENT_ID) || '',
  clientSecret: getCookie(LOCAL_STORAGE_KEY.CLIENT_SECRET) || '',
  authority: getCookie(LOCAL_STORAGE_KEY.AUTHORITY) || '',
  scope: '',
  isRedirectSignInSSO: false,
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setCredentials: (state, action) => {
      state.isLoading = false;
      state.fullName = action?.payload?.userName;
      state.token = action?.payload?.token;
      state.refreshToken = action?.payload?.refreshToken;
    },
    logOut: state => {
      state.token = null;
      state.fullName = null;
      const tld = getTld(window.location.hostname);
      deleteCookie(TOKEN_STORAGE, '/', `.${tld}`);
      deleteCookie(EXPIRE_TOKEN_TIME, '/', `.${tld}`);
      deleteCookie(REFRESH_TOKEN, '/', `.${tld}`);
    },
    setResentOtpToken: (state, action) => {
      state.resendOTPToken = action.payload?.resendOTPToken;
      state.email = action.payload?.email;
    },
    removeMessage: state => {
      state.messageError = '';
    },
  },
  extraReducers(builder) {
    builder.addMatcher(
      authApiSlice.endpoints.sendOtp.matchRejected,
      (state, action) => {
        const error = action as any;
        if (
          error.payload?.status === 401 &&
          error.payload.data?.code ===
            'IDENTITY/REQUEST-OTP/LOGIN-RATE-LIMIT-EXCEEDED'
        )
          state.messageError =
            'Another user just logged in using this email address. Please try again in a minute.';
      }
    );
    builder.addMatcher(
      authApiSlice.endpoints.logout.matchFulfilled,
      (state, action) => {
        state.token = null;
        state.fullName = null;
        const tld = getTld(window.location.hostname);
        deleteCookie(TOKEN_STORAGE, '/', `.${tld}`);
        deleteCookie(EXPIRE_TOKEN_TIME, '/', `.${tld}`);
        deleteCookie(REFRESH_TOKEN, '/', `.${tld}`);
      }
    );
    builder.addMatcher(
      authApiSlice.endpoints.validateEmailSSO.matchFulfilled,
      (state, action) => {
        const response = action as any;
        const { clientId, clientSecret, authority, scope } =
          response.payload || {};
        state.clientId = clientId;
        state.clientSecret = clientSecret;
        state.authority = authority;
        state.scope = scope;
        state.isRedirectSignInSSO = true;
        // set cookie
        const exp = new Date(new Date().getTime() + 60 * 60000);
        setCookie(LOCAL_STORAGE_KEY.CLIENT_ID, clientId, exp.valueOf());
        setCookie(LOCAL_STORAGE_KEY.CLIENT_SECRET, clientSecret, exp.valueOf());
        setCookie(LOCAL_STORAGE_KEY.AUTHORITY, authority, exp.valueOf());
      }
    );

    if (!isDevelopment) {
      builder.addMatcher(
        authApiSlice.endpoints.authenticateSSO.matchFulfilled,
        (_state, action) => {
          const tld = getTld(window.location.hostname);
          deleteCookie(LOCAL_STORAGE_KEY.CLIENT_ID, '/', `.${tld}`);
          deleteCookie(LOCAL_STORAGE_KEY.CLIENT_SECRET, '/', `.${tld}`);
          deleteCookie(LOCAL_STORAGE_KEY.AUTHORITY, '/', `.${tld}`);
        }
      );
    }

    // manually set cookies for local development
    if (isDevelopment()) {
      builder.addMatcher(
        authApiSlice.endpoints.sendOtp.matchFulfilled,
        (_state, action) => {
          const exp = new Date(new Date().getTime() + 10 * 60000);
          setCookie('app__token__otp', action.payload.otpToken, exp.valueOf());
        }
      );

      builder.addMatcher(
        authApiSlice.endpoints.login.matchFulfilled,
        (_state, action) => {
          const exp = new Date(new Date().getTime() + 60 * 60000);
          setCookie('app__token', action.payload.token, exp.valueOf());
          setCookie(
            'app__refresh_token',
            action.payload.refreshToken,
            exp.valueOf()
          );
        }
      );

      builder.addMatcher(
        authApiSlice.endpoints.authenticateSSO.matchFulfilled,
        (_state, action) => {
          const tld = getTld(window.location.hostname);
          deleteCookie(LOCAL_STORAGE_KEY.CLIENT_ID, '/', `.${tld}`);
          deleteCookie(LOCAL_STORAGE_KEY.CLIENT_SECRET, '/', `.${tld}`);
          deleteCookie(LOCAL_STORAGE_KEY.AUTHORITY, '/', `.${tld}`);

          const exp = new Date(new Date().getTime() + 60 * 60000);
          setCookie('app__token', action.payload.token, exp.valueOf());
          setCookie(
            'app__refresh_token',
            action.payload.refreshToken,
            exp.valueOf()
          );
        }
      );
    }
  },
});

export const { setCredentials, logOut, setResentOtpToken, removeMessage } =
  authSlice.actions;
export const authReducer = authSlice.reducer;
