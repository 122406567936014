import { QueryParams } from '../../../@types/QueryParams';
import { apiSlice } from '../api/apiSlice';
import {
  OrganizationStructureListType,
  OrganizationStructureCreateType,
  organizationStructureUpdateType,
} from '../../../@types/OrganizationStructure';

export const OrganizationStructureApiSlice = apiSlice.injectEndpoints({
  endpoints: builder => ({
    getOrganizationStructureList: builder.query<
      OrganizationStructureListType,
      QueryParams
    >({
      query: (params: {
        limit: any;
        search: any;
        page: any;
        sortType: any;
        sort: any;
        searchString: any;
      }) => {
        const { search, page, sortType, sort, limit, searchString } = params;
        return {
          url: `/organization/organizations`,
          params: { search, page, limit, sortType, sort, ...searchString },
        };
      },
      providesTags: ['OrganizationStructureList'],
    }),
    getOrganizationStructureDetails: builder.query<any, any>({
      query: params => {
        const { effectiveDate, id } = params;
        return {
          url: `/organization/organizations/${id}`,
          params: {
            effectiveDate,
          },
        };
      },
      providesTags: ['OrganizationStructureDetail'],
    }),
    createOrganizationStructure: builder.mutation<
      OrganizationStructureCreateType,
      any
    >({
      query: payload => ({
        url: 'organization/organizations',
        method: 'POST',
        body: payload,
      }),
      invalidatesTags: ['OrganizationStructureList'],
    }),
    editOrganizationStructure: builder.mutation<
      organizationStructureUpdateType,
      any
    >({
      query: ({ payload, id }) => ({
        url: `organization/organizations/${id}`,
        method: 'PUT',
        body: payload,
      }),
      invalidatesTags: [
        'OrganizationStructureList',
        'OrganizationStructureDetail',
        'EmployeeList',
        'employeeEmployment',
      ],
    }),
    deleteOrganizationStructure: builder.mutation<any, any>({
      query: id => ({
        url: `organization/organizations/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['OrganizationStructureList'],
    }),
    cancelUpcomingChangesOrganization: builder.mutation<any, any>({
      query: ({ newId }) => ({
        url: `organization/organizations/${newId}/cancellation`,
        method: 'DELETE',
      }),
      invalidatesTags: [
        'OrganizationStructureDetail',
        'OrganizationStructureList',
      ],
    }),
  }),
});

export const {
  useGetOrganizationStructureListQuery,
  useGetOrganizationStructureDetailsQuery,
  useCreateOrganizationStructureMutation,
  useEditOrganizationStructureMutation,
  useDeleteOrganizationStructureMutation,
  useCancelUpcomingChangesOrganizationMutation,
} = OrganizationStructureApiSlice;
