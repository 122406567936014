import { createSlice } from '@reduxjs/toolkit';
import { importApiSlice } from './service/importApiSlice';

type InitialStateI = {
  messageSuccess: string;
  messageError: string;
  messagePending: string;
};

const initialState: InitialStateI = {
  messageSuccess: '',
  messageError: '',
  messagePending: '',
};

const importSlice = createSlice({
  name: 'import',
  initialState,
  reducers: {
    removeMessage: state => {
      state.messageSuccess = '';
      state.messageError = '';
      state.messagePending = '';
    },
    manuallySetPendingMessage: state => {
      state.messagePending = 'Downloading file...';
    },
    manuallySetSuccessMessage: state => {
      state.messagePending = '';
      state.messageSuccess = 'File downloaded';
    },
  },
  extraReducers(builder) {
    builder.addMatcher(
      importApiSlice.endpoints.createImport.matchFulfilled,
      (state, action) => {
        state.messageSuccess = 'File imported';
      }
    );
    builder.addMatcher(
      importApiSlice.endpoints.createImport.matchRejected,
      (state, action) => {
        const payload = action.payload as any;
        if (payload?.data?.errors?.['ENTITY-MISSING']) {
          state.messageError =
            'Entity doesn’t exist, please refresh and check Time > Configuration > Attendance Machine.';
        } else if (payload?.data?.message) {
          state.messageError = payload?.data?.message;
        }
      }
    );
    builder.addMatcher(
      importApiSlice.endpoints.submitApprovalImport.matchFulfilled,
      (state, action) => {
        const payload = action.payload as any;
        if (payload.statusCode === 'APPROVED') {
          state.messageSuccess = `${
            payload?.totalData || 'some'
          } data approved`;
        }
        if (payload.statusCode === 'REJECTED') {
          state.messageSuccess = `${
            payload?.totalData || 'some'
          } data rejected`;
        }
      }
    );
    builder.addMatcher(
      importApiSlice.endpoints.downloadTemplate.matchFulfilled,
      (state, action) => {
        state.messageSuccess = 'File downloaded';
      }
    );
    builder.addMatcher(
      importApiSlice.endpoints.downloadTemplate.matchRejected,
      (state, action) => {
        const payload = action.payload as any;
        state.messageError =
          payload?.data?.message || 'Failed download template';
      }
    );
    builder.addMatcher(
      importApiSlice.endpoints.downloadError.matchFulfilled,
      (state, action) => {
        state.messageSuccess = 'Import Error downloaded';
      }
    );
    builder.addMatcher(
      importApiSlice.endpoints.downloadError.matchRejected,
      (state, action) => {
        const payload = action.payload as any;
        state.messageError =
          payload?.data?.message || 'Failed download import error';
      }
    );
    builder.addMatcher(
      importApiSlice.endpoints.submitApprovalImport.matchRejected,
      (state, action) => {
        const payload = action.payload as any;
        state.messageError =
          payload?.data?.message || 'Failed download import error';
      }
    );
  },
});

export const {
  removeMessage,
  manuallySetPendingMessage,
  manuallySetSuccessMessage,
} = importSlice.actions;
export const importReducer = importSlice.reducer;
