import { createSlice } from '@reduxjs/toolkit';
import { PayrollRunApiSlice } from './service/payrollRun';
import { bankFileApiSlice } from './service/report/bankFileApiSlice';
import { employeeApiSlice } from './service/employeeCompensationApiSlice';

interface initialStateI {
  messageSuccess: string;
  messageError: string;
}

const initialState: initialStateI = {
  messageSuccess: '',
  messageError: '',
};

const payrollRunSlice = createSlice({
  name: 'payrollRun',
  initialState,
  reducers: {
    removeMessage: state => {
      state.messageSuccess = '';
      state.messageError = '';
    },
  },
  extraReducers(builder) {
    builder.addMatcher(
      PayrollRunApiSlice.endpoints.payrollStepManageAddEmployee.matchFulfilled,
      (state, _action) => {
        state.messageSuccess = 'Employee added';
      }
    );
    builder.addMatcher(
      PayrollRunApiSlice.endpoints.payrollStepManageAddEmployee.matchRejected,
      (state, _action) => {
        const payload = _action.payload as any;
        state.messageError = payload?.data?.message || 'Internal Server Error';
      }
    );
    builder.addMatcher(
      PayrollRunApiSlice.endpoints.payrollStepManageDeleteEmployee
        .matchFulfilled,
      (state, _action) => {
        state.messageSuccess = 'Employee removed';
      }
    );
    builder.addMatcher(
      PayrollRunApiSlice.endpoints.payrollStepManageAddEmployee.matchRejected,
      (state, _action) => {
        const payload = _action.payload as any;
        state.messageError = payload?.data?.message || 'Internal Server Error';
      }
    );
    builder.addMatcher(
      PayrollRunApiSlice.endpoints.recalculatePayroll.matchFulfilled,
      (state, action) => {
        state.messageSuccess = 'Payroll Run recalculated';
      }
    );
    builder.addMatcher(
      PayrollRunApiSlice.endpoints.recalculatePayroll.matchRejected,
      (state, action) => {
        const payload = action.payload as any;
        state.messageError = payload?.data?.message || 'Internal Server Error';
      }
    );
    builder.addMatcher(
      PayrollRunApiSlice.endpoints.rollbackPayrollRun.matchFulfilled,
      (state, action) => {
        state.messageSuccess = 'Payroll Run rolled back';
      }
    );
    builder.addMatcher(
      PayrollRunApiSlice.endpoints.reopenPayrollRun.matchFulfilled,
      (state, action) => {
        state.messageSuccess = 'Payroll Run reopened';
      }
    );
    builder.addMatcher(
      PayrollRunApiSlice.endpoints.createPayment.matchFulfilled,
      (state, action) => {
        state.messageSuccess = 'One-time Payment created';
      }
    );
    builder.addMatcher(
      PayrollRunApiSlice.endpoints.payrollStepSetupAddPayItem.matchFulfilled,
      (state, action) => {
        state.messageSuccess = 'Pay Item added';
      }
    );
    builder.addMatcher(
      PayrollRunApiSlice.endpoints.payrollStepSetupRemovePayItem.matchFulfilled,
      (state, action) => {
        state.messageSuccess = 'Pay Item removed';
      }
    );
    builder.addMatcher(
      bankFileApiSlice.endpoints.downloadBankFile.matchFulfilled,
      state => {
        state.messageSuccess = 'Bank File downloaded';
      }
    );
    builder.addMatcher(
      bankFileApiSlice.endpoints.downloadBankFile.matchRejected,
      (state, action) => {
        state.messageError = action?.payload?.data?.message;
      }
    );
    builder.addMatcher(
      PayrollRunApiSlice.endpoints.createOneTimePayment.matchFulfilled,
      (state, _action) => {
        state.messageSuccess = 'One-time Payment created';
      }
    );
    builder.addMatcher(
      PayrollRunApiSlice.endpoints.createOneTimePayment.matchRejected,
      (state, action) => {
        // const messageError = action.payload as any;
        state.messageError = 'Failed create One-time Payment';
      }
    );
    builder.addMatcher(
      employeeApiSlice.endpoints.sendWorkPersonalPayslip.matchFulfilled,
      (state, _action) => {
        state.messageSuccess =
          'Payslips are being gradually sent to your employees';
      }
    );
    builder.addMatcher(
      employeeApiSlice.endpoints.sendWorkPersonalPayslip.matchRejected,
      (state, action) => {
        const payload = action.payload as any;
        state.messageError = payload?.data?.message || 'Failed Send Payslips';
      }
    );
    builder.addMatcher(
      PayrollRunApiSlice.endpoints.deletePayrollRun.matchFulfilled,
      (state, _action) => {
        state.messageSuccess = 'Payroll deleted';
      }
    );
    builder.addMatcher(
      PayrollRunApiSlice.endpoints.deletePayrollRun.matchRejected,
      (state, action) => {
        state.messageError = 'Failed delete payroll';
      }
    );
    builder.addMatcher(
      PayrollRunApiSlice.endpoints.updateDetailStatus.matchRejected,
      (state, action) => {
        state.messageError = 'Internal Server Error';
      }
    );
    builder.addMatcher(
      PayrollRunApiSlice.endpoints.submitStepSetup.matchRejected,
      (state, action) => {
        state.messageError =
          action.payload.status === 422 || action.payload.status === 404
            ? ''
            : 'Internal Server Error';
      }
    );
    builder.addMatcher(
      PayrollRunApiSlice.endpoints.postPayslipCompiled.matchRejected,
      (state, action) => {
        const payload = action.payload as any;
        state.messageError = payload?.data?.message || 'Internal Server Error';
      }
    );
    // builder.addMatcher(
    //   PayrollRunApiSlice.endpoints.getPayrollRunSummary.matchRejected,
    //   state => {
    //     state.messageError = 'Payroll has not been run for this period';
    //   }
    // );
  },
});

export const { removeMessage } = payrollRunSlice.actions;
export const payrollRunReducer = payrollRunSlice.reducer;
