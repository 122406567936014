import { apiSlice } from '../../../api/apiSlice';

export const employeePaymentDetailsApiSlice = apiSlice.injectEndpoints({
  endpoints: builder => ({
    getDetailRegularPayment: builder.query({
      query: args => {
        const { id, groupId, ...query } = args;
        return {
          url: `/payroll/employees/${id}/regular-payments`,
          params: query,
        };
      },
      providesTags: ['regularPayment'],
    }),
    createRegularPayment: builder.mutation<any, any>({
      query: ({ payload, employeeId }) => ({
        url: `/payroll/employees/${employeeId}/regular-payments`,
        method: 'POST',
        body: payload,
      }),
      invalidatesTags: ['regularPayment'],
    }),
    updateRegularPayment: builder.mutation<any, any>({
      query: ({ employeeId, oldId, data }) => ({
        url: `/payroll/employees/${employeeId}/regular-payments/${oldId}`,
        method: 'PUT',
        body: data,
      }),
      invalidatesTags: ['regularPayment'],
    }),
    cancelChangesRegularPayment: builder.mutation<any, any>({
      query: ({ employeeId, deductionId }) => ({
        url: `/payroll/employees/${employeeId}/regular-payments/${deductionId}/cancellation`,
        method: 'DELETE',
      }),
      invalidatesTags: ['regularPayment'],
    }),
    getDetailOffCyclePayment: builder.query({
      query: args => {
        const { id, groupId, ...query } = args;
        return {
          url: `/payroll/employees/${id}/off-cycle-payments`,
          params: query,
        };
      },
      providesTags: ['offCyclePayment'],
    }),
    createOffCyclePayment: builder.mutation<any, any>({
      query: ({ payload, employeeId }) => ({
        url: `/payroll/employees/${employeeId}/off-cycle-payments`,
        method: 'POST',
        body: payload,
      }),
      invalidatesTags: ['offCyclePayment'],
    }),
    updateOffCyclePayment: builder.mutation<any, any>({
      query: ({ employeeId, oldId, data }) => ({
        url: `/payroll/employees/${employeeId}/off-cycle-payments/${oldId}`,
        method: 'PUT',
        body: data,
      }),
      invalidatesTags: ['offCyclePayment'],
    }),
    cancelChangesOffCyclePayment: builder.mutation<any, any>({
      query: ({ employeeId, deductionId }) => ({
        url: `/payroll/employees/${employeeId}/off-cycle-payments/${deductionId}/cancellation`,
        method: 'DELETE',
      }),
      invalidatesTags: ['offCyclePayment'],
    }),
  }),
});

export const {
  useCreateRegularPaymentMutation,
  useGetDetailRegularPaymentQuery,
  useUpdateRegularPaymentMutation,
  useCancelChangesRegularPaymentMutation,
  useGetDetailOffCyclePaymentQuery,
  useCreateOffCyclePaymentMutation,
  useUpdateOffCyclePaymentMutation,
  useCancelChangesOffCyclePaymentMutation,
} = employeePaymentDetailsApiSlice;
