import { Box, SxProps } from '@mui/material';
import { Control } from 'react-hook-form';
import { ContactUsFormProps } from '../../@types/ContactUs';
import InputWithLabel from '../../components/molecule/form/InputWithLabel/InputWithLabel';

interface Step4Props {
  control: Control<ContactUsFormProps>;
}

const Step4 = ({ control }: Step4Props) => {
  const style: SxProps = {
    '& .MuiFormControl-fullWidth': {
      mb: '1.25rem',
    },
  };
  return (
    <Box sx={style}>
      <InputWithLabel
        className="form-input"
        label="Full Name"
        name="fullName"
        placeholder="e.g. John Doe"
        control={control}
      />
      <InputWithLabel
        className="form-input"
        label="Job Title"
        name="jobTitle"
        placeholder="Your job title"
        control={control}
      />
      <InputWithLabel
        className="form-input"
        label="Company"
        name="company"
        placeholder="Company name"
        control={control}
      />
      <InputWithLabel
        className="form-input"
        label="Business Email"
        name="businessEmail"
        placeholder="Your email"
        control={control}
      />
      <InputWithLabel
        className="form-input"
        label="Phone Number"
        name="phoneNumber"
        placeholder="e.g. 08**********"
        control={control}
        type="number"
      />
    </Box>
  );
};

export default Step4;
