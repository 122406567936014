import { apiSlice } from '../../api/apiSlice';

export const employeeFamilyApiSlice = apiSlice.injectEndpoints({
  endpoints: builder => ({
    getListFamilies: builder.query({
      query: args => {
        const { id, ...query } = args;
        return {
          url: `/employee/employees/${id}/families`,
          params: query,
        };
      },
      providesTags: ['EmployeeList'],
    }),
    getDetailFamily: builder.query({
      query: args => {
        const { id, groupId, ...query } = args;
        return {
          url: `/employee/employees/${id}/families/${groupId}`,
          params: query,
        };
      },
      providesTags: ['EmployeeList'],
    }),
    createFamily: builder.mutation<any, any>({
      query: ({ id, payload }) => ({
        url: `/employee/employees/${id}/families`,
        method: 'POST',
        body: payload,
      }),
      invalidatesTags: ['EmployeeList'],
    }),
    updateFamily: builder.mutation<any, any>({
      query: ({ id, groupId, payload }) => ({
        url: `/employee/employees/${id}/families/${groupId}`,
        method: 'PUT',
        body: payload,
      }),
      invalidatesTags: ['EmployeeList'],
    }),
    cancelChangesFamily: builder.mutation<any, any>({
      query: ({ id, newId }) => ({
        url: `/employee/employees/${id}/families/${newId}/cancellation`,
        method: 'DELETE',
      }),
      invalidatesTags: ['EmployeeList'],
    }),
    deleteFamily: builder.mutation<any, any>({
      query: ({ id, groupId }) => ({
        url: `/employee/employees/${id}/families/${groupId}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['EmployeeList'],
    }),
  }),
});

export const {
  useCreateFamilyMutation,
  useGetListFamiliesQuery,
  useGetDetailFamilyQuery,
  useUpdateFamilyMutation,
  useCancelChangesFamilyMutation,
  useDeleteFamilyMutation,
} = employeeFamilyApiSlice;
