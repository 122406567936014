export function setCookie(name: string, value: any, expired: number) {
  const date = new Date();
  date.setTime(date.getTime() + expired);
  const domain = window.location.hostname;
  const expires = `; expires=${date.toUTCString()}`;
  document.cookie = `${name}=${
    value || ''
  }${expires}; domain=.${domain}; path=/`;
}

export function getCookie(name: string) {
  const nameEQ = `${name}=`;
  const ca = document.cookie.split(';');
  for (let i = 0; i < ca.length; i += 1) {
    let c = ca[i];
    while (c.charAt(0) === ' ') c = c.substring(1, c.length);
    if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
  }
  return null;
}
export function eraseCookie(name: string) {
  document.cookie = `${name}=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;`;
}

function getCookies(name: string) {
  return document.cookie.split(';').some(c => c.trim().startsWith(`${name  }=`));
}

export function deleteCookie(name: string, path: string, domain: string) {
  if (getCookies(name)) {
    document.cookie =
      `${name 
      }=${ 
      path ? `;path=${  path}` : '' 
      }${domain ? `;domain=${  domain}` : '' 
      };expires=Thu, 01 Jan 1970 00:00:01 GMT`;
  }
}

export function getTld(name: string) {
  /* eslint-disable-next-line */
  const re = /(?<![^\/]\/)\b\w+\.\b\w{2,5}(?:\.\b\w{2})?(?=$|\/)/;
  const domain = window.location.hostname;
  const tld = re.exec(domain)?.[0];
  return tld;
}
