import { apiSlice } from '../api/apiSlice';

interface Query {
  id: string;
  effectiveDate?: string;
}

export const costCenterApiSlice = apiSlice.injectEndpoints({
  endpoints: builder => ({
    getCostCenterList: builder.query({
      query: params => {
        const { search, page, sortType, sort, searchString } = params;
        return {
          url: `/payroll/cost-centers`,
          params: { search, page, sortType, sort, ...searchString },
        };
      },
      providesTags: ['CostCenterList', 'CostCenterDelete'],
    }),
    getCostCenterDetail: builder.query({
      query: params => {
        const { id, effectiveDate } = params;
        return {
          url: `/payroll/cost-centers/${id}`,
          params: { effectiveDate },
        };
      },
      providesTags: ['CostCenterList', 'CostCenterDelete'],
    }),
    createCostCenter: builder.mutation<void, any>({
      query: body => ({
        url: `/payroll/cost-centers`,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['CostCenterList'],
    }),
    updateCostCenter: builder.mutation<void, Query>({
      query: ({ id, ...data }) => ({
        url: `/payroll/cost-centers/${id}`,
        method: 'PUT',
        body: data,
      }),
      invalidatesTags: ['CostCenterList', 'CostCenterDelete'],
    }),
    cancelUpcomingCostCenter: builder.mutation<any, any>({
      query: ({ id }) => ({
        url: `/payroll/cost-centers/${id}/cancellation`,
        method: 'DELETE',
      }),
      invalidatesTags: ['CostCenterList', 'CostCenterDelete'],
    }),
    deleteCostCenter: builder.mutation<any, any>({
      query: ({ id }) => ({
        url: `/payroll/cost-centers/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['CostCenterList'],
    }),
  }),
});

export const {
  useGetCostCenterListQuery,
  useGetCostCenterDetailQuery,
  useCreateCostCenterMutation,
  useUpdateCostCenterMutation,
  useDeleteCostCenterMutation,
  useCancelUpcomingCostCenterMutation,
} = costCenterApiSlice;
