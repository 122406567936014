import { ValueRoundingList } from '../../../@types/valueRounding';
import { apiSlice } from '../api/apiSlice';

export const ValueRoundingApiSlice = apiSlice.injectEndpoints({
  endpoints: builder => ({
    getValueRoundingList: builder.query<ValueRoundingList, any>({
      query: params => {
        const { search, page, sortType, sort, searchString } = params;
        return {
          url: '/payroll/value-roundings',
          params: { search, page, sortType, sort, ...searchString },
        };
      },
      providesTags: ['ValueRounding'],
    }),
    GetValueRoundingDetail: builder.query({
      query: params => ({
        url: `/payroll/value-roundings/${params.id}`,
        // url: `/value-roundings/${params.id}`,
      }),
      providesTags: ['ValueRounding'],
    }),
    createValueRounding: builder.mutation({
      query: payload => ({
        url: '/payroll/value-roundings',
        method: 'POST',
        body: payload,
      }),
      invalidatesTags: ['ValueRounding'],
    }),
    updateValueRounding: builder.mutation({
      query: ({ payload, id }) => ({
        url: `/payroll/value-roundings/${id}`,
        method: 'PUT',
        body: payload,
      }),
      invalidatesTags: ['ValueRounding'],
    }),
    deleteValueRounding: builder.mutation({
      query: id => ({
        url: `/payroll/value-roundings/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['ValueRounding'],
    }),
  }),
});

export const {
  useGetValueRoundingListQuery,
  useGetValueRoundingDetailQuery,
  useCreateValueRoundingMutation,
  useUpdateValueRoundingMutation,
  useDeleteValueRoundingMutation,
} = ValueRoundingApiSlice;
