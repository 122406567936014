import { createSlice } from '@reduxjs/toolkit';
import { costCenterApiSlice } from './service/costCenterApiSlice';

interface initialStateI {
  messageSuccess: string;
  messageError: string;
}

const initialState: initialStateI = {
  messageSuccess: '',
  messageError: '',
};

const costCenterSlice = createSlice({
  name: 'costCenter',
  initialState,
  reducers: {
    removeMessage: state => {
      state.messageSuccess = '';
      state.messageError = '';
    },
  },
  extraReducers(builder) {
    builder.addMatcher(
      costCenterApiSlice.endpoints.createCostCenter.matchFulfilled,
      (state, _action) => {
        state.messageSuccess = 'Cost Center created';
      }
    );
    builder.addMatcher(
      costCenterApiSlice.endpoints.updateCostCenter.matchFulfilled,
      (state, action: any) => {
        if (action.payload.typeOfChange === 'UPDATED') {
          state.messageSuccess = 'Cost Center updated';
        } else if (action.payload.typeOfChange === 'CORRECTED') {
          state.messageSuccess = 'Cost Center corrected';
        }
      }
    );
    builder.addMatcher(
      costCenterApiSlice.endpoints.cancelUpcomingCostCenter.matchFulfilled,
      (state, _action: any) => {
        state.messageSuccess = 'Upcoming Changes cancelled';
      }
    );
    builder.addMatcher(
      costCenterApiSlice.endpoints.deleteCostCenter.matchRejected,
      (state, _action: any) => {
        state.messageError =
          "This data can't be deleted as it had been associated with an employee";
      }
    );
    builder.addMatcher(
      costCenterApiSlice.endpoints.deleteCostCenter.matchFulfilled,
      (state, _action) => {
        state.messageSuccess = 'Cost Center deleted';
      }
    );
  },
});

export const { removeMessage } = costCenterSlice.actions;
export const costCenterReducer = costCenterSlice.reducer;
