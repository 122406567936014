import React from 'react';
import { Chip, SxProps, useTheme } from '@mui/material';
import { TagProps } from '../../../@types';
import { mergeDeep } from '../../../utils/deepMerge';

const Tags = React.forwardRef((Props: TagProps, ref: any) => {
  const { label, variant = 'primary', sx, size = 'big', ...props } = Props;
  const { palette } = useTheme();
  const color = {
    primary: palette.blue,
    success: palette.green,
    warning: palette.orange,
    error: palette.red,
  };
  const style: SxProps = {
    height: '24px',
    backgroundColor:
      variant === 'gray' ? palette.gray[40] : color?.[variant]?.[20],
    py: '0px',
    px: '1rem',
    borderRadius: '6px',
    fontFamily: 'Inter',
    fontWeight: 500,
    fontSize: size === 'big' ? '0.875rem' : '0.75rem',
    lineHeight: size === 'big' ? '1.25rem' : '1rem',
    color: variant === 'gray' ? palette.gray[90] : color?.[variant]?.[70],
    padding: '2px 8px',
    '.MuiChip-label': {
      paddingX: '0px',
    },
  };
  return <Chip ref={ref} sx={mergeDeep(style, sx)} label={label} {...props} />;
});

export default Tags;
