import { createSlice } from '@reduxjs/toolkit';
import { attendanceStatusApiSlice } from './service/attendanceStatusApiSlice';

interface initialStateI {
  messageSuccess: string;
  messageError: string;
}

const initialState: initialStateI = {
  messageSuccess: '',
  messageError: '',
};

const attendanceStatusSlice = createSlice({
  name: 'attendanceStatus',
  initialState,
  reducers: {
    removeMessage: state => {
      state.messageSuccess = '';
      state.messageError = '';
    },
  },
  extraReducers(builder) {
    builder.addMatcher(
      attendanceStatusApiSlice.endpoints.createAttendanceStatus.matchFulfilled,
      (state, _action) => {
        state.messageSuccess = 'Attendance Status created';
      }
    );
    builder.addMatcher(
      attendanceStatusApiSlice.endpoints.createAttendanceStatus.matchRejected,
      (state, action) => {
        const payload = action.payload as any;
        state.messageError = payload?.data?.message || 'Internal Server Error';
      }
    );
    builder.addMatcher(
      attendanceStatusApiSlice.endpoints.updateAttendanceStatus.matchFulfilled,
      (state, _action) => {
        state.messageSuccess = 'Attendance Status updated';
      }
    );
    builder.addMatcher(
      attendanceStatusApiSlice.endpoints.updateAttendanceStatus.matchRejected,
      (state, action) => {
        const payload = action.payload as any;
        state.messageError = payload?.data?.message || 'Internal Server Error';
      }
    );
    builder.addMatcher(
      attendanceStatusApiSlice.endpoints.deleteAttendanceStatus.matchFulfilled,
      (state, _action) => {
        state.messageSuccess = 'Attendance Status deleted';
      }
    );
    builder.addMatcher(
      attendanceStatusApiSlice.endpoints.deleteAttendanceStatus.matchRejected,
      (state, action) => {
        const payload = action.payload as any;
        state.messageError = payload?.data?.message || 'Internal Server Error';
      }
    );
  },
});

export const { removeMessage } = attendanceStatusSlice.actions;
export const attendanceStatusReducer = attendanceStatusSlice.reducer;
