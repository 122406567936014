import { QueryParams } from '../../../@types/QueryParams';
import { apiSlice } from '../api/apiSlice';
import {
  EmploymentTypeListType,
  EmploymentTypeDetailsType,
} from '../../../@types/EmploymentType';

interface Query {
  id: string;
  effectiveDate?: string;
}

export const EmploymentTypeApiSlice = apiSlice.injectEndpoints({
  endpoints: builder => ({
    getEmploymentTypeList: builder.query<EmploymentTypeListType, QueryParams>({
      query: params => {
        const { search, page, sortType, sort, searchString } = params;
        return {
          url: `/organization/employment-types`,
          params: { search, page, sortType, sort, ...searchString },
        };
      },
      providesTags: ['EmploymentTypeList'],
    }),
    getEmploymentTypeDetails: builder.query<EmploymentTypeDetailsType, Query>({
      query: params => {
        const { effectiveDate, id } = params;
        return {
          url: `/organization/employment-types/${id}`,
          params: {
            effectiveDate,
          },
        };
      },
      providesTags: ['EmploymentTypeDetail'],
    }),
    addEmploymentType: builder.mutation<any, any>({
      query: body => ({
        url: '/organization/employment-types',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['EmploymentTypeList'],
    }),
    updateEmploymentType: builder.mutation<any, any>({
      query: ({ id, payload }) => ({
        url: `/organization/employment-types/${id}`,
        method: 'PUT',
        body: payload,
      }),
      invalidatesTags: ['EmploymentTypeDetail'],
    }),
    deleteEmploymentType: builder.mutation<any, any>({
      query: ({ id }) => ({
        url: `/organization/employment-types/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['EmploymentTypeList'],
    }),
    cancelUpcomingEmploymentType: builder.mutation<any, any>({
      query: ({ id }) => ({
        url: `/organization/employment-types/${id}/cancellation`,
        method: 'DELETE',
      }),
      invalidatesTags: ['EmploymentTypeDetail', 'EmploymentTypeList'],
    }),
  }),
});

export const {
  useGetEmploymentTypeListQuery,
  useGetEmploymentTypeDetailsQuery,
  useAddEmploymentTypeMutation,
  useUpdateEmploymentTypeMutation,
  useDeleteEmploymentTypeMutation,
  useCancelUpcomingEmploymentTypeMutation,
} = EmploymentTypeApiSlice;
