const Key02 = () => (
  <svg
    width="17"
    height="18"
    viewBox="0 0 17 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11 6.5H11.0083M11 11.5C13.7614 11.5 16 9.26142 16 6.5C16 3.73858 13.7614 1.5 11 1.5C8.23858 1.5 6 3.73858 6 6.5C6 6.72807 6.01527 6.95256 6.04484 7.17253C6.09348 7.53432 6.1178 7.71521 6.10143 7.82966C6.08438 7.94888 6.06267 8.01312 6.00391 8.11825C5.9475 8.21917 5.84809 8.31857 5.64928 8.51739L1.39052 12.7761C1.2464 12.9203 1.17433 12.9923 1.1228 13.0764C1.07711 13.151 1.04344 13.2323 1.02303 13.3173C1 13.4132 1 13.5151 1 13.719V15.1667C1 15.6334 1 15.8667 1.09083 16.045C1.17072 16.2018 1.29821 16.3293 1.45501 16.4092C1.63327 16.5 1.86662 16.5 2.33333 16.5H3.78105C3.98487 16.5 4.08679 16.5 4.18269 16.477C4.26772 16.4566 4.34901 16.4229 4.42357 16.3772C4.50767 16.3257 4.57973 16.2536 4.72386 16.1095L8.98261 11.8507C9.18142 11.6519 9.28083 11.5525 9.38175 11.4961C9.48688 11.4373 9.55112 11.4156 9.67034 11.3986C9.78479 11.3822 9.96568 11.4065 10.3275 11.4552C10.5474 11.4847 10.7719 11.5 11 11.5Z"
      stroke="#475667"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default Key02;
