import { apiSlice } from '../../api/apiSlice';

export const employeeAddressApiSlice = apiSlice.injectEndpoints({
  endpoints: builder => ({
    getListAddressEmployee: builder.query({
      query: args => {
        const { id, ...query } = args;
        return {
          url: `/employee/employees/${id}/address`,
          params: query,
        };
      },
      providesTags: ['employeeAddressList'],
    }),
    getDetailAddressEmployee: builder.query({
      query: args => {
        const { id, ccId, ...query } = args;
        return {
          url: `/employee/employees/${id}/address/${ccId}`,
          params: query,
        };
      },
      providesTags: ['employeeAddressDetail'],
    }),
    createAddressEmployee: builder.mutation<any, any>({
      query: ({ id, body }) => ({
        url: `/employee/employees/${id}/address`,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['employeeAddressList'],
    }),
    updateAddressEmployee: builder.mutation<any, any>({
      query: ({ id, groupId, body }: any) => ({
        url: `/employee/employees/${id}/address/${groupId}`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: ['employeeAddressDetail'],
    }),
    cancelAddressEmployee: builder.mutation<any, any>({
      query: ({ id, newId }) => ({
        url: `/employee/employees/${id}/address/${newId}/cancellation`,
        method: 'DELETE',
      }),
      invalidatesTags: ['employeeAddressDetail', 'employeeAddressList'],
    }),
    deleteAddressEmployee: builder.mutation<any, any>({
      query: ({ id, groupId }) => ({
        url: `/employee/employees/${id}/address/${groupId}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['employeeAddressDetail', 'employeeAddressList'],
    }),
  }),
});

export const {
  useGetListAddressEmployeeQuery,
  useGetDetailAddressEmployeeQuery,
  useCreateAddressEmployeeMutation,
  useUpdateAddressEmployeeMutation,
  useCancelAddressEmployeeMutation,
  useDeleteAddressEmployeeMutation,
} = employeeAddressApiSlice;
