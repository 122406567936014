import { lazy } from 'react';

const List = lazy(() => import('../../../pages/schedule/List'));
const Detail = lazy(() => import('../../../pages/schedule/Detail'));
const Create = lazy(() => import('../../../pages/schedule/Create'));
const Update = lazy(() => import('../../../pages/schedule/Update'));
const Correct = lazy(() => import('../../../pages/schedule/Correct'));

export const schedule = [
  {
    label: 'Schedule',
    child: null,
    path: 'schedule',
    component: List,
  },
  {
    label: '',
    child: null,
    path: 'schedule/detail/:id',
    component: Detail,
  },
  {
    label: '',
    child: null,
    path: 'schedule/create',
    component: Create,
  },
  {
    label: '',
    child: null,
    path: 'schedule/update/:id',
    component: Update,
  },
  {
    label: '',
    child: null,
    path: 'schedule/correction/:id',
    component: Correct,
  },
];
