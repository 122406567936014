import React from 'react';
import { RouteNode } from '../../@types';

const EmployeeCompensation = React.lazy(
  () => import('../../pages/employeeCompensation/List')
);
const EmployeeCompensationDetail = React.lazy(
  () => import('../../pages/employeeCompensation/Details')
);
const PayrollRunList = React.lazy(
  () => import('../../pages/payrollRun/PayrollRun')
);
const PayrollRunRun = React.lazy(
  () => import('../../pages/payrollRun/FormRunPayroll/FormRunPayroll')
);
const PayrollRunDetail = React.lazy(
  () => import('../../pages/payrollRun/DetailRunPayroll/DetailRunPayroll')
);
const DetailPayrollRunPayment = React.lazy(
  () => import('../../pages/payrollRun/DetailRunPayroll/DetailPayment')
);

const PaymentCreate = React.lazy(
  () => import('../../pages/payrollRun/DetailRunPayroll/PaymentCreate')
);

const OneTimePaymentList = React.lazy(
  () => import('../../pages/payrollRun/OneTimePayment/OneTimePayment')
);

const OneTimePaymentCreate = React.lazy(
  () => import('../../pages/payrollRun/OneTimePayment/Create')
);

const payrollRun: RouteNode[] = [
  {
    label: 'Run Payroll',
    path: 'payroll-run',
    child: null,
    component: PayrollRunList,
  },
  {
    label: 'child',
    path: 'payroll-run/regular/run/:id',
    child: null,
    component: PayrollRunRun,
  },
  {
    label: 'child',
    path: 'payroll-run/one-time-payment',
    child: null,
    component: OneTimePaymentList,
  },
  {
    label: 'child',
    path: 'payroll-run/one-time-payment/create',
    child: null,
    component: OneTimePaymentCreate,
  },
  {
    label: '',
    path: 'payroll-run/regular/detail/:id',
    child: null,
    component: PayrollRunDetail,
  },
  {
    label: '',
    path: 'payroll-run/regular/detail/:id/payment-detail/:employeeId',
    child: null,
    component: DetailPayrollRunPayment,
  },
  {
    label: '',
    path: 'compensation/detail/:id',
    component: EmployeeCompensationDetail,
    child: null,
  },
  {
    label: '',
    path: 'payroll-run/regular/detail/:id/payment-detail/:employeeId/create',
    child: null,
    component: PaymentCreate,
  },
  {
    label: 'child',
    path: 'payroll-run/off-cycle/run/:id',
    child: null,
    component: PayrollRunRun,
  },
  {
    label: '',
    path: 'payroll-run/off-cycle/detail/:id',
    child: null,
    component: PayrollRunDetail,
  },
  {
    label: '',
    path: 'payroll-run/off-cycle/detail/:id/payment-detail/:employeeId',
    child: null,
    component: DetailPayrollRunPayment,
  },
  {
    label: '',
    path: 'payroll-run/off-cycle/detail/:id/payment-detail/:employeeId/create',
    child: null,
    component: PaymentCreate,
  },
];

export default payrollRun;
