import { useNavigate } from 'react-router-dom';
import { Box, SxProps, Typography, useTheme } from '@mui/material';
import { useCallback } from 'react';
import { Button, ESSAvatarProfile, Tags } from '../../../components/atom';
import { mergeDeep } from '../../../utils/deepMerge';
import {
  calculateDateDiff,
  calculateSinceJoin,
  formatDate,
  formatDateTimeFromUnixUTC,
} from '../../../utils/date';
import { ESSEmployeeProfile } from '../../../@types/EssEmployee';
import { useGetESSProfileHistoryQuery } from '../../../store/feature/service/essEmployee';
import dayjs from '../../../utils/dayjs';

type EmployeeBoxContainerProps = {
  sx?: SxProps;
  className?: string;
  data?: ESSEmployeeProfile;
};

const EmployeeBoxContainer = ({
  sx,
  className,
  data,
}: EmployeeBoxContainerProps) => {
  const navigate = useNavigate();
  const { palette } = useTheme();
  const style: SxProps = {
    backgroundColor: palette.gray[10],
    borderRadius: '1rem',
    width: '357px',
    padding: '20px',
    display: 'flex',
    flexDirection: 'column',
    gap: '1rem',
    '& .employee-box-avatar': {
      display: 'flex',
      justifyContent: 'center',
      width: '100%',
    },
    '& .employee-box-name': {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      gap: '0.5rem',
      pb: '1rem',
      width: '100%',
      borderBottom: `1px solid ${palette.gray[50]}`,
    },
    '& .avatar-box': {
      flex: 1,
      display: 'flex',
      justifyContent: 'center',
      transform: 'translateX(20px)',
    },
    '& .description-list-container': {
      display: 'flex',
      flexDirection: 'column',
      gap: '0.5rem',
    },
    '& .description-list': {
      display: 'flex',
      alignItems: 'center',
      gap: '1rem',
      width: '100%',
    },
    '& .description-label': {
      width: '88px',
    },
    '& .duration-counter': {
      ml: '0.5rem',
    },
  };
  const { data: history } = useGetESSProfileHistoryQuery({});
  const activeDataHistory: any =
    Array.isArray(history) && history.length > 0
      ? history.filter(history => history.statusName === 'Active')?.[0]
      : history?.[0];
  const getTimePassed = useCallback((timestamped: number) => {
    const currentTimestamp = Date.now() / 1000;
    const timestamp = timestamped;
    const date1 = dayjs.unix(timestamp);
    const date2 = dayjs.unix(currentTimestamp);
    const years = date2.diff(date1, 'year');
    const months = date2.diff(date1, 'month') % 12;
    const pluralYearsSuffix = years > 1 ? 's' : '';
    const pluralMonthsSuffix = months > 1 ? 's' : '';
    return `${years} year${pluralYearsSuffix} ${months} month${pluralMonthsSuffix}`;
  }, []);
  return (
    <Box sx={mergeDeep(style, sx)} className={className}>
      <Box className="employee-box-avatar">
        <Box className="avatar-box">
          <ESSAvatarProfile src={data?.profileUrl} />
        </Box>
        <Button
          variant="link"
          onClick={() => navigate('/ess/employee/details')}
        >
          Details
        </Button>
      </Box>
      <Box className="employee-box-name">
        <Typography
          className="font-bold"
          variant="body18"
          color={palette.gray[100]}
        >
          {data?.fullName || '-'}
        </Typography>
        <Typography
          className="font-normal"
          variant="body16"
          color={palette.gray[90]}
        >
          {data?.employeeNumber || '-'} • {data?.organization || '-'}
        </Typography>
        <Typography
          className="font-normal"
          variant="body16"
          color={palette.gray[90]}
        >
          {data?.jobPosition || '-'}
        </Typography>
      </Box>
      <Box className="description-list-container">
        <Box className="description-list">
          <Typography
            className="font-normal description-label"
            variant="body14"
            color={palette.gray[90]}
          >
            Join Date
          </Typography>
          <Box>
            <Typography
              className="font-normal"
              variant="body14"
              color={palette.gray[100]}
            >
              {activeDataHistory?.joinDate
                ? formatDateTimeFromUnixUTC(activeDataHistory?.joinDate)
                : '-'}
            </Typography>
            {activeDataHistory?.joinDate && (
              <Tags
                label={
                  activeDataHistory?.joinDate
                    ? getTimePassed(activeDataHistory?.joinDate)
                    : '-'
                }
                variant="gray"
                className="duration-counter"
              />
            )}
          </Box>
        </Box>
        <Box className="description-list">
          <Typography
            className="font-normal description-label"
            variant="body14"
            color={palette.gray[90]}
          >
            Organization
          </Typography>
          <Typography
            className="font-normal"
            variant="body14"
            color={palette.gray[100]}
          >
            {data?.organization || '-'}
          </Typography>
        </Box>
        <Box className="description-list">
          <Typography
            className="font-normal description-label"
            variant="body14"
            color={palette.gray[90]}
          >
            Grade
          </Typography>
          <Typography
            className="font-normal"
            variant="body14"
            color={palette.gray[100]}
          >
            {activeDataHistory?.jobGrade || '-'}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default EmployeeBoxContainer;
