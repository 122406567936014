import React from 'react';

const SwitchDesc = () => (
  <svg
    style={{ minWidth: '16px' }}
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.3333 2.66666V13.3333M11.3333 13.3333L8.66667 10.6667M11.3333 13.3333L14 10.6667M4.66667 13.3333V2.66666M4.66667 2.66666L2 5.33333M4.66667 2.66666L7.33333 5.33333"
      stroke="#DCE0E5"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <mask
      id="mask0_8250_14520"
      style={{ maskType: 'alpha' }}
      maskUnits="userSpaceOnUse"
      x="1"
      y="1"
      width="14"
      height="14"
    >
      <path
        d="M11.3333 2.66667V13.3333M11.3333 13.3333L8.66667 10.6667M11.3333 13.3333L14 10.6667M4.66667 13.3333V2.66667M4.66667 2.66667L2 5.33334M4.66667 2.66667L7.33333 5.33334"
        stroke="#DCE0E5"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </mask>
    <g mask="url(#mask0_8250_14520)">
      <path d="M0 1H10L6 15H4L0 1Z" fill="#045FC4" />
    </g>
  </svg>
);

export default SwitchDesc;
