import React from 'react';
import { Box, Popover, SxProps, Typography, useTheme } from '@mui/material';
import { Button } from '../../atom';
import { ActionMenuProps } from '../../../@types/TableProps';
import { DotsVertical, Edit, Check, Trash } from '../../atom/icon';

type VerticalDotsActionProps = {
  actionPopoverDirection?: 'left' | 'right';
  actionMenus?: Array<ActionMenuProps>;
  onClickMenuAction?: Function;
  disabled?: boolean;
  data?: any;
  sx?: SxProps;
  isCorrectionEnabled?: boolean;
  variant?: 'primary' | 'secondary' | 'tertiary' | 'outlined' | 'link';
};

const ActionButton = ({
  actionPopoverDirection = 'right',
  actionMenus,
  onClickMenuAction,
  disabled = false,
  data,
  sx,
  variant,
  isCorrectionEnabled = true,
}: VerticalDotsActionProps) => {
  const { palette } = useTheme();
  const ActionMenu: ActionMenuProps[] = actionMenus || [
    {
      name: 'update',
      title: 'Update',
      Icon: <Edit />,
    },
    ...(isCorrectionEnabled
      ? [
          {
            name: 'correction',
            title: 'Correction',
            Icon: <Check />,
          },
        ]
      : []),
    {
      name: 'delete',
      title: 'Delete',
      Icon: <Trash />,
      hasBorderTop: true,
      color: palette.red[50],
    },
  ];

  const [ActionAnchorEl, setActionAnchorEl] =
    React.useState<HTMLButtonElement | null>(null);

  const openAction = Boolean(ActionAnchorEl);

  const handleActionClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setActionAnchorEl(event.currentTarget);
  };
  const handleActionClose = () => {
    setActionAnchorEl(null);
  };
  return (
    <>
      <Popover
        open={openAction}
        anchorEl={ActionAnchorEl}
        onClose={handleActionClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: actionPopoverDirection === 'left' ? 'left' : 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: actionPopoverDirection === 'left' ? 'left' : 'right',
        }}
        sx={{
          marginTop: '4px',
          '& .MuiPaper-root': {
            borderRadius: '5px',
          },
        }}
      >
        <Box
          sx={{
            boxSizing: 'border-box',
            paddingY: '4px',
            minWidth: '220px',
            '& .MuiButtonBase-root': {
              border: 'none',
              borderRadius: 0,
              justifyContent: 'flex-start',
              height: '44px',
              gap: '12px',
              paddingX: '16px',
              fontWeight: 400,
              lineHeight: '20px',
              '& svg': {
                width: '20px',
                height: '20px',
                minWidth: '20px !important',
                minHeight: '20px',
              },
            },
          }}
        >
          {ActionMenu &&
            ActionMenu?.map(action => {
              const {
                Icon,
                title,
                name,
                hasBorderTop,
                color,
                isDisabled = false,
              } = action;
              return (
                <Button
                  key={name}
                  fullWidth
                  variant="outlined"
                  disabled={isDisabled}
                  onClick={() =>
                    onClickMenuAction?.(name, data, setActionAnchorEl)
                  }
                  sx={{
                    '&:disabled': {
                      cursor: 'not-allowed',
                      pointerEvents: 'all !important',
                    },

                    borderTop: hasBorderTop
                      ? `1px solid ${palette.gray[50]} !important`
                      : 'none',
                  }}
                >
                  {Icon}
                  <Typography
                    variant="body14"
                    color={color || palette.gray[100]}
                  >
                    {title}
                  </Typography>
                </Button>
              );
            })}
        </Box>
      </Popover>
      <Button
        sx={
          sx || {
            minWidth: '23px',
            minHeight: '23px',
            padding: '2px',
            width: '23px',
            height: '23px',
            borderRadius: '7px',
          }
        }
        variant={variant || 'outlined'}
        onClick={handleActionClick}
        disabled={disabled}
      >
        <DotsVertical />
      </Button>
    </>
  );
};

export default ActionButton;
