import { QueryParams } from '../../../@types/QueryParams';
import { apiSlice } from '../api/apiSlice';
import {
  JobLevelListType,
  JobLevelDetailsType,
} from '../../../@types/JobLevel';

interface Query {
  id: string;
  effectiveDate?: string;
}

export const JobLevelApiSlice = apiSlice.injectEndpoints({
  endpoints: builder => ({
    getJobLevelList: builder.query<JobLevelListType, QueryParams>({
      query: params => {
        const { search, page, sortType, sort, searchString } = params;
        return {
          url: `/organization/job-levels`,
          params: { search, page, sortType, sort, ...searchString },
        };
      },
      providesTags: ['JobLevelList'],
    }),
    getJobLevelDetails: builder.query<JobLevelDetailsType, Query>({
      query: (params: { effectiveDate: any; id: any }) => {
        const { effectiveDate, id } = params;
        return {
          url: `/organization/job-levels/${id}`,
          params: { effectiveDate },
        };
      },
      providesTags: ['jobLevelDetail'],
    }),
    updateJobLevel: builder.mutation({
      query: (params: { id: any; payload: any }) => {
        const { id, payload } = params;
        return {
          url: `/organization/job-levels/${id}`,
          method: 'PUT',
          body: payload,
        };
      },
      invalidatesTags: ['jobLevelDetail'],
    }),
    createJobLevel: builder.mutation({
      query: payload => ({
        url: '/organization/job-levels',
        method: 'POST',
        body: payload,
      }),
      invalidatesTags: ['JobLevelList'],
    }),
    deleteJobLevel: builder.mutation<any, any>({
      query: id => ({
        url: `/organization/job-levels/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['JobLevelList'],
    }),
    cancelUpcomingJobLevel: builder.mutation<any, any>({
      query: ({ id }) => ({
        url: `/organization/job-levels/${id}/cancellation`,
        method: 'DELETE',
      }),
      invalidatesTags: ['jobLevelDetail', 'JobLevelList'],
    }),
  }),
});

export const {
  useCancelUpcomingJobLevelMutation,
  useCreateJobLevelMutation,
  useDeleteJobLevelMutation,
  useGetJobLevelDetailsQuery,
  useGetJobLevelListQuery,
  useUpdateJobLevelMutation,
} = JobLevelApiSlice;
