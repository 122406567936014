import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { EmployeeTabsMenu } from '../../@types/EmployeeTypes';

interface isListViewInitialState {
  personal: boolean;
  employment: boolean;
  bank: boolean;
  statutory: boolean;
  experience: boolean;
  education: boolean;
  family: boolean;
  files: boolean;
  history: boolean;
}

const initialState: isListViewInitialState = {
  personal: false,
  employment: false,
  bank: false,
  statutory: false,
  experience: false,
  education: false,
  family: false,
  files: false,
  history: false,
};

const employeeTabSlice = createSlice({
  name: 'employeeTab',
  initialState,
  reducers: {
    updateTabView: (state, action: PayloadAction<EmployeeTabsMenu>) => {
      state[action.payload] = !state[action.payload];
    },
  },
});

export const { updateTabView } = employeeTabSlice.actions;
export const employeeTabReducer = employeeTabSlice.reducer;
