const CalendarCheck01 = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="28"
    height="28"
    viewBox="0 0 28 28"
    fill="none"
  >
    <path
      d="M24.5 11.6667H3.5M18.6667 2.33334V7.00001M9.33333 2.33334V7.00001M10.5 18.6667L12.8333 21L18.0833 15.75M9.1 25.6667H18.9C20.8602 25.6667 21.8403 25.6667 22.589 25.2852C23.2475 24.9496 23.783 24.4142 24.1185 23.7556C24.5 23.007 24.5 22.0269 24.5 20.0667V10.2667C24.5 8.30649 24.5 7.3264 24.1185 6.57771C23.783 5.91914 23.2475 5.38371 22.589 5.04815C21.8403 4.66668 20.8602 4.66668 18.9 4.66668H9.1C7.13982 4.66668 6.15972 4.66668 5.41103 5.04815C4.75247 5.38371 4.21703 5.91914 3.88148 6.57771C3.5 7.3264 3.5 8.30649 3.5 10.2667V20.0667C3.5 22.0269 3.5 23.007 3.88148 23.7556C4.21703 24.4142 4.75247 24.9496 5.41103 25.2852C6.15972 25.6667 7.13982 25.6667 9.1 25.6667Z"
      stroke="#124987"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default CalendarCheck01;
