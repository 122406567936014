import { apiSlice } from '../api/apiSlice';

type ForgotPasswordData = {
  email: string;
};

export const forgotPasswordApiSlice = apiSlice.injectEndpoints({
  endpoints: builder => ({
    sendEmail: builder.mutation<void, ForgotPasswordData>({
      query: credentials => ({
        url: '/identity/authenticate/forgot-password',
        method: 'POST',
        body: credentials,
      }),
    }),
  }),
});

export const { useSendEmailMutation } = forgotPasswordApiSlice;
