import React from 'react';
import { RouteNode } from '../../@types';

const List = React.lazy(() => import('../../pages/banks/List'));
const Details = React.lazy(() => import('../../pages/banks/Details'));
const Create = React.lazy(() => import('../../pages/banks/Create'));
const Update = React.lazy(() => import('../../pages/banks/Update'));

const bank: RouteNode[] = [
  {
    label: 'Bank',
    path: 'bank',
    component: List,
    child: null,
  },
  {
    label: '',
    path: 'bank/details/:id',
    component: Details,
    child: null,
  },
  {
    label: '',
    path: 'bank/create',
    component: Create,
    child: null,
  },
  {
    label: '',
    path: 'bank/update/:id',
    component: Update,
    child: null,
  },
];

export default bank;
