import { QueryParams } from '../../../../@types/QueryParams';
import { apiSlice } from '../../api/apiSlice';
import {
  ChartOfAccountDetailsType,
  ChartOfAccountListType,
} from '../../../../@types/accounting/chartOfAccounts';

interface Query {
  id: string;
  effectiveDate?: string;
}

export const ChartOfAccountApiSlice = apiSlice.injectEndpoints({
  endpoints: builder => ({
    getChartOfAccountList: builder.query<ChartOfAccountListType, QueryParams>({
      query: params => {
        const { search, page, sortType, sort, searchString } = params;
        return {
          url: `/payroll/chart-of-accounts`,
          params: { search, page, sortType, sort, ...searchString },
        };
      },
      providesTags: ['taxLocationList'],
    }),
    getChartOfAccountDetails: builder.query<any, Query>({
      query: (params: { id: any }) => {
        const { id } = params;
        return {
          url: `/payroll/chart-of-accounts/${id}`,
        };
      },
      providesTags: ['taxLocationDetail'],
    }),
    createChartOfAccount: builder.mutation({
      query: payload => ({
        url: '/payroll/chart-of-accounts',
        method: 'POST',
        body: payload,
      }),
      invalidatesTags: ['taxLocationList'],
    }),
  }),
});

export const {
  useGetChartOfAccountListQuery,
  useGetChartOfAccountDetailsQuery,
  useCreateChartOfAccountMutation,
} = ChartOfAccountApiSlice;
