import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { accumulatorApiSlice } from './service/accumulatorApiSlice';
import { AccumulatorPayItem } from '../../@types/AccumulatorTypes';

interface initialStateI {
  messageSuccess: string;
  messageError: string;
  payItems: Array<AccumulatorPayItem>;
}

const initialState: initialStateI = {
  messageSuccess: '',
  messageError: '',
  payItems: [],
};

const accumulatorSlice = createSlice({
  name: 'accumulator',
  initialState,
  reducers: {
    removeMessage: state => {
      state.messageSuccess = '';
      state.messageError = '';
    },
    addPayItem: (state, action: PayloadAction<AccumulatorPayItem>) => {
      const exist = state.payItems.findIndex(
        item => item.id === action.payload.id
      );
      if (exist === -1) {
        state.payItems.push(action.payload);
        state.messageSuccess = 'Pay Item added';
      } else {
        state.messageError = 'Error! Pay Item already exist!';
      }
    },
    updatePayItem: (
      state,
      action: PayloadAction<{ id: string; data: AccumulatorPayItem }>
    ) => {
      const { payload } = action;
      const existingIndex = state.payItems.findIndex(
        obj => obj.id === payload.data.id && obj.id !== payload.id
      );
      const index = state.payItems.findIndex(obj => obj.id === payload.id);
      if (existingIndex === -1) {
        state.payItems[index] = payload.data;
        state.messageSuccess = 'Pay Item edited';
      } else {
        state.messageError = 'Error! Pay Item already exist!';
      }
    },
    deleteAccumulatorPayItems: (state, action: PayloadAction<string>) => {
      const filtered = state.payItems.filter(
        item => item.id !== action.payload
      );
      state.payItems = filtered;
      state.messageSuccess = 'Pay Item removed';
    },
    resetAccumulatorPayItems: state => {
      state.payItems = [];
    },
    fillAccumulatorPayItems: (state, _action) => {
      state.payItems = _action.payload;
    },
  },
  extraReducers(builder) {
    builder.addMatcher(
      accumulatorApiSlice.endpoints.createAccumulator.matchFulfilled,
      (state, _action) => {
        state.messageSuccess = 'Accumulator created';
      }
    );
    builder.addMatcher(
      accumulatorApiSlice.endpoints.updateAccumulator.matchFulfilled,
      (state, _action: any) => {
        if (_action.payload.typeOfChange === 'UPDATED') {
          state.messageSuccess = 'Accumulator updated';
        } else if (_action.payload.typeOfChange === 'CORRECTED') {
          state.messageSuccess = 'Accumulator corrected';
        }
      }
    );
    builder.addMatcher(
      accumulatorApiSlice.endpoints.deleteAccumulator.matchFulfilled,
      (state, _action) => {
        state.messageSuccess = 'Accumulator deleted';
      }
    );
    builder.addMatcher(
      accumulatorApiSlice.endpoints.deleteAccumulator.matchRejected,
      (state, _action) => {
        state.messageError =
          "This accumulator can't be deleted since it has been used in the payroll run.";
      }
    );
    builder.addMatcher(
      accumulatorApiSlice.endpoints.cancelChangesAccumulator.matchFulfilled,
      (state, _action) => {
        state.messageSuccess = 'Upcoming Changes cancelled';
      }
    );
  },
});

export const {
  removeMessage,
  addPayItem,
  updatePayItem,
  deleteAccumulatorPayItems,
  resetAccumulatorPayItems,
  fillAccumulatorPayItems,
} = accumulatorSlice.actions;
export const accumulatorReducer = accumulatorSlice.reducer;
