import { Box, SxProps, Typography, useTheme } from '@mui/material';
import { ClockHistory } from '../../../atom/icon';
import { formatDateTimeFromUnixUTC } from '../../../../utils/date';

type LastUpdatedProps = {
  lastUpdated?: number;
};

const LastUpdated = ({ lastUpdated }: LastUpdatedProps) => {
  const { palette } = useTheme();
  const style: SxProps = {
    display: 'flex',
    alignItems: 'center',
    columnGap: '8px',
    '& .last-updated': {
      fontWeight: 400,
    },
  };
  return (
    <Box sx={style}>
      <ClockHistory />
      <Typography
        variant="body14"
        className="last-updated"
        color={palette.gray[80]}
      >
        The last corrected data effective as of{' '}
        <span style={{ color: palette.gray[90], fontWeight: 700 }}>
          {formatDateTimeFromUnixUTC(lastUpdated || null)}
        </span>
      </Typography>
    </Box>
  );
};

export default LastUpdated;
