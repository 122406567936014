import React, { useState, useEffect } from 'react';
import { Box, Typography, SxProps, useTheme } from '@mui/material';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router-dom';
import { Button } from '../../components/atom';
import InputWithLabel from '../../components/molecule/form/InputWithLabel/InputWithLabel';
import { Eye, EyeOff, CheckCircle } from '../../components/atom/icon';
import { useResetPasswordMutation } from '../../store/feature/service/resetPasswordSlice';
import { mappingError } from '../../utils/errorMapping';
import { Notification } from '../../components/molecule';

const NewPassword = () => {
  const { palette } = useTheme();
  const location = useLocation();
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [isValid, setIsValid] = useState(false);
  const { control, watch, handleSubmit, setError } = useForm({
    defaultValues: {
      password: '',
    },
  });

  const [submitResetPassword, { isLoading, isSuccess }] =
    useResetPasswordMutation();

  const validateRules = (value: string) => ({
    minLength: value && value.length >= 8,
    symbolNumber:
      value &&
      /^(?=.*[~`!@#$%^&*()+=\-[\]\\';,./{}|\\":<>?])(?=.*[0-9]).*$/g.test(
        value
      ),
    lowerCap: value && /^(?=.*[a-z])(?=.*[A-Z]).*$/.test(value),
  });

  const style: SxProps = {
    height: '100vh',
    width: '100vw',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    justifyContent: 'center',
    '& .container': {
      width: '400px',
    },
    '& .section': {
      mb: '1.5rem',
    },
    '& .header': {
      textAlign: 'center',
    },
    '& .newPassword': {
      fontWeight: 700,
      textAlign: 'center',
    },
    '& .caption': {
      lineHeight: '20px',
      letterSpacing: '0.05px',
    },
    '& .passwordSection': {
      mb: '1rem',
      pb: '1rem',
      borderBottom: `1px solid ${palette.gray[50]}`,
    },
    '& .requirement': {
      mb: '0.5rem',
      display: 'flex',
      alignItems: 'center',
    },
    '& .passwordTitle': {
      fontWeight: 500,
    },
    '& .requirementList': {
      fontWeight: 400,
    },
    '& .checkCircle': {
      mr: '0.5rem',
      fontSize: '20px',
    },
    '& .minLength': {
      color: validateRules(watch('password')).minLength
        ? palette.green[60]
        : palette.gray[80],
    },
    '& .symbolNumber': {
      color: validateRules(watch('password')).symbolNumber
        ? palette.green[60]
        : palette.gray[80],
    },
    '& .lowerCap': {
      color: validateRules(watch('password')).lowerCap
        ? palette.green[60]
        : palette.gray[80],
    },
    '& .buttonPassword': {
      border: 'none',
    },
  };

  const onSubmit: SubmitHandler<{ password: string }> = async form => {
    try {
      const payload = {
        newPassword: form.password,
        token:
          location.pathname.split('/')[location.pathname.split('/').length - 1],
      };
      await submitResetPassword(payload).unwrap();
    } catch (error) {
      const err = error as any;
      mappingError(err.data.errors, setError);
    }
  };

  const handleClose = () => {
    setTimeout(() => {
      navigate('/login');
    }, 500);
  };

  useEffect(() => {
    if (watch('password') !== '') {
      const password = watch('password');
      const result = validateRules(password);
      if (Object.values(result).every(val => val)) {
        setIsValid(true);
      } else {
        setIsValid(false);
      }
    } else {
      setIsValid(false);
    }
  }, [watch('password')]);

  return (
    <Box sx={style}>
      <form className="container" onSubmit={handleSubmit(onSubmit)}>
        <Box className="section header">
          <Typography variant="h6" className="newPassword">
            Create a New Password
          </Typography>
          <Typography variant="paragraph14" className="caption">
            Enter your new password below.
          </Typography>
        </Box>
        <Box className="passwordSection">
          <InputWithLabel
            label="New Password"
            name="password"
            placeholder="••••••••"
            control={control}
            inputProps={{ maxLength: 128 }}
            type={showPassword ? 'text' : 'password'}
            suffixIcon={
              <Button
                onClick={() => setShowPassword(!showPassword)}
                disableRipple
                disableFocusRipple
                disableTouchRipple
                variant="outlined"
                className="buttonPassword"
              >
                {showPassword ? <Eye /> : <EyeOff />}
              </Button>
            }
          />
        </Box>
        <Box className="section">
          <Typography variant="body14" className="requirement passwordTitle">
            Password requirements
          </Typography>
          <Box className="requirement">
            <CheckCircle className="checkCircle minLength" />
            <Typography
              variant="body14"
              className="requirementList"
              color={
                validateRules(watch('password')).minLength
                  ? palette.green[60]
                  : palette.gray[80]
              }
            >
              Min. 8 characters
            </Typography>
          </Box>
          <Box className="requirement">
            <CheckCircle className="checkCircle symbolNumber" />
            <Typography
              variant="body14"
              className="requirementList"
              color={
                validateRules(watch('password')).symbolNumber
                  ? palette.green[60]
                  : palette.gray[80]
              }
            >
              Include Symbol and Number (e.g. 0-9)
            </Typography>
          </Box>
          <Box className="requirement">
            <CheckCircle className="checkCircle lowerCap" />
            <Typography
              variant="body14"
              className="requirementList"
              color={
                validateRules(watch('password')).lowerCap
                  ? palette.green[60]
                  : palette.gray[80]
              }
            >
              Include Uppercase (e.g. A-Z) and Lowercase (e.g. a-z)
            </Typography>
          </Box>
        </Box>
        <Button
          type="submit"
          fullWidth
          disabled={!isValid || isLoading}
          isLoading={isLoading}
        >
          Set Password
        </Button>
      </form>
      <Notification
        open={isSuccess}
        variant="success"
        message="Your password successfully updated. You can now log in with the new password."
        onNotificationClose={handleClose}
      />
    </Box>
  );
};

export default NewPassword;
