import { RouteNode } from '../../@types';
import { Building07 } from '../../components/atom/icon';
import jobGrade from './jobGrade';
import jobPosition from './jobPosition';
import legalEntity from './legalEntity';
import organizationStructure from './organizationStructure';
import workLocation from './workLocation';
import organizationUnit from './organizationUnit';
import StatutoryRoute from './statutory';
import BankRoute from './bank';
import JobLevel from './jobLevel';
import legalEntityTaxLocationRoute from './legalEntityTaxLocation';
import fileRoute from './file';

export const Organization: RouteNode[] = [
  {
    label: 'Organization',
    icon: Building07,
    path: 'organization',
    component: null,
    child: [
      ...legalEntity,
      ...organizationStructure,
      ...jobGrade,
      ...JobLevel,
      ...jobPosition,
      ...organizationUnit,
      ...workLocation,
      ...StatutoryRoute,
      ...BankRoute,
      ...legalEntityTaxLocationRoute,
      ...fileRoute,
    ],
  },
];
