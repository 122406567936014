import { Box, Grid } from '@mui/material';
import Skeleton from 'react-loading-skeleton';

const TaskItemLoading = () => (
  <Grid item container gap="8px">
    <Grid item>
      <Skeleton
        style={{
          width: '24px',
          height: '24px',
          borderRadius: '8px',
        }}
      />
    </Grid>
    <Grid xs item container gap="8px">
      <Grid item>
        <Skeleton style={{ width: '200px', height: '24px' }} />
      </Grid>
      <Grid item container gap="16px">
        <Grid item>
          <Skeleton
            style={{
              borderRadius: '8px',
              width: '40px',
              height: '40px',
            }}
          />
        </Grid>
        <Grid item container direction="column" gap="4px" xs>
          <Grid item>
            <Skeleton style={{ width: '320px', height: '20px' }} />
          </Grid>
          <Grid item>
            <Skeleton style={{ width: '40px', height: '16px' }} />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  </Grid>
);

export default TaskItemLoading;
