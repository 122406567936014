import * as yup from 'yup';
import {
  validationEmailField,
  validationNumberLength,
  validationTextField,
} from './defaultValidations';

export default yup.object().shape({
  other: yup.string().when('modules', {
    is: (modules: Array<string>) =>
      modules.findIndex(val => val === 'Other') !== -1,
    then: validationTextField('Other', 1, 1000),
  }),
  fullName: validationTextField('Full Name', 3, 50),
  jobTitle: validationTextField('Job Title', 3, 50),
  company: validationTextField('Company', 3, 50),
  businessEmail: validationEmailField(
    'Business Email',
    'Your Email is invalid.',
    8,
    100
  ),
  phoneNumber: validationNumberLength('Phone Number', 5, 15),
});
