import {
  Box,
  Pagination as Paginate,
  PaginationItem,
  PaginationProps,
  Stack,
  Typography,
  useTheme,
  useMediaQuery,
} from '@mui/material';
import {
  ChevronLeft,
  ChevronLeftDouble,
  ChevronRight,
  ChevronRightDouble,
} from '../../atom/icon';

const Pagination = (
  Props: PaginationProps & {
    totalData: number;
    showTotalData?: boolean;
    limit?: number;
  }
) => {
  const { totalData, page, showTotalData = true, limit = 10, ...props } = Props;
  const { palette } = useTheme();
  const matches = useMediaQuery('(max-width:599px)');
  return (
    <Box
      className="pagination-test"
      sx={{
        display: 'flex',
        gap: matches ? '24px' : 0,
        flexDirection: matches ? 'column' : 'row',
        justifyContent: totalData > 10 ? 'space-between' : 'flex-start',
        alignItems: 'center',
        '& li': {
          '& .MuiButtonBase-root': {
            padding: 0,
            height: '36px',
            width: '36px',
            borderRadius: '8px',
            '&.Mui-selected': {
              color: palette.blue[50],
            },
            '& svg': {
              // transform: 'scale(0.8333)',
            },
            '&.MuiPaginationItem-root:hover': {
              background: palette.blue[20],
            },
            '&.MuiPaginationItem-root.Mui-selected:hover': {
              background: palette.blue[20],
            },
            '&.MuiPaginationItem-root.Mui-selected': {
              background: palette.blue[20],
            },
            '& .MuiTouchRipple-root span': {
              background: palette.blue[30],
            },
          },
        },
      }}
    >
      {showTotalData && (
        <Typography variant="body14" color={palette.gray[90]}>
          Show: {totalData > 0 && ((page || 1) - 1) * limit + 1}{' '}
          {totalData > 0 && '- '}
          {(page || 0) * limit > totalData
            ? totalData
            : (page || 0) * limit} of {totalData}
        </Typography>
      )}

      {totalData > limit && (
        <Stack spacing={2}>
          <Paginate
            {...props}
            showFirstButton
            showLastButton
            sx={{
              '& .MuiPagination-ul': {
                gap: '8px',
              },
            }}
            renderItem={item => (
              <PaginationItem
                {...item}
                sx={{
                  '&.MuiButtonBase-root': {
                    margin: 0,
                  },
                  '& .MuiButtonBase-root': {
                    width: '36px',
                    height: '36px',
                    minWidth: '36px',
                    padding: 0,
                    '& svg': {
                      transform: 'scale(0.8333)',
                    },
                  },
                }}
                components={{
                  first: () => <ChevronLeftDouble />,
                  previous: () => <ChevronLeft />,
                  next: () => <ChevronRight />,
                  last: () => <ChevronRightDouble />,
                }}
              />
            )}
          />
        </Stack>
      )}
    </Box>
  );
};

export default Pagination;
