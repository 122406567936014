import { Box, CircularProgress, Grid, useTheme } from '@mui/material';
import { useParams } from 'react-router-dom';
import { educationHeaderKey } from '../../../../constants/employee';
import EducationSection from './EducationSection';
import { GraduationHat01 } from '../../../../components/atom/icon';
import EmptyCard from '../../../../components/molecule/emptyCard/EmptyCard';
import { TabContainer } from '../../../../components/template';
import { useGetESSEducationListQuery } from '../../../../store/feature/service/essEmployee';

const Education = () => {
  const params = useParams();
  const { palette } = useTheme();
  const { data, isLoading } = useGetESSEducationListQuery({
    id: params.id || '',
  });

  return (
    <Grid container spacing={2}>
      <Grid item xs={6} container rowSpacing={3}>
        {data?.map((item: any, index: number) => (
          <EducationSection
            key={JSON.stringify(item)}
            index={index}
            headerKey={educationHeaderKey}
            groupId={item?.data?.old?.groupId || item?.data?.new?.groupId}
          />
        ))}
        {data && data?.length < 1 && (
          <EmptyCard
            style={{
              '& svg path': {
                stroke: palette.gray[80],
                strokeWidth: '5px',
              },
            }}
            icon={<GraduationHat01 />}
            label="Education Info"
            customLabel="Education"
            isESS
            onClickAddTop={() => {}}
          />
        )}
      </Grid>
    </Grid>
  );
};

export default Education;
