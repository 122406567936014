import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { sortData } from '../../utils/dataDisplay';
import { workflowApiSlice } from './service/workflowApiSlice';

export type workflowItemTab = {
  id: any;
  field: string;
  actionType: string;
};

export type workflowType = {
  id: number;
  data: Array<workflowItemTab>;
};

export type WorkflowInitialType = {
  // FIELD SELECTION
  dataField: any[];
  filteredDataField: any[];
  updatedDataField: number;
  selectedDataField: any;
  // APPROVAL
  dataApproval: Array<any>;
  filteredDataApproval: Array<any>;
  updatedDataApproval: number;
  selectedDataApproval: any;
  // CONTRIBUTOR
  dataContributor: any[];
  filteredDataContributor: Array<any>;
  updatedDataContributor: number;
  selectedDataContributor: any;
  // CCROLE
  dataCCRole: any[];
  filteredDataCCRole: Array<any>;
  updatedDataCCRole: number;
  selectedDataCCRole: any;
  // OTHER TYPES

  // payGroupData: any;
  messageSuccess: string;
  messageError: string;
  workFlows: Array<workflowType>;
};

const initialState: WorkflowInitialType = {
  // FIELD SELECTION
  dataField: [],
  filteredDataField: [],
  updatedDataField: 0,
  selectedDataField: 0,
  // APPROVAL
  dataApproval: [],
  filteredDataApproval: [],
  updatedDataApproval: 0,
  selectedDataApproval: 0,
  // CONTRIBUTOR
  dataContributor: [],
  filteredDataContributor: [],
  updatedDataContributor: 0,
  selectedDataContributor: 0,
  // CCROLE
  dataCCRole: [],
  filteredDataCCRole: [],
  updatedDataCCRole: 0,
  selectedDataCCRole: 0,
  // OTHER VARS
  // payGroupData: {},
  messageSuccess: '',
  messageError: '',
  workFlows: [],
};

const workflowSlice = createSlice({
  name: 'workflow',
  initialState,
  reducers: {
    // FIELD SELECTION ACTIONS
    getSelectedAddField: (
      state,
      action: PayloadAction<{ search: string; sortBy: any; sortType: any }>
    ) => {
      const { search, sortBy, sortType } = action.payload;
      state.filteredDataField = state.dataField;
      if (search) {
        state.filteredDataField = state.filteredDataField?.filter(
          dataField =>
            dataField?.field?.label
              ?.toLowerCase()
              ?.includes(search.toLowerCase())
          // || dataField.act?.toLowerCase().includes(search.toLowerCase())
        );
      }
      state.filteredDataField = sortData(
        state.filteredDataField,
        sortBy,
        sortType
      );
    },
    addSelectedAddField: (
      state,
      action: PayloadAction<{
        field: {};
        actionType: {}[];
      }>
    ) => {
      console.log('ini data addField selection', action.payload);
      state.dataField = [...state.dataField, action.payload];
      state.updatedDataField = new Date().getTime();
      state.messageSuccess = 'Field added';
    },
    removeSelectedAddField: (state, action): any => {
      state.dataField = state.dataField.filter(
        (item: any) => item?.id !== action?.payload
      );
      state.messageSuccess = 'Field removed';
      state.updatedDataField = new Date().getTime();
      state.selectedDataField = 0;
    },
    updateSelectedAddField: (state, action: PayloadAction<any>) => {
      state.dataField = state.dataField.map(item => {
        if (item.id === state.selectedDataField) {
          return { ...item, ...action.payload };
        }
        return item;
      });
      state.updatedDataField = new Date().getTime();
      state.messageSuccess = 'Field updated';
      state.selectedDataField = 0;
    },
    setSelectedAddField: (state: WorkflowInitialType, action: any) => {
      state.selectedDataField = action?.payload;
    },
    // APPROVAL ACTIONS
    getApprover: (
      state,
      action: PayloadAction<{ search: string; sortBy: any; sortType: any }>
    ) => {
      const { search, sortBy, sortType } = action.payload;
      state.filteredDataApproval = state.dataApproval;
      if (search) {
        state.filteredDataApproval = state.filteredDataApproval?.filter(
          dataApprov =>
            dataApprov?.type?.toLowerCase()?.includes(search.toLowerCase()) ||
            dataApprov?.nameBasedOnType?.label
              ?.toLowerCase()
              .includes(search.toLowerCase())
        );
      }
      state.filteredDataApproval = sortData(
        state.filteredDataApproval,
        sortBy,
        sortType
      );
    },
    addApprover: (state, action: PayloadAction<any>) => {
      const payload = {
        ...action?.payload,
        order: state.dataApproval.length + 1,
      };
      state.dataApproval = [...state.dataApproval, payload];
      state.updatedDataApproval = new Date().getTime();
      state.messageSuccess = 'Approver added';
    },
    removeApprover: (state, action): any => {
      state.dataApproval = state.dataApproval.filter(
        (item: any) => item?.id !== action?.payload
      );
      state.messageSuccess = 'Approver removed';
      state.updatedDataApproval = new Date().getTime();
      state.selectedDataApproval = 0;
    },
    updateApprover: (state, action: PayloadAction<any>) => {
      state.dataApproval = state.dataApproval.map(item => {
        if (item.id === state.selectedDataApproval) {
          return { ...item, ...action.payload };
        }
        return item;
      });
      state.updatedDataApproval = new Date().getTime();
      state.messageSuccess = 'Approver edited';
      state.selectedDataApproval = 0;
    },
    setApprover: (state: WorkflowInitialType, action: any) => {
      state.selectedDataApproval = action?.payload;
    },
    resetApprover: state => {
      state.selectedDataApproval = 0;
    },
    // CONTRIBUTOR ACTIONS
    getContributor: (
      state,
      action: PayloadAction<{ search: string; sortBy: any; sortType: any }>
    ) => {
      const { search, sortBy, sortType } = action.payload;
      state.filteredDataContributor = state.dataContributor;
      if (search) {
        state.filteredDataContributor = state.filteredDataContributor?.filter(
          dataContributor =>
            dataContributor?.type
              ?.toLowerCase()
              ?.includes(search.toLowerCase()) ||
            dataContributor?.nameBasedOnType?.label
              ?.toLowerCase()
              .includes(search.toLowerCase())
        );
      }
      state.filteredDataContributor = sortData(
        state.filteredDataContributor,
        sortBy,
        sortType
      );
    },
    addContributor: (state, action: PayloadAction<any>) => {
      const payload = {
        ...action?.payload,
        order: state.dataContributor.length + 1,
      };
      state.dataContributor = [...state.dataContributor, payload];
      state.updatedDataContributor = new Date().getTime();
      state.messageSuccess = 'Contributor added';
    },
    removeContributor: (state, action): any => {
      state.dataContributor = state.dataContributor.filter(
        (item: any) => item?.id !== action?.payload
      );
      state.messageSuccess = 'Contributor removed';
      state.updatedDataContributor = new Date().getTime();
      state.selectedDataContributor = 0;
    },
    updateContributor: (state, action: PayloadAction<any>) => {
      state.dataContributor = state.dataContributor.map(item => {
        if (item.id === state.selectedDataContributor) {
          return { ...item, ...action.payload };
        }
        return item;
      });
      state.updatedDataContributor = new Date().getTime();
      state.messageSuccess = 'Contributor Edited';
      state.selectedDataContributor = 0;
    },
    setContributor: (state: WorkflowInitialType, action: any) => {
      state.selectedDataContributor = action?.payload;
    },
    resetContributor: state => {
      state.selectedDataContributor = 0;
    },
    // CCROLE ACTIONS
    getCCRole: (
      state,
      action: PayloadAction<{ search: string; sortBy: any; sortType: any }>
    ) => {
      const { search, sortBy, sortType } = action.payload;
      state.filteredDataCCRole = state.dataCCRole;
      if (search) {
        state.filteredDataCCRole = state.filteredDataCCRole?.filter(
          dataCC =>
            dataCC?.type?.toLowerCase()?.includes(search.toLowerCase()) ||
            dataCC?.nameBasedOnType?.label
              ?.toLowerCase()
              .includes(search.toLowerCase())
        );
      }
      state.filteredDataCCRole = sortData(
        state.filteredDataCCRole,
        sortBy,
        sortType
      );
    },
    addCCRole: (state, action: PayloadAction<any>) => {
      const payload = {
        ...action?.payload,
        order: state.dataCCRole.length + 1,
      };
      state.dataCCRole = [...state.dataCCRole, payload];
      state.updatedDataCCRole = new Date().getTime();
      state.messageSuccess = 'CC Role added';
    },
    removeCCRole: (state, action): any => {
      state.dataCCRole = state.dataCCRole.filter(
        (item: any) => item?.id !== action?.payload
      );
      state.messageSuccess = 'CC Role removed';
      state.updatedDataCCRole = new Date().getTime();
      state.selectedDataCCRole = 0;
    },
    updateCCRole: (state, action: PayloadAction<any>) => {
      state.dataCCRole = state.dataCCRole.map(item => {
        if (item.id === state.selectedDataCCRole) {
          return { ...item, ...action.payload };
        }
        return item;
      });
      state.updatedDataCCRole = new Date().getTime();
      state.messageSuccess = 'CC Role edited';
      state.selectedDataCCRole = 0;
    },
    setCCRole: (state: WorkflowInitialType, action: any) => {
      state.selectedDataCCRole = action?.payload;
    },
    resetCCRole: state => {
      state.selectedDataCCRole = 0;
    },
    // ANNOTHER ACTIONS
    removeMessage: state => {
      state.messageSuccess = '';
      state.messageError = '';
    },
    resetWorkflow: (state: WorkflowInitialType) => {
      state.dataField = [];
      state.dataApproval = [];
      state.dataContributor = [];
      state.dataCCRole = [];
      state.selectedDataApproval = 0;
      state.selectedDataCCRole = 0;
      state.selectedDataContributor = 0;
      state.selectedDataField = 0;
    },
    fetchDataApproval: (state, action): any => {
      const payload = action?.payload?.map((item: any) => ({
        ...item,
        id: Date.now(),
      }));
      state.dataApproval = state.dataApproval.concat(payload);
    },
    fetchDataContributor: (state, action): any => {
      const payload = action?.payload?.map((item: any) => ({
        ...item,
        id: Date.now(),
      }));
      state.dataContributor = state.dataContributor.concat(payload);
    },
    fetchDataCCRole: (state, action): any => {
      const payload = action?.payload?.map((item: any) => ({
        ...item,
        id: Date.now(),
      }));
      state.dataCCRole = state.dataCCRole.concat(payload);
    },
  },
  extraReducers(builder) {
    builder.addMatcher(
      workflowApiSlice.endpoints.createWorkflow.matchFulfilled,
      (state, _action) => {
        state.messageSuccess = 'Workflow Created';
      }
    );
    builder.addMatcher(
      workflowApiSlice.endpoints.updateWorkflow.matchFulfilled,
      (state, _action) => {
        state.messageSuccess = 'Workflow updated';
      }
    );
    builder.addMatcher(
      workflowApiSlice.endpoints.cancelUpcomingWorkflow.matchFulfilled,
      state => {
        state.messageSuccess = 'Upcoming changes cancelled';
      }
    );
  },
});

export const {
  // FIELD SELECTION
  addSelectedAddField,
  removeSelectedAddField,
  getSelectedAddField,
  updateSelectedAddField,
  setSelectedAddField,
  // APPROVAL
  getApprover,
  addApprover,
  setApprover,
  updateApprover,
  removeApprover,
  resetApprover,
  // Contributor
  getContributor,
  addContributor,
  setContributor,
  updateContributor,
  removeContributor,
  // CC ROLE
  getCCRole,
  addCCRole,
  setCCRole,
  updateCCRole,
  removeCCRole,
  // OTHER
  removeMessage,
  fetchDataApproval,
  fetchDataContributor,
  fetchDataCCRole,
  resetWorkflow,
} = workflowSlice.actions;
export const workflowSliceReducer = workflowSlice.reducer;
