import React from 'react';
import { Box, SxProps, useTheme } from '@mui/material';
import Select, { GroupBase, components } from 'react-select';
import { AsyncProps } from 'react-select/async';
import { ChevronRight, Close } from '../icon';

interface CustomSelectAsyncProps {}

const SelectAsync = React.forwardRef(
  <
    OptionType,
    IsMulti extends boolean = false,
    GroupType extends GroupBase<OptionType> = GroupBase<OptionType>
  >(
    {
      ...props
    }: AsyncProps<OptionType, IsMulti, GroupType> & CustomSelectAsyncProps,
    _ref: any
  ) => {
    const { palette } = useTheme();
    const { DropdownIndicator, ClearIndicator, MultiValueRemove } = components;

    const style: SxProps = {
      '& .select__control': {
        minHeight: '48px',
        boxSizing: 'border-box',
        padding: 0,
        borderRadius: '8px',
        '& .select__value-container': {
          paddingX: '12px',
          '& .select__multi-value': {
            height: '24px',
            boxSizing: 'border-box',
            padding: '2px 8px 2px 8px',
            display: 'flex',
            alignItems: 'center',
            background: palette.blue[20],
            gap: '4px',
            borderRadius: '6px',
            '& .select__multi-value__label': {
              color: `${palette.blue[70]} !important`,
              fontWeight: '500',
              fontSize: '14px',
              lineHeight: '20px',
            },
            '& .select__multi-value__remove': {
              '&:hover': {
                background: 'none',
              },
              padding: 0,
              '& .close--icon__multi': {
                height: '100%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                '& svg': {
                  '& path': {
                    stroke: palette.blue[90],
                  },
                  transform: 'scale(0.83333)',
                },
              },
            },
          },
        },
        '& .select__indicators .select__indicator': {
          '& .close--icon': {
            '& svg': {
              transform: 'scale(0.83333) !important',
            },
          },
          height: '100%',
          paddingRight: '12px',
          paddingY: 0,
          display: 'flex',
          alignItems: 'center',
          '& svg': {
            transform: 'scale(1.25)',
            rotate: '90deg',
            '& path': {
              stroke: palette.gray[90],
            },
          },
        },
        '& .select__indicators .select__clear-indicator': {
          paddingRight: '0px',
          '& .close--icon': {
            display: 'flex',
            alignItems: 'center',
            height: '100%',
            '& svg': {
              transform: 'scale(1)',
            },
          },
        },
        '& .select__value-container:not(.select__value-container--is-multi)': {
          height: '100%',
          padding: 0,
          '& .select__single-value': {
            marginLeft: '0px',
            display: 'flex',
            alignItems: 'center',
            paddingLeft: '13px',
          },
          '& .select__input-container': {
            paddingLeft: '13px',
            margin: 0,
            fontFamily: 'Inter',
            fontSize: '1rem',
            lineHeight: '24px',
            color: '#101B28',
            height: '100%',
            paddingY: '12px',
          },
          '& .select__placeholder': {
            margin: 0,
            paddingLeft: '13px',
            fontFamily: 'Inter',
            fontSize: '1rem',
            lineHeight: '24px',
            color: '#A5B1C0',
            height: '100%',
            paddingY: '12px',
          },
        },
      },
      '& .basic-single': {
        '& .select__menu .select__menu-list': {
          fontSize: '16px',
          fontWeight: '400',
          '& .select__option:not(.select__option--is-disabled)': {
            color: `${palette.gray[100]} !important`,
          },
        },
        '& .select__control:not(.select__control--is-focused)': {
          border: `1px solid ${palette.gray[50]}`,
        },
        '& .select__control--is-focused': {
          border: `1px solid ${palette.blue[50]}`,
          boxShadow: 'none',
        },
      },
    };

    const IndicatorDropdown = React.useCallback(
      (indicatorDropdownProps: any) => (
        <DropdownIndicator {...indicatorDropdownProps}>
          <Box
            sx={{
              height: '100%',
              display: 'flex',
              alignItems: 'center',
              '& svg': {
                transform: 'scale(0.83333) !important',
              },
            }}
          >
            <ChevronRight />
          </Box>
        </DropdownIndicator>
      ),
      []
    );

    const CloseDropdown = React.useCallback(
      (closeDropdownProps: any) => (
        <ClearIndicator {...closeDropdownProps}>
          <div className="close--icon">
            <Close />
          </div>
        </ClearIndicator>
      ),
      []
    );

    const RemoveIndicator = React.useCallback(
      (removeIndicatorProps: any) => (
        <MultiValueRemove {...removeIndicatorProps}>
          <div className="close--icon__multi">
            <Close />
          </div>
        </MultiValueRemove>
      ),
      []
    );
    return (
      <Box sx={style}>
        <Select
          className="basic-single"
          classNamePrefix="select"
          components={{
            IndicatorSeparator: null,
            DropdownIndicator: IndicatorDropdown,
            ClearIndicator: CloseDropdown,
            MultiValueRemove: RemoveIndicator,
          }}
          {...props}
        />
      </Box>
    );
  }
);

export default SelectAsync;
