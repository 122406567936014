import { createSlice } from '@reduxjs/toolkit';
import { JobLevelApiSlice } from './service/jobLevelSliceApi';

interface initialStateI {
  messageSuccess: string;
  messageError: string;
}

const initialState: initialStateI = {
  messageSuccess: '',
  messageError: '',
};

const jobLevelSlice = createSlice({
  name: 'jobLevel',
  initialState,
  reducers: {
    removeMessage: state => {
      state.messageSuccess = '';
      state.messageError = '';
    },
  },
  extraReducers(builder) {
    builder.addMatcher(
      JobLevelApiSlice.endpoints.createJobLevel.matchFulfilled,
      (state, _action) => {
        state.messageSuccess = 'Job Level created';
      }
    );
    builder.addMatcher(
      JobLevelApiSlice.endpoints.createJobLevel.matchRejected,
      (state, action) => {
        const payload = action.payload as any;
        state.messageError = payload?.data?.message;
      }
    );
    builder.addMatcher(
      JobLevelApiSlice.endpoints.updateJobLevel.matchFulfilled,
      (state, action) => {
        if (action?.payload?.typeOfChange === 'UPDATED') {
          state.messageSuccess = 'Job Level updated';
        } else if (action?.payload?.typeOfChange === 'CORRECTED') {
          state.messageSuccess = 'Job Level corrected';
        }
      }
    );
    builder.addMatcher(
      JobLevelApiSlice.endpoints.updateJobLevel.matchRejected,
      (state, action: any) => {
        const payload = action.payload as any;
        state.messageError = payload?.data?.message;
      }
    );
    builder.addMatcher(
      JobLevelApiSlice.endpoints.deleteJobLevel.matchFulfilled,
      (state, _action) => {
        state.messageSuccess = 'Job Level deleted';
      }
    );
    builder.addMatcher(
      JobLevelApiSlice.endpoints.deleteJobLevel.matchRejected,
      (state, _action: any) => {
        const payload = _action.payload as any;
        state.messageError = payload?.data?.message;
      }
    );
    builder.addMatcher(
      JobLevelApiSlice.endpoints.cancelUpcomingJobLevel.matchFulfilled,
      (state, _action) => {
        state.messageSuccess = 'Upcoming Changes cancelled';
      }
    );
    builder.addMatcher(
      JobLevelApiSlice.endpoints.cancelUpcomingJobLevel.matchRejected,
      (state, action) => {
        const payload = action.payload as any;
        state.messageError = payload?.data?.message;
      }
    );
  },
});

export const { removeMessage } = jobLevelSlice.actions;
export const jobLevelReducer = jobLevelSlice.reducer;
