import { apiSlice } from '../api/apiSlice';
import { AttendanceProcessList } from '../../../@types/AttendanceProcessTypes';

type QueryParams = {
  page: number;
  limit: number;
  sortBy: string;
  sortType: string;
  search: string;
  searchString: { [key: string]: any };
  id?: string | number;
};

type BaseParamsDetail = {
  processID: string;
};

type ResponseDetailSummary = {
  data:
    | [
        {
          id: string;
          'Employee ID': string;
          'Full Name': string;
          timeItems: Record<string, any>;
          attendanceSummary: Record<string, number>;
        }
      ]
    | null;
  page: number;
  limit: number;
  total: number;
};

type ResponseDetailView = {
  data: [
    {
      id: string;
      'Employee ID': string;
      'Full Name': string;
      timeItems: Record<string, number>;
      attendanceDaily: Record<string, Array<string>>;
    }
  ];
  page: number;
  limit: number;
  total: number;
};

export type ResponseDataDailyAttendance = {
  id: string;
  employeeNumber: string;
  fullName: string;
  date: number;
  shifts?: [
    {
      shiftInformation: {
        shiftCode: string;
        shiftName: string;
        shiftTime: {
          startTime: number;
          endTime: number;
        };
        clockIn: number;
        clockOut: number;
        overNightShift: boolean;
      };
      logHistory?: [
        {
          loggedAt: number;
          source: string;
          createdAt: number;
          type: 'CLOCK_OUT' | 'CLOCK_IN';
        }
      ];
      request: any;
    }
  ];
};

interface ListAttendanceDailyResponse {
  data: Array<ResponseDataDailyAttendance>;
  limit: number;
  page: number;
  total: number;
}

export const attendanceProcessApiSlice = apiSlice.injectEndpoints({
  endpoints: builder => ({
    attendanceProcessList: builder.query<AttendanceProcessList, QueryParams>({
      query: params => {
        const { search, page, sortType, sortBy, searchString } = params;
        return {
          url: '/time-management/attendances-process',
          params: { search, page, sortType, sortBy, ...searchString },
        };
      },
      providesTags: ['attendanceProcessList'],
    }),
    createAttendanceProcess: builder.mutation<void, any>({
      query: payload => ({
        url: '/time-management/attendances-process',
        method: 'POST',
        body: payload,
      }),
      invalidatesTags: ['attendanceProcessList'],
    }),
    tableHeaderReviewAttendance: builder.query<any, any>({
      query: ({
        cutOffFrom,
        cutOffTo,
        referenceId,
        referenceCode,
        source,
        data,
      }) => ({
        url: '/time-management/attendance-review/header',
        params: {
          cutOffFrom,
          cutOffTo,
          referenceId,
          referenceCode,
          source,
          data,
        },
      }),
    }),
    attendanceReviewSummary: builder.query<
      any,
      QueryParams & {
        cutOffFrom: number;
        cutOffTo: number;
        referenceId: string;
        referenceCode: string;
        source?: string;
        data?: string;
      }
    >({
      query: params => {
        const {
          search,
          page,
          sortType,
          sortBy,
          searchString,
          cutOffFrom,
          cutOffTo,
          referenceId,
          referenceCode,
          source,
          data,
        } = params;
        return {
          url: '/time-management/attendance-review/summary',
          params: {
            search,
            page,
            sortType,
            sortBy,
            cutOffFrom,
            cutOffTo,
            referenceId,
            referenceCode,
            source,
            data,
            ...searchString,
          },
        };
      },
    }),
    attendanceReviewDaily: builder.query<
      any,
      QueryParams & {
        cutOffFrom: number;
        cutOffTo: number;
        referenceId: string;
        referenceCode: string;
        source?: string;
        data?: string;
      }
    >({
      query: params => {
        const {
          search,
          page,
          sortType,
          sortBy,
          searchString,
          cutOffFrom,
          cutOffTo,
          referenceId,
          referenceCode,
          source,
          data,
        } = params;
        return {
          url: '/time-management/attendance-review/daily',
          params: {
            search,
            page,
            sortType,
            sortBy,
            cutOffFrom,
            cutOffTo,
            referenceId,
            referenceCode,
            source,
            data,
            ...searchString,
          },
        };
      },
    }),
    // API for Detail Attendance Process
    getAttendanceProcessDetailSummaryView: builder.query<
      ResponseDetailSummary,
      BaseParamsDetail & QueryParams
    >({
      query: params => {
        const {
          processID,
          page,
          limit,
          sortBy,
          sortType,
          searchString,
          search,
        } = params;
        return {
          url: `/time-management/attendance-process/${processID}/summary`,
          params: {
            page,
            limit,
            sortBy,
            sortType,
            search,
            ...searchString,
          },
        };
      },
      providesTags: ['summaryViewAttendanceProcess'],
    }),
    getAttendanceProcessDetailDailySummary: builder.query<
      ResponseDetailView,
      BaseParamsDetail & QueryParams
    >({
      query: params => {
        const {
          processID,
          page,
          limit,
          sortBy,
          sortType,
          searchString,
          search,
        } = params;
        return {
          url: `/time-management/attendance-process/${processID}/daily`,
          params: {
            page,
            limit,
            sortBy,
            sortType,
            search,
            ...searchString,
          },
        };
      },
      providesTags: ['dailySummaryAttendanceProcess'],
    }),
    getAttendanceHeaderTable: builder.query<any, BaseParamsDetail>({
      query: params => {
        const { processID } = params;
        return {
          url: `/time-management/attendance-process/${processID}/header`,
        };
      },
    }),
    deleteAttendanceProcess: builder.mutation<unknown, { processID: string }>({
      query: params => {
        const { processID } = params;
        return {
          url: `/time-management/attendance-process/${processID}`,
          method: 'DELETE',
        };
      },
      invalidatesTags: ['attendanceProcessList'],
    }),
    checkIfAttendanceIsReprocessing: builder.query<
      {
        statusCode: null | 'REPROCESSING' | 'COMPLETED';
        startReprocessingTime: number;
      },
      { processID: string }
    >({
      query: params => {
        const { processID } = params;
        return {
          url: `/time-management/attendance-process/status/${processID}`,
        };
      },
      providesTags: ['attendanceProcessList'],
    }),
    reprocessAttendanceData: builder.mutation<
      any,
      {
        processID: string;
        startDate: number;
        endDate: number;
        startReprocessingTime: number;
      }
    >({
      query: params => {
        const { processID, startDate, endDate, startReprocessingTime } = params;
        return {
          url: `time-management/attendance-process/reprocess/${processID}`,
          method: 'POST',
          body: {
            startDate,
            endDate,
            startReprocessingTime,
          },
        };
      },
      invalidatesTags: [
        'dailySummaryAttendanceProcess',
        'summaryViewAttendanceProcess',
      ],
    }),
    cancelAttendanceData: builder.mutation<
      any,
      {
        processID: string;
      }
    >({
      query: params => {
        const { processID } = params;
        return {
          url: `time-management/attendance-process/cancel/${processID}`,
          method: 'POST',
        };
      },
    }),
    // DAILY ATTENDANCE
    getListAttendanceDaily: builder.query<
      ListAttendanceDailyResponse,
      {
        dateTime: number;
        page: number;
        limit: number;
        sortBy: any;
        sortType: any;
        search: any;
        searchString: Record<string, any>;
      }
    >({
      query: params => {
        const {
          dateTime,
          page,
          limit,
          sortBy,
          sortType,
          search,
          searchString,
        } = params;
        return {
          url: `/time-management/daily-attendance/${dateTime}`,
          params: { page, limit, sortBy, sortType, search, ...searchString },
        };
      },
      providesTags: ['listDailyAttendance'],
    }),
    getListUpcomingDailyAttendance: builder.query<Array<number>, any>({
      query: () => ({
        url: '/time-management/daily-attendance/upcoming-request',
      }),
      providesTags: ['listDailyAttendance'],
    }),
  }),
});

export const {
  useAttendanceProcessListQuery,
  useCreateAttendanceProcessMutation,
  useTableHeaderReviewAttendanceQuery,
  useAttendanceReviewSummaryQuery,
  useAttendanceReviewDailyQuery,
  useGetAttendanceProcessDetailSummaryViewQuery,
  useGetAttendanceProcessDetailDailySummaryQuery,
  useGetAttendanceHeaderTableQuery,
  useDeleteAttendanceProcessMutation,
  useCheckIfAttendanceIsReprocessingQuery,
  useReprocessAttendanceDataMutation,
  useCancelAttendanceDataMutation,
  useGetListAttendanceDailyQuery,
  useGetListUpcomingDailyAttendanceQuery,
} = attendanceProcessApiSlice;
