import { Button, CircularProgress, SxProps, useTheme } from '@mui/material';
import color from 'color';
import React from 'react';

import { ButtonProps } from '../../../@types';
import { mergeDeep } from '../../../utils/deepMerge';

const ExtendButton = (Props: ButtonProps) => {
  const {
    children,
    variant = 'primary',
    size = 'default',
    destructive = false,
    disabled = false,
    sx,
    isLoading = false,
    ...props
  } = Props;

  const { palette } = useTheme();

  const style: SxProps = {
    fontSize: '0.875rem',
    fontWeight: '600',
    borderRadius: '0.5rem',
    padding: '0.875rem 1.25rem 0.875rem 1.25rem',
    display: 'flex',
    gap: '0.5rem',
    ...(size === 'default'
      ? {
          height: variant === 'link' ? '1.25rem' : '3rem',
        }
      : {
          height: variant === 'link' ? '1.25rem' : '2.25rem',
        }),
    ...(variant === 'primary' && {
      ...(destructive
        ? {
            background: color(palette.red[50])
              .alpha(disabled ? 0.5 : 1)
              .toString(),
            '&:hover': {
              background: palette.red[70],
            },
          }
        : {
            background: color(palette.blue[50])
              .alpha(disabled ? 0.5 : 1)
              .toString(),
            ...(!disabled && {
              '&:hover': {
                background: palette.blue[70],
              },
            }),
          }),
      color: color(palette.gray[0]).toString(),
      '&:disabled': {
        color: `${color(palette.gray[0]).toString()} !important`,
      },
    }),
    ...(variant === 'secondary' && {
      ...(destructive
        ? {
            background: color(palette.red[20])
              .alpha(disabled ? 0.5 : 1)
              .toString(),
            '&:hover': {
              background: palette.red[30],
            },
            color: color(palette.red[50]).toString(),
            '&:disabled': {
              color: `${color(palette.red[50])
                .alpha(0.5)
                .toString()} !important`,
            },
          }
        : {
            background: color(palette.blue[20])
              .alpha(disabled ? 0.5 : 1)
              .toString(),
            ...(!disabled && {
              '&:hover': {
                background: palette.blue[30],
              },
            }),
            color: color(palette.blue[50]).toString(),
            '&:disabled': {
              color: `${color(palette.blue[50])
                .alpha(0.5)
                .toString()} !important`,
            },
          }),
    }),
    ...(variant === 'tertiary' && {
      ...(destructive
        ? {
            background: color(palette.gray[0])
              .alpha(disabled ? 0.5 : 1)
              .toString(),
            ...(!disabled && {
              '&:hover': {
                background: palette.red[20],
              },
            }),
            color: color(palette.red[50]).toString(),
            '&:disabled': {
              color: `${color(palette.red[50])
                .alpha(0.5)
                .toString()} !important`,
            },
          }
        : {
            background: color(palette.gray[0]).alpha(0).toString(),
            ...(!disabled && {
              '&:hover': {
                background: palette.blue[20],
              },
            }),
            color: color(palette.blue[50]).toString(),
            '&:disabled': {
              color: `${color(palette.blue[50])
                .alpha(0.5)
                .toString()} !important`,
            },
          }),
    }),
    ...(variant === 'outlined' && {
      background: color(disabled ? palette.gray[40] : palette.gray[0])
        .alpha(disabled ? 0.5 : 1)
        .toString(),
      border: `1px solid ${color(palette.gray[50])
        .alpha(disabled ? 0.5 : 1)
        .toString()}`,
      ...(!disabled && {
        '&:hover': {
          background: palette.gray[20],
        },
      }),
      color: color(palette.gray[90]).toString(),
      '&:disabled': {
        color: `${color(palette.gray[90]).alpha(0.5).toString()} !important`,
        '& svg path': {
          stroke: `${color(palette.gray[90]).alpha(0.5).toString()} !important`,
        },
      },
    }),
    ...(variant === 'link' && {
      padding: 0,
      minWidth: 0,
      background: color(palette.gray[0])
        .alpha(disabled ? 0.5 : 1)
        .toString(),
      ...(destructive
        ? {
            color: color(palette.red[50]).toString(),
            '&:hover': {
              color: color(palette.red[70]).toString(),
              background: palette.gray[0],
            },
            '&:disabled': {
              color: `${color(palette.red[50])
                .alpha(0.5)
                .toString()} !important`,
            },
          }
        : {
            color: color(palette.blue[50]).toString(),
            '&:hover': {
              color: color(palette.blue[70]).toString(),
              background: palette.gray[0],
            },
            '&:disabled': {
              color: `${color(palette.blue[50])
                .alpha(0.5)
                .toString()} !important`,
              '& svg path': {
                stroke: `${color(palette.blue[50])
                  .alpha(0.5)
                  .toString()} !important`,
              },
            },
          }),
    }),
    boxSizing: 'border-box',
  };

  return (
    <Button
      sx={mergeDeep(style, sx)}
      disabled={disabled}
      disableRipple={variant === 'link'}
      {...props}
    >
      {isLoading ? <CircularProgress /> : children}
    </Button>
  );
};

export default ExtendButton;
