import { createSlice } from '@reduxjs/toolkit';
import { scheduleApiSlice } from './service/scheduleApiSlice';

interface initialStateI {
  messageSuccess: string;
  messageError: string;
}

const initialState: initialStateI = {
  messageSuccess: '',
  messageError: '',
};

const scheduleSlice = createSlice({
  name: 'schedule',
  initialState,
  reducers: {
    removeMessage: state => {
      state.messageSuccess = '';
      state.messageError = '';
    },
    setMessageSuccess: (state, action) => {
      state.messageSuccess = action.payload;
    },
  },
  extraReducers(builder) {
    builder.addMatcher(
      scheduleApiSlice.endpoints.createSchedule.matchFulfilled,
      (state, _action) => {
        state.messageSuccess = 'Schedule created';
      }
    );
    builder.addMatcher(
      scheduleApiSlice.endpoints.createSchedule.matchRejected,
      (state, action) => {
        const payload = action.payload as any;
        state.messageError = payload?.data?.message || 'Internal Server Error';
      }
    );
    builder.addMatcher(
      scheduleApiSlice.endpoints.updateSchedule.matchFulfilled,
      (state, action) => {
        if (action?.payload?.typeOfChange === 'UPDATED') {
          state.messageSuccess = 'Schedule updated';
        } else if (action?.payload?.typeOfChange === 'CORRECTED') {
          state.messageSuccess = 'Schedule corrected';
        }
      }
    );
    builder.addMatcher(
      scheduleApiSlice.endpoints.updateSchedule.matchRejected,
      (state, action) => {
        const payload = action.payload as any;
        state.messageError = payload?.data?.message || 'Internal Server Error';
      }
    );
    builder.addMatcher(
      scheduleApiSlice.endpoints.deleteSchedule.matchFulfilled,
      (state, _action) => {
        state.messageSuccess = 'Schedule deleted';
      }
    );
    builder.addMatcher(
      scheduleApiSlice.endpoints.deleteSchedule.matchRejected,
      (state, action) => {
        const payload = action.payload as any;
        if (payload.data.errors.id[0] === 'EMPLOYEE-WORK-SCHEDULE-EXIST') {
          state.messageError =
            "This data can't be deleted as it had been associated with an employee.";
        } else {
          state.messageError =
            payload?.data?.message || 'Internal Server Error';
        }
      }
    );
  },
});

export const { removeMessage, setMessageSuccess } = scheduleSlice.actions;
export const scheduleReducer = scheduleSlice.reducer;
