import { lazy } from 'react';

const List = lazy(() => import('../../../pages/shift/List'));
const Create = lazy(() => import('../../../pages/shift/Create'));
const Detail = lazy(() => import('../../../pages/shift/Detail'));
const Update = lazy(() => import('../../../pages/shift/Update'));
const Correction = lazy(() => import('../../../pages/shift/Correction'));

export const shift = [
  {
    label: 'Shift',
    child: null,
    path: 'shift',
    component: List,
  },
  {
    label: '',
    child: null,
    path: 'shift/create',
    component: Create,
  },
  {
    label: '',
    child: null,
    path: 'shift/detail/:id',
    component: Detail,
  },
  {
    label: '',
    child: null,
    path: 'shift/update/:id',
    component: Update,
  },
  {
    label: '',
    child: null,
    path: 'shift/correction/:id',
    component: Correction,
  },
];
