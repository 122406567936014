import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { employeeEarningApiSlice } from './service/employee/earningApiSlice';
import { EarningType, ParamsType } from '../../@types/EmployeeEarning';
import { sortData } from '../../utils/dataDisplay';

type initialStateType = {
  messageSuccess: string;
  messageError: string;
  earnings: Array<EarningType>;
  filteredEarnings: Array<EarningType>;
  updatedEarning: number;
};

const initialState: initialStateType = {
  messageSuccess: '',
  messageError: '',
  filteredEarnings: [],
  earnings: [],
  updatedEarning: 0,
};

const employeeEarningSlice = createSlice({
  name: 'employeeEarning',
  initialState,
  reducers: {
    removeMessage: state => {
      state.messageSuccess = '';
      state.messageError = '';
    },
    getEarningList: (state, action: PayloadAction<ParamsType>) => {
      const { search } = action.payload;
      state.filteredEarnings = state.earnings;
      if (search) {
        state.filteredEarnings = state.filteredEarnings.filter(
          earning =>
            earning.payItemName?.toLowerCase().includes(search.toLowerCase()) ||
            earning.payItemCode?.toLowerCase().includes(search.toLowerCase())
        );
      }
      state.filteredEarnings = sortData(
        state.filteredEarnings,
        action.payload.sortBy,
        action.payload.sortType
      );
    },
    addEarning: (state, action: PayloadAction<EarningType>) => {
      const indexEarning = state.earnings.findIndex(
        item => item.id === action.payload.id
      );
      if (indexEarning === -1) {
        state.earnings.push(action.payload);
        state.messageSuccess = 'Earnings added';
      } else {
        state.messageError = 'Error! Earning already exist!';
      }
      state.updatedEarning = new Date().getTime();
    },
    updateEarning: (
      state,
      action: PayloadAction<{ id: string; data: EarningType }>
    ) => {
      const indexExistingEarning = state.earnings.findIndex(
        item =>
          item.id !== action.payload.id && item.id === action.payload.data.id
      );

      const indexEarning = state.earnings.findIndex(
        item => item.id === action.payload.id
      );

      if (indexExistingEarning === -1) {
        state.earnings[indexEarning] = action.payload.data;
        state.updatedEarning = new Date().getTime();
        state.messageSuccess = 'Earnings edited';
      } else {
        state.messageError = 'Error! Earning already exist!';
      }
    },
    deleteEarning: (state, action: PayloadAction<string>) => {
      state.earnings = state.earnings.filter(
        item => item.id !== action.payload
      );
      console.log('action payload ===>', action.payload);
      state.messageSuccess = 'Earnings removed';
      state.updatedEarning = new Date().getTime();
    },
    setEarning: (state, action: PayloadAction<Array<EarningType>>) => {
      state.earnings = action.payload;
      state.filteredEarnings = action.payload;
    },
    resetEarning: state => {
      state.earnings = [];
      state.filteredEarnings = [];
    },
  },
  extraReducers(builder) {
    builder.addMatcher(
      employeeEarningApiSlice.endpoints.createEmployeeEarning.matchFulfilled,
      (state, _action) => {
        state.messageSuccess = 'Earnings updated';
      }
    );
    builder.addMatcher(
      employeeEarningApiSlice.endpoints.updateEmployeeEarning.matchFulfilled,
      (state, _action) => {
        if (_action.payload.typeOfChange === 'UPDATED') {
          state.messageSuccess = 'Earnings updated';
        } else if (_action.payload.typeOfChange === 'CORRECTED') {
          state.messageSuccess = 'Earnings corrected ';
        }
      }
    );
    builder.addMatcher(
      employeeEarningApiSlice.endpoints.cancelChangesEmployeeEarning
        .matchFulfilled,
      (state, _action) => {
        state.messageSuccess = 'Upcoming Changes cancelled';
      }
    );
  },
});

export const {
  removeMessage,
  addEarning,
  updateEarning,
  resetEarning,
  getEarningList,
  deleteEarning,
  setEarning,
} = employeeEarningSlice.actions;
export const employeeEarningReducer = employeeEarningSlice.reducer;
