import { Box, Typography, useTheme } from '@mui/material';
import { VerticalDotsAction } from '..';
import { useAppSelector, RootState } from '../../../store';
import { ActionMenuProps } from '../../../@types/TableProps';

type SubHeaderProps = {
  label: string;
  idx: number;
  onClickMenuAction?: Function;
  data?: any;
  disabled?: boolean;
  isCorrectionEnabled?: boolean;
  actionMenu?: ActionMenuProps[];
};
const SubHeader = ({
  label,
  idx,
  onClickMenuAction,
  data,
  isCorrectionEnabled = true,
  disabled = false,
  actionMenu,
}: SubHeaderProps) => {
  const { palette } = useTheme();
  const { isTerminated } = useAppSelector(
    (state: RootState) => state.termination
  );
  return (
    <Box
      className="list"
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        borderRadius: '10px',
        alignItems: 'center',
        backgroundColor: palette.gray[20],
        width: '480px',
      }}
    >
      <Typography
        className="sub-title"
        variant="body16"
        color={palette.gray[90]}
      >
        {label} {idx + 1}
      </Typography>
      {isCorrectionEnabled && (
        <VerticalDotsAction
          onClickMenuAction={onClickMenuAction}
          data={data}
          disabled={disabled || isTerminated}
          actionMenus={actionMenu}
        />
      )}
    </Box>
  );
};

export default SubHeader;
