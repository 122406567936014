import React from 'react';

const UserHistory = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1.79102 9.58333L3.45719 11.25L5.12435 9.58333M3.25341 10.8333C3.22317 10.5597 3.20764 10.2817 3.20764 10C3.20764 5.85786 6.56551 2.5 10.7076 2.5C14.8498 2.5 18.2076 5.85786 18.2076 10C18.2076 14.1421 14.8498 17.5 10.7076 17.5C8.35159 17.5 6.24927 16.4136 4.87431 14.7144M6.666 13.75C7.74738 12.657 9.21554 11.9853 10.8327 11.9853C12.4498 11.9853 13.9179 12.657 14.9993 13.75M12.916 8.23529C12.916 9.33174 11.9833 10.2206 10.8327 10.2206C9.68207 10.2206 8.74933 9.33174 8.74933 8.23529C8.74933 7.13885 9.68207 6.25 10.8327 6.25C11.9833 6.25 12.916 7.13885 12.916 8.23529Z"
      stroke="#475667"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default UserHistory;
