const GraduationHat01 = () => (
  <svg
    width="64"
    height="64"
    viewBox="0 0 64 64"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13.333 26.6667V42.6964C13.333 43.6536 13.333 44.1322 13.4788 44.5548C13.6076 44.9284 13.818 45.2688 14.0946 45.5512C14.4073 45.8705 14.8354 46.0845 15.6916 46.5126L30.0916 53.7126C30.7912 54.0624 31.141 54.2374 31.5079 54.3062C31.8329 54.3672 32.1664 54.3672 32.4914 54.3062C32.8583 54.2374 33.2082 54.0624 33.9078 53.7126L48.3078 46.5126C49.164 46.0845 49.592 45.8705 49.9048 45.5512C50.1814 45.2688 50.3917 44.9284 50.5206 44.5548C50.6663 44.1322 50.6663 43.6536 50.6663 42.6964V26.6667M5.33301 22.6667L31.0456 9.81037C31.3954 9.63546 31.5703 9.54801 31.7538 9.51359C31.9163 9.4831 32.083 9.4831 32.2455 9.51359C32.429 9.54801 32.6039 9.63546 32.9537 9.81037L58.6663 22.6667L32.9537 35.523C32.6039 35.6979 32.429 35.7853 32.2455 35.8198C32.083 35.8503 31.9163 35.8503 31.7538 35.8198C31.5703 35.7853 31.3954 35.6979 31.0456 35.523L5.33301 22.6667Z"
      stroke="#8A98A8"
      strokeWidth="4.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default GraduationHat01;
