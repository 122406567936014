import { apiSlice } from '../api/apiSlice';

export const shiftApiSlice = apiSlice.injectEndpoints({
  endpoints: builder => ({
    getListShift: builder.query<any, any>({
      query: params => {
        const { search, page, sortType, sortBy, limit, searchString } = params;
        return {
          url: '/time-management/shifts',
          params: { search, page, sortType, sortBy, limit, ...searchString },
        };
      },
      providesTags: ['listShift'],
    }),
    createShift: builder.mutation<any, any>({
      query: payload => ({
        url: '/time-management/shifts',
        method: 'POST',
        body: payload,
      }),
      invalidatesTags: ['listShift'],
    }),
    shiftDetail: builder.query<any, any>({
      query: ({ id, effectiveDate }) => ({
        url: `/time-management/shifts/${id}`,
        params: { effectiveDate },
      }),
      providesTags: ['detailShift'],
    }),
    deleteShift: builder.mutation<void, { id: string }>({
      query: ({ id }) => ({
        url: `/time-management/shifts/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['listShift'],
    }),
    updateShift: builder.mutation<any, any>({
      query: ({ id, payload }) => ({
        url: `/time-management/shifts/${id}`,
        method: 'PUT',
        body: payload,
      }),
      invalidatesTags: ['listShift', 'detailShift'],
    }),
    getShiftInfo: builder.query<any, any>({
      query: id => ({
        url: `/time-management/shifts/${id}/info`,
      }),
    }),
  }),
});

export const {
  useGetListShiftQuery,
  useCreateShiftMutation,
  useShiftDetailQuery,
  useDeleteShiftMutation,
  useUpdateShiftMutation,
  useGetShiftInfoQuery,
} = shiftApiSlice;
