import { Box, SxProps } from '@mui/material';
import React from 'react';

interface ContainerProps {
  children: React.ReactNode;
  sx?: SxProps;
}

const ESSContainer = ({ children, sx }: ContainerProps) => (
  <Box
    sx={{
      width: '100%',
      height: 'calc(100vh - 56px)',
      padding: '24px 120px',
      overflow: 'auto',
      boxSizing: 'border-box',
      display: 'flex',
      flexDirection: 'column',
      gap: '2rem',
      ...sx,
    }}
  >
    {children}
  </Box>
);

export default ESSContainer;
