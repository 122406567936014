import React from 'react';
import { RouteNode } from '../../../@types';
import Star06 from '../../../components/atom/icon/Star06';

const UserInterface = React.lazy(
  () => import('../../../pages/userInterface/index')
);

const UserInterfaceRoute: RouteNode[] = [
  {
    label: 'User Interface',
    path: 'user-interface',
    icon: Star06,
    component: UserInterface,
    child: null,
  },
];

export default UserInterfaceRoute;
