import React from 'react';
import { Box, SxProps, ToggleButton, ToggleButtonGroup } from '@mui/material';
import { UseFormSetValue, UseFormWatch } from 'react-hook-form';
import { ContactUsFormProps } from '../../@types/ContactUs';

interface Step1Props {
  onChange: Function;
  setValue: UseFormSetValue<ContactUsFormProps>;
  watch: UseFormWatch<ContactUsFormProps>;
}

const Step1 = ({ onChange, setValue, watch }: Step1Props) => {
  const style: SxProps = {
    '& .flag': {
      width: '1.5rem',
      height: '1.5rem',
      marginRight: '0.75rem',
    },
  };
  const handleChange = (
    _: React.MouseEvent<HTMLElement>,
    selectedCountry: string
  ) => {
    setValue('country', selectedCountry);
    if (selectedCountry) {
      setTimeout(() => {
        onChange();
      }, 1000);
    }
  };
  return (
    <ToggleButtonGroup
      orientation="vertical"
      value={watch('country')}
      exclusive
      sx={style}
      fullWidth
      onChange={handleChange}
    >
      <ToggleButton value="IDN" className="option not-last" fullWidth>
        <Box className="flag circle">
          <img src="/assets/Indonesia.png" alt="indonesia" title="Indonesia" />
        </Box>
        Indonesia
      </ToggleButton>
      <ToggleButton value="MYS" className="option not-last" fullWidth>
        <Box className="flag circle">
          <img src="/assets/Malaysia.png" alt="malaysia" title="Malaysia" />
        </Box>
        Malaysia
      </ToggleButton>
      <ToggleButton value="THA" className="option" fullWidth>
        <Box className="flag circle">
          <img src="/assets/Thailand.png" alt="thailand" title="Thailand" />
        </Box>
        Thailand
      </ToggleButton>
    </ToggleButtonGroup>
  );
};

export default Step1;
