import { SvgIcon as MuiSvgIcon, SvgIconProps, styled } from '@mui/material';

export const SvgIcon = styled(MuiSvgIcon, {
  name: 'CustomIcon',
  // shouldForwardProp: prop => prop !== 'fill',
})<SvgIconProps>(() => ({
  fill: 'none',
  stroke: 'currentColor',
  strokeLinecap: 'round',
  strokeLinejoin: 'round',
  strokeWidth: '1.5',
}));

SvgIcon.defaultProps = {
  viewBox: '0 0 20 20',
  focusable: 'false',
  'aria-hidden': 'true',
};
