import React from 'react';

const CalendarClose = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14 7.66732V5.86732C14 4.74721 14 4.18716 13.782 3.75934C13.5903 3.38301 13.2843 3.07705 12.908 2.8853C12.4802 2.66732 11.9201 2.66732 10.8 2.66732H5.2C4.07989 2.66732 3.51984 2.66732 3.09202 2.8853C2.71569 3.07705 2.40973 3.38301 2.21799 3.75934C2 4.18716 2 4.74721 2 5.86732V11.4673C2 12.5874 2 13.1475 2.21799 13.5753C2.40973 13.9516 2.71569 14.2576 3.09202 14.4493C3.51984 14.6673 4.07989 14.6673 5.2 14.6673H8.33333M14 6.66732H2M10.6667 1.33398V4.00065M5.33333 1.33398V4.00065M11 10.6673L12.6667 12.334M12.6667 12.334L14.3333 14.0007M12.6667 12.334L14.3333 10.6673M12.6667 12.334L11 14.0007"
      stroke="#8A98A8"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default CalendarClose;
