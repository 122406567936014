import { lazy } from 'react';

const Create = lazy(() => import('../../../pages/attendanceMachine/Create'));
const List = lazy(() => import('../../../pages/attendanceMachine/List'));
const Detail = lazy(() => import('../../../pages/attendanceMachine/Detail'));
const Update = lazy(() => import('../../../pages/attendanceMachine/Update'));

export const attendanceMachine = [
  {
    label: 'Attendance Machine',
    child: null,
    path: 'attendance-machine',
    component: List,
  },
  {
    label: '',
    child: null,
    path: 'attendance-machine/create',
    component: Create,
  },
  {
    label: '',
    child: null,
    path: 'attendance-machine/detail/:id',
    component: Detail,
  },
  {
    label: '',
    child: null,
    path: 'attendance-machine/update/:id',
    component: Update,
  },
];
