const DataFlow04 = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_15456_10676)">
      <path
        d="M3.33268 14.9993V14.8327C3.33268 13.4326 3.33268 12.7325 3.60517 12.1977C3.84485 11.7273 4.2273 11.3448 4.69771 11.1052C5.23249 10.8327 5.93255 10.8327 7.33268 10.8327H12.666C14.0661 10.8327 14.7662 10.8327 15.301 11.1052C15.7714 11.3448 16.1538 11.7273 16.3935 12.1977C16.666 12.7325 16.666 13.4326 16.666 14.8327V14.9993M3.33268 14.9993C2.41221 14.9993 1.66602 15.7455 1.66602 16.666C1.66602 17.5865 2.41221 18.3327 3.33268 18.3327C4.25316 18.3327 4.99935 17.5865 4.99935 16.666C4.99935 15.7455 4.25316 14.9993 3.33268 14.9993ZM16.666 14.9993C15.7455 14.9993 14.9993 15.7455 14.9993 16.666C14.9993 17.5865 15.7455 18.3327 16.666 18.3327C17.5865 18.3327 18.3327 17.5865 18.3327 16.666C18.3327 15.7455 17.5865 14.9993 16.666 14.9993ZM9.99935 14.9993C9.07887 14.9993 8.33268 15.7455 8.33268 16.666C8.33268 17.5865 9.07887 18.3327 9.99935 18.3327C10.9198 18.3327 11.666 17.5865 11.666 16.666C11.666 15.7455 10.9198 14.9993 9.99935 14.9993ZM9.99935 14.9993V6.66602M4.99935 6.66602H14.9993C15.7759 6.66602 16.1642 6.66602 16.4705 6.53915C16.8789 6.36999 17.2033 6.04554 17.3725 5.63716C17.4993 5.33087 17.4993 4.94259 17.4993 4.16602C17.4993 3.38945 17.4993 3.00116 17.3725 2.69488C17.2033 2.2865 16.8789 1.96204 16.4705 1.79288C16.1642 1.66602 15.7759 1.66602 14.9993 1.66602H4.99935C4.22278 1.66602 3.8345 1.66602 3.52821 1.79288C3.11983 1.96204 2.79537 2.2865 2.62622 2.69488C2.49935 3.00116 2.49935 3.38945 2.49935 4.16602C2.49935 4.94259 2.49935 5.33087 2.62622 5.63716C2.79537 6.04554 3.11983 6.36999 3.52821 6.53915C3.8345 6.66602 4.22278 6.66602 4.99935 6.66602Z"
        stroke="#475667"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_15456_10676">
        <rect width="20" height="20" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default DataFlow04;
