import React from 'react';
import { RouteNode } from '../../@types';
import { DownloadCloud01 } from '../../components/atom/icon';

const List = React.lazy(() => import('../../pages/export/List'));

export const exportFileRoute: RouteNode[] = [
  {
    icon: DownloadCloud01,
    label: 'Export',
    path: 'export',
    component: List,
    child: null,
  },
];
