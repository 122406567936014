const BirthdayIcon = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="Icon">
      <path
        id="Vector"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.0878 22.37L3.88784 22.31C3.88784 21.6 1.98584 21.468 1.98584 20.024L3.93784 20.01C3.93784 18.182 3.97184 14.478 3.97584 13.928C3.97584 12.66 4.47984 12.048 5.57584 12.03C6.46184 12.016 17.1338 11.984 18.2678 11.99C19.5598 11.99 20.0198 12.84 20.0378 14.016C20.0378 14.478 20.0378 17.946 20.0378 19.9L22.1758 19.886C22.1658 21.4 20.0878 21.704 20.0878 22.37ZM17.4578 15.888C17.4578 14.718 15.5218 14.73 15.5218 15.888C15.5218 16.16 15.5218 16.406 15.5218 16.594C15.5218 19.228 11.5218 19.278 11.5218 16.582C11.5218 15.368 9.79184 15.496 9.79184 16.538C9.79184 17.538 7.57584 18.002 7.57584 15.796C7.57584 14.148 6.13784 13.796 5.19384 14.66L5.19984 20L18.6778 19.908L18.7698 17.14C17.9658 16.908 17.4578 16.468 17.4578 15.888ZM17.8138 10.888L15.8258 10.946L15.7778 10.076L15.4338 8.71198L18.0738 9.03598L17.8138 10.888ZM14.9998 6.22398C14.9998 5.54398 15.1998 4.34798 16.9638 3.27398C16.7238 5.15998 18.3038 4.92798 18.3038 6.47398C18.2938 8.13198 14.9998 8.07998 14.9998 6.22398ZM12.9158 11.024L10.9638 11.08L10.6678 7.87998L13.2998 8.21998L12.9998 9.47198L12.9158 11.024ZM9.97184 5.32798C9.97184 4.64798 10.1718 3.45198 11.9358 2.37598C11.6958 4.26198 13.2758 4.02998 13.2758 5.57598C13.2758 7.23398 9.97184 7.18398 9.97184 5.32798ZM8.19984 11.17L6.25184 11.226L5.92584 10.036L5.57984 9.03598L8.21984 9.11798L8.19984 11.17ZM5.12984 6.30598C5.12984 5.62598 5.32984 4.42998 7.09584 3.35398C6.85584 5.23998 8.43584 5.00798 8.43584 6.55398C8.43584 8.21398 5.12984 8.16198 5.12984 6.30598Z"
        fill="#045FC4"
      />
    </g>
  </svg>
);

export default BirthdayIcon;
