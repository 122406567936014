import {
  educationHeaderKey,
  familyHeaderKey,
  bankHeaderKey,
  address,
  filesHeaderKey,
  personalData,
} from './employee';

export const newsHeaderKey: { [key: string]: any } = {
  title: 'Title',
  message: 'Message',
  attachment: 'Attachment',
  important: 'Important',
  visibleTo: 'Visible To',
  sender: 'Sender',
  includeNewEmployee: 'Incl. New Employee',
  publishDate: 'Publish Date',
  unpublishDate: 'Unpublish Date',
  legalEntity: 'Legal Entity',
  organization: 'Organization',
  jobGrade: 'Job Grade',
  jobPosition: 'Job Position',
  employmentType: 'Employment Type',
  employmentStatus: 'Employment Status',
  includeSpecificEmployee: 'Include Specific Employee',
  excludeSpecificEmployee: 'Exclude Specific Employee',
};

export const updateDataRequestHeaderKey: { [key: string]: any } = {
  EDUCATION: educationHeaderKey,
  FAMILY: familyHeaderKey,
  BANK: bankHeaderKey,
  EXPERIENCE: {
    effectiveDate: 'Effective Date',
    companyName: 'Company Name',
    position: 'Position',
    startDate: 'Start Date',
    endDate: 'End Date',
    reasonOfResignation: 'Reason of Resignation',
  },
  ADDRESS: address,
  FILE: filesHeaderKey,
  PERSONAL: personalData,
};
