const headerKey = {
  name: 'Name',
  description: 'Description',
};

const detailKey: { [key: string]: any } = {
  effectiveDate: 'Effective Date',
  name: 'Name',
  description: 'Description',
};

const defaultValueForm = {
  name: null,
  description: null,
  effectiveDate: '',
};

export default {
  headerKey,
  detailKey,
  defaultValueForm,
};
