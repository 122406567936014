import { useState, MouseEvent } from 'react';
import { Box, Popover, SxProps, Typography, useTheme } from '@mui/material';
import { useNavigate } from 'react-router-dom';

import { Button } from '../../atom';
import { ChevronRight, LogoMark } from '../../atom/icon';
import { useAppSelector } from '../../../store';

const LogoutPopover = (Props: any) => {
  const [helpAnchorEl, setHelpAnchorEl] = useState<HTMLButtonElement | null>(
    null
  );
  const { anchorEl, handleClose, onClick, handleClickAbout } = Props;
  const { palette } = useTheme();
  const navigate = useNavigate();
  const { fullName, email } = useAppSelector(state => state.credential);

  const open = Boolean(anchorEl);
  const openHelp = Boolean(helpAnchorEl);
  const id = open ? 'simple-popover' : undefined;

  const style: SxProps = {
    '& .MuiPopover-paper': {
      boxShadow:
        '0px 4px 8px -2px rgba(16, 27, 40, 0.1), 0px 2px 4px -2px rgba(16, 27, 40, 0.06)',
      borderRadius: '8px',
    },
    '& .top-container': {
      display: 'flex',
      padding: '16px',
      borderBottom: `1px solid ${palette.gray[50]}`,
      alignItems: 'center',
      justifyContent: 'center',
      gap: '12px',
    },
    '& .logo-container': {
      border: '1px solid #DCE0E5',
      height: '40px',
      width: '40px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      borderRadius: '90px',
      minWidth: '40px',
      boxSizing: 'border-box',
    },
    '& .button-wrapper': {
      boxSizing: 'border-box',
      paddingY: '4px',
      minWidth: '220px',
      '& .MuiButtonBase-root': {
        border: 'none',
        borderRadius: 0,
        justifyContent: 'flex-start',
        height: '44px',
        gap: '12px',
        paddingX: '16px',
        fontWeight: 400,
        lineHeight: '20px',
        '& svg': {
          width: '20px',
          height: '20px',
          minWidth: '20px !important',
          minHeight: '20px',
        },
      },
    },
  };

  const handleLogout = async () => {
    onClick();
  };

  const handleGotoSettings = () => {
    navigate('/custom-field');
    handleClose();
  };

  const onClickAbout = () => {
    handleClose();
    setHelpAnchorEl(null);
    handleClickAbout();
  };

  return (
    <Popover
      id={id}
      open={open}
      anchorEl={anchorEl}
      onClose={handleClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      sx={style}
    >
      <Box className="top-container">
        <Box className="logo-container">
          <LogoMark />
        </Box>
        <Box>
          <Typography
            variant="body14"
            fontWeight={600}
            color={palette.gray[100]}
            component="p"
          >
            {fullName}
          </Typography>
          <Typography variant="body12" color={palette.gray[80]} component="p">
            {email}
          </Typography>
        </Box>
      </Box>
      <Box className="button-wrapper">
        <Button fullWidth variant="outlined" onClick={handleGotoSettings}>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              gap: '.75rem',
              padding: '.2rem',
            }}
          >
            <Typography
              variant="body14"
              color={palette.gray[90]}
              marginRight="1rem"
            >
              Settings
            </Typography>
          </Box>
        </Button>
        <Button
          fullWidth
          variant="outlined"
          onClick={(e: MouseEvent<HTMLButtonElement>) => {
            setHelpAnchorEl(e.currentTarget);
          }}
        >
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              gap: '.75rem',
              padding: '.2rem',
              width: '100%',
            }}
          >
            <Typography
              variant="body14"
              color={palette.gray[90]}
              marginRight="1rem"
            >
              Help
            </Typography>
            <ChevronRight />
          </Box>
        </Button>
        <Button fullWidth variant="outlined" onClick={handleLogout}>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              gap: '.75rem',
              padding: '.2rem',
            }}
          >
            <Typography
              variant="body14"
              color={palette.red[50]}
              marginRight="1rem"
            >
              Log Out
            </Typography>
          </Box>
        </Button>
        <Popover
          open={openHelp}
          anchorEl={helpAnchorEl}
          onClose={() => setHelpAnchorEl(null)}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          sx={style}
        >
          <Box className="button-wrapper">
            <Button fullWidth variant="outlined" onClick={onClickAbout}>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  gap: '.75rem',
                  padding: '.2rem',
                  width: '100%',
                }}
              >
                <Typography
                  variant="body14"
                  color={palette.gray[90]}
                  marginRight="1rem"
                >
                  About
                </Typography>
              </Box>
            </Button>
          </Box>
        </Popover>
      </Box>
    </Popover>
  );
};

export default LogoutPopover;
