const defaultValueTaxLocation: { [key: string]: any } = {
  name: null,
  taxWithHolder: null,
  country: null,
  effectiveDate: null,
  signatoryName: null,
  signatoryNpwp: null,
  signatoryEmail: null,
  countryPhoneCode: null,
  signatoryMobileNo: null,
  signatoryPosition: null,
  reason: null,
};

const headerKeyTaxLocation: { [key: string]: any } = {
  effectiveDate: 'Effective Date',
  taxWithHolder: 'Tax Withholder',
  name: 'Name',
  signatoryName: 'Signatory Name',
  signatoryNpwp: 'Signatory NPWP',
  signatoryEmail: 'Signatory Email',
  signatoryMobileNo: 'Signatory Mobile No.',
  signatoryPosition: 'Signatory Position',
  totalEmployees: 'Total Employees',
};

const headerKeyForm = {
  taxWithholderDetail: {
    country: 'Country',
    withHolderNpwp: 'Withholder NPWP',
    withHolderName: 'Withholder Name',
  },
  taxLocationDetail: {
    name: 'Name',
    code: 'Code',
    taxOfficeName: 'Tax Service Office',
    country: 'Country',
    address: 'Address',
  },
};

const detailKey: { [key: string]: any } = {
  country: 'Country',
  name: 'Name',
  code: 'Code',
  taxOfficeName: 'Tax Service Office',
  address: 'Address',
  totalEmployee: 'Total Employees',
};

const defaultValueForm = {
  country: null,
  name: null,
  code: null,
  address: null,
};

const headerKey = {
  name: 'Name',
  code: 'Code',
  taxOfficeName: 'Tax Service Office',
  countryName: 'Country',
};

export default {
  defaultValueTaxLocation,
  headerKeyTaxLocation,
  headerKeyForm,
  detailKey,
  defaultValueForm,
  headerKey,
};
