import React from 'react';
import { RouteNode } from '../../@types';

const EmploymentTypeList = React.lazy(
  () => import('../../pages/employmentType/EmploymentTypeList')
);
const EmploymentTypeCreate = React.lazy(
  () => import('../../pages/employmentType/EmploymentTypeCreate')
);
const EmploymentTypeDetail = React.lazy(
  () => import('../../pages/employmentType/EmploymentTypeDetail')
);
const EmploymentTypeUpdate = React.lazy(
  () => import('../../pages/employmentType/EmploymentTypeUpdate')
);
const EmploymentTypeCorrection = React.lazy(
  () => import('../../pages/employmentType/EmploymentTypeCorrection')
);

const employementTypeRoute: RouteNode[] = [
  {
    label: 'Employment Type',
    path: 'employment-type',
    component: EmploymentTypeList,
    child: null,
  },
  {
    label: '',
    path: 'employment-type/create',
    component: EmploymentTypeCreate,
    child: null,
  },
  {
    label: '',
    path: 'employment-type/detail/:id',
    component: EmploymentTypeDetail,
    child: null,
  },
  {
    label: '',
    path: 'employment-type/update/:id',
    component: EmploymentTypeUpdate,
    child: null,
  },
  {
    label: '',
    path: 'employment-type/correction/:id',
    component: EmploymentTypeCorrection,
    child: null,
  },
];

export default employementTypeRoute;
