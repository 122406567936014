import { apiSlice } from '../../api/apiSlice';

type TDataTemplate = {
  id: string | null;
  columnName: string | null;
  maxLength: number | null;
  decimalLength: number | null;
  values: [
    {
      label: string;
      value: string;
    } | null
  ];
  sequentialNumber: number | null;
};
type TResponseTemplate = TDataTemplate[];

type TResponseBankFileInfo = {
  id: string;
  bankId: string;
  bankCode: string;
  swiftCode: string;
};

export const bankFileApiSlice = apiSlice.injectEndpoints({
  endpoints: builder => ({
    getBankFileList: builder.query<any, any>({
      query: params => {
        const { search, page, sortType, sort, searchString } = params;
        return {
          url: `/payroll/reports/bank-file`,
          params: { search, page, sortType, sort, ...searchString },
        };
      },
      providesTags: ['bankFileList'],
    }),
    generateTemplateBankFile: builder.query<
      TResponseTemplate,
      { bankId: string }
    >({
      query: ({ bankId }: { bankId: 'BCA' | 'HSBC' }) => ({
        url: `/payroll/reports/bank-file/template/${bankId}`,
      }),
    }),
    getBankFormatCode: builder.query<
      { id: string; bankFormatCode: string },
      { bankFileId: string }
    >({
      query: ({ bankFileId }) => ({
        url: `/payroll/reports/bank-format-code/${bankFileId}
      `,
      }),
    }),
    getBankFileInfo: builder.query<TResponseBankFileInfo, any>({
      query: params => {
        const { id, groupId } = params;
        return {
          url: `organization/legal-entities/${id}/banks/${groupId}/info`,
        };
      },
    }),
    generateBankFile: builder.mutation<any, { payload: any }>({
      query: ({ payload }) => ({
        url: '/payroll/reports/bank-file/configure',
        method: 'POST',
        body: payload,
      }),
      invalidatesTags: ['bankFileList'],
    }),
    deleteBankFile: builder.mutation<any, any>({
      query: id => ({
        url: `/payroll/reports/bank-file/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['bankFileList'],
    }),
    downloadBankFile: builder.mutation<any, any>({
      query: params => {
        const { body, payrollId } = params;
        return {
          url: `/payroll/reports/bank-file/generate/${payrollId}`,
          method: 'POST',
          body,
        };
      },
    }),
    getBankFormat: builder.query<any, any>({
      query: params => {
        const { bankFileId } = params;
        return {
          url: `/payroll/reports/bank-file/${bankFileId}/info`,
        };
      },
    }),
  }),
});

export const {
  useGetBankFileInfoQuery,
  useGenerateBankFileMutation,
  useGetBankFileListQuery,
  useDeleteBankFileMutation,
  useDownloadBankFileMutation,
  useGenerateTemplateBankFileQuery,
  useGetBankFormatCodeQuery,
  useGetBankFormatQuery,
} = bankFileApiSlice;
