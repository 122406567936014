const headerKey = {
  name: 'Name',
  code: 'Code',
  typeName: 'Type',
  totalPayItem: 'Total Pay Item',
};

const detailKey: { [key: string]: any } = {
  name: 'Name',
  code: 'Code',
  typeName: 'Type',
  payItem: 'Pay Item',
};

const defaultValueForm = {
  name: null,
  code: null,
  payItemData: [],
  type: 'DEBIT',
};

export default {
  headerKey,
  detailKey,
  defaultValueForm,
};
