import { useTheme, Typography, Box, SxProps } from '@mui/material';
import { useLocation } from 'react-router-dom';
import { Button } from '../../atom';
import { Plus } from '../../atom/icon';
import { VerticalDotsAction } from '..';
import { removeInfoLabel } from '../../../utils/removeInfoLabel';
import { useAppSelector, RootState } from '../../../store';
// import {ActionMenuProps} '../../../@types/TableProps'

type AddActionProps = {
  label: string;
  isButtonEnabled?: boolean;
  isCorrectionEnabled?: boolean;
  handleAction?: any;
  handleClickMenuAction?: Function;
  disableActionButton?: boolean;
  labelAdd?: string;
  actionMenus?: any[];
  sx?: SxProps;
};

const Header = ({
  handleAction,
  label,
  isButtonEnabled = false,
  isCorrectionEnabled = false,
  handleClickMenuAction,
  disableActionButton,
  labelAdd,
  actionMenus,
  sx,
}: AddActionProps) => {
  const { palette } = useTheme();
  const { isTerminated } = useAppSelector(
    (state: RootState) => state.termination
  );
  return (
    <Box
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      width="500px"
      padding="0"
      sx={sx}
    >
      <Typography variant="h6" color={palette.gray[90]} fontWeight="700">
        {label}
      </Typography>
      {isButtonEnabled && (
        <Button
          variant="link"
          disabled={disableActionButton}
          sx={{
            background: 'none',
            margin: 0,
          }}
          onClick={handleAction}
        >
          <Plus sx={{ color: palette.gray[80] }} />
          <Typography
            variant="body16"
            color={palette.gray[90]}
            fontWeight="600"
          >
            Add {labelAdd || removeInfoLabel(label)}
          </Typography>
        </Button>
      )}
      {isCorrectionEnabled && (
        <VerticalDotsAction
          actionMenus={actionMenus}
          onClickMenuAction={handleClickMenuAction}
          disabled={disableActionButton || isTerminated}
        />
      )}
    </Box>
  );
};

export default Header;
