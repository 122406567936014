const CreditCard02 = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14.6666 6.66658H1.33331M7.33331 9.33325H3.99998M1.33331 5.46659L1.33331 10.5333C1.33331 11.28 1.33331 11.6534 1.47864 11.9386C1.60647 12.1895 1.81044 12.3934 2.06133 12.5213C2.34654 12.6666 2.71991 12.6666 3.46665 12.6666L12.5333 12.6666C13.2801 12.6666 13.6534 12.6666 13.9386 12.5213C14.1895 12.3934 14.3935 12.1895 14.5213 11.9386C14.6666 11.6534 14.6666 11.28 14.6666 10.5333V5.46659C14.6666 4.71985 14.6666 4.34648 14.5213 4.06127C14.3935 3.81038 14.1895 3.60641 13.9386 3.47858C13.6534 3.33325 13.2801 3.33325 12.5333 3.33325L3.46665 3.33325C2.71991 3.33325 2.34654 3.33325 2.06133 3.47858C1.81044 3.60641 1.60647 3.81038 1.47864 4.06126C1.33331 4.34648 1.33331 4.71985 1.33331 5.46659Z"
      stroke="#475667"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default CreditCard02;
