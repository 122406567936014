import { createSlice } from '@reduxjs/toolkit';
import { timeOffApiSlice } from './service/timeOffApiSlice';
import { holidayApiSlice } from './service/holiday';

interface initialStateI {
  messageSuccess: string;
  messageError: string;
  rowData: Array<any>;
  updatedData: number;
}

const initialState: initialStateI = {
  messageSuccess: '',
  messageError: '',
  rowData: [],
  updatedData: 0,
};

export const holidaySlice = createSlice({
  name: 'timeOff',
  initialState,
  reducers: {
    removeMessage: state => {
      state.messageSuccess = '';
      state.messageError = '';
    },
    addRowData: (state, action) => {
      state.rowData.push(action.payload);
      state.messageSuccess = 'Holiday added';
    },
    updateRowData: (state, action) => {
      const { payload } = action;
      const index = state.rowData.findIndex(obj => obj.id === payload.id);
      state.rowData[index] = payload;
      state.messageSuccess = 'Holiday edited';
    },
    deleteRowData: (state, action) => {
      const filtered = state.rowData.filter(item => item.id !== action.payload);
      state.rowData = filtered;
      state.messageSuccess = 'Holiday removed';
    },
    resetRowData: state => {
      state.rowData = [];
    },
    fillPayData: (state, _action) => {
      state.rowData = _action.payload;
    },
    setMessageSuccess: (state, action) => {
      state.messageSuccess = action.payload;
    },
  },
  extraReducers(builder) {
    builder.addMatcher(
      holidayApiSlice.endpoints.createHoliday.matchFulfilled,
      (state, _action) => {
        state.messageSuccess = 'Holiday created';
      }
    );
    builder.addMatcher(
      holidayApiSlice.endpoints.cancelUpcomingHoliday.matchFulfilled,
      (state, _action) => {
        state.messageSuccess = 'Upcoming data cancelled';
      }
    );
  },
});

export const {
  removeMessage,
  addRowData,
  updateRowData,
  deleteRowData,
  resetRowData,
  fillPayData,
  setMessageSuccess,
} = holidaySlice.actions;
export const holidayReducer = holidaySlice.reducer;
