const headerKey = {
  name: 'Name',
  code: 'Code',
  precision: 'Precision',
  mode: 'Mode',
  remarks: 'Remarks',
};

const SelectButtonOptions = [
  {
    label: 'One Decimal Place (0,1)',
    value: 'ONE_DECIMAL_PLACE',
  },
  {
    label: 'Ones (1)',
    value: 'ONES',
  },
  {
    label: 'Tens (10)',
    value: 'TENS',
  },
  {
    label: 'Hundreds (100)',
    value: 'HUNDREDS',
  },
  {
    label: 'Thousands (1000)',
    value: 'THOUSANDS',
  },
];

const RadioButtonOptions = [
  {
    label: 'Round Up',
    value: 'ROUND_UP',
  },
  {
    label: 'Round Down',
    value: 'ROUND_DOWN',
  },
  {
    label: 'Round to the Nearest',
    value: 'ROUND_NEAREST',
  },
];

const RadioButtonOptionsAll = [
  {
    label: '(All)',
    value: '',
  },
  {
    label: 'Round Up',
    value: 'ROUND_UP',
  },
  {
    label: 'Round Down',
    value: 'ROUND_DOWN',
  },
  {
    label: 'Round to the Nearest',
    value: 'ROUND_NEAREST',
  },
];

const defaultValueForm = {
  name: null,
  code: null,
  precision: null,
  mode: 'ROUND_UP',
  remarks: null,
};

export default {
  headerKey,
  RadioButtonOptions,
  RadioButtonOptionsAll,
  SelectButtonOptions,
  defaultValueForm,
};
