import { apiSlice } from '../api/apiSlice';

type dropdown = {
  label: string;
  value: string;
};
export const exportApiSlice = apiSlice.injectEndpoints({
  endpoints: builder => ({
    downloadExport: builder.mutation<
      { entity: dropdown; fileType: dropdown },
      any
    >({
      query: payload => ({
        url: `/bulk-data/export`,
        method: 'POST',
        body: payload,
      }),
    }),
  }),
});

export const { useDownloadExportMutation } = exportApiSlice;
