import {
  ExchangeRateListType,
  ExchangeRateDetailsType,
} from '../../../@types/ExchangeRate';
import { QueryParams } from '../../../@types/QueryParams';
import { apiSlice } from '../api/apiSlice';

interface TabQuery extends QueryParams {
  id: string;
}
interface Query {
  id: string;
  effectiveDate?: string;
}

export const ExchangeRateSlice = apiSlice.injectEndpoints({
  endpoints: builder => ({
    getExchangeRateList: builder.query<ExchangeRateListType, QueryParams>({
      query: params => {
        const { search, page, sortType, sort, searchString } = params;
        return {
          url: `/payroll/exchange-rates`,
          params: { search, page, sortType, sort, ...searchString },
        };
      },
      providesTags: ['exchangeRatesList'],
    }),
    getExchangeRateDetail: builder.query<ExchangeRateDetailsType, Query>({
      query: params => {
        const { id, effectiveDate } = params;
        return {
          url: `/payroll/exchange-rates/${id}`,
          params: {
            effectiveDate,
          },
        };
      },
      providesTags: ['exchangeRateDetail'],
    }),
    createExchangeRate: builder.mutation({
      query: payload => ({
        url: '/payroll/exchange-rates',
        method: 'POST',
        body: payload,
      }),
      invalidatesTags: ['exchangeRatesList'],
    }),
    updateExchangeRate: builder.mutation({
      query: ({ payload, id }) => ({
        url: `payroll/exchange-rates/${id}`,
        method: 'PUT',
        body: payload,
      }),
      invalidatesTags: ['exchangeRateDetail'],
    }),
    cancelUpcomingExchangeRate: builder.mutation<any, any>({
      query: ({ id }) => ({
        url: `payroll/exchange-rates/${id}/cancellation`,
        method: 'DELETE',
      }),
      invalidatesTags: ['exchangeRateDetail', 'exchangeRatesList'],
    }),
    deleteExchangeRate: builder.mutation<any, any>({
      query: ({ id }) => ({
        // url: `exchange-rates/${id}`,
        url: `payroll/exchange-rates/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['exchangeRateDetail', 'exchangeRatesList'],
    }),
  }),
});

export const {
  useGetExchangeRateListQuery,
  useGetExchangeRateDetailQuery,
  useCreateExchangeRateMutation,
  useUpdateExchangeRateMutation,
  useDeleteExchangeRateMutation,
  useCancelUpcomingExchangeRateMutation,
} = ExchangeRateSlice;
