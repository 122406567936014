import { Box, CircularProgress, SxProps } from '@mui/material';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Payslips } from '../components';
import { mergeDeep } from '../../../utils/deepMerge';
import {
  essEmployeeSlice,
  useGetESSListYearsQuery,
} from '../../../store/feature/service/essEmployee';
import { useAppDispatch, useAppSelector } from '../../../store';
import { setTab } from '../../../store/feature/utilSlice';
import { Tabs } from '../../../components/molecule';

type LatestPayslipContainerProps = {
  sx?: SxProps;
  className?: string;
  setYearChoosen: any;
};

const LatestPayslipContainer = ({
  sx,
  className,
  setYearChoosen,
}: LatestPayslipContainerProps) => {
  const style: SxProps = {
    '& .payslip-title': {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      mb: '20px',
    },
    '& .right-arrow': {
      fontSize: '20px',
    },
  };
  const { data: years } = useGetESSListYearsQuery({});
  const [trigger, { isLoading }] =
    essEmployeeSlice.endpoints.getESSListMonthsByYear.useLazyQuery({});

  const [choosenYears, setYears] = useState<any>();
  const [listMonths, setMonths] = useState<any>();

  const renderTabs: any = () =>
    years &&
    years.map((year: any) => ({
      label: year?.label,
      component: () => (
        <Box>
          {isLoading ? (
            <CircularProgress />
          ) : (
            listMonths?.map((month: any) => (
              <Payslips
                date={month?.name}
                id={month?.id}
                sx={{ marginTop: '1rem' }}
              />
            ))
          )}
        </Box>
      ),
    }));

  const location = useLocation();
  const tabKey = `${location.pathname}`;
  const { selectedTab } = useAppSelector(state => state.utils);
  const dispatch = useAppDispatch();
  const handleChangeValue = (index: number) => {
    setYears(years?.[index]?.value);
    setYearChoosen(years?.[index]?.value);
    dispatch(setTab({ [tabKey]: index }));
  };
  useEffect(() => {
    if (years) {
      setYears(years?.[selectedTab[tabKey] || 0]?.value);
      setYearChoosen(years?.[selectedTab[tabKey] || 0]?.value);
    }
  }, [years]);

  useEffect(() => {
    choosenYears &&
      trigger({ year: choosenYears }).then(
        (val: any) => val?.isSuccess === true && setMonths(val?.data)
      );
  }, [choosenYears]);

  return (
    <Box sx={mergeDeep(style, sx)} className={className}>
      <Box>
        <Box
          sx={{
            display: 'flex',
            gap: '8px',
            flexDirection: 'column',
          }}
        >
          <Tabs
            value={selectedTab[tabKey] || 0}
            setValue={handleChangeValue}
            tabs={renderTabs()}
            sx={{
              height: 'calc(100vh - 160px)',
            }}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default LatestPayslipContainer;
