import { isValidDate } from './date';
import dayjs from './dayjs';

export const headerKeyMapper = (headerKey: any, data: any) => {};

export const objectMapper = (data: any) => {
  let result = {};
  if (data !== undefined && data !== null) {
    Object?.keys(data)?.forEach(key => {
      const value = data[key];
      if (Array.isArray(value) && value?.length !== 0) {
        result = {
          ...result,
          [key]: data[key]
            ?.map((item: any) => (typeof item === 'object' ? item.label : item))
            ?.join(', '),
          // ?.toLowerCase(),
        };
      } else if (Array.isArray(value) && value?.length === 0) {
        result = {
          ...result,
          [key]: '-',
        };
      } else if (
        (typeof value === 'object' || typeof value === 'function') &&
        value !== null
      ) {
        result = {
          ...result,
          [key]: data[key].label,
        };
      } else if (typeof value === 'boolean') {
        result = {
          ...result,
          [key]: value ? 'Yes' : 'No',
        };
      } else if (value === null) {
        result = {
          ...result,
          [key]: '-',
        };
      } else if (value === '') {
        result = {
          ...result,
          [key]: '-',
        };
      } else {
        result = {
          ...result,
          [key]: isValidDate(data[key])
            ? dayjs(data[key]).format('DD MMM YYYY')
            : data[key],
        };
      }
    });
  }
  return result;
};

export const arrayToObject = (data: any, key: string) => {
  let newData = {};
  data.forEach((item: any) => {
    newData = {
      ...newData,
      [item[key]]: item,
    };
  });
  return newData;
};

export const defaultObjectValueMapper = (data: any): any => {
  if (Array.isArray(data)) {
    const mapper = data.map((item: any) => {
      let object = {};
      Object.keys(item).forEach(key => {
        object = {
          ...object,
          [key]: item[key] ?? '-',
        };
      });
      return object;
    });
    return mapper;
  }
  let object = {};
  Object.keys(data).forEach(key => {
    object = {
      ...object,
      [key]: data[key] ?? '-',
    };
  });
  return object;
};

export const getMessageFromObject = (obj: any, keyString: any) => {
  const keys = keyString.split('.');
  let current = obj;
  keys?.forEach((key: any) => {
    current = current?.[key];
  });
  if (typeof current === 'object' && current !== null && 'message' in current) {
    return current.message;
  }
  return null;
};

/* eslint no-param-reassign: "off" */
export const addMissingKeys = (arr: string[], obj: { [key: string]: any }) => {
  const newObj = { ...obj };

  arr?.forEach((e: string) => {
    if (!(e in newObj)) {
      newObj[e] = null;
    }
  });
  return newObj;
};
