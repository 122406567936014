import { Box, SxProps, Typography, useTheme } from '@mui/material';
import React from 'react';
import { mergeDeep } from '../../../../utils/deepMerge';

type FormProps = {
  title?: string;
  children: React.ReactNode;
  sx?: SxProps;
};

const FormContainer = ({ title, children, sx }: FormProps) => {
  const { palette } = useTheme();
  const style: SxProps = {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    gap: '20px',
    '& .title': {
      fontWeight: 700,
    },
  };
  return (
    <Box sx={mergeDeep(style, sx)}>
      {!!title && (
        <Typography variant="body18" className="title" color={palette.gray[90]}>
          {title}
        </Typography>
      )}
      {children}
    </Box>
  );
};

export default FormContainer;
