import React, { useState } from 'react';
import { CircularProgress, Typography, Box, Popover } from '@mui/material';
// import firebase from 'firebase/app';
import { Button } from '../../components/atom';
import { ChevronDown } from '../../components/atom/icon';
import { downloadWithLoadingState } from '../../utils/file';
import { useAppSelector } from '../../store';
import Timer from '../../components/atom/timer/Timer';

const generate = ({ actionPopoverDirection = 'left' }) => {
  const [loadingGenerate, setLoadingGenerate] = useState(false);
  const [isRunning, setIsRunning] = useState(true);
  const [dataReport, setReport] = useState<any>({});
  const { isLoading } = useAppSelector(state => state.reportGeneration);

  const datas = JSON.parse(localStorage.getItem('dataDownload') || '');

  const renderExtensionFile = (file: string): string => {
    const splitted = file.split('.');
    if (splitted.length > 1) {
      const extension = splitted[splitted.length - 1];
      switch (extension) {
        case 'xlsx':
          return 'Excel (.xlsx)';
        case 'csv':
          return ' CSV UTF-8 Comma Delimited (.csv)';
        case 'pdf':
          return 'Portable Document Format (.pdf)';
        case 'zip':
          return 'ZIP (.zip)';
        default:
          return '';
      }
    }
    return '';
  };

  React.useEffect(
    () => setReport(JSON.parse(localStorage.getItem('dataDownload') as string)),
    []
  );

  const [isGenerated, setGenerated] = React.useState(false);
  React.useEffect(() => {
    if (!isLoading) {
      setTimeout(() => {
        setGenerated(true);
        setIsRunning(false);
      }, 5000);
    }
  }, [isLoading]);
  const [ActionAnchorEl, setActionAnchorEl] =
    React.useState<HTMLButtonElement | null>(null);
  const openAction = Boolean(ActionAnchorEl);
  const handleActionClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setActionAnchorEl(event.currentTarget);
  };
  const handleActionClose = () => {
    setActionAnchorEl(null);
  };
  const downloadFile = async () => {
    const datas = JSON.parse(localStorage.getItem('dataDownload') || '');
    if (datas !== null) {
      handleActionClose();
      try {
        await downloadWithLoadingState(
          {
            fileUrl: datas?.fileUrl,
            fileName: datas?.fileName,
            mimeType: datas?.mimeType,
          },
          setLoadingGenerate
        );
      } catch (error) {
        console.log(error);
      }
    }
  };

  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      flexDirection="column"
      gap="24px"
      width="100%"
      padding="3rem"
    >
      <Typography color="#101B28" fontSize="2rem" fontWeight="700">
        {isGenerated ? 'Your report is ready!' : 'Generating your report...'}
      </Typography>
      <Typography color="#475667" fontSize="1rem" fontWeight="400">
        {isGenerated
          ? // eslint-disable-next-line no-restricted-globals
            dataReport?.report
          : `  Please don't refresh this page while we're processing your report, this
        will take a minute.`}
      </Typography>
      <Typography color="#475667" fontSize="1rem" fontWeight="400">
        Elapsed time: <Timer isRunning={isRunning} />
      </Typography>
      {!isGenerated ? (
        <CircularProgress />
      ) : (
        <>
          <Button
            onClick={handleActionClick}
            isLoading={loadingGenerate}
            disabled={loadingGenerate}
          >
            Download <ChevronDown />
          </Button>
          <Popover
            open={openAction}
            anchorEl={ActionAnchorEl}
            onClose={handleActionClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: actionPopoverDirection === 'left' ? 'left' : 'right',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: actionPopoverDirection === 'left' ? 'left' : 'right',
            }}
            sx={{
              marginTop: '4px',
              '& .MuiPaper-root': {
                borderRadius: '8px',
              },
            }}
          >
            <Button
              onClick={() => downloadFile()}
              variant="outlined"
              fullWidth
              sx={{
                fontSize: '12px',
                width: '268px',
                display: 'flex',
                justifyContent: 'flex-start',
              }}
            >
              {renderExtensionFile(datas?.fileName)}
            </Button>
          </Popover>
        </>
      )}
    </Box>
  );
};

export default generate;
