import React from 'react';
import { RouteNode } from '../../@types';
import { Clock } from '../../components/atom/icon';
import { timeItem } from './configuration/timeItem';
import { holiday } from './setup/holiday';
import { shift } from './setup/shift';
import { schedule } from './setup/schedule';
import { timeEvent } from './configuration/timeEvent';
import { attendanceStatus } from './configuration/attendanceStatus';
import { attendanceProcess } from './attendance/attendanceProcess';
import { dailyAttendance } from './attendance/dailyAttendance';
import { timeOff } from './setup/timeOff';
import { workSchedule } from './workSchedule/workSchedule';
import { attendanceMachine } from './configuration/attendanceMachine';
import { AnalyticRoute } from './Analytic/index';
import { jobSite } from './setup/jobSite';
import { timeOffRequest } from './timeOff/request';
import { overtime } from './setup/overtime';
import { policy } from './configuration/policy';
import { overtimeItem } from './configuration/overtimeItem';
import { overtimeRequest } from './overtime/request';
import { timeOffItem } from './configuration/timeOffItem';

export const TimeRoute: RouteNode[] = [
  {
    label: 'Time',
    icon: Clock,
    path: 'time',
    component: null,
    child: [
      ...AnalyticRoute,
      ...workSchedule,
      {
        label: 'Attendance',
        path: 'attendance',
        component: null,
        // Remove attendance summary route as it's cancelled according to Product Team
        child: [...attendanceProcess, ...dailyAttendance],
      },
      {
        label: 'Overtime',
        path: 'overtime',
        component: null,
        // Remove attendance summary route as it's cancelled according to Product Team
        child: [...overtimeRequest],
      },
      {
        label: 'Time Off',
        path: 'time-off-request',
        component: null,
        // Remove attendance summary route as it's cancelled according to Product Team
        child: [...timeOffRequest],
      },

      {
        label: 'Setup',
        path: 'setup',
        component: null,
        child: [
          // {
          //   label: 'Synchronization',
          //   path: 'synchronization',
          //   component: UnderConstruction,
          //   child: null,
          // },
          ...holiday,
          ...jobSite,
          ...overtime,
          ...shift,
          ...schedule,
          ...timeOff,
        ],
      },
      {
        label: 'Configuration',
        path: 'configuration',
        component: null,
        child: [
          ...attendanceMachine,
          ...attendanceStatus,
          ...overtimeItem,
          ...policy,
          // ...timeEvent,
          ...timeItem,
          ...timeOffItem,
        ],
      },
    ],
  },
];
