import React from 'react';

const PieChart: React.FC = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_9485_62782)">
      <path
        d="M8.00004 1.3335C8.87552 1.3335 9.74243 1.50593 10.5513 1.84097C11.3601 2.176 12.095 2.66706 12.7141 3.28612C13.3331 3.90518 13.8242 4.64011 14.1592 5.44894C14.4943 6.25778 14.6667 7.12469 14.6667 8.00017M8.00004 1.3335V8.00016M8.00004 1.3335C4.31814 1.3335 1.33337 4.31826 1.33337 8.00016C1.33337 11.6821 4.31814 14.6668 8.00004 14.6668C11.6819 14.6668 14.6667 11.6821 14.6667 8.00017M8.00004 1.3335C11.6819 1.3335 14.6667 4.31827 14.6667 8.00017M14.6667 8.00017L8.00004 8.00016M14.6667 8.00017C14.6667 9.05224 14.4177 10.0894 13.9401 11.0268C13.4625 11.9642 12.7698 12.7752 11.9186 13.3936L8.00004 8.00016"
        stroke="#475667"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_9485_62782">
        <rect width="16" height="16" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default PieChart;
