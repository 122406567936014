import { Typography } from '@mui/material';

export const boldTextErrorRenderer = (text: string, arr: Array<string>) => {
  if (text === 'To must be filled.') {
    return (
      <Typography
        marginTop="8px"
        component="p"
        variant="body14"
        color="#F04438"
      >
        {text}
      </Typography>
    );
  }
  const words = text.split(' ');
  /* eslint react/no-array-index-key: "off" */
  return (
    <Typography marginTop="8px" component="p" variant="body14" color="#F04438">
      {words.map((word, index) => {
        const isBold = arr.includes(word);
        return isBold ? (
          <b key={index}>{word} </b>
        ) : (
          <span key={index}>{word} </span>
        );
      })}
    </Typography>
  );
};
