import { createSlice } from '@reduxjs/toolkit';
import { userAccessApiSlice } from './service/userAccessApiSlice';

interface initialStateI {
  messageSuccess: string;
  messageError: string;
  dataAccess: Record<string, string[]>;
}

const initialState: initialStateI = {
  messageSuccess: '',
  messageError: '',
  dataAccess: {},
};

const userAccessSlice = createSlice({
  name: 'userAccess',
  initialState,
  reducers: {
    removeMessage: state => {
      state.messageSuccess = '';
      state.messageError = '';
    },
    setDataAccess: (state, action) => {
      state.dataAccess = action.payload;
    },
  },
  extraReducers(builder) {
    builder.addMatcher(
      userAccessApiSlice.endpoints.createUserAccess.matchFulfilled,
      (state, _action) => {
        state.messageSuccess = 'User Access created.';
      }
    );
    builder.addMatcher(
      userAccessApiSlice.endpoints.createUserAccess.matchRejected,
      (state, _action) => {
        const payload = _action.payload as any;
        state.messageError = payload?.data?.message || 'Internal Server Error';
      }
    );
    builder.addMatcher(
      userAccessApiSlice.endpoints.deleteUserAccess.matchFulfilled,
      (state, _action) => {
        state.messageSuccess = 'User Access deleted.';
      }
    );
    builder.addMatcher(
      userAccessApiSlice.endpoints.deleteUserAccess.matchRejected,
      (state, _action) => {
        const payload = _action.payload as any;
        state.messageError = payload?.data?.message || 'Internal Server Error';
      }
    );
    builder.addMatcher(
      userAccessApiSlice.endpoints.updateUserAccess.matchFulfilled,
      (state, _action) => {
        state.messageSuccess = 'User Access updated.';
      }
    );
    builder.addMatcher(
      userAccessApiSlice.endpoints.updateUserAccess.matchRejected,
      (state, _action) => {
        const payload = _action.payload as any;
        state.messageError = payload?.data?.message || 'Internal Server Error';
      }
    );
  },
});

export const { removeMessage, setDataAccess } = userAccessSlice.actions;
export const userAccessReducer = userAccessSlice.reducer;
