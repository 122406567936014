import React from 'react';
import { RouteNode } from '../../@types';

const Create = React.lazy(
  () => import('../../pages/employee/previousEmployment/Create')
);

const Corrections = React.lazy(
  () => import('../../pages/employee/previousEmployment/Correction')
);

const Update = React.lazy(
  () => import('../../pages/employee/previousEmployment/Update')
);

const previousEmp: RouteNode[] = [
  {
    label: '',
    path: '/employee/detail/:employeeId/previous-employment/create',
    component: Create,
    child: null,
  },
  {
    label: '',
    path: '/employee/detail/:employeeId/previous-employment/correction/:groupId',
    component: Corrections,
    child: null,
  },
  {
    label: '',
    path: '/employee/detail/:employeeId/previous-employment/update/:groupId',
    component: Update,
    child: null,
  },
];

export default previousEmp;
