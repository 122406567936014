import React from 'react';
import {
  FormLabel,
  FormControl,
  FormGroup,
  FormControlLabel,
  Box,
  useTheme,
  CheckboxProps,
  Typography,
  SxProps,
} from '@mui/material';
import Skeleton from 'react-loading-skeleton';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { CheckboxOptionProps } from '../../../../@types';
import { Checkbox } from '../../../atom';
import { mergeDeep } from '../../../../utils/deepMerge';

interface CustomProps {
  label?: string;
  name: string;
  options: Array<CheckboxOptionProps>;
  showLabel?: boolean;
  isLoading?: boolean;
  isHorizontal?: boolean;
  onChange?: Function;
  defaultValue?: Array<any>;
  customElements?: any;
  required?: boolean;
  customError?: React.ReactNode;
}

const CheckboxWithLabel = <UseFieldArrayOptions extends Record<string, any>>(
  Props: Omit<CheckboxProps, 'onChange' | 'defaultValue'> &
    UseFieldArrayOptions &
    CustomProps
) => {
  const {
    name,
    label,
    control,
    options,
    disabled,
    showLabel = true,
    isLoading = false,
    isHorizontal = false,
    onChange,
    sx,
    customElements,
    defaultValue,
    required,
    customError,
    ...props
  } = Props;
  const { palette } = useTheme();
  const { t } = useTranslation();

  const style = {
    '& .MuiFormLabel-root': {
      marginBottom: '0.5rem',
    },
    '& .input--error .MuiInputBase-root fieldset': {
      borderColor: 'red !important',
      '&:focused': {
        borderColor: 'red !important',
      },
    },
  };

  return (
    <FormControl sx={mergeDeep(style, sx) as SxProps} fullWidth>
      {label && (
        <FormLabel htmlFor={name}>
          <Typography
            color={palette.gray[100]}
            variant="body14"
            fontWeight={500}
            sx={{
              wordWrap: 'break-word',
              maxWidth: '500px',
            }}
          >
            {label} {customElements}
            {!required && (
              <Typography
                component="span"
                variant="body14"
                color={palette.gray[80]}
              >
                {' '}
                (optional)
              </Typography>
            )}
          </Typography>
        </FormLabel>
      )}
      {control && (
        <Controller
          control={control}
          name={name || ''}
          render={({ field, formState }) => {
            const { errors } = formState as any;
            const isError =
              Object.keys(errors).length > 0 &&
              name &&
              Object.prototype.hasOwnProperty.call(errors, name);
            return (
              <Box
                sx={{ display: 'flex', gap: '8px', flexDirection: 'column' }}
              >
                {options.map((option: CheckboxOptionProps) => (
                  <FormGroup key={option.value}>
                    <FormControlLabel
                      disabled={option.isDisabled || disabled}
                      control={
                        <Checkbox
                          checked={field?.value?.indexOf(option.value) > -1}
                          onChange={(
                            ev: React.ChangeEvent<HTMLInputElement>
                          ) => {
                            const isChecked = ev.target.checked;
                            if (isChecked) {
                              const def = field?.value || [];
                              const newValues = [...def, option.value];
                              field.onChange(newValues);
                              onChange?.(newValues);
                            }

                            if (!isChecked) {
                              const newValues =
                                field.value?.filter(
                                  (e: string) => e !== option.value
                                ) || [];
                              field.onChange(newValues);
                              onChange?.(newValues);
                            }
                          }}
                        />
                      }
                      label={option.label}
                    />
                  </FormGroup>
                ))}
                {isError &&
                  (customError || (
                    <Typography
                      component="p"
                      variant="body14"
                      color={palette.red[50]}
                    >
                      {t(errors?.[name]?.message)}
                    </Typography>
                  ))}
              </Box>
            );
          }}
        />
      )}
      {!control && (
        <Box sx={{ display: 'flex', gap: '8px', flexDirection: 'column' }}>
          {options.map((option: CheckboxOptionProps) => (
            <FormGroup key={option.value}>
              <FormControlLabel
                disabled={disabled}
                control={
                  <Checkbox
                    checked={
                      defaultValue && defaultValue?.indexOf?.(option.value) > -1
                    }
                    onChange={(ev: React.ChangeEvent<HTMLInputElement>) => {
                      const isChecked = ev.target.checked;
                      if (isChecked) {
                        const newValues = defaultValue
                          ? [...defaultValue, option.value]
                          : [option.value];
                        onChange?.(newValues);
                      }

                      if (!isChecked) {
                        const newValues = defaultValue?.filter(
                          (e: string) => e !== option.value
                        );
                        onChange?.(newValues);
                      }
                    }}
                  />
                }
                label={option.label}
              />
            </FormGroup>
          ))}
        </Box>
      )}
    </FormControl>
  );
};

export default CheckboxWithLabel;
