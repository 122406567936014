import React from 'react';
import { RouteNode } from '../../@types';

const List = React.lazy(
  () => import('../../pages/taxLocation/TaxLocationList')
);
const Create = React.lazy(
  () => import('../../pages/taxLocation/TaxLocationCreate')
);
const Detail = React.lazy(
  () => import('../../pages/taxLocation/TaxLocationDetails')
);
const Update = React.lazy(
  () => import('../../pages/taxLocation/TaxLocationUpdate')
);
const Correction = React.lazy(
  () => import('../../pages/costCenter/Correction')
);

const taxLocation: RouteNode[] = [
  {
    label: 'Tax Location',
    path: 'tax-location',
    component: List,
    child: null,
  },
  {
    label: '',
    path: 'tax-location/create',
    component: Create,
    child: null,
  },
  {
    label: '',
    path: 'tax-location/detail/:id',
    component: Detail,
    child: null,
  },
  {
    label: '',
    path: 'tax-location/update/:id',
    component: Update,
    child: null,
  },
  // {
  //   label: '',
  //   path: 'cost-center/correction/:id',
  //   component: Correction,
  //   child: null,
  // },
];

export default taxLocation;
