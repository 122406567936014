import {
  Checkbox,
  TableBody as TableB,
  Typography,
  useTheme,
} from '@mui/material';
import Box from '@mui/material/Box';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import { TableBodyProps } from '../../../@types/TableProps';
import './TableBody.css';

const TableBody = (Props: TableBodyProps) => {
  const {
    headerKey,
    rowData,
    showShadow,
    showRightShadow,
    stickyLeft,
    activeDescription = false,
    onDescriptionClick,
    page,
    showIdx = true,
    stickyRight,
    minWidth,
    listCheckedRow,
    showCheckbox,
    isAllChecked,
    listUncheckedRow,
    handleClickUncheckRow,
    onClickRowCheckbox,
    limit = 10,
  } = Props;
  const { palette } = useTheme();
  const headerKeyList = Object.keys(headerKey);
  return (
    <TableB>
      {rowData.map((value: any, index: number) => (
        <TableRow
          key={JSON.stringify(value?.id)}
          sx={{
            background:
              index % 2 === 0 || value?.whiteBackgroundCell
                ? `${palette.gray[0]} !important`
                : palette.gray[10],
            '& .body-14': {
              width: '52px',
              padding: value?.noPaddingCell ? 0 : '12px 16px',
              boxSizing: 'border-box',
              height: '44px',
            },
            '& .sticky': {
              backgroundColor:
                index % 2 === 0 || value?.whiteBackgroundCell
                  ? `${palette.gray[0]} !important`
                  : palette.gray[10],
              '&::after': {
                position: 'absolute',
                right: '-8px',
                top: 0,
                content: "''",
                width: '8px',
                height: '100%',
                boxShadow: showShadow
                  ? 'inset 10px 0 8px -8px rgb(5 5 5 / 6%)'
                  : 'none',
              },
              '& .sticky-table': {
                maxWidth: '200px',
                minWidth: '150px',
                padding: value?.noPaddingCell ? 0 : '12px 16px',
                boxSizing: 'border-box',
                cursor: activeDescription ? 'pointer' : 'cursor',
              },
            },
            '& .sticky-right': {
              backgroundColor:
                index % 2 === 0 || value?.whiteBackgroundCell
                  ? `${palette.gray[0]} !important`
                  : palette.gray[10],
              '&::before': {
                position: 'absolute',
                left: '-8px',
                top: 0,
                content: "''",
                width: '8px',
                height: '100%',
                boxShadow: showRightShadow
                  ? 'inset -10px 0 8px -8px rgb(5 5 5 / 6%)'
                  : 'none',
              },
              '& .sticky-table': {
                maxWidth: '200px',
                minWidth: '150px',
                padding: value?.noPaddingCell ? 0 : '12px 16px',
                boxSizing: 'border-box',
              },
            },
            '&:hover': {
              backgroundColor: !value?.whiteBackgroundCell
                ? '#F3F6F9 !important'
                : 'inherit',
              '& .sticky': {
                backgroundColor: !value?.whiteBackgroundCell
                  ? '#F3F6F9 !important'
                  : 'inherit',
              },
              '& .sticky-right': {
                backgroundColor: !value?.whiteBackgroundCell
                  ? '#F3F6F9 !important'
                  : 'inherit',
              },
            },
          }}
        >
          {stickyLeft && (
            <TableCell
              className="sticky"
              sx={{
                borderTop: value?.borderCell
                  ? `1px solid ${palette.gray[40]} !important`
                  : 0,
                borderRight: value?.borderCell
                  ? `1px solid ${palette.gray[40]} !important`
                  : 0,
              }}
            >
              <Box sx={{ display: 'flex' }}>
                {showCheckbox ? (
                  <Checkbox
                    onClick={
                      isAllChecked
                        ? () => handleClickUncheckRow?.(value.id)
                        : () => onClickRowCheckbox?.(value.id)
                    }
                    checked={
                      (isAllChecked || listCheckedRow?.includes(value.id)) &&
                      !listUncheckedRow?.includes(value.id)
                    }
                  />
                ) : (
                  <Box width="3rem">
                    <Typography
                      className="body-14"
                      variant="body14"
                      component="p"
                      color={palette.gray[100]}
                    >
                      {(page - 1) * limit + index + 1}
                    </Typography>
                  </Box>
                )}

                {headerKeyList.length && (
                  <Box
                    className="sticky-table"
                    onClick={() =>
                      activeDescription &&
                      setTimeout(() => {
                        onDescriptionClick?.(value.id);
                      }, 110)
                    }
                  >
                    <Typography
                      variant="body14"
                      color={
                        activeDescription ? palette.blue[50] : palette.gray[100]
                      }
                      fontWeight={activeDescription ? 600 : 400}
                    >
                      {value[headerKeyList[0]]}
                    </Typography>
                  </Box>
                )}
              </Box>
            </TableCell>
          )}
          {!stickyLeft && (
            <>
              {showIdx && (
                <TableCell
                  sx={{
                    borderTop: value?.borderCell
                      ? `1px solid ${palette.gray[40]} !important`
                      : 0,
                    borderRight: value?.borderCell
                      ? `1px solid ${palette.gray[40]} !important`
                      : 0,
                  }}
                >
                  <Typography
                    variant="body14"
                    component="p"
                    color={palette.gray[100]}
                    sx={{
                      padding: value?.noPaddingCell ? '0 16px' : '12px 16px',
                    }}
                  >
                    {(page - 1) * limit + index + 1}
                  </Typography>
                </TableCell>
              )}

              {headerKeyList.length && (
                <TableCell
                  sx={{
                    borderTop: value?.borderCell
                      ? `1px solid ${palette.gray[40]} !important`
                      : 0,
                    borderRight: value?.borderCell
                      ? `1px solid ${palette.gray[40]} !important`
                      : 0,
                    borderLeft: value?.borderCell
                      ? `1px solid ${palette.gray[40]} !important`
                      : 0,
                    cursor: activeDescription ? 'pointer' : 'cursor',
                  }}
                  onClick={() =>
                    activeDescription &&
                    setTimeout(() => {
                      onDescriptionClick?.(value.id);
                    }, 110)
                  }
                >
                  <Typography
                    variant="body14"
                    component="p"
                    color={
                      activeDescription ? palette.blue[50] : palette.gray[100]
                    }
                    fontWeight={activeDescription ? 600 : 400}
                    sx={{
                      padding: value?.noPaddingCell ? 0 : '12px 16px',
                      whiteSpace: 'nowrap',
                    }}
                  >
                    {value[headerKeyList[0]]}
                  </Typography>
                </TableCell>
              )}
            </>
          )}
          {headerKeyList.map(
            (key, index) =>
              index >= 1 &&
              index < headerKeyList.length - 1 && (
                <TableCell
                  key={key + value[key]}
                  sx={{
                    borderTop: value?.borderCell
                      ? `1px solid ${palette.gray[40]} !important`
                      : 0,
                    borderRight: value?.borderCell
                      ? `1px solid ${palette.gray[40]} !important`
                      : 0,
                    borderLeft: value?.borderCell
                      ? `1px solid ${palette.gray[40]} !important`
                      : 0,
                  }}
                >
                  <Typography
                    component="div"
                    sx={{
                      whiteSpace: 'nowrap',
                      padding: value?.noPaddingCell ? 0 : '12px 16px',
                    }}
                    variant="body14"
                    color={palette.gray[100]}
                  >
                    {value[key]}
                  </Typography>
                </TableCell>
              )
          )}
          {stickyRight && (
            <TableCell
              className="sticky-right"
              sx={{
                borderTop: value?.borderCell
                  ? `1px solid ${palette.gray[40]} !important`
                  : 0,
                borderLeft: value?.borderCell
                  ? `1px solid ${palette.gray[40]} !important`
                  : 0,
              }}
            >
              <Box sx={{ display: 'flex' }}>
                {headerKeyList.length && (
                  <Box className="sticky-table">
                    <Typography variant="body14" color={palette.gray[100]}>
                      {value[headerKeyList[headerKeyList.length - 1]]}
                    </Typography>
                  </Box>
                )}
              </Box>
            </TableCell>
          )}
          {!stickyRight && (
            <TableCell
              sx={{
                borderTop: value?.borderCell
                  ? `1px solid ${palette.gray[40]} !important`
                  : 0,
                borderLeft: value?.borderCell
                  ? `1px solid ${palette.gray[40]} !important`
                  : 0,
              }}
            >
              {headerKeyList.length && (
                <Typography
                  component="div"
                  sx={{
                    whiteSpace: 'nowrap',
                    padding: value?.noPaddingCell ? 0 : '12px 16px',
                  }}
                  variant="body14"
                  color={palette.gray[100]}
                >
                  {value[headerKeyList[headerKeyList.length - 1]]}
                </Typography>
              )}
            </TableCell>
          )}
        </TableRow>
      ))}
    </TableB>
  );
};

export default TableBody;
