import { Box, SxProps } from '@mui/material';
import React from 'react';
import { LastUpdated, Title } from '../../../molecule';

type TabContainerProps = {
  title?: string;
  children: React.ReactNode;
  sx?: SxProps;
  lastUpdated?: number;
  showLastUpdated?: boolean;
};

const TabContainer = ({
  title,
  children,
  sx,
  lastUpdated,
  showLastUpdated = true,
}: TabContainerProps) => {
  const style: SxProps = {
    mt: '1.5rem',
    '& .title': {
      mb: '1rem',
      display: 'flex',
      justifyContent: 'space-between',
    },
    '& .title-text': {
      fontWeight: 700,
    },
    '& .last-updated': {
      fontWeight: 400,
    },
    ...sx,
  };
  return (
    <Box sx={style}>
      <Box className="title">
        {title && <Title title={title} />}
        {showLastUpdated && <LastUpdated lastUpdated={lastUpdated} />}
      </Box>
      {children}
    </Box>
  );
};

export default TabContainer;
