import { apiSlice } from '../../api/apiSlice';

export const ConfigurationSlice = apiSlice.injectEndpoints({
  endpoints: builder => ({
    getDetailBpjsKesehatan: builder.query<any, any>({
      query: () => ({
        url: '/payroll/reports/bpjs-kesehatan/configure',
      }),
      providesTags: ['updateConfigureBPJSKesehatan'],
    }),
    updateConfigurationBpjs: builder.mutation<any, any>({
      query: ({ payload }) => ({
        url: '/payroll/reports/bpjs-kesehatan/configure',
        method: 'PUT',
        body: payload,
      }),
      invalidatesTags: ['updateConfigureBPJSKesehatan'],
    }),
    getDetail1721i: builder.query<any, any>({
      query: () => ({
        url: '/payroll/1721-i-report-configuration',
      }),
      providesTags: ['updateConfigure1721I'],
    }),
    updateConfiguration1721i: builder.mutation<any, any>({
      query: ({ body }) => ({
        url: '/payroll/reports/1721-i-report',
        method: 'PUT',
        body,
      }),
      invalidatesTags: ['updateConfigure1721I'],
    }),
    getDetail1721A1: builder.query<any, any>({
      query: () => ({
        url: '/payroll/1721-a1-report-configuration',
      }),
      providesTags: ['1721a1Configuration'],
    }),
    submitConfiguration1721A1: builder.mutation<any, any>({
      query: ({ body }) => ({
        url: '/payroll/reports/1721-a1-report/configuration',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['1721a1Configuration'],
    }),
    getBpjsTkBaruConfig: builder.query<any, any>({
      query: () => ({
        url: 'payroll/reports/bpjs-tk-baru/configure',
      }),
      providesTags: ['bpjsTkBaruConfiguration'],
    }),
    submitConfigurationBpjsTkBaru: builder.mutation<any, any>({
      query: ({ body }) => ({
        url: '/payroll/reports/bpjs-tk-baru/configure',
        method: 'PUT',
        body,
      }),
      invalidatesTags: ['bpjsTkBaruConfiguration'],
    }),
    getBpjsTkLanjutanConfig: builder.query<any, any>({
      query: () => ({
        url: 'payroll/reports/bpjs-tk-lanjutan/configure',
      }),
      providesTags: ['bpjsTkLanjutanConfiguration'],
    }),
    submitConfigurationBpjsTkLanjutan: builder.mutation<any, any>({
      query: ({ body }) => ({
        url: 'payroll/reports/bpjs-tk-lanjutan/configure',
        method: 'PUT',
        body,
      }),
      invalidatesTags: ['bpjsTkLanjutanConfiguration'],
    }),
    getEBupot2126Configuration: builder.query<any, any>({
      query: () => ({
        url: 'payroll/bupot-2126-report-configuration',
      }),
    }),
  }),
});

export const {
  useGetDetailBpjsKesehatanQuery,
  useGetDetail1721iQuery,
  useUpdateConfigurationBpjsMutation,
  useUpdateConfiguration1721iMutation,
  useGetDetail1721A1Query,
  useSubmitConfiguration1721A1Mutation,
  useGetBpjsTkBaruConfigQuery,
  useSubmitConfigurationBpjsTkBaruMutation,
  useGetBpjsTkLanjutanConfigQuery,
  useSubmitConfigurationBpjsTkLanjutanMutation,
  useGetEBupot2126ConfigurationQuery,
} = ConfigurationSlice;

export const ConfigurationReducer = ConfigurationSlice.reducer;
