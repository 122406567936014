import { apiSlice } from '../../api/apiSlice';

export const employeeTerminationApiSlice = apiSlice.injectEndpoints({
  endpoints: builder => ({
    getDetailTermination: builder.query({
      query: args => {
        const { id } = args;
        return {
          url: `/employee/employees/${id}/terminate`,
        };
      },
      providesTags: ['EmployeeTerminationDetail', 'EmployeeList'],
    }),
    createTermination: builder.mutation<any, any>({
      query: ({ id, payload }) => ({
        url: `/employee/employees/${id}/terminate`,
        method: 'POST',
        body: payload,
      }),
      invalidatesTags: [
        'EmployeeTerminationDetail',
        'EmployeeList',
        'employeeEmployment',
        'employeeBank',
        'employeeWorkLocation',
        'employeeFamily',
        'employeeEducationList',
        'employeeFiles',
        'employeePersonalList',
        'employeeUpcomingData',
        'employeeStatutoryList',
        'CostCenterList',
      ],
    }),
    updateTermination: builder.mutation<any, any>({
      query: ({ terminationId, payload }) => ({
        url: `/employee/employees/${terminationId}/terminate`,
        method: 'PUT',
        body: payload,
      }),
      invalidatesTags: [
        'EmployeeTerminationDetail',
        'EmployeeList',
        'employeeEmployment',
        'employeeBank',
        'employeeWorkLocation',
        'employeeFamily',
        'employeeEducationList',
        'employeeFiles',
        'employeePersonalList',
        'employeeUpcomingData',
        'employeeStatutoryList',
        'CostCenterList',
      ],
    }),
    cancelChangesTermination: builder.mutation<any, any>({
      query: ({ terminateId }) => ({
        url: `/employee/employees/${terminateId}/terminate`,
        method: 'DELETE',
      }),
      invalidatesTags: [
        'EmployeeTerminationDetail',
        'EmployeeList',
        'employeeEmployment',
        'employeeBank',
        'employeeWorkLocation',
        'employeeFamily',
        'employeeEducationList',
        'employeeFiles',
        'employeePersonalList',
        'employeeUpcomingData',
        'employeeStatutoryList',
        'CostCenterList',
      ],
    }),
    withdrawTermintaion: builder.mutation<any, any>({
      query: ({ terminationId, body }) => ({
        url: `/employee/employees/${terminationId}/terminate/withdraw`,
        method: 'DELETE',
        body,
      }),
      invalidatesTags: [
        'EmployeeTerminationDetail',
        'EmployeeList',
        'employeeEmployment',
        'employeeBank',
        'employeeWorkLocation',
        'employeeFamily',
        'employeeEducationList',
        'employeeFiles',
        'employeePersonalList',
        'employeeUpcomingData',
        'employeeStatutoryList',
        'CostCenterList',
      ],
    }),
    checkAllIncomingData: builder.query<{ isUpcoming: { isIt: boolean } }, any>(
      {
        query: ({ employeeId }) => ({
          url: `/employee/employees/${employeeId}/terminate/check-upcoming`,
        }),
        providesTags: [
          'EmployeeTerminationDetail',
          'EmployeeList',
          'employeeEmployment',
          'employeeBank',
          'employeeWorkLocation',
          'employeeFamily',
          'employeeEducationList',
          'employeeFiles',
          'employeePersonalList',
          'employeeUpcomingData',
          'employeeStatutoryList',
          'CostCenterList',
        ],
      }
    ),
  }),
});

export const {
  useGetDetailTerminationQuery,
  useCreateTerminationMutation,
  useCancelChangesTerminationMutation,
  useWithdrawTermintaionMutation,
  useUpdateTerminationMutation,
  useCheckAllIncomingDataQuery,
} = employeeTerminationApiSlice;
