import React from 'react';
import { RouteNode } from '../../@types';

const fileList = React.lazy(() => import('../../pages/file/List'));
const fileCreate = React.lazy(() => import('../../pages/file/Create'));
const fileCorrection = React.lazy(() => import('../../pages/file/Correction'));
const fileUpdate = React.lazy(() => import('../../pages/file/Update'));

const file: RouteNode[] = [
  {
    label: 'File',
    path: 'file',
    component: fileList,
    child: null,
  },
  {
    label: '',
    path: 'file/create',
    component: fileCreate,
    child: null,
  },
  {
    label: '',
    path: 'file/correction/:id',
    component: fileCorrection,
    child: null,
  },
  {
    label: '',
    path: 'file/update/:id',
    component: fileUpdate,
    child: null,
  },
];

export default file;
