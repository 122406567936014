import React from 'react';

const List = React.lazy(() => import('../../../pages/timeOffItem/List'));
const Create = React.lazy(() => import('../../../pages/timeOffItem/Create'));
const Detail = React.lazy(() => import('../../../pages/timeOffItem/Detail'));

export const timeOffItem = [
  {
    label: 'Time Off Item',
    child: null,
    path: 'time-off-items',
    component: List,
  },
  {
    label: '',
    child: null,
    path: 'time-off-items/create',
    component: Create,
  },
  {
    label: '',
    path: 'time-off-items/detail/:id',
    component: Detail,
    child: null,
  },
];
