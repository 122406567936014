import {
  Typography,
  useTheme,
  TableHead as Th,
  TableCell,
  TableRow,
  Box,
  Checkbox,
} from '@mui/material';

import { Button } from '..';
import { SwitchAsc, SwitchDesc, SwitchDisable } from '../icon';
import { TableListProps } from '../../../@types/TableProps';

const TableHead = (
  Props: Omit<
    TableListProps,
    'setSort' | 'rowData' | 'page' | 'setPage' | 'excludeSelectedColumn'
  >
) => {
  const {
    headerKey,
    showShadow,
    showRightShadow,
    showSort,
    sortBy,
    sortType,
    handleSort,
    stickyLeft,
    showIdx = true,
    stickyRight,
    excludeSortTable = [],
    alterTableHeaderName = {},
    minWidth,
    showCheckbox,
    listCheckedRow,
    handleUnselectAll,
    handleSelectAllCheckbox,
    isAllChecked,
    listUncheckedRow,
    customFirstCellHeaderWidth,
  } = Props;
  const { palette } = useTheme();
  const headerKeyList = Object.keys(headerKey);
  const renderSort = (label: string) => {
    if (sortBy === label) {
      if (sortType === 'asc') {
        return (
          <Button
            disableFocusRipple
            disableRipple
            disableTouchRipple
            onClick={() => {
              handleSort(label);
            }}
            size="small"
            sx={{
              '&:hover': {
                background: 'none',
              },
              width: '16px',
              height: '16px',
              minWidth: 0,
              minHeight: 0,
              padding: 0,
              background: 'RGBA(255, 255, 255, 0)',
            }}
          >
            <SwitchAsc />
          </Button>
        );
      }
      return (
        <Button
          disableFocusRipple
          disableRipple
          disableTouchRipple
          onClick={() => {
            handleSort(label);
          }}
          size="small"
          sx={{
            '&:hover': {
              background: 'none',
            },
            width: '16px',
            height: '16px',
            minWidth: 0,
            minHeight: 0,
            padding: 0,
            background: 'RGBA(255, 255, 255, 0)',
          }}
        >
          <SwitchDesc />
        </Button>
      );
    }
    return (
      <Button
        disableFocusRipple
        disableRipple
        disableTouchRipple
        onClick={() => {
          handleSort(label);
        }}
        size="small"
        sx={{
          '&:hover': {
            background: 'none',
          },
          width: '16px',
          height: '16px',
          minWidth: 0,
          minHeight: 0,
          padding: 0,
          background: 'RGBA(255, 255, 255, 0)',
        }}
      >
        <SwitchDisable />
      </Button>
    );
  };

  return (
    <Th>
      <TableRow>
        {stickyLeft && (
          <TableCell component="th" className="sticky">
            <Box
              sx={{
                display: 'flex',
              }}
            >
              {showCheckbox ? (
                <Checkbox
                  sx={{
                    background: palette.gray[20],
                  }}
                  checked={isAllChecked && listUncheckedRow?.length === 0}
                  indeterminate={
                    (isAllChecked && listUncheckedRow?.length !== 0) ||
                    (!isAllChecked && listCheckedRow?.length !== 0)
                  }
                  onClick={
                    listUncheckedRow?.length !== 0 ||
                    listCheckedRow?.length !== 0
                      ? handleUnselectAll
                      : handleSelectAllCheckbox
                  }
                />
              ) : (
                <Typography
                  sx={{
                    fontWeight: 600,
                    fontSize: '12px',
                    lineHeight: '16px',
                    width: '52px',
                    height: '40px',
                    padding: '12px 16px',
                    boxSizing: 'border-box',
                    background: palette.gray[20],
                    // boxShadow: showShadow ? 'inset 10px 0 8px -8px rgb(5 5 5 / 6%)' : 'none'
                  }}
                  color={palette.gray[80]}
                  component="div"
                >
                  #
                </Typography>
              )}

              {headerKeyList.length && (
                <Box
                  sx={{
                    display: 'flex',
                    height: '40px',
                    gap: '8px',
                    width: '100%',
                    maxWidth: '200px',
                    minWidth: customFirstCellHeaderWidth || '150px',
                    padding: '12px 16px',
                    boxSizing: 'border-box',
                    background: palette.gray[20],
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    '&::after': {
                      position: 'absolute',
                      right: '-8px',
                      top: 0,
                      content: "''",
                      width: '8px',
                      height: '100%',
                      boxShadow: showShadow
                        ? 'inset 10px 0 8px -8px rgb(5 5 5 / 6%)'
                        : 'none',
                    },
                  }}
                >
                  <Typography
                    sx={{
                      fontWeight: 600,
                      fontSize: '12px',
                      lineHeight: '16px',
                      whiteSpace: 'nowrap',
                    }}
                    component="p"
                    color={palette.gray[80]}
                  >
                    {Object.keys(alterTableHeaderName).includes(
                      headerKey[headerKeyList[0]]
                    )
                      ? alterTableHeaderName[
                          headerKey[headerKeyList[0]]
                        ].toUpperCase()
                      : headerKey[headerKeyList[0]].toUpperCase()}
                  </Typography>
                  {showSort &&
                    !excludeSortTable.includes(headerKeyList[0]) &&
                    renderSort(headerKeyList[0])}
                </Box>
              )}
            </Box>
          </TableCell>
        )}

        {!stickyLeft && (
          <>
            {showIdx && (
              <TableCell
                component="th"
                sx={{
                  width: '8px',
                }}
              >
                <Typography
                  sx={{
                    fontWeight: 600,
                    fontSize: '12px',
                    lineHeight: '16px',
                    width: '52px',
                    height: '40px',
                    padding: '12px 16px',
                    boxSizing: 'border-box',
                    background: palette.gray[20],
                    // boxShadow: showShadow ? 'inset 10px 0 8px -8px rgb(5 5 5 / 6%)' : 'none'
                  }}
                  color={palette.gray[80]}
                  component="div"
                >
                  #
                </Typography>
              </TableCell>
            )}

            <TableCell>
              <Box
                sx={{
                  display: 'flex',
                  gap: '4px',
                  boxSizing: 'border-box',
                  whiteSpace: 'nowrap',
                  height: '40px',
                  padding: '12px 16px',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  minWidth: { minWidth },
                }}
              >
                <Typography
                  sx={{
                    fontWeight: 600,
                    fontSize: '12px',
                    lineHeight: '16px',
                  }}
                  component="p"
                  color={palette.gray[80]}
                >
                  {Object.keys(alterTableHeaderName).includes(
                    headerKey[headerKeyList[0]]
                  )
                    ? alterTableHeaderName[
                        headerKey[headerKeyList[0]]
                      ]?.toUpperCase()
                    : headerKey[headerKeyList[0]]?.toUpperCase()}
                </Typography>
                {showSort &&
                  !excludeSortTable.includes(headerKeyList[0]) &&
                  renderSort(headerKeyList[0])}
              </Box>
            </TableCell>
          </>
        )}

        {headerKeyList.map(
          (value, index) =>
            index >= 1 &&
            index < headerKeyList.length - 1 && (
              <TableCell key={value + headerKey[value]}>
                <Box
                  sx={{
                    display: 'flex',
                    gap: '4px',
                    boxSizing: 'border-box',
                    whiteSpace: 'nowrap',
                    height: '40px',
                    padding: '12px 16px',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                  }}
                >
                  <Typography
                    sx={{
                      fontWeight: 600,
                      fontSize: '12px',
                      lineHeight: '16px',
                    }}
                    component="p"
                    color={palette.gray[80]}
                  >
                    {Object.keys(alterTableHeaderName).includes(
                      headerKey[value]
                    )
                      ? alterTableHeaderName[headerKey[value]]?.toUpperCase()
                      : headerKey[value]?.toUpperCase()}
                  </Typography>
                  {showSort &&
                    !excludeSortTable.includes(value) &&
                    renderSort(value)}
                </Box>
              </TableCell>
            )
        )}
        {stickyRight && (
          <TableCell component="th" className="sticky-right">
            {headerKeyList.length && (
              <Box
                sx={{
                  display: 'flex',
                  height: '40px',
                  gap: '8px',
                  maxWidth: '200px',
                  minWidth: '150px',
                  padding: '12px 16px',
                  boxSizing: 'border-box',
                  background: palette.gray[20],
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  '&::before': {
                    position: 'absolute',
                    left: '-8px',
                    top: 0,
                    content: "''",
                    width: '8px',
                    height: '100%',
                    boxShadow: showRightShadow
                      ? 'inset -10px 0 8px -8px rgba(5, 5, 5, 0.06)'
                      : 'none',
                  },
                }}
              >
                <Typography
                  sx={{
                    fontWeight: 600,
                    fontSize: '12px',
                    lineHeight: '16px',
                    whiteSpace: 'nowrap',
                  }}
                  component="p"
                  color={palette.gray[80]}
                >
                  {Object.keys(alterTableHeaderName).includes(
                    headerKey[headerKeyList[headerKeyList.length - 1]]
                  )
                    ? alterTableHeaderName[
                        headerKey[headerKeyList[headerKeyList.length - 1]]
                      ].toUpperCase()
                    : headerKey[
                        headerKeyList[headerKeyList.length - 1]
                      ].toUpperCase()}
                </Typography>
                {showSort &&
                  !excludeSortTable.includes(
                    headerKeyList[headerKeyList.length - 1]
                  ) &&
                  renderSort(headerKeyList[headerKeyList.length - 1])}
              </Box>
            )}
          </TableCell>
        )}

        {!stickyRight && (
          <TableCell>
            <Box
              sx={{
                display: 'flex',
                gap: '4px',
                boxSizing: 'border-box',
                whiteSpace: 'nowrap',
                height: '40px',
                padding: '12px 16px',
                alignItems: 'center',
                justifyContent: 'space-between',
                minWidth: minWidth || '',
              }}
            >
              <Typography
                sx={{
                  fontWeight: 600,
                  fontSize: '12px',
                  lineHeight: '16px',
                }}
                component="p"
                color={palette.gray[80]}
              >
                {Object.keys(alterTableHeaderName).includes(
                  headerKey[headerKeyList[headerKeyList.length - 1]]
                )
                  ? alterTableHeaderName[
                      headerKey[headerKeyList[headerKeyList.length - 1]]
                    ]?.toUpperCase()
                  : headerKey[
                      headerKeyList[headerKeyList.length - 1]
                    ]?.toUpperCase()}
              </Typography>
              {showSort &&
                !excludeSortTable.includes(
                  headerKeyList[headerKeyList.length - 1]
                ) &&
                renderSort(headerKeyList[headerKeyList.length - 1])}
            </Box>
          </TableCell>
        )}
      </TableRow>
    </Th>
  );
};

export default TableHead;
