import { Box, Typography, useTheme } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import { Input } from '../../atom';

interface OtpType {
  size: number;
  onChange: Function;
  error?: boolean;
  disabled?: boolean;
}

const Otp = (Props: OtpType) => {
  const { size, onChange, error, disabled, ...props } = Props;
  const [text, setText] = useState(Array(size).fill(''));
  const inputRefs = useRef<HTMLInputElement[]>([]);
  const { palette } = useTheme();

  const handleOtpChange =
    (index: number) => (val: React.ChangeEvent<HTMLInputElement>) => {
      if (disabled) {
        return;
      }
      let { value } = val.target;
      if (!/^\d+$/.test(value)) {
        return;
      }
      if (value.length > 2 || value === '') {
        return;
      }
      if (value.length === 2) {
        value = value[value.length - 1];
      }
      const otpArrayCopy = [...text];
      otpArrayCopy[index] = value;
      setText(otpArrayCopy);

      if (value !== '') {
        if (index < size - 1) {
          inputRefs.current[index].disabled = true;
          inputRefs.current[index + 1].disabled = false;
          inputRefs.current[index + 1].focus();
        }
      }
    };

  useEffect(() => {
    if (inputRefs.current[0]) {
      inputRefs.current[0].focus();
      text.forEach((_element, index) => {
        if (index !== 0) {
          inputRefs.current[index].disabled = true;
        }
      });
    }
  }, []);

  useEffect(() => {
    onChange(text.join(''));
  }, [JSON.stringify(text)]);

  const handlePaste =
    (index: number) => (val: React.ClipboardEvent<HTMLInputElement>) => {
      const value = val.clipboardData.getData('Text');
      const pasteText = value.split('').join('').split('e').join('');
      if (!/^\d+$/.test(pasteText)) {
        return;
      }
      const toArray = pasteText.split('');
      let copyText = [...text];
      copyText.splice(index, toArray.length, ...toArray);
      if (copyText.length > size) {
        copyText = copyText.splice(0, size);
      }

      setText(copyText);

      copyText.forEach((element, index) => {
        if (index < size - 1 && element !== '') {
          inputRefs.current[index].disabled = true;
          inputRefs.current[index + 1].disabled = false;
          inputRefs.current[index + 1].focus();
        }
      });
    };

  const handleKeyDown = (
    event: React.KeyboardEvent<HTMLDivElement>,
    index: number
  ) => {
    if (event.key === 'Backspace') {
      const textCopy = text;
      if (textCopy[index] === '' && index !== 0) {
        textCopy[index - 1] = '';
        setText(textCopy.concat());
        inputRefs.current[index - 1].disabled = false;
        inputRefs.current[index - 1].focus();
        return;
      }
      textCopy[index] = '';
      setText(textCopy.concat());
      if (index === 0) {
        return;
      }
      inputRefs.current[index].disabled = true;
      inputRefs.current[index - 1].disabled = false;
      inputRefs.current[index - 1].focus();
    }
  };
  return (
    <Box
      sx={{
        display: 'flex',
        gap: '8px',
      }}
    >
      {text.map((val, index) => (
        <Input
          // eslint-disable-next-line
          key={index}
          inputRef={ref => (inputRefs.current[index] = ref)}
          type="text"
          value={val}
          onChange={handleOtpChange(index)}
          onPaste={handlePaste(index)}
          onKeyDown={e => {
            handleKeyDown(e, index);
          }}
          error={error}
          sx={{
            height: '52px',
            width: '52px',
            boxSizing: 'border-box',
            '& .MuiInputBase-root': {
              '& input': {
                padding: '4px',
                fontSize: '36px',
                textAlign: 'center',
                color: '#101B28',
                WebkitTextFillColor: '#101B28',
                caretColor: 'transparent',
              },
              '& fieldset': {
                borderColor: val
                  ? `${palette.blue[50]} !important`
                  : palette.gray[50],
              },
              '& fieldset:focus': {
                borderColor: `${palette.blue[50]}`,
                borderWidth: '1px',
              },
            },
          }}
        />
      ))}
    </Box>
  );
};

export default Otp;
