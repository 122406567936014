const toDataURL = (url: any) =>
  fetch(url)
    .then(response => response.blob())
    .then(blob => URL.createObjectURL(blob));

const toDataURLWithLoading = async (url: any, setLoadingDl: any) => {
  setLoadingDl(true);
  return fetch(url)
    .then(response => response.blob())
    .then(blob => {
      setLoadingDl(false);
      // setTimeout(() => setLoadingDl(false), 3000);
      return URL.createObjectURL(blob);
    });
};

export const downloadWithLoadingState = async (data: any, setLoading: any) => {
  const a = document.createElement('a');
  a.href = await toDataURLWithLoading(data?.fileUrl, setLoading);
  a.download = data?.fileName;
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
};

export const download = async (data: any) => {
  const a = document.createElement('a');
  a.href = await toDataURL(data?.fileUrl);
  a.download = data?.fileName;
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
};

export const downloads = async (data: any) => {
  const a = document.createElement('a');
  a.href = data?.fileUrl;
  a.download = data.fileName;
  console.log(data);
  // document.body.appendChild(a);
  // a.click();
  // document.body.removeChild(a);
};

export const downloadImportFilename = async (
  data: any,
  setLoadingDownload: any,
  triggerPendingToast: any,
  removeToast: any,
  setDownloaded?: any
) => {
  const toDataUrl = async (
    url: any,
    setLoadingDl: any,
    triggerPendingToast: any,
    removeToast: any
  ) => {
    triggerPendingToast?.();
    setLoadingDl(true);
    return fetch(url)
      .then(response => response.blob())
      .then(blob => {
        setTimeout(() => removeToast?.(), 3000);
        setTimeout(() => setLoadingDl(false), 3000);
        return URL.createObjectURL(blob);
      });
  };
  const a = document.createElement('a');
  a.href = await toDataUrl(
    data?.fileUrl,
    setLoadingDownload,
    triggerPendingToast,
    removeToast
  );
  a.download = data.fileName;
  document.body.appendChild(a);
  a.click();
  setDownloaded?.(true);
  document.body.removeChild(a);
};

export const removeUrlQueryStrings = (url: string) => url.split('?sv=')[0];
