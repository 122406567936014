import React from 'react';

const FolderDropdownIcon = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.66666 4.66667L7.92297 3.17928C7.70893 2.7512 7.6019 2.53715 7.44224 2.38078C7.30105 2.24249 7.13088 2.13732 6.94404 2.07287C6.73277 2 6.49347 2 6.01486 2H3.46666C2.71992 2 2.34656 2 2.06134 2.14532C1.81046 2.27316 1.60648 2.47713 1.47865 2.72801C1.33333 3.01323 1.33333 3.3866 1.33333 4.13333V4.66667M1.33333 4.66667H11.4667C12.5868 4.66667 13.1468 4.66667 13.5746 4.88465C13.951 5.0764 14.2569 5.38236 14.4487 5.75869C14.6667 6.18651 14.6667 6.74656 14.6667 7.86667V10.8C14.6667 11.9201 14.6667 12.4802 14.4487 12.908C14.2569 13.2843 13.951 13.5903 13.5746 13.782C13.1468 14 12.5868 14 11.4667 14H4.53333C3.41322 14 2.85317 14 2.42535 13.782C2.04902 13.5903 1.74306 13.2843 1.55132 12.908C1.33333 12.4802 1.33333 11.9201 1.33333 10.8V4.66667Z"
      stroke="#045FC4"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default FolderDropdownIcon;
