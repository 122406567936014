const headerKey = {
  currencyFrom: 'FROM',
  currencyTo: 'TO',
  value: 'VALUE',
  rate: 'RATE',
  inverseRate: 'INVERSE RATE',
  legalEntityName: 'LEGAL ENTITY',
  effectiveOn: 'EFFECTIVE FROM',
  effectiveUntil: 'EFFECTIVE TO',
  effectiveDate: 'EFFECTIVE DATE',
};

const defaultFilterValue = {
  currencyFromCode: null,
  currencyToCode: null,
  rateFrom: '',
  rateTo: '',
  inverseRateFrom: '',
  inverseRateTo: '',
  legalEntityId: [],
  effectiveOnFrom: '',
  effectiveOnTo: '',
  effectiveUntilFrom: '',
  effectiveUntilTo: '',
};

const defaultValueForm = {
  currencyFrom: '',
  currencyTo: '',
  value: '',
  rate: '',
  effectiveDate: '',
};

const defaultValueFormUpdate = {
  currencyFrom: {},
  currencyTo: {},
  value: 0,
  rate: 0,
  effectiveDate: '',
  id: '',
};

const detailKey = {
  effectiveDate: 'Effective Date',
  from: 'From',
  to: 'To',
  value: 'Value',
  rate: 'Rate',
};

export default {
  headerKey,
  defaultFilterValue,
  defaultValueForm,
  detailKey,
  defaultValueFormUpdate,
};
