import React from 'react';

const List = React.lazy(() => import('../../../pages/timeItem/List'));
const Create = React.lazy(() => import('../../../pages/timeItem/Create'));
const Detail = React.lazy(() => import('../../../pages/timeItem/Detail'));
const Update = React.lazy(() => import('../../../pages/timeItem/Update'));

export const timeItem = [
  {
    label: 'Time Item',
    child: null,
    path: 'time-items',
    component: List,
  },
  {
    label: '',
    child: null,
    path: 'time-items/create',
    component: Create,
  },
  {
    label: '',
    path: 'time-items/detail/:id',
    component: Detail,
    child: null,
  },
  {
    label: '',
    path: 'time-items/update/:id',
    component: Update,
    child: null,
  },
];
