import React from 'react';
import { Switch, SwitchProps, SxProps, useTheme } from '@mui/material';
import { mergeDeep } from '../../../utils/deepMerge';

const BasicSwitch = React.forwardRef((Props: SwitchProps, ref: any) => {
  const { sx, ...props } = Props;
  const { palette, transitions } = useTheme();

  const style: SxProps = {
    width: '40px',
    height: '24px',
    padding: 0,
    marginRight: '12px',
    '& .MuiSwitch-switchBase': {
      padding: 0,
      transitionDuration: '300ms',
      '&.Mui-checked': {
        transform: 'translateX(16px)',
        color: 'white',
        '& + .MuiSwitch-track': {
          backgroundColor: palette.success.main,
          opacity: 1,
          border: 0,
        },
        '&.Mui-disabled + .MuiSwitch-track': {
          opacity: 0.5,
        },
      },
      '&.Mui-focusVisible .MuiSwitch-thumb': {
        color: palette.success.main,
        border: '6px solid white',
      },
      '&.Mui-disabled .MuiSwitch-thumb': {
        color: palette.gray[50],
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: 0.7,
        backgroundColor: palette.gray[50],
      },
    },
    '& .MuiSwitch-thumb': {
      boxSizing: 'border-box',
      margin: '0.13rem',
      width: 20,
      height: 20,
      boxShadow: 'none',
    },
    '& .MuiSwitch-track': {
      borderRadius: 12,
      backgroundColor: palette.gray[50],
      opacity: 1,
      transition: transitions.create(['background-color'], {
        duration: 500,
      }),
    },
    '& .MuiSwitch-switchBase.Mui-checked+.MuiSwitch-track': {
      opacity: 1,
      background: palette.blue[50],
    },
  };

  return <Switch ref={ref} sx={mergeDeep(style, sx) as SxProps} {...props} />;
});

export default BasicSwitch;
