import { QueryParams } from '../../../@types/QueryParams';
import { apiSlice } from '../api/apiSlice';
import { ListResponse } from '../../../@types/ListResponseProps';
import {
  AccumulatorFormType,
  AccumulatorHeaderResponseType,
  AccumulatorList,
  AccumulatorPayItem,
} from '../../../@types/AccumulatorTypes';

type RequestParameterType = {
  id: string;
  effectiveDate?: string;
  sortType?: string;
  sort?: string;
  search?: string;
};

export const accumulatorApiSlice = apiSlice.injectEndpoints({
  endpoints: builder => ({
    getAccumulatorList: builder.query<
      ListResponse<AccumulatorList>,
      QueryParams
    >({
      query: params => {
        const { search, page, sortType, sort, searchString } = params;
        return {
          url: `/payroll/accumulators`,
          params: { search, page, sortType, sort, ...searchString },
        };
      },
      providesTags: ['AccumulatorList'],
    }),
    createAccumulator: builder.mutation<void, AccumulatorFormType>({
      query: body => ({
        url: `/payroll/accumulators`,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['AccumulatorList'],
    }),
    updateAccumulator: builder.mutation<
      void,
      { id: string; payload: AccumulatorFormType }
    >({
      query: ({ id, payload }) => ({
        url: `/payroll/accumulators/${id}`,
        method: 'PUT',
        body: payload,
      }),
      invalidatesTags: ['AccumulatorDetail'],
    }),
    deleteAccumulator: builder.mutation<void, { id: string }>({
      query: ({ id }) => ({
        url: `/payroll/accumulators/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['AccumulatorList'],
    }),
    getAccumulatorHeaderDetail: builder.query<
      AccumulatorHeaderResponseType,
      RequestParameterType
    >({
      query: params => {
        const { id, effectiveDate } = params;
        return {
          url: `/payroll/accumulators/${id}`,
          params: { effectiveDate },
        };
      },
      providesTags: ['AccumulatorList', 'AccumulatorDetail'],
    }),
    getAccumulatorPayItemList: builder.query<any, RequestParameterType>({
      query: params => {
        const { id, ...param } = params;
        return {
          url: `/payroll/accumulators/${id}/pay-items`,
          params: param,
        };
      },
      providesTags: ['AccumulatorList', 'AccumulatorDetail'],
    }),
    cancelChangesAccumulator: builder.mutation<any, any>({
      query: ({ newId }) => ({
        url: `/payroll/accumulators/${newId}/cancellation`,
        method: 'DELETE',
      }),
      invalidatesTags: ['AccumulatorDetail'],
    }),
  }),
});

export const {
  useGetAccumulatorListQuery,
  useCreateAccumulatorMutation,
  useUpdateAccumulatorMutation,
  useGetAccumulatorHeaderDetailQuery,
  useGetAccumulatorPayItemListQuery,
  useCancelChangesAccumulatorMutation,
} = accumulatorApiSlice;
