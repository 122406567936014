import { ReactNode } from 'react';
import {
  FormControl,
  FormLabel,
  SxProps,
  Typography,
  useTheme,
} from '@mui/material';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { InputProps } from '../../../../@types';
import { Input, LoadingSkeleton } from '../../../atom';
import { getMessageFromObject } from '../../../../utils/objectMap';
import { boldTextErrorRenderer } from '../../../../utils/boldTextErrorRenderer';

export type CustomProps = {
  label: string;
  showLabelOnError?: boolean;
  isLading?: boolean;
  showLabel?: boolean;
  boldErrorText?: Array<string>;
  customComponent?: ReactNode;
  customErrorMessage?: any;
};

const InputWithLabel = <UseFieldArrayOptions extends Record<string, any>>(
  Props: InputProps & UseFieldArrayOptions & CustomProps
) => {
  const { palette } = useTheme();
  const { t } = useTranslation();

  const {
    sx,
    name,
    control,
    label,
    showLabelOnError,
    isLoading,
    required = true,
    showLabel = true,
    disabled,
    boldErrorText = [],
    customComponent,
    customErrorMessage = null,
    onChange,
    ...props
  } = Props;
  const style = {
    '& .MuiFormLabel-root': {
      marginBottom: '0.5rem',
    },
    '& .input--error .MuiInputBase-root fieldset': {
      borderColor: `${palette.red[50]} !important`,
      '&:focused': {
        borderColor: `${palette.red[50]} !important`,
      },
    },
    ...sx,
  } as SxProps;
  return (
    <FormControl sx={style} fullWidth>
      {showLabel && (
        <FormLabel htmlFor={name}>
          <Typography
            color={palette.gray[100]}
            variant="body14"
            fontWeight={500}
            sx={{
              wordWrap: 'break-word',
              maxWidth: '500px',
            }}
          >
            {label} {customComponent}
            {!required && (
              <Typography
                component="span"
                variant="body14"
                color={palette.gray[80]}
              >
                (optional)
              </Typography>
            )}
          </Typography>
        </FormLabel>
      )}
      {control && (
        <Controller
          control={control}
          name={name || ''}
          render={({ field, formState }) => {
            const { errors } = formState as any;
            const isError =
              Object.keys(errors).length > 0 &&
              name &&
              getMessageFromObject(errors, name);
            return (
              <>
                {!isLoading && (
                  <Input
                    id={name}
                    className={isError ? 'input--error' : ''}
                    {...props}
                    {...field}
                    disabled={disabled}
                    onChange={e => {
                      field.onChange(e);
                      onChange?.(e);
                    }}
                  />
                )}
                {isLoading && (
                  <LoadingSkeleton
                    sx={{
                      '& .react-loading-skeleton': {
                        height: '48px',
                        borderRadius: '8px',
                      },
                    }}
                  />
                )}
                {isError &&
                  boldErrorText.length === 0 &&
                  !customErrorMessage && (
                    <Typography
                      marginTop="8px"
                      component="p"
                      variant="body14"
                      color={palette.red[50]}
                      dangerouslySetInnerHTML={{
                        __html: `${showLabelOnError ? label : ''} ${t(
                          getMessageFromObject(errors, name)
                        )}`,
                      }}
                    />
                  )}
                {isError &&
                  boldErrorText.length !== 0 &&
                  boldTextErrorRenderer(
                    t(errors?.[name]?.message),
                    boldErrorText
                  )}
                {customErrorMessage && customErrorMessage}
              </>
            );
          }}
        />
      )}
      {!control && (
        <>
          {!isLoading && <Input id={name} {...props} />}
          {isLoading && (
            <LoadingSkeleton
              sx={{
                '& .react-loading-skeleton': {
                  height: '48px',
                  borderRadius: '8px',
                },
              }}
            />
          )}
        </>
      )}
    </FormControl>
  );
};

export default InputWithLabel;
