import React from 'react';
import { RouteNode } from '../../../@types';
import exchangeRate from './exchangeRate';
import valueRounding from './valueRounding';
import statutory from './statutory';
import accumulatorRoutes from './accumulator';
import payslipTemplateRoutes from './payslipTemplate';
import formulaRoute from './formula';
import bankFileRoutes from './bankFile';

const configuration: RouteNode[] = [
  {
    label: 'Configuration',
    path: 'configuration',
    component: null,
    child: [
      ...accumulatorRoutes,
      ...bankFileRoutes,
      ...exchangeRate,
      ...formulaRoute,
      ...payslipTemplateRoutes,
      ...statutory,
      ...valueRounding,
    ],
  },
];

export default configuration;
