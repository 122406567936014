import { QueryParams } from '../../../@types/QueryParams';
import { apiSlice } from '../api/apiSlice';
import {
  JobPositionListType,
  JobPositionDetailsType,
} from '../../../@types/JobPosition';

interface Query {
  id: string;
  effectiveDate?: string;
}

export const JobPositionApiSlice = apiSlice.injectEndpoints({
  endpoints: builder => ({
    getJobPositionList: builder.query<JobPositionListType, QueryParams>({
      query: params => {
        const { search, page, sortType, sort, searchString } = params;
        return {
          url: `/organization/job-position`,
          params: { search, page, sortType, sort, ...searchString },
        };
      },
      providesTags: ['JobPositionList'],
    }),
    getJobPositionDetails: builder.query<any, Query>({
      query: params => {
        const { effectiveDate, id } = params;
        return {
          url: `/organization/job-position/${id}`,
          params: { effectiveDate },
        };
      },
      providesTags: ['JobPositionDetail'],
    }),
    addJobPosition: builder.mutation<any, any>({
      query: ({ body }) => ({
        url: '/organization/job-position',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['JobPositionList'],
    }),
    updateJobPosition: builder.mutation({
      query: ({ payload, id }) => ({
        url: `/organization/job-position/${id}`,
        method: 'PUT',
        body: payload,
      }),
      invalidatesTags: [
        'JobPositionDetail',
        'employeeEmployment',
        'EmployeeList',
      ],
    }),
    deleteJobPosition: builder.mutation<any, any>({
      query: id => ({
        url: `/organization/job-position/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['JobPositionList'],
    }),
    cancelUpdateJobPosition: builder.mutation<any, any>({
      query: newId => ({
        url: `/organization/job-position/${newId}/cancellation`,
        method: 'DELETE',
      }),
      invalidatesTags: ['JobPositionDetail', 'JobPositionList'],
    }),
  }),
});

export const {
  useGetJobPositionListQuery,
  useGetJobPositionDetailsQuery,
  useAddJobPositionMutation,
  useUpdateJobPositionMutation,
  useDeleteJobPositionMutation,
  useCancelUpdateJobPositionMutation,
} = JobPositionApiSlice;
