import employee from '../../../../constants/employee';
import { apiSlice } from '../../api/apiSlice';

export const EmployeeWorkLocationApiSlice = apiSlice.injectEndpoints({
  endpoints: builder => ({
    getDetailEmployeeWorkLocation: builder.query({
      query: ({ employeeId, ...query }) => ({
        url: `/employee/employees/${employeeId}/work-locations`,
        params: query,
      }),
      providesTags: ['employeeWorkLocation'],
    }),
    addEmployeeWorkLocation: builder.mutation<any, any>({
      query: query => {
        const { id, payload } = query;
        return {
          url: `employee/employees/${id}/work-locations`,
          method: 'POST',
          body: payload,
        };
      },
      invalidatesTags: ['employeeWorkLocation'],
    }),
    updateEmployeeWorkLocation: builder.mutation({
      query: ({ payload, id, employeeId }) => ({
        url: `/employee/employees/${employeeId}/work-locations/${id}`,
        method: 'PUT',
        body: payload,
      }),
      invalidatesTags: ['employeeWorkLocation'],
    }),
    cancelChangesWorkLocation: builder.mutation<any, any>({
      query: ({ id, employeeWorkLocationId }) => ({
        url: `/employee/employees/${id}/work-locations/${employeeWorkLocationId}/cancellation`,
        method: 'DELETE',
      }),
      invalidatesTags: ['employeeWorkLocation'],
    }),
  }),
});

export const {
  useGetDetailEmployeeWorkLocationQuery,
  useAddEmployeeWorkLocationMutation,
  useUpdateEmployeeWorkLocationMutation,
} = EmployeeWorkLocationApiSlice;
