import React from 'react';
import { RouteNode } from '../../../@types';

const payslipTemplate = React.lazy(
  () => import('../../../pages/payslipTemplate/PayslipTemplate')
);

const payslipTemplateRoutes: RouteNode[] = [
  {
    label: 'Payslip Template',
    path: 'payslip-template',
    child: null,
    component: payslipTemplate,
  },
];

export default payslipTemplateRoutes;
