import { createSlice } from '@reduxjs/toolkit';
import { employeeCareerTransitionSlice } from './service/employee/careerTransition';

interface initialStateI {
  messageSuccess: string;
  messageError: string;
}

const initialState: initialStateI = {
  messageSuccess: '',
  messageError: '',
};

const careerTransitionSlice = createSlice({
  name: 'careerTransition',
  initialState,
  reducers: {
    removeMessage: state => {
      state.messageSuccess = '';
      state.messageError = '';
    },
  },
  extraReducers(builder) {
    builder.addMatcher(
      employeeCareerTransitionSlice.endpoints.createCareerTransition
        .matchFulfilled,
      (state, _action) => {
        state.messageSuccess = 'Career Transition created';
      }
    );
    builder.addMatcher(
      employeeCareerTransitionSlice.endpoints.createCareerTransition
        .matchRejected,
      (state, _action) => {
        const payload = _action.payload as any;
        state.messageError = payload?.data?.message || 'Somethings Wrong';
      }
    );
    builder.addMatcher(
      employeeCareerTransitionSlice.endpoints.withdrawCareerTransition
        .matchFulfilled,
      (state, _action) => {
        state.messageSuccess = 'Career Transition withdrawn';
      }
    );
    builder.addMatcher(
      employeeCareerTransitionSlice.endpoints.withdrawCareerTransition
        .matchRejected,
      (state, action) => {
        const payload = action.payload as any;
        if (payload?.status === 422) {
          state.messageError =
            "This transition can't be withdrawn as it contains a backdated update.";
        } else {
          state.messageError =
            payload?.data?.message || 'Internal Server Error';
        }
      }
    );
    builder.addMatcher(
      employeeCareerTransitionSlice.endpoints.cancelTransition.matchFulfilled,
      (state, _action) => {
        state.messageSuccess = 'Career Transition canceled';
      }
    );
    builder.addMatcher(
      employeeCareerTransitionSlice.endpoints.cancelTransition.matchRejected,
      (state, action) => {
        const payload = action.payload as any;
        state.messageError = payload?.data?.message || 'Internal Server Error';
      }
    );
  },
});

export const { removeMessage } = careerTransitionSlice.actions;
export const careerTransitionReducer = careerTransitionSlice.reducer;
