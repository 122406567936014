import { apiSlice } from '../api/apiSlice';

export const LoadOptionsData = apiSlice.injectEndpoints({
  endpoints: builder => ({
    getPaginateData: builder.query<any, any>({
      query: params => {
        const { url, search, page, sortType, sort, searchString, ...props } =
          params;
        return {
          url,
          params: { search, page, sortType, sort, ...searchString, ...props },
        };
      },
    }),
  }),
});

export const { useGetPaginateDataQuery } = LoadOptionsData;
