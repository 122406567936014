import { Navigate } from 'react-router-dom';
import { getCookie } from '../utils/cookies';

interface PublicRouteProps {
  children?: any;
}

const PublicRoute = (props: PublicRouteProps) => {
  const { children } = props;
  return getCookie('app__refresh_token') ? <Navigate to="/" /> : children;
};

export default PublicRoute;
