import { apiSlice } from '../../api/apiSlice';

type ParamsHistory = {
  page?: number;
  limit?: number;
  sort?: any;
  sortType?: string | 'ASC' | 'DESC';
  search?: string;
  type: string;
};

type ResponseHistory = {
  data: [
    {
      id: string;
      reportName: string;
      legalEntity: string;
      month: string;
      year: string;
      frequency: string;
      generatedAt: number;
      generatedBy: string;
    }
  ];
  limit: number;
  page: number;
  total: number;
};

export const HistorySlice = apiSlice.injectEndpoints({
  endpoints: builder => ({
    getHistoryBPJS: builder.query<ResponseHistory, ParamsHistory>({
      query: params => ({
        url: `/payroll/reports/bpjs/${params.type}/history`,
        params: { ...params },
      }),
    }),
    getHistory1721: builder.query<ResponseHistory, ParamsHistory>({
      query: params => ({
        url: `/payroll/reports/1721/${params.type}/history`,
        params: { ...params },
      }),
    }),
    useGetHistory1721A1: builder.query<any, any>({
      query: params => ({
        url: `/payroll/reports/1721-a1-report/history`,
        params: { ...params },
      }),
      providesTags: ['1721a1History'],
    }),
    useDownloadHistory1721A1: builder.query<any, any>({
      query: historyId => ({
        url: `/payroll/reports/1721-a1-report/history/${historyId}/download`,
      }),
    }),
    useDeleteHistory1721A1: builder.mutation<any, any>({
      query: historyId => ({
        url: `/payroll/reports/1721-a1-report/history/${historyId}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['1721a1History'],
    }),
    listHistoryBupot2126: builder.query<any, any>({
      query: params => ({
        url: '/payroll/reports/bupot2126/history',
        params: { ...params },
      }),
      providesTags: ['listHistoryBupot2126'],
    }),
    downloadHistoryBupot2126: builder.query<any, any>({
      query: historyId => ({
        url: `/payroll/reports/bupot-report/history/${historyId}/download`,
      }),
    }),
    deleteHistoryBupot2126: builder.mutation<any, any>({
      query: historyId => ({
        url: `/payroll/reports/bupot-report/history/${historyId}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['listHistoryBupot2126'],
    }),
  }),
});

export const {
  useGetHistoryBPJSQuery,
  useGetHistory1721Query,
  useUseGetHistory1721A1Query,
  useUseDeleteHistory1721A1Mutation,
  useListHistoryBupot2126Query,
  useDownloadHistoryBupot2126Query,
  useDeleteHistoryBupot2126Mutation,
} = HistorySlice;

export const HistoryReducer = HistorySlice.reducer;
