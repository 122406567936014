import { Box, Grid } from '@mui/material';
import { useAppSelector } from '../../../../store';
import { objectMapper } from '../../../../utils/objectMap';
import { formatDateTimeFromUnixUTC } from '../../../../utils/date';
import LabelText from '../../../../components/atom/labelText/LabelText';
import HeaderAction from '../../../../components/molecule/action/HeaderAction';
import HistoricalTimeLine from '../../../../components/molecule/historicalTimeLine/HistoricalTimeLine';
import SubHeader from '../../../../components/molecule/subHeader/SubHeader';
import { useGetESSFamilyDetailQuery } from '../../../../store/feature/service/essEmployee';

const FamilySection = ({
  index,
  handleClickAction,
  headerKey,
  groupId,
  onClickAdd,
  employeeStatus,
}: any) => {
  const selectedHeader = `employee/family/${groupId}`;
  const { headerDate } = useAppSelector(state => state.utils);
  const { data }: any = useGetESSFamilyDetailQuery({
    dataId: groupId,
    effectiveDate: headerDate[selectedHeader],
  });

  const currentData: { [key: string]: any } = data?.data?.old
    ? {
        ...objectMapper(data?.data?.old || {}),
        birthDate: formatDateTimeFromUnixUTC(data?.data?.old?.birthDate),
        isDependent: data?.data?.old?.isDependent ? 'Yes' : 'No',
        isDeceased: data?.data?.old?.isDeceased ? 'Yes' : 'No',
        effectiveDate: (
          <HistoricalTimeLine
            headersState={data}
            selectedTab={selectedHeader}
          />
        ),
      }
    : {
        effectiveDate: (
          <HistoricalTimeLine
            headersState={data}
            selectedTab={selectedHeader}
          />
        ),
      };

  const upcomingData: { [key: string]: any } = {
    ...objectMapper(data?.data?.new || {}),
    birthDate: formatDateTimeFromUnixUTC(data?.data?.new?.birthDate),
    isDependent: data?.data?.new?.isDependent ? 'Yes' : 'No',
    isDeceased: data?.data?.new?.isDeceased ? 'Yes' : 'No',
  };

  return (
    <Grid item container spacing={1}>
      {index === 0 && (
        <Grid item xs={12}>
          <HeaderAction label="Family Info" labelAdd="Family" />
        </Grid>
      )}
      <Grid item xs={12}>
        <Box
          sx={{
            '& .list': {
              display: 'flex',
              padding: '8px 12px',
            },
            '& .sub-title': {
              fontWeight: 700,
            },
            display: 'flex',
            flexDirection: 'column',
            gap: '20px',
          }}
        >
          <SubHeader
            label="Family"
            idx={index}
            data={data}
            isCorrectionEnabled={false}
          />
        </Box>
      </Grid>
      {Object.keys(headerKey).map((key: any) => (
        <Grid item xs={12}>
          <LabelText
            headerKey={headerKey[key]}
            currentData={currentData?.[key]}
            upcommingData={upcomingData?.[key]}
            dataPresentationStatus={data?.dataPresentationStatus}
          />
        </Grid>
      ))}
    </Grid>
  );
};

export default FamilySection;
