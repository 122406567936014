import React from 'react';
import { RouteNode } from '../../@types';

const JobGradeList = React.lazy(
  () => import('../../pages/jobGrade/JobGradeList')
);
const JobGradeDetails = React.lazy(
  () => import('../../pages/jobGrade/JobGradeDetails')
);
const JobGradeUpdate = React.lazy(
  () => import('../../pages/jobGrade/JobGradeUpdate')
);
const JobGradeCorrection = React.lazy(
  () => import('../../pages/jobGrade/JobGradeCorrection')
);
const JobGradeCreate = React.lazy(
  () => import('../../pages/jobGrade/JobGradeCreate')
);

const jobGrade: RouteNode[] = [
  {
    label: 'Job Grade',
    path: 'job-grade',
    component: JobGradeList,
    child: null,
  },
  {
    label: '',
    path: 'job-grade/detail/:id',
    component: JobGradeDetails,
    child: null,
  },
  {
    label: '',
    path: 'job-grade/create',
    component: JobGradeCreate,
    child: null,
  },
  {
    label: '',
    path: 'job-grade/update/:id',
    component: JobGradeUpdate,
    child: null,
  },
  {
    label: '',
    path: 'job-grade/correction/:id',
    component: JobGradeCorrection,
    child: null,
  },
];

export default jobGrade;
