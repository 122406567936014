import { Box, SxProps, useTheme } from '@mui/material';
import { mergeDeep } from '../../../utils/deepMerge';

interface CapsuleProps {
  children: any;
  sx?: SxProps;
  onClick?: Function;
}

const Capsule = ({ children, sx, onClick }: CapsuleProps) => {
  const { palette } = useTheme();
  const style = {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    padding: ' 8px 12px',
    gap: '8px',
    background: '#FFFFFF',
    border: '1px solid #DCE0E5',
    borderRadius: '100px',
    order: 0,
    flexGrow: 0,
    '& svg': {
      transform: 'scale(0.666)',
      '& path': {
        stroke: palette.gray[80],
      },
    },
  };

  return (
    <Box
      sx={mergeDeep(style, sx)}
      onClick={() => {
        onClick?.();
      }}
    >
      {children}
    </Box>
  );
};

export default Capsule;
