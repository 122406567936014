import { Box, Typography } from '@mui/material';
import { Component, ErrorInfo, ReactNode } from 'react';
import { DetailsContainer } from '../../components/template';

interface Props {
  children: ReactNode;
}

interface State {
  hasError: boolean;
}

class ErrorBoundary extends Component<Props, State> {
  constructor(props: Props | Readonly<Props>) {
    super(props);
    this.state = { hasError: false };
  }

  public static getDerivedStateFromError(_: Error): State {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  public componentDidCatch(error: any, errorInfo: ErrorInfo) {
    console.log(errorInfo);
  }

  public render() {
    const { hasError } = this.state;
    if (hasError) {
      return (
        <DetailsContainer>
          <Box
            sx={{
              display: 'flex',
              height: '100%',
              width: '100%',
              justifyContent: 'center',
              alignItems: 'center',
              background: '#ffe1e3',
              borderRadius: '8px',
            }}
          >
            <Typography variant="h3" color="#f54f5c">
              Something Went Wrong!
            </Typography>
          </Box>
        </DetailsContainer>
      );
    }
    const { children } = this.props;
    return children;
  }
}

export default ErrorBoundary;
