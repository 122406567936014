import { useTheme, Box, Typography, Tooltip } from '@mui/material';

import { Button } from '..';
import { HeaderStateProps } from '../../../@types/HeaderStateProps';
import { ChevronLeft, ChevronRight } from '../icon';
import dayjs from '../../../utils/dayjs';

interface PropsPreviousNextDateButton {
  headersState?: HeaderStateProps;
  handleClickPrevious: Function;
  handleClickUpcoming: Function;
}

const PreviousNextDateButton = ({
  headersState,
  handleClickPrevious,
  handleClickUpcoming,
}: PropsPreviousNextDateButton) => {
  const { palette } = useTheme();
  return (
    <Box sx={{ display: 'flex' }}>
      <Button
        sx={{
          width: '48px',
          minWidth: '48px',
          borderTopRightRadius: 0,
          borderBottomRightRadius: 0,
          '& svg path': {
            stroke: palette.gray[90],
          },
        }}
        variant="outlined"
        disabled={headersState?.previousEffectiveDate === null}
        onClick={() => {
          handleClickPrevious();
        }}
      >
        <ChevronLeft />
      </Button>
      <Tooltip
        title={(() => {
          if (
            headersState?.dataPresentationStatus?.toLowerCase() === 'active'
          ) {
            return 'Active Effective Date';
          }
          if (
            headersState?.dataPresentationStatus?.toLowerCase() === 'upcoming'
          ) {
            return 'Upcoming Effective Date';
          }
          return 'Previous Effective Date';
        })()}
        placement="top"
        // sx={{ minWidth: '302px' }}
        componentsProps={{
          tooltip: {
            sx: {
              maxWidth: 'none',
              background: 'RGBA(255,255,255,1)',
              color: palette.gray[80],
              boxSizing: 'border-box',
              padding: '12px',
              borderRadius: '8px',
              fontSize: '12px',
              lineHeight: '16px',
              // minWidth: '302px',
              wordBreak: 'keep-all',
              boxShadow:
                '0px 4px 8px -2px rgba(16, 27, 40, 0.1), 0px 2px 4px -2px rgba(16, 27, 40, 0.06)',
              '& .MuiTooltip-arrow': {
                color: 'RGBA(255,255,255,1)',
              },
            },
          },
        }}
        arrow
      >
        <Typography
          component="div"
          sx={{
            height: '48px',
            boxSizing: 'border-box',
            border: `1px solid ${palette.gray[50]}`,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            padding: '14px 20px',
          }}
          variant="body14"
          color={palette.gray[100]}
        >
          {dayjs(headersState?.effectiveDate || new Date()).format(
            'DD MMM YYYY'
          )}
        </Typography>
      </Tooltip>
      <Button
        sx={{
          width: '48px',
          minWidth: '48px',
          borderTopLeftRadius: 0,
          borderBottomLeftRadius: 0,
          '& svg path': {
            stroke: palette.gray[90],
          },
        }}
        variant="outlined"
        disabled={headersState?.upcomingEffectiveDate === null}
        onClick={() => {
          handleClickUpcoming();
        }}
      >
        <ChevronRight />
      </Button>
    </Box>
  );
};

export default PreviousNextDateButton;
