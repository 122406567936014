import React from 'react';

const List = React.lazy(() => import('../../../pages/claimType/List'));
const Create = React.lazy(() => import('../../../pages/claimType/Create'));
const Detail = React.lazy(() => import('../../../pages/claimType/Detail'));

export const claimType = [
  {
    label: 'Claim Type',
    child: null,
    path: 'claim-type',
    component: List,
  },
  {
    label: '',
    child: null,
    path: 'claim-type/create',
    component: Create,
  },
  {
    label: '',
    path: 'claim-type/detail/:id',
    component: Detail,
    child: null,
  },
];
