import React from 'react';
import { RouteNode } from '../../@types';

const Create = React.lazy(
  () => import('../../pages/employee/rehire/CreateRehire')
);
const Detail = React.lazy(
  () => import('../../pages/employee/rehire/DetailRehire')
);

const rehire: RouteNode[] = [
  {
    label: '',
    path: 'employee/detail/:id/rehire/create',
    component: Create,
    child: null,
  },
  {
    label: '',
    path: 'employee/detail/:id/rehire/detail/:idrehire',
    component: Detail,
    child: null,
  },
];

export default rehire;
