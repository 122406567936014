import { apiSlice } from '../api/apiSlice';

type DemoRequestData = {
  country: string;
  totalEmployees: string;
  companyNeeds: { label: string; value: string }[];
  fullName: string;
  jobTitle: string;
  company: string;
  businessEmail: string;
  phoneNumber: string;
};

export const DemoRequestApiSlice = apiSlice.injectEndpoints({
  endpoints: builder => ({
    requestDemo: builder.mutation<void, DemoRequestData>({
      query: credentials => ({
        url: '/core/demo-requests',
        method: 'POST',
        body: credentials,
      }),
    }),
  }),
});

export const { useRequestDemoMutation } = DemoRequestApiSlice;
