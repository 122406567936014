import { apiSlice } from '../../api/apiSlice';

export const employeeEducationApiSlice = apiSlice.injectEndpoints({
  endpoints: builder => ({
    getListEmployeeEducation: builder.query({
      query: args => {
        const { id, ...query } = args;
        return {
          url: `/employee/employees/${id}/educations`,
          params: query,
        };
      },
      providesTags: ['employeeEducationList'],
    }),
    getDetailEmployeeEducation: builder.query({
      query: args => {
        const { id, groupId, ...query } = args;
        return {
          url: `/employee/employees/${id}/educations/${groupId}`,
          params: query,
        };
      },
      providesTags: ['employeeEducationDetail'],
    }),
    createEmployeeEducation: builder.mutation<any, any>({
      query: ({ id, body }) => ({
        url: `/employee/employees/${id}/educations`,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['employeeEducationList'],
    }),
    updateEmployeeEducation: builder.mutation<any, any>({
      query: ({ id, oldId, body }) => ({
        url: `/employee/employees/${id}/educations/${oldId}`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: ['employeeEducationDetail'],
    }),
    cancelChangesEmployeeEducation: builder.mutation<any, any>({
      query: ({ id, newId }) => ({
        url: `/employee/employees/${id}/educations/${newId}/cancellation`,
        method: 'DELETE',
      }),
      invalidatesTags: ['employeeEducationDetail', 'employeeEducationList'],
    }),
    deleteEmployeeEducation: builder.mutation<any, any>({
      query: ({ id, groupId }) => ({
        url: `/employee/employees/${id}/educations/${groupId}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['employeeEducationList'],
    }),
  }),
});

export const {
  useCreateEmployeeEducationMutation,
  useGetListEmployeeEducationQuery,
  useGetDetailEmployeeEducationQuery,
  useUpdateEmployeeEducationMutation,
  useCancelChangesEmployeeEducationMutation,
  useDeleteEmployeeEducationMutation,
} = employeeEducationApiSlice;
