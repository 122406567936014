import { Box, SxProps, Typography, useTheme } from '@mui/material';
import { Button } from '../../atom';
import { AlertTriangle } from '../../atom/icon';

type PreviousChangesProps = {
  reason: string;
  type?: string;
  isMessageHardCoded?: boolean;
  handleCancel?: Function;
};

const PreviousChanges = ({
  reason,
  type = '',
  handleCancel,
  isMessageHardCoded = false,
}: PreviousChangesProps) => {
  const { palette } = useTheme();
  const style: SxProps = {
    display: 'flex',
    flexDirection: 'column',
    gap: '1.5rem',
    mt: '2rem',
    '& .cancel-alert-box-container': {
      px: '1rem',
      py: '14px',
      display: 'flex',
      gap: '12px',
      alignItems: 'center',
      backgroundColor: palette.orange[20],
      borderRadius: '0.5rem',
    },
    '& .cancel-alert-box-icon-container': {
      width: '20px',
      height: '20px',
    },
    '& .cancel-alert-box-icon': {
      fontSize: '20px',
      color: palette.orange[50],
      width: '20px',
      height: '20px',
    },
    '& .cancel-alert-box-message-container': {
      flex: 1,
    },
    '& .cancel-alert-box-message': {
      fontWeight: 400,
    },
    '& .upcoming-changes-container': {
      p: '1rem',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      backgroundColor: palette.gray[10],
      borderRadius: '0.5rem',
    },
    '& .upcoming-changes-section': {
      display: 'flex',
      flexDirection: 'column',
      gap: '0.5rem',
    },
    '& .upcoming-changes-title': {
      fontWeight: 600,
    },
    '& .upcoming-changes-reason': {
      fontWeight: 400,
    },
  };

  const determineTitle = (presentationStatus: string) => {
    switch (presentationStatus) {
      case 'ACTIVE':
        return 'Latest';
      case 'PREVIOUS':
        return 'Previous';
      case 'UPCOMING':
        return 'Upcoming';
      default:
        return '';
    }
  };
  return (
    <Box sx={style}>
      {type === 'UPCOMING' && !isMessageHardCoded && (
        <Box className="cancel-alert-box-container">
          <Box className="cancel-alert-box-icon-container">
            <AlertTriangle className="cancel-alert-box-icon" />
          </Box>
          <Box className="cancel-alert-box-message-container">
            <Typography
              variant="body14"
              className="cancel-alert-box-message"
              color={palette.gray[100]}
            >
              Cancel your scheduled changes before updating the upcoming data.
            </Typography>
          </Box>
        </Box>
      )}
      <Box className="upcoming-changes-container">
        <Box className="upcoming-changes-section">
          <Typography
            variant="body16"
            className="upcoming-changes-title"
            color={palette.gray[100]}
          >
            {determineTitle(type)} Changes
          </Typography>
          {isMessageHardCoded ? (
            <Typography
              variant="body14"
              className="upcoming-changes-reason"
              color={palette.gray[90]}
            >
              Cancel upcoming changes before updating.
            </Typography>
          ) : (
            <Typography
              variant="body14"
              className="upcoming-changes-reason"
              color={palette.gray[90]}
            >
              Reason: {reason}
            </Typography>
          )}
        </Box>
        {type === 'UPCOMING' && (
          <Box>
            <Button variant="link" destructive onClick={() => handleCancel?.()}>
              Cancel Update
            </Button>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default PreviousChanges;
