import { PayloadType } from '../../../../@types/EmployeeDeduction';
import { apiSlice } from '../../api/apiSlice';

export const employeeDeductionApiSlice = apiSlice.injectEndpoints({
  endpoints: builder => ({
    getDetailEmployeeDeduction: builder.query({
      query: args => {
        const { id, groupId, ...query } = args;
        return {
          url: `/payroll/employees/${id}/deductions`,
          params: query,
        };
      },
      providesTags: ['employeeDeductionDetail'],
    }),
    createEmployeeDeduction: builder.mutation<any, PayloadType>({
      query: ({ employeeId, data }) => ({
        url: `/payroll/employees/${employeeId}/deductions`,
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['employeeDeductionDetail'],
    }),
    updateEmployeeDeduction: builder.mutation<any, PayloadType>({
      query: ({ employeeId, deductionId, data }) => ({
        url: `/payroll/employees/${employeeId}/deductions/${deductionId}`,
        method: 'PUT',
        body: data,
      }),
      invalidatesTags: ['employeeDeductionDetail'],
    }),
    cancelChangesEmployeeDeduction: builder.mutation<any, any>({
      query: ({ employeeId, deductionId }) => ({
        url: `/payroll/employees/${employeeId}/deductions/${deductionId}/cancellation`,
        method: 'DELETE',
      }),
      invalidatesTags: ['employeeDeductionDetail'],
    }),
  }),
});

export const {
  useCreateEmployeeDeductionMutation,
  useGetDetailEmployeeDeductionQuery,
  useUpdateEmployeeDeductionMutation,
  useCancelChangesEmployeeDeductionMutation,
} = employeeDeductionApiSlice;
