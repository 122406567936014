import * as React from 'react';
import { useTheme, Box, Tab, Tabs, SxProps } from '@mui/material';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

interface IBasicTabs {
  tabs: {
    label: string;
    Icon?: React.ReactNode;
    component: () => React.ReactNode;
  }[];
  value: number;
  setValue: Function;
  sx?: SxProps;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export default function BasicTabs({ tabs, value, setValue, sx }: IBasicTabs) {
  const { palette, typography } = useTheme();

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const renderTabComponent = (
    Icon: any,
    e: any,
    index: any
  ): React.ReactChild => {
    if (Icon) {
      return (
        <Tab
          icon={<Icon />}
          iconPosition="start"
          key={e.label}
          label={e.label}
          sx={{ padding: '8px 24px', minHeight: 0 }}
          {...a11yProps(index)}
        />
      );
    }
    return (
      <Tab
        key={e.label}
        sx={{ padding: '8px 24px', minHeight: 0 }}
        label={e.label}
        {...a11yProps(index)}
      />
    );
  };

  return (
    <Box sx={{ width: '100%', height: '100vh', ...sx }}>
      <Box>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
          sx={{
            height: '40px',
            minHeight: '0',
            boxSizing: 'border-box',
            '& .MuiTabs-fixed': {
              overflow: 'auto !important',
              '&::-webkit-scrollbar': {
                height: '0.1px',
              },
            },
            '& .MuiTabs-indicator': {
              backgroundColor: palette.blue[50],
              borderRadius: '2px',
            },
            '& .MuiButtonBase-root': {
              padding: '10px 8px 10px 8px',
              minWidth: 0,
              height: '40px',
              boxSizing: 'border-box',
            },
            '& .MuiTab-root.Mui-selected': {
              ...typography.body14,
              color: palette.blue[50],
              fontWeight: '500',
            },
            '& .MuiTab-root': {
              ...typography.body14,
              color: palette.gray[80],
              fontWeight: '500',
            },
            '& .MuiTab-textColorPrimary': {
              ...typography.body14,
              color: palette.gray[80],
              fontWeight: '500',
            },
            '& .MuiTab-root svg ': {
              marginRight: '8px',
            },
            '& .MuiTab-root.Mui-selected svg path ': {
              fill: palette.blue[50],
            },
            '& .MuiTab-root svg path': {
              fill: palette.gray[80],
            },
          }}
        >
          {tabs?.map((e: any, index: number) =>
            renderTabComponent(e.Icon, e, index)
          )}
        </Tabs>
      </Box>
      <Box
        sx={{
          borderBottom: '2px solid #DCE0E5',
          position: 'relative',
          width: '100%',
          top: '-2px',
          zIndex: '-1',
          borderRadius: '2px',
        }}
      />
      {tabs?.map((e: any, index: number) => (
        <TabPanel key={e.label} value={value} index={index}>
          {e.component()}
        </TabPanel>
      ))}
    </Box>
  );
}
