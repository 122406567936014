export const handleChangeEffectiveDateData = async (
  idx: number,
  options: object,
  trigger: Function,
  setData: Function
) => {
  try {
    const args = {
      ...options,
    };
    const response = await trigger({ ...args }).then(
      (datas: any) => datas.data
    );
    setData((prev: any) => {
      const newData = prev?.map((obj: object, id: number) => {
        if (id === idx) {
          return response;
        }
        return obj;
      });
      return newData;
    });
  } catch (error) {
    console.log(error);
  }
};

export const sortData = (data: any, sortBy: string, sortType: string) =>
  data.sort((a: any, b: any) => {
    let x = a[sortBy];
    let y = b[sortBy];

    if (typeof x === 'object' && x !== null) {
      if (Array.isArray(x) && x.length > 0) {
        x = x?.[0].label.toLowerCase();
      } else {
        x = x.label.toLowerCase();
      }
    }
    if (typeof y === 'object' && y !== null) {
      if (Array.isArray(y) && y.length > 0) {
        y = y?.[0].label.toLowerCase();
      } else {
        y = y.label.toLowerCase();
      }
    }
    if (sortType === 'asc') {
      if (x === null) return 1;
      if (y === null) return -1;
      return x < y ? -1 : 1;
    }
    if (x === null) return -1;
    if (y === null) return 1;
    if (x < y) return 1;
    if (x > y) return -1;
    return 0;
  });

export const filterData = (
  data: any,
  search: string,
  searchKey: string,
  searchKey2: string
) =>
  // data.filter((item: any) =>
  //   Object.keys(searchKey).every(key => {
  //     console.log(item[key].includes(search));
  //     return item[key].includes(search)
  //   })
  // );
  data?.filter(
    (item: any) =>
      item[searchKey]?.toLowerCase().includes(search) ||
      item[searchKey2]?.toLowerCase().includes(search)
  );
