import React from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { CircularProgress, Box, Grid } from '@mui/material';
import { FileShield02 } from '../../../../components/atom/icon';
import EmptyCard from '../../../../components/molecule/emptyCard/EmptyCard';
import { TabContainer } from '../../../../components/template';
import StatutorySection from './StatutorySection';
import { useGetESSStatutoryListQuery } from '../../../../store/feature/service/essEmployee';

const Statutory = () => {
  const navigate = useNavigate();
  const params = useParams<{ id: string }>();
  const { data, isLoading } = useGetESSStatutoryListQuery({});

  return (
    <Grid container spacing={2}>
      <Grid item xs={6} container rowSpacing={3}>
        {data?.map((item: any, index: number) => (
          <StatutorySection
            key={JSON.stringify(item)}
            index={index}
            groupId={item?.data?.old?.groupId || item?.data?.new?.groupId}
            statutoryType={
              data?.[index]?.data?.old?.statutoryType?.value ||
              data?.[index]?.data?.new?.statutoryType?.value
            }
          />
        ))}
        {data && data?.length < 1 && (
          <EmptyCard
            icon={<FileShield02 />}
            label="Statutory Info"
            isESS
            onClickAddTop={() =>
              navigate(`/employee/detail/${params.id}/statutory/create`)
            }
          />
        )}
      </Grid>
    </Grid>
  );
};

export default Statutory;
